import colorSet from "@/src/styles/color";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import React, { HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { styled } from "styled-components";
import {
  ButtonColorType,
  ButtonGradeType,
  ButtonSizeType,
  getButtonPadding,
  getPrimaryButtonColor,
  getSecondaryButtonColor,
  getTertiaryButtonColor,
} from "./BasicButton";

interface DropDownButtonProps extends HTMLAttributes<HTMLButtonElement> {
  buttonColor?: ButtonColorType;
  buttonSize?: ButtonSizeType;
  buttonGrade?: ButtonGradeType;

  item: {
    label: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }[];
}

function DropDownButton({
  item,
  buttonColor = "blue",
  buttonSize = 40,
  buttonGrade = "primary",
  children,
  ...props
}: PropsWithChildren<DropDownButtonProps>) {
  return (
    <DropdownMenu>
      <StyledMenuTrigger
        buttonColor={buttonColor}
        buttonSize={buttonSize}
        buttonGrade={buttonGrade}
        {...props}
      >
        {children}
      </StyledMenuTrigger>

      <StyeldDropdownMenuContent>
        {item.map(({ label, onClick }, idx) => {
          return (
            <StyledDropdownMenuItem
              key={idx.toString()}
              onClick={onClick && onClick}
            >
              {label}
            </StyledDropdownMenuItem>
          );
        })}
      </StyeldDropdownMenuContent>
    </DropdownMenu>
  );
}

export default DropDownButton;

const StyeldDropdownMenuContent = styled(DropdownMenuContent)`
  margin-top: 5px;
  padding: 6px;
  border-radius: 8px;
  background: ${colorSet.white};
  box-shadow: 0px 2px 8px 0px rgba(5, 29, 57, 0.25),
    0px 0px 0px 1px rgba(160, 164, 171, 0.2);
`;

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    outline: none;
  }
`;

const StyledMenuTrigger = styled(DropdownMenuTrigger)<{
  buttonColor: ButtonColorType;
  buttonSize: ButtonSizeType;
  buttonGrade: ButtonGradeType;
}>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.15s;
  &:focus {
    outline-offset: 1px;
    outline: 2px solid ${colorSet.blue5};
  }

  ${({ buttonSize }) => getButtonPadding(buttonSize)};
  ${({ buttonGrade, buttonColor }) =>
    buttonGrade === "primary"
      ? getPrimaryButtonColor(buttonColor)
      : buttonGrade === "secondary"
      ? getSecondaryButtonColor(buttonColor)
      : getTertiaryButtonColor(buttonColor)};
`;
