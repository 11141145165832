import { ForwardedRef, forwardRef } from "react";
import { Bar, ChartProps as ReactChartJsProps } from "react-chartjs-2";
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  RadialLinearScale,
  Filler,
  Legend,
  LineElement,
  PointElement,
  Tooltip,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title,
  BarElement,
  ChartDataLabels
);

interface BarChartProps extends ReactChartJsProps {
  options?: ChartOptions<"bar">;
  data: ChartData<"bar">;
  isStack?: boolean;
}

const BarChart = forwardRef(
  (
    { options, data, isStack, ...restProps }: BarChartProps,
    ref: ForwardedRef<ChartJSOrUndefined<"bar">>
  ) => {
    const option: ChartOptions<"bar"> = {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: !!isStack,
        },
        y: {
          stacked: !!isStack,
        },
      },
      ...options,
    };

    return <Bar ref={ref} data={data} options={option} {...restProps} />;
  }
);

export default BarChart;
