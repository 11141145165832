import colorSet, { ColorType } from "@/src/styles/color";
import typo, { FontSizeType } from "@/src/styles/typography";
import { HTMLAttributes, PropsWithChildren } from "react";
import { styled } from "styled-components";

interface ReadOnlyTextAreaProps extends HTMLAttributes<HTMLDivElement> {
  typoType?: FontSizeType;
  color?: ColorType;
}

function ReadOnlyText({
  color = "gray2",
  typoType = "b7r",
  children,
  ...props
}: PropsWithChildren<ReadOnlyTextAreaProps>) {
  return (
    <StyledReadOnlyTextArea color={color} typoType={typoType} {...props}>
      {children}
    </StyledReadOnlyTextArea>
  );
}

export default ReadOnlyText;

const StyledReadOnlyTextArea = styled.div<{
  typoType: FontSizeType;
  color: ColorType;
}>`
  border: none;
  outline: none;
  width: 100%;
  color: ${({ color }) => colorSet[color]};
  ${({ typoType }) => typo[typoType]}
  white-space: pre-wrap;
`;
