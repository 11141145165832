/**
 * @param value 숫자 구성된 문자열
 * @returns 세번째 자리마다 컴마가 추가된 숫자로 구성된 문자열과 소수점 두자리
 * @example "1,000.00"
 */
export const getIntegerWithDecimal = (value: string) => {
  let decimalValue = "";
  let replaceValue = value.replace(/,/g, "") ?? "";

  const [integerPart, decimalPart] = replaceValue.split(".");

  // 소수
  if (!decimalPart) {
    decimalValue = ".00";
  } else {
    decimalValue =
      decimalPart.length === 1
        ? `.${decimalPart}0`
        : `.${decimalPart.slice(0, 2)}`;
  }

  // 정수
  if (integerPart) {
    const formattedIntegerPart = Number(integerPart).toLocaleString("ko-KR");

    return (replaceValue = `${formattedIntegerPart}${decimalValue}`);
  }

  return "";
};

/**
 * @param target 해당 Input Element의 target
 * @description Input onChange 이벤트에서 세번째자리 마다 ,를 추가하고 커서위치를 유지를위한 함수
 * @returns 세번째 자리마다 컴마가 추가된 숫자로 구성된 문자열과 소수점 두자리
 * @example "1,000.00"
 */
export const handleIntegerWithDecimalsChange = (
  target: EventTarget & HTMLInputElement
) => {
  const { value: originValue, selectionStart } = target;
  const replaceNumber = originValue.replace(/[^\d.]/g, "");
  const numberParts = replaceNumber.split(".");

  // 커서가 존재하지 않을 경우 빈 문자열을 반환합니다.
  if (selectionStart === null) {
    return "";
  }

  // 정수, 소수
  let integerPart = Number(numberParts[0]).toLocaleString("ko-KR");
  let decimalPart = numberParts[1] ? `.${numberParts[1].slice(0, 2)}` : "";

  // 문자열이 마지막이 소수점인 경우
  if (selectionStart === originValue.length && originValue.endsWith(".")) {
    decimalPart = ".";
  }

  const newFormattedValue = `${integerPart}${decimalPart}`;

  // 쉼표를 직접 제거
  if (
    newFormattedValue[selectionStart] === "," &&
    originValue[selectionStart] !== ","
  ) {
    target.value =
      originValue.slice(0, selectionStart) + originValue.slice(selectionStart);
  } else {
    target.value = newFormattedValue;
  }

  // 수정시 커서를 수정한 위치로
  // originValue 소수점 세번째 자리 변환전 ex) 12,345,6123
  // target.value 소수점이 세번째자리마다 변환후 ex) 123,456,123
  const diff = target.value.length - originValue.length;
  target.setSelectionRange(selectionStart + diff, selectionStart + diff);

  return target.value;
};
