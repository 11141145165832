import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store";
import { useGetAllPushAlarmsQuery } from "./store/apis/pushAlarms";
import { closeNotification } from "./store/slice/webUtil";
import EXPORTER_PRIVATE_PATH from "./routes/exporter/path";
import PUBLIC_PATH from "./routes/public/path";
import PRIVATE_PATH from "./routes/private/path";

const SHARED_EMAIL_STRING = "shared-email";

function PushAlarms() {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const isNotificationOpen = useAppSelector(
    (state) => state.webUtil.isNotificationOpen
  );

  const [userToken, setUserToken] = useState(token);

  const isExportPath = window.location.pathname.includes("export");

  const isValidationUrl =
    window.location.pathname.includes(SHARED_EMAIL_STRING) ||
    window.location.pathname.includes(PUBLIC_PATH.CHANGE_PASSWORD) ||
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP
    ) ||
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING
    ) ||
    window.location.pathname.includes(
      EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP
    ) ||
    window.location.pathname.includes(PRIVATE_PATH.TWO_FACTOR_AUTHENTICATION);

  useGetAllPushAlarmsQuery(
    {
      services: isExportPath
        ? ["EXPORT_WEB", "COMMON_WEB"]
        : ["IMPORT_WEB", "COMMON_WEB"],
    },
    {
      skip: !userToken || isValidationUrl,
      refetchOnMountOrArgChange: true,
      pollingInterval: !isNotificationOpen ? 30000 : 0,
    }
  );

  useEffect(() => {
    setUserToken(token);
  }, [token]);

  useEffect(() => {
    dispatch(closeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default PushAlarms;
