export const ITEM_AVAILABLE_MESSAGE = "VALUE_AVAILABLE";

export interface GetItemManagementListRequest {
  // 아이템 코드
  itemCode?: string;
  // 아이템 코드 활성화 여부
  isActivated?: boolean;
  // 메인 카테고리 공통코드 아이템 codeItemName
  mainCategoryCodeItemName?: string;
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName?: string;
  // 아이템
  item?: string;
  // page
  page?: number;
  // pageSize
  pageSize?: number;
}

export interface ExporterItemCodeListViewV2Dto {
  // 데이터 ID(PK)
  id: number;
  // 아이템 카테고리 공통코드 아이템 codeItemName 또는 직접 입력한 값
  itemCodeItemName: string;
  // 아이템 코드
  itemCode: string;
  // 아이템 코드에 대한 설명
  description: string;
  // 아이템 코드 활성화 여부
  isActivated: boolean;
  // 메인 카테고리 공통코드 아이템 codeItemName
  mainCategoryCodeItemName: string;
  // 메인 카테고리
  mainCategory: string;
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName: string;
  // 서브 카테고리
  subCategory: string;
  // 아이템
  item: string;
  // ExporterItem의 ID(PK)
  exporterItemId: number;
}

export interface GetRegistrableItemsRequest {
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName: string;
}

export interface GetItemListRequest {
  // MANAGER 필터 적용 여부. MANAGER가 해당 값을 true로 하여 API 요청을 할 시,
  // MANAGER가 담당하는 메인 카테고리의 아이템 목록만 노출됩니다.
  // CORPORATE_MANAGER, MIDDLE_MANAGER의 경우 해당 필터를 적용하더라도 수출회사의 모든 아이템 목록이 노출됩니다.
  isManagerFilterOn?: boolean;
}

export interface ExporterItemListViewV2Dto {
  // 데이터 ID(PK)
  id: number;
  // 아이템
  item: string;
}

export interface ModifyExporterItemCodeV2Dto {
  // 아이템 코드
  itemCode: string;
  // 아이템 코드에 대한 설명
  description?: string | null;
  // 아이템 코드 활성화 여부
  isActivated: boolean;
}

export interface ExporterItemCodeDto {
  // 데이터 ID(PK)
  id: number;
  // 생성 일시
  createdAt: string;
  // 수정 일시
  updatedAt: string;
  // 삭제 일시
  deletedAt: string;
  // 아이템 코드
  itemCode: string;
  // 아이템 코드에 대한 설명
  description?: string;
  // 아이템 코드 활성화 여부
  isActivated: boolean;
}

export interface GenerateExporterItemCodeV2Dto {
  // 아이템 코드
  itemCode: string;
  // 아이템 코드에 대한 설명
  description?: string | null;
}

export interface GenerateBulkExporterItemCodesV2Dto {
  // 메인 카테고리 공통코드 아이템 codeItemName
  mainCategoryCodeItemName: string;
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName: string;
  // 아이템 카테고리 공통코드 아이템 codeItemName 또는 직접 입력한 값
  item: string;
  // 아이템 코드를 생성하기 위한 객체의 배열
  exporterItemCodeList: GenerateExporterItemCodeV2Dto[];
}

export interface MessageDto {
  // response에 전달되는 메시지
  message: string;
}

export interface GetItemCodeCheckRequest {
  // 메인 카테고리 공통코드 아이템 codeItemName
  mainCategoryCodeItemName: string;
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName: string;
  // 아이템 카테고리 공통코드 아이템 codeItemName 또는 직접 입력한 값
  item: string;
  // 아이템 코드
  itemCode: string;
}

export interface GenerateSingleExporterItemCodeV2Dto {
  // 메인 카테고리 공통코드 아이템 codeItemName
  mainCategoryCodeItemName: string;
  // 서브 카테고리 공통코드 아이템 codeItemName
  subCategoryCodeItemName: string;
  // 아이템 카테고리 공통코드 아이템 codeItemName 또는 직접 입력한 값
  item: string;
  // 아이템 코드
  itemCode: string;
  description?: string;
}

export interface GenerateSingleExporterItemCodeV2Response {
  // 데이터 ID(PK)
  id: number;
  // 생성 일시
  createdAt: string;
  // 수정 일시
  updatedAt: string;
  // 삭제 일시
  deletedAt: string;
  // 아이템 코드
  itemCode: string;
  // 아이템 코드에 대한 설명
  description?: string;
  // 아이템 코드 활성화 여부
  isActivated: boolean;
  // 메인 카테고리
  mainCategory: string;
  // 서브 카테고리
  subCategory: string;
  // 아이템
  item: string;
  // ExporterItem의 ID(PK)
  exporterItemId: number;
}
