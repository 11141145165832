import { HTMLAttributes, InputHTMLAttributes, ReactNode } from "react";
import colorSet, { ColorType } from "@/src/styles/color";
import styled from "styled-components";
import RadioCheckedSvg from "@/src/assets/icons/icon-radio-checked-blue4.svg";
import RadioUnCheckedSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import Typo from "../../atom/Typo";

function GridCardRadioContainer(props: HTMLAttributes<HTMLDivElement>) {
  return <StyledDiv {...props} />;
}

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

export interface GridCardRadioProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  value: any;
  gridSpan?: number;
  defaultBackgroundColor?: ColorType;
  checkedBackgroundColor?: ColorType;
  checkedBoxShadowColor?: ColorType;
}

function GridCardRadioItem({
  label,
  value,
  gridSpan = 1,
  checkedBackgroundColor = "blue10",
  checkedBoxShadowColor = "blue4",
  defaultBackgroundColor = "gray10",
  ...props
}: GridCardRadioProps) {
  const renderLabel = () => {
    if (typeof label === "string") {
      return (
        <Typo typoType="h9" color={props.checked ? "blue4" : "gray6"}>
          {label}
        </Typo>
      );
    }

    return label;
  };

  return (
    <StyledLabel
      id={value}
      data-isChecked={props.checked}
      gridSpan={gridSpan}
      checkedBackgroundColor={checkedBackgroundColor}
      checkedBoxShadowColor={checkedBoxShadowColor}
      defaultBackgroundColor={defaultBackgroundColor}
    >
      {renderLabel()}

      <StyledRadio type="radio" {...props} value={value} id={value} />
    </StyledLabel>
  );
}

const GridCardRadio = Object.assign(
  {},
  {
    Container: GridCardRadioContainer,
    Radio: GridCardRadioItem,
  }
);

export default GridCardRadio;

const StyledLabel = styled.label<{
  gridSpan: number;
  checkedBackgroundColor: ColorType;
  checkedBoxShadowColor: ColorType;
  defaultBackgroundColor: ColorType;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  grid-column: span ${({ gridSpan }) => gridSpan};
  background: ${({ defaultBackgroundColor }) =>
    colorSet[defaultBackgroundColor]};
  border-radius: 8px;
  padding: 18px 16px;
  cursor: pointer;
  user-select: none;

  &[data-isChecked="true"] {
    background: ${({ checkedBackgroundColor }) =>
      colorSet[checkedBackgroundColor]};
    box-shadow: ${({ checkedBoxShadowColor }) =>
      `0 0 0 2px ${colorSet[checkedBoxShadowColor]} inset`};
  }
`;

const StyledRadio = styled.input`
  width: 20px;
  height: 20px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${RadioUnCheckedSvg}) no-repeat;
  background-position: center;

  &:checked {
    background: url(${RadioCheckedSvg}) no-repeat;
  }

  &:focus-within {
    outline: none;
  }
`;
