import React from "react";
import styled from "styled-components";
import Badge from "@/src/components/atom/Badge";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import colorSet from "@/src/styles/color";
import ClockSvg from "@/src/assets/icons/icon-clock-gray5.svg";
import { BookingProgressDto } from "@/src/store/apis/shipments/importerShipment/interface";
import dayjs from "dayjs";

interface ImminentShipmentCardProps {
  className?: string;
  shipmentData: BookingProgressDto;
  onClick?: () => void;
}

const ImminentShipmentCard = ({
  className,
  shipmentData,
  onClick,
}: ImminentShipmentCardProps) => {
  return (
    <Article className={className} onClick={onClick}>
      <Flex justifyContent="space-between" alignItems="center">
        <Badge badgeColor="systemBlue2" color="white" badgeSize="S">
          <Typo typoType="btn5m" color="white">
            D-Day
          </Typo>
        </Badge>

        <Flex gap={8} alignItems="center">
          <Flex gap={4} alignItems="center">
            <Icon iconSrc={ClockSvg} iconSize={16} />
            <Typo typoType="b9m" color="gray5">
              ATA
            </Typo>
          </Flex>
          <Typo typoType="h9" color="blue2">
            {shipmentData.ataAt
              ? dayjs(shipmentData.ataAt).format("YYYY-MM-DD")
              : ""}
          </Typo>
        </Flex>
      </Flex>

      <Divider />

      <Flex flexDirection="column" gap={4}>
        <Typo typoType="h4">B/L No. {shipmentData.blNo || "-"}</Typo>
        <p>
          <Typo typoType="b9r">{shipmentData.buyerNameCode}</Typo>{" "}
          <Typo color="gray8">|</Typo> <Typo typoType="b9r">Item</Typo>{" "}
          <Typo typoType="h9" color="blue4">
            {shipmentData.itemCode}
          </Typo>
        </p>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Typo typoType="b9r">Total Net Weight</Typo>
        <Typo typoType="b9r">{shipmentData.totalNetWeight}</Typo>
      </Flex>
    </Article>
  );
};

export default ImminentShipmentCard;

const Article = styled.article`
  width: 300px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${colorSet.blue10};
  border: 1px solid ${colorSet.blue9};
  border-radius: 16px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${colorSet.blue9};
`;
