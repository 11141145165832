import { RouteObject } from "react-router-dom";
import PUBLIC_PATH from "./path";
import ProtectedPublicRoute from "@/components/template/ProtectedPublicRoute";
import CorporateSignupPage from "@/pages/public/CorporateSignup";
import FindPasswordPage from "@/src/pages/public/FindPassword";
import LoginPage from "@/src/pages/public/Login";
import SignUpPage from "@/pages/public/Signup";
import StaffSignupPage from "@/pages/public/StaffSignup";
import TermsPage from "@/pages/public/Terms";
import PublicNotFoundPage from "@/pages/public/NotFound";

const publicRoutes: RouteObject[] = [
  {
    path: PUBLIC_PATH.ROOT,
    element: <ProtectedPublicRoute />,
    children: [
      {
        path: PUBLIC_PATH.LOGIN,
        element: <LoginPage />,
      },
      {
        path: PUBLIC_PATH.SIGNUP,
        element: <SignUpPage />,
      },
      {
        path: PUBLIC_PATH.FIND_PASSWORD,
        element: <FindPasswordPage />,
      },
      {
        path: PUBLIC_PATH.CORP_SIGNUP,
        element: <CorporateSignupPage />,
      },
      {
        path: PUBLIC_PATH.STAFF_SIGNUP,
        element: <StaffSignupPage />,
      },
    ],
  },
  {
    path: PUBLIC_PATH.TERMS,
    element: <TermsPage />,
  },
  {
    path: PUBLIC_PATH.NOT_FOUND,
    element: <PublicNotFoundPage />,
  },
];

export default publicRoutes;
