import { PurchaseAndSalesInfoDto } from "@/src/store/apis/dashboard/importer/interface";
import dayjs from "dayjs";

export const getMonthlyWeightSumsByCategory = <
  T extends { ataDateAt?: string } & Record<string, any>
>({
  list,
  ataAtTo,
}: {
  list: T[];
  ataAtTo: string;
}) => {
  const startDate = dayjs(ataAtTo).subtract(11, "month");
  const months: string[] = []; // 월 이름 배열

  // 12개의 월 이름을 배열에 추가
  for (let i = 0; i < 12; i++) {
    const currentMonth = startDate.add(i, "month");
    months.push(currentMonth.format("MMMM").toLocaleLowerCase());
  }

  const result: Record<string, any>[] = [];

  // 각 mainCategory별로 월별 sumOfWeight의 합산 계산
  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt);
    const mainCategory = item.mainCategory;
    const monthName = ataDateAt.format("MMMM").toLocaleLowerCase();

    // 결과 배열에 mainCategory가 없으면 추가
    if (!result.find((entry) => entry.mainCategory === mainCategory)) {
      const categoryData: Record<string, any> = { mainCategory };
      months.forEach((month) => {
        categoryData[month] = 0;
      });
      result.push(categoryData);
    }

    // 해당 월의 합산에 sumOfWeight 추가
    const categoryData = result.find(
      (entry) => entry.mainCategory === mainCategory
    ) as Record<string, any>;
    categoryData[monthName] += item.sumOfWeight;
  });

  return result ?? [];
};

// 주어진 기간 동안 각 mainCategory의 일별 무게 합계를 계산합니다.
export const getDailyWeightSumsByCategory = <
  T extends {
    ataDateAt?: string;
    mainCategory: string;
    sumOfWeight: number;
  } & Record<string, any>
>({
  list,
  ataAtFrom,
  ataAtTo,
}: {
  list: T[];
  ataAtFrom: string;
  ataAtTo: string;
}) => {
  const categories: Record<string, any>[] = [];

  // 날짜 범위의 마지막 날짜를 포함하도록 범위 설정
  const ataAtToEndOfDay = dayjs(ataAtTo).endOf("day");

  // 리스트를 순회하며 합산
  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt);
    const mainCategory = item.mainCategory;

    // 범위 내의 데이터만 포함
    if (ataDateAt.isBetween(ataAtFrom, ataAtToEndOfDay, null, "[]")) {
      const dateKey = ataDateAt.format("MM-DD"); // MM-DD 형식 사용

      // 기존 카테고리 데이터를 찾거나 새로 생성
      let category = categories.find(
        (category) => category.mainCategory === mainCategory
      );

      if (!category) {
        category = { mainCategory };
        categories.push(category);
      }

      // 합산 로직
      if (!category[dateKey]) {
        category[dateKey] = 0;
      }

      category[dateKey] += item.sumOfWeight;
    }
  });

  // 범위 내 모든 날짜를 채우기 위해서 초기화
  categories.forEach((category: { [x: string]: number }) => {
    for (
      let dateDiff = 0;
      dateDiff <= dayjs(ataAtTo).diff(ataAtFrom, "day");
      dateDiff++
    ) {
      const currentDate = dayjs(ataAtFrom).add(dateDiff, "day");
      const dateKey = currentDate.format("MM-DD"); // MM-DD 형식 사용

      if (!(dateKey in category)) {
        category[dateKey] = 0;
      }
    }
  });

  return categories;
};

export type PurchaseAndSalesRowData = {
  difference: string;
  [key: string]: number | string;
};

// 주어진 기간 동안의 월별 매입, 매출, 이익, 이익률을 계산합니다.
export const getMonthlyPurchaseAndSales = ({
  list,
  ataAtTo,
}: {
  list: PurchaseAndSalesInfoDto[];
  ataAtTo: string;
}): PurchaseAndSalesRowData[] => {
  const startDate = dayjs(ataAtTo).subtract(11, "month");
  const months: string[] = [];

  // 12개의 월 이름을 배열에 추가
  for (let i = 0; i < 12; i++) {
    const currentMonth = startDate.add(i, "month");
    months.push(currentMonth.format("MMMM").toLocaleLowerCase());
  }

  const monthlyPurchases: Record<string, number> = {};
  const monthlySales: Record<string, number> = {};
  months.forEach((month) => {
    monthlyPurchases[month] = 0;
    monthlySales[month] = 0;
  });

  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt);
    const monthName = ataDateAt.format("MMMM").toLocaleLowerCase();

    if (monthlyPurchases[monthName] !== undefined) {
      monthlyPurchases[monthName] += item.purchases;
      monthlySales[monthName] += item.sales;
    }
  });

  const monthlyProfit: Record<string, number> = {};
  const monthlyProfitRate: Record<string, number> = {};

  months.forEach((month) => {
    monthlyProfit[month] = monthlySales[month] - monthlyPurchases[month];

    monthlyProfitRate[month] = monthlySales[month]
      ? (monthlyProfit[month] / monthlySales[month]) * 100
      : 0;
  });

  const result: PurchaseAndSalesRowData[] = [
    { difference: "매입액", ...monthlyPurchases },
    { difference: "매출액", ...monthlySales },
    { difference: "이익", ...monthlyProfit },
    { difference: "이익률", ...monthlyProfitRate },
  ];

  return result;
};

// 주어진 기간 동안의 일별 매입, 매출, 이익, 이익률을 계산합니다.
export const getDailyPurchaseAndSales = ({
  list,
  ataAtFrom,
  ataAtTo,
}: {
  list: PurchaseAndSalesInfoDto[];
  ataAtFrom: string;
  ataAtTo: string;
}): PurchaseAndSalesRowData[] => {
  const dailyPurchases: Record<string, number> = {};
  const dailySales: Record<string, number> = {};
  const dateDiff = dayjs(ataAtTo).diff(ataAtFrom, "day") + 1;

  for (let i = 0; i < dateDiff; i++) {
    const currentDate = dayjs(ataAtFrom).add(i, "day");
    const dateKey = currentDate.format("MM-DD");
    dailyPurchases[dateKey] = 0;
    dailySales[dateKey] = 0;
  }

  list.forEach((item) => {
    const ataDateAt = dayjs(item.ataDateAt);
    const dateKey = ataDateAt.format("MM-DD");

    if (dailyPurchases[dateKey] !== undefined) {
      dailyPurchases[dateKey] += item.purchases;
      dailySales[dateKey] += item.sales;
    }
  });

  const dailyProfit: Record<string, number> = {};
  const dailyProfitRate: Record<string, number> = {};

  for (let i = 0; i < dateDiff; i++) {
    const currentDate = dayjs(ataAtFrom).add(i, "day");
    const dateKey = currentDate.format("MM-DD");
    dailyProfit[dateKey] = dailySales[dateKey] - dailyPurchases[dateKey];

    // 이 부분을 수정하여 매출액을 기준으로 이익률 계산
    dailyProfitRate[dateKey] = dailySales[dateKey]
      ? (dailyProfit[dateKey] / dailySales[dateKey]) * 100
      : 0;
  }

  const result: PurchaseAndSalesRowData[] = [
    { difference: "매입액", ...dailyPurchases },
    { difference: "매출액", ...dailySales },
    { difference: "이익", ...dailyProfit },
    { difference: "이익률", ...dailyProfitRate },
  ];

  return result;
};
