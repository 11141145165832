export const imageFullDownload = async ({
  response,
  imageListFileName = "container.zip",
}: {
  response: Response;
  imageListFileName?: string;
}) => {
  const fileName =
    response.headers.get("Content-Disposition")?.split("filename=")?.[1] ??
    imageListFileName;
  const url = window.URL.createObjectURL(await response.blob());
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
