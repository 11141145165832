import { css } from "styled-components";

export const lineClamp = (lineClamp: number = 2) => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${lineClamp};
    -webkit-box-orient: vertical;
  `;
};
