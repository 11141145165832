import { Ref, forwardRef } from "react";
import { DatePicker as AntdDatePicker, ConfigProvider, TimePicker } from "antd";
import { Dayjs } from "dayjs";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import styled from "styled-components";
import SuffixSvg from "../../../assets/icons/icon-datepicker-surffix.svg";
import colorSet from "@/src/styles/color";

export interface DatePickerProps extends PickerDateProps<Dayjs> {}

const DatePicker = forwardRef(
  ({ ...props }: DatePickerProps, ref: Ref<any>) => {
    return (
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              colorPrimary: colorSet.blue4,
              colorText: colorSet.gray2,
            },
          },
        }}
      >
        <StyledAntdDatePicker
          ref={ref}
          suffixIcon={<SuffixIcon src={SuffixSvg} alt="suffixIcon" />}
          placeholder={props.placeholder ?? "YYYY-MM-DD"}
          format={props.format ?? "YYYY-MM-DD"}
          allowClear={false}
          {...props}
        />
      </ConfigProvider>
    );
  }
);

export default DatePicker;

const StyledAntdDatePicker = styled(AntdDatePicker)`
  width: auto;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  padding: 8px 16px;

  &.ant-picker-focused {
    border: 1px solid ${colorSet.blue1};

    &:hover {
      border: 1px solid ${colorSet.blue1};
      box-shadow: none;
    }
  }

  &:hover {
    outline: none;
    border: 1px solid ${colorSet.gray9};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
      0px 0px 0px 3px rgba(24, 123, 235, 0.2), 0px 0px 0px 1px #1479eb;
  }

  &:disabled {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }
`;

const SuffixIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  padding: 8px 16px;

  &.ant-picker-focused {
    border: 1px solid ${colorSet.blue1};

    &:hover {
      border: 1px solid ${colorSet.blue1};
      box-shadow: none;
    }
  }

  &:hover {
    outline: none;
    border: 1px solid ${colorSet.gray9};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
      0px 0px 0px 3px rgba(24, 123, 235, 0.2), 0px 0px 0px 1px #1479eb;
  }

  &:disabled {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }

  .ant-picker-clear {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
