import { useGetShipmentDetailContractBookingQuery } from "@/src/store/apis/shipments/shipmentDetail";
import React from "react";
import { useParams } from "react-router-dom";
import BookingContractCardItem from "./BookingContractCardItem";
import { ShipmentDetailContractBookingListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { isUndefined } from "@/src/utils/is";

const bookingWithContractListEmptyArray: ShipmentDetailContractBookingListViewDto[] =
  [];

function BookingContractCard() {
  const params = useParams();
  const { bookingWithContractList } = useGetShipmentDetailContractBookingQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          bookingWithContractList: !isUnstable
            ? currentData.rows ?? bookingWithContractListEmptyArray
            : bookingWithContractListEmptyArray,
        };
      },
    }
  );

  return (
    <>
      {bookingWithContractList.map((data, rowsIdx) => {
        return (
          <BookingContractCardItem
            data={data}
            index={rowsIdx}
            key={rowsIdx.toString()}
          />
        );
      })}
    </>
  );
}

export default BookingContractCard;
