import { CiInfoDto } from "@/src/store/apis/shipments/ci/interface";
import { PlInfoDto } from "@/src/store/apis/shipments/pl/interface";

export const ciDefaultValue: CiInfoDto = {
  ciStatus: undefined,
  shipper: undefined,
  shipperTitle: undefined,
  consignee: undefined,
  consigneeTitle: undefined,
  notifyParty: undefined,
  notifyPartyTitle: undefined,
  isNotifyPartyHidden: true,
  isNotifyPartySameAsConsignee: false,
  invoiceDateAt: undefined,
  invoicoNoAndDateTitle: undefined,
  poNoTitle: undefined,
  isPoNoHidden: true,
  scNoTitle: undefined,
  isScNoHidden: true,
  lcInfoList: [],
  lcNoAndDateTitle: undefined,
  remark: undefined,
  remarkTitle: undefined,
  isRemarkHidden: false,
  portOfLoading: undefined,
  portOfLoadingCodeItemName: undefined,
  portOfLoadingTitle: undefined,
  isPortOfLoadingHidden: false,
  portOfDischarge: undefined,
  portOfDischargeCodeItemName: undefined,
  portOfDischargeTitle: undefined,
  isPortOfDischargeHidden: false,
  placeOfDelivery: undefined,
  placeOfDeliveryCodeItemName: undefined,
  placeOfDeliveryTitle: undefined,
  isPlaceOfDeliveryHidden: false,
  vessel: undefined,
  voyageNo: undefined,
  isVesselAndVoyageNoHidden: false,
  etdAt: undefined,
  etdTitle: undefined,
  isEtdHidden: false,
  paymentTerms: undefined,
  paymentTermsTitle: undefined,
  isPaymentTermsHidden: false,
  shippingTerms: undefined,
  shippingTermsTitle: undefined,
  isShippingTermsHidden: false,
  invoiceNo: "",
  businessLogoSimpleMedia: undefined,
  poNoList: [],
  scNoList: [],
  isLcInfoHidden: false,
  vesselAndVoyageNoTitle: "",
  numberOfContainerTypeA: "",
  numberOfContainerTypeB: "",
  simpleSignatureMedias: undefined,
  shipmentDocumentType: "ORIGINAL",
  bankName: undefined,
  bankTel: undefined,
  bankFax: undefined,
  swiftCode: undefined,
  accountNumber: undefined,
  accountName: undefined,
  ciItems: [],
  totalQuantity: "",
  totalGrossWeight: "",
  totalNetWeight: "",
  totalAmount: "",
  bankAddress: undefined,
  isBankDetailHidden: false,
};

export const plDefaultValue: PlInfoDto = {
  invoiceNo: "",
  plStatus: undefined,
  shipper: undefined,
  shipperTitle: undefined,
  consignee: undefined,
  consigneeTitle: undefined,
  notifyParty: undefined,
  notifyPartyTitle: undefined,
  isNotifyPartySameAsConsignee: false,
  isNotifyPartyHidden: false,
  invoiceDateAt: undefined,
  invoicoNoAndDateTitle: undefined,
  poNoTitle: undefined,
  isPoNoHidden: false,
  scNoTitle: undefined,
  isScNoHidden: false,
  lcInfoList: [],
  lcNoAndDateTitle: undefined,
  remark: undefined,
  remarkTitle: undefined,
  isRemarkHidden: false,
  portOfLoading: undefined,
  portOfLoadingCodeItemName: undefined,
  portOfLoadingTitle: undefined,
  isPortOfLoadingHidden: false,
  portOfDischarge: undefined,
  portOfDischargeCodeItemName: undefined,
  portOfDischargeTitle: undefined,
  isPortOfDischargeHidden: false,
  placeOfDelivery: undefined,
  placeOfDeliveryCodeItemName: undefined,
  placeOfDeliveryTitle: undefined,
  isPlaceOfDeliveryHidden: false,
  vessel: undefined,
  voyageNo: undefined,
  isVesselAndVoyageNoHidden: false,
  etdAt: undefined,
  etdTitle: undefined,
  isEtdHidden: false,
  paymentTerms: undefined,
  paymentTermsTitle: undefined,
  isPaymentTermsHidden: false,
  shippingTerms: undefined,
  shippingTermsTitle: undefined,
  isShippingTermsHidden: false,
  shipmentDocumentType: "ORIGINAL",
  poNoList: [],
  scNoList: [],
  isLcInfoHidden: false,
  vesselAndVoyageNoTitle: "",
  plItems: [],
  numberOfContainerTypeA: "",
  numberOfContainerTypeB: "",
  simpleSignatureMedias: undefined,
};
