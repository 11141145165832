import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import { AlarmDto, GetAlarmsRequest, ModifyAlarmDto } from "./interface";

export const alarmsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 알림 목록 조회
    getAlarms: build.query<Rows<AlarmDto>, GetAlarmsRequest>({
      query: (params) => ({
        url: "/exporter-users/this/alarms",
        params,
      }),
    }),

    // 알림 단일 수정 API
    updateAlarm: build.mutation<
      Rows<AlarmDto>,
      ModifyAlarmDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/exporter-users/this/alarms/${id}`,
        body,
      }),
    }),
  }),
});

export const { useGetAlarmsQuery, useUpdateAlarmMutation } = alarmsApi;
