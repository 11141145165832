import { css } from "styled-components";

const BOLD_FONT_WEIGHT = 700;
const SEMI_BOLD_FONT_WEIGHT = 600;
const MEDIUM_FONT_WEIGHT = 500;
const REGULAR_FONT_WEIGHT = 400;

type FontType =
  | "display"
  | "header"
  | "body_medium"
  | "body_regular"
  | "button_medium"
  | "button_semibold"
  | "label";

const getFontWeight = (fontType: FontType) => {
  switch (fontType) {
    case "display":
    case "header":
      return css`
        font-family: "Pretendard Bold";
        font-weight: ${BOLD_FONT_WEIGHT};
      `;
    case "button_semibold":
      return css`
        font-family: "Pretendard SemiBold";
        font-weight: ${SEMI_BOLD_FONT_WEIGHT};
      `;
    case "label":
    case "button_medium":
    case "body_medium":
      return css`
        font-family: "Pretendard Medium";
        font-weight: ${MEDIUM_FONT_WEIGHT};
      `;
    case "body_regular":
      return css`
        font-family: "Pretendard Regular";
        font-weight: ${REGULAR_FONT_WEIGHT};
      `;
  }
};

const typo = {
  // Display
  d1: css`
    ${getFontWeight("display")}
    font-size: 48px;
    line-height: 56px;
  `,
  d2: css`
    ${getFontWeight("display")}
    font-size: 40px;
    line-height: 52px;
  `,
  d3: css`
    ${getFontWeight("display")}
    font-size: 36px;
    line-height: 42px;
  `,
  d4: css`
    ${getFontWeight("display")}
    font-size: 32px;
    line-height: 42px;
  `,
  d5: css`
    ${getFontWeight("display")}
    font-size: 28px;
    line-height: 38px;
  `,
  d6: css`
    ${getFontWeight("display")}
    font-size: 24px;
    line-height: 38px;
  `,
  // header
  h1: css`
    ${getFontWeight("header")}
    font-size: 26px;
    line-height: 38px;
  `,
  h2: css`
    ${getFontWeight("header")}
    font-size: 24px;
    line-height: 36px;
  `,
  h3: css`
    ${getFontWeight("header")}
    font-size: 22px;
    line-height: 34px;
  `,
  h4: css`
    ${getFontWeight("header")}
    font-size: 20px;
    line-height: 30px;
  `,
  h5: css`
    ${getFontWeight("header")}
    font-size: 19px;
    line-height: 26px;
  `,
  h6: css`
    ${getFontWeight("header")}
    font-size: 17px;
    line-height: 24px;
  `,
  h7: css`
    ${getFontWeight("header")}
    font-size: 16px;
    line-height: 24px;
  `,
  h8: css`
    ${getFontWeight("header")}
    font-size: 15px;
    line-height: 20px;
  `,
  h9: css`
    ${getFontWeight("header")}
    font-size: 14px;
    line-height: 18px;
  `,
  h10: css`
    ${getFontWeight("header")}
    font-size: 13px;
    line-height: 18px;
  `,
  h11: css`
    ${getFontWeight("header")}
    font-size: 11px;
    line-height: 16px;
  `,

  // Body medium
  b1m: css`
    ${getFontWeight("body_medium")}
    font-size: 26px;
    line-height: 38px;
  `,
  b2m: css`
    ${getFontWeight("body_medium")}
    font-size: 24px;
    line-height: 36px;
  `,
  b3m: css`
    ${getFontWeight("body_medium")}
    font-size: 22px;
    line-height: 34px;
  `,
  b4m: css`
    ${getFontWeight("body_medium")}
    font-size: 20px;
    line-height: 30px;
  `,
  b5m: css`
    ${getFontWeight("body_medium")}
    font-size: 19px;
    line-height: 26px;
  `,
  b6m: css`
    ${getFontWeight("body_medium")}
    font-size: 17px;
    line-height: 24px;
  `,
  b7m: css`
    ${getFontWeight("body_medium")}
    font-size: 16px;
    line-height: 24px;
  `,
  b8m: css`
    ${getFontWeight("body_medium")}
    font-size: 15px;
    line-height: 20px;
  `,
  b9m: css`
    ${getFontWeight("body_medium")}
    font-size: 14px;
    line-height: 18px;
  `,
  b10m: css`
    ${getFontWeight("body_medium")}
    font-size: 13px;
    line-height: 18px;
  `,
  b11m: css`
    ${getFontWeight("body_medium")}
    font-size: 11px;
    line-height: 16px;
  `,

  // Body regular
  b1r: css`
    ${getFontWeight("body_regular")}
    font-size: 26px;
    line-height: 38px;
  `,
  b2r: css`
    ${getFontWeight("body_regular")}
    font-size: 24px;
    line-height: 36px;
  `,
  b3r: css`
    ${getFontWeight("body_regular")}
    font-size: 22px;
    line-height: 34px;
  `,
  b4r: css`
    ${getFontWeight("body_regular")}
    font-size: 20px;
    line-height: 30px;
  `,
  b5r: css`
    ${getFontWeight("body_regular")}
    font-size: 19px;
    line-height: 26px;
  `,
  b6r: css`
    ${getFontWeight("body_regular")}
    font-size: 17px;
    line-height: 24px;
  `,
  b7r: css`
    ${getFontWeight("body_regular")}
    font-size: 16px;
    line-height: 24px;
  `,
  b8r: css`
    ${getFontWeight("body_regular")}
    font-size: 15px;
    line-height: 20px;
  `,
  b9r: css`
    ${getFontWeight("body_regular")}
    font-size: 14px;
    line-height: 18px;
  `,
  b10r: css`
    ${getFontWeight("body_regular")}
    font-size: 13px;
    line-height: 18px;
  `,
  b11r: css`
    ${getFontWeight("body_regular")}
    font-size: 11px;
    line-height: 16px;
  `,

  // button_semibold
  btn1sb: css`
    ${getFontWeight("button_semibold")};
    font-size: 19px;
    line-height: 26px;
  `,
  btn2sb: css`
    ${getFontWeight("button_semibold")};
    font-size: 17px;
    line-height: 24px;
  `,
  btn3sb: css`
    ${getFontWeight("button_semibold")};
    font-size: 15px;
    line-height: 20px;
  `,
  btn4sb: css`
    ${getFontWeight("button_semibold")};
    font-size: 13px;
    line-height: 18px;
  `,
  btn5sb: css`
    ${getFontWeight("button_semibold")};
    font-size: 11px;
    line-height: 16px;
  `,

  // button_medium
  btn1m: css`
    ${getFontWeight("button_medium")};
    font-size: 19px;
    line-height: 26px;
  `,
  btn2m: css`
    ${getFontWeight("button_medium")};
    font-size: 17px;
    line-height: 24px;
  `,
  btn3m: css`
    ${getFontWeight("button_medium")};
    font-size: 15px;
    line-height: 20px;
  `,
  btn4m: css`
    ${getFontWeight("button_medium")};
    font-size: 13px;
    line-height: 18px;
  `,
  btn5m: css`
    ${getFontWeight("button_medium")};
    font-size: 11px;
    line-height: 16px;
  `,

  // label
  label1: css`
    ${getFontWeight("label")}
    font-size: 15px;
    line-height: 16px;
  `,
  label2: css`
    ${getFontWeight("label")}
    font-size: 13px;
    line-height: 14px;
  `,
  label3: css`
    ${getFontWeight("label")}
    font-size: 11px;
    line-height: 12px;
  `,
};

export default typo;

type FontSizeType = keyof typeof typo;
export { type FontSizeType };
