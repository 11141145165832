import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../../baseQuery";
import { Rows } from "../../type";
import {
  BookingContractListViewDto,
  ContractListViewDto,
  ContractListViewForImporterDto,
  GetContractListRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list"],
});

const contractApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 계약 목록 조회
    getContractList: build.query<
      Rows<ContractListViewDto>,
      GetContractListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/contracts",
        params,
      }),
      providesTags: ["list"],
    }),

    getContractListV2: build.query<
      Rows<ContractListViewDto>,
      GetContractListRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/contracts",
        params,
      }),
      providesTags: ["list"],
    }),

    getImporterContractList: build.query<
      Rows<ContractListViewForImporterDto>,
      GetContractListRequest
    >({
      query: (params) => ({
        url: "/importers/this/contracts",
        params,
      }),
    }),

    // 부킹과 연결된 계약 목록을 조회하는 API
    getContractListAssociatedWithBooking: build.query<
      Rows<BookingContractListViewDto>,
      {
        id: number;
        contractStatus?: "PROCESSING" | "COMPLETE";
        buyerNameCode?: string;
        page?: number;
        pageSize?: number;
        isExporterUserMainCategoryFilterOn?: boolean;
      }
    >({
      query: ({ id, ...restQuery }) => ({
        url: `exporters/this/bookings/${id}/contracts`,
        params: { isExporterUserMainCategoryFilterOn: true, ...restQuery },
      }),
    }),

    // 계약 리스트를 엑셀로 다운로드 하는 API 입니다
    getContractExcelList: build.query<void, GetContractListRequest>({
      query: (params) => ({
        url: "/exporters/this/contracts-excel",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "contract_list.xlsx",
          });
        },
      }),
    }),

    // 수입자 계약 리스트를 엑셀로 다운로드 하는 API 입니다
    getImporterContractExcelList: build.query<void, GetContractListRequest>({
      query: (params) => ({
        url: "/importers/this/contracts-excel",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "contract_list.xlsx",
          });
        },
      }),
    }),
  }),
});

export const {
  useLazyGetContractListQuery,
  useLazyGetContractListV2Query,
  useGetContractListV2Query,
  useLazyGetImporterContractListQuery,
  useGetImporterContractListQuery,
  useLazyGetContractListAssociatedWithBookingQuery,
  useLazyGetContractExcelListQuery,
  useLazyGetImporterContractExcelListQuery,
} = contractApi;
export { contractApi };
