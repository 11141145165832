import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import React, {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useState,
} from "react";
import { css, styled } from "styled-components";
import Tag from "../Tag";
import { InputError } from "../../molecule/FormItem";

interface MultipleInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  valueList: string[];
  onChangeValue: (value: string) => void;
  onChangeValueList: (value: string[]) => void;
  handleKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  tagColor?: ColorType;
  tagBackgroundColor?: ColorType;
  validation?: (value: string) => void;
  isError?: boolean;
  errorMessage?: ReactNode;
}

const MultipleInput = forwardRef<HTMLInputElement, MultipleInputProps>(
  (
    {
      value,
      valueList,
      onChangeValue,
      onChangeValueList,
      tagColor,
      tagBackgroundColor,
      handleKeyUp,
      validation,
      isError,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);

    const handleValueSelect = () => {
      if (value.trim() !== "") {
        onChangeValueList([
          ...valueList,
          ...value.split(",").map((item) => item.trim()),
        ]);
        onChangeValue("");
      }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue(event.target.value);
    };

    const handleKeyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (event.key === "Enter") {
        handleValueSelect();
      }

      if (event.key === "Backspace") {
        if (value === "" && valueList.length > 0) {
          onChangeValueList(valueList.slice(0, -1));
        }

        if (value.length === 1 && valueList.length >= 1) {
          onChangeValue("");
        }
      }
    };

    const handleTagCloseClick = (select: string) => {
      const filter = [...valueList].filter((item) => item !== select);

      return onChangeValueList(filter);
    };

    return (
      <MultipleInputContainer>
        <BorderContainer
          data-disabled={props.disabled}
          data-invalid={isError}
          isFocus={isFocus}
          onBlur={() => handleValueSelect()}
        >
          <StyledTagContainer>
            {valueList.map((value, index) => {
              const isValidation = validation ? validation(value) : true;

              return (
                <Tag
                  key={index}
                  label={value}
                  onRemove={() => handleTagCloseClick(value)}
                  color={isValidation ? tagColor : "red2"}
                  tagColor={isValidation ? tagBackgroundColor : "red8"}
                  isValid={isValidation ? true : false}
                />
              );
            })}

            <Input
              ref={ref}
              type="text"
              value={value}
              onChange={handleInputChange}
              onKeyUp={handleKeyUpEvent}
              isSelectedValues={valueList.length >= 1}
              data-disabled={props.disabled}
              onFocus={() => setIsFocus(true)}
              onBlur={() => {
                setIsFocus(false);
              }}
              {...props}
            />
          </StyledTagContainer>
        </BorderContainer>
        {isError && <InputError message={errorMessage} />}
      </MultipleInputContainer>
    );
  }
);

export default MultipleInput;

const MultipleInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BorderContainer = styled.div<{ isFocus: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  padding: 6px 16px;
  min-height: 40px;
  height: 100%;
  border: 1px solid ${colorSet.gray9};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  ${typo.b7r};
  color: ${colorSet.gray2};
  transition: all 0.2s;

  &[data-invalid="true"] {
    border: 1px solid ${colorSet.red2};
  }

  &[data-disabled="true"] {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    color: ${colorSet.gray7};
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px #1479eb, 0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  ${({ isFocus }) =>
    isFocus &&
    css`
      border: 1px solid ${colorSet.blue1};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

      &:hover {
        box-shadow: none;
      }
    `};
`;

const Input = styled.input<{ isSelectedValues: boolean }>`
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  transition: all 0.2s;

  ${typo.b7r};

  &::placeholder {
    opacity: ${({ isSelectedValues }) => isSelectedValues && 0};
    color: ${colorSet.gray7};
  }

  &[data-disabled="true"] {
    background: none;
    border: none;
    box-shadow: none;
    pointer-events: none;
    color: ${colorSet.gray7};
  }
`;

const StyledTagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;
  flex: 1;
`;
