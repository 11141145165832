import colorSet from "@/src/styles/color";
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";
import { css, styled } from "styled-components";
import Loader from "../Loader";
import typo from "@/src/styles/typography";

export type ButtonColorType = "blue" | "red" | "black";
export type ButtonSizeType = 40 | 32 | 24;
export type ButtonGradeType = "primary" | "secondary" | "tertiary";
export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  buttonColor?: ButtonColorType;
  buttonSize?: ButtonSizeType;
  buttonGrade?: ButtonGradeType;
  isIconOnly?: boolean;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonColor = "blue",
      buttonSize = 40,
      buttonGrade = "primary",
      isIconOnly = false,
      isLoading = false,
      children,
      onClick,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledBasicButton
        ref={ref}
        type="button"
        $buttonColor={buttonColor}
        $buttonSize={buttonSize}
        $buttonGrade={buttonGrade}
        $isIconOnly={isIconOnly}
        $isLoading={isLoading}
        data-loading={isLoading}
        aria-disabled={restProps.disabled}
        data-disabled={restProps.disabled}
        onClick={(e) => {
          if (!isLoading) {
            onClick?.(e);
          }
        }}
        {...restProps}
      >
        {isLoading ? (
          <Loader
            size={buttonSize === 40 ? 20 : buttonSize === 32 ? 18 : 14}
            tickColor={
              buttonGrade === "primary" ? colorSet.white : colorSet.blue4
            }
            trackColor={
              buttonGrade === "primary"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(22, 109, 215, 0.2);"
            }
          />
        ) : (
          children
        )}
      </StyledBasicButton>
    );
  }
);

export default Button;

export const getPrimaryButtonColor = (color: ButtonColorType) => {
  switch (color) {
    case "blue":
      return css`
        color: ${colorSet.white};
        background: ${colorSet.indigo};
        box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1),
          0px 0px 0px 0.5px rgba(23, 98, 195, 0.2);

        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 0.5px rgba(23, 98, 195, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 2px rgba(173, 204, 243, 0.6),
            0px 0px 0px 1px rgba(23, 98, 195, 0.6);
        }
        &:disabled {
          background: ${colorSet.gray7};
          color: ${colorSet.white};
          box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1);
        }
      `;
    case "red":
      return css`
        color: ${colorSet.white};
        background: ${colorSet.red2};
        box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1),
          0px 0px 0px 0.5px rgba(214, 76, 76, 0.2);
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 0.5px rgba(214, 76, 76, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 2px rgba(214, 76, 76, 0.2), 0px 0px 0px 1px #d64c4c;
        }
        &:disabled {
          background: ${colorSet.red6};
          box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1),
            0px 0px 0px 0.5px rgba(214, 76, 76, 0.2);
        }
      `;
  }
};

export const getSecondaryButtonColor = (color: ButtonColorType) => {
  switch (color) {
    case "blue":
      return css`
        color: ${colorSet.indigo};
        background: ${colorSet.blue10};
        box-shadow: 0px 1px 2px 0px rgba(5, 29, 57, 0.1),
          0px 0px 0px 0.5px rgba(23, 98, 195, 0.2);
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 0.5px rgba(23, 98, 195, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 2px 4px 0px rgba(5, 29, 57, 0.2),
            0px 0px 0px 2px rgba(173, 204, 243, 0.6),
            0px 0px 0px 1px rgba(23, 98, 195, 0.6);
        }
        &:disabled {
          background: ${colorSet.gray11};
          color: ${colorSet.gray8};
          box-shadow: 0px 1px 2px rgba(5, 29, 57, 0.1);
        }
      `;
    case "red":
      return css`
        color: ${colorSet.red2};
        background: ${colorSet.red8};
        box-shadow: 0px 0px 0px 0.5px rgba(214, 76, 76, 0.2),
          0px 1px 2px rgba(5, 29, 57, 0.1);
        &:hover {
          box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 0px 0px 1px #d64c4c,
            0px 0px 0px 2px rgba(214, 76, 76, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
        }
        &:disabled {
          color: ${colorSet.red6};
          background: ${colorSet.red8};
          box-shadow: 0px 1px 2px rgba(5, 29, 57, 0.1);
        }
      `;
  }
};

export const getTertiaryButtonColor = (color: ButtonColorType) => {
  switch (color) {
    case "black":
      return css`
        color: ${colorSet.gray2};
        background: ${colorSet.white};
        box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
          0px 1px 2px rgba(5, 29, 57, 0.1);
        &:hover {
          box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 0px 0px 1px rgba(5, 29, 57, 0.6),
            0px 0px 0px 2px rgba(5, 29, 57, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
        }
        &:disabled {
          background: ${colorSet.white};
          color: ${colorSet.gray8};
          box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
            0px 1px 2px rgba(5, 29, 57, 0.1);
        }
      `;
    case "red":
      return css`
        color: ${colorSet.red2};
        background: ${colorSet.white};
        box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
          0px 1px 2px rgba(5, 29, 57, 0.1);
        &:hover {
          box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
          transform: translateY(-2px);
        }
        &:active {
          box-shadow: 0px 0px 0px 1px #d64c4c,
            0px 0px 0px 2px rgba(214, 76, 76, 0.2),
            0px 2px 4px rgba(5, 29, 57, 0.2);
        }
        &:disabled {
          background: ${colorSet.white};
          color: ${colorSet.red6};
          box-shadow: 0px 0px 0px 0.5px rgba(214, 76, 76, 0.2),
            0px 1px 2px rgba(5, 29, 57, 0.1);
        }
      `;
  }
};

export const getButtonPadding = (size: ButtonSizeType) => {
  switch (size) {
    case 40:
      return css`
        padding: 10px 16px;
        ${typo.btn3m}
      `;
    case 32:
      return css`
        padding: 6px 12px;
        ${typo.btn3m}
      `;
    case 24:
      return css`
        padding: 3px 8px;
        ${typo.btn4m}
      `;
  }
};

const StyledBasicButton = styled.button<{
  $buttonColor: ButtonColorType;
  $buttonSize: ButtonSizeType;
  $buttonGrade: ButtonGradeType;
  $isIconOnly: boolean;
  $isLoading: boolean;
}>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.15s;
  &:focus {
    outline-offset: 1px;
    outline: 2px solid ${colorSet.blue5};
  }
  ${({ $buttonSize, $isIconOnly }) =>
    $isIconOnly ? null : getButtonPadding($buttonSize)};
  ${({ $buttonGrade, $buttonColor }) =>
    $buttonGrade === "primary"
      ? getPrimaryButtonColor($buttonColor)
      : $buttonGrade === "secondary"
      ? getSecondaryButtonColor($buttonColor)
      : getTertiaryButtonColor($buttonColor)};
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: wait;
      display: flex;
      align-items: center;
      justify-content: center;
    `};

  &:disabled {
    transform: none;
    cursor: not-allowed;
  }
`;
