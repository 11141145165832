import { Ref, forwardRef } from "react";
import { DatePicker as AntdDatePicker, ConfigProvider } from "antd";
import { Dayjs } from "dayjs";
import styled from "styled-components";
import SuffixSvg from "../../../assets/icons/icon-datepicker-surffix.svg";
import colorSet from "@/src/styles/color";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { utcNow } from "@/src/utils/utcDayjs";
const { RangePicker: AntdRangePicker } = AntdDatePicker;

export interface RangePickerProps extends RangePickerDateProps<Dayjs> {}

const RangePicker = forwardRef(
  ({ picker, ...props }: RangePickerProps, ref: Ref<any>) => {
    return (
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              colorPrimary: colorSet.blue4,
              controlPaddingHorizontal: 16,
              colorText: colorSet.gray1,
            },
          },
        }}
      >
        <StyledAntdDatePicker
          ref={ref}
          suffixIcon={<SuffixIcon src={SuffixSvg} alt="suffixIcon" />}
          placeholder={props.placeholder ?? ["YYYY-MM-DD", "YYYY-MM-DD"]}
          format={props.format ?? "YYYY-MM-DD"}
          allowClear={false}
          {...props}
        />
      </ConfigProvider>
    );
  }
);

export const getTodayDateRange = () => {
  const startDate = utcNow().startOf("day");
  const endDate = utcNow().startOf("day");
  return [startDate, endDate];
};

export const get1MonthDateRange = () => {
  const startDate = utcNow().subtract(1, "M");
  const endDate = utcNow().startOf("day");
  return [startDate, endDate];
};

export const get3MonthDateRange = () => {
  const startDate = utcNow().subtract(3, "M");
  const endDate = utcNow().startOf("day");
  return [startDate, endDate];
};

RangePicker.defaultProps = {
  ranges: {
    // @ts-ignore
    Today: getTodayDateRange(),
    // @ts-ignore
    "1 month": get1MonthDateRange(),
    // @ts-ignore
    "3 month": get3MonthDateRange(),
  },
};

export default RangePicker;

const StyledAntdDatePicker = styled(AntdRangePicker)`
  width: auto;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  padding: 0 16px;

  &.ant-picker-focused {
    border: 1px solid ${colorSet.blue1};

    &:hover {
      border: 1px solid ${colorSet.blue1};
      box-shadow: none;
    }
  }

  &::placeholder {
    color: ${colorSet.gray7};
  }

  &:hover {
    outline: none;
    border: 1px solid ${colorSet.gray9};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
      0px 0px 0px 3px rgba(24, 123, 235, 0.2), 0px 0px 0px 1px #1479eb;
  }

  &:disabled {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }
`;

const SuffixIcon = styled.img`
  width: 16px;
  height: 16px;
`;
