import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import { NoticeDto } from "./interface";

const noticeApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getNotices: build.query<
      Rows<NoticeDto>,
      { page?: number; pageSize?: number }
    >({
      query: ({ page = 1, pageSize = 10 }) => ({
        url: "/notices",
        params: {
          service: "EXPORT_WEB",
          isViewable: true,
          page,
          pageSize,
        },
      }),
    }),

    getNotice: build.query<NoticeDto, { id: number }>({
      query: ({ id }) => ({
        url: `/notices/${id}`,
      }),
      transformResponse: (res: Row<NoticeDto>) => res.row,
    }),
  }),
});

export const { useLazyGetNoticeQuery, useLazyGetNoticesQuery } = noticeApi;
