import React, { useState } from "react";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import { importerShipmentListApi } from "@/src/store/apis/shipments/importerShipment";
import { isUndefined } from "@/src/utils/is";
import { PulseLoader } from "react-spinners";
import colorSet from "@/src/styles/color";
import NotShareSvg from "@/src/assets/icons/illust-not-share.svg";
import Icon from "@/src/components/atom/Icon";
import Flex from "@/src/components/molecule/Flex";
import Typo from "@/src/components/atom/Typo";
import CargoTracking from "@/src/components/organism/CargoTracking";
import { useTranslation } from "react-i18next";

interface ShipmentTrackingCardProps {
  id: number;
}

const ShipmentTrackingCard = ({ id }: ShipmentTrackingCardProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { hasBlNo, isFetching } =
    importerShipmentListApi.endpoints.getBookingShipmentContainer.useQueryState(
      { id },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError;
          const isStable = !isUnstable;

          return {
            hasBlNo: isStable
              ? currentData.rows.some(({ blNo }) => !!blNo)
              : false,
            isFetching,
          };
        },
      }
    );
  return (
    <>
      <SectionFoldableCard
        open={open}
        onOpenChange={setOpen}
        cardTitle={t("shipment:importer.detail.label.shipmentTracking")}
        rightAccessary={
          <RightArrowIcon
            isCardOpen={open}
            onClick={() => setOpen((open) => !open)}
          />
        }
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {isFetching ? (
            <PulseLoader color={colorSet.blue4} />
          ) : !hasBlNo ? (
            <CargoTracking
              blNo="SMLMPHX4A1990400" // 개발용
            />
          ) : (
            <>
              <Icon iconSrc={NotShareSvg} iconSize={160} />
              <Typo typoType="b4m">업데이트 전 입니다.</Typo>
            </>
          )}
        </Flex>
      </SectionFoldableCard>
    </>
  );
};

export default ShipmentTrackingCard;
