import { useState } from "react";
import { styled } from "styled-components";
import { ReactImageGalleryItem } from "react-image-gallery";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import PhotoCollection from "@/src/components/molecule/PhotoCollection";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import {
  ContainerShareInfoDto,
  ContainerShareInfoListDto,
} from "@/src/store/apis/tasks/taskShare/interface";
import { ContainerMediaData } from "@/src/pages/exporter/Task/components/detail/ContainerAccordionItem";
import { useTranslation } from "react-i18next";
import LoadingPhotoPreviewDialog from "@/src/components/organism/LoadingPhotoPreviewDialog";

interface SharedViewPhotoCollectionDialogProps {
  items: ReactImageGalleryItem[];
  open: boolean;
  onClose: () => void;
  startIndex?: number;
  selectLoading?: ContainerShareInfoListDto | ContainerShareInfoDto | null;
  mediaDataList: ContainerMediaData[];
}

const SharedViewPhotoCollectionDialog = ({
  items,
  open,
  onClose,
  startIndex,
  selectLoading,
  mediaDataList,
}: SharedViewPhotoCollectionDialogProps) => {
  const { t } = useTranslation();

  const [isPhotoDetailInfoDialogOpen, setIsPhotoDetailInfoDialogOpen] =
    useState(false);
  const [selectImage, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  const handlePhotoClick = (imageUrl: string) => {
    const findImageInfo = mediaDataList.find(
      (item) => item.mediaUrl === imageUrl
    );

    if (!findImageInfo) {
      return;
    }

    setSelectImage(findImageInfo);
    setIsPhotoDetailInfoDialogOpen(true);
  };

  const renderPhotoDialog = () => {
    if (isPhotoDetailInfoDialogOpen) {
      return (
        <LoadingPhotoPreviewDialog
          open
          onOpenChange={() => {
            setIsPhotoDetailInfoDialogOpen(false);
            setSelectImage(null);
          }}
          selectImage={selectImage}
        />
      );
    }
  };

  return (
    <>
      <Dialog
        title={t("task:detail.dialog.viewPhotoCollection")}
        open={open}
        onOpenChange={() => onClose?.()}
        width={1150}
      >
        <DialogContentSection>
          <ImageGalleryContainer>
            <PhotoCollection
              items={items}
              startIndex={startIndex}
              onImageClick={(imageUrl) => handlePhotoClick(imageUrl)}
              imgProps={{
                onContextMenu: (e) => {
                  e.preventDefault();
                },
              }}
              thumbnailProps={{
                onContextMenu: (e) => {
                  e.preventDefault();
                },
              }}
            />
          </ImageGalleryContainer>
          <TaskDetailSection>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.loadingDetails")}
              </Typo>
              <DetailContainer>
                <SectionCardRow
                  label="Container No."
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.containerNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
                <SectionCardRow
                  label="Seal No."
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.sealNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
              </DetailContainer>
            </div>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.additionalInformation")}
              </Typo>

              <DetailContainer>
                <SectionCardRow
                  label="Gross Weight"
                  value={
                    selectLoading?.grossWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.grossWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label="Net Weight"
                  value={
                    selectLoading?.netWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.netWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label="Unit Q’ty"
                  value={
                    !selectLoading?.unitQuantity &&
                    !selectLoading?.unitQuantityUnit ? (
                      "-"
                    ) : (
                      <>
                        {selectLoading?.unitQuantity
                          ? selectLoading.unitQuantity.toLocaleString("ko-KR")
                          : ""}
                        {selectLoading?.unitQuantityUnit ?? ""}
                      </>
                    )
                  }
                />
              </DetailContainer>
            </div>
          </TaskDetailSection>
        </DialogContentSection>
      </Dialog>

      {renderPhotoDialog()}
    </>
  );
};

export default SharedViewPhotoCollectionDialog;

const DialogContentSection = styled.section`
  display: flex;
  gap: 24px;
`;

const ImageGalleryContainer = styled.div`
  width: 718px;
  flex-shrink: 0;
`;

const TaskDetailSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .detail-title {
    padding-bottom: 8px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledRowTypo = styled(Typo)`
  word-break: break-word;
`;
