import AlertDialog from "@/src/components/atom/AlertDialog";
import Typo from "@/src/components/atom/Typo";
import { useAppSelector } from "@/src/store";
import { useTranslation } from "react-i18next";

interface PlanChangeConfirmAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOk: () => void;
  currentPlanName: string;
  currentPaymentCycle: string;
  nextPlanName: string;
  nextPaymentCycle: string;
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
}

function PlanChangeConfirmAlertDialog({
  open,
  onOpenChange,
  onOk,
  currentPlanName,
  currentPaymentCycle,
  nextPlanName,
  nextPaymentCycle,
  isOkDisabled,
  isOkLoading,
}: PlanChangeConfirmAlertDialogProps) {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);

  return (
    <AlertDialog
      title={t("subscriptionManagement:subscription.checkChangePlan")}
      open={open}
      onOpenChange={onOpenChange}
      cancelText={t("subscriptionManagement:subscription.button.cancel")}
      okText={t("subscriptionManagement:subscription.button.ok")}
      onOk={onOk}
      isOkDisabled={isOkDisabled}
      isOkLoading={isOkLoading}
    >
      {lang === "en" ? (
        <>
          From{" "}
          <Typo
            typoType="h8"
            color="gray5"
          >{`{${currentPlanName} - ${currentPaymentCycle}}`}</Typo>
          <br />
          <Typo
            typoType="h8"
            color="gray5"
          >{`{${nextPlanName} - ${nextPaymentCycle}}`}</Typo>
          <br />
          Do you want to change the plan?
        </>
      ) : (
        <>
          <Typo
            typoType="h8"
            color="gray5"
          >{`{${currentPlanName} - ${currentPaymentCycle}}`}</Typo>
          에서
          <br />
          <Typo
            typoType="h8"
            color="gray5"
          >{`{${nextPlanName} - ${nextPaymentCycle}}`}</Typo>
          로<br />
          플랜 변경을 하시겠습니까?
        </>
      )}
    </AlertDialog>
  );
}

export default PlanChangeConfirmAlertDialog;
