import dayjs, { Dayjs } from "dayjs";

const today = dayjs();

export type State = {
  tab: "item" | "itemCode";

  periodInItem: "daily" | "monthly";
  dailyPeriodInItem: [Dayjs, Dayjs];
  monthlyPeriodInItem: [Dayjs, Dayjs];
  dailyCategoryInItem: string;
  dailyItemsInItem: string[];
  monthlyCategoryInItem: string;
  monthlyItemsInItem: string[];

  periodInItemCode: "daily" | "monthly";
  dailyPeriodInItemCode: [Dayjs, Dayjs];
  monthlyPeriodInItemCode: [Dayjs, Dayjs];
  dailyCategoryInItemCode: string;
  dailyItemCodeInItemCode: string[];
  monthlyCategoryInItemCode: string;
  monthlyItemCodeInItemCode: string[];
};

export enum TransactionStatisticAction {
  CHANGE_TAB,

  CHANGE_PERIOD_TYPE_IN_ITEM, // daily or monthly in item tab
  CHANGE_PERIOD_IN_ITEM_DAILY_TAB, // change period in daily of item tab
  CHANGE_CATEGORY_IN_ITEM_DAILY_TAB, // change category in daily of item tab
  ADD_ITEM_IN_ITEM_DAILY_TAB, // add item
  DELETE_ITEM_IN_ITEM_DAILY_TAB, // delete item
  CHANGE_ITEM_IN_ITEM_DAILY_TAB,

  CHANGE_PERIOD_IN_ITEM_MONTHLY_TAB, // change period in monthly of item tab
  CHANGE_CATEGORY_IN_ITEM_MONTHLY_TAB, // change category in monthly of item tab
  ADD_ITEM_IN_ITEM_MONTHLY_TAB,
  DELETE_ITEM_IN_ITEM_MONTHLY_TAB,
  CHANGE_ITEM_IN_ITEM_MONTHLY_TAB,

  CHANGE_PERIOD_TYPE_IN_ITEM_CODE,
  CHANGE_PERIOD_IN_ITEM_CODE_DAILY_TAB,
  CHANGE_CATEGORY_IN_ITEM_CODE_DAILY_TAB, // change category in daily of item tab
  ADD_ITEM_IN_ITEM_CODE_DAILY_TAB, // add item
  DELETE_ITEM_IN_ITEM_CODE_DAILY_TAB, // delete item
  CHANGE_ITEM_IN_ITEM_CODE_DAILY_TAB,

  CHANGE_PERIOD_IN_ITEM_CODE_MONTHLY_TAB, // change period in monthly of item tab
  CHANGE_CATEGORY_IN_ITEM_CODE_MONTHLY_TAB, // change category in monthly of item tab
  ADD_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
  DELETE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
  CHANGE_ITEM_IN_ITEM_CODE_MONTHLY_TAB,
}

export const initialState: State = {
  tab: "item",

  periodInItem: "daily",
  dailyPeriodInItem: [
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ],
  monthlyPeriodInItem: [
    today.clone().subtract(1, "year").startOf("month"),
    today.clone().subtract(1, "month").endOf("month"),
  ],
  dailyCategoryInItem: "all",
  dailyItemsInItem: ["all"],
  monthlyCategoryInItem: "all",
  monthlyItemsInItem: ["all"],

  periodInItemCode: "daily",
  dailyPeriodInItemCode: [
    today.clone().subtract(1, "month"),
    today.clone().subtract(1, "day"),
  ],
  monthlyPeriodInItemCode: [
    today.clone().subtract(1, "year").startOf("month"),
    today.clone().subtract(1, "month").endOf("month"),
  ],
  dailyCategoryInItemCode: "all",
  dailyItemCodeInItemCode: ["all"],
  monthlyCategoryInItemCode: "all",
  monthlyItemCodeInItemCode: ["all"],
};

type PayloadByType = {
  type: TransactionStatisticAction;
  payload: any;
};

export const reducer = (
  state: State,
  { type, payload }: PayloadByType
): State => {
  switch (type) {
    case TransactionStatisticAction.CHANGE_TAB:
      return {
        ...state,
        tab: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM:
      return {
        ...state,
        periodInItem: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_DAILY_TAB:
      return {
        ...state,
        dailyPeriodInItem: payload,
      };
    case TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_DAILY_TAB:
      return {
        ...state,
        dailyCategoryInItem: payload,
      };
    case TransactionStatisticAction.ADD_ITEM_IN_ITEM_DAILY_TAB:
      return {
        ...state,
        dailyItemsInItem: [...state.dailyItemsInItem, payload],
      };
    case TransactionStatisticAction.DELETE_ITEM_IN_ITEM_DAILY_TAB:
      return {
        ...state,
        dailyItemsInItem: state.dailyItemsInItem.filter(
          (item) => item !== payload
        ),
      };
    case TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_DAILY_TAB:
      return {
        ...state,
        dailyItemsInItem: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_MONTHLY_TAB:
      return {
        ...state,
        monthlyPeriodInItem: payload,
      };
    case TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_MONTHLY_TAB:
      return {
        ...state,
        monthlyCategoryInItem: payload,
      };
    case TransactionStatisticAction.ADD_ITEM_IN_ITEM_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemsInItem: [...state.monthlyItemsInItem, payload],
      };
    case TransactionStatisticAction.DELETE_ITEM_IN_ITEM_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemsInItem: state.monthlyItemsInItem.filter(
          (item) => item !== payload
        ),
      };
    case TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemsInItem: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_TYPE_IN_ITEM_CODE:
      return {
        ...state,
        periodInItemCode: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_CODE_DAILY_TAB:
      return {
        ...state,
        dailyPeriodInItemCode: payload,
      };
    case TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_CODE_DAILY_TAB:
      return {
        ...state,
        dailyCategoryInItemCode: payload,
      };
    case TransactionStatisticAction.ADD_ITEM_IN_ITEM_CODE_DAILY_TAB:
      return {
        ...state,
        dailyItemCodeInItemCode: [...state.dailyItemCodeInItemCode, payload],
      };
    case TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_DAILY_TAB:
      return {
        ...state,
        dailyItemCodeInItemCode: state.dailyItemCodeInItemCode.filter(
          (item) => item !== payload
        ),
      };
    case TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_DAILY_TAB:
      return {
        ...state,
        dailyItemCodeInItemCode: payload,
      };
    case TransactionStatisticAction.CHANGE_PERIOD_IN_ITEM_CODE_MONTHLY_TAB:
      return {
        ...state,
        monthlyPeriodInItemCode: payload,
      };
    case TransactionStatisticAction.CHANGE_CATEGORY_IN_ITEM_CODE_MONTHLY_TAB:
      return {
        ...state,
        monthlyCategoryInItemCode: payload,
      };
    case TransactionStatisticAction.ADD_ITEM_IN_ITEM_CODE_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemCodeInItemCode: [
          ...state.monthlyItemCodeInItemCode,
          payload,
        ],
      };
    case TransactionStatisticAction.DELETE_ITEM_IN_ITEM_CODE_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemCodeInItemCode: state.monthlyItemCodeInItemCode.filter(
          (item) => item !== payload
        ),
      };
    case TransactionStatisticAction.CHANGE_ITEM_IN_ITEM_CODE_MONTHLY_TAB:
      return {
        ...state,
        monthlyItemCodeInItemCode: payload,
      };
  }
};
