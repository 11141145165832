import {
  PaymentCycleType,
  PlanType,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import { TFunction } from "i18next";

export const planStatusStrings = (param: {
  t: TFunction;
  type: PlanType | SubscriptionType | null;
}) => {
  const { t, type } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "NORMAL":
      return t("subscriptionManagement:subscription.table.normalPlanType");

    case "ENTERPRISE":
      return t("subscriptionManagement:subscription.table.enterprisePlanType");

    case "FREE":
      return t("subscriptionManagement:subscription.table.freeType");
  }
};

export const paymentCycleStrings = (param: {
  t: TFunction;
  type?: PaymentCycleType | null;
}) => {
  const { t, type } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "ANNUALLY":
      return t("subscriptionManagement:subscription.annuallyPaymentCycle");

    case "MONTHLY":
      return t("subscriptionManagement:subscription.monthlyPaymentCycle");

    default:
      return "-";
  }
};
