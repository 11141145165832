import { STRINGS } from "@/src/constant";
import Typo from "../../atom/Typo";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/src/store";
import styled from "styled-components";
import { LanguageSliceState } from "@/src/store/slice/lang";
import PUBLIC_PATH from "@/src/routes/public/path";
interface PublicFooterProps {
  language?: LanguageSliceState["value"];
}

function PublicFooter({ language }: PublicFooterProps) {
  const navigate = useNavigate();
  const lang = useAppSelector((state) => state.lang.value);

  return (
    <StyledPublicFooter>
      <div>
        {STRINGS.login.footer[language ? language : lang].map(
          (string, idx, origin) => {
            const isLastIndex = origin.length - 1 === idx;
            const wordBreak = idx === 1 || idx === 3;

            return (
              <>
                <Typo typoType="b10m" color="gray6">
                  {string}{" "}
                </Typo>

                {!isLastIndex && !wordBreak && (
                  <Typo typoType="b10m" color="gray9">
                    |{" "}
                  </Typo>
                )}

                {wordBreak && <br />}
              </>
            );
          }
        )}

        <br />
        <Typo typoType="b10m" color="gray6">
          <span
            onClick={() => navigate(PUBLIC_PATH.TERMS)}
            style={{ cursor: "pointer" }}
          >
            Legal
          </span>{" "}
          <Typo typoType="b10m" color="gray9">
            |
          </Typo>{" "}
          Copyright ⓒ 2023 HRM Corporation
        </Typo>
      </div>
    </StyledPublicFooter>
  );
}

export default PublicFooter;

const StyledPublicFooter = styled.footer`
  padding: 16px 10px 24px 10px;
  text-align: center;
`;
