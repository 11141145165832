import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import { FaqDto } from "./interface";

const faqApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFaqs: build.query<
      Rows<FaqDto>,
      { page?: number; pageSize?: number; category?: string }
    >({
      query: ({ page = 1, pageSize = 10, category }) => ({
        url: "/faqs",
        params: {
          service: "EXPORT_WEB",
          isViewable: true,
          page,
          pageSize,
          category,
        },
      }),
    }),
  }),
});

export const { useLazyGetFaqsQuery } = faqApi;
