import { PDFViewer, BlobProvider } from "@react-pdf/renderer";
import { ReactNode } from "react";
import styled from "styled-components";
import { PlData } from "./types";
import PlContent from "./PlContent";
import { Style } from "@react-pdf/types";

interface PlPreviewProps {
  /**
   * @description PDF 필드의 title, value, hidden 필수값
   * data만 있어도 pdf 미리보기가 가능합니다
   * */
  data: PlData;
  trigger?: ReactNode;

  /**
   * @description PDF URL 원본을 구하기 위한 함수
   * URL로 새창을 띄우면 브라우저 내장 PDF View 실행
   */
  getUrl?: (url: string | null) => void;
  getBlob?: (file: Blob | null) => void;
  previewStyle?: Style | Style[];
}

function PlPreview({
  trigger,
  data,
  getUrl,
  getBlob,
  previewStyle,
}: PlPreviewProps) {
  const renderDocument = () => {
    return <PlContent data={data} />;
  };

  const renderPreview = () => {
    return (
      <PDFViewer style={{ width: "100%", ...previewStyle }}>
        {renderDocument()}
      </PDFViewer>
    );
  };

  return (
    <>
      {trigger ? (
        <BlobProvider document={renderDocument()}>
          {({ blob, url }) => {
            getBlob && getBlob(blob);
            getUrl && getUrl(url);

            return (
              <Anchor
                href={url === null ? "" : url}
                target="_blank"
                rel="noreferrer"
              >
                {trigger}
              </Anchor>
            );
          }}
        </BlobProvider>
      ) : (
        renderPreview()
      )}
    </>
  );
}

export default PlPreview;

const Anchor = styled.a`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: initial;
  font-size: initial;
  display: inline-block;
`;
