const ROOT_PATH = "/import";

const IMPORTER_PRIVATE_PATH = {
  // Public
  ROOT: `${ROOT_PATH}`,
  LOGIN: `${ROOT_PATH}/login`,
  SIGNUP: `${ROOT_PATH}/signup`,
  CORP_SIGNUP: `${ROOT_PATH}/corp-signup`,
  STAFF_SIGNUP: `${ROOT_PATH}/staff-signup`,
  CHANGE_PASSWORD: `${ROOT_PATH}/change-password`,
  FIND_PASSWORD: `${ROOT_PATH}/find-password`,
  TWO_FACTOR_AUTHENTICATION: `${ROOT_PATH}/two-factor-authentication`,

  // Side Nav
  HOME: `${ROOT_PATH}/home`,
  CONTRACT: `${ROOT_PATH}/contract`,
  CONTRACT_DETAIL: `${ROOT_PATH}/contract/detail`,
  CONTRACT_ADD: `${ROOT_PATH}/contract/add`,
  CONTRACT_EDIT: `${ROOT_PATH}/contract/edit`,

  SHIPMENT: `${ROOT_PATH}/shipment`,
  SHIPMENT_ADD_CI: `${ROOT_PATH}/shipment/add/ci`,
  SHIPMENT_ADD_PL: `${ROOT_PATH}/shipment/add/pl`,
  SHIPMENT_EDIT_CI: `${ROOT_PATH}/shipment/edit/ci`,
  SHIPMENT_EDIT_PL: `${ROOT_PATH}/shipment/edit/pl`,
  SHIPMENT_ADD: `${ROOT_PATH}/shipment/add`,
  SHIPMENT_DETAIL: `${ROOT_PATH}/shipment/detail`,

  SALES_MANAGEMENT: `${ROOT_PATH}/sales`,
  SALES_ADD: `${ROOT_PATH}/sales/add`,
  SALES_DETAIL: `${ROOT_PATH}/sales/detail`,
  SALES_EDIT: `${ROOT_PATH}/sales/edit`,

  FILE_BOX: `${ROOT_PATH}/file-box`,

  BUYER_MANAGEMENT: `${ROOT_PATH}/buyer-management`,
  BUYER_MANAGEMENT_ADD: `${ROOT_PATH}/buyer-management/add`,
  BUYER_MANAGEMENT_DETAIL: `${ROOT_PATH}/buyer-management/detail`,
  BUYER_MANAGEMENT_EDIT: `${ROOT_PATH}/buyer-management/edit`,

  FACTORY_MANAGEMENT: `${ROOT_PATH}/factory-management`,
  FACTORY_MANAGEMENT_ADD: `${ROOT_PATH}/factory-management/add`,
  FACTORY_MANAGEMENT_DETAIL: `${ROOT_PATH}/factory-management/detail`,
  FACTORY_MANAGEMENT_EDIT: `${ROOT_PATH}/factory-management/edit`,

  COMPANY_INFORMATION_MANAGEMENT: `${ROOT_PATH}/company-information-management`,
  ITEM_MANAGEMENT: `${ROOT_PATH}/item-management`,
  STAFF_MANAGEMENT: `${ROOT_PATH}/staff-management`,
  CLIENT_MANAGEMENT: `${ROOT_PATH}/client-management`,

  CLIENT_MANAGEMENT_ADD: `${ROOT_PATH}/client-management/add`,
  CLIENT_MANAGEMENT_DETAIL: `${ROOT_PATH}/client-management/detail`,
  CLIENT_MANAGEMENT_EDIT: `${ROOT_PATH}/client-management/edit`,
  STORE_MANAGEMENT: `${ROOT_PATH}/store-management`,
  WAREHOUSE_MANAGEMENT: `${ROOT_PATH}/warehouse-management`,
  WAREHOUSE_MANAGEMENT_ADD: `${ROOT_PATH}/warehouse-management/add`,
  WAREHOUSE_MANAGEMENT_DETAIL: `${ROOT_PATH}/warehouse-management/detail`,
  WAREHOUSE_MANAGEMENT_EDIT: `${ROOT_PATH}/warehouse-management/edit`,

  NOTICE: `${ROOT_PATH}/notice`,

  INQUIRY: `${ROOT_PATH}/inquiry`,
  INQUIRY_ADD: `${ROOT_PATH}/inquiry/add`,
  INQUIRY_EDIT: `${ROOT_PATH}/inquiry/edit`,
  FAQ: `${ROOT_PATH}/faq`,

  MY_PAGE: `${ROOT_PATH}/mypage`,
  MY_PAGE_EDIT: `${ROOT_PATH}/mypage/edit`,

  // Email
  // SHARED_EMAIL_IMPORT_CONTRACT: `${ROOT_PATH}/shared-email/contract`,
};

export default IMPORTER_PRIVATE_PATH;
