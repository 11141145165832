import piexif from "piexifjs";
import { downloadFileFromBlob } from "./downloadFile";

/**
 * Blob을 JPG 형식으로 변환하는 함수
 * @param blob - 변환할 이미지 Blob
 * @returns Promise<Blob> - JPG로 변환된 Blob
 */
const convertToJpg = (blob: Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = () => {
        // Canvas에 이미지를 그려 JPG로 변환
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          // 이미지 데이터를 JPG로 변환
          canvas.toBlob(
            (jpgBlob) => {
              if (jpgBlob) {
                resolve(jpgBlob); // JPG Blob 반환
              } else {
                reject(new Error("Failed to convert image to JPG"));
              }
            },
            "image/jpeg",
            1 // 품질 설정 (1은 최고 품질)
          );
        } else {
          reject(new Error("Canvas context not available"));
        }
      };

      img.onerror = () => {
        reject(new Error("Failed to load image for conversion"));
      };
    };

    reader.onerror = () => {
      reject(new Error("Failed to read image blob"));
    };

    reader.readAsDataURL(blob); // Blob을 Base64로 변환
  });
};

/**
 * EXIF 메타데이터를 Blob 이미지에 추가하고 JPG로 변환한 Blob 반환
 * @param blob - 이미지 Blob
 * @param latitude - 추가할 위도 값 (선택 사항)
 * @param longitude - 추가할 경도 값 (선택 사항)
 * @returns Promise<Blob> - EXIF 메타데이터가 추가되고 JPG로 변환된 Blob
 */
export const addExifMetadataToImage = async (
  blob: Blob,
  latitude?: number | string,
  longitude?: number | string
): Promise<Blob> => {
  const jpgBlob = await convertToJpg(blob); // 우선 JPG로 변환

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      try {
        const base64Image = reader.result as string;

        // EXIF 데이터 추가 객체
        const exifObj: any = {};

        // 위도 및 경도 값이 문자열로 들어올 경우 숫자로 변환
        const parsedLatitude = latitude
          ? parseFloat(latitude as string)
          : undefined;
        const parsedLongitude = longitude
          ? parseFloat(longitude as string)
          : undefined;

        // EXIF 메타데이터 추가 (위도, 경도 값이 유효한 경우)
        if (
          !isNaN(parsedLatitude as number) &&
          !isNaN(parsedLongitude as number)
        ) {
          exifObj["GPS"] = {
            [piexif.GPSIFD.GPSLatitude]: [
              [Math.abs(parsedLatitude!), 1],
              [0, 1],
              [0, 1],
            ],
            [piexif.GPSIFD.GPSLatitudeRef]: parsedLatitude! >= 0 ? "N" : "S",
            [piexif.GPSIFD.GPSLongitude]: [
              [Math.abs(parsedLongitude!), 1],
              [0, 1],
              [0, 1],
            ],
            [piexif.GPSIFD.GPSLongitudeRef]: parsedLongitude! >= 0 ? "E" : "W",
          };
        }

        // EXIF 데이터 삽입 (GPS 데이터가 있을 때만 추가)
        const exifBytes = piexif.dump(exifObj);
        const newImageData = piexif.insert(exifBytes, base64Image);

        // base64 데이터를 Blob으로 변환
        const byteString = atob(newImageData.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        const newBlob = new Blob([uint8Array], { type: "image/jpeg" }); // 항상 JPG로 변환
        resolve(newBlob);
      } catch (error) {
        console.error("Error in EXIF metadata processing:", error);
        reject(error);
      }
    };

    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      reject(error);
    };

    reader.readAsDataURL(jpgBlob); // 변환된 JPG Blob을 Base64로 변환하여 처리
  });
};

/**
 * 이미지 URL에서 EXIF 메타데이터를 추가한 이미지 파일을 다운로드하는 함수
 * @param url - 이미지 URL
 * @param latitude - 추가할 위도 값
 * @param longitude - 추가할 경도 값
 * @param fileName - 다운로드할 파일 이름
 */
export const downloadImageWithExif = async (props: {
  url: string;
  latitude?: number;
  longitude?: number;
  fileName?: string;
}) => {
  const { url, latitude, longitude, fileName } = props;

  try {
    const blob = await fetch(url, {
      mode: "cors", // CORS 모드 설정
      headers: { "Cache-Control": "no-cache" }, // 캐시 무효화를 위한 헤더
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch image: ${res.statusText}`);
        }

        return res.blob(); // Blob으로 변환하여 반환
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        throw error;
      });

    // EXIF 메타데이터 추가
    const updatedBlob = await addExifMetadataToImage(blob, latitude, longitude);

    // 파일명을 jpg 확장자로 강제 변환 (.jpeg 포함하여 모두 .jpg로 바꿈)
    const fileNameWithJpg = (fileName ?? "image_with_metadata.jpg")
      .replace(/\.jpeg$/, ".jpg")
      .replace(/\.png$/, ".jpg");

    // Blob 다운로드
    downloadFileFromBlob(updatedBlob, fileNameWithJpg);
  } catch (error) {
    console.error("Failed to download image with metadata:", error);
  }
};
