import Dialog from "@/src/components/atom/Dialog";
import React, { useEffect, useRef, useState } from "react";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import styled from "styled-components";
import TabItem from "@/src/components/molecule/TabItem";
import colorSet from "@/src/styles/color";
import { ContainerMediaData } from "../ContainerAccordionItem";
import PhotoCollection from "@/src/components/molecule/PhotoCollection";
import dayjs from "dayjs";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import {
  taskDetailApi,
  useUpdateTaskDetailContainerMutation,
} from "@/src/store/apis/tasks/taskDetail";
import {
  ContainerMediaType,
  SimpleContainerMediaDto,
  SimpleMediaDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import { Controller, useForm } from "react-hook-form";
import { convertImgList } from "../utils/convertImgList";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import Loader from "@/src/components/atom/Loader";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Input from "@/src/components/atom/Input";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import Icon from "@/src/components/atom/Icon";
import SearchSelect from "@/src/components/atom/Select/SearchSelect";
import useAlert from "@/src/hooks/useAlert";
import { StyledScroll } from "@/src/styles/scroll";
import {
  renderContainerIcon,
  renderContainerText,
} from "../utils/renderContainerType";
import SingleImageUpload from "@/src/components/molecule/SingleImageUpload";
import DeleteSvg from "@/src/assets/icons/icon-close-white.svg";
import { MediaDto } from "@/src/store/apis/media/interface";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import ImagePlaceholderImage from "@/src/assets/images/image-photo-collection-placeholder.png";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";
import LoadingPhotoPreviewDialog from "@/src/components/organism/LoadingPhotoPreviewDialog";

type MediaType = "EXIST" | "NEW";
export type EditContainerTabType = "PHOTO" | "WEIGHT";
const TAB_LIST: EditContainerTabType[] = ["PHOTO", "WEIGHT"];

enum DialogState {
  NULL,
  PHOTO_DETAIL_INFO,
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

type OptionType = {
  value: string;
  label: string;
};

const MEASUREMENT_MT_UNIT = "MEASUREMENT_MT";
const imageEmptyArray: SimpleContainerMediaDto[] = [];
const simpleMediaEmptyArray: SimpleMediaDto[] = [];

interface EditContainerInformationDialogProps {
  containerId: number;
  tabType: EditContainerTabType;
  disableTabChange?: boolean;
  open: boolean;
  onOpenChange: (value: boolean) => void;
  refetch: () => void;
}

function EditContainerInformationDialog({
  containerId,
  tabType,
  disableTabChange = false,
  open,
  onOpenChange,
  refetch,
}: EditContainerInformationDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const tabRef = useRef<HTMLButtonElement>(null);

  const [startPhotoIndex, setStartPhotoIndex] = useState(0);
  const [selectTab, setSelectTab] = useState<EditContainerTabType>(tabType);
  const [selectImage, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const initialSelectTab = tabType;

  // API
  const {
    currentData: measurementList,
    isFetching: isMeasurementListFetching,
  } = useGetCommonCodeViaCodeNameQuery({
    codeName: "MEASUREMENT",
  });
  const {
    currentData: unitQuantityUnitList,
    isFetching: isUnitQuantityUnitListFetching,
  } = useGetCommonCodeViaCodeNameQuery({
    codeName: "UNIT_QUANTITY",
  });
  const [updateContainer] = useUpdateTaskDetailContainerMutation();

  const mesurementListToOptionList = measurementList
    ? measurementList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const unitQuantityUnitListToOptionList = unitQuantityUnitList
    ? unitQuantityUnitList.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: val.codeItemNameEn,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const {
    isFetching,
    containerNo,
    sealNo,
    weightUnitCodeItemName,
    firstWeight,
    secondWeight,
    grossWeight,
    netWeight,
    unitQuantity,
    weighingTicketMedias,
    photoSubmittedDateAt,
    unitQuantityUnitCodeItemName,
    containerSampleBaleMediasCount,
    containerEmptyMediasCount,
    containerQuarterLoadedMediasCount,
    containerHalfLoadedMediasCount,
    containerFullLoadedMediasCount,
    containerOneDoorClosedMediasCount,
    containerBothDoorsClosedMediasCount,
    containerCloseUpSealMediasCount,
    containerExtraMediasCount,
    sampleBaleSimpleContainerMedias,
    emptySimpleContainerMedias,
    quarterLoadedSimpleContainerMedias,
    halfLoadedSimpleContainerMedias,
    fullLoadedSimpleContainerMedias,
    oneDoorClosedSimpleContainerMedias,
    bothDoorsClosedSimpleContainerMedias,
    closeUpSealSimpleContainerMedias,
    extraSimpleContainerMedias,
  } = taskDetailApi.endpoints.getTaskDetailContainer.useQueryState(
    {
      id: containerId,
    },
    {
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isFetching,
          containerNo: !isUnstable ? currentData.containerNo ?? "" : "",
          sealNo: !isUnstable ? currentData.sealNo ?? "" : "",
          weightUnitCodeItemName: !isUnstable
            ? currentData.weightUnitCodeItemName ?? MEASUREMENT_MT_UNIT
            : MEASUREMENT_MT_UNIT,
          firstWeight:
            !isUnstable && currentData.firstWeight
              ? Number(currentData.firstWeight).toLocaleString("ko-KR")
              : "",
          secondWeight:
            !isUnstable && currentData.secondWeight
              ? Number(currentData.secondWeight).toLocaleString("ko-KR")
              : "",
          grossWeight:
            !isUnstable && currentData.grossWeight
              ? Number(currentData.grossWeight).toLocaleString("ko-KR")
              : "",
          netWeight:
            !isUnstable && currentData.netWeight
              ? Number(currentData.netWeight).toLocaleString("ko-KR")
              : "",
          unitQuantity:
            !isUnstable && currentData.unitQuantity
              ? Number(currentData.unitQuantity).toLocaleString("ko-KR")
              : "",
          weighingTicketMedias: !isUnstable
            ? currentData.weighingTicketMedias ?? simpleMediaEmptyArray
            : simpleMediaEmptyArray,
          photoSubmittedDateAt:
            !isUnstable && currentData.photoSubmittedDateAt
              ? dayjs(currentData.photoSubmittedDateAt).format(
                  DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                )
              : "",
          unitQuantityUnitCodeItemName:
            currentData?.unitQuantityUnitCodeItemName,

          // Count
          containerSampleBaleMediasCount: !isUnstable
            ? currentData.containerSampleBaleMediasCount ?? 0
            : 0,
          containerEmptyMediasCount: !isUnstable
            ? currentData.containerEmptyMediasCount ?? 0
            : 0,
          containerQuarterLoadedMediasCount: !isUnstable
            ? currentData.containerQuarterLoadedMediasCount ?? 0
            : 0,
          containerHalfLoadedMediasCount: !isUnstable
            ? currentData.containerHalfLoadedMediasCount ?? 0
            : 0,
          containerFullLoadedMediasCount: !isUnstable
            ? currentData.containerFullLoadedMediasCount ?? 0
            : 0,
          containerOneDoorClosedMediasCount: !isUnstable
            ? currentData.containerOneDoorClosedMediasCount ?? 0
            : 0,
          containerBothDoorsClosedMediasCount: !isUnstable
            ? currentData.containerBothDoorsClosedMediasCount ?? 0
            : 0,
          containerCloseUpSealMediasCount: !isUnstable
            ? currentData.containerCloseUpSealMediasCount ?? 0
            : 0,
          containerExtraMediasCount: !isUnstable
            ? currentData.containerExtraMediasCount ?? 0
            : 0,

          // Images
          sampleBaleSimpleContainerMedias: !isUnstable
            ? currentData.sampleBaleSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          emptySimpleContainerMedias: !isUnstable
            ? currentData.emptySimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          quarterLoadedSimpleContainerMedias: !isUnstable
            ? currentData.quarterLoadedSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          halfLoadedSimpleContainerMedias: !isUnstable
            ? currentData.halfLoadedSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          fullLoadedSimpleContainerMedias: !isUnstable
            ? currentData.fullLoadedSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          oneDoorClosedSimpleContainerMedias: !isUnstable
            ? currentData.oneDoorClosedSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          bothDoorsClosedSimpleContainerMedias: !isUnstable
            ? currentData.bothDoorsClosedSimpleContainerMedias ??
              imageEmptyArray
            : imageEmptyArray,
          closeUpSealSimpleContainerMedias: !isUnstable
            ? currentData.closeUpSealSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
          extraSimpleContainerMedias: !isUnstable
            ? currentData.extraSimpleContainerMedias ?? imageEmptyArray
            : imageEmptyArray,
        };
      },
    }
  );

  const {
    control,
    watch,
    handleSubmit,
    setFocus,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm<{
    // Edit Photo
    images: {
      type: ContainerMediaType;
      media: { id?: number; mediaType: MediaType; mediaUrl: string }[];
      isUsed: boolean;
    }[];

    // Edit  Information
    containerNo: string;
    sealNo: string;
    photoSubmittedDateAt: string;
    weighingTicketFirst: SimpleMediaDto[];
    weighingTicketSecond: SimpleMediaDto[];
    weightUnitCodeItemName: string;
    firstWeight: string;
    secondWeight: string;
    grossWeight: string;
    netWeight: string;
    netWeightUse: string[];
    unitQuantity: string;
    unitQuantityUnitCodeItemName: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      // Edit Photo
      images: [
        {
          type: "SAMPLE_BALE",
          media:
            convertImgList({
              list: sampleBaleSimpleContainerMedias,
              maxCount: containerSampleBaleMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerSampleBaleMediasCount !== 0,
        },
        {
          type: "EMPTY",
          media:
            convertImgList({
              list: emptySimpleContainerMedias,
              maxCount: containerEmptyMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerEmptyMediasCount !== 0,
        },
        {
          type: "QUARTER_LOADED",
          media:
            convertImgList({
              list: quarterLoadedSimpleContainerMedias,
              maxCount: containerQuarterLoadedMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerQuarterLoadedMediasCount !== 0,
        },
        {
          type: "HALF_LOADED",
          media:
            convertImgList({
              list: halfLoadedSimpleContainerMedias,
              maxCount: containerHalfLoadedMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerHalfLoadedMediasCount !== 0,
        },
        {
          type: "FULL_LOADED",
          media:
            convertImgList({
              list: fullLoadedSimpleContainerMedias,
              maxCount: containerFullLoadedMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerFullLoadedMediasCount !== 0,
        },
        {
          type: "ONE_DOOR_CLOSED",
          media:
            convertImgList({
              list: oneDoorClosedSimpleContainerMedias,
              maxCount: containerOneDoorClosedMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerOneDoorClosedMediasCount !== 0,
        },
        {
          type: "BOTH_DOORS_CLOSED",
          media:
            convertImgList({
              list: bothDoorsClosedSimpleContainerMedias,
              maxCount: containerBothDoorsClosedMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerBothDoorsClosedMediasCount !== 0,
        },
        {
          type: "CLOSE_UP_SEAL",
          media:
            convertImgList({
              list: closeUpSealSimpleContainerMedias,
              maxCount: containerCloseUpSealMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerCloseUpSealMediasCount !== 0,
        },
        {
          type: "EXTRA",
          media:
            convertImgList({
              list: extraSimpleContainerMedias,
              maxCount: containerExtraMediasCount,
            }).map(({ containerMediaId, containerPhotoSimpleMedia }) => {
              return {
                id: containerMediaId,
                mediaType: "EXIST",
                mediaUrl: containerPhotoSimpleMedia?.mediaUrl || "",
              };
            }) || [],
          isUsed: containerExtraMediasCount !== 0,
        },
      ],

      // Edit  Information
      containerNo,
      sealNo,
      photoSubmittedDateAt,
      weighingTicketFirst: weighingTicketMedias
        ? weighingTicketMedias.length >= 1
          ? [weighingTicketMedias[0]]
          : []
        : [],
      weighingTicketSecond: weighingTicketMedias
        ? weighingTicketMedias.length >= 2
          ? [weighingTicketMedias[1]]
          : []
        : [],
      weightUnitCodeItemName,
      firstWeight,
      secondWeight,
      grossWeight,
      netWeight,
      netWeightUse: netWeight === grossWeight ? ["true"] : [],
      unitQuantity,
      unitQuantityUnitCodeItemName,
    },
  });

  const isNetWeightCheck = watch("netWeightUse")[0] === "true";
  const isSecondWeightValidation =
    Number(watch("firstWeight").replace(/[,]/g, "")) <
    Number(watch("secondWeight").replace(/[,]/g, ""));

  const handleNetWeightAndGrossWeightChange = () => {
    const value = (
      Number(watch("firstWeight").replace(/[,]/g, "")) -
      Number(watch("secondWeight").replace(/[,]/g, ""))
    ).toLocaleString("ko-KR");

    if (isNetWeightCheck) {
      setValue("netWeight", value);
    }

    setValue("grossWeight", value);
  };

  const weightUnit = measurementList?.find(
    (item) => item.codeItemName === getValues("weightUnitCodeItemName")
  )?.codeItemNameEn;

  const imageUrlList = getValues("images")
    .map((item) =>
      item.media.reduce((acc, val): string[] => {
        if (val.mediaUrl === "") {
          return [...acc];
        }

        return [...acc, val.mediaUrl];
      }, [] as string[])
    )
    .reduce((accumulator, currentValue) => {
      if (currentValue.length > 0) {
        accumulator.push(...currentValue);
      }

      return accumulator;
    }, [] as string[]);

  const imageDataList = getValues("images")
    .map((item) =>
      item.media.reduce((acc, val) => {
        if (val.id === undefined) {
          return [...acc];
        }
        return [...acc, val];
      }, [] as { id?: number; mediaType: MediaType; mediaUrl: string }[])
    )
    .reduce((mediaData, currentMediaData) => {
      if (currentMediaData.length > 0) {
        mediaData.push(...currentMediaData);
      }

      return mediaData;
    }, []);

  const handleSubmitClick = async () => {
    const isWeightValidation =
      Number(getValues("firstWeight").replace(/[,]/g, "")) <
      Number(getValues("secondWeight").replace(/[,]/g, ""));

    if (isWeightValidation) {
      setSelectTab("WEIGHT");
      setError("secondWeight", { type: "validate" });
      return;
    }

    const weighingTicketMediaIdList = [
      ...getValues("weighingTicketFirst"),
      ...getValues("weighingTicketSecond"),
    ].map((item) => item.id);

    const sampleBaleMediaIdList = getValues("images").find(
      (item) => item.type === "SAMPLE_BALE"
    );
    const emptyMediaIdList = getValues("images").find(
      (item) => item.type === "EMPTY"
    );
    const quarterLoadedMediaIdList = getValues("images").find(
      (item) => item.type === "QUARTER_LOADED"
    );
    const halfLoadedMediaIdList = getValues("images").find(
      (item) => item.type === "HALF_LOADED"
    );
    const fullLoadedMediaIdList = getValues("images").find(
      (item) => item.type === "FULL_LOADED"
    );
    const oneDoorClosedMediaIdList = getValues("images").find(
      (item) => item.type === "ONE_DOOR_CLOSED"
    );
    const bothDoorsClosedMediaIdList = getValues("images").find(
      (item) => item.type === "BOTH_DOORS_CLOSED"
    );
    const closeUpSealMediaIdList = getValues("images").find(
      (item) => item.type === "CLOSE_UP_SEAL"
    );
    const extraMediaIdList = getValues("images").find(
      (item) => item.type === "EXTRA"
    );

    const imageParams = {
      addingSampleBaleMediaIdList:
        sampleBaleMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingSampleBaleContainerMediaIdList:
        sampleBaleMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingEmptyMediaIdList:
        emptyMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingEmptyContainerMediaIdList:
        emptyMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingQuarterLoadedMediaIdList:
        quarterLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingQuarterLoadedContainerMediaIdList:
        quarterLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingHalfLoadedMediaIdList:
        halfLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingHalfLoadedContainerMediaIdList:
        halfLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingFullLoadedMediaIdList:
        fullLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingFullLoadedContainerMediaIdList:
        fullLoadedMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingOneDoorClosedMediaIdList:
        oneDoorClosedMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingOneDoorClosedContainerMediaIdList:
        oneDoorClosedMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingBothDoorsClosedMediaIdList:
        bothDoorsClosedMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingBothDoorsClosedContainerMediaIdList:
        bothDoorsClosedMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingCloseUpSealMediaIdList:
        closeUpSealMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingCloseUpSealContainerMediaIdList:
        closeUpSealMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
      addingExtraMediaIdList:
        extraMediaIdList?.media
          .filter((item) => item.mediaType === "NEW" && item.id)
          .map((media) => media.id) || [],
      existingExtraContainerMediaIdList:
        extraMediaIdList?.media
          .filter((item) => item.mediaType === "EXIST" && item.id)
          .map((media) => media.id) || [],
    };

    const submitParams = {
      id: containerId,
      containerNo: getValues("containerNo")
        ? getValues("containerNo").toUpperCase()
        : null,
      sealNo: getValues("sealNo") ? getValues("sealNo").toUpperCase() : null,
      weightUnit: measurementList?.find(
        (item) => item.codeItemName === getValues("weightUnitCodeItemName")
      )?.codeItemNameEn as string,
      weightUnitCodeItemName: getValues("weightUnitCodeItemName"),
      firstWeight: Number(getValues("firstWeight").replace(/[,]/g, "")),
      secondWeight: Number(getValues("secondWeight").replace(/[,]/g, "")),
      netWeight: Number(getValues("netWeight").replace(/[,]/g, "")),
      unitQuantity: Number(getValues("unitQuantity").replace(/[,]/g, "")),
      unitQuantityUnit: unitQuantityUnitList?.find(
        (item) =>
          item.codeItemName === getValues("unitQuantityUnitCodeItemName")
      )?.codeItemNameEn as string,
      unitQuantityUnitCodeItemName: getValues("unitQuantityUnitCodeItemName"),
      weighingTicketMediaIdList,
      ...imageParams,
    };

    const transformParams = Object.entries(submitParams).reduce(
      (acc: any, [key, value]) => {
        if (value === "" || value === 0) {
          acc[key] = null;
        } else {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );

    try {
      await updateContainer(transformParams).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      onOpenChange(false);
      refetch();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  // 슬라이드 메인 이미지 클릭
  const handleSlideMainImageClick = (imageUrl: string) => {
    const findImageInfo = imageDataList.find(
      (item) => item.mediaUrl === imageUrl
    );

    if (!findImageInfo) {
      return;
    }

    setSelectImage(findImageInfo);
    setDialogState(DialogState.PHOTO_DETAIL_INFO);
  };

  // 사진 편집내 이미지 클릭 함수
  const handleEditContainerImageClick = (imageUrl: string) => {
    const findMediaIndex = imageDataList.findIndex(
      (item) => item.mediaUrl === imageUrl
    );

    setStartPhotoIndex(findMediaIndex);
  };

  const handleImageChange = (param: {
    data: MediaDto;
    taskListIndex: number;
    imageIndex: number;
  }) => {
    const { data, taskListIndex, imageIndex } = param;

    const taskListMaping = watch("images")
      .filter((item) => item.isUsed)
      .map((item, idx) => {
        if (taskListIndex === idx) {
          item.media[imageIndex].mediaType = "NEW";
          item.media[imageIndex].id = data.id;
          item.media[imageIndex].mediaUrl = data.mediaUrl;
        }

        return item;
      });

    return setValue("images", taskListMaping);
  };

  const handleImageRemoveClick = (param: {
    taskListIndex: number;
    imageIndex: number;
  }) => {
    const { taskListIndex, imageIndex } = param;

    const taskListFilter = watch("images")
      .filter((item) => item.isUsed)
      .map((item, idx) => {
        if (taskListIndex === idx) {
          item.media[imageIndex].id = undefined;
          item.media[imageIndex].mediaUrl = "";
        }

        return item;
      });

    return setValue("images", taskListFilter);
  };

  const renderDialog = () => {
    if (dialogState === DialogState.PHOTO_DETAIL_INFO) {
      return (
        <LoadingPhotoPreviewDialog
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectImage(null);
          }}
          selectImage={selectImage}
          mediaCoordinateRange="ALL"
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={() => {
            onOpenChange(false);
          }}
        />
      );
    }
  };

  const renderTabItemTitle = (type: EditContainerTabType) => {
    switch (type) {
      case "PHOTO":
        return t("task:detail.tabs.loadingPhoto");

      case "WEIGHT":
        return t("task:detail.tabs.weightInformation");
    }
  };

  const renderTabContent = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={40} />
        </LoaderContainer>
      );
    }

    if (selectTab === "PHOTO") {
      return (
        <EditContent>
          <PhotoTypesContainer>
            <SectionCardRow
              label={t("task:detail.dialog.photoByTypes")}
              direction="vertical"
              value={
                <article>
                  {watch("images")
                    .filter((item) => item.isUsed === true)
                    .map(({ type, media }, idx) => {
                      return (
                        <section key={idx.toString()}>
                          <TextContainer>
                            {renderContainerIcon(type)}
                            {renderContainerText(t, type, "b7m")}
                          </TextContainer>

                          <ImageListContainer>
                            {media?.map((img, imageIdx) => {
                              return (
                                <SingleImageUpload
                                  key={imageIdx.toString()}
                                  isNoneDescription={true}
                                  value={img?.mediaUrl}
                                  folder="containers/"
                                  onChange={(value) => {
                                    handleImageChange({
                                      data: value,
                                      taskListIndex: idx,
                                      imageIndex: imageIdx,
                                    });
                                  }}
                                  imageOnClick={() =>
                                    handleEditContainerImageClick(img.mediaUrl)
                                  }
                                  topAccessary={({ handleRemove }) => {
                                    return (
                                      <DeleteIcon
                                        iconSrc={DeleteSvg}
                                        iconSize={20}
                                        onClick={() => {
                                          handleImageRemoveClick({
                                            taskListIndex: idx,
                                            imageIndex: imageIdx,
                                          });

                                          handleRemove();
                                        }}
                                      />
                                    );
                                  }}
                                />
                              );
                            })}
                          </ImageListContainer>
                        </section>
                      );
                    })}
                </article>
              }
            />
          </PhotoTypesContainer>
        </EditContent>
      );
    }

    if (selectTab === "WEIGHT") {
      return (
        <EditContent>
          {/* Photo Submitted Date */}
          <FormItem
            label={t("task:detail.cards.photoSubmittedDate")}
            type="text"
            name="photoSubmittedDateAt"
            control={control}
            direction="vertical"
            inputProps={{
              disabled: true,
              placeholder: getValues("photoSubmittedDateAt")
                ? dayjs(getValues("photoSubmittedDateAt")).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                  )
                : "-",
            }}
          />

          {/* Container No. Seal No */}
          <SectionCardRow
            direction="vertical"
            value={
              <StyledSectionCardValue>
                <FormItem
                  label={"Container No."}
                  type="text"
                  name="containerNo"
                  control={control}
                  direction="vertical"
                  inputProps={{
                    placeholder: "Enter container no.",
                    onBlur: (e) => {
                      getValues("containerNo") &&
                        setValue("containerNo", e.target.value.toUpperCase());
                    },
                  }}
                />

                <FormItem
                  label={"Seal No"}
                  type="text"
                  name="sealNo"
                  control={control}
                  direction="vertical"
                  inputProps={{
                    placeholder: "Enter seal no.",
                    onBlur: (e) => {
                      getValues("sealNo") &&
                        setValue("sealNo", e.target.value.toUpperCase());
                    },
                  }}
                />
              </StyledSectionCardValue>
            }
          />

          {/* Weighing Ticket */}
          <SectionCardRow
            label={t("task:detail.cards.weighingTicket")}
            direction="vertical"
            value={
              <StyledSectionCardValue>
                <FormItem
                  type="file"
                  name="weighingTicketFirst"
                  control={control}
                  inputProps={{
                    defaultFileList:
                      watch("weighingTicketFirst")?.length >= 1
                        ? [
                            new File(
                              [""],
                              watch("weighingTicketFirst")?.[0]
                                ?.originalFileName ?? ""
                            ),
                          ]
                        : undefined,
                    onRemove: () => setValue("weighingTicketFirst", []),
                    isPreview: true,
                  }}
                />

                <FormItem
                  type="file"
                  name="weighingTicketSecond"
                  control={control}
                  inputProps={{
                    defaultFileList:
                      (watch("weighingTicketSecond")?.length as any) >= 1
                        ? [
                            new File(
                              [""],
                              watch("weighingTicketSecond")?.[0]
                                ?.originalFileName ?? ""
                            ),
                          ]
                        : undefined,
                    onRemove: () => setValue("weighingTicketSecond", []),
                    isPreview: true,
                  }}
                />
              </StyledSectionCardValue>
            }
          />

          {/* Weight Calculation */}
          <SectionCardRow
            label={t("task:detail.cards.weightInformation")}
            direction="vertical"
            value={
              <StyledSectionCardValue>
                <FormItem
                  label={"Unit"}
                  type="singleSelect"
                  name="weightUnitCodeItemName"
                  control={control}
                  direction="vertical"
                  inputProps={{
                    placeholder: "MT",
                    getPopupContainer: (triggerNode) => {
                      return triggerNode.parentElement;
                    },
                    suffixIcon: isMeasurementListFetching ? (
                      <Loader />
                    ) : undefined,
                  }}
                  options={mesurementListToOptionList}
                />

                <SectionCardRow
                  label={
                    <Typo typoType="b7m">
                      {t("task:detail.cards.firstWeight")}
                    </Typo>
                  }
                  direction="vertical"
                  value={
                    <SectionCardRowItem>
                      <FlexRow>
                        <FlexOne>
                          <Controller
                            name="firstWeight"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder="Enter weight"
                                onClear={() => {
                                  setValue("firstWeight", "");
                                  handleNetWeightAndGrossWeightChange();
                                }}
                                onChange={(e) => {
                                  clearErrors("secondWeight");
                                  setValue(
                                    "firstWeight",
                                    e.target.value.replace(/[^0-9.,]/g, "")
                                  );
                                  handleNetWeightAndGrossWeightChange();
                                }}
                                onBlur={(e) => {
                                  if (isSecondWeightValidation) {
                                    return setError("secondWeight", {
                                      type: "validate",
                                    });
                                  }

                                  if (e.target.value === "") {
                                    return;
                                  }

                                  setValue(
                                    "firstWeight",
                                    Number(
                                      e.target.value.replace(/[,]/g, "")
                                    ).toLocaleString("ko-KR")
                                  );
                                }}
                              />
                            )}
                          />
                        </FlexOne>
                        <FlexOne>
                          <Input value={weightUnit} disabled />
                        </FlexOne>
                      </FlexRow>
                    </SectionCardRowItem>
                  }
                />

                <SectionCardRow
                  label={
                    <Typo typoType="b7m">
                      {t("task:detail.cards.secondWeight")}
                    </Typo>
                  }
                  direction="vertical"
                  value={
                    <SectionCardRowItem>
                      <FlexRow>
                        <FlexOne>
                          <Controller
                            name="secondWeight"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder="Enter weight"
                                data-invalid={!!errors.secondWeight}
                                onClear={() => {
                                  setValue("secondWeight", "");
                                  handleNetWeightAndGrossWeightChange();
                                }}
                                onChange={(e) => {
                                  clearErrors("secondWeight");
                                  setValue(
                                    "secondWeight",
                                    e.target.value.replace(/[^0-9.,]/g, "")
                                  );
                                  handleNetWeightAndGrossWeightChange();
                                }}
                                onBlur={(e) => {
                                  if (isSecondWeightValidation) {
                                    return setError("secondWeight", {
                                      type: "validate",
                                    });
                                  }

                                  if (e.target.value === "") {
                                    return;
                                  }

                                  setValue(
                                    "secondWeight",
                                    Number(
                                      e.target.value.replace(/[,]/g, "")
                                    ).toLocaleString("ko-KR")
                                  );
                                }}
                              />
                            )}
                          />
                        </FlexOne>
                        <FlexOne>
                          <Input value={weightUnit} disabled />
                        </FlexOne>
                      </FlexRow>

                      {errors.secondWeight?.type === "validate" && (
                        <StyledInputError
                          message={t("task:detail.errors.secondWeight")}
                        />
                      )}
                    </SectionCardRowItem>
                  }
                />

                <SectionCardRow
                  label={<Typo typoType="b7m">Gross Weight</Typo>}
                  direction="vertical"
                  value={
                    <SectionCardRowItem>
                      <FlexRow>
                        <FlexOne>
                          <Controller
                            name="grossWeight"
                            control={control}
                            render={({ field }) => (
                              <Input {...field} placeholder="-" disabled />
                            )}
                          />
                        </FlexOne>
                        <FlexOne>
                          <Input value={weightUnit} disabled />
                        </FlexOne>
                      </FlexRow>

                      <GrossWeightNotice>
                        <StyeldIcon iconSrc={InfoSvg} iconSize={16} />
                        <Typo typoType="b9r" color="gray6">
                          Gross Weight = First Weight - Second Weight
                        </Typo>
                      </GrossWeightNotice>
                    </SectionCardRowItem>
                  }
                />

                <SectionCardRow
                  label={<Typo typoType="b7m">Net Weight</Typo>}
                  direction="vertical"
                  value={
                    <SectionCardRowItem>
                      <FlexRow>
                        <FlexOne>
                          <Controller
                            name="netWeight"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder="-"
                                disabled={
                                  getValues("netWeightUse")[0] === "true"
                                }
                                onChange={(e) => {
                                  return setValue(
                                    "netWeight",
                                    e.target.value.replace(/[^0-9.,]/g, "")
                                  );
                                }}
                                onClear={() => {
                                  setValue("netWeight", "");
                                }}
                                onBlur={(e) => {
                                  if (e.target.value === "") {
                                    return;
                                  }

                                  setValue(
                                    "netWeight",
                                    Number(
                                      e.target.value.replace(/[,]/g, "")
                                    ).toLocaleString("ko-KR")
                                  );
                                }}
                              />
                            )}
                          />
                        </FlexOne>
                        <FlexOne>
                          <Input value={weightUnit} disabled />
                        </FlexOne>
                      </FlexRow>

                      <FormItem
                        type="checkbox"
                        control={control}
                        name="netWeightUse"
                        options={[
                          {
                            label: t("task:detail.dialog.sameAsGrossWeight"),
                            value: "true",
                          },
                        ]}
                        inputProps={{
                          onChange: () => {
                            if (watch("netWeightUse")[0] === "true") {
                              return setValue(
                                "netWeight",
                                getValues("grossWeight")
                              );
                            }

                            return setValue("netWeight", "");
                          },
                        }}
                      />
                    </SectionCardRowItem>
                  }
                />
              </StyledSectionCardValue>
            }
          />

          {/* Unit Q’ty */}
          <SectionCardRow
            label={<Typo typoType="b7m">Unit Q’ty</Typo>}
            direction="vertical"
            value={
              <SectionCardRowItem>
                <FlexRow>
                  <FlexOne>
                    <Controller
                      name="unitQuantity"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter qt’y"
                          onChange={(e) => {
                            return setValue(
                              "unitQuantity",
                              e.target.value.replace(/[^0-9.,]/g, "")
                            );
                          }}
                          data-invalid={!!errors.unitQuantity}
                          onClear={() => setValue("unitQuantity", "")}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              return;
                            }

                            setValue(
                              "unitQuantity",
                              Number(
                                e.target.value.replace(/[,]/g, "")
                              ).toLocaleString("ko-KR")
                            );
                          }}
                        />
                      )}
                    />
                  </FlexOne>
                  <FlexOne>
                    <Controller
                      name="unitQuantityUnitCodeItemName"
                      control={control}
                      render={({ field }) => (
                        <SearchSelect
                          {...field}
                          ref={field.ref}
                          getPopupContainer={(triggerNode: any) => {
                            return triggerNode.parentElement;
                          }}
                          options={unitQuantityUnitListToOptionList}
                          suffixIcon={
                            isUnitQuantityUnitListFetching ? (
                              <Loader />
                            ) : undefined
                          }
                        />
                      )}
                    />
                  </FlexOne>
                </FlexRow>
              </SectionCardRowItem>
            }
          />
        </EditContent>
      );
    }
  };

  // Mount Focus
  useEffect(() => {
    tabRef.current?.focus();

    if (initialSelectTab === "WEIGHT") {
      setFocus("containerNo");
    }
  }, [initialSelectTab, setFocus]);

  // Error Focus
  useEffect(() => {
    if (errors.secondWeight && selectTab === "WEIGHT") {
      setFocus("secondWeight");
    }
  }, [errors, selectTab, setFocus]);

  return (
    <>
      <Dialog
        title={t("task:detail.dialog.editInformation")}
        open={open}
        onOpenChange={onOpenChange}
        destroyDialogWhenEscapePress={false}
        onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
        width={1200}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
              ref={cancelButtonRef}
            >
              <Typo typoType="btn3m">
                {t("task:detail.button.cancelButton")}
              </Typo>
            </Button>
            <Button onClick={() => submitButtonRef.current?.click()}>
              <Typo typoType="btn3m" color="white">
                {t("task:detail.button.saveButton")}
              </Typo>
            </Button>
          </DialogFooterContainer>
        }
      >
        <DialogContent>
          {/* Image Slide */}
          <ImageGalleryContainer>
            <PhotoCollection
              items={
                imageUrlList.length >= 1
                  ? imageUrlList.map((item) => {
                      return { original: item, thumbnail: item };
                    })
                  : [
                      {
                        original: ImagePlaceholderImage,
                        thumbnail: ImagePlaceholderImage,
                      },
                    ]
              }
              imgProps={{
                style: { border: imageUrlList.length === 0 ? "none" : "" },
              }}
              startIndex={startPhotoIndex}
              onImageClick={(imageUrl) => handleSlideMainImageClick(imageUrl)}
              disableKeyDown
            />
          </ImageGalleryContainer>

          {/* Tabs */}
          <TabContent>
            <Tabs role="tablist">
              {TAB_LIST.map((item, idx) => {
                return (
                  <StyledTabItem
                    key={idx.toString()}
                    ref={item === selectTab ? tabRef : null}
                    tabIndex={item === selectTab ? 0 : -1}
                    data-selected={item === selectTab}
                    disabled={disableTabChange && item !== selectTab}
                    tabValue={item}
                    onClick={
                      disableTabChange
                        ? undefined
                        : () => {
                            setSelectTab(item);
                          }
                    }
                    onFocusItem={
                      disableTabChange
                        ? undefined
                        : (value) => {
                            setSelectTab(value);
                          }
                    }
                    type="button"
                  >
                    <Typo
                      typoType="b7m"
                      color={item === selectTab ? "gray2" : "gray6"}
                    >
                      {renderTabItemTitle(item)}
                    </Typo>
                  </StyledTabItem>
                );
              })}
            </Tabs>

            <Form onSubmit={handleSubmit(handleSubmitClick)}>
              {renderTabContent()}
              <Hidden type="submit" ref={submitButtonRef} />
            </Form>
          </TabContent>
        </DialogContent>
      </Dialog>

      {renderDialog()}
      {renderAlertDialog()}
    </>
  );
}

export default EditContainerInformationDialog;

const DialogContent = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 718px auto;
`;

const ImageGalleryContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
`;

const Form = styled.form`
  overflow: hidden;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const TabContent = styled.div`
  flex: 1;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px 0;
  cursor: pointer;
  background: none;
  border: none;

  &[data-selected="true"] {
    padding: 10px 0 8px;
    border-bottom: 2px solid ${colorSet.gray2};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const EditContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  max-height: 516px;
  overflow: auto;

  ${StyledScroll}
`;

const StyledSectionCardValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray9};
  padding: 16px;
`;

const SectionCardRowItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const FlexOne = styled.div`
  flex: 1;
`;

const GrossWeightNotice = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyeldIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledInputError = styled(InputError)`
  align-items: flex-start;
`;

const PhotoTypesContainer = styled.div`
  article {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 8px;
    overflow-y: auto;

    ${StyledScroll}
    section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      border: 1px solid ${colorSet.gray9};
      border-radius: 8px;
    }
  }
`;

const DeleteIcon = styled(Icon)`
  filter: drop-shadow(0px 2px 8px rgba(5, 29, 57, 0.25))
    drop-shadow(0px 0px 0px rgba(160, 164, 171, 0.2));
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImageListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 96px;
  gap: 16px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  min-height: calc(100vh - 300px);
`;
