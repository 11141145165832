import { ICellRendererParams, RowSelectedEvent } from "ag-grid-community";
import { Button } from "../components/atom/Button";
import Switch from "../components/atom/Switch";
import styled from "styled-components";
import typo from "../styles/typography";
import colorSet from "../styles/color";
import Icon from "../components/atom/Icon";
import Typo from "../components/atom/Typo";
import PencelSvg from "@/src/assets/icons/icon-edit-pencel.svg";
import CloseRedSvg from "@/src/assets/icons/icon-close-red.svg";
import CloseSvg from "@/src/assets/icons/icon-cancle-black.svg";
import DeleteSvg from "@/src/assets/icons/icon-delete.svg";
import store from "../store";
import Flex from "../components/molecule/Flex";

export const CELL_TYPE_TOGGLE_ACTION = "CELL_TYPE_TOGGLE_ACTION";
export const CELL_TYPE_EDIT_ACTION = "CELL_TYPE_EDIT_ACTION";
export const CELL_TYPE_DELETE_ACTION = "CELL_TYPE_DELETE_ACTION";
export const CELL_TYPE_UNDER_LINE_LINK_ACTION =
  "CELL_TYPE_UNDER_LINE_LINK_ACTION";
export const CELL_TYPE_VIEW_ACTION = "CELL_TYPE_VIEW_ACTION";
export const CELL_TYPE_EDIT_DELETE_ACTION = "CELL_TYPE_EDIT_DELETE_ACTION";
export const CELL_TYPE_DELETE_FILE_EMAIL = "CELL_TYPE_DELETE_FILE_EMAIL";

// CELL_TYPE_TOGGLE_ACTION : 토글 ------------------------------------------
export const renderToggleAciton = (
  onClick?: (id: RowSelectedEvent<any>) => void,
  detailKey?: string,
  disalbe?: boolean
) => {
  return function (props: ICellRendererParams) {
    const { data } = props;

    return (
      <Switch
        disabled={disalbe}
        checked={data[`${detailKey}`]}
        onClick={() => {
          if (detailKey && onClick) {
            onClick(data);
          }
        }}
        size="small"
      />
    );
  };
};

export const createToggleAction = (
  onClick?: (selectRow: any) => void,
  detailKey?: string,
  disabled?: boolean
) => {
  return {
    [CELL_TYPE_TOGGLE_ACTION]: renderToggleAciton(onClick, detailKey, disabled),
  };
};

// CELL_TYPE_EDIT_ACTION : 수정 ------------------------------------------
export const renderEditAction = (
  onClick?: (id: RowSelectedEvent<any>) => void
) => {
  return function (props: ICellRendererParams) {
    const lang = store.getState().lang.value;
    const { data } = props;

    return (
      <StyledButton
        buttonGrade="tertiary"
        buttonColor="black"
        buttonSize={24}
        onClick={() => onClick?.(data)}
      >
        <Icon iconSrc={PencelSvg} iconSize={16} />
        <Typo color="gray4" typoType="btn4m">
          {lang === "en" ? "Edit" : "수정"}
        </Typo>
      </StyledButton>
    );
  };
};

export const editAction = (
  onClick?: (id: RowSelectedEvent<{ uniqueId: string }>) => void
) => {
  return {
    [CELL_TYPE_EDIT_ACTION]: renderEditAction(onClick),
  };
};

// CELL_TYPE_EDIT_DELETE_ACTION : 수정 & 삭제 ------------------------------------------
const renderEditAndDelete = ({
  onEdit,
  onDelete,
}: {
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
}) => {
  return (data: ICellRendererParams) => {
    return (
      <Flex gap={4}>
        <IconButton
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={24}
          isIconOnly
          onClick={() => onEdit(data)}
        >
          <Icon iconSrc={PencelSvg} iconSize={16} />
        </IconButton>
        <IconButton
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={24}
          isIconOnly
          onClick={() => onDelete(data)}
        >
          <Icon iconSrc={DeleteSvg} iconSize={16} />
        </IconButton>
      </Flex>
    );
  };
};

export const editAndDeleteAction = ({
  onEdit,
  onDelete,
}: {
  onEdit: (data: ICellRendererParams) => void;
  onDelete: (data: ICellRendererParams) => void;
}) => {
  return {
    [CELL_TYPE_EDIT_DELETE_ACTION]: renderEditAndDelete({ onDelete, onEdit }),
  };
};

// CELL_TYPE_DELETE_ACTION : 삭제 ------------------------------------------
export const renderDeleteAction = (
  onClick?: (id: RowSelectedEvent<any>) => void
) => {
  return function (props: ICellRendererParams) {
    const lang = store.getState().lang.value;
    const { data } = props;

    return (
      <StyledButton
        buttonGrade="tertiary"
        buttonColor="red"
        buttonSize={24}
        onClick={() => onClick?.(data)}
      >
        <Icon iconSrc={CloseRedSvg} iconSize={16} />
        <Typo color="red2" typoType="btn4m">
          {lang === "en" ? "Delete" : "삭제"}
        </Typo>
      </StyledButton>
    );
  };
};

export const createDeleteAction = (
  onClick?: (id: RowSelectedEvent<{ uniqueId: string }>) => void
) => {
  return {
    [CELL_TYPE_DELETE_ACTION]: renderDeleteAction(onClick),
  };
};

// CELL_TYPE_UNDER_LINE_LINK_ACTION : 언더라인 스타일 상세이동 ------------------------------------------
export const renderUnderLineLinkAction = (
  onClick: (row: RowSelectedEvent<any>) => void,
  detailKey: string
) => {
  return function (props: ICellRendererParams) {
    const { data } = props;

    if (!!data[detailKey] === false) {
      return "-";
    }

    return (
      <LinkText
        typoType="b9m"
        color="systemBlue2"
        onClick={() => onClick?.(data)}
      >
        {data[detailKey]}
      </LinkText>
    );
  };
};

export const createUnderLineLinkAction = (
  onClick: (row: any) => void,
  detailKey: string
) => {
  return {
    [CELL_TYPE_UNDER_LINE_LINK_ACTION]: renderUnderLineLinkAction(
      onClick,
      detailKey
    ),
  };
};

// CELL_TYPE_VIEW_ACTION : 사진보기 ------------------------------------------
export const renderViewAction = (
  onClick?: (id: RowSelectedEvent<any>) => void
) => {
  return function (props: ICellRendererParams) {
    const { data, node } = props;
    const buttonText = data.lang === "en" ? "View" : "보기";

    return (
      <StyledButton
        ref={(ref) => {
          if (!ref) return;
          ref.onclick = (e) => {
            e.stopPropagation();
            onClick?.(data);
          };
        }}
        disabled={!node.selectable}
        buttonGrade="tertiary"
        buttonColor="black"
        buttonSize={24}
      >
        <Typo color="gray4" typoType="btn4m">
          {buttonText}
        </Typo>
      </StyledButton>
    );
  };
};

export const createViewAction = (
  onClick?: (id: RowSelectedEvent<{ uniqueId: string }>) => void
) => {
  return {
    [CELL_TYPE_VIEW_ACTION]: renderViewAction(onClick),
  };
};

// File - Send Email
export const renderDeleteFileEmailAction = (
  onClick: (rowData: ICellRendererParams["data"]) => void
) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={() => onClick(data)}
      >
        <StyledIcon iconSrc={CloseSvg} iconSize={18} />
      </Flex>
    );
  };
};

export const deleteAction = (
  onClick: (rowData: ICellRendererParams["data"]) => void
) => {
  return {
    [CELL_TYPE_DELETE_FILE_EMAIL]: renderDeleteFileEmailAction(onClick),
  };
};

const StyledButton = styled(Button)`
  ${typo.btn4m}
  height: 24px;
  color: ${colorSet.gray2};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const LinkText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
`;

const IconButton = styled(Button)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;
