import Badge from "@/src/components/atom/Badge";
import { SignatureStatusType } from "@/src/store/apis/contracts/interface";
import { TFunction } from "i18next";

const renderContractStatusBadge = (param: {
  t: TFunction;
  type: SignatureStatusType | null;
  lang: "en" | "ko";
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  switch (type) {
    case "DRAFT":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.draft")}
          color="systemGrape2"
          badgeColor="systemGrape6"
          badgeSize={size}
        />
      );

    case "SENT":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.sent")}
          color="yellow1"
          badgeColor="yellow6"
          badgeSize={size}
        />
      );

    case "PENDING":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.pending")}
          color="red2"
          badgeColor="red8"
          badgeSize={size}
        />
      );
    case "APPROVED":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.approved")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );
    case "REQUESTED":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.requested")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );
    case "LINKED":
      return (
        <Badge
          text={t("contract:importer.list.signatureBadge.linked")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export default renderContractStatusBadge;
