import { ColorType } from "../styles/color";

export const getPlanServiceCountColor = ({
  type,
  currentCount,
  planCount,
}: {
  type: "loading" | "member";
  currentCount: number;
  planCount: number;
}) => {
  let color: ColorType = "gray7";

  switch (type) {
    case "loading":
      if (planCount === 0) {
        return (color = "gray7");
      }
      if (currentCount > planCount) {
        color = "green1";
      }
      if (currentCount <= planCount) {
        color = "blue4";
      }
      if (currentCount === 0) {
        color = "red2";
      }
      return color;

    case "member":
      if (planCount === 0) {
        return (color = "gray7");
      }
      if (currentCount <= planCount) {
        color = "blue4";
      }
      if (currentCount > planCount) {
        color = "red2";
      }
      return color;
  }
};
