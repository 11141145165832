import styled from "styled-components";
import Typo, { TypoProps } from "../../atom/Typo";

interface TableBlueTextProps extends TypoProps {}

function TableBlueText(props: TableBlueTextProps) {
  return <BlueText color="systemBlue2" {...props} />;
}

export default TableBlueText;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  cursor: pointer;
`;
