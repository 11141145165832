import Badge from "@/src/components/atom/Badge";
import { BookingStatusType } from "@/src/store/apis/bookings/bookingList/interface";
import { TFunction } from "i18next";

const renderBookingStatusBadge = (param: {
  type: BookingStatusType | null;
  size?: "S" | "L";
  t: TFunction;
}) => {
  const { t, type, size = "S" } = param;

  if (type === null) {
    return;
  }

  switch (type) {
    case "TEMPORARY_BOOKING":
      return (
        <Badge
          text={t("booking:detail.badge.temporaryBooking")}
          color="systemOrange2"
          badgeColor="systemOrange6"
          badgeSize={size}
        />
      );

    case "CONFIRMED_BOOKING":
      return (
        <Badge
          text={t("booking:detail.badge.confirmedBooking")}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={size}
        />
      );

    case "CANCELED_BOOKING":
      return (
        <Badge
          text={t("booking:detail.badge.canceledBooking")}
          color="gray7"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export default renderBookingStatusBadge;
