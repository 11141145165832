import { HTMLAttributes, ReactNode } from "react";
import { styled } from "styled-components";
import Typo from "../../atom/Typo";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";

type ItemDirectionType = "horizontal" | "vertical";

interface SectionCardRowProps extends HTMLAttributes<HTMLDivElement> {
  direction?: ItemDirectionType;
  label?: ReactNode;
  value?: ReactNode | ReactNode[];
  className?: string;
}

const SectionCardRow = ({
  direction = "horizontal",
  label,
  value,
  className,
  ...rest
}: SectionCardRowProps) => {
  const isMultipleValue = Array.isArray(value);

  return (
    <RowContainer data-direction={direction} className={className} {...rest}>
      <LabelParagraph>{label}</LabelParagraph>

      {isMultipleValue ? (
        <MultipleValueContainer>
          {value.map((item, idx) => (
            <Typo key={idx.toString()} typoType="b7r" color="gray5" as="p">
              {item}
            </Typo>
          ))}
        </MultipleValueContainer>
      ) : (
        <SingleValueParagraph className="section-row-single-value">
          {value}
        </SingleValueParagraph>
      )}
    </RowContainer>
  );
};

export default SectionCardRow;

const MultipleValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingleValueParagraph = styled.p`
  ${typo.b7r};
  color: ${colorSet.gray5};
`;

const LabelParagraph = styled.p`
  ${typo.b7m};
  color: ${colorSet.gray2};
  min-width: 162px;
  max-width: 162px;
`;

const RowContainer = styled.div`
  display: flex;
  &[data-direction="horizontal"] {
    flex-direction: row;
    gap: 8px;
  }
  &[data-direction="vertical"] {
    flex-direction: column;
    gap: 8px;
  }

  ${SingleValueParagraph},${MultipleValueContainer} {
    word-break: keep-all;
    &[data-direction="horizontal"] {
      text-align: right;
      flex: 1;
    }
    &[data-direction="vertical"] {
    }
  }

  &[data-direction="horizontal"] {
    ${MultipleValueContainer} {
      gap: 12px;
    }
  }
`;
