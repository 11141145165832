import { RefObject } from "react";
import styled from "styled-components";
import colorSet from "@/src/styles/color";
import Icon from "../../atom/Icon";
import contractSvg from "@/src/assets/icons/icon-document-gary4.svg";
import bookingSvg from "@/src/assets/icons/icon-booking-gray4.svg";
import taskSvg from "@/src/assets/icons/icon-loading-gray4.svg";
import shipmentSvg from "@/src/assets/icons/icon-ship-gray4.svg";
import noticeSvg from "@/src/assets/icons/icon-notice-fill-blue4.svg";
import inquirySvg from "@/src/assets/icons/icon-inquiry-fill-blue4.svg";
import ribbonSvg from "@/src/assets/icons/icon-ribbon.svg";
import etcSvg from "@/src/assets/icons/icon-building-gray4.svg";
import Typo from "../../atom/Typo";
import getDateDifference from "@/src/utils/getDateDifference";
import { useLocation, useNavigate } from "react-router-dom";
import { PushAlarmDto } from "@/src/store/apis/pushAlarms/interface";
import { useAppSelector } from "@/src/store";
import { usePushAlarmReadMutation } from "@/src/store/apis/pushAlarms";
import useAlert from "@/src/hooks/useAlert";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
interface NotificationItemProps extends PushAlarmDto {
  domainRef: RefObject<HTMLButtonElement>;
  tabRef: RefObject<HTMLButtonElement>;
}

const notificationIcon = [
  { type: "CONTRACT", icon: contractSvg },
  { type: "BOOKING", icon: bookingSvg },
  { type: "TASK", icon: taskSvg },
  { type: "SHIPMENT", icon: shipmentSvg },
  { type: "NOTICE", icon: noticeSvg },
  { type: "INQUIRY", icon: inquirySvg },
  { type: "SUBSCRIPTION", icon: ribbonSvg },
  { type: "ETC", icon: etcSvg },
  { type: "EXPORTER", icon: etcSvg },
];

const NotificationItem = ({
  titleKr,
  titleEn,
  isRead,
  payloadString,
  domainType,
  createdAt,
  subTitleKr,
  subTitleEn,
  bodyKr,
  bodyEn,
  id,
  domainRef,
  tabRef,
  service,
}: NotificationItemProps) => {
  const alert = useAlert();
  const lang = useAppSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const location = useLocation();
  // export | import
  const pathName = location.pathname.split("/")[1];
  const iconSrc = notificationIcon.find((item) => item.type === domainType)
    ?.icon as string;

  const isKorean = lang === "ko" ? true : false;

  const [alarmUpdate] = usePushAlarmReadMutation();

  const handleNotificationClick = async () => {
    try {
      await alarmUpdate({ id, isRead: true });

      if (service === "COMMON_WEB") {
        return navigate(`/${pathName}/${payloadString}`);
      }

      navigate(`/${payloadString}`);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    }
  };

  return (
    <NotificationItemContainer
      onKeyDown={(e) => {
        if (e.shiftKey && e.code === "Tab") {
          domainRef.current?.focus();
        }

        if (e.code === "Tab") {
          tabRef.current?.focus();
        }
      }}
      onClick={handleNotificationClick}
    >
      {/* Badge, Icon */}
      <ReadBadge>{!isRead && <ReadBadgeIcon />}</ReadBadge>
      <NotificationIconContainer>
        <NotificationIcon iconSrc={iconSrc} iconSize={20} />
      </NotificationIconContainer>

      {/* Info */}
      <NotificationInfo>
        <Title>
          <Typo typoType="h7">{isKorean ? titleKr : titleEn}</Typo>
          <Typo typoType="b9r" color="gray7" className="update-date">
            {getDateDifference({
              updateAt: createdAt,
              disableUtcNow: true,
            })}
          </Typo>
        </Title>

        {(subTitleKr || subTitleEn) && (
          <SubTitle>
            <Typo typoType="b9r" color="blue2">
              {isKorean ? subTitleKr : subTitleEn}
            </Typo>
          </SubTitle>
        )}

        <Typo typoType="b7r" color="gray5">
          {isKorean ? bodyKr : bodyEn}
        </Typo>
      </NotificationInfo>
    </NotificationItemContainer>
  );
};

export default NotificationItem;

const NotificationItemContainer = styled(DropdownMenu.Item)`
  display: flex;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 24px;

  &:first-of-type {
    margin-top: 0;
  }

  &:focus {
    outline: none;
    background: ${colorSet.gray10};
  }
`;

const ReadBadge = styled.div`
  width: 10px;
  height: 10px;
  padding: 8px 0;
  flex-shrink: 0;
`;

const ReadBadgeIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${colorSet.red2};
`;

const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;

  .update-date {
    flex-shrink: 0;
  }
`;

const SubTitle = styled.div`
  span {
    display: inline-block;
    width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const NotificationIconContainer = styled.div`
  padding: 2px;
  flex-shrink: 0;
`;

const NotificationIcon = styled(Icon)`
  flex-shrink: 0;
`;
