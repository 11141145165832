import {
  Select as AntdSelect,
  SelectProps as AntdSelectProps,
  ConfigProvider,
} from "antd";
import { forwardRef } from "react";
import colorSet from "@/src/styles/color";
import Icon from "../Icon";
import SearchIcon from "@/src/assets/icons/icon-search.svg";

export interface SelectProps extends AntdSelectProps {}

const Select = forwardRef<any, SelectProps>((props: SelectProps, ref) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Pretendard Regular",
          controlItemBgHover: colorSet.gray10,
          borderRadius: 8,
          colorTextPlaceholder: colorSet.gray7,
          colorText: colorSet.gray2,
          controlOutline: "#1479eb",
          paddingSM: 17,
          colorBgContainerDisabled: colorSet.gray10,
          sizeUnit: 6,
          colorTextDisabled: colorSet.gray7,
          fontSizeIcon: 16,
        },
      }}
    >
      <AntdSelect
        ref={ref}
        size="large"
        showAction={["focus"]}
        onKeyDown={(e) => e.stopPropagation()}
        {...props}
        suffixIcon={
          props.showSearch ? (
            <Icon iconSrc={SearchIcon} iconSize={20} />
          ) : (
            props.suffixIcon ?? undefined
          )
        }
      />
    </ConfigProvider>
  );
});

export default Select;
