import colorSet from "@/src/styles/color";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "styled-components";

interface TextButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {}

const TextButton = (props: TextButtonProps) => {
  return <StyledTextButton role="button" {...props} />;
};

export default TextButton;

const StyledTextButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${colorSet.systemBlue2};
`;
