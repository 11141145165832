import { useEffect, useRef, useState } from "react";

interface UseTimerProps {
  sec?: number;
  onTimeExpired?: () => void;
  addZeroUnderTen?: boolean;
  start?: boolean;
}

const useTimer = ({
  sec = 180,
  addZeroUnderTen = true,
  onTimeExpired = () => {},
  start = true,
}: UseTimerProps = {}) => {
  const [time, setTime] = useState(sec);
  const [isPaused, setIsPaused] = useState(false);
  const pausedTimeRef = useRef<number | null>(null);
  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (start) {
      const countDown = () => {
        if (!isPaused) {
          setTime((prev) => prev - 1);
        }
      };
      intervalRef.current = setInterval(countDown, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPaused, start]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(intervalRef.current);
      onTimeExpired?.();
    }
  }, [time, onTimeExpired]);

  const handleRestart = () => {
    clearInterval(intervalRef.current);

    const countDown = () => setTime((prev) => prev - 1);
    intervalRef.current = setInterval(countDown, 1000);
    setTime(sec);
  };

  const handlePause = () => {
    if (!isPaused) {
      clearInterval(intervalRef.current);
      pausedTimeRef.current = time;
      setIsPaused(true);
    }
  };

  const handleResume = () => {
    if (isPaused) {
      setTime(pausedTimeRef.current ?? sec);
      setIsPaused(false);
      const countDown = () => {
        if (!isPaused) {
          setTime((prev) => prev - 1);
        }
      };
      intervalRef.current = setInterval(countDown, 1000);
    }
  };

  const handleReset = () => {
    clearInterval(intervalRef.current);
    setTime(sec);
    setIsPaused(false);
    pausedTimeRef.current = null;
  };

  const hour = Math.floor(time / 3600);
  const min = Math.floor((time - hour * 3600) / 60);
  const second = time % 60;

  if (!addZeroUnderTen) {
    return {
      hour: `${hour}`,
      min: `${min}`,
      second: `${second}`,
      restart: handleRestart,
      pauseOn: handlePause,
      pauseOff: handleResume,
      reset: handleReset,
    };
  }

  return {
    hour: hour < 10 ? `0${hour}` : `${hour}`,
    min: min < 10 ? `0${min}` : `${min}`,
    second: second < 10 ? `0${second}` : `${second}`,

    restart: handleRestart,
    pauseOn: handlePause,
    pauseOff: handleResume,
    reset: handleReset,
  };
};

export default useTimer;
