import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import PDFSvg from "@/src/assets/icons/icon-pdf.svg";
import ImageSvg from "@/src/assets/icons/icon-image-file-type.svg";
import EtcSvg from "@/src/assets/icons/icon-docs-type.svg";
import { CELL_TYPE_DELETE_FILE_EMAIL } from "@/src/utils/row-data-util";
import { ExportFileBoxTabType, tabToTitle } from "..";
import { imageFileExtensionList } from "@/src/constant/fileExtension";

export const fileBoxColumns = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { display: "flex", alignItems: "center" },
    field: "type",
    width: 70,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.fileExtension === "pdf") {
        return <Icon iconSrc={PDFSvg} iconSize={27} />;
      }
      if (imageFileExtensionList.includes(params.data.fileExtension)) {
        return <Icon iconSrc={ImageSvg} iconSize={27} />;
      }

      return <Icon iconSrc={EtcSvg} iconSize={27} />;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "fileCategory",
    width: 130,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "originalFileName",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams) => {
      const fileName = data.originalFileName;

      if (!imageFileExtensionList.includes(data.fileExtension)) {
        return (
          <Typo
            typoType="b9r"
            onClick={() => window.open(data.mediaUrl, "_blank")}
          >
            {fileName}
          </Typo>
        );
      } else {
        return <Typo typoType="b9r">{fileName}</Typo>;
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    width: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.createdAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.createdAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
] as ColDef[];

export const fileBoxAllColumns = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { display: "flex", alignItems: "center" },
    field: "type",
    width: 70,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.fileExtension === "pdf") {
        return <Icon iconSrc={PDFSvg} iconSize={27} />;
      }
      if (imageFileExtensionList.includes(params.data.fileExtension)) {
        return <Icon iconSrc={ImageSvg} iconSize={27} />;
      }

      return <Icon iconSrc={EtcSvg} iconSize={27} />;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sort",
    width: 130,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.fileClass) {
        return (
          <Typo typoType="b9r">
            {
              tabToTitle(
                params.data?.fileClass?.toLocaleLowerCase() as ExportFileBoxTabType
              )[params.data.lang as "en" | "ko"]
            }
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "fileCategory",
    width: 130,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "originalFileName",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams) => {
      const fileName = data.originalFileName;

      if (!imageFileExtensionList.includes(data.fileExtension)) {
        return (
          <Typo
            typoType="b9r"
            onClick={() => window.open(data.mediaUrl, "_blank")}
          >
            {fileName}
          </Typo>
        );
      } else {
        return <Typo typoType="b9r">{fileName}</Typo>;
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    width: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.createdAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.createdAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
] as ColDef[];

export const fileBoxEmailColumns = [
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { display: "flex", alignItems: "center" },
    field: "type",
    width: 70,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.fileExtension === "pdf") {
        return <Icon iconSrc={PDFSvg} iconSize={27} />;
      }
      if (imageFileExtensionList.includes(params.data.fileExtension)) {
        return <Icon iconSrc={ImageSvg} iconSize={27} />;
      }

      return <Icon iconSrc={EtcSvg} iconSize={27} />;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "originalFileName",
    flex: 1,
    sortable: false,
  },
  {
    width: 50,
    sortable: false,
    cellStyle: { display: "flex", alignItems: "center" },
    cellRenderer: CELL_TYPE_DELETE_FILE_EMAIL,
  },
] as ColDef[];
