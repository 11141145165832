const PUBLIC_PATH = {
  // Public
  ROOT: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  CORP_SIGNUP: "/corp-signup",
  STAFF_SIGNUP: "/staff-signup",
  TEMPORARY_SIGNUP: "/temporary-signup",
  CHANGE_PASSWORD: "/change-password",
  FIND_PASSWORD: "/find-password",
  TERMS: "/terms",
  NOT_FOUND: "/404",
};

export default PUBLIC_PATH;
