import { useEffect, useState } from "react";

const url = "https://api.country.is";

const useCountryCode = ({ skip }: { skip?: boolean }) => {
  const [countryCode, setCountryCode] = useState<string>();

  useEffect(() => {
    if (skip) return;
    (async () => {
      await fetch(url)
        .then((res) => {
          return res.json();
        })
        .then((res: { country: string }) => {
          setCountryCode(res.country);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, [skip]);

  return countryCode;
};

export default useCountryCode;
