import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import { CELL_TYPE_UNDER_LINE_LINK_ACTION } from "@/src/utils/row-data-util";
import renderBookingStatusBadge from "@/src/pages/exporter/Booking/utils/renderBookingStatusBadge";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { TFunction } from "i18next";

export const columnBookingList = (t: TFunction): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.bookingStatus"),
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "bookingStatus",
    flex: 1,
    minWidth: 142,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.bookingStatus) {
        return renderBookingStatusBadge({
          type: params.data.bookingStatus,
          t: params.data.t,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.bookingNo"),
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: CELL_TYPE_UNDER_LINE_LINK_ACTION,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.numberOfContainer"),
    cellStyle: { textAlign: "center" },
    field: "numberOfContainer",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.docCutOffAt"),
    cellStyle: { textAlign: "center" },
    field: "docCutOffAt",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.docCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.docCutOffAt).format(
              dayjs(params.data.docCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.cargoCutOffAt"),
    cellStyle: { textAlign: "center" },
    field: "cargoCutOffAt",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.cargoCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.cargoCutOffAt).format(
              dayjs(params.data.cargoCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.etdAt"),
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.etaAt"),
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.portOfLoading"),
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.getValue("portOfLoading")}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: t("contract:exporter.detail.bookingList.placeOfDelivery"),
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.getValue("placeOfDelivery")}</Typo>
      ) : (
        "-"
      );
    },
  },
];
