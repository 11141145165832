import { apiSlice } from "../baseQuery";
import { Row } from "../type";
import { LoginHomeDto } from "./interface";

const onBoardingApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOnBoardingImage: build.query<LoginHomeDto, void>({
      query: () => ({
        url: "/login-homes/viewable",
      }),
      transformResponse: (res: Row<LoginHomeDto>) => res.row,
    }),
  }),
});

export const { useGetOnBoardingImageQuery } = onBoardingApi;
