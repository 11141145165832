import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  GenerateWorkplaceDto,
  GenerateWorkplaceEmployeeDto,
  GetWorkplacesRequestParams,
  ModifyWorkplaceDto,
  ModifyWorkplaceEmployeeDto,
  WorkplaceEmployeeDto,
  WorkplaceListViewDto,
  WorkplaceViewDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["factoryEmployees", "workplaces", "workplace"],
});

const factoryApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    createFactory: build.mutation<Row<WorkplaceViewDto>, GenerateWorkplaceDto>({
      query: (body) => ({
        url: "/exporters/this/workplaces",
        method: "POST",
        body,
      }),
      invalidatesTags: ["workplaces"],
    }),
    getFactories: build.query<
      Rows<WorkplaceListViewDto>,
      GetWorkplacesRequestParams
    >({
      query: (params) => ({
        url: "/exporters/this/workplaces",
        params,
      }),
      providesTags: ["workplaces"],
    }),
    getFactory: build.query<WorkplaceViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/workplaces/${id}`,
      }),
      providesTags: ["workplace"],
      transformResponse: (res: Row<WorkplaceViewDto>) => res.row,
    }),
    editFactory: build.mutation<
      Row<WorkplaceViewDto>,
      ModifyWorkplaceDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/workplaces/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
      invalidatesTags: ["workplace"],
    }),
    createFactoryEmployees: build.mutation<
      Row<WorkplaceEmployeeDto>,
      GenerateWorkplaceEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/workplaces/${id}/workplace-employees`,
          method: "POST",
          body: restBody,
        };
      },
      invalidatesTags: ["factoryEmployees"],
    }),

    getFactoryEmployees: build.query<
      Rows<WorkplaceEmployeeDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: (params) => {
        const { id, ...restParams } = params;
        return {
          url: `/exporters/this/workplaces/${params.id}/workplace-employees`,
          params: restParams,
        };
      },
      providesTags: ["factoryEmployees"],
    }),

    editFactoryEmployees: build.mutation<
      Row<WorkplaceViewDto>,
      ModifyWorkplaceEmployeeDto & { id: number }
    >({
      query: (body) => {
        const { id, ...restBody } = body;
        return {
          url: `/exporters/this/workplace-employees/${body.id}`,
          method: "PUT",
          body: restBody,
        };
      },
      invalidatesTags: ["factoryEmployees"],
    }),
  }),
});

export const {
  useGetFactoriesQuery,
  useLazyGetFactoriesQuery,
  useGetFactoryQuery,
  useGetFactoryEmployeesQuery,
  useLazyGetFactoryEmployeesQuery,
  useCreateFactoryMutation,
  useEditFactoryMutation,
  useEditFactoryEmployeesMutation,
  useCreateFactoryEmployeesMutation,
} = factoryApi;
export { factoryApi };
