import React, { useEffect, useRef, useState } from "react";
import Dialog from "@/src/components/atom/Dialog";
import { styled } from "styled-components";
import { Button } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import { useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import { ExporterUserListViewDto } from "@/src/store/apis/corporate/staffManagement/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useEditStaffMutation } from "@/src/store/apis/corporate/staffManagement";
import { changeEmailCharacters } from "@/src/utils/format";
import EditAlertDialog from "./EditAlertDialog";
import dayjs from "dayjs";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import { authApi } from "@/src/store/apis/auth";
import { isUndefined } from "@/src/utils/is";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { ClientType, ExporterUserType } from "@/src/store/apis/auth/interface";
import { COMPANY_TYPE_STRINGS_DATA } from "../../CompanyInformationManagement/constant";
import { useTranslation } from "react-i18next";

interface EditDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  selectData: ExporterUserListViewDto | null;
}

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];

function EditDialog({ open, onOpenChange, selectData }: EditDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  // 기업이 Both 타입인지
  const isBothType =
    selectData?.exporterUserListQueryResultDto.exporter.companyType === "BOTH";

  // API
  const {
    mainCategoryCodeItemNames,
    exporterTelPrefix,
    exporterOfficeContactPrefix,
  } = authApi.endpoints.getSession.useQueryState(undefined, {
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        mainCategoryCodeItemNames: !isUnstable
          ? currentData?.row.exporter.mainCategoryCodeItemNames
          : [],
        exporterTelPrefix: !isUnstable
          ? currentData?.row.exporter.telPrefix ?? " "
          : " ",
        exporterOfficeContactPrefix: !isUnstable
          ? currentData?.row.exporter.faxPrefix ?? " "
          : " ",
      };
    },
  });

  const [editStaff] = useEditStaffMutation();
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const mainCategoryFilterList = mainCategoryCurrentData
    .filter((item) => mainCategoryCodeItemNames.includes(item.codeItemName))
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });

  // State
  const [isAdminAlertDialogOpen, setIsAdminAlertDialogOpen] = useState(false);
  const [isAdminSeconds, setIsAdminSeconds] = useState(false);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    register,
    setValue,
    setFocus,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: selectData?.name as string,
      email: changeEmailCharacters(selectData?.email as string),
      exporterUserType: selectData?.exporterUserType as ExporterUserType,
      isActivated: selectData?.isActivated === true ? "Y" : "N",
      mainCategory: selectData?.mainCategory
        ? selectData.exporterUserListQueryResultDto.mainCategoryCodeItemNames
        : [],
      personalContactPrefix: selectData?.personalContactPrefix
        ? selectData?.personalContactPrefix
        : exporterTelPrefix,
      personalContact: selectData?.personalContact ?? "",
      officeContactPrefix: selectData?.officeContactPrefix
        ? selectData?.officeContactPrefix
        : exporterOfficeContactPrefix,
      officeContact: selectData?.officeContact ?? "",
      exporterUserMainFieldType:
        selectData?.exporterUserMainFieldType as ClientType,
    },
  });

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const handleSubmitClick = async () => {
    if (!selectData) {
      return;
    }

    const params = {
      id: selectData.exporterUserListQueryResultDto.id,
      name: getValues("name"),
      email: selectData?.email,
      exporterUserType: getValues("exporterUserType"),
      mainCategoryCodeItemNames: getValues("mainCategory"),
      personalContactPrefix:
        getValues("personalContactPrefix") !== " "
          ? getValues("personalContactPrefix")
          : null,
      personalContact: getValues("personalContact") || null,
      officeContactPrefix:
        getValues("officeContactPrefix") !== " "
          ? getValues("officeContactPrefix")
          : null,
      officeContact: getValues("officeContact") || null,
      isActivated: getValues("isActivated") === "Y" ? true : false,
      exporterUserMainFieldType: getValues("exporterUserMainFieldType"),
    };

    try {
      await editStaff(params).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      onOpenChange(false);
    } catch (e: any) {
      // 기업관리자가 정해진 최대 인원수(2)를 초과하였을 경우
      if (
        e.data.errorCode ===
        "EXPORTER_USER_CORPORATE_MANAGER_MAX_COUNT_EXCEEDED"
      ) {
        setIsAdminAlertDialogOpen(true);
        setIsAdminSeconds(true);
      }

      // 기업관리자가 정해진 미만
      if (
        e.data.errorCode ===
        "EXPORTER_USER_CORPORATE_MANAGER_LESS_THAN_MIN_COUNT"
      ) {
        setIsAdminAlertDialogOpen(true);
        setIsAdminSeconds(false);
      }

      if (
        e.data.errorCode !==
          "EXPORTER_USER_CORPORATE_MANAGER_LESS_THAN_MIN_COUNT" &&
        e.data.errorCode !==
          "EXPORTER_USER_CORPORATE_MANAGER_MAX_COUNT_EXCEEDED"
      ) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  return (
    <Dialog
      title={t("staffManagement:importer.editDialog.title")}
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
            ref={cancelButtonRef}
            onKeyUp={(e) => {
              if (e.shiftKey && e.code === "Tab") {
                const target = document.getElementsByName(
                  "officeContact"
                )[0] as HTMLElement;

                if (target) {
                  target.focus();
                }
              }
            }}
          >
            <Typo typoType="btn3m">
              {t("staffManagement:importer.editDialog.cancelButton")}
            </Typo>
          </Button>

          <Button onClick={() => submitButtonRef.current?.click()}>
            <Typo color="white" typoType="btn3m">
              {t("staffManagement:importer.editDialog.saveButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      {/* 기업 관리자 1명 이상 Alert Dialog */}
      {isAdminAlertDialogOpen && (
        <EditAlertDialog
          isOpen={isAdminAlertDialogOpen}
          setIsOpen={setIsAdminAlertDialogOpen}
          handleSubmit={() => setIsAdminAlertDialogOpen(false)}
          isAdminSeconds={isAdminSeconds}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <FormItem
          label={t("staffManagement:importer.editDialog.name")}
          type="text"
          name="name"
          control={control}
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="vertical"
          inputProps={{
            placeholder: t(
              "staffManagement:importer.editDialog.namePlaceholder"
            ),
          }}
        />

        <FormItem
          label={t("staffManagement:importer.editDialog.email")}
          type="text"
          name="email"
          control={control}
          rules={{ required: true }}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="vertical"
          inputProps={{
            disabled: true,
          }}
        />

        <UserTypeContainer>
          <FormItem
            label={t("staffManagement:importer.editDialog.authority")}
            type="radioGroup"
            name="exporterUserType"
            rules={{ required: true }}
            control={control}
            options={[
              {
                label: t(
                  "staffManagement:importer.editDialog.authorityCorporateManager"
                ),
                value: "CORPORATE_MANAGER",
              },
              {
                label: t(
                  "staffManagement:importer.editDialog.authorityMiddleManager"
                ),
                value: "MIDDLE_MANAGER",
              },
              {
                label: t(
                  "staffManagement:importer.editDialog.authorityManager"
                ),
                value: "MANAGER",
              },
            ]}
            direction="vertical"
          />
          <Typo color="gray7" typoType="b9r">
            {t("staffManagement:importer.editDialog.exporterUserTypeNotice")}
          </Typo>
        </UserTypeContainer>

        <FormItem
          label={"Staff Type"}
          type="radioGroup"
          name="exporterUserMainFieldType"
          rules={{ required: true }}
          control={control}
          options={[
            {
              label: COMPANY_TYPE_STRINGS_DATA["BUYER"],
              value: "BUYER",
              disabled: !isBothType,
            },
            {
              label: COMPANY_TYPE_STRINGS_DATA["SELLER"],
              value: "SELLER",
              disabled: !isBothType,
            },
            {
              label: COMPANY_TYPE_STRINGS_DATA["BOTH"],
              value: "BOTH",
              disabled: !isBothType,
            },
          ]}
          direction="vertical"
        />

        <UseContainer>
          <FormItem
            label={t("staffManagement:importer.editDialog.use")}
            type="radioGroup"
            name="isActivated"
            control={control}
            rules={{ required: true }}
            options={[
              { label: "Y", value: "Y" },
              { label: "N", value: "N" },
            ]}
            direction="vertical"
          />

          <UseHistory>
            <li>
              <Typo color="gray6" typoType="b9r">
                {t("staffManagement:importer.editDialog.chageDate")}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {`(${
                  selectData?.exporterUserListQueryResultDto
                    .isActivatedChangedAt
                    ? dayjs(
                        selectData?.exporterUserListQueryResultDto
                          .isActivatedChangedAt
                      ).format("YYYY-MM-DD HH:mm")
                    : "-"
                })`}
              </Typo>
            </li>
            <li>
              <Typo color="gray6" typoType="b9r">
                {t("staffManagement:importer.editDialog.editor")}
              </Typo>
              <Typo color="gray7" typoType="b9r">
                {`${
                  selectData?.exporterUserListQueryResultDto
                    .isActivatedChangeExporterUser?.name
                    ? selectData?.exporterUserListQueryResultDto
                        .isActivatedChangeExporterUser?.name
                    : `(-)`
                }`}
              </Typo>
            </li>
          </UseHistory>
        </UseContainer>

        <FormItem
          label={t("staffManagement:importer.editDialog.categoryInCharge")}
          type="multipleSelect"
          name="mainCategory"
          control={control}
          options={mainCategoryFilterList}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{
            getPopupContainer: (triggerNode) => {
              return triggerNode.parentElement;
            },
            onRemoveItem: (value) => {
              const watchCategory = watch("mainCategory");
              const watchCategoryFilter = watchCategory.filter(
                (item) => item !== value
              );
              setValue("mainCategory", watchCategoryFilter);
            },
            placeholder: t(
              "staffManagement:importer.editDialog.categoryInChargePlaceholder"
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        <ContactFormItem
          label={t("staffManagement:importer.editDialog.contactPersonal")}
          prefixValue={watch("personalContactPrefix")}
          prefixName="personalContactPrefix"
          restContactName="personalContact"
          register={register}
          setValue={setValue}
          direction="vertical"
          isRequired={false}
        />

        <ContactFormItem
          label={t("staffManagement:importer.editDialog.contactOffice")}
          prefixValue={watch("officeContactPrefix")}
          prefixName="officeContactPrefix"
          restContactName="officeContact"
          register={register}
          setValue={setValue}
          direction="vertical"
          isRequired={false}
        />
        <Hidden
          ref={submitButtonRef}
          type="submit"
          onFocus={() => cancelButtonRef.current?.focus()}
        />
      </Form>
    </Dialog>
  );
}

export default EditDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const UseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UseHistory = styled.ul`
  display: flex;
  gap: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 4px;

    &::before {
      content: "•";
      color: ${colorSet.gray7};
    }
  }
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const UserTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
