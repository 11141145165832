import { useEffect, useMemo, useRef } from "react";
import { styled } from "styled-components";
import useAlert from "@/src/hooks/useAlert";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useAppSelector } from "@/src/store";
import { useCreateInquiryMutation } from "@/src/store/apis/inquiry";
import SectionCard from "@/src/components/molecule/SectionCard";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import FormItem from "@/src/components/molecule/FormItem";
import { useForm } from "react-hook-form";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import useContentLoading from "@/src/hooks/useContentLoading";
import { useTranslation } from "react-i18next";

type OptionType = {
  value: string;
  label: string;
};

const INQUIRY_SORT = [
  "INQUIRY_PAYMENT_SUBSCRIPTION",
  "INQUIRY_ACCOUNT_PERMISSIONS",
  "INQUIRY_DASHBOARD",
  "INQUIRY_CONTRACT",
  "INQUIRY_BOOKING",
  "INQUIRY_TASK_WEB",
  "INQUIRY_TASK_APP",
  "INQUIRY_SHIPMENT",
  "INQUIRY_ETC",
];

const ExporterInquiryAddPage = () => {
  const { t } = useTranslation();
  const { value: lang } = useAppSelector((state) => state.lang);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const { control, handleSubmit, setFocus } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      category: undefined,
      title: "",
      content: "",
    },
  });
  const navigate = useNavigate();
  const {
    currentData: inquiryCategory,
    isFetching: isInquiryCategoryFetching,
  } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "INQUIRY_CATEGORY",
    },
    { refetchOnMountOrArgChange: true }
  );
  const [createInquiry] = useCreateInquiryMutation();

  const sortedInquiryCategory = useMemo(
    () =>
      inquiryCategory?.slice().sort((prev, next) => {
        const prevIndex = INQUIRY_SORT.indexOf(prev.codeItemName);
        const nextIndex = INQUIRY_SORT.indexOf(next.codeItemName);

        if (prevIndex === -1) {
          return 1;
        }

        if (nextIndex === -1) {
          return -1;
        }

        return prevIndex - nextIndex;
      }) ?? [],
    [inquiryCategory]
  );

  const mesurementListToOptionList = inquiryCategory
    ? sortedInquiryCategory.reduce<OptionType[]>((acc, val) => {
        const resource = {
          label: lang === "en" ? val.codeItemNameEn : val.codeItemNameKo,
          value: val.codeItemName,
        };
        return [...acc, { ...resource }];
      }, [])
    : [];

  const handleCreateInquiry = async ({
    category,
    content,
    title,
  }: {
    category: undefined;
    title: string;
    content: string;
  }) => {
    try {
      handleContentLoadingOn();
      const { id } = await createInquiry({
        category: category,
        title: title,
        body: content,
      }).unwrap();

      navigate(`${EXPORTER_PRIVATE_PATH.INQUIRY}/${id}`);
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  useEffect(() => {
    setFocus("category");
  }, [setFocus]);

  return (
    <>
      <ExporterMainLayout
        breadcrumb={[t("sideNav:inquiry"), t("sideNav:inquiryRegistration")]}
        pageTitle={t("sideNav:inquiryRegistration")}
      >
        <Article>
          <SectionCard cardTitle={t("sideNav:inquiry")}>
            <StyledForm onSubmit={handleSubmit(handleCreateInquiry)}>
              <FormItem
                label="Category"
                rules={{
                  required: true,
                }}
                type="singleSelect"
                control={control}
                name="category"
                inputProps={{
                  suffixIcon: isInquiryCategoryFetching ? (
                    <Loader />
                  ) : undefined,
                  disabled: isInquiryCategoryFetching,
                  placeholder: "Select category",
                  filterOption: (input, option) =>
                    ((option?.label as string) ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase()),
                }}
                options={mesurementListToOptionList}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <FormItem
                label={"Title"}
                type={"text"}
                name={"title"}
                control={control}
                inputProps={{
                  placeholder: "Enter title",
                }}
                rules={{
                  required: true,
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <FormItem
                label={"Inquiry Detail"}
                type={"textarea"}
                name={"content"}
                control={control}
                inputProps={{
                  placeholder: "Enter contents",
                  maxLength: 2000,
                  style: {
                    height: "500px",
                  },
                }}
                rules={{
                  required: true,
                }}
                errorsMessage={{
                  required: t("error:required"),
                }}
              />
              <Hidden ref={buttonRef} type="submit" />
            </StyledForm>
          </SectionCard>

          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() => navigate(-1)}
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
                Back to Previous
              </Button>
              <Button
                buttonGrade="primary"
                style={{ width: 158, textAlign: "center" }}
                type="submit"
                onClick={() => buttonRef.current?.click()}
              >
                Save
              </Button>
            </FooterButtonSection>
          </BottomFixedContainer>
        </Article>
      </ExporterMainLayout>
    </>
  );
};

export default ExporterInquiryAddPage;

const Article = styled.article`
  margin-bottom: 72px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
