import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@/src/components/atom/Dialog";
import TextArea from "@/src/components/atom/TextArea";
import styled from "styled-components";
import { InputError } from "@/src/components/molecule/FormItem";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import { useTranslation } from "react-i18next";

interface RejectReasonDialogProps {
  onClose: () => void;
  onSubmit: (reason: string) => void;
}

const RejectReasonDialog = ({ onClose, onSubmit }: RejectReasonDialogProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLButtonElement | null>(null);
  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      reason: undefined,
    },
  });

  return (
    <Dialog
      title={t("contract:importer.detail.dialogTitle.rejectContract")}
      open
      onOpenChange={onClose}
      footer={
        <ButtonContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={40}
            onClick={onClose}
          >
            {t("contract:importer.detail.button.cancelButton")}
          </Button>
          <Button
            disabled={!watch("reason")}
            onClick={() => ref.current?.click()}
            isLoading={isSubmitting}
          >
            {t("contract:importer.detail.button.rejectSaveButton")}
          </Button>
        </ButtonContainer>
      }
    >
      <form onSubmit={handleSubmit(({ reason }) => reason && onSubmit(reason))}>
        <Memo>
          <Typo typoType="b7m">
            {t("contract:importer.detail.label.rejectionReason")}{" "}
            <Typo typoType="b7m" color="red2" as="strong">
              *
            </Typo>
          </Typo>
          <Controller
            control={control}
            name="reason"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <StyeldTextArea
                {...field}
                placeholder={t(
                  "contract:importer.detail.placeholder.rejectReason"
                )}
                maxLength={100}
                containerStyle={
                  errors.reason
                    ? {
                        outline: `1px solid ${colorSet.red2}`,
                      }
                    : undefined
                }
              />
            )}
          />
          {errors.reason && <InputError message={t("error:required")} />}
        </Memo>

        <Hidden type="submit" ref={ref} />
      </form>
    </Dialog>
  );
};

export default RejectReasonDialog;

const Memo = styled.div`
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyeldTextArea = styled(TextArea)`
  width: 100%;
  min-height: 218px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
