import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { setRedirectUrl } from "@/src/store/slice/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedPrivateRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const token = useAppSelector((state) => state.auth.token);
  const search = useLocation();

  if (!user) {
    dispatch(setRedirectUrl(`${location.pathname}${location.search}`));
    return <Navigate to={PUBLIC_PATH.LOGIN} replace />;
  }

  const getClientType = () => {
    if (isSame(user.exporterUserType, "CORPORATE_MANAGER")) {
      return user.exporter.companyType;
    }
    return user.exporterUserMainFieldType;
  };

  // / 입력시
  if (
    (token && search.pathname === PUBLIC_PATH.ROOT) ||
    search.pathname === "home"
  ) {
    /* 수입자 일 때 */
    if (isSame(getClientType(), "BUYER")) {
      return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} />;
    } else {
      /* BOTH 일 때, 수출자 일 때 */
      return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} />;
    }
  }

  return <Outlet />;
};

export default ProtectedPrivateRoute;

function isSame<T>(x: T, y: T): boolean {
  return x === y;
}
