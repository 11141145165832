import { useEffect } from "react";
import useCountryCode from "./hooks/useCountryCode";
import { useAppDispatch, useAppSelector } from "./store";
import { setCountryCode } from "./store/slice/auth";
import {
  useGetCurrencyQuery,
  useGetOneDayBeforeCurrencyQuery,
} from "./store/apis/currency";
import { CURRENCY_MAP } from "./utils/currency";

const CountryCode = () => {
  const country = useAppSelector((state) => state.auth.countryCode);
  const token = useAppSelector((state) => state.auth.token);
  const countryCode = useCountryCode({ skip: !!country || !token });
  const dispatch = useAppDispatch();

  useGetCurrencyQuery(
    { base: CURRENCY_MAP[country as string] as string },
    { skip: !CURRENCY_MAP[country as string] || !token }
  );
  useGetOneDayBeforeCurrencyQuery(
    {
      base: CURRENCY_MAP[country as string] as string,
    },
    { skip: !CURRENCY_MAP[country as string] || !token }
  );

  useEffect(() => {
    if (countryCode && token) {
      dispatch(setCountryCode(countryCode));
    }
  }, [countryCode, dispatch, token]);

  return <></>;
};

export default CountryCode;
