import { apiSlice } from "../baseQuery";
import { MessageDto } from "../corporate/companyItemManegement/interface";
import { Row, Rows } from "../type";
import {
  CancelFeeResponse,
  CompanyRejoinCheckRequest,
  FreeCodeDto,
  GeneratePaymentMethodDto,
  GeneratePlanInquiryDto,
  GenerateSubscriptionDto,
  GetEnterpriseInquiriesRequest,
  GetFreeCodesRequest,
  GetPaymentMethodsRequest,
  GetPaymentsRequest,
  GetPlansRequest,
  ModifyPaymentMethodDto,
  ModifySubscriptionDto,
  PaymentDto,
  PaymentMethodDto,
  PlanDto,
  PlanInquiryDto,
  SubscriptionDto,
  UseFreeCodeDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list", "card"],
});

const subscriptionApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 재가입 회사 여부 확인 API
    rejoinCheck: build.query<Row<MessageDto>, CompanyRejoinCheckRequest>({
      query: (params) => ({
        url: "/exporter/company-rejoin-check",
        params,
      }),
    }),

    // 구독플랜 목록 조회
    getPlans: build.query<Rows<PlanDto>, GetPlansRequest>({
      query: (params) => ({
        url: "/plans",
        params,
      }),
    }),

    // 구독플랜 단일 조회 API
    getPlan: build.query<PlanDto, { id: number }>({
      query: ({ id }) => ({
        url: `/plans/${id}`,
      }),
      transformResponse: (res: Row<PlanDto>) => res.row,
    }),

    // 구독 목록 조회
    getSubscriptions: build.query<
      Rows<SubscriptionDto>,
      { page?: number; pageSize?: number }
    >({
      query: (params) => ({
        url: "/subscriptions",
        params,
      }),
      providesTags: ["list"],
    }),

    // 구독 단일 조회 API
    getSubscription: build.query<SubscriptionDto, { id: number }>({
      query: ({ id }) => ({
        url: `/subscriptions/${id}`,
      }),
      transformResponse: (res: Row<SubscriptionDto>) => res.row,
      providesTags: ["list"],
    }),

    // 구독 목록 조회 1번째
    getCurrentSubscription: build.query<SubscriptionDto, void>({
      query: () => ({
        url: "/subscriptions",
        params: { page: 1, pageSize: 1 },
      }),
      transformResponse: (res: Rows<SubscriptionDto>) => res.rows[0],
      providesTags: ["list"],
    }),

    // 구독 생성
    createSubscriptions: build.mutation<
      Row<SubscriptionDto>,
      GenerateSubscriptionDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/subscriptions",
        body,
      }),
    }),

    /**
     * @description
     * 구독 단일 수정[해지, 구독변경 포함] API
     *
     * @description 구독변경
     * 월 결제 사용자 변경
     * - nextPlanPaymentCycle
     * - nextPlanId
     *
     * 연 결제 사용자
     * 즉시 해지 후 구독 새로등록
     *
     * @description 즉시해지
     * - subscriptionEndAtTo: dayjs().toISOString(), <--- 오늘날짜 입력
     * - isImmediateCancel: true,
     * - subscriptionStatus: "CANCELED"
     * @description 기간만기해지
     * - isImmediateCancel: false
     * - subscriptionStatus: "CANCELED"
     */
    updateSubscription: build.mutation<
      Row<SubscriptionDto>,
      ModifySubscriptionDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/subscriptions/${id}`,
        body,
      }),
      invalidatesTags: ["list"],
    }),

    // 무료 이용 기간 코드 목록 조회
    getFreeCodes: build.query<Rows<FreeCodeDto>, GetFreeCodesRequest>({
      query: (params) => ({
        url: "/free-codes",
        params,
      }),
    }),

    // 구독에 무료 이용 기간 코드사용
    freeCodeRegistered: build.mutation<
      Row<FreeCodeDto>,
      UseFreeCodeDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/subscriptions/${id}/use-free-code`,
        body,
      }),
      invalidatesTags: ["list"],
    }),

    // 구독 해지금 조회API
    getCancelFee: build.query<Row<CancelFeeResponse>, { id: number }>({
      query: ({ id }) => ({
        url: `/subscriptions/${id}/cancel-fee`,
      }),
    }),

    // 엔터프라이즈 플랜 문의 생성
    createEnterpriseInquiries: build.mutation<
      Row<PlanInquiryDto>,
      GeneratePlanInquiryDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/plan-inquiries",
        body,
      }),
    }),

    // 엔터프라이즈 플랜 문의 목록 조회
    getEnterpriseInquiries: build.query<
      Rows<PlanInquiryDto>,
      GetEnterpriseInquiriesRequest
    >({
      query: (params) => ({
        url: "/plan-inquiries",
        params,
      }),
    }),

    // 자신의 엔터프라이즈 플랜 문의 단일 조회 API
    getUserEnterpriseInquiries: build.query<
      Row<PlanInquiryDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/plan-inquiries/${id}`,
      }),
    }),

    // 새로운 결제수단 생성 API
    createPaymentMethods: build.mutation<
      Row<PaymentMethodDto>,
      GeneratePaymentMethodDto
    >({
      query: (body) => ({
        method: "POST",
        url: "/payment-methods",
        body,
      }),
    }),

    // 결재 수단 목록 조회
    getPaymentMethods: build.query<
      Rows<PaymentMethodDto>,
      GetPaymentMethodsRequest
    >({
      query: (params) => ({
        url: "/payment-methods",
        params,
      }),
      providesTags: ["card"],
    }),

    // 결재 수단 단일 조회 API
    getPaymentMethod: build.query<PaymentMethodDto, { id: number }>({
      query: ({ id }) => ({
        url: `/payment-methods/${id}`,
      }),
      transformResponse: (res: Row<PaymentMethodDto>) => res.row,
    }),

    // 결재 수단 단일 수정 API
    updatePaymentMethods: build.mutation<
      Row<PaymentMethodDto>,
      ModifyPaymentMethodDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/payment-methods/${id}`,
        body,
      }),
      invalidatesTags: ["card"],
    }),

    // 결재 수단 단일 삭제 API
    deletePaymentMethods: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/payment-methods/${id}`,
      }),
      invalidatesTags: ["card"],
    }),

    // 결재 목록 조회
    getPayments: build.query<Rows<PaymentDto>, GetPaymentsRequest>({
      query: (params) => ({
        url: "/payments",
        params,
      }),
    }),
  }),
});

export const {
  useLazyRejoinCheckQuery,

  // 플랜
  useGetPlansQuery,
  useLazyGetPlansQuery,
  useGetPlanQuery,
  useLazyGetPlanQuery,

  // 구독
  useGetSubscriptionsQuery,
  useLazyGetSubscriptionsQuery,
  useCreateSubscriptionsMutation,
  useUpdateSubscriptionMutation,
  useGetCurrentSubscriptionQuery,
  useLazyGetCurrentSubscriptionQuery,
  useLazyGetSubscriptionQuery,
  useGetSubscriptionQuery,

  // 무료기간 코드
  useLazyGetFreeCodesQuery,
  useFreeCodeRegisteredMutation,

  // 엔터프라이즈 문의
  useCreateEnterpriseInquiriesMutation,
  useGetEnterpriseInquiriesQuery,
  useLazyGetEnterpriseInquiriesQuery,
  useGetUserEnterpriseInquiriesQuery,

  // 결제수단
  useCreatePaymentMethodsMutation,
  useGetPaymentMethodsQuery,
  useLazyGetPaymentMethodsQuery,
  useUpdatePaymentMethodsMutation,
  useDeletePaymentMethodsMutation,
  useGetPaymentMethodQuery,
  useLazyGetPaymentMethodQuery,

  // 환불금
  useGetCancelFeeQuery,
  useLazyGetCancelFeeQuery,

  // 결제
  useLazyGetPaymentsQuery,
} = subscriptionApi;
export { subscriptionApi };
