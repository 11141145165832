import Flex from "@/src/components/molecule/Flex";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import { useAppSelector } from "@/src/store";
import renderSalesStatusBadge from "./utils/renderSalesStatusBadge";
import styled, { css } from "styled-components";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import Icon from "@/src/components/atom/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/src/components/atom/Button";
import { ReactComponent as CloseIconSvg } from "@/src/assets/icons/icon-cancle-black.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import SalesDetailMemo from "./components/detail/SalesDetailMemo";
import SalesDetailInformation from "./components/detail/SalesDetailInformation";
import SalesDetailContainerList from "./components/detail/SalesDetailContainerList";
import SalesDetailStatistics from "./components/detail/SalesDetailStatistics";
import Typo from "@/src/components/atom/Typo";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import {
  useGetSaleQuery,
  useGetSalesDetailBookmarkQuery,
  useGetSalesDetailBookmarkUserQuery,
  useSalesCloseMutation,
  useSalesRedoMutation,
  useUpdateSalesDetailBookmarkMutation,
} from "@/src/store/apis/sales";
import { useEffect, useRef, useState } from "react";
import useContentLoading from "@/src/hooks/useContentLoading";
import { isUndefined } from "@/src/utils/is";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import dayjs from "dayjs";
import useAlert from "@/src/hooks/useAlert";
import { ReactComponent as RedoSvg } from "@/src/assets/icons/icon-redo-blue.svg";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import { SalesBookmarkListViewDto } from "@/src/store/apis/sales/interface";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import { useTranslation } from "react-i18next";

enum DialogState {
  BOOK_MARK,
  NULL,
}

const bookmarkEmptyArray: SalesBookmarkListViewDto[] = [];

const ImporterSalesDetailPage = () => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const alert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [salesRedo, { isLoading: isSalesRedoLoading }] = useSalesRedoMutation();
  const [salesClose, { isLoading: isSalesCloseLoading }] =
    useSalesCloseMutation();
  const {
    isSaleDetailFetching,
    salesWriterName,
    salesCreatedAt,
    lastEditorName,
    salesUpdatedAt,
    salesStatus,
    salesNumber,
  } = useGetSaleQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isSaleDetailFetching: isFetching,
          salesStatus: currentData?.salesStatus,
          salesWriterName: isStable
            ? currentData.exporterUser?.name || "-"
            : "-",
          salesNumber: isStable ? currentData.salesNumber || "-" : "-",
          salesCreatedAt:
            isStable && currentData.createdAt
              ? `(${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD
                )},${dayjs(currentData.createdAt).format(
                  DATE_FORMAT_STRINGS.HH_mm
                )})`
              : "-",
          salesUpdatedAt:
            isStable && currentData.updatedAt
              ? `(${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.YY_MM_DD
                )},${dayjs(currentData.updatedAt).format(
                  DATE_FORMAT_STRINGS.HH_mm
                )})`
              : "-",
          lastEditorName: isStable ? currentData.lastEditor?.name || "-" : "-",
        };
      },
    }
  );
  const { bookmarkUserList } = useGetSalesDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          bookmarkUserList: !isUnstable
            ? currentData.rows ?? bookmarkEmptyArray
            : bookmarkEmptyArray,
        };
      },
    }
  );
  const [editContractBookmark] = useUpdateSalesDetailBookmarkMutation();

  const { isBookmarked } = useGetSalesDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return { isBookmarked: currentData?.isBookmarked };
      },
    }
  );

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };

      await editContractBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleOrderClosingClick = async () => {
    try {
      await salesClose({ id: Number(params.id) }).unwrap();
      alert.showAlert({ type: "success", message: "Order Closing" });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleSalesRedoClick = async () => {
    try {
      await salesRedo({ id: Number(params.id) }).unwrap();
      alert.showAlert({ type: "success", message: "Order Redo" });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }
  };

  useEffect(() => {
    if (isSaleDetailFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }
  }, [isSaleDetailFetching, handleContentLoadingOff, handleContentLoadingOn]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:sales"), t("sideNav:detailSales")]}
      pageTitle={`Contract No. ${salesNumber}`}
    >
      <Flex flexDirection="column" isFullWidth gap={16}>
        <StyledFlex justifyContent="space-between">
          {salesStatus &&
            renderSalesStatusBadge({
              type: salesStatus,
              lang,
              size: "L",
            })}

          {/* BookMark */}
          <BookMarkStatus
            bookMarkStatus={isBookmarked}
            bookMarkCount={bookmarkUserList.length}
            onClickBookMark={handleBookmarkClick}
            onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
          />
        </StyledFlex>

        {/* Content */}
        <SalesDetailStatistics />
        <Flex gap={16} isFullWidth>
          <SalesDetailInformation />
          <SalesDetailMemo />
        </Flex>
        <SalesDetailContainerList />

        {/* Last Editor */}
        <EditorContainer>
          <figure>
            <Icon iconSrc={DotGray7Svg} iconSize={4} />
            <Typo color="gray6" typoType="b9r">
              {`${t("sales:detail.editor.registrant")} (${t(
                "sales:detail.editor.registrantAt"
              )}) ${salesWriterName}`}
            </Typo>
            <Typo color="gray7" typoType="b9r">
              {salesCreatedAt}
            </Typo>
          </figure>
          <figure>
            <Icon iconSrc={DotGray4Svg} iconSize={4} />
            <Typo color="gray4" typoType="b9r">
              {`${t("sales:detail.editor.lastEditor")} (${t(
                "sales:detail.editor.lastEditorAt"
              )}) ${lastEditorName}`}
            </Typo>
            <Typo color="gray7" typoType="b9r">
              {salesUpdatedAt}
            </Typo>
          </figure>
        </EditorContainer>
      </Flex>

      {/* Bottom Fixed */}
      <BottomFixedContainer>
        <BottomButtonContainer>
          <StyledButton
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => navigate(-1)}
          >
            <Icon iconSrc={ChevronLeft} iconSize={16} />
            {t("sales:detail.button.backToPreviousButton")}
          </StyledButton>

          <figure>
            {salesStatus === "PROCESSING" && (
              <StyledButton
                buttonColor="red"
                buttonGrade="secondary"
                onClick={handleOrderClosingClick}
                isLoading={isSalesCloseLoading}
                disabled={isSalesCloseLoading}
              >
                <CloseIcon />
                {t("sales:detail.button.orderClosing")}
              </StyledButton>
            )}
            {salesStatus === "COMPLETE" && (
              <StyledButton
                buttonColor="blue"
                buttonGrade="secondary"
                onClick={handleSalesRedoClick}
                isLoading={isSalesRedoLoading}
                disabled={isSalesRedoLoading}
              >
                <RedoIcon />
                {t("sales:detail.button.RedoSales")}
              </StyledButton>
            )}
          </figure>
        </BottomButtonContainer>
      </BottomFixedContainer>

      {renderDialogs()}
    </ImporterMainLayout>
  );
};

export default ImporterSalesDetailPage;

const StyledFlex = styled(Flex)`
  padding-bottom: 18px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    display: flex;
    gap: 8px;

    .temporary-save {
      width: 136px;
      justify-content: center;
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CloseIcon = styled(CloseIconSvg)`
  width: 16px;
  height: 16px;

  path {
    fill: ${colorSet.red2};
  }

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.red6};
    }
  }
`;

const RedoIcon = styled(RedoSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 72px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
