import { Ref, forwardRef } from "react";
import {
  Radio as AntdRadio,
  RadioGroupProps as AntdRadioGroupProps,
} from "antd";
import { styled } from "styled-components";
import Radio from "../Radio";
import { FontSizeType } from "@/src/styles/typography";

export interface RadioGroupProps extends AntdRadioGroupProps {
  name: string;
  options: { value: string | number | boolean; label: string }[];
  typoType?: FontSizeType;
}

const RadioGroup = forwardRef(
  (
    { name, options, typoType, ...props }: RadioGroupProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <StyledRadioGroup name={name} ref={ref} {...props}>
        {options.map((item, idx) => {
          return (
            <Radio key={idx.toString()} typoType={typoType} value={item.value}>
              {item.label}
            </Radio>
          );
        })}
      </StyledRadioGroup>
    );
  }
);

export default RadioGroup;

export const StyledRadioGroup = styled(AntdRadio.Group)`
  // radio label
  & > label {
    gap: 10px;
    margin: 0;
    padding: 0;

    span:not(.ant-radio) {
      padding: 0;
    }
  }
`;
