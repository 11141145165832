import { apiSlice } from "../baseQuery";
import { Row } from "../type";
import {
  GenerateJsonTemporaryStorageDto,
  JsonTemporaryStorageDto,
  StorageTargetType,
} from "./interface";

const tempSaveApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    saveTemporaryData: build.mutation<
      JsonTemporaryStorageDto,
      GenerateJsonTemporaryStorageDto
    >({
      query: (body) => ({
        url: "/json-temporary-storages",
        method: "POST",
        body,
      }),
    }),
    getTemporaryData: build.query<
      Row<JsonTemporaryStorageDto>,
      { storageTarget: StorageTargetType }
    >({
      query: (params) => ({
        url: "/json-temporary-storages/this",
        params,
      }),
    }),
  }),
});

export const {
  useGetTemporaryDataQuery,
  useLazyGetTemporaryDataQuery,
  useSaveTemporaryDataMutation,
} = tempSaveApi;
