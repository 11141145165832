import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import { CiInfoDto, GenerateCiDto } from "./interface";

const ciApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 선적의 C/I 정보를 조회하는 API 입니다. C/I를 등록하지 않은 경우에도 해당 API를 호출하여 생성 화면으로 진입합니다.
    getCiInformation: build.query<CiInfoDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/ci-info`,
      }),
      transformResponse: (res: Row<CiInfoDto>) => res.row,
    }),

    // 선적의 초기 C/I 정보를 조회하는 API 입니다.
    getInitialCiInformation: build.query<CiInfoDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/initial-ci-info`,
      }),
      transformResponse: (res: Row<CiInfoDto>) => res.row,
    }),

    // Temporary 상태의 C/I를 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다. 또한, shipmentStatus가 COMPLETED인 경우 해당 요청은 불가합니다.
    createTemporaryCi: build.mutation<
      CiInfoDto,
      GenerateCiDto & { id: number }
    >({
      query: ({ id, ...restBody }) => ({
        url: `/exporters/this/shipments/${id}/temporary-ci-info-creation`,
        method: "POST",
        body: restBody,
      }),
    }),

    // Final 상태의 C/I를 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다. 또한, shipmentStatus가 COMPLETED인 경우 해당 요청은 불가합니다.
    createFinalCi: build.mutation<CiInfoDto, GenerateCiDto & { id: number }>({
      query: ({ id, ...restBody }) => ({
        url: `/exporters/this/shipments/${id}/final-ci-info-creation`,
        method: "POST",
        body: restBody,
      }),
    }),
  }),
});

export const {
  useGetCiInformationQuery,
  useLazyGetCiInformationQuery,
  useLazyGetInitialCiInformationQuery,

  useCreateTemporaryCiMutation,
  useCreateFinalCiMutation,
} = ciApi;

export { ciApi };
