import { useAppSelector } from "@/src/store";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import Icon from "../../atom/Icon";
import AddImageSvg from "@/src/assets/icons/icon-image-add.svg";
import { useUploadBigImageMutation } from "@/src/store/apis/media";
import { MediaDto, MediaFolderType } from "@/src/store/apis/media/interface";
import useAlert from "@/src/hooks/useAlert";

interface SingleImageUploadProps {
  onChange?: (media: MediaDto, type: "edit" | "create") => void;
  value?: string;
  topAccessary?: ({
    handleEdit,
    handleRemove,
  }: {
    handleEdit: () => void;
    handleRemove: () => void;
  }) => ReactNode;
  imgWidth?: number;
  imgHeight?: number;
  isNoneDescription?: boolean;
  disabled?: boolean;
  folder?: MediaFolderType;
  imageOnClick?: () => void;
}

function SingleImageUpload({
  onChange,
  value,
  topAccessary,
  imgWidth,
  imgHeight,
  isNoneDescription = false,
  disabled,
  folder = "commons/",
  imageOnClick,
}: SingleImageUploadProps) {
  const lang = useAppSelector((state) => state.lang.value);
  const ref = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | null>(value || null);
  const [uploadImage] = useUploadBigImageMutation();
  const alert = useAlert();

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    try {
      const response = await uploadImage({
        files: Array.from(e.target.files),
        folder,
      }).unwrap();
      await onChange?.(response[0], image ? "edit" : "create");
      onChange || setImage(response[0].mediaUrl);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      setImage(null);
      alert.showAlert({ type: "error", message });
    }
  };

  const handleRemove = () => {
    setImage(null);
  };

  useEffect(() => {
    setImage(value || null);
  }, [value]);

  return (
    <>
      {image ? (
        <ImageContainer>
          <StyledImg
            disabled={disabled}
            src={image}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            onClick={imageOnClick}
          />
          <TopAccessaryContainer>
            {topAccessary?.({
              handleEdit: () => ref.current?.click(),
              handleRemove,
            })}
          </TopAccessaryContainer>
        </ImageContainer>
      ) : (
        <UploadButton
          disabled={disabled}
          onClick={() => {
            ref.current?.click();
          }}
        >
          <Icon iconSrc={AddImageSvg} iconSize={24} />

          {!isNoneDescription
            ? lang === "en"
              ? "Please register the file"
              : "파일을 첨부하세요"
            : ""}
        </UploadButton>
      )}

      <HiddenInput
        ref={ref}
        type="file"
        multiple={false}
        accept="image/png, image/gif, image/jpeg"
        value={""}
        disabled={disabled}
        onChange={(e) => {
          handleUpload(e);
        }}
      />
    </>
  );
}

export default SingleImageUpload;

const UploadButton = styled.button.attrs({
  type: "button",
})`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: ${colorSet.blue10};
  border: 1px dotted ${colorSet.blue7};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colorSet.blue4};
  ${typo.b10m}

  &:disabled {
    cursor: not-allowed;
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  border: 1px solid ${colorSet.gray10};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img<{
  imgWidth?: number;
  imgHeight?: number;
  disabled?: boolean;
}>`
  object-fit: contain;
  ${({ imgHeight, imgWidth }) => css`
    width: ${imgWidth ? `${imgWidth}px` : "100%"};
    height: ${imgHeight ? `${imgHeight}px` : "100%"};
  `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const TopAccessaryContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
