import Dialog from "@/src/components/atom/Dialog";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "@/src/store";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { Button } from "@/src/components/atom/Button";
import AlertDialog from "@/src/components/atom/AlertDialog";
import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import Checkbox from "@/src/components/atom/Checkbox";
import { Controller, useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import { useNavigate } from "react-router-dom";
import useAlert from "@/src/hooks/useAlert";
import { staffApi } from "@/src/store/apis/corporate/staffManagement";
import {
  useWithdrawExporterAndUsersMutation,
  useWithdrawUserMutation,
} from "@/src/store/apis/auth";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import { persistor } from "@/src/index";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

interface WithdrawDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

enum AlertDialogState {
  NULL,
  CONFIRM,
}

const ACCOUNT_WITHDRAWAL_REASON_ETC = "ACCOUNT_WITHDRAWAL_REASON_ETC";

function WithdrawDialog({ open, onOpenChange }: WithdrawDialogProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const lang = useAppSelector((state) => state.lang.value);
  const userData = useAppSelector((state) => state.auth.user);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  const {
    currentData: withdrawalReasonList,
    isFetching,
    isError: withdrawalReasonListError,
  } = useGetCommonCodeViaCodeNameQuery({
    codeName: "ACCOUNT_WITHDRAWAL_REASON",
  });
  const { currentData: corporateUserList, isError } =
    staffApi.endpoints.getStaffs.useQueryState({
      isActivated: true,
      exporterUserType: "CORPORATE_MANAGER",
    });
  const [withdrawUser] = useWithdrawUserMutation();
  const [withdrawExporterAndUsers] = useWithdrawExporterAndUsersMutation();

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const { control, watch, setFocus, setValue } = useForm<{
    withdrawalReason: string;
    withdrawalReasonDirectInput: string;
    isConfirm: boolean;
  }>({
    mode: "onBlur",
    defaultValues: {
      withdrawalReason: "",
      withdrawalReasonDirectInput: "",
      isConfirm: false,
    },
  });

  const userType = userData?.exporterUserType;
  const isLastCorporateManager =
    corporateUserList?.rows.length === 1 && userType === "CORPORATE_MANAGER";

  const getWithdrawalReasonOptionList = () => {
    if (withdrawalReasonListError || withdrawalReasonList === undefined) {
      return [];
    }

    const withdrawalReasonListMaping = withdrawalReasonList.map(
      ({ codeItemNameKo, codeItemNameEn, codeItemName }) => {
        return {
          label: lang === "en" ? codeItemNameEn : codeItemNameKo,
          value: codeItemName,
        };
      }
    );

    const etcItem = withdrawalReasonListMaping.splice(
      withdrawalReasonListMaping.findIndex(
        (item) => item.value === ACCOUNT_WITHDRAWAL_REASON_ETC
      ),
      1
    )[0];

    withdrawalReasonListMaping.splice(
      withdrawalReasonListMaping.length,
      0,
      etcItem
    );

    return withdrawalReasonListMaping;
  };

  const getConfirmDisabledStatus = () => {
    let isDisabeld = false;

    if (watch("withdrawalReason") === ACCOUNT_WITHDRAWAL_REASON_ETC) {
      isDisabeld =
        watch("withdrawalReasonDirectInput").trim().length < 1;
    }

    if (!watch("isConfirm") || !watch("withdrawalReason")) {
      isDisabeld = true;
    }

    return isDisabeld;
  };

  const handleWithdrawClick = async () => {
    const withdrawalReason =
      watch("withdrawalReason") === ACCOUNT_WITHDRAWAL_REASON_ETC
        ? watch("withdrawalReasonDirectInput")
        : (withdrawalReasonList?.find(
            ({ codeItemName }) => codeItemName === watch("withdrawalReason")
          )?.codeItemNameKo as string);

    try {
      // 마지막 기업관리자
      if (isLastCorporateManager) {
        await withdrawExporterAndUsers({ withdrawalReason }).unwrap();
      } else {
        await withdrawUser({ withdrawalReason }).unwrap();
      }

      persistor.purge();
      navigate(PUBLIC_PATH.LOGIN);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) {
      return null;
    }

    if (alertDialogState === AlertDialogState.CONFIRM) {
      return (
        <AlertDialog
          open={open}
          title={t("myPage:exporter.withdrawAlertDialog.title")}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onCancel={() => {
            setAlertDialogState(AlertDialogState.NULL);
            setFocus("withdrawalReason");
          }}
          onOk={handleWithdrawClick}
          okText={t("myPage:exporter.button.withdrawAlertDialogSubmit")}
          cancelText={t("myPage:exporter.button.withdrawAlertDialogCancel")}
        />
      );
    }
  };

  const renderNotice = () => {
    if (isLastCorporateManager) {
      return t("myPage:exporter.withdrawDialog.CorporateManagerNotice");
    }

    return t("myPage:exporter.withdrawDialog.notice");
  };

  const renderDescription = () => {
    let descriptionList = [];

    if (isLastCorporateManager) {
      descriptionList = t(
          "myPage:exporter.withdrawDialog.CorporateManagerDescriptionList"
      ).split("\n");
    } else {
      descriptionList = t(
          "myPage:exporter.withdrawDialog.descriptionList"
      ).split("\n");
    }

    return descriptionList.map((item, idx) => {
      return (
          <Description key={idx.toString()}>
            <Typo typoType="b7r">{item}</Typo>
          </Description>
      );
    });
  };

  const renderDialogBodyContent = () => {
    if (isError || corporateUserList === undefined) {
      return null;
    }

    return (
      <WithdrawDialogContainer>
        <Typo typoType="h7">{renderNotice()}</Typo>
        <DescriptionList>{renderDescription()}</DescriptionList>

        <Form
          onSubmit={(e) => {
            e.preventDefault();

            !getConfirmDisabledStatus() &&
              setAlertDialogState(AlertDialogState.CONFIRM);
          }}
        >
          <FormItem
            label={t("myPage:exporter.withdrawDialog.reasonLabel")}
            rules={{ required: true }}
            control={control}
            name="withdrawalReason"
            type="singleSelect"
            direction="vertical"
            inputProps={{
              suffixIcon: isFetching ? <Loader /> : undefined,
              placeholder: t(
                "myPage:exporter.withdrawDialog.reasonPlaceholder"
              ),
              showAction: ["click"],
              showSearch: false,
              getPopupContainer: (triggerNode) => {
                return triggerNode.parentElement;
              },
            }}
            options={getWithdrawalReasonOptionList()}
          />

          {watch("withdrawalReason") === ACCOUNT_WITHDRAWAL_REASON_ETC && (
            <FormItem
              control={control}
              name="withdrawalReasonDirectInput"
              type="textarea"
              inputProps={{
                maxLength: 100,
                placeholder: t(
                  "myPage:exporter.withdrawDialog.reasonDirectInputPlaceholder"
                ),
              }}
            />
          )}

          <GrayLine />

          <Controller
            control={control}
            name="isConfirm"
            render={({ field }) => (
              <CheckboxWithText>
                <Checkbox
                  ref={field.ref}
                  checked={field.value}
                  id="isConfirm"
                  onChange={() => setValue("isConfirm", !field.value)}
                />
                <label htmlFor="isConfirm">
                  <Typo typoType="b7r">
                    {t("myPage:exporter.withdrawDialog.checkboxConfirmText")}
                  </Typo>
                </label>
              </CheckboxWithText>
            )}
          />

          <HiddenButton
            ref={submitButtonRef}
            type="submit"
            onClick={() => {
              !getConfirmDisabledStatus() &&
                setAlertDialogState(AlertDialogState.CONFIRM);
            }}
            onFocus={() => cancelButtonRef.current?.focus()}
          >
            hidden
          </HiddenButton>
        </Form>
      </WithdrawDialogContainer>
    );
  };

  useEffect(() => {
    setFocus("withdrawalReason");
  }, [setFocus]);

  return (
    <>
      <Dialog
        title={t("myPage:exporter.withdrawDialog.title")}
        open={open}
        onOpenChange={onOpenChange}
        footer={
          <DialogFooterContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={() => onOpenChange(false)}
              ref={cancelButtonRef}
              onKeyUp={(e) => {
                if (e.shiftKey && e.code === "Tab") {
                  setFocus("isConfirm");
                }
              }}
            >
              {t("myPage:exporter.button.withdrawDialogCancel")}
            </Button>
            <Button
              disabled={getConfirmDisabledStatus()}
              onClick={() => submitButtonRef.current?.click()}
            >
              {t("myPage:exporter.button.withdrawDialogConfirm")}
            </Button>
          </DialogFooterContainer>
        }
      >
        {renderDialogBodyContent()}
      </Dialog>

      {renderAlertDialog()}
    </>
  );
}

export default WithdrawDialog;

const WithdrawDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const DescriptionList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 20px;
  padding: 24px 16px;

  background: ${colorSet.gray11};
`;

const Description = styled.li`
  list-style: disc;
  margin-left: 16px;
`;

const GrayLine = styled.div`
  border-bottom: 1px solid ${colorSet.gray9};
`;

const CheckboxWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HiddenButton = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
