import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import {
  BookingContractShareInfoV2Dto,
  GetBookingSharedRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const bookingShareApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 이메일로 전송받은 bookingShareKey와 bookingId, contractShareKey로 부킹 단일 조회 API
    getBookingShared: build.query<
      Row<BookingContractShareInfoV2Dto>,
      GetBookingSharedRequest
    >({
      query: (params) => ({
        url: "/v2/bookings/this",
        params,
      }),
      providesTags: [""],
    }),
  }),
});

export const { useLazyGetBookingSharedQuery } = bookingShareApi;
export { bookingShareApi };
