import { useSearchParams } from "react-router-dom";
import ContractAddBasedOnPoPage from "./components/add/ContractAddBasedOnPo";
import ContractAddPage from "./components/add/ContractAddPage";

const ExporterContractAddPage = () => {
  const [params] = useSearchParams();
  const isBasedOnPo = !!params.get("id");

  return (
    <>
      {isBasedOnPo ? (
        <ContractAddBasedOnPoPage id={Number(params.get("id"))} />
      ) : (
        <ContractAddPage />
      )}
    </>
  );
};

export default ExporterContractAddPage;
