import { forwardRef } from "react";
import styled, { css } from "styled-components";
import Button, { ButtonProps, ButtonSizeType } from "./BasicButton";

interface IconButtonProps extends Omit<ButtonProps, "isIconOnly"> {}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    return (
      <StyledIconButton
        {...props}
        buttonGrade={props.buttonGrade ?? "tertiary"}
        ref={ref}
        isIconOnly
      />
    );
  }
);

export default IconButton;

const getButtonPadding = (size: ButtonSizeType) => {
  switch (size) {
    case 40:
      return css`
        padding: 10px;
      `;
    case 32:
      return css`
        padding: 6px;
      `;
    case 24:
      return css`
        padding: 4px;
      `;
  }
};

const StyledIconButton = styled(Button)`
  ${({ buttonSize }) => getButtonPadding(buttonSize ?? 40)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
