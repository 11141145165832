import { ReactNode } from "react";
import styled from "styled-components";
import GreenLogoSvg from "@/src/assets/logos/logo-export.svg";
import { useNavigate } from "react-router-dom";
import PublicFooter from "../../molecule/PublicFooter";
import PUBLIC_PATH from "@/src/routes/public/path";

interface SignUpLayoutProps {
  children?: ReactNode;
  onLogoClick?: () => void;
  isLogoClick?: boolean;
}

function SignUpLayout({
  children,
  onLogoClick,
  isLogoClick = true,
}: SignUpLayoutProps) {
  const navigate = useNavigate();

  return (
    <Layout>
      <StyledHeader>
        <span
          className="green-logo"
          onClick={() =>
            isLogoClick &&
            (onLogoClick ? onLogoClick() : navigate(PUBLIC_PATH.LOGIN))
          }
        />
      </StyledHeader>

      <Flex>{children}</Flex>

      <PublicFooter />
    </Layout>
  );
}

export default SignUpLayout;

const Layout = styled.div`
  min-width: 1250px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Flex = styled.div`
  flex: 1;
`;
const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;

  span.green-logo {
    display: inline-block;
    width: 156px;
    height: 24px;
    background: url(${GreenLogoSvg}) center center/contain no-repeat;
    cursor: pointer;
  }
`;
