import dayjs from "dayjs";

/**
 *
 * @param expiredDate "0122"
 * @description
 * - 신용카드 만료일의 날짜형식이 맞는지 확인하는 함수
 */
function isValidExpirationDate(expiredDate: string) {
  const currentYear = dayjs().get("year");
  const currentMonth = dayjs().get("month") + 1;
  const month = parseInt(expiredDate.substring(0, 2));
  const year = parseInt(expiredDate.substring(2, 4)) + 2000;

  // 01 ~ 12 확인
  if (month < 1 || month > 12) {
    return false;
  }

  // 입력한 연도 지난 연도 인지 확인
  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false;
  }

  return true;
}

/**
 *
 * @param birthDay "120122"
 * @description
 * - 생년월일 월,일 날짜형식이 맞는지 확인하는 함수
 */
function isValidBirthDay(birthDay: string) {
  const month = parseInt(birthDay.substring(2, 4));
  const day = parseInt(birthDay.substring(4, 6));

  // 01 ~ 12 확인
  if (month < 1 || month > 12) {
    return false;
  }

  // 01 ~ 31 확인
  if (day < 1 || day > 31) {
    return false;
  }

  return true;
}

export { isValidExpirationDate, isValidBirthDay };
