import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../../baseQuery";
import { Rows } from "../../type";
import { BookingListViewDto, GetBookingListRequest } from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list", "importer-list"],
});

const bookingListApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 부킹 목록 조회
    getBookingList: build.query<
      Rows<BookingListViewDto>,
      GetBookingListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/bookings",
        params,
      }),
      providesTags: ["list"],
    }),

    // 부킹 카테고리 필터된 목록 조회
    getBookingCategoryFilterList: build.query<
      Rows<BookingListViewDto>,
      GetBookingListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/bookings",
        params: { isExporterUserMainCategoryFilterOn: true, ...params },
      }),
      providesTags: ["list"],
    }),

    // 부킹 리스트를 엑셀로 다운로드 하는 API 입니다
    getBookingExcelList: build.query<void, GetBookingListRequest>({
      query: (params) => ({
        url: "/exporters/this/bookings-excel",
        params: {
          isExporterUserMainCategoryFilterOn: true,
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "booking_list.xlsx",
          });
        },
      }),
    }),

    getImporterBookingList: build.query<
      Rows<BookingListViewDto>,
      GetBookingListRequest
    >({
      query: (params) => ({
        url: "/importers/this/bookings",
        params,
      }),
      providesTags: ["importer-list"],
    }),
  }),
});

export const {
  useLazyGetBookingListQuery,
  useGetBookingListQuery,
  useLazyGetBookingExcelListQuery,

  useGetBookingCategoryFilterListQuery,
  useLazyGetBookingCategoryFilterListQuery,

  useLazyGetImporterBookingListQuery,
} = bookingListApi;
export { bookingListApi };
