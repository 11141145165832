import React, { useEffect, useRef, useState } from "react";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import {
  ExporterItemCodeListViewV2Dto,
  ExporterItemListViewV2Dto,
  GetItemManagementListRequest,
} from "@/src/store/apis/corporate/companyItemManegement/interface";
import { AgGridReact } from "ag-grid-react";
import useAlert from "@/src/hooks/useAlert";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateBulkItemCodeMutation,
  useGetItemListQuery,
  useLazyGetItemCodeListQuery,
} from "@/src/store/apis/corporate/companyItemManegement";
import { isUndefined } from "@/src/utils/is";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { ColDef } from "ag-grid-community";
import { columnItemList } from "./columns/columnItemList";
import AddItemDialog, { AddItemType } from "./components/dialog/AddItemDialog";
import EditItemDialog from "./components/dialog/EditItemDialog";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import styled from "styled-components";
import Filter from "@/src/components/molecule/Filter";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import { Button } from "@/src/components/atom/Button";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import Table from "@/src/components/atom/Table";
import { editAction } from "@/src/utils/row-data-util";
import { useTranslation } from "react-i18next";

const Init: GetItemManagementListRequest = {
  item: "all",
  mainCategoryCodeItemName: "all",
  subCategoryCodeItemName: "all",
  isActivated: "all" as unknown as boolean,
};

const FAST_SEARCH_QUERY_KEY = [
  "mainCategoryCodeItemName",
  "subCategoryCodeItemName",
  "item",
  "isActivated",
];

const ACTIVATED_OPTION_LIST = [
  { value: "true", label: "Y" },
  { value: "false", label: "N" },
];

enum DialogState {
  NULL,
  ADD_ITEM,
  EDIT_ITEM,
}

const optionListEmptyArray: PartialCommonCodeItemDto[] = [];
const itemListEmptyArray: ExporterItemListViewV2Dto[] = [];

const ImporterItemManagementPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  // API
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [createBulkItemCode] = useCreateBulkItemCodeMutation();

  const [getItemCodes, { isFetching, itemList, count, isError }] =
    useLazyGetItemCodeListQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isFetching,
          itemList: !isUnstable ? currentData?.rows : undefined,
          count: !isUnstable ? currentData?.count : 0,
          isError,
        };
      },
    });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? currentData
            : optionListEmptyArray,
        };
      },
    }
  );
  const { subCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "SUB_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          subCategoryCurrentData: !isErrorAndUndefined
            ? currentData
            : optionListEmptyArray,
        };
      },
    }
  );
  const { itemOptionList } = useGetItemListQuery(
    { isManagerFilterOn: true },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isErrorAndUndefined = isError || currentData === undefined;

        return {
          itemOptionList: !isErrorAndUndefined
            ? currentData.rows ?? itemListEmptyArray
            : itemListEmptyArray,
        };
      },
    }
  );

  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const subCategoryFilterList = subCategoryCurrentData
    ? subCategoryCurrentData.map((item) => {
        return {
          label: item.codeItemNameEn,
          value: item.codeItemName,
        };
      })
    : [];
  const itemOptionFilterList = itemOptionList?.map((item) => {
    return { label: item.item, value: String(item.id) };
  });
  const allOptionItem = {
    label: t("itemManagement:importer.list.placeholder.all"),
    value: "all",
  };

  // State
  const [columnDefs] = useState<ColDef[]>(columnItemList);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [selectItemCode, setSelectItemCode] =
    useState<ExporterItemCodeListViewV2Dto | null>(null);

  const handleEditClick = (row: ExporterItemCodeListViewV2Dto) => {
    setSelectItemCode(row);
    setDialogState(DialogState.EDIT_ITEM);
  };

  const handleAddItems = async (params: AddItemType) => {
    try {
      await createBulkItemCode(params).unwrap();
      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.NULL) {
      return;
    }

    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open={dialogState === DialogState.ADD_ITEM}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={handleAddItems}
        />
      );
    }

    if (dialogState === DialogState.EDIT_ITEM && selectItemCode) {
      return (
        <EditItemDialog
          open={dialogState === DialogState.EDIT_ITEM}
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectItemCode(null);
          }}
          selectItemCode={selectItemCode}
        />
      );
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "item",
        langKey: "itemManagement:importer.list.table.item",
      },
      {
        columnKey: "itemCode",
        langKey: "itemManagement:importer.list.table.itemCode",
      },
      {
        columnKey: "subCategory",
        langKey: "itemManagement:importer.list.table.subCategory",
      },
      {
        columnKey: "mainCategory",
        langKey: "itemManagement:importer.list.table.mainCategory",
      },
      {
        columnKey: "description",
        langKey: "itemManagement:importer.list.table.description",
      },
      {
        columnKey: "isActivated",
        langKey: "itemManagement:importer.list.table.isActivated",
      },
      {
        columnKey: "edit",
        langKey: "itemManagement:importer.list.table.edit",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<
    GetItemManagementListRequest,
    GetItemManagementListRequest
  >({
    isReady,
    gridRef: gridRef.current,
    fetch: getItemCodes,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        subCategoryCodeItemName:
          data.subCategoryCodeItemName === "all"
            ? undefined
            : data?.subCategoryCodeItemName,
        itemCode: data?.itemCode || undefined,
        item:
          data.item === "all"
            ? undefined
            : itemOptionFilterList.find(({ value }) => value === data.item)
                ?.label,
        isActivated:
          (data?.isActivated as unknown as string) === "all"
            ? undefined
            : (data?.isActivated as any) === "true",
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        subCategoryCodeItemName: urlObject.subCategoryCodeItemName ?? "all",
        item:
          itemOptionFilterList.find(({ label }) => urlObject.item === label)
            ?.value ?? "all",
        itemCode: urlObject.itemCode,
        isActivated: (urlObject.isActivated
          ? urlObject.isActivated === "true"
            ? "true"
            : "false"
          : "all") as unknown as boolean,
      };
    },
  });

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:importManagement"), t("sideNav:itemManagement")]}
      pageTitle={t("sideNav:itemManagement")}
    >
      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "mainCategoryCodeItemName",
              label: t("itemManagement:importer.list.filter.mainCategory"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:importer.list.placeholder.all"),
                allowClear: filterData.mainCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "subCategoryCodeItemName",
              label: t("itemManagement:importer.list.filter.subCategory"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:importer.list.placeholder.all"),
                allowClear: filterData.subCategoryCodeItemName !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("subCategoryCodeItemName", "all");
                    onForceSearch("subCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("subCategoryCodeItemName", value);
                    onForceSearch("subCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "item",
              label: t("itemManagement:importer.list.filter.item"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:importer.list.placeholder.all"),
                allowClear: filterData.item !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("item", "all");
                    onForceSearch("item", "all");
                  } else {
                    onFilterDataChange("item", value);
                    onForceSearch("item", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("itemManagement:importer.list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t(
                  "itemManagement:importer.list.placeholder.itemCode"
                ),
              },
            },
            {
              key: "isActivated",
              label: t("itemManagement:importer.list.filter.use"),
              type: "searchSelect",
              props: {
                placeholder: t("itemManagement:importer.list.placeholder.all"),
                allowClear: (filterData.isActivated as any) !== "all",
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("isActivated", "all");
                    onForceSearch("isActivated", "all");
                  } else {
                    onFilterDataChange("isActivated", value);
                    onForceSearch("isActivated", value);
                  }
                },
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
            {
              key: "subCategoryCodeItemName",
              option: [allOptionItem, ...subCategoryFilterList],
            },
            {
              key: "isActivated",
              option: [allOptionItem, ...ACTIVATED_OPTION_LIST],
            },
            {
              key: "item",
              option: [allOptionItem, ...itemOptionFilterList],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <Typo color="gray4" typoType="b5m">
              {t("itemManagement:importer.list.table.title")}
            </Typo>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                onClick={() => setDialogState(DialogState.ADD_ITEM)}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />

                {t("itemManagement:importer.list.buttons.addItemButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>
          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : itemList}
              isPaginationDataMaping
              columnDefs={columnDefs}
              handlePaginationClick={(page, pageSize) => {
                onPaginationChange({ page, pageSize });
              }}
              totalPage={count}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onGridReady={() => setIsReady(true)}
              components={{
                ...editAction((row) => {
                  handleEditClick(
                    row as unknown as ExporterItemCodeListViewV2Dto
                  );
                }),
              }}
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
      {renderDialogs()}
    </ImporterMainLayout>
  );
};

export default ImporterItemManagementPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
