import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type ColumnHeaderSet = {
  columnKey: string;
  langKey: string;
};

const useAgGridHeaderRefresh = ({
  gridRef,
  isReady,
  headerSet = [],
}: {
  gridRef: AgGridReact<any> | null;
  isReady: boolean;
  headerSet?: ColumnHeaderSet[];
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!gridRef?.api?.getColumnDef) {
      return;
    }

    if (i18n.language === "en" && gridRef && isReady) {
      headerSet.forEach((item) => {
        const transformedClassName = gridRef.api
          .getColumnDef(item.columnKey)!
          .headerClass?.toString()
          .split(" ")
          .filter((className) => className !== "ag-header-hide")
          .join(" ");

        gridRef.api.getColumnDef(item.columnKey)!.headerName = t(item.langKey);
        gridRef.api.getColumnDef(item.columnKey)!.headerClass =
          transformedClassName;
      });

      gridRef.api.refreshHeader();
    }
    if (i18n.language === "ko" && gridRef && isReady) {
      headerSet.forEach((item) => {
        const transformedClassName = gridRef.api
          .getColumnDef(item.columnKey)!
          .headerClass?.toString()
          .split(" ")
          .filter((className) => className !== "ag-header-hide")
          .join(" ");

        gridRef.api.getColumnDef(item.columnKey)!.headerName = t(item.langKey);
        gridRef.api.getColumnDef(item.columnKey)!.headerClass =
          transformedClassName;
      });
      gridRef.api.refreshHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, headerSet, isReady]);
};

export default useAgGridHeaderRefresh;
