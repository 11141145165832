import { useContext } from "react";
import { isUndefined } from "@/src/utils/is";
import { EnterPriseSignupDateContext, EnterPriseSignupStepContext } from ".";

export const useEnterPriseSignupStepContext = () => {
  const context = useContext(EnterPriseSignupStepContext);

  if (isUndefined(context)) {
    throw Error("EnterPrise Signup Step Context가 존재 하지 않습니다.");
  }

  return context;
};

export const useEnterPriseSignupDateContext = () => {
  const context = useContext(EnterPriseSignupDateContext);

  if (isUndefined(context)) {
    throw Error("EnterPrise Signup Date Context가 존재 하지 않습니다.");
  }

  return context;
};
