import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import { IconButton } from "@/src/components/atom/Button";
import SectionCard from "@/src/components/molecule/SectionCard";
import SearchIcon from "@/src/assets/icons/icon-search.svg";
import ListIcon from "@/src/assets/icons/icon-menu.svg";
import GalleryIcon from "@/src/assets/icons/icon-gallery.svg";
import Table from "@/src/components/atom/Table";
import { columnContractFiles } from "../../columns/columnContractFiles";
import GalleryFileCard from "./card/GalleryFileCard";
import { StyledScroll } from "@/src/styles/scroll";
import { useGetContractsMediasQuery } from "@/src/store/apis/contracts/contractDetail";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";

interface ContractFilesTabProps {
  tab: "list" | "gallery";
  onTabChange: (tab: ContractFilesTabProps["tab"]) => void;
}

const ContractFilesTab = ({ tab, onTabChange }: ContractFilesTabProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const gridRef = useRef<AgGridReact>(null);

  const [columnDefs] = useState(columnContractFiles);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [categoryInputValue, setCategoryInputValue] = useState<string>("");
  const [isReady, setIsReady] = useState<boolean>(false);
  const { currentData, isError } = useGetContractsMediasQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const categoryInputFilteredData = (currentData?.rows || []).filter(
    ({ category }) =>
      category.toLowerCase().includes(categoryInputValue.toLowerCase())
  );

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "type",
        langKey: "contract:exporter.detail.fileList.type",
      },
      {
        columnKey: "category",
        langKey: "contract:exporter.detail.fileList.category",
      },
      {
        columnKey: "originalFileName",
        langKey: "contract:exporter.detail.fileList.originalFileName",
      },
      {
        columnKey: "createdAt",
        langKey: "contract:exporter.detail.fileList.createdAt",
      },
    ],
  });

  const renderFilesByTab = () => {
    switch (tab) {
      case "list":
        return (
          <Table
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={
              isError
                ? []
                : categoryInputFilteredData.slice(
                    (page - 1) * pageSize,
                    page * pageSize - 1
                  ) || []
            }
            isPaginationDataMaping
            totalPage={currentData?.count || 0}
            page={page}
            pageSize={pageSize}
            handlePaginationClick={(page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            onGridReady={() => setIsReady(true)}
          />
        );
      case "gallery":
        return (
          <FileGalleryList>
            {categoryInputFilteredData.map(
              ({ createdAt, mediaUrl, originalFileName, category }) => {
                return (
                  <GalleryFileCard
                    category={category}
                    fileSrc={mediaUrl}
                    fileName={originalFileName}
                    uploadedAt={createdAt}
                    onClick={() => window.open(mediaUrl, "_blank")}
                  />
                );
              }
            )}
          </FileGalleryList>
        );
    }
  };

  return (
    <>
      <FlexSpaceBetween>
        <InputContainer>
          <Input
            inputSize="small"
            value={categoryInputValue}
            onChange={({ target: { value } }) => setCategoryInputValue(value)}
            placeholder={t("contract:exporter.detail.placeholder.category")}
            suffix={<Icon iconSrc={SearchIcon} />}
            onClear={() => setCategoryInputValue("")}
          />
        </InputContainer>

        <Flex>
          {[
            { iconSrc: ListIcon, onClick: () => onTabChange("list") },
            { iconSrc: GalleryIcon, onClick: () => onTabChange("gallery") },
          ].map(({ onClick, iconSrc }) => {
            return (
              <StyledIconButton
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                onClick={onClick}
              >
                <Icon iconSrc={iconSrc} iconSize={16} />
              </StyledIconButton>
            );
          })}
        </Flex>
      </FlexSpaceBetween>

      <SectionCard cardTitle={t("contract:exporter.detail.fileList.title")}>
        {renderFilesByTab()}
      </SectionCard>
    </>
  );
};

export default ContractFilesTab;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  width: 240px;
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
`;

const FileGalleryList = styled.div`
  display: flex;
  max-height: 520px;
  overflow: auto;
  gap: 16px;
  flex-wrap: wrap;
  ${StyledScroll}
`;
