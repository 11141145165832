import { ReactNode, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useForm } from "react-hook-form";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem, { FormItemProps } from "@/src/components/molecule/FormItem";
import FormInputPropsWithInputType from "@/src/components/molecule/FormItem/type";
import SectionCard from "@/src/components/molecule/SectionCard";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { mediaQuery } from "@/src/styles/mediaQuery";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import typo from "@/src/styles/typography";
import Table from "@/src/components/atom/Table";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useContentLoading from "@/src/hooks/useContentLoading";
import { editAction } from "@/src/utils/row-data-util";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import { editWarehouseContactPersonListColumn } from "./columns/column";
import { isUndefined } from "@/src/utils/is";
import ContactPersonAddDialog, {
  TransformContactPersonAddModalFormType,
} from "./dialog/ContactPersonAddDialog";
import ContactPersonEditDialog, {
  ContactPersonEditFormType,
} from "./dialog/ContactPersonEditDialog";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import {
  useCreateWarehouseEmployeesMutation,
  useEditWarehouseEmployeesMutation,
  useEditWarehouseMutation,
  useGetWarehouseQuery,
  useLazyGetWarehouseEmployeesQuery,
} from "@/src/store/apis/warehouse";
import CountryWithTimezoneFormItem from "@/src/components/molecule/CountryWithTimezoneFormItem";
import { useTranslation } from "react-i18next";

type BasicInformationType = {
  countryCodeItemName: string;
  warehouseName: string;
  isActivated: string;
  apartment: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  telPrefix: string;
  tel: string;
  faxPrefix: string;
  fax: string;
  businessNumber: string;
  businessRegistration: any;
  mainCategoryCodeItemNames: string[];
  timezoneCity: string;
  timezoneGmtOffset: number;
};

enum DialogState {
  NULL,
  ADD,
  EDIT,
}

const ImporterWarehouseManagementEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const agGridRef = useRef<AgGridReact>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const pageParams = useParams<{ id: string }>();
  const editButtonRef = useRef<HTMLButtonElement | null>(null);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [targetEditId, setTargetEditId] = useState<null | number>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [columnDefs] = useState<ColDef[]>(editWarehouseContactPersonListColumn);
  const alert = useAlert();

  const warehouseQuery = useGetWarehouseQuery(
    {
      id: Number(pageParams.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFactoryInfoFetching: isFetching,
          mainCategoryCodeItemNames: isStable
            ? currentData.mainCategoryCodeItemNames
            : [],
          countryCodeItemName: isStable ? currentData.country.codeItemName : "",
          warehouseName: isStable ? currentData.warehouseName : "",
          isActivated: isStable ? currentData.isActivated : false,
          apartment: isStable ? currentData.warehouseStreetAddress : "",
          city: isStable ? currentData.warehouseLocality : "",
          state: isStable ? currentData.warehouseRegion : "",
          postal: isStable ? currentData.warehousePostalCode : "",
          country: isStable ? currentData.warehouseCountryName : "",
          telPrefix: isStable ? currentData.telPrefix : "",
          tel: isStable ? currentData.tel : "",
          faxPrefix: isStable ? currentData.faxPrefix : "",
          fax: isStable ? currentData.fax : "",
          businessNumber: isStable ? currentData.businessNumber : "",
          businessRegistration: isStable
            ? currentData.businessRegistrationMedia
            : undefined,
          timezoneCity: isStable ? currentData.city : "",
          timezoneGmtOffset: isStable ? currentData.gmtOffset : 0,
        };
      },
    }
  );
  const [createEmployee] = useCreateWarehouseEmployeesMutation();
  const [editEmployee] = useEditWarehouseEmployeesMutation();
  const [editFactory] = useEditWarehouseMutation();
  const { countryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, isError, currentData }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isCountryFetching: isFetching,
          countryList: isStable ? currentData : [],
        };
      },
    }
  );
  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || currentData === undefined;
          const isStable = !isUnstable;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: isStable ? currentData : [],
          };
        },
      }
    );

  const {
    control,
    register,
    watch,
    setValue,
    setFocus,
    handleSubmit: handleBasicInfoSubmit,
    getValues,
  } = useForm<BasicInformationType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      mainCategoryCodeItemNames: warehouseQuery.mainCategoryCodeItemNames,
      countryCodeItemName: warehouseQuery.countryCodeItemName,
      warehouseName: warehouseQuery.warehouseName,
      isActivated: warehouseQuery.isActivated ? "Y" : "N",
      apartment: warehouseQuery.apartment,
      city: warehouseQuery.city,
      state: warehouseQuery.state,
      postal: warehouseQuery.postal,
      country: warehouseQuery.country,
      telPrefix: warehouseQuery.telPrefix,
      tel: warehouseQuery.tel,
      faxPrefix: warehouseQuery.faxPrefix,
      fax: warehouseQuery.fax,
      businessNumber: warehouseQuery.businessNumber,
      businessRegistration: warehouseQuery.businessRegistration
        ? [warehouseQuery.businessRegistration]
        : [],
      timezoneCity: warehouseQuery.timezoneCity,
      timezoneGmtOffset: warehouseQuery.timezoneGmtOffset,
    },
  });

  const mainCategoryOptionList = mainCategory.map((item) => {
    return {
      label: item.codeItemNameEn,
      value: item.codeItemName,
    };
  });

  const [
    getWarehouseEmployees,
    { warehouseEmployees, isEmployeesFetching, warehouseEmployeesCount },
  ] = useLazyGetWarehouseEmployeesQuery({
    selectFromResult: ({ isFetching, isError, currentData }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      const isStable = !isUnstable;

      return {
        warehouseEmployees: isStable ? currentData.rows : [],
        warehouseEmployeesCount: isStable ? currentData.count : 0,
        isEmployeesFetching: isFetching,
      };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "warehouseManagement:common.name",
      },
      {
        columnKey: "personalContact",
        langKey: "warehouseManagement:common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "warehouseManagement:common.officeContact",
      },
      {
        columnKey: "isActivated",
        langKey: "warehouseManagement:common.use",
      },
      { columnKey: "edit", langKey: "warehouseManagement:common.edit" },
    ],
  });

  const basicInformationFormItemList: (Partial<
    FormItemProps<BasicInformationType, keyof BasicInformationType> &
      FormInputPropsWithInputType
  > & {
    customRender?: () => ReactNode;
  })[] = [
    {
      label: t("warehouseManagement:common.warehouseName"),
      type: "text",
      name: "warehouseName",
      control: control,
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.warehouseName"),
      },
    },
    {
      customRender: () => (
        <StyledCountryWithTimezoneFormItem
          label={t("signupCorp:content.countryAndCity")}
          register={register as any}
          setValue={setValue as any}
          isRequired={true}
          countryName="countryCodeItemName"
          countryValue={watch("countryCodeItemName") || undefined}
          cityName="timezoneCity"
          cityValue={watch("timezoneCity") || undefined}
          gmtOffsetName="timezoneGmtOffset"
          errorMessage={t("error:required")}
          onCountryChange={(value) => {
            const prefix = countryList.find(
              ({ codeItemName }) => codeItemName === value
            )?.value1;
            setValue("telPrefix", prefix || "");
            setValue("faxPrefix", prefix || "");
          }}
        />
      ),
    },
    {
      label: t("warehouseManagement:common.categoryInCharge"),
      type: "multipleSelect",
      name: "mainCategoryCodeItemNames",
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      control: control,
      options: mainCategoryOptionList,
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.categoryInCharge"),
        suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
        disabled: isMainCategoryFetching,
        onRemoveItem: (item) => {
          setValue(
            "mainCategoryCodeItemNames",
            getValues("mainCategoryCodeItemNames").filter(
              (mainCategoryItem) => mainCategoryItem !== item
            )
          );
        },
      },
    },
    {
      label: t("warehouseManagement:common.businessNumber"),
      type: "text",
      name: "businessNumber",
      control: control,
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.businessNumber"),
      },
    },
    {
      label: t("warehouseManagement:common.businessRegistration"),
      type: "file",
      name: "businessRegistration",
      control: control,
      inputProps: {
        defaultFileList: watch("businessRegistration").length
          ? [
              new File(
                [""],
                watch("businessRegistration")?.[0]?.originalFileName ?? ""
              ),
            ]
          : undefined,
        onRemove: () => setValue("businessRegistration", []),
      },
    },
    {
      label: t("warehouseManagement:common.use"),
      type: "radioGroup",
      name: "isActivated",
      control: control,
      rules: { required: true },
      options: [
        { label: "Y", value: "Y" },
        { label: "N", value: "N" },
      ],
    },
  ];

  const handleCreateEmployee = async (
    values: TransformContactPersonAddModalFormType
  ) => {
    try {
      await createEmployee({
        id: Number(pageParams.id) ?? "",
        isActivated: values.isActivated,
        name: values.name,
        email: values.email,
        personalContactPrefix: values.personalContactPrefix,
        personalContact: values.personalContact,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : undefined,
        officeContact: values.officeContact ? values.officeContact : undefined,
      }).unwrap();
      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleEditContactPerson = async (values: ContactPersonEditFormType) => {
    try {
      await editEmployee({
        id: Number(targetEditId) ?? "",
        name: values.name ?? "",
        email: values.email ?? "",
        isActivated: values.isActivated,
        personalContact: values.personalContact,
        personalContactPrefix: values.personalContactPrefix,
        officeContact: values.officeContact ? values.officeContact : null,
        officeContactPrefix: values.officeContactPrefix
          ? values.officeContactPrefix
          : null,
      }).unwrap();
      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleEditFactory = async () => {
    try {
      const {
        countryCodeItemName,
        warehouseName,
        isActivated,
        apartment,
        city,
        state,
        postal,
        country,
        telPrefix,
        tel,
        faxPrefix,
        fax,
        businessNumber,
        businessRegistration,
        mainCategoryCodeItemNames,
        timezoneCity,
        timezoneGmtOffset,
      } = getValues();

      await editFactory({
        id: Number(pageParams.id) ?? "",
        warehouseName,
        mainCategoryCodeItemNames,
        countryCodeItemName:
          countryList.find(
            ({ codeItemNameKo, codeItemName }) =>
              countryCodeItemName === codeItemNameKo ||
              countryCodeItemName === codeItemName
          )?.codeItemName ?? "",
        telPrefix: telPrefix,
        faxPrefix,
        warehousePostalCode: postal || null,
        warehouseCountryName: country,
        warehouseRegion: state || null,
        warehouseLocality: city || null,
        warehouseStreetAddress: apartment || null,
        isActivated: isActivated === "Y",
        tel: tel || null,
        fax: fax || null,
        businessNumber: businessNumber,
        businessRegistrationMediaId: businessRegistration[0]?.id ?? null,
        city: timezoneCity,
        gmtOffset: timezoneGmtOffset,
      }).unwrap();

      navigate(
        `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_DETAIL}/${pageParams.id}`
      );
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDetailClick = (data: any) => {
    setTargetEditId(data.id);
    setDialogState(DialogState.EDIT);
  };

  const fetchWarehouseEmployees = async ({
    page,
    pageSize,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    try {
      await getWarehouseEmployees({
        id: Number(pageParams.id),
        page,
        pageSize,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    fetchWarehouseEmployees({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocus("warehouseName");
  }, [setFocus]);

  useEffect(() => {
    if (warehouseQuery.isFactoryInfoFetching) {
      handleContentLoadingOn();
    } else {
      handleContentLoadingOff();
    }
  }, [
    warehouseQuery.isFactoryInfoFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  useEffect(() => {
    if (agGridRef.current && isEmployeesFetching) {
      agGridRef.current.api.showLoadingOverlay();
    }
  }, [isEmployeesFetching]);

  const renderDialog = () => {
    const defaultCountry = countryList.find(
      ({ codeItemName }) => getValues("countryCodeItemName") === codeItemName
    );

    if (dialogState === DialogState.NULL) return null;

    if (dialogState === DialogState.ADD) {
      return (
        <ContactPersonAddDialog
          open
          defaultCountryCode={defaultCountry?.value1}
          onSave={handleCreateEmployee}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.EDIT && targetEditId) {
      const employee = warehouseEmployees.find(({ id }) => id === targetEditId);

      if (!employee) return null;

      return (
        <ContactPersonEditDialog
          open
          onSave={handleEditContactPerson}
          onOpenChange={() => setDialogState(DialogState.NULL)}
          defaultContactPersonInfo={{
            name: employee?.name || "",
            email: employee?.email || "",
            personalContactPrefix: employee.personalContactPrefix,
            personalContact: employee.personalContact,
            isActivated: employee.isActivated ? "Y" : "N",
            officeContactPrefix:
              employee.officeContactPrefix || defaultCountry?.value1,
            officeContact: employee.officeContact,
          }}
        />
      );
    }
  };

  return (
    <ImporterMainLayout
      breadcrumb={[
        "...",
        t("sideNav:warehouseManagement"),
        t("sideNav:warehouseEdit"),
      ]}
      pageTitle={t("sideNav:warehouseEdit")}
    >
      <SectionContainer>
        <SectionCard
          cardTitle={t("warehouseManagement:common.basicInformation")}
        >
          <StyledForm onSubmit={handleBasicInfoSubmit(handleEditFactory)}>
            <HalfDiv>
              {basicInformationFormItemList.map(
                ({
                  label,
                  type,
                  name,
                  control,
                  options,
                  rules,
                  inputProps,
                  disabled,
                  errorsMessage,
                  customRender,
                }) => {
                  if (customRender) {
                    return customRender();
                  }

                  return (
                    <StyledFormItem
                      label={label}
                      type={type as any}
                      name={name as keyof BasicInformationType}
                      control={control as any}
                      options={options ?? []}
                      inputProps={inputProps as any}
                      rules={rules}
                      disabled={disabled}
                      errorsMessage={errorsMessage}
                    />
                  );
                }
              )}
            </HalfDiv>
            <HalfDiv>
              <StyledAddressFormItem
                label={t("signupCorp:content.address")}
                register={register as any}
                isRequired
                setValue={setValue as any}
              />
              <StyledContactFormItem
                label={t("signupCorp:content.contact")}
                prefixName="telPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="tel"
                prefixValue={watch("telPrefix") || undefined}
                isRequired={false}
              />
              <StyledContactFormItem
                label={t("warehouseManagement:common.fax")}
                prefixName="faxPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="fax"
                prefixValue={watch("faxPrefix") || undefined}
                isRequired={false}
              />
            </HalfDiv>
            <Hidden ref={editButtonRef} />
          </StyledForm>
        </SectionCard>
        <SectionCard
          cardTitle={t("warehouseManagement:common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t("warehouseManagement:factoryListAddPage.addContactPerson")}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={isEmployeesFetching ? undefined : warehouseEmployees}
            columnDefs={columnDefs}
            components={{
              ...editAction(handleDetailClick),
            }}
            isPaginationDataMaping
            totalPage={warehouseEmployeesCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) => {
              fetchWarehouseEmployees({ page, pageSize });
              setPagination({ page, pageSize });
            }}
          />
        </SectionCard>

        <BottomFixedContainer>
          <FooterButtonContainer>
            <FooterButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
              {t("warehouseManagement:common.backToPrevious")}
            </FooterButton>
            <FooterButton onClick={() => editButtonRef.current?.click()}>
              {t("warehouseManagement:common.save")}
            </FooterButton>
          </FooterButtonContainer>
        </BottomFixedContainer>
      </SectionContainer>
      {renderDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterWarehouseManagementEditPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const StyledForm = styled.form`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormItem = styled(FormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledCountryWithTimezoneFormItem = styled(CountryWithTimezoneFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledAddressFormItem = styled(AddressFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;

    > div {
      width: 100% !important;
    }
  }
`;
const StyledContactFormItem = styled(ContactFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FooterButton = styled(Button)`
  min-width: 158px;
  text-align: center;
  ${typo.btn3m}
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
