import { imageFullDownload } from "@/src/utils/imageFullDownload";
import { imageApiSlice } from "../baseQuery";
import {
  ContainerIdListDto,
  ContainerMediaTypeListDto,
  FindTaskByTaskShareKeyDto,
  ShipmentShareKeyAndContainerIdListDto,
  ShipmentShareKeyAndContainerMediaTypeListDto,
  TaskContainerFullBatchDownloadRequest,
  TaskIdListAndContainerMediaTypeListDto,
} from "./interface";

const apiWithTag = imageApiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const contractDetailApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 작업에 귀속된 컨테이너 목록의 이미지 일괄 다운로드 API (작업 상세페이지)
    taskContainerFullBatchDownload: build.mutation<
      void,
      TaskContainerFullBatchDownloadRequest
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporters/this/tasks/${id}/container-image-full-batch-download`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // containerId 목록으로 컨테이너 이미지 일괄 다운로드 API (선적 등록, 상세페이지)
    containerIdsFullBatchDownload: build.mutation<void, ContainerIdListDto>({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/container-image-full-batch-download-by-container-id-list`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // shipmentId와 containerMediaType 목록으로 컨테이너 이미지 일괄 다운로드 API (선적 상세페이지)
    shipmentIdWithMediaTypeFullBatchDownload: build.mutation<
      void,
      ContainerMediaTypeListDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporters/this/shipments/${id}/container-image-full-batch-download-by-container-media-type-list`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // taskId 목록과 containerMediaType 목록으로 컨테이너 이미지 일괄 다운로드 API (선적 등록페이지)
    taskIdWithMediaTypeFullBatchDownload: build.mutation<
      void,
      TaskIdListAndContainerMediaTypeListDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/container-image-full-batch-download-by-task-id-list-and-container-media-type-list`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // 이메일로 전송받은 shipmentShareKey, shipmentId와 containerId 목록으로 컨테이너 이미지 일괄 다운로드 API
    shipmentShareKeyWithcontainerIdsFullBatchDownload: build.mutation<
      void,
      ShipmentShareKeyAndContainerIdListDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/shipments/this/container-image-full-batch-download-by-container-id-list`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // 이메일로 전송받은 shipmentShareKey, shipmentId와 containerMediaType 목록으로 컨테이너 이미지를 일괄 다운로드하는 API 입니다.
    shipmentShareKeyWithMediaTypeFullBatchDownload: build.mutation<
      void,
      ShipmentShareKeyAndContainerMediaTypeListDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/shipments/this/container-image-full-batch-download-by-container-media-type-list`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    // 이메일로 전송받은 taskShareKey와 taskId로 작업에 귀속된 컨테이너 목록의 이미지 일괄 다운로드 API
    taskShareKeyWithContainerFullBatchDownload: build.mutation<
      void,
      FindTaskByTaskShareKeyDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/tasks/this/container-image-full-batch-download`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: "container.zip",
          });
        },
      }),
    }),

    //
    fileDownloadByMediaId: build.mutation<
      void,
      { mediaIdList: number[]; fileName?: string }
    >({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/files`,
        body,
        responseHandler: async (response) => {
          imageFullDownload({
            response,
            imageListFileName: `${body.fileName || "file"}.zip`,
          });
        },
      }),
    }),
  }),
});

export const {
  useTaskContainerFullBatchDownloadMutation,
  useContainerIdsFullBatchDownloadMutation,
  useShipmentIdWithMediaTypeFullBatchDownloadMutation,
  useTaskIdWithMediaTypeFullBatchDownloadMutation,

  // 이메일 공유
  useShipmentShareKeyWithcontainerIdsFullBatchDownloadMutation,
  useShipmentShareKeyWithMediaTypeFullBatchDownloadMutation,
  useTaskShareKeyWithContainerFullBatchDownloadMutation,

  useFileDownloadByMediaIdMutation,
} = contractDetailApi;
export { contractDetailApi };
