import { ExporterUserDto } from "../auth/interface";
import { MediaDto } from "../media/interface";
import { WarehouseDto, WarehouseEmployeeDto } from "../warehouse/interface";

export type SalesStatusType = "PROCESSING" | "COMPLETE";

// 발주계약 생성시 중복된 SalesNumber일시 에러타입
export const DUPLICATED_SALES_NUMBER = "DUPLICATED_SALES_NUMBER";

export interface GetSalesRequestParams {
  // 발주 No
  salesNumber?: string;
  // 발주 계약 상태
  salesStatus?: string;
  // warehouse ID(PK)를 컴마로 이은 값
  warehouseIdList?: string;
  // Qty
  quantity?: number;
  // 아이템 코드
  itemCode?: string;
  // sort 기준 필드
  sort?: string;
  // order
  order?: string;
  // page
  page?: number;
  // pageSize
  pageSize?: number;
}

export interface GetSalesFilterRequestParams
  extends Omit<GetSalesRequestParams, "warehouseIdList"> {
  warehouseIdList?: number[];
}

export type WarehousingsType = {
  id: number;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  warehousedAt: string;
  warehousingContainers: {
    id: number;
    createdAt: string;
    deletedAt: string;
    updatedAt: string;
    container: {
      id: number;
      weightUnit: string;
      weightUnitCodeItemName: string;
      netWeight: string;
    };
  }[];
};

export interface SalesDto {
  // 데이터 ID(PK)
  id: number;
  // 생성 일시
  createdAt: string;
  // 수정 일시
  updatedAt: string;
  // 삭제 일시
  deletedAt: string;
  // warehouse
  warehouse: WarehouseDto;
  // warehouseEmployee
  warehouseEmployee: WarehouseEmployeeDto;
  // salesFinalProducts;
  salesFinalProducts: {
    id: number;
    createdAt: string;
    deletedAt: string | null;
    updatedAt: string;
    finalProduct: FinalProductDto;
  }[];
  // 발주 No
  salesNumber: string;
  // 발주 계약 상태
  salesStatus: SalesStatusType;
  // Order Date
  orderDateAt: string;
  // exporterItemCode;
  exporterItemCode: {
    // 데이터 ID(PK)
    id: number;
    // 생성 일시
    createdAt: string;
    // 수정 일시
    updatedAt: string;
    // 삭제 일시
    deletedAt: string;
    // 아이템 코드
    itemCode: string;
    // 아이템 코드에 대한 설명
    description: string;
    // 아이템 코드 활성화 여부
    isActivated: boolean;
  };
  // exporterItem
  exporterItem: {
    // 데이터 ID(PK)
    id: number;
    // 생성 일시
    createdAt: string;
    // 수정 일시
    updatedAt: string;
    // 삭제 일시
    deletedAt: null;
    // 아이템
    item: string;
    // 메인 카테고리
    mainCategoryCodeItemName: string;
    // 서브 카테고리
    subCategoryCodeItemName: string;
    // 아이템 활성화 여부
    isActivated: boolean;
  };
  // Qty
  quantity: string;
  // Qty Unit
  quantityUnit: string;
  // Qty Unit 공통코드 아이템 codeItemName
  quantityUnitCodeItemName: string;
  // Unit Price
  unitPrice: string;
  // Unit Price Unit
  unitPriceUnit: string;
  // Unit Price Unit 공통코드 아이템 codeItemName
  unitPriceUnitCodeItemName: string;
  // lastEditor;
  lastEditor: ExporterUserDto;
  // salesMedia;
  salesMedia: MediaDto;
  // exporterUser 작성자인지?;
  exporterUser: ExporterUserDto;
  // salesMemos;
  salesMemos: {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    exporterUser: ExporterUserDto;
    memo: string;
  }[];
  // warehousings;
  warehousings: WarehousingsType[];
  // salesBookmarks;
  salesBookmarks: {
    id: number;
    createdAt: string;
    updatedAt: string;
  }[];
}

export interface ItemInformationDto {
  // Qty
  quantity: number;
  // Qty Unit example: MT
  quantityUnit: string;
  // Qty Unit 공통코드 아이템 codeItemName example: MEASUREMENT_MT
  quantityUnitCodeItemName: string;
  // Unit Price
  unitPrice: number;
  // Unit Price Unit example: USD
  unitPriceUnit: string;
  // Unit Price Unit 공통코드 아이템 codeItemName example: USD
  unitPriceUnitCodeItemName: string;
  // exporter item ID(PK)
  exporterItemId: number;
  // exporter item code ID(PK)
  exporterItemCodeId: number;
}

export interface GenerateSalesDto {
  // 발주 No
  salesNumber: string;
  // Order Date
  orderDateAt: string;
  // 메모
  memo?: string;
  // 최종공산품 ID(PK) 배열
  finalProductIdList: number[];
  // Warehouse ID(PK)
  warehouseId: number;
  // warehouseEmployee ID(PK)
  warehouseEmployeeId: number;
  // Sales Media ID(PK)
  salesMediaId?: number;
  // 아이템 관련 정보
  itemInformationDtoList: ItemInformationDto[];
}

export interface GenerateFinalProductDto {
  // 최종 공산품 이름
  name: string;
}

export interface FinalProductDto {
  // 데이터 ID(PK)
  id: number;
  // 생성 일시
  createdAt: string;
  // 수정 일시
  updatedAt: string;
  // 삭제 일시
  deletedAt: string;
  // 최종 공산품 이름
  name: string;
}

export interface ModifySalesDto {
  // 발주 No
  salesNumber: string;
  // Order Date
  orderDateAt: string;
  // 메모;
  memo?: string;
  // 최종공산품 ID(PK) 배열
  finalProductIdList: number[];
  // Warehouse ID(PK)
  warehouseId: number;
  // warehouseEmployee ID(PK)
  warehouseEmployeeId: number;
  // Sales Media ID(PK)
  salesMediaId?: number | null;
  // 아이템 관련 정보
  itemInformationDto: ItemInformationDto;
}

export interface GetSalesWithWarehousingRequest {
  warehousedAt?: string;
  page?: number;
  pageSize?: number;
}

export interface GetSalesWithWarehousingDetailRequest {
  id: number;
  warehousingId: number;
  page?: number;
  pageSize?: number;
}

export interface WarehousingDto {
  // 데이터 ID(PK)
  id: number;
  // 생성 일시
  createdAt: string;
  // 수정 일시
  updatedAt: string;
  // 삭제 일시
  deletedAt: string;
  // sales;
  sales: SalesDto;
  // 입고일
  warehousedAt?: string;
  // 컨테이너 대수
  numberOfContainer: number;
  // 입고 물량
  warehousedAmount: number;
  // warehousingMedia;
  warehousingMedia?: MediaDto;
}

export interface ModifyWarehousingDto {
  // 입고일
  warehousedAt?: string;
  // warehousing File Media ID(PK)
  warehousingMediaId?: number;
  // sales(발주계약) ID(PK)
  salesId: number;
}

export interface GenerateWarehousingContainerDto {
  // sales(발주계약) ID(PK)
  salesId: number;
  // 입고일
  warehousedAt?: string;
  // warehousing File Media ID(PK)
  warehousingMediaId?: number;
  // containerIdList;
  containerIdList: number[];
}

export interface WarehousingContainerDto {
  // containerNo
  containerNo: string;
  // sealNo
  sealNo: string;
  // unitQuantity
  unitQuantity: string;
  // unitPrice
  unitPrice: string;
  // unitPriceUnit
  unitPriceUnit: string;
  // taskNo
  taskNo: string;
  // blNo
  blNo: string;
  // poNo
  poNo: string;
  // bookingId
  bookingId: number;
  // contractId
  contractId: number;
  // netWeight;
  netWeight: string;
}

export interface GenerateSalesMemoDto {
  // memo
  memo: string;
}

export interface ExporterUserSalesBookmarkInfoDto {
  // 계약에 대한 수출입웹 유저의 북마크 여부
  isBookmarked: false;
  // 수출입웹 유저의 ID(PK)
  exporterUserId: 1;
  // 발주계약의 ID(PK)
  salesId: 1;
}

export interface SalesBookmarkListViewDto {
  // 이름
  name: string;
  // 이메일
  email: string;
  // 수출입웹 유저의 ID(PK)
  exporterUserId: number;
}
