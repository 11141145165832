import dayjs from "dayjs";
import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  GetImporterDomainCurrentSituationRequest,
  GetImporterShipmentTotalOptionalStatisticRequest,
  GetImporterShipmentTotalStatisticRequest,
  ImporterCategoryStatisticInfoDto,
  ImporterDashboardStatisticsType,
  ImporterDomainStatusInfoDto,
  ImporterShipmentTotalStatisticInfoDto,
  PurchaseAndSalesAndRankStatisticInfoDto,
  SellerAndItemTopRankStatisticInfoDto,
} from "./interface";

const today = dayjs();

const importerDashboardApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // (수입자)홈 각 도메인 현황 조회 API
    getImporterDomainCurrentSituation: build.query<
      ImporterDomainStatusInfoDto,
      GetImporterDomainCurrentSituationRequest
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-domain-status-info",
        params,
      }),
      transformResponse: (res: Row<ImporterDomainStatusInfoDto>) => res.row,
    }),

    // (수입자)홈 대시보드에서 셀러,카테고리 따른 총 컨테이너 수량, 물동량, 아이템수량,선적수 통계 정보 조회 API
    getImporterShipmentTotalStatistic: build.query<
      Rows<ImporterShipmentTotalStatisticInfoDto>,
      GetImporterShipmentTotalStatisticRequest
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-shipment-total-statistic-info",
        params,
      }),
    }),
    // 오늘날짜 (수입자)홈 대시보드에서 셀러,카테고리 따른 총 컨테이너 수량, 물동량, 아이템수량,선적수 통계 정보 조회 API
    getTodayImporterShipmentTotalStatistic: build.query<
      Rows<ImporterShipmentTotalStatisticInfoDto>,
      GetImporterShipmentTotalOptionalStatisticRequest
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-shipment-total-statistic-info",
        params: {
          ...params,
          etaAtFrom: today.startOf("day").toISOString(),
          etaAtTo: today.endOf("day").toISOString(),
        },
      }),
    }),
    // 어제날짜 (수입자)홈 대시보드에서 셀러,카테고리 따른 총 컨테이너 수량, 물동량, 아이템수량,선적수 통계 정보 조회 API
    getYesterdayImporterShipmentTotalStatistic: build.query<
      Rows<ImporterShipmentTotalStatisticInfoDto>,
      GetImporterShipmentTotalOptionalStatisticRequest
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-shipment-total-statistic-info",
        params: {
          ...params,
          etaAtFrom: today.subtract(1, "day").startOf("day").toISOString(),
          etaAtTo: today.subtract(1, "day").endOf("day").toISOString(),
        },
      }),
    }),

    // 홈 대시보드에서 셀러별 거래량 상위 순위(5위까지) 정보 조회 API
    getSellerAndItemRankStatistic: build.query<
      SellerAndItemTopRankStatisticInfoDto,
      ImporterDashboardStatisticsType
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/seller-and-item-top-rank-statistic-info",
        params,
      }),
      transformResponse: (res: Row<SellerAndItemTopRankStatisticInfoDto>) =>
        res.row,
    }),

    // (수입자)홈 대시보드에서 카테고리에 따른 총 컨테이너 수량, 물동량 통계 정보 조회 API
    getCategoryStatistic: build.query<
      ImporterCategoryStatisticInfoDto,
      ImporterDashboardStatisticsType
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-category-statistic-info",
        params,
      }),
      transformResponse: (res: Row<ImporterCategoryStatisticInfoDto>) =>
        res.row,
    }),

    // (수입자)홈 대시보드에서 카테고리에 따른 매입,매출액, 아이템 순위 통계 정보 조회 API
    getPurchasesAndSalesStatistic: build.query<
      PurchaseAndSalesAndRankStatisticInfoDto,
      ImporterDashboardStatisticsType
    >({
      query: (params) => ({
        url: "/importers/this/dashboard/importer-purchases-and-sales-statistic-info",
        params,
      }),
      transformResponse: (res: Row<PurchaseAndSalesAndRankStatisticInfoDto>) =>
        res.row,
    }),
  }),
});

export const {
  useGetImporterDomainCurrentSituationQuery,

  // shipment
  useGetImporterShipmentTotalStatisticQuery,
  useLazyGetImporterShipmentTotalStatisticQuery,
  useGetTodayImporterShipmentTotalStatisticQuery,
  useLazyGetTodayImporterShipmentTotalStatisticQuery,
  useGetYesterdayImporterShipmentTotalStatisticQuery,
  useLazyGetYesterdayImporterShipmentTotalStatisticQuery,

  // Statistic
  useGetSellerAndItemRankStatisticQuery,
  useLazyGetSellerAndItemRankStatisticQuery,
  useGetCategoryStatisticQuery,
  useLazyGetCategoryStatisticQuery,
  useGetPurchasesAndSalesStatisticQuery,
  useLazyGetPurchasesAndSalesStatisticQuery,
} = importerDashboardApi;
export { importerDashboardApi };
