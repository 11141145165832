import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import { MediaDto, MediaRequestBody } from "./interface";

const commonConfig = {
  method: "POST",
};

const mediaAPi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    uploadSmallImage: build.mutation<
      MediaDto[],
      Omit<MediaRequestBody, "service">
    >({
      query: (body) => {
        const formData = new FormData();
        body.files.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("folder", "commons/");
        formData.append("service", "BOS_WEB");
        return {
          url: "/media/small-images",
          ...commonConfig,
          body: formData,
        };
      },
      transformResponse: (res: Rows<MediaDto>) => res.rows,
    }),
    uploadBigImage: build.mutation<
      MediaDto[],
      Omit<MediaRequestBody, "service">
    >({
      query: (body) => {
        const formData = new FormData();
        body.files.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("folder", body.folder || "commons/");
        formData.append("service", "BOS_WEB");
        return {
          url: "/media/big-images",
          ...commonConfig,
          body: formData,
        };
      },
      transformResponse: (res: Rows<MediaDto>) => res.rows,
    }),
    uploadBigFile: build.mutation<
      MediaDto[],
      Omit<MediaRequestBody, "service">
    >({
      query: (body) => {
        const formData = new FormData();
        body.files.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("folder", body.folder || "commons/");
        formData.append("service", "BOS_WEB");
        return {
          url: "/media/big-files",
          ...commonConfig,
          body: formData,
        };
      },
      transformResponse: (res: Rows<MediaDto>) => res.rows,
    }),
  }),
});

export const {
  useUploadSmallImageMutation,
  useUploadBigImageMutation,
  useUploadBigFileMutation,
} = mediaAPi;
