import { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";

interface SectionCardGridProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function SectionCardGrid({
  className,
  children,
  ...props
}: PropsWithChildren<SectionCardGridProps>) {
  return (
    <Article className={className} {...props}>
      {children}
    </Article>
  );
}

export default SectionCardGrid;

const Article = styled.article`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 24px;
  width: 100%;
  border-radius: 16px;
`;
