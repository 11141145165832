import { TaskCalendarType } from "@/src/store/apis/dashboard/interface";
import dayjs, { Dayjs } from "dayjs";

export const getYearOptionList = ({
  startYear = 2023,
  endYear,
}: {
  startYear?: number;
  endYear: number;
}) => {
  let result: { label: string; value: string }[] = [];
  for (let i = startYear; i <= endYear; i++) {
    result.push({ label: i.toString(), value: i.toString() });
  }
  return result;
};

export const MONTH_OPTION_LIST = [
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];

export const TASK_CALENDER_TYPE_TASK_TYPE_MAP: Record<
  TaskCalendarType,
  string
> = {
  DOC_CUT_OFF: "Doc Cut-off",
  CARGO_CUT_OFF: "Cargo Cut-off",
  WORKING_DAY: "Working Day",
  ETD: "ETD",
};

export const getFirstDateOfWeek = (weekOfYear: number) =>
  dayjs().week(weekOfYear).startOf("week");

export const getLastDateOfWeek = (weekOfYear: number) =>
  dayjs().week(weekOfYear).endOf("week");

export const getMonthBetweenTwoDate = ({
  from,
  to,
}: {
  from: Dayjs;
  to: Dayjs;
}) => {
  const betweenMonths: string[] = [];
  const diff = to.startOf("month").diff(from.startOf("month"), "month");
  const clonedFrom = from.startOf("month");

  for (let i = 0; i < diff + 1; i++) {
    betweenMonths.push(clonedFrom.add(i, "month").format("YY.MM"));
  }

  return betweenMonths;
};
