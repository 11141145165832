import React, { useState } from "react";
import { styled } from "styled-components";
import { Accordion as AccordionRoot } from "@radix-ui/react-accordion";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import {
  useGetImporterTaskDetailContainerListQuery,
  useGetImporterTaskDetailQuery,
  useLazyGetImporterTaskDetailContainerQuery,
} from "@/src/store/apis/tasks/taskDetail";
import colorSet, { ColorType } from "@/src/styles/color";
import Loader from "@/src/components/atom/Loader";
import { ContainerListViewDto } from "@/src/store/apis/tasks/taskDetail/interface";
import Checkbox from "@/src/components/atom/Checkbox";
import { isUndefined } from "@/src/utils/is";
import ContainerAccordionItem from "./ContainerAccordionItem";
import { useTranslation } from "react-i18next";
import {
  createZipWithImages,
  Folder,
  ImageItem,
} from "@/src/utils/createZipWithImages";
import { toPascalCase } from "@/src/utils/transform";

const containerEmptyArray: ContainerListViewDto[] = [];

interface ContainerTaskResultCardProps {
  loadingId?: number;
  bookingNo: string;
}

function ContainerTaskResultCard({
  bookingNo,
  loadingId,
}: ContainerTaskResultCardProps) {
  const { t } = useTranslation();

  const { extraRemark } = useGetImporterTaskDetailQuery(
    {
      id: loadingId as number,
    },
    {
      skip: !loadingId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;

        return {
          extraRemark: !isUnstable ? currentData.extraMediaRemark : null,
        };
      },
    }
  );
  const { containerList } = useGetImporterTaskDetailContainerListQuery(
    {
      id: loadingId as number,
    },
    {
      skip: !loadingId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;

        return {
          containerList: !isUnstable
            ? currentData.rows ?? containerEmptyArray
            : containerEmptyArray,
        };
      },
    }
  );

  const [getLoadingContainer] = useLazyGetImporterTaskDetailContainerQuery();

  // State
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [containerCheckList, setContainerCheckList] = useState<number[]>([]);

  const selectContainerList = containerList.filter(({ id }) =>
    containerCheckList.includes(id)
  );

  const handleCheckboxClick = (checkboxItem: number) => {
    if (checkboxItem >= 0) {
      setContainerCheckList((prev) => {
        if (prev.includes(checkboxItem)) {
          return prev.filter((item) => item !== checkboxItem);
        }

        return [...prev, checkboxItem];
      });
    }
  };

  // 컨테이너 이미지 다운로드
  const handleContainerImageDownloadClick = async () => {
    if (containerCheckList.length === 0) {
      return;
    }

    const formatSingleContainerMedia = (
      containerData: any
    ): { folderName: string; images: ImageItem[] } => {
      const {
        containerNo,
        sampleBaleSimpleContainerMedias,
        emptySimpleContainerMedias,
        quarterLoadedSimpleContainerMedias,
        halfLoadedSimpleContainerMedias,
        fullLoadedSimpleContainerMedias,
        oneDoorClosedSimpleContainerMedias,
        bothDoorsClosedSimpleContainerMedias,
        closeUpSealSimpleContainerMedias,
        extraSimpleContainerMedias,
      } = containerData;

      // 미디어 타입별로 데이터를 분리하여 처리
      const allMediaDataByType: Record<string, any[]> = {
        SAMPLE_BALE: sampleBaleSimpleContainerMedias || [],
        EMPTY: emptySimpleContainerMedias || [],
        QUARTER_LOADED: quarterLoadedSimpleContainerMedias || [],
        HALF_LOADED: halfLoadedSimpleContainerMedias || [],
        FULL_LOADED: fullLoadedSimpleContainerMedias || [],
        ONE_DOOR_CLOSED: oneDoorClosedSimpleContainerMedias || [],
        BOTH_DOORS_CLOSED: bothDoorsClosedSimpleContainerMedias || [],
        CLOSE_UP_SEAL: closeUpSealSimpleContainerMedias || [],
        EXTRA: extraSimpleContainerMedias || [],
      };

      const images: ImageItem[] = [];

      // 타입별로 파일명과 URL을 생성하여 이미지 리스트에 추가
      Object.keys(allMediaDataByType).forEach((type) => {
        const mediaList = allMediaDataByType[type];

        if (mediaList.length > 0) {
          mediaList.forEach((media: any, index) => {
            images.push({
              mediaUrl: media.containerPhotoSimpleMedia.mediaUrl, // mediaUrl 경로
              originalFileName: `${containerNo || "NoContainer"}_${toPascalCase(
                type
              )}${String(index + 1).padStart(2, "0")}.jpg`,
              latitude: media.coordinate?.latitude
                ? Number(media.coordinate?.latitude)
                : undefined,
              longitude: media.coordinate?.longitude
                ? Number(media.coordinate?.longitude)
                : undefined,
            });
          });
        }
      });

      return {
        folderName: containerNo || "-",
        images,
      };
    };

    try {
      setIsDownloadLoading(true);

      const folderList: Folder[] = [];

      // 선택된 각 컨테이너에 대해 데이터를 가져오고, 포맷팅
      for (const containerId of containerCheckList) {
        const response = await getLoadingContainer({
          id: containerId,
        }).unwrap();

        if (response) {
          const formattedData = formatSingleContainerMedia(response);
          folderList.push(formattedData);
        }
      }

      await createZipWithImages(bookingNo || "BOOKING-DEFAULT", folderList);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  return (
    <SectionCard
      cardTitle={t("contract:importer.detail.label.containerLoadingResult")}
    >
      <TitleContainer>
        <AllCheckboxTitle>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setContainerCheckList(containerList.map(({ id }) => id));
              } else {
                setContainerCheckList([]);
              }
            }}
            checked={
              containerList.length === containerCheckList.length &&
              containerList.length > 0
            }
          />

          <SelectContainerCount>
            <Typo typoType="h6" color="gray5">
              {t("task:detail.cards.containerList")}
            </Typo>

            <div>
              <Typo typoType="b6m" color="blue4">
                {containerCheckList.length}
              </Typo>
              <Typo typoType="b6m" color="gray7">
                /{containerList.length}
              </Typo>
            </div>
          </SelectContainerCount>
        </AllCheckboxTitle>
        {/* Buttons */}
        <TitleButtonContainer>
          {/* Download */}
          <StyledButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={handleContainerImageDownloadClick}
            disabled={isDownloadLoading || selectContainerList.length === 0}
          >
            {isDownloadLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <>
                <DownloadIcon
                  color={selectContainerList.length === 0 ? "gray8" : "gray2"}
                />
                {t("task:detail.cards.download")}
              </>
            )}
          </StyledButton>
        </TitleButtonContainer>
      </TitleContainer>
      <AccordionRoot type="multiple">
        {containerList.length >= 1 &&
          containerList.map((item, idx) => {
            return (
              <ContainerAccordionItem
                loadingId={loadingId}
                containerNo={item.containerNo}
                sealNo={item.sealNo}
                containerId={item.id}
                onCheckChange={(containerId) =>
                  handleCheckboxClick(containerId)
                }
                extraRemark={extraRemark}
                key={item.id}
                containerCheckList={containerCheckList}
                index={idx}
              />
            );
          })}
      </AccordionRoot>
    </SectionCard>
  );
}

export default ContainerTaskResultCard;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 16px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DownloadIcon = styled(DownloadSvg)<{ color: ColorType }>`
  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const LoaderContainer = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 89px;
  text-align: center;
`;

const AllCheckboxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectContainerCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
