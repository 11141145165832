import { apiSlice } from "../../baseQuery";
import { ContractDetailShareInfoDto } from "../../contracts/contractShare/interface";
import { Row, Rows } from "../../type";
import { CiInfoDto } from "../ci/interface";
import { PlInfoDto } from "../pl/interface";
import {
  ShipmentContainerMediaByPhotoTypeDto,
  ShipmentContainerMediaContainerDetailDto,
  ShipmentDetailContainersIntegrationInfo,
} from "../shipmentDetail/interface";
import {
  GetShipmentShareRequest,
  ShipmentContainerMediaContainerListDto,
  ShipmentDetailContainerShareInfosDto,
  ShipmentDetailContractBookingShareInfosDto,
  ShipmentDetailDocumentInfoDto,
  ShipmentDetailShareInfoDto,
  ShipmentDetailTaskShareInfosDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const shipmentShareApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적 단일 조회 API
    getShipmentShare: build.query<
      ShipmentDetailShareInfoDto,
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipments/this",
        params,
      }),
      transformResponse: (res: Row<ShipmentDetailShareInfoDto>) => res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 계약/부킹 목록 조회 API
    getShipmentShareContractWithBooking: build.query<
      Rows<ShipmentDetailContractBookingShareInfosDto>,
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipments/this/contract-booking-infos",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 작업 목록 조회 API
    getShipmentShareTasks: build.query<
      Rows<ShipmentDetailTaskShareInfosDto>,
      GetShipmentShareRequest & { page?: number; pageSize?: number }
    >({
      query: (params) => ({
        url: "/shipments/this/tasks",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 컨테이너 목록의 통합 정보 조회 API
    getShipmentShareContainerIntegration: build.query<
      ShipmentDetailContainersIntegrationInfo,
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipments/this/containers-integration-info",
        params,
      }),
      transformResponse: (res: Row<ShipmentDetailContainersIntegrationInfo>) =>
        res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 컨테이너 목록 조회 API
    getShipmentShareContainerList: build.query<
      Rows<ShipmentDetailContainerShareInfosDto>,
      GetShipmentShareRequest & { page?: number; pageSize?: number }
    >({
      query: (params) => ({
        url: "/shipments/this/containers",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적 도메인의 컨테이너 이미지와 관련하여, 컨테이너 목록 조회 API
    getShipmentShareMediaByContainerList: build.query<
      ShipmentContainerMediaContainerListDto[],
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipment-container-media/shipments/this/containers",
        params,
      }),
      transformResponse: (res: Rows<ShipmentContainerMediaContainerListDto>) =>
        res.rows,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적 도메인의 컨테이너 이미지와 관련하여, Photo Type 별 컨테이너 이미지 조회 API
    getShipmentShareMediaByPhoto: build.query<
      ShipmentContainerMediaByPhotoTypeDto,
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipment-container-media-by-photo-type/shipments/this",
        params,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaByPhotoTypeDto>) =>
        res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적 도메인의 컨테이너 이미지와 관련하여, 컨테이너 단일 조회 API
    getShipmentShareMediaByContainer: build.query<
      ShipmentContainerMediaContainerDetailDto,
      GetShipmentShareRequest & { id: number }
    >({
      query: ({ id, ...params }) => ({
        url: `/shipment-container-media/containers/${id}`,
        params,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaContainerDetailDto>) =>
        res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 Document 정보 조회 API
    getShipmentShareDocumentInfo: build.query<
      Row<ShipmentDetailDocumentInfoDto>,
      GetShipmentShareRequest
    >({
      query: (params) => ({
        url: "/shipments/this/document-info",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 Document 정보 중 CiInfoDto 단일 조회 API
    getShipmentShareCiInfo: build.query<CiInfoDto, GetShipmentShareRequest>({
      query: (params) => ({
        url: "/shipments/this/ci-info",
        params,
      }),
      transformResponse: (res: Row<CiInfoDto>) => res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 Document 정보 중 PlInfoDto 단일 조회 API
    getShipmentSharePlInfo: build.query<PlInfoDto, GetShipmentShareRequest>({
      query: (params) => ({
        url: "/shipments/this/pl-info",
        params,
      }),
      transformResponse: (res: Row<PlInfoDto>) => res.row,
      providesTags: [""],
    }),

    // 이메일로 전송받은 shipmentShareKey와 shipmentId로 선적에 귀속된 Document 정보 중 internalScFile 조회를 위한 ContractDetailShareInfoDto 단일 조회 API
    getShipmentShareWithContracts: build.query<
      ContractDetailShareInfoDto,
      GetShipmentShareRequest & { id: number }
    >({
      query: ({ id, ...params }) => ({
        url: `/shipments/this/contacts/${id}`,
        params,
      }),
      transformResponse: (res: Row<ContractDetailShareInfoDto>) => res.row,
      providesTags: [""],
    }),
  }),
});

export const {
  useLazyGetShipmentShareQuery,
  // Contract
  useGetShipmentShareContractWithBookingQuery,
  useLazyGetShipmentShareContractWithBookingQuery,
  // Tasks
  useLazyGetShipmentShareTasksQuery,
  // Container
  useGetShipmentShareContainerIntegrationQuery,
  useLazyGetShipmentShareContainerIntegrationQuery,
  useLazyGetShipmentShareContainerListQuery,
  useLazyGetShipmentShareMediaByContainerListQuery,
  useLazyGetShipmentShareMediaByPhotoQuery,
  useLazyGetShipmentShareMediaByContainerQuery,
  // Document
  useLazyGetShipmentShareDocumentInfoQuery,
  useGetShipmentShareCiInfoQuery,
  useLazyGetShipmentShareCiInfoQuery,
  useGetShipmentSharePlInfoQuery,
  useLazyGetShipmentSharePlInfoQuery,
  useLazyGetShipmentShareWithContractsQuery,
} = shipmentShareApi;
export { shipmentShareApi };
