import React, { useEffect, useRef, useState } from "react";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import styled, { css } from "styled-components";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useNavigate } from "react-router-dom";
import Typo from "@/src/components/atom/Typo";
import Table from "@/src/components/atom/Table";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { AgGridReact } from "ag-grid-react";
import Filter from "@/src/components/molecule/Filter";
import { ColDef } from "ag-grid-community";
import { columnSalesList } from "./columns/columnsSales";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { isUndefined } from "@/src/utils/is";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import colorSet from "@/src/styles/color";
import Input from "@/src/components/atom/Input";
import SearchIcon from "@/src/assets/icons/icon-search.svg";
import Checkbox from "@/src/components/atom/Checkbox";
import { StyledScroll } from "@/src/styles/scroll";
import DoubleLeftSvg from "@/src/assets/icons/icon-double-left.svg";
import typo from "@/src/styles/typography";
import { useGetWarehousesQuery } from "@/src/store/apis/warehouse";
import { WarehouseDto } from "@/src/store/apis/warehouse/interface";
import Tag from "@/src/components/atom/Tag";
import zIndex from "@/src/styles/zIndex";
import {
  GetSalesFilterRequestParams,
  GetSalesRequestParams,
  SalesStatusType,
} from "@/src/store/apis/sales/interface";
import {
  useLazyGetSalesExcelListQuery,
  useLazyGetSalesQuery,
} from "@/src/store/apis/sales";
import { calculateTotalNetWeight } from "./utils/getTotalNetWeight";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Init: GetSalesFilterRequestParams = {
  salesNumber: undefined,
  salesStatus: "all",
  itemCode: undefined,
  quantity: undefined,
  warehouseIdList: [],
};

const FAST_SEARCH_QUERY_KEY = ["salesStatus"];

const warehouseEmptyArray: WarehouseDto[] = [];

const ImporterSalesManagementPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const alert = useAlert();

  const [columnDefs] = useState<ColDef[]>(columnSalesList);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSideContentOpen, setIsSideContentOpen] = useState<boolean>(true);
  const [warehouseFilterValue, setWarehouseFilterValue] = useState<string>("");

  // API
  const [getExcel] = useLazyGetSalesExcelListQuery();
  const [getSaleList, { count, isFetching, salesList, isError, originalArgs }] =
    useLazyGetSalesQuery({
      selectFromResult: ({
        currentData,
        isFetching,
        isError,
        originalArgs,
      }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isFetching,
          originalArgs,
          count: !isUnstable ? currentData.count : 0,
          salesList: !isUnstable ? currentData.rows : undefined,
          isError,
        };
      },
    });

  const { warehouseList, warehouseCount } = useGetWarehousesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);

        return {
          warehousesCurrentData: currentData,
          warehouseList: !isUnstable ? currentData.rows : warehouseEmptyArray,
          warehouseCount: !isUnstable ? currentData.count : 0,
        };
      },
    }
  );

  const allOptionItem = {
    label: t("sales:listFilter.option.all"),
    value: "all",
  };

  const handleExcelDownload = async () => {
    try {
      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "orderDateAt",
        langKey: "sales:listTable.orderDateAt",
      },
      {
        columnKey: "isBookmarked",
        langKey: t("sales:listTable.isBookmarked"),
      },
      {
        columnKey: "salesNumber",
        langKey: "sales:listTable.salesNumber",
      },
      {
        columnKey: "salesStatus",
        langKey: "sales:listTable.salesStatus",
      },
      {
        columnKey: "warehouse",
        langKey: "sales:listTable.warehouse",
      },
      {
        columnKey: "exporterItemCode",
        langKey: "sales:listTable.exporterItemCode",
      },
      {
        columnKey: "unitPrice",
        langKey: "sales:listTable.unitPrice",
      },
      {
        columnKey: "quantity",
        langKey: "sales:listTable.quantity",
      },
      {
        columnKey: "incomingQuantity",
        langKey: "sales:listTable.incomingQuantity",
      },
      {
        columnKey: "orderBalance",
        langKey: "sales:listTable.orderBalance",
      },
      {
        columnKey: "salesFinalProducts",
        langKey: "sales:listTable.salesFinalProducts",
      },
    ],
  });

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetSalesRequestParams, GetSalesFilterRequestParams>({
    isReady,
    gridRef: gridRef.current,
    fetch: getSaleList,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        salesStatus:
          data.salesStatus === "all"
            ? undefined
            : (data?.salesStatus as SalesStatusType),
        salesNumber: data?.salesNumber || undefined,
        itemCode: data?.itemCode || undefined,
        quantity:
          data?.quantity === undefined
            ? undefined
            : Number(data?.quantity.toString().replace(/[^0-9]/g, "")),
        warehouseIdList: !!data.warehouseIdList?.length
          ? data.warehouseIdList.join()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        salesStatus: urlObject.salesStatus ?? "all",
        salesNumber: urlObject.salesNumber,
        itemCode: urlObject.itemCode,
        quantity:
          urlObject.quantity === "0" ? undefined : Number(urlObject.quantity),
        warehouseIdList: urlObject.warehouseIdList
          ? urlObject.warehouseIdList.split(",").map((id) => Number(id))
          : [],
      };
    },
  });

  const salesMappingList = salesList
    ? salesList.map((item) => {
        return {
          ...item,
          incomingQuantity: calculateTotalNetWeight(item.warehousings),
          orderBalance:
            item.warehousings.length >= 1
              ? parseFloat(item.quantity) -
                calculateTotalNetWeight(item.warehousings)
              : 0,
        };
      })
    : [];

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:sales")]}
      pageTitle={t("sideNav:sales")}
      sideAccessory={
        <SideContentSection>
          <FilterSection data-open={isSideContentOpen}>
            <Typo typoType="h6" className="filter-title" as="h3">
              {t("sales:common.warehouseList")}
            </Typo>

            <SearchInputContainer>
              <Input
                value={warehouseFilterValue}
                onChange={({ target }) => setWarehouseFilterValue(target.value)}
                placeholder={t("sales:common.warehousePlaceholder")}
                onClear={() => setWarehouseFilterValue("")}
                suffix={<Icon iconSrc={SearchIcon} iconSize={16} />}
              />
            </SearchInputContainer>

            <SearchInputContainer>
              <Typo typoType="h6" color="gray5">
                {t("sales:common.warehouse")}{" "}
              </Typo>
              <Typo typoType="h6" color="blue4">
                {filterData.warehouseIdList?.length || 0}
              </Typo>
              <Typo typoType="h6" color="gray7">
                /{warehouseCount}
              </Typo>
            </SearchInputContainer>

            <AllSellerContainer>
              <Checkbox
                className="flex-shrink-0"
                checked={
                  filterData.warehouseIdList?.length === warehouseCount &&
                  filterData.warehouseIdList?.length !== 0
                }
                onClick={() => {
                  if (
                    filterData.warehouseIdList?.length === warehouseCount &&
                    filterData.warehouseIdList?.length !== 0
                  ) {
                    onForceSearch("warehouseIdList", []);
                  } else {
                    onForceSearch(
                      "warehouseIdList",
                      warehouseList.map((warehouse) => warehouse.id)
                    );
                  }
                }}
              />
              <Typo typoType="b7r" color="gray4">
                All Warehouse
              </Typo>
            </AllSellerContainer>

            <List>
              {warehouseList
                .filter((item) =>
                  item.warehouseName
                    .toLowerCase()
                    .includes(warehouseFilterValue)
                )
                .map((warehouse) => {
                  return (
                    <ClientListItem>
                      <Checkbox
                        className="flex-shrink-0"
                        checked={filterData.warehouseIdList?.includes(
                          warehouse.id
                        )}
                        onClick={() => {
                          if (
                            filterData.warehouseIdList?.includes(warehouse.id)
                          ) {
                            onForceSearch(
                              "warehouseIdList",
                              filterData.warehouseIdList.filter(
                                (warehouseId) => warehouseId !== warehouse.id
                              )
                            );
                          } else {
                            onForceSearch("warehouseIdList", [
                              ...(filterData?.warehouseIdList || []),
                              warehouse.id,
                            ]);
                          }
                        }}
                      />
                      <Ellipsis>{warehouse.warehouseName}</Ellipsis>
                    </ClientListItem>
                  );
                })}
            </List>
          </FilterSection>

          <ToggleButtonContainer data-open={isSideContentOpen}>
            <ToggleButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              isIconOnly
              onClick={() => setIsSideContentOpen((prev) => !prev)}
            >
              <Icon
                className="double-left-icon"
                iconSrc={DoubleLeftSvg}
                iconSize={20}
              />
            </ToggleButton>
          </ToggleButtonContainer>
        </SideContentSection>
      }
    >
      <FlexColumn gap={16}>
        <SelectSellerCard>
          <Typo typoType="b8m" color="gray3">
            {t("sales:common.selectWarehouse")}
          </Typo>
          <TagList>
            {filterData.warehouseIdList?.length === 0 ||
            filterData.warehouseIdList?.length === warehouseCount ? (
              <Tag label="All" />
            ) : (
              <>
                {filterData.warehouseIdList
                  ?.map((filterWarehouseId: number) => {
                    return {
                      warehouseName:
                        warehouseList.find(
                          (warehouse) => warehouse.id === filterWarehouseId
                        )?.warehouseName || "",
                      warehouseId: filterWarehouseId,
                    };
                  })
                  .map(
                    (warehouse: {
                      warehouseName: string;
                      warehouseId: number;
                    }) => {
                      return (
                        <Tag
                          label={warehouse.warehouseName}
                          onRemove={() => {
                            onForceSearch(
                              "warehouseIdList",
                              filterData.warehouseIdList?.filter(
                                (warehouseId) =>
                                  warehouseId !== warehouse.warehouseId
                              )
                            );
                          }}
                        />
                      );
                    }
                  )}
              </>
            )}
          </TagList>
        </SelectSellerCard>

        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "salesNumber",
              label: t("sales:listFilter.label.salesNumber"),
              type: "input",
              props: {
                placeholder: t("sales:listFilter.placeholder.salesNumber"),
              },
            },
            {
              key: "salesStatus",
              label: t("sales:listFilter.label.salesStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("sales:listFilter.placeholder.all"),
                allowClear: filterData.salesStatus === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("salesStatus", "all");
                    onForceSearch("salesStatus", "all");
                  } else {
                    onFilterDataChange("salesStatus", value);
                    onForceSearch("salesStatus", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("sales:listFilter.label.itemCode"),
              type: "input",
              props: {
                placeholder: t("sales:listFilter.placeholder.itemCode"),
              },
            },
            {
              key: "quantity",
              label: t("sales:listFilter.label.salesQuantity"),
              type: "input",
              props: {
                value: filterData.quantity
                  ? filterData.quantity?.toString().replace(/[^0-9]/g, "")
                  : "",
                placeholder: t("sales:listFilter.placeholder.salesQuantity"),
              },
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key as string)) {
              onForceSearch(key, value);
            }
          }}
          onReset={() => {
            onFilterDataChange("quantity", undefined);
            onResetFilter();
          }}
          onSubmit={onSearch}
          optionList={[
            {
              key: "salesStatus",
              option: [
                allOptionItem,
                {
                  label: t("sales:listFilter.option.processing"),
                  value: "PROCESSING",
                },
                {
                  label: t("sales:listFilter.option.complete"),
                  value: "COMPLETE",
                },
              ],
            },
          ]}
        />

        <FlexColumn gap={8}>
          <FlexRow>
            <Typo typoType="b5m" color="gray5">
              {t("sales:common.receiptList")}
            </Typo>

            <FlexRow gap={8}>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("sales:common.button.excel")}
              </StyledButton>

              <StyledButton
                buttonSize={40}
                onClick={() => navigate(IMPORTER_PRIVATE_PATH.SALES_ADD)}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                {t("sales:common.button.createContract")}
              </StyledButton>
            </FlexRow>
          </FlexRow>

          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : salesMappingList}
              isPaginationDataMaping
              columnDefs={columnDefs}
              handlePaginationClick={(page, pageSize) => {
                onPaginationChange({ page, pageSize });
              }}
              totalPage={count}
              onRowClicked={({ data }) => {
                navigate(`${IMPORTER_PRIVATE_PATH.SALES_DETAIL}/${data.id}`);
              }}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onGridReady={() => setIsReady(true)}
            />
          </SectionCardWithoutHeader>
        </FlexColumn>
      </FlexColumn>
    </ImporterMainLayout>
  );
};

export default ImporterSalesManagementPage;

const FlexRow = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

const FlexColumn = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const SideContentSection = styled.section`
  display: flex;
  z-index: ${zIndex.sideNav};
  background: ${colorSet.white};
`;

const FilterSection = styled.section`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${colorSet.gray9};
  white-space: nowrap;

  h3.filter-title {
    padding: 16px;
    border-bottom: 1px solid ${colorSet.gray9};
  }

  transition: all 0.5s;

  &[data-open="true"] {
    width: 220px;
  }

  &[data-open="false"] {
    width: 0px;
    overflow: hidden;
    border: none;
    transform: translateX(-100%) translateZ(-100);
  }
`;

const SearchInputContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const AllSellerContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const List = styled.ul`
  overflow: auto;
  flex: 1;
  ${StyledScroll};
`;

const ClientListItem = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  .flex-shrink-0 {
    flex-shrink: 0;
  }
`;

const ToggleButtonContainer = styled.div`
  padding: 12px 4px 12px 16px;

  &[data-open="false"] {
    .double-left-icon {
      transform: rotateY(180deg);
    }
  }
`;

const ToggleButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 6px;
  z-index: 1;
`;

const Ellipsis = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${typo.b7r}
`;

const SelectSellerCard = styled(SectionCardWithoutHeader)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0px 2px 4px 0px #0000000d;
`;
