import dayjs from "dayjs";
import styled from "styled-components";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import Dot from "@/src/components/atom/Dot";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Badge from "@/src/components/atom/Badge";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import getTableIndex from "@/src/utils/getTableIndex";
import { renderShipmentStatusBadge } from "../utils/renderShipmentStatusBadge";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import { TFunction } from "i18next";

export const columnShipmentList = (t: TFunction): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <TypoContainer>
          <Typo typoType="b9r">
            {params.node?.rowIndex
              ? getTableIndex(
                  params.node?.rowIndex + 1,
                  params.data.page,
                  params.data.pageSize
                )
              : getTableIndex(1, params.data.page, params.data.pageSize)}
          </Typo>
        </TypoContainer>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isBookmarked === true) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }

      return null;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "bookingStatusForImporter",
    flex: 1,
    minWidth: 143,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return renderShipmentStatusBadge({
          t: params.data.t,
          type: params.data.bookingStatusForImporter,
          lang: params.data.lang,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Exporter Code",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "PO No.",
    cellStyle: { textAlign: "center" },
    field: "poNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.poNo ? (
        <TableBlueText typoType="b9r" color="systemBlue2">
          {params.data.poNo}
        </TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.bookingNo ? (
        <Typo typoType="b9r">{params.data.bookingNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "B/L No.",
    cellStyle: { textAlign: "center" },
    field: "blNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.blNo ? (
        <TableBlueText typoType="b9r" color="systemBlue2">
          {params.data.blNo}
        </TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "No. of Container",
    cellStyle: { textAlign: "center" },
    field: "numberOfContainer",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfContainer ? (
        <Typo typoType="b9r">{params.data.numberOfContainer} EA</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Total Net Weight",
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Place of Delivery",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Port of Loading",
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.data.portOfLoading}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitPrice",
    flex: 1,
    minWidth: 158,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitPrice ? (
        <Typo typoType="b9r">{params.data.unitPrice}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "ETD / ATD",
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {!params.data.etdAt
                ? "-"
                : dayjs(params.data.etdAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )}{" "}
              /{" "}
              {!params.data.atdAt
                ? "-"
                : dayjs(params.data.atdAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )}
            </Typo>{" "}
            {params.data.etdAt && params.data.atdAt ? (
              <EtdCompareCount
                initDate={params.data.etdAt || ""}
                currentDate={params.data.atdAt || ""}
                typoType="b9m"
              />
            ) : null}
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "ETA / ATA",
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <>
            <Typo typoType="b9r">
              {!params.data.etaAt
                ? "-"
                : dayjs(params.data.etaAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )}{" "}
              /{" "}
              {!params.data.ataAt
                ? "-"
                : dayjs(params.data.ataAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )}
            </Typo>{" "}
            {params.data.etaAt && params.data.ataAt ? (
              <EtdCompareCount
                initDate={params.data.etaAt || ""}
                currentDate={params.data.ataAt || ""}
                typoType="b9m"
              />
            ) : null}
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: "loadingPhoto",
    flex: 1,
    minWidth: 135,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.taskSharedAt ? (
        <RelativeDiv>
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            text={t("shipment:importer.list.badge.shared")}
            badgeSize="S"
          />

          {!params.data.taskIsRead && <UnreadDot />}
        </RelativeDiv>
      ) : (
        <Badge
          badgeColor="gray10"
          color="gray7"
          text={t("shipment:importer.list.badge.unshared")}
          badgeSize="S"
        />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "C/I, P/L",
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    flex: 1,
    minWidth: 100,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.ciPlSharedAt ? (
        <RelativeDiv>
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            text={t("shipment:importer.list.badge.shared")}
            badgeSize="S"
          />

          {!params.data.isCiPlRead && <UnreadDot />}
        </RelativeDiv>
      ) : (
        <Badge
          badgeColor="gray10"
          color="gray7"
          text={t("shipment:importer.list.badge.unshared")}
          badgeSize="S"
        />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "B/L",
    cellStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    flex: 1,
    minWidth: 100,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.blSharedAt ? (
        <RelativeDiv>
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            text={t("shipment:importer.list.badge.shared")}
            badgeSize="S"
          />

          {!params.data.isBlRead && <UnreadDot />}
        </RelativeDiv>
      ) : (
        <Badge
          badgeColor="gray10"
          color="gray7"
          text={t("shipment:importer.list.badge.unshared")}
          badgeSize="S"
        />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Main category",
    cellStyle: { textAlign: "center" },
    field: "mainCategoryCodeItemName",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.mainCategory ? (
        <Typo typoType="b9r">{params.data.mainCategory}</Typo>
      ) : (
        "-"
      );
    },
  },
];

const TypoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 6px;
`;

const RelativeDiv = styled.div`
  position: relative;
`;

const UnreadDot = styled(Dot).attrs({ dotColor: "red2" })`
  position: absolute;
  top: 0;
  right: 0;
`;
