import { ReactNode, useRef, useState } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import Input from "../../atom/Input";
import { styled } from "styled-components";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import { Button } from "../../atom/Button";
import Icon from "../../atom/Icon";
import SearchSvg from "@/src/assets/icons/icon-search.svg";
import Typo from "../../atom/Typo";
import AddressSearchModal from "../../organism/AddressSearchModal";
import { InputError } from "../FormItem";
import { AddressDto } from "@/src/store/apis/common/interface";
import { useTranslation } from "react-i18next";

interface AddressFormItemProps<TFieldValues extends FieldValues> {
  register: UseFormRegister<TFieldValues>;
  isRequired?: boolean;
  label?: ReactNode;
  setValue?: UseFormSetValue<TFieldValues>;
  direction?: "horizontal" | "vertical";
  errorMessage?: ReactNode;
  className?: string;
  error?: any;
  clearErrors?: UseFormClearErrors<any>;
  onAddressItemClick?: (address: AddressDto) => void;
  disabled?: boolean;
}

const AddressFormItem = <TFieldValues extends FieldValues>({
  register,
  isRequired = true,
  label = "Address",
  setValue,
  direction = "vertical",
  errorMessage,
  className,
  error,
  clearErrors,
  onAddressItemClick,
  disabled,
}: AddressFormItemProps<TFieldValues>) => {
  const { t } = useTranslation();
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState<boolean>(false);
  const isStringLabel = typeof label === "string";
  const apartmentInputRef = useRef<HTMLInputElement | null>(null);
  const handleSearchClick = () => {
    setIsSearchDialogOpen(true);
  };

  const { ref, ...apartmentRestField } = register(
    "apartment" as Path<TFieldValues>,
    { required: false }
  );

  const setHookFormValue = (
    name: Path<TFieldValues>,
    value: PathValue<TFieldValues, Path<TFieldValues>>
  ) => {
    setValue?.(name, value);
  };

  const renderDialog = () => {
    if (!isSearchDialogOpen) return null;
    return (
      <AddressSearchModal
        onClose={() => setIsSearchDialogOpen(false)}
        onAddressItemClick={(addressDetail) => {
          if (addressDetail) {
            onAddressItemClick?.(addressDetail);
            setHookFormValue(
              "apartment" as Path<TFieldValues>,
              (addressDetail?.streetAddress ?? "") as PathValue<
                TFieldValues,
                Path<TFieldValues>
              >
            );
            setHookFormValue(
              "city" as Path<TFieldValues>,
              (addressDetail?.locality ?? "") as PathValue<
                TFieldValues,
                Path<TFieldValues>
              >
            );
            setHookFormValue(
              "state" as Path<TFieldValues>,
              (addressDetail?.region ?? "") as PathValue<
                TFieldValues,
                Path<TFieldValues>
              >
            );
            setHookFormValue(
              "postal" as Path<TFieldValues>,
              (addressDetail?.postalCode ?? "") as PathValue<
                TFieldValues,
                Path<TFieldValues>
              >
            );
            setHookFormValue(
              "country" as Path<TFieldValues>,
              (addressDetail?.countryName ?? "") as PathValue<
                TFieldValues,
                Path<TFieldValues>
              >
            );
            if (addressDetail?.locality) {
              clearErrors?.("city");
            }
            if (addressDetail?.region) {
              clearErrors?.("state");
            }
            if (addressDetail?.postalCode) {
              clearErrors?.("postal");
            }
            if (addressDetail?.countryName) {
              clearErrors?.("country");
            }
            setIsSearchDialogOpen(false);
            apartmentInputRef.current?.focus();
          }
        }}
      />
    );
  };

  return (
    <FormItemContainer data-direction={direction} className={className}>
      {isStringLabel ? (
        <LabelContainer>
          <FormLabel data-direction={direction} data-required={isRequired}>
            {label}
          </FormLabel>
          <div>
            <StyledButton
              buttonSize={24}
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={handleSearchClick}
              disabled={disabled}
            >
              <StyledIcon iconSrc={SearchSvg} iconSize={16} />
              <Typo typoType="btn4m" color="gray2">
                {t("signupCorp:content.searchAddress")}
              </Typo>
            </StyledButton>
          </div>
        </LabelContainer>
      ) : (
        label
      )}
      <AddressFormItemContainer>
        <StyledInput
          ref={(node) => {
            ref(node);
            apartmentInputRef.current = node;
          }}
          {...apartmentRestField}
          disabled={disabled}
          placeholder={`${t(
            "signupCorp:content.addressPlaceholder.apartmentUnit"
          )} ${isRequired ? `(${t("common:optional")})` : ""}`}
          onClear={() =>
            setHookFormValue(
              "apartment" as Path<TFieldValues>,
              "" as PathValue<TFieldValues, Path<TFieldValues>>
            )
          }
        />
        <StyledInput
          {...register("city" as Path<TFieldValues>)}
          placeholder={`${t("signupCorp:content.addressPlaceholder.city")} ${
            isRequired ? `(${t("common:optional")})` : ""
          }`}
          disabled={disabled}
          onClear={() =>
            setHookFormValue(
              "city" as Path<TFieldValues>,
              "" as PathValue<TFieldValues, Path<TFieldValues>>
            )
          }
        />
        <HalfInputsContainer>
          <FlexColumn>
            <HalfInput
              {...register("state" as Path<TFieldValues>)}
              placeholder={`${t(
                "signupCorp:content.addressPlaceholder.state"
              )} ${isRequired ? `(${t("common:optional")})` : ""}`}
              disabled={disabled}
              onClear={() =>
                setHookFormValue(
                  "state" as Path<TFieldValues>,
                  "" as PathValue<TFieldValues, Path<TFieldValues>>
                )
              }
            />
          </FlexColumn>
          <FlexColumn>
            <HalfInput
              {...register("postal" as Path<TFieldValues>)}
              placeholder={`${t(
                "signupCorp:content.addressPlaceholder.postalCode"
              )} ${isRequired ? `(${t("common:optional")})` : ""}`}
              disabled={disabled}
              onClear={() =>
                setHookFormValue(
                  "postal" as Path<TFieldValues>,
                  "" as PathValue<TFieldValues, Path<TFieldValues>>
                )
              }
            />
          </FlexColumn>
        </HalfInputsContainer>
        <StyledInput
          {...register("country" as Path<TFieldValues>, {
            required: isRequired,
          })}
          placeholder={t("signupCorp:content.addressPlaceholder.country")}
          data-invalid={!!error?.["country"]}
          disabled={disabled}
          onClear={() =>
            setHookFormValue(
              "country" as Path<TFieldValues>,
              "" as PathValue<TFieldValues, Path<TFieldValues>>
            )
          }
        />
        {error?.["country"] && (
          <InputError message={errorMessage || t("error:required")} />
        )}
      </AddressFormItemContainer>
      {renderDialog()}
    </FormItemContainer>
  );
};

export default AddressFormItem;

export const AddressFormItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const HalfInputsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const HalfInput = styled(Input)`
  width: 100%;
`;

const FormLabel = styled.p`
  ${typo.b7r};
  flex-shrink: 0;
  min-width: 163px;
  max-width: 163px;

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &[data-direction="vertical"] {
    ${AddressFormItemContainer} {
      width: calc(100% - 163px);
      margin-left: auto;
    }
  }
`;

const StyledIcon = styled(Icon)``;

const StyledButton = styled(Button)`
  display: flex;
  gap: 2px;
  margin-left: auto;

  ${StyledIcon} {
    flex-shrink: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;
