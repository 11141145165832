import { ReactNode, useState } from "react";
import Dialog, { DialogCloseButton } from "@/src/components/atom/Dialog";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import CloseIconSvg from "@/src/assets/icons/icon-cancle-black.svg";
import styled, { css } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import {
  useGetContainerDetailInCombineAndSplitQuery,
  useGetContainerHistoryQuery,
} from "@/src/store/apis/tasks/taskDetail";
import { isUndefined } from "@/src/utils/is";
import Loader from "@/src/components/atom/Loader";
import dayjs from "dayjs";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import { useNavigate } from "react-router-dom";
import Table from "@/src/components/atom/Table";
import { containerHistoryColumn } from "../../../columns/columnTaskList";
import { ICellRendererParams } from "ag-grid-community";
import SingleContainerPhotoDialog from "@/src/pages/exporter/Shipment/components/dialog/SingleContainerPhotoDialog";
import { MAIN_CONTENT_MAIN_ID } from "@/src/components/template/Layout/exporter/ExporterMainContentLayout";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

interface ContainerHistoryDialogProps {
  containerId: number;
  onClose: () => void;
}

const LinkTaskCellRenderer = (
  params: ICellRendererParams,
  onClose: () => void
) => {
  const navigate = useNavigate();

  return (
    <TaskLinkTextButton
      onClick={() => {
        onClose();
        navigate(
          `${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${params.data.taskId}`
        );
        document.getElementById(MAIN_CONTENT_MAIN_ID)?.scrollTo(0, 0); // Scroll 유지 풀기
      }}
    >
      {params.data.taskNo}
    </TaskLinkTextButton>
  );
};

const ContainerHistoryDialog = ({
  containerId,
  onClose,
}: ContainerHistoryDialogProps) => {
  const navigate = useNavigate();
  const [isContainerPhotoDialog, setIsContainerPhotoDialog] =
    useState<boolean>(false);

  const { containerDetail, isContainerDetailFetching } =
    useGetContainerDetailInCombineAndSplitQuery(
      { id: containerId },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;
          return {
            containerDetail: isUnstable ? null : currentData.row,
            isContainerDetailFetching: isFetching,
          };
        },
      }
    );

  const { historyDetail, isHistoryDetailFetching } =
    useGetContainerHistoryQuery(
      { id: containerId },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;
          return {
            historyDetail: isUnstable ? [] : currentData.rows,
            isHistoryDetailFetching: isFetching,
          };
        },
      }
    );

  const boardItemList: {
    label: ReactNode;
    value: ReactNode;
    isDivider?: boolean;
  }[] = [
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Container No.
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="blue2">
          {containerDetail?.containerNo ?? "-"}
        </Typo>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Seal No.
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="blue2">
          {containerDetail?.sealNo ?? "-"}
        </Typo>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Original Loading No.
        </Typo>
      ),
      value: (
        <TextButton
          onClick={() => {
            navigate(
              `${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${containerDetail?.originalTaskId}`
            );
            onClose();
            document.getElementById(MAIN_CONTENT_MAIN_ID)?.scrollTo(0, 0); // Scroll 유지 풀기
          }}
        >
          {containerDetail?.originalTaskNo ?? "-"}
        </TextButton>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Factory
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="gray2">
          {containerDetail?.workplaceName ?? "-"}
        </Typo>
      ),
    },
    {
      label: "",
      value: "",
      isDivider: true,
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Working Day
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="gray2">
          {containerDetail?.workingDayAt
            ? dayjs(containerDetail?.workingDayAt).format("YYYY-MM-DD")
            : "-"}
        </Typo>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Item
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="gray2">
          {containerDetail?.item ?? "-"}
        </Typo>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Item Code
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="gray2">
          {containerDetail?.itemCode ?? "-"}
        </Typo>
      ),
    },
    {
      label: (
        <Typo typoType="b9m" color="gray6">
          Net Weight
        </Typo>
      ),
      value: (
        <Typo typoType="h6" color="gray2">
          {containerDetail?.netWeight ?? "-"}
        </Typo>
      ),
    },
  ];

  const renderContainerPhotoDialog = () => {
    if (!isContainerPhotoDialog) return null;

    return (
      <SingleContainerPhotoDialog
        containerId={containerId}
        onClose={() => setIsContainerPhotoDialog(false)}
      />
    );
  };

  return (
    <Dialog
      open
      onOpenChange={onClose}
      title="Container History"
      width={850}
      titleRightAccessory={
        <>
          <FlexDiv gap={8}>
            <Button
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={32}
              onClick={() => setIsContainerPhotoDialog(true)}
            >
              Photo View
            </Button>

            <DialogCloseButton>
              <Icon iconSrc={CloseIconSvg} iconSize={20} />
            </DialogCloseButton>
          </FlexDiv>
        </>
      }
    >
      {isContainerDetailFetching || isHistoryDetailFetching ? (
        <LoaderDiv>
          <Loader size={40} />
        </LoaderDiv>
      ) : (
        <FlexDiv direction="column" gap={16}>
          <Board>
            {boardItemList.map(({ label, value, isDivider = false }, idx) => {
              if (isDivider) {
                return <Divider />;
              }
              return (
                <FlexDiv direction="column" gap={8} key={idx.toString()}>
                  {label}
                  {value}
                </FlexDiv>
              );
            })}
          </Board>

          <Table
            rowData={historyDetail}
            columnDefs={containerHistoryColumn}
            isPagination={false}
            height={320}
            components={{
              LinkTaskCellRenderer: (params: any) =>
                LinkTaskCellRenderer(params, onClose),
            }}
          />
        </FlexDiv>
      )}
      {renderContainerPhotoDialog()}
    </Dialog>
  );
};

export default ContainerHistoryDialog;

const FlexDiv = styled.div<{ gap?: number; direction?: "column" | "row" }>`
  display: flex;
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
`;

const LoaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const TextButton = styled.span`
  ${typo.h6}
  color: ${colorSet.systemBlue2};
  text-decoration: underline;
  cursor: pointer;
`;

const TaskLinkTextButton = styled.p`
  text-decoration: underline;
  color: ${colorSet.systemBlue2};
  cursor: pointer;
`;
const Divider = styled.div`
  grid-column: 1 / 5;
  height: 1px;
  width: 100%;
  background: ${colorSet.gray9};
  margin: 8px 0;
`;
