import SectionCard from "@/src/components/molecule/SectionCard";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import { downloadFile } from "@/src/utils/downloadFile";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { StyledScroll } from "@/src/styles/scroll";
import Typo from "@/src/components/atom/Typo";
import typo from "@/src/styles/typography";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import ScDownLoad from "@/src/components/template/pdfs/Sc/DownLoad";
import ScPreview from "@/src/components/template/pdfs/Sc/Preview";
import Icon from "@/src/components/atom/Icon";
import PreviewBlackSvg from "@/src/assets/icons/icon-preview-black.svg";
import DownloadBlackSvg from "@/src/assets/icons/icon-download-black.svg";
import PreviewBlueSvg from "@/src/assets/icons/icon-preview-blue.svg";
import DownloadBlueSvg from "@/src/assets/icons/icon-download-blue.svg";
import dayjs from "dayjs";
import { Button, IconButton } from "@/src/components/atom/Button";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import renderContractStatusBadge from "@/src/pages/exporter/Contract/utils/renderContractStatusBadge";
import styled from "styled-components";
import colorSet from "@/src/styles/color";
import getScPdfData from "@/src/components/template/pdfs/Sc/utils/getScPdfData";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import { isNull } from "@/src/utils/is";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { useTranslation } from "react-i18next";

interface ContractItemProps {
  contractData?: ContractDetailShareInfoDto | ContractDetailViewDto;
  isError?: boolean;
}

function ContractItem({ contractData, isError }: ContractItemProps) {
  const { t } = useTranslation();
  const scPdfData = getScPdfData({
    type: "SHARE",
    sharedScData: contractData,
  });

  const renderBadgeContainer = () => {
    return (
      <HeaderContainer>
        {contractData?.contractStatus &&
          renderContractStatusBadge({
            t,
            type: contractData?.contractStatus as ContractStatusType,
            size: "L",
          })}

        {!contractData?.scSimpleMedia?.id && (
          <PdfButtonContainer>
            <ScDownLoad
              fileName={`SC NO.${contractData?.scNo}.pdf`}
              data={scPdfData}
              trigger={
                <StyledButton
                  buttonSize={32}
                  buttonColor="black"
                  buttonGrade="tertiary"
                >
                  <Icon iconSrc={DownloadBlackSvg} iconSize={20} />
                  {t("shareEmail:sharedContract.buttons.download")}
                </StyledButton>
              }
            />
            <ScPreview
              data={scPdfData}
              trigger={
                <StyledButton
                  buttonSize={32}
                  buttonColor="black"
                  buttonGrade="tertiary"
                >
                  <Icon iconSrc={PreviewBlackSvg} iconSize={20} />
                  {t("shareEmail:sharedContract.buttons.preview")}
                </StyledButton>
              }
            />
          </PdfButtonContainer>
        )}
      </HeaderContainer>
    );
  };

  const renderContractNoneTitleCard = () => {
    return (
      <SectionCardGrid>
        <StyledSectionCardColumnSix
          label={
            <Typo color="gray6" typoType="b9m">
              Importer
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.buyerName ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Shipping Terms
            </Typo>
          }
          direction="vertical"
          value={
            <ShippingTermsContainer>
              <Typo typoType="h4">{`${
                contractData?.shippingTerms ?? "-"
              }`}</Typo>
              {contractData?.shippingTermsRemark && (
                <BreakWordTypo typoType="h4">{`${contractData?.shippingTermsRemark}`}</BreakWordTypo>
              )}
            </ShippingTermsContainer>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Importer Contact
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.buyerEmployeeName ?? "-"}
            </BreakWordTypo>
          }
        />

        <HorizenGrayLine />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Item
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {contractData?.exporterItem ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Qty
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              contractData?.quantity && contractData?.quantityUnit
                ? `${Number(contractData?.quantity).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${contractData?.quantityUnit}`
                : "-"
            } `}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Unit Price
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              contractData?.unitPrice && contractData?.unitPriceUnit
                ? `${Number(contractData?.unitPrice).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${contractData?.unitPriceUnit}`
                : "-"
            }`}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Amount
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {`${
                contractData?.quantity && contractData?.unitPrice
                  ? `${parseFloat(
                      (
                        Number(contractData?.quantity) *
                        Number(contractData?.unitPrice)
                      ).toFixed(2)
                    ).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${contractData?.unitPriceUnit}`
                  : "-"
              }`}
            </BreakWordTypo>
          }
        />
      </SectionCardGrid>
    );
  };

  const renderContractInformationCard = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.detail.contractInformationCard.title")}
      >
        <ContractInformationFlexColumn>
          {/* Card */}
          <ContractDateContainer>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Payment Terms
              </Typo>
              <PaymentTermsContainer>
                <BreakWordTypo color="blue2" typoType="h4">
                  {contractData?.paymentTerms
                    ? `${contractData?.paymentTerms} ${
                        contractData?.paymentTermsRemark
                          ? contractData?.paymentTermsRemark
                          : ""
                      }`
                    : "-"}
                </BreakWordTypo>
              </PaymentTermsContainer>
            </ContractDateCard>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Order Date
              </Typo>
              <BreakWordTypo color="blue2" typoType="h4">
                {contractData?.orderDateAt
                  ? dayjs.utc(contractData?.orderDateAt).format("YYYY-MM-DD")
                  : "-"}
              </BreakWordTypo>
            </ContractDateCard>

            {contractData?.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  Expire Date
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {contractData?.expireDateAt
                    ? dayjs.utc(contractData?.expireDateAt).format("YYYY-MM-DD")
                    : "-"}
                </Typo>
              </ContractDateCard>
            )}

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Last Shipment Date
              </Typo>
              <Typo color="blue2" typoType="h4">
                {contractData?.lastShipmentDateAt
                  ? dayjs
                      .utc(contractData?.lastShipmentDateAt)
                      .format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>
          </ContractDateContainer>

          <ContractInformationGridContainer>
            <ContractInformationGridItem>
              {/* Location */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Location
                </Typo>
                <section>
                  <SectionCardRow
                    label={"Place of Delivery"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.placeOfDelivery ??
                          contractData?.anyPortIn ??
                          "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={"Origin / Location"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.origin ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  {contractData?.allocation && (
                    <SectionCardRow
                      label="Allocation"
                      value={
                        <BreakWordTypo typoType="b7r" color="gray5" as="p">
                          {contractData?.allocation ?? "-"}
                        </BreakWordTypo>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>

              {/* Attachment File */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Attachment File
                </Typo>
                <section>
                  <SectionCardRow
                    label={"PO No."}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {contractData?.poNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.poSimpleMedia)}
                          >
                            {isNull(contractData?.poSimpleMedia) ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    contractData?.poSimpleMedia?.mediaUrl ?? "",
                                    contractData?.poSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.poSimpleMedia)}
                          >
                            {isNull(contractData?.poSimpleMedia) ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={contractData?.poSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  <SectionCardRow
                    label={"SC No."}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {contractData?.scNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.scSimpleMedia)}
                          >
                            {!contractData?.scSimpleMedia ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    contractData?.scSimpleMedia?.mediaUrl ?? "",
                                    contractData?.scSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(contractData?.scSimpleMedia)}
                          >
                            {!contractData?.scSimpleMedia ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={contractData?.scSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  {contractData?.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                    <SectionCardRow
                      label={"L/C No."}
                      value={
                        <FileInfoContainer>
                          <BreakWordTypo typoType="b7r" color="gray5">
                            {contractData?.lcNo ?? "-"}
                          </BreakWordTypo>
                          <div>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                contractData?.paymentTermsSimpleMedia
                              )}
                            >
                              {contractData?.paymentTermsSimpleMedia ===
                              null ? (
                                <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                              ) : (
                                <Icon
                                  iconSrc={DownloadBlueSvg}
                                  iconSize={16}
                                  onClick={() =>
                                    downloadFile(
                                      contractData?.paymentTermsSimpleMedia
                                        ?.mediaUrl ?? "",
                                      contractData?.paymentTermsSimpleMedia
                                        ?.originalFileName ?? ""
                                    )
                                  }
                                />
                              )}
                            </IconButton>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                contractData?.paymentTermsSimpleMedia
                              )}
                            >
                              {contractData?.paymentTermsSimpleMedia ===
                              null ? (
                                <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                              ) : (
                                <a
                                  href={
                                    contractData?.paymentTermsSimpleMedia
                                      ?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon
                                    iconSrc={PreviewBlueSvg}
                                    iconSize={16}
                                  />
                                </a>
                              )}
                            </IconButton>
                          </div>
                        </FileInfoContainer>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>

            <GrayLine />

            <ContractInformationGridItem>
              {/* ETC */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  ETC
                </Typo>
                <section>
                  <SectionCardRow
                    label={"HS Code"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.hsCode ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={"Freetime"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {contractData?.freetime ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                </section>
              </ContractInformationRow>

              {/* Optional */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Optional
                </Typo>
                <section>
                  <SectionCardRow
                    label={"Contract Term"}
                    value={
                      contractData?.isAddContractTerms
                        ? "Included"
                        : "Not Included"
                    }
                  />
                  <SectionCardRow
                    label={"Bank Detail"}
                    value={
                      contractData?.isAddBankDetail
                        ? "Included"
                        : "Not Included"
                    }
                  />
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>
          </ContractInformationGridContainer>
        </ContractInformationFlexColumn>
      </SectionCard>
    );
  };

  const renderRemarkCard = () => {
    return (
      <SectionCard cardTitle="Remark">
        <RemarkContainer>
          <SectionCardRow
            label={
              <Typo color="gray7" typoType="b9m">
                Contract Remark
              </Typo>
            }
            direction="vertical"
            value={
              <ReadOnlyText>{contractData?.contractRemark ?? "-"}</ReadOnlyText>
            }
          />
        </RemarkContainer>
      </SectionCard>
    );
  };

  const renderContractContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          Sales Contract No. {contractData?.scNo ?? "-"}
        </PageTitle>

        <FlexColumn>
          {/* header */}
          {renderBadgeContainer()}
          {/* ContractNoneTitleCard */}
          {renderContractNoneTitleCard()}
          {/* Contract Information */}
          {renderContractInformationCard()}
          {/* Remark */}
          {renderRemarkCard()}
        </FlexColumn>
      </>
    );
  };

  return renderContractContent();
}

export default ContractItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PdfButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardColumnSix = styled(SectionCardRow)`
  grid-column: 6 span;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTermsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ContractDateCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContractDateContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const ContractInformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const ContractInformationGridContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const ContractInformationGridItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 182px;
  ${StyledScroll}
`;

const HorizenGrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
