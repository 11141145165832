import { forwardRef, useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import { InputNumber, InputNumberProps } from "antd";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";

interface NumberInputProps extends InputNumberProps {
  "data-invalid"?: boolean;
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const [isFocus, setIsFocus] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      const handleFocus = () => setIsFocus(true);
      const handleBlur = () => setIsFocus(false);

      if (inputRef.current) {
        inputRef.current.addEventListener("blur", handleBlur);
        inputRef.current.addEventListener("focus", handleFocus);

        return () => {
          inputRef.current?.removeEventListener("blur", handleBlur);
          inputRef.current?.removeEventListener("focus", handleFocus);
        };
      }
    }, []);
    return (
      <StyledInputNumber
        ref={(node) => {
          inputRef.current = node;
          if (ref) {
            if (typeof ref === "function") {
              ref(node);
            } else {
              ref.current = node;
            }
          }
        }}
        isFocus={isFocus}
        isDisabled={!!props.disabled}
        size="large"
        isInvalid={!!props["data-invalid"]}
        {...props}
      />
    );
  }
);

export default NumberInput;

const StyledInputNumber = styled(InputNumber)<{
  isFocus: boolean;
  isDisabled: boolean;
  isInvalid: boolean;
}>`
  ${typo.b7r};
  width: 100%;
  transition: all 0.15s;
  border: 1px solid ${colorSet.gray9};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  input.ant-input-number-input {
    padding: 0 16px;
  }

  &:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25),
      0px 0px 0px 3px rgba(24, 123, 235, 0.2), 0px 0px 0px 1px #1479eb;
    border: 1px solid ${colorSet.white};
  }

  ${({ isFocus }) =>
    isFocus &&
    css`
      border: 1px solid ${colorSet.blue1};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

      &:hover {
        box-shadow: none;
        border: 1px solid ${colorSet.blue1};
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      border: 1px solid ${colorSet.gray8};
      background: ${colorSet.gray10};
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        border: 1px solid ${colorSet.gray8};
      }
    `};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 1px solid ${colorSet.red2};

      &:hover {
        box-shadow: none;
        border: 1px solid ${colorSet.red2};
      }
    `};
`;
