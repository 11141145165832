import { useRef, useState } from "react";
import styled from "styled-components";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BasicCard from "@/src/components/atom/Cards/BasicCard";
import { useAppSelector } from "@/src/store";
import FrameSvg from "@/src/assets/icons/icon-frame.svg";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import VerificationInputDiv from "@/src/components/molecule/VerificationInputDiv";
import GridCardRadio from "@/src/components/molecule/GridCardRadio";
import { Button } from "@/src/components/atom/Button";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import RegisteredAccountDialog from "../dialog/RegisteredAccountDialog";
import { useTranslation } from "react-i18next";

export interface StepProps {
  onNextStep: (
    isNewClient: boolean,
    linkCode: string,
    clientId?: number
  ) => void;
  linkCode: string;
}

enum DialogState {
  NULL,
  EXIST_CLIENT,
}

const EnterAccountCode = ({ onNextStep, linkCode }: StepProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement[]>([]);
  const companyType = useAppSelector(
    (state) => state.auth.user?.exporter.companyType
  );
  const navigate = useNavigate();

  const basicCodeArr = ["", "", "", "", "", "", "", ""].map(
    (_, idx) => linkCode?.[idx] || ""
  );
  const [codeArr, setCodeArr] = useState<string[]>(basicCodeArr);
  const [clientSelection, setClientSelection] = useState<"new" | "exist">(
    "new"
  );
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);

  const handleCancel = () => {
    if (companyType === "BOTH" || companyType === "SELLER") {
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
    return navigate(IMPORTER_PRIVATE_PATH.HOME, { replace: true });
  };

  const handleNext = () => {
    if (clientSelection === "new") {
      onNextStep(true, codeArr.join(""));
    } else {
      setDialogState(DialogState.EXIST_CLIENT);
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;

    if (dialogState === DialogState.EXIST_CLIENT) {
      return (
        <RegisteredAccountDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          onNextStep={(clientId) =>
            onNextStep(false, codeArr.join(""), clientId)
          }
        />
      );
    }
  };

  return (
    <StyledMain>
      <Typo as="h1" typoType="d3" className="enter-account-code-heading">
        {t("linkToAccount:linkToAccount")}
      </Typo>

      <StyledBasicCard
        title={
          <BasicCardTitleContainer>
            {t("linkToAccount:registration.registration")}
          </BasicCardTitleContainer>
        }
      >
        <ContentSection>
          <Icon iconSrc={FrameSvg} iconSize={80}></Icon>
          <TypoContainer>
            <Typo as="span" typoType="d6" color="gray1">
              {t("linkToAccount:registration.info")}
            </Typo>
            <Description>
              <p>{t("linkToAccount:registration.descriptionTop")}</p>
            </Description>
          </TypoContainer>

          <FlexColumn className="gap-8">
            <TextContainer>
              <Typo as="p" typoType="b7m" color="gray2">
                {t("linkToAccount:registration.corpAccountCode")}
              </Typo>
              <RequireMark>*</RequireMark>
            </TextContainer>
            <EnterCodeContainer>
              <EnterCodeBox>
                {codeArr.map((_, idx) => {
                  return (
                    <VerificationInputDiv
                      key={idx.toString()}
                      ref={(node) => node && ref.current.push(node)}
                      char={codeArr[idx]}
                      style={idx === 3 ? { marginRight: "20px" } : undefined}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (key === "ArrowLeft") {
                          if (idx - 1 !== -1) {
                            ref.current[idx - 1].focus();
                          }
                        }
                        if (key === "ArrowRight") {
                          if (idx + 1 !== codeArr.length) {
                            ref.current[idx + 1].focus();
                          }
                        }
                        if ((e.ctrlKey || e.metaKey) && e.key === "v") {
                          navigator.clipboard.readText().then((res) => {
                            const overridden = Array.from(
                              { length: 8 },
                              () => ""
                            );
                            const pasteText = res
                              .split("")
                              .filter((letter) => letter !== " ")
                              .slice(0, 8)
                              .concat(overridden)
                              .slice(0, 8);
                            setCodeArr(pasteText);
                          });
                        }
                      }}
                      onCharChange={(char) => {
                        if (!!codeArr[idx]) {
                          if (idx + 1 !== codeArr.length) {
                            ref.current[idx + 1].focus();
                          }
                          return;
                        }
                        const copied = codeArr.slice();
                        copied.splice(idx, 1, char ?? "");
                        setCodeArr(copied);
                        if (idx + 1 !== codeArr.length) {
                          ref.current[idx + 1].focus();
                        }
                      }}
                      onBackSpaceKeyDown={() => {
                        const copied = codeArr.slice();
                        copied.splice(idx, 1, "");
                        setCodeArr(copied);
                        if (idx - 1 !== -1) {
                          ref.current[idx - 1].focus();
                        }
                      }}
                    />
                  );
                })}
              </EnterCodeBox>
            </EnterCodeContainer>
          </FlexColumn>

          <FlexColumn className="gap-8">
            <Typo typoType="b7m">
              {t("linkToAccount:registration.selectNewRegisteredClient")}{" "}
              <strong style={{ color: `${colorSet.red2}` }}>*</strong>
            </Typo>

            <GridCardRadioContainer>
              <GridCardRadio.Radio
                label={
                  <Typo
                    typoType="h9"
                    color={clientSelection === "new" ? "blue4" : "gray6"}
                  >
                    {t("linkToAccount:registration.newClient")}
                  </Typo>
                }
                value={"new"}
                gridSpan={1}
                checked={clientSelection === "new"}
                onChange={() => setClientSelection("new")}
              />
              <GridCardRadio.Radio
                label={
                  <Typo
                    typoType="h9"
                    color={clientSelection === "exist" ? "blue4" : "gray6"}
                  >
                    {t("linkToAccount:registration.registeredClient")}
                  </Typo>
                }
                value={"exist"}
                gridSpan={1}
                checked={clientSelection === "exist"}
                onChange={() => setClientSelection("exist")}
              />
            </GridCardRadioContainer>
          </FlexColumn>

          <StyledFooter>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={40}
              onClick={handleCancel}
            >
              {t("linkToAccount:registration.cancel")}
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={codeArr.filter((code) => code !== "").length !== 8}
            >
              {t("linkToAccount:registration.next")}
            </StyledButton>
          </StyledFooter>
        </ContentSection>
      </StyledBasicCard>

      {renderDialog()}
    </StyledMain>
  );
};

export default EnterAccountCode;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h1.enter-account-code-heading {
    padding: 24px 0;
  }
`;

const StyledBasicCard = styled(BasicCard)`
  width: 640px;
`;

const BasicCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 40px 0;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  &.gap-8 {
    gap: 8px;
  }
`;

const TypoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const Description = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;

  color: ${colorSet.gray2};
  ${typo.b7m}
`;

const TextContainer = styled.span`
  display: flex;
  gap: 2px;
`;

const RequireMark = styled.p`
  color: ${colorSet.systemRed2};
`;

const EnterCodeContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const EnterCodeBox = styled.div`
  display: flex;
  gap: 4px;

  :nth-child(4) {
    margin-right: 16px;
  }
`;

const GridCardRadioContainer = styled(GridCardRadio.Container)`
  width: 460px;
`;

const StyledFooter = styled.footer`
  display: flex;
  gap: 8px;
  width: 480px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  text-align: center;
`;
