import { useCallback, useEffect, useRef, useState } from "react";
import Table from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import useAlert from "@/src/hooks/useAlert";
import { styled } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import TaskSvg from "@/src/assets/icons/icon-total-task.svg";
import TaskContainerSvg from "@/src/assets/icons/icon-total-container.svg";
import ArrowRightSvg from "@/src/assets/icons/icon-arrow-right-bold.svg";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import {
  taskListApi,
  useLazyGetTaskListQuery,
} from "@/src/store/apis/tasks/taskList";
import { useNavigate, useParams } from "react-router-dom";
import { columnLoadingList } from "@/src/pages/exporter/Contract/columns/columnLoadingList";
import { bookingDetailApi } from "@/src/store/apis/bookings/bookingDetail";
import { TaskListViewDto } from "@/src/store/apis/tasks/taskList/interface";
import { isUndefined } from "@/src/utils/is";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";

const loadingEmptyArray: TaskListViewDto[] = [];

function TaskList() {
  const { t } = useTranslation();
  const params = useParams();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const [columnDefs] = useState<ColDef[]>(() => columnLoadingList(t));
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  // API
  const [getLoadingList] = useLazyGetTaskListQuery();
  const { isFetching, count, bookingWithTaskList } =
    taskListApi.endpoints.getTaskList.useQueryState(
      {
        bookingId: Number(params.id),
        page: pagination.page,
        pageSize: pagination.pageSize,
        isCancelTaskRequestExist: false,
      },
      {
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            isFetching,
            count: !isUnstable ? currentData.count : 0,
            bookingWithTaskList: !isUnstable
              ? currentData.rows
              : loadingEmptyArray,
          };
        },
      }
    );
  const {
    loadingRequestCount,
    loadingScheduledCount,
    workingCount,
    photoSubmittedCount,
    fieldWorkCompletedCount,
    loadingCompletedCount,
    totalLoadingCount,
    totalContainerCount,
  } = bookingDetailApi.endpoints.getBookingTaskSummaryInfo.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          loadingRequestCount: !isUnstable
            ? currentData.taskRequestCount ?? "-"
            : "-",
          loadingScheduledCount: !isUnstable
            ? currentData.taskScheduledCount ?? "-"
            : "-",
          workingCount: !isUnstable ? currentData.workingCount ?? "-" : "-",
          photoSubmittedCount: !isUnstable
            ? currentData.photoSubmittedCount ?? "-"
            : "-",
          fieldWorkCompletedCount: !isUnstable
            ? currentData.fieldWorkCompletedCount ?? "-"
            : "-",
          loadingCompletedCount: !isUnstable
            ? currentData.taskCompletedCount ?? "-"
            : "-",
          totalLoadingCount: !isUnstable
            ? currentData.totalTaskCount ?? "-"
            : "-",
          totalContainerCount: !isUnstable
            ? currentData.totalContainerCount ?? "-"
            : "-",
        };
      },
    }
  );

  const statusList = [
    {
      label: t("contract:exporter.detail.loadingList.loadingRequest"),
      value: loadingRequestCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.loadingScheduled"),
      value: loadingScheduledCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.working"),
      value: workingCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.photoSubmitted"),
      value: photoSubmittedCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.fieldWorkCompleted"),
      value: fieldWorkCompletedCount,
    },
    {
      label: t("contract:exporter.detail.loadingList.LoadingCompleted"),
      value: loadingCompletedCount,
    },
  ];

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "taskStatus",
        langKey: "contract:exporter.detail.loadingList.loadingStatus",
      },
      {
        columnKey: "workplaceName",
        langKey: "contract:exporter.detail.loadingList.workplaceName",
      },
      {
        columnKey: "workingDayAt",
        langKey: "contract:exporter.detail.loadingList.workingDayAt",
      },
      {
        columnKey: "numberOfTaskContainer",
        langKey:
          "contract:exporter.detail.loadingList.numberOfLoadingContainer",
      },
      {
        columnKey: "totalNetWeight",
        langKey: "contract:exporter.detail.loadingList.totalNetWeight",
      },
    ],
  });

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const taskListParams = {
        page,
        pageSize,
        bookingId: Number(params.id),
        isCancelTaskRequestExist: false,
      };

      try {
        await getLoadingList(taskListParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [alert, getLoadingList, pagination.page, pagination.pageSize, params.id]
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <SectionCard
      cardContentContainerStyle={
        isCardOpen ? { display: "block" } : { display: "none" }
      }
      cardTitle={t("contract:exporter.detail.loadingList.title")}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={isCardOpen}
          onClick={() => setIsCardOpen((prev) => !prev)}
        />
      }
    >
      {/* Total */}
      <TaskTotalInfoContainer>
        <TaskTotalItem>
          <TaskTotalIllust $imageUrl={TaskSvg} />
          <TaskDescription>
            <Typo color="gray6" typoType="b9m">
              {t("contract:exporter.detail.loadingList.totalLoading")}
            </Typo>
            <Typo color="blue2" typoType="h4">
              {totalLoadingCount} cases
            </Typo>
          </TaskDescription>
        </TaskTotalItem>

        <TaskTotalItem>
          <TaskTotalIllust $imageUrl={TaskContainerSvg} />
          <TaskDescription>
            <Typo color="gray6" typoType="b9m">
              {t("contract:exporter.detail.loadingList.totalContainer")}
            </Typo>
            <Typo color="blue2" typoType="h4">
              {totalContainerCount} ea
            </Typo>
          </TaskDescription>
        </TaskTotalItem>
      </TaskTotalInfoContainer>

      {/* Status */}
      <TaskStatusContainer>
        {statusList.map(({ label, value }, idx) => {
          return (
            <TaskStatus key={idx.toString()}>
              <StatusLabel>
                <Typo typoType="b9m" color="gray6">
                  {label}
                </Typo>
              </StatusLabel>
              <StatusValue>
                <Typo typoType="h4" color="blue2">
                  {value}
                </Typo>
              </StatusValue>
            </TaskStatus>
          );
        })}
      </TaskStatusContainer>

      {/* Table */}
      <Table
        ref={gridRef}
        rowData={bookingWithTaskList}
        columnDefs={columnDefs}
        isPaginationDataMaping
        totalPage={count}
        pageSize={pagination.pageSize}
        page={pagination.page}
        onRowClicked={({ data }) => {
          navigate(`${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${data.id}`);
        }}
        handlePaginationClick={handlePaginationClick}
        onGridReady={() => {
          setIsReady(true);
        }}
      />
    </SectionCard>
  );
}

export default TaskList;

const TaskTotalInfoContainer = styled.ul`
  display: flex;
  padding: 0 12px;
  gap: 24px;
`;

const TaskTotalItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const TaskTotalIllust = styled.div<{ $imageUrl: string }>`
  width: 85px;
  height: 85px;
  border: 1px solid ${colorSet.gray10};
  border-radius: 8px;
  background: ${({ $imageUrl }) => $imageUrl && `url(${$imageUrl})`} no-repeat;
  background-position: center;
`;

const TaskDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TaskStatusContainer = styled.ul`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: ${colorSet.gray11};
  margin: 16px 0;
  padding: 16px 0;
`;

const TaskStatus = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 0 8px;
  flex: 1;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: url(${ArrowRightSvg}) no-repeat;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`;
const StatusValue = styled.div`
  flex: 1;
`;
