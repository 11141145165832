import Dialog from "@/src/components/atom/Dialog";
import { GenerateSalesContractTermsDto } from "@/src/store/apis/corporate/company/interface";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import Typo from "@/src/components/atom/Typo";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface TermsAddDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  termsValueData: GenerateSalesContractTermsDto;
}

const TermsPreviewDialog = ({
  open,
  onOpenChange,
  termsValueData,
}: TermsAddDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t(
        "companyManagement:exporter.companyInfoDialog.terms.previewTitle"
      )}
      open={open}
      width={850}
      onOpenChange={onOpenChange}
    >
      <Title typoType="h5">{termsValueData.title}</Title>
      <BasicTextArea defaultValue={termsValueData.body} isReadOnly={true} />
    </Dialog>
  );
};

export default TermsPreviewDialog;

const Title = styled(Typo)`
  display: inline-block;
  margin-bottom: 16px;
`;
