import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import colorSet from "@/src/styles/color";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import CompletedIcon from "@/src/assets/icons/icon-completed.svg";
import Badge from "@/src/components/atom/Badge";
import { useNormalPlanSignupContext } from "../hooks";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { useAppSelector } from "@/src/store";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { useTranslation } from "react-i18next";

function NormalPlanSignupCompleted() {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const {
    planName,
    subscriptionStartAtFrom,
    subscriptionEndAtTo,
    isFreeTrial,
  } = useNormalPlanSignupContext();

  const goToHome = () => {
    navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
  };

  /**
   * @description
   * - nextPaymentDate: endDate + 무료기간 + 1일
   */
  const getSubscriptionDate = () => {
    const nextPayment = dayjs(subscriptionEndAtTo).add(1, "day");
    const nextYear = dayjs(nextPayment).get("year");
    const nextMonth = addZeroPrefix(dayjs(nextPayment).get("month") + 1);
    const nextDate = addZeroPrefix(dayjs(nextPayment).get("date"));

    return {
      startDate: dayjs(subscriptionStartAtFrom).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD
      ),
      endDate: dayjs(subscriptionEndAtTo).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD
      ),
      nextPaymentYear: nextYear,
      nextPaymentMonth: nextMonth,
      nextPaymentDay: nextDate,
    };
  };

  return (
    <StyledSectionCard>
      <FlexColumn gap={40}>
        {/* Information */}
        <PlanInfo>
          <FlexColumn alignItems="center">
            <Icon iconSrc={CompletedIcon} iconSize={60} />
          </FlexColumn>

          <FlexColumn gap={12} alignItems="center">
            <Typo typoType="d6">
              {t("normalPlanSignup:planSubscriptionCompleted")}
            </Typo>
            <Typo>
              {t("normalPlanSignup:planSubscriptionCompletedDescription")}
            </Typo>
          </FlexColumn>

          <StyledCallout backgroundColor="white">
            <FlexColumn gap={16}>
              <FlexColumn gap={4}>
                <Typo typoType="h4">{planName} Plan</Typo>

                <FlexRow gap={4}>
                  <Typo typoType="b7m">
                    {getSubscriptionDate()?.startDate} ~{" "}
                    {getSubscriptionDate()?.endDate}
                  </Typo>
                  {isFreeTrial && (
                    <Badge
                      badgeSize="S"
                      typoType="b11m"
                      badgeColor="systemBlue6"
                      color="systemBlue2"
                      text={t("normalPlanSignup:freeTrialDateBadge")}
                    />
                  )}
                </FlexRow>
              </FlexColumn>

              <Divider />

              <FlexRow gap={4}>
                <Icon iconSrc={IconCheck} iconSize={16} />
                <Typo>
                  {lang === "en" ? (
                    <Typo>
                      {t("normalPlanSignup:automaticPaymentWillCommencefrom")}{" "}
                      {dayjs(subscriptionEndAtTo)
                        .add(1, "day")
                        .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
                    </Typo>
                  ) : (
                    <Typo>
                      {getSubscriptionDate().nextPaymentYear}
                      {t("normalPlanSignup:year")}{" "}
                      {getSubscriptionDate().nextPaymentMonth}
                      {t("normalPlanSignup:month")}{" "}
                      {getSubscriptionDate().nextPaymentDay}
                      {t("normalPlanSignup:automaticPaymentDescription")}
                    </Typo>
                  )}
                </Typo>
              </FlexRow>
            </FlexColumn>
          </StyledCallout>
        </PlanInfo>

        {/* Button */}
        <StyledButton
          buttonColor="blue"
          buttonGrade="secondary"
          onClick={goToHome}
        >
          {t("normalPlanSignup:button.home")}
        </StyledButton>
      </FlexColumn>
    </StyledSectionCard>
  );
}

export default NormalPlanSignupCompleted;

const StyledSectionCard = styled(SectionCardWithoutHeader)`
  width: 640px;
  padding: 40px 48px;
`;

const FlexColumn = styled.div<{
  gap?: number;
  alignItems?: CSSProperties["alignItems"];
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
`;

const FlexRow = styled(FlexColumn)`
  flex-direction: row;
  align-items: center;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;

const StyledCallout = styled(CallOut)``;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;
