import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import dayjs from "dayjs";
import styled from "styled-components";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import Icon from "@/src/components/atom/Icon";
import { downloadFile } from "@/src/utils/downloadFile";
import { IconButton } from "@/src/components/atom/Button";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { StyledScroll } from "@/src/styles/scroll";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { useParams } from "react-router-dom";
import { bookingDetailApi } from "@/src/store/apis/bookings/bookingDetail";
import { isNull, isUndefined } from "@/src/utils/is";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { SimpleBookingMemoDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import { useTranslation } from "react-i18next";

const memoEmptyArray: SimpleBookingMemoDto[] = [];

function BookingInformation() {
  const { t } = useTranslation();
  const params = useParams();

  const {
    isError,
    placeOfReceipt,
    portOfDischarge,
    cfs,
    cargoCutOffAt,
    docCutOffAt,
    estimatedWeightAndUnit,
    bookingFileSimpleMedia,
    bookingRemark,
    simpleBookingMemos,
  } = bookingDetailApi.endpoints.getBookingDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          isError,
          placeOfReceipt: !isUnstable ? currentData.placeOfReceipt ?? "-" : "-",
          portOfDischarge: !isUnstable
            ? currentData.portOfDischarge ?? "-"
            : "-",
          cfs: !isUnstable ? currentData.cfs ?? "-" : "-",
          cargoCutOffAt:
            !isUnstable && currentData?.cargoCutOffAt
              ? dayjs(currentData.cargoCutOffAt).format(
                  dayjs(currentData.cargoCutOffAt).get("minute") === 30
                    ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                    : DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                )
              : "-",
          docCutOffAt:
            !isUnstable && currentData.docCutOffAt
              ? dayjs(currentData.docCutOffAt).format(
                  dayjs(currentData.docCutOffAt).get("minute") === 30
                    ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                    : DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                )
              : "-",
          estimatedWeightAndUnit:
            !isUnstable &&
            currentData?.estimatedWeight &&
            currentData?.estimatedWeightUnit
              ? `${Number(currentData.estimatedWeight).toLocaleString(
                  "ko-KR"
                )} ${currentData.estimatedWeightUnit}`
              : "-",
          bookingFileSimpleMedia: currentData?.bookingFileSimpleMedia,
          bookingRemark: !isUnstable ? currentData.bookingRemark ?? "-" : "-",
          simpleBookingMemos: !isUnstable
            ? currentData.simpleBookingMemos ?? memoEmptyArray
            : memoEmptyArray,
        };
      },
    }
  );

  return (
    <BookingInformationContainer>
      {/* Left */}
      <FlexColumn>
        <StyeldSectionCardFlexOne
          cardTitle={t("booking:detail.card.bookingInformation")}
        >
          <InformationFlexColumn>
            {/* Location */}
            <InformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Location
              </Typo>
              <section>
                <SectionCardRow
                  label={"Place of Receipt"}
                  value={
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {placeOfReceipt}
                    </BreakWordTypo>
                  }
                />
                <SectionCardRow
                  label={"Port of Discharge"}
                  value={
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {portOfDischarge}
                    </BreakWordTypo>
                  }
                />
                <SectionCardRow
                  label={"CFS / CY Code"}
                  value={
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {cfs}
                    </BreakWordTypo>
                  }
                />
              </section>
            </InformationRow>

            {/* Date */}
            <InformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Date
              </Typo>
              <section>
                <SectionCardRow label={"Cargo Cut-off"} value={cargoCutOffAt} />
                <SectionCardRow
                  label={
                    <Typo typoType="b7m">
                      Doc Cut-Off
                      <br />
                      (VGM Cut Off)
                    </Typo>
                  }
                  value={docCutOffAt}
                />
                <SectionCardRow
                  label={"Estimated Weight"}
                  value={
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {estimatedWeightAndUnit}
                    </BreakWordTypo>
                  }
                />
              </section>
            </InformationRow>

            {/* Attachment File */}
            <InformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Attachment File
              </Typo>
              <section>
                <SectionCardRow
                  label={"Booking File"}
                  value={
                    isError ? (
                      "-"
                    ) : (
                      <FileInfoContainer>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(bookingFileSimpleMedia)}
                          >
                            {isNull(bookingFileSimpleMedia) ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    bookingFileSimpleMedia?.mediaUrl ?? "",
                                    bookingFileSimpleMedia?.originalFileName ??
                                      ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(bookingFileSimpleMedia)}
                          >
                            {isNull(bookingFileSimpleMedia) ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={bookingFileSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    )
                  }
                />
              </section>
            </InformationRow>
          </InformationFlexColumn>
        </StyeldSectionCardFlexOne>
      </FlexColumn>

      {/* Right */}
      <FlexColumn>
        <StyeldSectionCardFlexOne cardTitle="Booking Remark">
          <BookingRemarkReadOnlyText typoType="b7r">
            {bookingRemark}
          </BookingRemarkReadOnlyText>
        </StyeldSectionCardFlexOne>
        <StyeldSectionCardFlexThree cardTitle={t("booking:detail.card.memo")}>
          <MemoContainer>
            <MemoDescription>
              <StyeldIcon iconSrc={InfoSvg} />
              <Typo typoType="b9r" color="gray6">
                {t("booking:detail.description.memo")}
              </Typo>
            </MemoDescription>

            <MemoContent>
              {simpleBookingMemos.length >= 1 ? (
                simpleBookingMemos.map((item, idx) => {
                  return (
                    <MemoItem key={idx.toString()}>
                      <MemoLabelContainer>
                        <Typo color="gray3" typoType="h9">
                          {item.writerName}
                        </Typo>
                        <Typo color="gray7" typoType="b9r">
                          {`(${dayjs(item.wroteAt).format(
                            DATE_FORMAT_STRINGS.YY_MM_DD
                          )}, ${dayjs(item.wroteAt).format(
                            DATE_FORMAT_STRINGS.HH_mm
                          )})`}
                        </Typo>
                      </MemoLabelContainer>

                      <ReadOnlyText typoType="b7r" color="gray6">
                        {item.memo}
                      </ReadOnlyText>
                    </MemoItem>
                  );
                })
              ) : (
                <MemoNoDataContainer>
                  {renderNoRowsComponent()}
                </MemoNoDataContainer>
              )}
            </MemoContent>
          </MemoContainer>
        </StyeldSectionCardFlexThree>
      </FlexColumn>
    </BookingInformationContainer>
  );
}

export default BookingInformation;

const BookingInformationContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const StyeldSectionCardFlexOne = styled(SectionCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyeldSectionCardFlexThree = styled(SectionCard)`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const InformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }
  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const MemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MemoDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyeldIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 120px;

  ${StyledScroll}
`;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BookingRemarkReadOnlyText = styled(ReadOnlyText)`
  height: 120px;
  overflow: auto;

  ${StyledScroll}
`;
