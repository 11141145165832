import { forwardRef } from "react";
import styled from "styled-components";
import { Radio as AntdRadio, RadioProps as AntdRadioProps } from "antd";
import { ColorType } from "@/src/styles/color";
import Typo from "../Typo";
import { FontSizeType } from "@/src/styles/typography";
import RadioUnCheckedSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckedSvg from "@/src/assets/icons/icon-radio-checked.svg";
import RadioDisabledCheckedSvg from "@/src/assets/icons/icon-radio-disabled-checked.svg";
import RadioDisabledUnCheckedSvg from "@/src/assets/icons/icon-radio-disalbed-unchecked.svg";

interface RadioProps extends AntdRadioProps {
  label?: string;
  typoType?: FontSizeType;
  color?: ColorType;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ label, typoType = "b9r", color, children, ...props }, ref) => {
    return (
      <StyledRadio {...props} ref={ref}>
        <Typo color={color} typoType={typoType}>
          {label || children}
        </Typo>
      </StyledRadio>
    );
  }
);

export default Radio;

const StyledRadio = styled(AntdRadio)`
  .ant-radio.ant-radio-checked .ant-radio-inner {
    background: url(${RadioCheckedSvg}) no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    border: none;
  }

  .ant-radio .ant-radio-inner {
    background: url(${RadioUnCheckedSvg}) no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    border: none;
  }

  // Disabled
  .ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    background: url(${RadioDisabledCheckedSvg}) no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    border: none;
  }

  .ant-radio-disabled .ant-radio-inner {
    background: url(${RadioDisabledUnCheckedSvg}) no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    border: none;
  }

  .ant-radio-inner::after {
    display: none;
  }
`;
