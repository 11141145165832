import Tooltip from "@/src/components/atom/Tooltip";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import { salesApi } from "@/src/store/apis/sales";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { calculateTotalNetWeight } from "../../utils/getTotalNetWeight";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { useTranslation } from "react-i18next";

function SalesDetailStatistics() {
  const { t } = useTranslation();
  const params = useParams();
  const percentageRef = useRef<HTMLDivElement>(null);

  const [isInnerWindow, setIsInnerWindow] = useState(false);

  const { quantityWithUnit, quantityUnit, warehousings, quantity } =
    salesApi.endpoints.getSale.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;
          const isStable = !isUnstable;

          return {
            quantityWithUnit:
              isStable && currentData.quantity && currentData.quantityUnit
                ? `${Number(currentData.quantity).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${currentData.quantityUnit}`
                : "-",
            warehousings: isStable ? currentData.warehousings : [],
            quantity: isStable ? currentData.quantity : "-",
            quantityUnit: isStable ? currentData.quantityUnit : "-",
          };
        },
      }
    );

  const getPercent = (par: number, total: number) => {
    const value = (par / total) * 100;

    if (isNaN(value)) {
      return 0;
    }

    return parseFloat(value.toFixed(2));
  };

  const isContainerLink = warehousings.length >= 1;
  const orderBalance = isContainerLink
    ? parseFloat(
        (Number(quantity) - calculateTotalNetWeight(warehousings)).toFixed(2)
      ).toLocaleString("ko-KR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInnerWindow(entry.isIntersecting);
        }
      },
      { threshold: 0.4 }
    );

    if (percentageRef.current) {
      observer.observe(percentageRef.current);
    }
  }, []);

  return (
    <SectionCardWithoutHeader>
      <SalesStatusContainer>
        {/* Status */}
        <SalesStatusTextGrid>
          <SalesStatusText>
            <Typo typoType="b9m" color="gray6">
              {t("sales:detail.orderQty")}
            </Typo>
            <BreakWordTypo typoType="h4" color="blue2">
              {quantityWithUnit}
            </BreakWordTypo>
          </SalesStatusText>

          <SalesStatusText>
            <Typo typoType="b9m" color="gray6">
              {t("sales:detail.receivedQty")}
            </Typo>
            <BreakWordTypo typoType="h4" color="blue2">
              {isContainerLink
                ? `${calculateTotalNetWeight(warehousings).toLocaleString(
                    "ko-KR",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )} ${quantityUnit}`
                : `0 ${quantityUnit}`}
            </BreakWordTypo>
          </SalesStatusText>

          <SalesStatusText>
            <Typo typoType="b9m" color="gray6">
              {t("sales:detail.orderRemain")}
            </Typo>
            <BreakWordTypo typoType="h4" color="blue2">
              {isContainerLink
                ? `${orderBalance} ${quantityUnit}`
                : `0 ${quantityUnit}`}
            </BreakWordTypo>
          </SalesStatusText>
        </SalesStatusTextGrid>

        {/* Status Percentage*/}
        <StatusPercentageContainer>
          {/* Percentage Tooltip */}
          <StyledTooltip
            tooltipColor="green2"
            placement="leftTop"
            isInnerWindow={isInnerWindow}
            left={
              getPercent(
                calculateTotalNetWeight(warehousings),
                Number(quantity)
              ) >= 100
                ? 100
                : getPercent(
                    calculateTotalNetWeight(warehousings),
                    Number(quantity)
                  )
            }
            triggerComponent={
              <PercentageContainer>
                <PercentageColor
                  ref={percentageRef}
                  percent={
                    getPercent(
                      calculateTotalNetWeight(warehousings),
                      Number(quantity)
                    ) >= 100
                      ? 100
                      : getPercent(
                          calculateTotalNetWeight(warehousings),
                          Number(quantity)
                        )
                  }
                  isInnerWindow={isInnerWindow}
                />
                <PercentageOverlay />
              </PercentageContainer>
            }
          >
            <Typo color="white" typoType="b10m">
              {getPercent(
                calculateTotalNetWeight(warehousings),
                Number(quantity)
              )}
              %
            </Typo>
          </StyledTooltip>

          {/* 0 ~ 100% */}
          <TopPercentage>
            <Typo typoType="b10m" color="gray7">
              0%
            </Typo>
            <Typo typoType="b10m" color="gray7">
              100%
            </Typo>
          </TopPercentage>

          {/* 선적 비율 */}
          <PercentageTypo>
            <Typo typoType="b9m" color="gray6">
              {t("sales:detail.receiptPercentage")}
            </Typo>
            <Typo typoType="h4" color="green1">
              {getPercent(
                calculateTotalNetWeight(warehousings),
                Number(quantity)
              )}
              %
            </Typo>
          </PercentageTypo>
        </StatusPercentageContainer>
      </SalesStatusContainer>
    </SectionCardWithoutHeader>
  );
}

export default SalesDetailStatistics;

const SalesStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  gap: 32px;
`;

const SalesStatusTextGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 176px);
  align-items: start;
`;

const SalesStatusText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 16px 8px;

  &:not(:last-child) {
    border-right: 1px solid ${colorSet.gray10};
  }
`;

const StatusPercentageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-right: 8px;
`;

const TopPercentage = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PercentageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  margin: 24px 0 8px 0;
`;

const PercentageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.gray9};
`;

const percentageAnimation = (number: number) => keyframes`
    0% {
        width: 0;
    }
    100% {
        width: ${Math.round(number)}%
    }
`;

const percentageTooltipAnimation = (number: number) => keyframes`
    0% {
        left: 0;
    }
    100% {
        left: ${Math.round(number)}%;
    }
`;

const PercentageColor = styled.div<{ percent: number; isInnerWindow: boolean }>`
  position: absolute;
  height: 100%;
  border-radius: 20px;
  background: ${colorSet.green2};
  z-index: 1;
  animation: ${({ percent, isInnerWindow }) =>
    isInnerWindow ? percentageAnimation(percent) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;

const PercentageTypo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTooltip = styled(Tooltip)<{ left: number; isInnerWindow: boolean }>`
  bottom: 30px;
  animation: ${({ left, isInnerWindow }) =>
    isInnerWindow ? percentageTooltipAnimation(left) : null};
  animation-duration: 500ms;
  animation-fill-mode: both;
`;
