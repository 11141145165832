import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import getTableIndex from "@/src/utils/getTableIndex";
import { isNull } from "@/src/utils/is";
import { CELL_TYPE_VIEW_ACTION } from "@/src/utils/row-data-util";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import UpSvg from "@/src/assets/icons/icon-arrow-up.svg";
import DownSvg from "@/src/assets/icons/icon-arrow-down.svg";
import styled from "styled-components";

export const columnContainerTab = (): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },

  {
    headerName: "",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "inOutType",
    width: 90,
    minWidth: 80,
    sortable: false,
    // resizable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return isNull(params.data.inOutType) ? (
        "-"
      ) : (
        <Flex>
          <StyledIcon
            iconSrc={params.data.inOutType === "IN" ? DownSvg : UpSvg}
            iconSize={16}
          />
          <Typo
            typoType="b9r"
            color={params.data.inOutType === "IN" ? "green1" : "red2"}
          >
            {params.data.inOutType}
          </Typo>
        </Flex>
      );
    },
  },

  {
    headerName: "Item Code",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Container No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "containerNo",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.containerNo ? (
        <TableBlueText typoType="b9r">{params.data.containerNo}</TableBlueText>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Seal No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sealNo",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sealNo ? (
        <Typo typoType="b9r">{params.data.sealNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Unit Q’ty",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitQuantity",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitQuantity ? (
        <Typo typoType="b9r">{params.data.unitQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Gross Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "grossWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.grossWeight ? (
        <Typo typoType="b9r">{params.data.grossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Net Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "netWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.netWeight ? (
        <Typo typoType="b9r">{params.data.netWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerName: "Photo",
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "photo",
    flex: 1,
    width: 110,
    sortable: false,
    lockPosition: "right",
    pinned: "right",
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
];

export const columnCombinedSplitedHistory = (): ColDef[] => [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return <Typo typoType="b9r">{(params.node?.rowIndex || 0) + 1}</Typo>;
    },
    sortable: false,
  },

  {
    headerName: "",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "inOutType",
    width: 90,
    minWidth: 80,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return isNull(params.data.inOutType) ? (
        "-"
      ) : (
        <Flex>
          <StyledIcon
            iconSrc={params.data.inOutType === "IN" ? DownSvg : UpSvg}
            iconSize={16}
          />
          <Typo
            typoType="b9r"
            color={params.data.inOutType === "IN" ? "green1" : "red2"}
          >
            {params.data.inOutType}
          </Typo>
        </Flex>
      );
    },
  },

  {
    headerName: "Container No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "containerNo",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.containerNo ? params.data.containerNo : "-";
    },
  },

  {
    headerName: "Seal No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sealNo",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sealNo ? (
        <Typo typoType="b9r">{params.data.sealNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Unit Q’ty",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitQuantity",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitQuantity ? (
        <Typo typoType="b9r">{params.data.unitQuantity}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Gross Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "grossWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.grossWeight ? (
        <Typo typoType="b9r">{params.data.grossWeight}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Net Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "netWeight",
    flex: 1,
    minWidth: 172,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.netWeight ? (
        <Typo typoType="b9r">{params.data.netWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerName: "Photo",
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "photo",
    flex: 1,
    width: 110,
    sortable: false,
    lockPosition: "right",
    pinned: "right",
    cellRenderer: CELL_TYPE_VIEW_ACTION,
  },
];

export const selectContainerColumn = (): ColDef[] => [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex ? params.node?.rowIndex + 1 : 1}
        </Typo>
      );
    },
    sortable: false,
  },

  {
    headerName: "Item Code",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Container No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "containerNo",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.containerNo ? (
        <Typo typoType="b9r">{params.data.containerNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Seal No.",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "sealNo",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.sealNo ? (
        <Typo typoType="b9r">{params.data.sealNo}</Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Unit Q’ty",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "unitQuantity",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitQuantity ? (
        <Typo typoType="b9r">
          {params.data.unitQuantity}{" "}
          {params.data.quantityUnit && params.data.quantityUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Gross Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "grossWeight",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.grossWeight ? (
        <Typo typoType="b9r">
          {params.data.grossWeight}{" "}
          {params.data.quantityUnit && params.data.quantityUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },

  {
    headerName: "Net Weight",
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "netWeight",
    flex: 1,
    minWidth: 137,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.netWeight ? (
        <Typo typoType="b9r">
          {params.data.netWeight}{" "}
          {params.data.quantityUnit && params.data.quantityUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
];

const StyledIcon = styled(Icon)``;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${StyledIcon} {
    flex-shrink: 0;
  }
`;
