import { css } from "styled-components";
import colorSet from "./color";

export const StyledScroll = css`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.white};
    border-radius: 12px;
    margin: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colorSet.white};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
  }
`;

export const StyledHorizontalScroll = css`
  &::-webkit-scrollbar {
    width: 1px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${colorSet.gray10};
    border: 1px solid ${colorSet.white};
    border-radius: 12px;
    margin: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colorSet.white};
    border: 1px solid ${colorSet.gray8};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
  }
`;
