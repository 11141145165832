import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export interface webUtilState {
  loading: boolean;
  contentLoading: boolean;
  isOkWithPopup: boolean;
  isNotificationOpen: boolean;
  isLinkageRequestAlertDialogOpen: boolean;
}

const initialState: webUtilState = {
  loading: false,
  contentLoading: false,
  isOkWithPopup: true,
  isNotificationOpen: false,
  isLinkageRequestAlertDialogOpen: false,
};

export const webUtil = createSlice({
  name: "webUtil",
  initialState,
  reducers: {
    onLoading: (state) => {
      state.loading = true;
    },
    offLoading: (state) => {
      state.loading = false;
    },
    onContentLoading: (state) => {
      state.contentLoading = true;
    },
    offContentLoading: (state) => {
      state.contentLoading = false;
    },
    closePopup: (state) => {
      state.isOkWithPopup = false;
    },
    openPopup: (state) => {
      state.isOkWithPopup = true;
    },
    openNotification: (state) => {
      state.isNotificationOpen = true;
    },
    closeNotification: (state) => {
      state.isNotificationOpen = false;
    },
    openLinkageRequestAlertDialog: (state) => {
      state.isLinkageRequestAlertDialogOpen = true;
    },
    closeLinkageRequestAlertDialog: (state) => {
      state.isLinkageRequestAlertDialogOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export default webUtil.reducer;
export const {
  onContentLoading,
  offContentLoading,
  closePopup,
  openPopup,
  openNotification,
  closeNotification,
  openLinkageRequestAlertDialog,
  closeLinkageRequestAlertDialog,
} = webUtil.actions;
