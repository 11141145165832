import { useNavigate } from "react-router-dom";
import { ClientType, ExporterUserType } from "../store/apis/auth/interface";
import { useAppSelector } from "../store";
import PUBLIC_PATH from "../routes/public/path";
import IMPORTER_PRIVATE_PATH from "../routes/importer/path";
import EXPORTER_PRIVATE_PATH from "../routes/exporter/path";

interface UseCompanyRedirectProps {
  companyType?: ClientType;
  userType?: ExporterUserType;
  isSubscription?: boolean;
}

/**
 * @description
 * 해당 페이지 진입시 회사타입, (일반 매니저, 중간관리자) 따라 해당 기업의 Home으로 Redirect를 하기위한 Hook
 *
 * - token이 없을시 로그인 페이지
 * - 중간관리자, 일반 매니저 진입불가
 * - 기업 유형이 (수입자): 수입자 홈화면 이동
 * - 기업 유형이 (수출자): 수출자 홈화면 이동
 * - 기업 유형 (Both): 중간관리자, 일반매니저는 기업이 구독이 되어있으면 수출자 홈 / 안 되어있으면 수입자 홈
 */
const useCompanyHomeRedirect = ({
  companyType,
  userType,
  isSubscription,
}: UseCompanyRedirectProps) => {
  const token = useAppSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const isCorporateManager = userType === "CORPORATE_MANAGER";

  const buyerTypeRedirect = () => {
    // 중간관리자, 일반매니저
    if (!isCorporateManager) {
      return navigate(IMPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
  };

  const sellerTypeRedirect = () => {
    // 중간관리자, 일반매니저
    if (!isCorporateManager) {
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
  };

  const bothTypeRedirect = () => {
    // 중간관리자, 일반매니저
    if (!isCorporateManager) {
      // 구독 X
      if (!isSubscription) {
        return navigate(IMPORTER_PRIVATE_PATH.HOME, { replace: true });
      }

      // 구독 O
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
  };

  const redirectNonCorporate = () => {
    if (!token) {
      return navigate(PUBLIC_PATH.LOGIN, { replace: true });
    }

    if (companyType === "BUYER") {
      return buyerTypeRedirect();
    }

    if (companyType === "SELLER") {
      return sellerTypeRedirect();
    }

    if (companyType === "BOTH") {
      return bothTypeRedirect();
    }
  };

  return {
    func: {
      redirectNonCorporate,
    },
  };
};

export default useCompanyHomeRedirect;
