import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { ColDef } from "ag-grid-community";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useAppSelector } from "@/src/store";
import typo from "@/src/styles/typography";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import {
  useCreateBuyerEmployeesMutation,
  useGetBuyerContractExporterItemQuery,
  useGetBuyerQuery,
  useLazyGetBuyerEmployeesQuery,
} from "@/src/store/apis/client/buyer";
import Badge from "@/src/components/atom/Badge";
import Table from "@/src/components/atom/Table";
import { buyerContactPersonListColumn } from "./columns/column";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import { isNull, isUndefined } from "@/src/utils/is";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import BuyerEmployeeAddDialog from "./dialog/BuyerEmployeeAddDialog";
import {
  BuyerEmployeeDto,
  GenerateBuyerEmployeeDto,
} from "@/src/store/apis/client/buyer/interface";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import EditSvg from "@/src/assets/icons/icon-edit-white.svg";
import ChainLinkSvg from "@/src/assets/icons/icon-chain-link.svg";
import CheckCircleBlackSvg from "@/src/assets/icons/icon-check-circle-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import LinkStatusBadge from "./components/LinkStatusBadge";
import LinkCodeInDetailPageDialog from "./components/LinkCodeInDetailPageDialog";
import LinkApproveDialog from "./components/LinkApproveDialog";
import DocumentBlue2 from "@/src/assets/icons/icon-document-blue2.svg";
import DocumentCheck from "@/src/assets/icons/icon-document-check.svg";
import Money from "@/src/assets/icons/icon-money.svg";
import InfoGray6 from "@/src/assets/icons/icon-info-gray6.svg";
import { useGetContractCountAndCumulativeAmountsByBuyerIdQuery } from "@/src/store/apis/dashboard";
import { useTranslation } from "react-i18next";

enum DialogState {
  NULL,
  ADD,
  CLIENT_LINK_CODE,
  APPROVE_OR_REJECT_LINK_CODE,
}

const EMPLOYEE_EMPTY_ARRAY: BuyerEmployeeDto[] = [];

const ExporterClientManagementDetailPage = () => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const userType = useAppSelector((state) => state.auth.user?.exporterUserType);

  const alert = useAlert();
  const pageParams = useParams<{ id: string }>();
  const navigator = useNavigate();
  const agGridRef = useRef<AgGridReact>(null);

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [columnDefs] = useState<ColDef[]>(buyerContactPersonListColumn);

  const { processingCount, totalCount, descendingCumulativeAmounts } =
    useGetContractCountAndCumulativeAmountsByBuyerIdQuery(
      {
        buyerId: Number(pageParams.id),
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, currentData, isError }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;

          return {
            isFetching,
            processingCount: !isUnstable ? currentData.processingCount : 0,
            totalCount: !isUnstable ? currentData.totalCount : 0,
            descendingCumulativeAmounts: !isUnstable
              ? currentData.descendingCumulativeAmounts
              : [],
          };
        },
      }
    );

  const {
    isFetching,
    isError,
    clientId,
    buyerName,
    buyerNameCode,
    country,
    businessRegistrationSimpleMedia,
    businessNumber,
    mainCategoryCodeItemNames,
    address,
    tel,
    fax,
    isActivated,
    countryValue,
    linkedStatus,
    defaultClientCode,
    defaultClientType,
    isReceivedLinkRequest,
  } = useGetBuyerQuery(
    {
      id: Number(pageParams.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isFetching, currentData, isError, data }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          isFetching,
          isError,
          clientId: !isUnstable ? currentData.id : 0,
          buyerName: !isUnstable ? currentData.buyerName : "-",
          buyerNameCode: !isUnstable ? currentData.buyerNameCode : "-",
          country: !isUnstable ? currentData.country : "-",
          businessRegistrationSimpleMedia:
            !isUnstable && !isNull(currentData.businessRegistrationSimpleMedia)
              ? currentData.businessRegistrationSimpleMedia
              : null,
          businessNumber:
            !isUnstable && !isNull(currentData.businessNumber)
              ? currentData.businessNumber
              : "-",
          address: !isUnstable ? currentData.address : "-",
          mainCategoryCodeItemNames: !isUnstable
            ? currentData.mainCategoryCodeItemNames
            : [],
          tel:
            !isUnstable &&
            !isNull(currentData.fullTel) &&
            !isNull(currentData.buyerQueryResultDto.tel) &&
            !isNull(currentData.buyerQueryResultDto.telPrefix)
              ? currentData.fullTel
              : "-",
          fax:
            !isUnstable &&
            !isNull(currentData.fullFax) &&
            !isNull(currentData.buyerQueryResultDto.fax) &&
            !isNull(currentData.buyerQueryResultDto.faxPrefix)
              ? currentData.fullFax
              : "-",
          isActivated: !isUnstable ? currentData.isActivated : false,
          countryValue: !isUnstable
            ? currentData.buyerQueryResultDto.country.value1
            : undefined,
          linkedStatus: !isUnstable
            ? currentData.buyerQueryResultDto.linkedStatus
            : "PENDING",

          defaultClientCode: !isUnstable
            ? currentData.buyerQueryResultDto.companyLinkageHistory?.sender
                ?.companyName ?? ""
            : "",
          defaultClientType: !isUnstable
            ? currentData.buyerQueryResultDto.companyLinkageHistory?.sender
                ?.companyType ?? "BOTH"
            : "BOTH",
          isReceivedLinkRequest: !isUnstable
            ? currentData.buyerQueryResultDto.linkedStatus === "RECEIVED"
            : false,
        };
      },
    }
  );

  const { currentData } = useGetBuyerContractExporterItemQuery(
    {
      id: Number(pageParams.id),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [getEmployees, { isEmployeeFetching, employeeList, employeeCount }] =
    useLazyGetBuyerEmployeesQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isUndefined(currentData);
        return {
          isEmployeeFetching: isFetching,
          employeeList: !isUnstable ? currentData.rows : EMPLOYEE_EMPTY_ARRAY,
          employeeCount: !isUnstable ? currentData.count : 0,
        };
      },
    });

  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: !isErrorAndUndefined ? currentData ?? [] : [],
          };
        },
      }
    );
  const [createEmployee] = useCreateBuyerEmployeesMutation();

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:exporter.common.name",
      },
      {
        columnKey: "email",
        langKey: "clientManagement:exporter.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
      {
        columnKey: "buyerEmployeeRemark",
        langKey: "clientManagement:exporter.common.remark",
      },
      {
        columnKey: "isActivated",
        langKey: "clientManagement:exporter.common.use",
      },
    ],
  });

  const fetchEmployees = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    try {
      await getEmployees({
        id: Number(pageParams.id),
        page,
        pageSize,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const basicInformationCardLeftSideItemList = [
    {
      label: t("clientManagement:exporter.common.clientName"),
      value: buyerName,
    },
    {
      label: t("clientManagement:exporter.common.clientCode"),
      value: buyerNameCode,
    },
    {
      label: t("clientManagement:exporter.common.country"),
      value: country,
    },
    {
      label: t("clientManagement:exporter.buyerDetail.categoryInCharge"),
      value: !isMainCategoryFetching
        ? mainCategoryCodeItemNames
            .map(
              (main) =>
                mainCategory.find(({ codeItemName }) => codeItemName === main)
                  ?.codeItemNameEn
            )
            .join(", ")
        : "-",
    },
    {
      label: t("clientManagement:exporter.common.businessNumber"),
      value: businessNumber,
    },
    {
      label: t("clientManagement:exporter.common.businessRegistration"),
      value: businessRegistrationSimpleMedia?.originalFileName ? (
        <RowValueContainer>
          {businessRegistrationSimpleMedia.originalFileName}{" "}
          <StyledIconButton
            buttonGrade="secondary"
            buttonSize={24}
            buttonColor="blue"
            onClick={() => {
              if (businessRegistrationSimpleMedia) {
                window.open(businessRegistrationSimpleMedia.mediaUrl);
              }
            }}
          >
            <Icon iconSrc={PreviewSvg} iconSize={16} />
          </StyledIconButton>
        </RowValueContainer>
      ) : (
        "-"
      ),
    },
  ];

  const basicInformationCardRightSideItemList = [
    {
      label: t("signupCorp:content.address"),
      value: address,
    },
    {
      label: t("clientManagement:exporter.common.contact"),
      value: tel,
    },
    {
      label: t("clientManagement:exporter.common.fax"),
      value: fax,
    },
    {
      label: t("clientManagement:exporter.common.use"),
      value: isActivated ? (
        <Badge
          text="YES"
          badgeSize="S"
          badgeColor="systemLime5"
          color="systemLime1"
        />
      ) : (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      ),
    },
  ];

  const handleSubmit = async (
    values: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    }
  ) => {
    try {
      setDialogState(DialogState.NULL);
      await createEmployee({
        id: Number(pageParams.id),
        name: values.name,
        email: values.email,
        isActivated: values.isActivated === "Y",
        personalContactPrefix: values.personalContactPrefix || undefined,
        personalContact: values.personalContact || undefined,
        officeContactPrefix: values.officeContactPrefix || undefined,
        officeContact: values.officeContact || undefined,
        buyerEmployeeRemark: values.buyerEmployeeRemark || undefined,
      }).unwrap();
      await fetchEmployees({
        page: pagination.page,
        pageSize: pagination.pageSize,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
    }
  };

  useEffect(() => {
    fetchEmployees({ page: 1, pageSize: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agGridRef.current && isEmployeeFetching) {
      agGridRef.current.api.showLoadingOverlay();
    }
  }, [isEmployeeFetching]);

  useEffect(() => {
    if (userType === "CORPORATE_MANAGER" && isReceivedLinkRequest) {
      setDialogState(DialogState.APPROVE_OR_REJECT_LINK_CODE);
    }
  }, [isReceivedLinkRequest, userType]);

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    const closeDialog = () => setDialogState(DialogState.NULL);

    if (dialogState === DialogState.ADD) {
      return (
        <BuyerEmployeeAddDialog
          defaultCountryCode={countryValue}
          onSave={handleSubmit}
          onClose={closeDialog}
        />
      );
    }

    if (dialogState === DialogState.CLIENT_LINK_CODE) {
      return (
        <LinkCodeInDetailPageDialog
          onClose={closeDialog}
          skipSelectClient
          clientId={clientId}
        />
      );
    }

    if (dialogState === DialogState.APPROVE_OR_REJECT_LINK_CODE) {
      return (
        <LinkApproveDialog
          onClose={closeDialog}
          clientId={Number(pageParams.id)}
          defaultClientCode={defaultClientCode}
          defaultClientType={defaultClientType}
        />
      );
    }
  };

  const renderFooter = () => {
    return (
      <BottomFixedContainer>
        <BackButton
          buttonGrade="tertiary"
          buttonColor="black"
          onClick={() => navigator(-1)}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
          {t("clientManagement:exporter.common.backToPrevious")}
        </BackButton>
      </BottomFixedContainer>
    );
  };

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:clientManagement"),
        t("sideNav:clientManagementDetail"),
      ]}
      customPageTitle={
        <PageTitleContainer>
          <h1>{buyerName}</h1>

          <FlexSpaceBetween className="pb-16">
            <LinkStatusBadge status={linkedStatus} lang={lang} badgeSize="L" />

            <Flex>
              {linkedStatus === "PENDING" &&
                !isFetching &&
                !isError &&
                userType === "CORPORATE_MANAGER" && (
                  <StyledButton
                    buttonColor="blue"
                    buttonGrade="secondary"
                    buttonSize={32}
                    onClick={() => setDialogState(DialogState.CLIENT_LINK_CODE)}
                  >
                    <Icon iconSrc={ChainLinkSvg} iconSize={16} />
                    {t(
                      "clientManagement:exporter.buyerDetail.buttons.companyIntegrationCode"
                    )}
                  </StyledButton>
                )}
              {linkedStatus === "RECEIVED" &&
                userType === "CORPORATE_MANAGER" && (
                  <StyledButton
                    buttonColor="blue"
                    buttonGrade="secondary"
                    buttonSize={32}
                    onClick={() =>
                      setDialogState(DialogState.APPROVE_OR_REJECT_LINK_CODE)
                    }
                  >
                    <Icon iconSrc={CheckCircleBlackSvg} iconSize={16} />
                    {t(
                      "clientManagement:exporter.buyerDetail.buttons.requestCompleted"
                    )}
                  </StyledButton>
                )}
              <StyledButton
                buttonSize={32}
                onClick={() =>
                  navigator(
                    `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_EDIT}/${pageParams.id}`
                  )
                }
              >
                <Icon iconSrc={EditSvg} iconSize={16} />
                {t("clientManagement:exporter.common.edit")}
              </StyledButton>
            </Flex>
          </FlexSpaceBetween>
        </PageTitleContainer>
      }
    >
      <SectionContainer>
        <SummaryContainer>
          <SummaryCard $gap={16}>
            <SummaryDiv>
              <Icon iconSrc={DocumentBlue2} iconSize={56} />
              <div>
                <Typo typoType="b6m">
                  {t("clientManagement:exporter.summary.processingContract")}
                </Typo>
                <Typo typoType="d4" color="blue4">
                  {processingCount}
                  {lang === "ko" ? "건" : ""}
                </Typo>
              </div>
            </SummaryDiv>
            <SummaryContractDivider />
            <SummaryDiv>
              <Icon iconSrc={DocumentCheck} iconSize={56} />
              <div>
                <Typo typoType="b6m">
                  {t("clientManagement:exporter.summary.totalContract")}
                </Typo>
                <Typo typoType="d4" color="blue4">
                  {totalCount}
                  {lang === "ko" ? "건" : ""}
                </Typo>
              </div>
            </SummaryDiv>
          </SummaryCard>
          <SummaryCard $gap={32}>
            <SummaryAmountDiv>
              <SummaryDiv $withoutPadding>
                <Icon iconSrc={Money} iconSize={56} />
                <Typo typoType="b6m">
                  {t("clientManagement:exporter.summary.cumulativeAmount")}
                </Typo>
              </SummaryDiv>
              <SummaryAmountNotice>
                <Icon iconSrc={InfoGray6} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("clientManagement:exporter.summary.summaryAmountNotice")}
                </Typo>
              </SummaryAmountNotice>
            </SummaryAmountDiv>
            {descendingCumulativeAmounts.length > 0 ? (
              <SummaryAmountList>
                {descendingCumulativeAmounts.slice(0, 3).map((amount) => (
                  <SummaryAmountListItem key={"amount-" + amount.unitPriceUnit}>
                    <Typo typoType="b6m" color="gray2">
                      {amount.unitPriceUnit}
                    </Typo>
                    <Typo typoType="h4" color="blue4">
                      {amount.amount}
                    </Typo>
                  </SummaryAmountListItem>
                ))}
              </SummaryAmountList>
            ) : (
              <SummaryAmountList>
                <SummaryAmountListItem>
                  <Typo typoType="b6m" color="gray2">
                    KRW
                  </Typo>
                  <Typo typoType="h4" color="blue4">
                    -
                  </Typo>
                </SummaryAmountListItem>
                <SummaryAmountListItem>
                  <Typo typoType="b6m" color="gray2">
                    USD
                  </Typo>
                  <Typo typoType="h4" color="blue4">
                    -
                  </Typo>
                </SummaryAmountListItem>
                <SummaryAmountListItem>
                  <Typo typoType="b6m" color="gray2">
                    EU
                  </Typo>
                  <Typo typoType="h4" color="blue4">
                    -
                  </Typo>
                </SummaryAmountListItem>
              </SummaryAmountList>
            )}
          </SummaryCard>
        </SummaryContainer>
        <SectionCard
          cardTitle={t("clientManagement:exporter.common.basicInformation")}
        >
          <StyledDivContainer>
            <HalfDiv>
              {basicInformationCardLeftSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
            <Divider />
            <HalfDiv>
              {basicInformationCardRightSideItemList.map(({ label, value }) => {
                return (
                  <SectionCardRow
                    label={label}
                    direction="horizontal"
                    value={value}
                  />
                );
              })}
            </HalfDiv>
          </StyledDivContainer>
        </SectionCard>

        <SectionCard
          cardTitle={t("clientManagement:exporter.buyerDetail.tradedItems")}
        >
          <Typo typoType="b7r" color="gray5">
            {currentData
              ? currentData.rows.map(({ item }) => item).join(", ")
              : "-"}
          </Typo>
        </SectionCard>

        <SectionCard
          cardTitle={t("clientManagement:exporter.common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t(
                  "clientManagement:exporter.buyerDetail.buttons.addContactPerson"
                )}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={employeeList}
            columnDefs={columnDefs}
            isPaginationDataMaping
            totalPage={employeeCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) => {
              fetchEmployees({ page, pageSize });
              setPagination({ page, pageSize });
            }}
          />
        </SectionCard>

        {renderFooter()}
        {renderDialog()}
      </SectionContainer>
    </ExporterMainLayout>
  );
};

export default ExporterClientManagementDetailPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  padding-bottom: 72px;
`;

const StyledDivContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Divider = styled.div`
  padding: 0.5px;
  background-color: ${colorSet.gray9};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledIconButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 4px;
`;

const RowValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    ${typo.h1}
    padding: 15px 0;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &.pb-16 {
    padding-bottom: 16px;
  }
`;

const BackButton = styled(Button)`
  ${typo.btn3m}
  display: inline-flex;
  align-self: flex-start;
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const SummaryCard = styled.div<{ $gap?: 16 | 32 }>`
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  gap: ${(props) => (props.$gap === 32 ? "32px" : "16px")};
`;

const SummaryDiv = styled.div<{ $withoutPadding?: boolean }>`
  flex: 1 1 0%;
  padding: ${(props) => (props.$withoutPadding ? "0" : "16px")};
  display: flex;
  align-items: center;
  gap: 12px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const SummaryContractDivider = styled.div`
  border-right-color: ${colorSet.gray9};
  border-right-width: 1px;
  border-right-style: solid;
  height: 100%;
`;

const SummaryAmountDiv = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  gap: 12px;
`;

const SummaryAmountNotice = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const SummaryAmountList = styled.ul`
  flex: 1 1 0%;
`;

const SummaryAmountListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:first) {
    margin-top: 4px;
  }

  *:first-child {
    width: 40px;
  }
`;
