import {
  AnnualFeeDiscountType,
  PaymentCurrencyUnitType,
  PaymentCycleType,
  PlanType,
} from "@/src/store/apis/subscription/interface";

type GetPaymentAmountType = {
  paymentCycle: PaymentCycleType;
  monthlyFee: string;
  annualFee: string;
  currencyUnit: PaymentCurrencyUnitType;
  exchangeRate: number;
};

/**
 * @description 구독 플랜버전 기반 (결제금액)
 */
export const getPaymentAmount = ({
  paymentCycle,
  monthlyFee,
  annualFee,
  currencyUnit,
  exchangeRate,
}: GetPaymentAmountType): number => {
  const priceUSD = paymentCycle === "MONTHLY" ? monthlyFee : annualFee;
  const paymentAmount =
    currencyUnit === "USD" ? priceUSD : Number(priceUSD) * Number(exchangeRate);

  return Number(paymentAmount);
};

type GetCancelFeeType = {
  cancellationFee: string;
  currencyUnit: PaymentCurrencyUnitType;
  exchangeRate: number;
};

/**
 * @description
 * 환불 금액
 * - USD : currencyUnit === USD && cancellationFee
 * - KRW : currencyUnit === USD && Number(cancellationFee) * Number(exchangeRate);
 * @returns cancelFee (number)
 */
export const getCurrencyCancelFee = ({
  cancellationFee,
  currencyUnit,
  exchangeRate,
}: GetCancelFeeType): number => {
  const cancelFee =
    currencyUnit === "USD"
      ? Number(cancellationFee)
      : Number(cancellationFee) * Number(exchangeRate);

  return cancelFee;
};

/**
 * @description:연 구독료 계산하는 함수
 * @param
 * - annualFee: string; 연 구독료,
 * - annualFeeDiscount: string; 연 할인 금액
 * - planType: PlanType; 할인 타입
 * - annualFeeDiscountType: AnnualFeeDiscountType; 플랜 타입
 */
export const transformAnnualFeeString = (param: {
  annualFee: string;
  annualFeeDiscount: string;
  planType: PlanType;
  annualFeeDiscountType: AnnualFeeDiscountType;
}) => {
  const { annualFee, annualFeeDiscount, planType, annualFeeDiscountType } =
    param;

  const numberToAnnualFee = parseInt(annualFee);
  const discountAnnualFee = Number(annualFeeDiscount);

  // 플랜 타입이 Enterprise 경우
  if (planType === "ENTERPRISE") {
    const resultAnnualFee = numberToAnnualFee.toLocaleString("ko-KR");

    return resultAnnualFee;
  }

  if (annualFeeDiscountType === "NUMBER") {
    // 연 결제 할인방식 가격 일시
    const resultAnnualFee = (
      numberToAnnualFee - discountAnnualFee
    ).toLocaleString("ko-KR");

    return resultAnnualFee;
  }

  // 연 결제 할인방식 퍼센트 일시
  if (annualFeeDiscountType === "PERCENT") {
    const discountedPrice = (
      numberToAnnualFee -
      numberToAnnualFee * (discountAnnualFee / 100)
    ).toLocaleString("ko-KR");

    return discountedPrice;
  }
};

export type GetCurrentPriceType = {
  exchangeRate: number;
  price: number;
  currencyUnit: PaymentCurrencyUnitType;
  isFree?: boolean;
};

export const getAmountOfPayment = ({
  exchangeRate,
  price,
  currencyUnit,
  isFree,
}: GetCurrentPriceType) => {
  const resultPrice = currencyUnit === "KRW" ? price : price / exchangeRate;

  if (isFree) {
    return 0;
  }

  return resultPrice;
};
