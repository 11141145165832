import React from "react";
import styled from "styled-components";
import CheckCircleSvg from "@/src/assets/icons/icon-checkmark-circle-green-fill.svg";
import CheckGrayCircleSvg from "@/src/assets/icons/icon-checkmark-circle-gray-fill.svg";
import ErrorSvg from "@/src/assets/icons/icon-error-circle-fill.svg";
import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle.svg";
import Icon from "../../atom/Icon";
import colorSet from "@/src/styles/color";
import Typo from "../../atom/Typo";
import typo from "@/src/styles/typography";
import { SignatureStatus } from "@/src/store/apis/contracts/contractDetail/interface";

type DoneType = {
  type: "done";
  at: string;
  who: string;
};

type ErrorType = {
  type: "error";
  at: string;
  who: string;
};

type UndoneType = {
  type: "todo";
  at?: never;
  who?: never;
};

type SignatureType = (DoneType | UndoneType | ErrorType) & {
  stepName: SignatureStatus;
  onErrorClick?: () => void;
};

type SignatureStepProps = {
  signStep: SignatureType[];
  className?: string;
};

const stepNameTransformLocale = (
  signStep: SignatureStatus,
  onErrorClick?: () => void
) => {
  switch (signStep) {
    case "DRAFT":
      return "Draft";
    case "APPROVED":
      return "Approved";
    case "LINKED":
      return "Contract Linked";
    case "PENDING":
      return (
        <Flex>
          Pending
          <StyledIcon iconSrc={ErrorCircleSvg} onClick={onErrorClick} />
        </Flex>
      );
    case "REQUESTED":
      return "Requested";
    case "SENT":
      return "Sent";
  }
};

const SignatureStep = ({ signStep, className }: SignatureStepProps) => {
  return (
    <Article className={className}>
      {signStep.map(({ type, at, who, stepName, onErrorClick }, idx) => {
        return (
          <StepContent key={idx.toString()}>
            <LineContainer>
              <TopLine />
              <Icon
                iconSrc={
                  type === "done"
                    ? CheckCircleSvg
                    : type === "error"
                    ? ErrorSvg
                    : CheckGrayCircleSvg
                }
                iconSize={16}
              />
              <BottomLine />
            </LineContainer>

            <Section>
              <FlexSpaceBetween>
                <Typo>
                  {stepNameTransformLocale(
                    stepName,
                    stepName === "PENDING" ? onErrorClick : undefined
                  )}
                </Typo>

                {type === "done" || type === "error" ? (
                  <TimeStampTag>{at}</TimeStampTag>
                ) : null}
              </FlexSpaceBetween>
              {type === "done" || type === "error" ? (
                <Typo typoType="b9m" color="gray5">
                  {who}
                </Typo>
              ) : null}
            </Section>
          </StepContent>
        );
      })}
    </Article>
  );
};

export default SignatureStep;

const Article = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopLine = styled.div`
  width: 2px;
  height: 12px;
  background: ${colorSet.gray10};
`;

const BottomLine = styled.div`
  width: 2px;
  flex: 1;
  background: ${colorSet.gray10};
`;

const StepContent = styled.div`
  display: flex;
  gap: 8px;

  &:first-of-type {
    ${TopLine} {
      background: transparent;
    }
  }

  &:last-of-type {
    ${BottomLine} {
      background: transparent;
    }
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
  flex: 1;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TimeStampTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border: 1px solid ${colorSet.systemBlue4};
  color: ${colorSet.systemBlue4};
  border-radius: 2px;
  ${typo.b11r}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
