import { PartialCommonCodeItemDto } from "../store/apis/common/interface";

type OptionType = {
  value: string;
  label: string;
};

export const commonCodeMapper = (
  acc: OptionType[],
  val: PartialCommonCodeItemDto
) => {
  const resource = { label: val.codeItemNameEn, value: val.codeItemName };
  return [...acc, { ...resource }];
};
