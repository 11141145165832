import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  CSSProperties,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  forwardRef,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";

export interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  maxLength?: number;
  name?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  readonly?: boolean;
  containerStyle?: CSSProperties;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [length, setLength] = useState<number>(props.value?.length ?? 0);
    const textAreaRef = useRef<HTMLTextAreaElement>();

    return (
      <StyledTextAreaContainer
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        data-focus={isFocus}
        onClick={() => {
          if (textAreaRef.current) {
            textAreaRef.current.focus();
          }
        }}
        style={props.containerStyle}
        disabled={props.disabled}
      >
        <StyledTextArea
          ref={(node) => {
            if (node) {
              textAreaRef.current = node;
            }
            if (ref) {
              if (typeof ref === "function") {
                ref(node);
              } else {
                ref.current = node;
              }
            }
          }}
          {...props}
          onChange={(e) => {
            setLength(e.target.value.length);
            if (length > (props?.maxLength ?? 300)) {
              return (e.target.value = e.target.value.slice(
                0,
                props?.maxLength ?? 300
              ));
            }

            props.onChange?.(e);
          }}
          maxLength={props.maxLength ?? 300}
        />

        <TextAreaLengthBoard>
          {length} / {props.maxLength ?? 300}
        </TextAreaLengthBoard>
      </StyledTextAreaContainer>
    );
  }
);

export default TextArea;

const StyledTextAreaContainer = styled.div<{ disabled?: boolean }>`
  cursor: text;
  transition: all 0.2s ease-in-out;
  border: ${({ disabled }) =>
    disabled ? `1px solid ${colorSet.gray8}` : `1px solid ${colorSet.gray9}`};
  border-radius: 8px;
  padding: 0 5px 5px 0;
  box-shadow: 0px 2px 4px 0px #0000000d;
  background: ${({ disabled }) =>
    disabled ? colorSet.gray10 : colorSet.white};

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled
        ? "none"
        : `0px 0px 0px 1px #1479eb, 0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);`};
  }

  &[data-focus="true"] {
    outline: 1px solid ${colorSet.blue1};
    box-shadow: none;
  }
`;

const StyledTextArea = styled.textarea`
  resize: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 10px 11px 0 16px;
  ${typo.b7r};

  &::placeholder {
    color: ${colorSet.gray7};
    ${typo.b7r}
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    border-radius: 8px;
    background: ${colorSet.gray10};
    color: ${colorSet.gray7};
  }
`;

const TextAreaLengthBoard = styled.p`
  ${typo.label2}
  color: ${colorSet.gray7};
  text-align: right;
`;
