import styled from "styled-components";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import RadioDisabledSvg from "@/src/assets/icons/icon-radio-disabled.svg";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import { ExporterUserDto } from "@/src/store/apis/auth/interface";

const commonColumn: ColDef = {
  sortable: false,
  headerClass: "ag-right-aligned-cell ag-header-hide",
  cellStyle: { textAlign: "center" },
  flex: 1,
};

export const clientColumn: ColDef[] = [
  {
    headerName: "",
    field: "radioButton",
    pinned: "left",
    lockPosition: "left",
    headerClass: "ag-right-aligned-cell",
    resizable: false,
    sortable: false,
    width: 60,
    cellRenderer: ({ data }: any) => {
      return (
        <StyledRadio>
          <input
            type="radio"
            className={`custom-ag-radio`}
            checked={data.isChecked}
          />
          <span className={`custom-radio-mark`} />
        </StyledRadio>
      );
    },
  },
  {
    headerName: "client",
    field: "client",
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams<BuyerListViewDto>) => {
      return <>{params.data?.buyerName || "-"}</>;
    },
  },
  {
    headerName: "clientType",
    field: "clientType",
    ...commonColumn,
    width: 150,
    cellRenderer: (params: ValueGetterParams<BuyerListViewDto>) => {
      return (
        <>
          {params.data?.companyType === "SELLER"
            ? "Exporter"
            : params.data?.companyType === "BUYER"
            ? "Importer"
            : "Exporter & Importer"}
        </>
      );
    },
  },
  {
    headerName: "clientCode",
    field: "clientCode",
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams<BuyerListViewDto>) => {
      return <>{params.data?.buyerNameCode || "-"}</>;
    },
  },
  {
    headerName: "country",
    field: "country",
    ...commonColumn,
  },
  {
    headerName: "categoryInCharge",
    field: "categoryInCharge",
    ...commonColumn,
    cellRenderer: (params: ValueGetterParams<BuyerListViewDto>) => {
      return <>{params.data?.mainCategoryCodeItemNames.join(", ") || "-"}</>;
    },
  },
];

export const staffManagerColumn = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.personalContact
            ? `${data?.personalContactPrefix ?? ""} ${data?.personalContact}`
            : "-"}
        </>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "officeContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.officeContact
            ? `${data?.officeContactPrefix ?? ""} ${data?.officeContact}`
            : "-"}
        </>
      );
    },
  },
] as ColDef[];

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .disabled {
    width: 22px;
    height: 22px;
    background: url(${RadioDisabledSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;
