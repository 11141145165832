export const excelDownload = async ({
  response,
  manualExcelFileName = "list.xlsx",
}: {
  response: Response;
  manualExcelFileName?: string;
}) => {
  const fileName =
    response.headers.get("Content-Disposition")?.split("filename=")?.[1] ??
    manualExcelFileName;
  const url = window.URL.createObjectURL(await response.blob());
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("download", decodeURI(fileName));
  document.body.appendChild(link);
  link.click();
};
