import colorSet from "@/src/styles/color";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { css, styled } from "styled-components";
import Loader from "../Loader";
import Icon from "../Icon";
import ImagePlaceholderSvg from "@/src/assets/icons/icon-image.svg";

export interface ImageProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  imageSrc?: string;
  isLoading?: boolean;
  imagePlaceholder?: ReactNode;
  topRightAccessary?: ReactNode;
  imageWidth?: number;
  imageHeight?: number;
}

const Image = ({
  imagePlaceholder,
  imageSrc,
  isLoading,
  topRightAccessary,
  imageWidth,
  imageHeight,
  ...divProps
}: ImageProps) => {
  return (
    <StyledImage
      {...divProps}
      imageSrc={imageSrc}
      isPlaceholder={!imageSrc}
      imageWidth={imageWidth ?? 100}
      imageHeight={imageHeight ?? 100}
      isLoading={!!isLoading}
      hasSrc={!!imageSrc}
    >
      {isLoading && <Loader />}
      {!isLoading && !imageSrc && (
        <>
          {imagePlaceholder || (
            <Icon iconSrc={ImagePlaceholderSvg} iconSize={30} />
          )}
        </>
      )}
      {!isLoading && imageSrc && topRightAccessary && (
        <AccessaryContainer>{topRightAccessary}</AccessaryContainer>
      )}
      {!isLoading && imageSrc && <Overlay />}
    </StyledImage>
  );
};

export default Image;

const StyledImage = styled.div<{
  isPlaceholder: boolean;
  imageWidth: number;
  imageHeight: number;
  isLoading: boolean;
  hasSrc: boolean;
  imageSrc?: string;
}>`
  width: ${({ imageWidth }) => imageWidth}px;
  height: ${({ imageHeight }) => imageHeight}px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${colorSet.gray10};

  ${({ imageSrc }) =>
    imageSrc &&
    css`
      background: url(${imageSrc}) no-repeat center/cover;
    `};

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      background: ${colorSet.gray11};
      border: 1px solid ${colorSet.gray10};
    `};

  ${({ isLoading, hasSrc }) =>
    (isLoading || !hasSrc) &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

const AccessaryContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;
