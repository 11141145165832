export const SUBSCRIPTION_MANAGEMENT_STRINGS = {
  paymentIsScheduledInOneWeek: {
    en: ({
      year,
      month,
      date,
    }: {
      year: string;
      month: string;
      date: string;
    }) => {
      return `Payment is scheduled in one week. The scheduled payment date is ${year}-${month}-${date}`;
    },
    ko: ({
      year,
      month,
      date,
    }: {
      year: string;
      month: string;
      date: string;
    }) => {
      return `1주일 후 결제 예정입니다. 결제 예정일은 ${year}년 ${month}월 ${date}일 입니다`;
    },
  },
  theSubscriptionToChange: {
    en: ({
      year,
      month,
      date,
      nextPlanName,
    }: {
      year: string;
      month: string;
      date: string;
      nextPlanName: string;
    }) => {
      return `It will be changed to ${nextPlanName} from ${year}-${month}-${date}. Please check the change information.`;
    },
    ko: ({
      year,
      month,
      date,
      nextPlanName,
    }: {
      year: string;
      month: string;
      date: string;
      nextPlanName: string;
    }) => {
      return `${year}년 ${month}월 ${date}일부터 ${nextPlanName}으로 변경됩니다. 변경 정보를 확인해주세요.`;
    },
  },
  theSubscriptionCanceledCancel: {
    en: ({
      year,
      month,
      date,
      currentPlanName,
    }: {
      year: string;
      month: string;
      date: string;
      currentPlanName: string;
    }) => {
      return `The subscription to ${currentPlanName} will be canceled as of ${year}-${month}-${date}. You can cancel the plan cancellation at any time before the cancellation date.`;
    },
    ko: ({
      year,
      month,
      date,
      currentPlanName,
    }: {
      year: string;
      month: string;
      date: string;
      currentPlanName: string;
    }) => {
      return `${year}년 ${month}월 ${date}일부로 ${currentPlanName} 구독이 해지가 됩니다. 해지일 이전에 언제든지 플랜 해지 취소가 가능합니다.`;
    },
  },
};

export const PAYMENT_STATUS_STRINGS = {
  payment_success: {
    en: "Payment Complete",
    ko: "결제 완료",
  },
  payment_fail: {
    en: "Payment Failed",
    ko: "결제 실패",
  },
  cancel_success: {
    en: "Payment cancel",
    ko: "카드 취소",
  },
  refund_require: {
    en: "환불 접수",
    ko: "환불 접수",
  },
  refund_success: {
    en: "Payment Refund",
    ko: "환불 완료",
  },
};
