import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { styled } from "styled-components";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Filter from "@/src/components/molecule/Filter";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useAppSelector } from "@/src/store";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import AddSvg from "@/src/assets/icons/icon-add-white.svg";
import ExcelSvg from "@/src/assets/icons/icon-excel.svg";
import ResetSvg from "@/src/assets/icons/icon-reset-circle.svg";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { columnBookingList } from "./columns/columnBookingList";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import {
  GetBookingListFilterRequest,
  GetBookingListRequest,
} from "@/src/store/apis/bookings/bookingList/interface";
import {
  useLazyGetBookingExcelListQuery,
  useLazyGetBookingCategoryFilterListQuery,
} from "@/src/store/apis/bookings/bookingList";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import useAlert from "@/src/hooks/useAlert";
import useAgGirdSetColumn from "@/src/hooks/useAgGridSetColumn";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { BuyerListViewDto } from "@/src/store/apis/client/buyer/interface";
import { gridColIdLocalStorage } from "@/src/utils/localStorageFixedColId";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

const Init: GetBookingListFilterRequest = {
  bookingStatus: "all",
  mainCategoryCodeItemName: "all",
  buyerNameCode: "all",
  itemCode: undefined,
  bookingNo: undefined,
  scNo: undefined,
  etdAt: undefined,
};

const FAST_SEARCH_QUERY_KEY = [
  "bookingStatus",
  "mainCategoryCodeItemName",
  "etdAt",
  "buyerNameCode",
];

const mainCategoryEmptyArray: PartialCommonCodeItemDto[] = [];
const buyerEmptyObject: {
  data: BuyerListViewDto[];
  count: number;
} = { data: [], count: 0 };

const ExporterBookingPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const alert = useAlert();

  const user = useAppSelector((state) => state.auth.user);
  const subscription = useAppSelector((state) => state.subscription);

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    user?.exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  // API
  const [getExcel] = useLazyGetBookingExcelListQuery();
  const session = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [
    getBookingList,
    { bookingList, count, isFetching, originalArgs, isError },
  ] = useLazyGetBookingCategoryFilterListQuery({
    selectFromResult: ({ currentData, isFetching, isError, originalArgs }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isFetching,
        originalArgs,
        count: !isUnstable ? currentData.count : 0,
        bookingList: !isUnstable ? currentData.rows : undefined,
        isError,
      };
    },
  });
  const { mainCategoryCurrentData } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategoryCurrentData: !isUnstable
            ? currentData ?? mainCategoryEmptyArray
            : mainCategoryEmptyArray,
        };
      },
    }
  );
  const { buyerCurrentData } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          buyerCurrentData: !isUnstable ? currentData : buyerEmptyObject,
        };
      },
    }
  );

  const buyerOptionList = buyerCurrentData.data?.map((item) => {
    return { label: item.buyerNameCode, value: String(item.id) };
  });
  const mainCategoryFilterList = mainCategoryCurrentData
    ?.filter((item) =>
      session.currentData?.row.exporter.mainCategoryCodeItemNames.includes(
        item.codeItemName
      )
    )
    .map((item) => {
      return {
        label: item.codeItemNameEn,
        value: item.codeItemName,
      };
    });
  const allOptionItem = {
    label: t("booking:list.placeholder.all"),
    value: "all",
  };

  // State
  const [isReady, setIsReady] = useState<boolean>(false);
  const {
    func: { handleColumnMoved, handleColumnReset },
    state: { col },
  } = useAgGirdSetColumn({
    key: gridColIdLocalStorage.booking,
    columns: columnBookingList,
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "isBookmarked",
        langKey: "booking:list.table.isBookmarked",
      },
      {
        columnKey: "bookingStatus",
        langKey: "booking:list.table.bookingStatus",
      },
      {
        columnKey: "bookingNo",
        langKey: "booking:list.table.bookingNo",
      },
      {
        columnKey: "scNo",
        langKey: "booking:list.table.scNo",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "booking:list.table.buyerNameCode",
      },
      {
        columnKey: "mainCategoryCodeItemName",
        langKey: "booking:list.table.mainCategoryCodeItemName",
      },
      {
        columnKey: "itemCode",
        langKey: "booking:list.table.itemCode",
      },
      {
        columnKey: "numberOfContainer",
        langKey: "booking:list.table.numberOfContainer",
      },
      {
        columnKey: "docCutOffAt",
        langKey: "booking:list.table.docCutOffAt",
      },
      {
        columnKey: "cargoCutOffAt",
        langKey: "booking:list.table.cargoCutOffAt",
      },
      {
        columnKey: "etdAt",
        langKey: "booking:list.table.etd",
      },
      {
        columnKey: "etaAt",
        langKey: "booking:list.table.eta",
      },
      {
        columnKey: "portOfLoading",
        langKey: "booking:list.table.portOfLoading",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "booking:list.table.placeOfDelivery",
      },
    ],
  });

  const handleExcelDownload = async () => {
    try {
      if (originalArgs) {
        await getExcel(originalArgs).unwrap();
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const {
    state: { pagination, filterData },
    func: {
      onPaginationChange,
      onSortChange,
      onSearch,
      onFilterDataChange,
      onResetFilter,
      onForceSearch,
    },
  } = useFilterSearch<GetBookingListRequest, GetBookingListFilterRequest>({
    isReady,
    gridRef: gridRef.current,
    fetch: getBookingList,
    defaultFilterData: Init,
    onBeforeSearch: (data) => {
      return {
        bookingStatus:
          data.bookingStatus === "all" ? undefined : data?.bookingStatus,
        bookingNo: data.bookingNo || undefined,
        scNo: data?.scNo || undefined,
        buyerNameCode:
          data.buyerNameCode === "all"
            ? undefined
            : buyerOptionList.find(({ value }) => value === data.buyerNameCode)
                ?.label,
        itemCode: data?.itemCode || undefined,
        mainCategoryCodeItemName:
          data.mainCategoryCodeItemName === "all"
            ? undefined
            : data?.mainCategoryCodeItemName,
        etdAt: undefined,
        etdAtFrom: data.etdAt?.[0]
          ? dayjs(data.etdAt?.[0]).startOf("day").toISOString()
          : undefined,
        etdAtTo: data.etdAt?.[1]
          ? dayjs(data.etdAt?.[1]).endOf("day").toISOString()
          : undefined,
      };
    },
    onBeforeSetFilter: (urlObject) => {
      return {
        bookingStatus: urlObject.bookingStatus ?? "all",
        buyerNameCode:
          buyerOptionList.find(({ label }) => label === urlObject.buyerNameCode)
            ?.value ?? "all",
        itemCode: urlObject.itemCode,
        bookingNo: urlObject.bookingNo,
        scNo: urlObject.scNo,
        mainCategoryCodeItemName: urlObject.mainCategoryCodeItemName ?? "all",
        etdAt:
          urlObject.etdAtFrom && urlObject.etdAtTo
            ? [dayjs(urlObject.etdAtFrom), dayjs(urlObject.etdAtTo)]
            : undefined,
        etdAtFrom: urlObject.etdAtFrom,
        etdAtTo: urlObject.etdAtTo,
      };
    },
  });

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:booking")]}
      pageTitle={t("sideNav:bookingManagement")}
    >
      <FlexColumn>
        <Filter
          filterData={filterData}
          filterKeyList={[
            {
              key: "bookingStatus",
              label: t("booking:list.filter.bookingStatus"),
              type: "searchSelect",
              props: {
                placeholder: t("booking:list.placeholder.all"),
                allowClear: filterData.bookingStatus === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("bookingStatus", "all");
                    onForceSearch("bookingStatus", "all");
                  } else {
                    onFilterDataChange("bookingStatus", value);
                    onForceSearch("bookingStatus", value);
                  }
                },
              },
            },
            {
              key: "bookingNo",
              label: t("booking:list.filter.bookingNo"),
              type: "input",
              props: {
                placeholder: t("booking:list.placeholder.bookingNo"),
              },
            },
            {
              key: "scNo",
              label: t("booking:list.filter.scNo"),
              type: "input",
              props: {
                placeholder: t("booking:list.placeholder.scNo"),
              },
            },
            {
              key: "buyerNameCode",
              label: t("booking:list.filter.buyerNameCode"),
              type: "searchSelect",
              props: {
                placeholder: t("booking:list.placeholder.all"),
                allowClear: filterData.buyerNameCode === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("buyerNameCode", "all");
                    onForceSearch("buyerNameCode", "all");
                  } else {
                    onFilterDataChange("buyerNameCode", value);
                    onForceSearch("buyerNameCode", value);
                  }
                },
              },
            },
            {
              key: "mainCategoryCodeItemName",
              label: t("booking:list.filter.mainCategoryCodeItemName"),
              type: "searchSelect",
              props: {
                placeholder: t("booking:list.placeholder.all"),
                allowClear:
                  filterData.mainCategoryCodeItemName === "all" ? false : true,
                onChange: (value) => {
                  if (!value) {
                    onFilterDataChange("mainCategoryCodeItemName", "all");
                    onForceSearch("mainCategoryCodeItemName", "all");
                  } else {
                    onFilterDataChange("mainCategoryCodeItemName", value);
                    onForceSearch("mainCategoryCodeItemName", value);
                  }
                },
              },
            },
            {
              key: "itemCode",
              label: t("booking:list.filter.itemCode"),
              type: "input",
              props: {
                placeholder: t("booking:list.placeholder.itemCode"),
              },
            },
            {
              key: "etdAt",
              label: t("booking:list.filter.etd"),
              type: "dateRangePicker",
            },
          ]}
          onChange={(key, value) => {
            onFilterDataChange(key, value);

            if (FAST_SEARCH_QUERY_KEY.includes(key)) {
              onForceSearch(key, value);
            }
          }}
          onReset={onResetFilter}
          onSubmit={onSearch}
          optionList={[
            {
              key: "bookingStatus",
              option: [
                {
                  label: t("booking:list.placeholder.all"),
                  value: "all",
                },
                {
                  label: t("booking:detail.badge.temporaryBooking"),
                  value: "TEMPORARY_BOOKING",
                },
                {
                  label: t("booking:detail.badge.confirmedBooking"),
                  value: "CONFIRMED_BOOKING",
                },
                {
                  label: t("booking:detail.badge.canceledBooking"),
                  value: "CANCELED_BOOKING",
                },
              ],
            },
            {
              key: "buyerNameCode",
              option: [allOptionItem, ...buyerOptionList],
            },
            {
              key: "mainCategoryCodeItemName",
              option: [allOptionItem, ...mainCategoryFilterList],
            },
          ]}
        />

        <div>
          <TableTitleContainer>
            <Typo color="gray4" typoType="b5m">
              {t("booking:list.table.title")}
            </Typo>

            <ButtonContainer>
              <StyledButton
                buttonSize={40}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleExcelDownload}
              >
                <Icon iconSrc={ExcelSvg} iconSize={16} />
                {t("booking:list.table.excel")}
              </StyledButton>
              <StyledButton
                buttonSize={40}
                onClick={() => navigate(EXPORTER_PRIVATE_PATH.BOOKING_ADD)}
                disabled={isCreateDisabled}
              >
                <Icon iconSrc={AddSvg} iconSize={16} />
                {t("booking:list.table.addButton")}
              </StyledButton>
            </ButtonContainer>
          </TableTitleContainer>
          <SectionCardWithoutHeader>
            <Table
              ref={gridRef}
              rowData={isError ? [] : bookingList}
              isPaginationDataMaping
              columnDefs={col}
              handlePaginationClick={(page, pageSize) =>
                onPaginationChange({ page, pageSize })
              }
              totalPage={count}
              onRowClicked={({ data }) => {
                navigate(`${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/${data.id}`);
              }}
              pageSize={pagination.pageSize}
              page={pagination.page}
              onSortChange={(sortSource, isClickedHeader) =>
                !!isClickedHeader && onSortChange(sortSource as any)
              }
              onColumnMoved={handleColumnMoved}
              onGridReady={() => setIsReady(true)}
              paginationRightAccessary={
                <StyledButton
                  buttonColor="black"
                  buttonGrade="tertiary"
                  buttonSize={32}
                  onClick={handleColumnReset}
                  style={{ marginLeft: "8px" }}
                >
                  <Icon iconSrc={ResetSvg} iconSize={16} />
                  <Typo typoType="btn4m">Column reset</Typo>
                </StyledButton>
              }
            />
          </SectionCardWithoutHeader>
        </div>
      </FlexColumn>
    </ExporterMainLayout>
  );
};

export default ExporterBookingPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
