const DATE_FORMAT_STRINGS = {
  // Date
  YY_MM_DD: "YY-MM-DD",
  YYYY_MM_DD: "YYYY-MM-DD",
  YYYY_MM_DD_HH_mm: "YYYY-MM-DD HH:mm",
  YYYY_MM_DD_hA: "YYYY-MM-DD hA",
  dddd: "dddd",
  DD: "DD",
  "MM.DD": "MM.DD",

  // Time
  HH_mm: "HH:mm",
  "YY.MM.DD": "YY.MM.DD",
  "YYYY.MM.DD": "YYYY.MM.DD",
};

export default DATE_FORMAT_STRINGS;
