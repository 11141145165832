import colorSet, { ColorType } from "@/src/styles/color";
import { HTMLAttributes, ReactNode } from "react";
import Typo from "../Typo";
import { styled } from "styled-components";
import { ReactComponent as CloseIconSvg } from "@/src/assets/icons/icon-cancle-black.svg";

interface TagProps extends HTMLAttributes<HTMLDivElement> {
  label: string | ReactNode;
  color?: ColorType;
  tagColor?: ColorType;
  onRemove?: () => void;
  isValid?: boolean;
}

function Tag({
  label,
  color = "blue4",
  tagColor = "blue10",
  onRemove,
  isValid = true,
  ...props
}: TagProps) {
  return (
    <StyledLabel
      $tagColor={tagColor}
      $isValid={isValid}
      isDisabled={!onRemove}
      {...props}
    >
      <Typo typoType="btn3m" as="span" color={color}>
        {label}
      </Typo>
      {onRemove && (
        <StyledIcon
          color={color}
          onPointerDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
        />
      )}
    </StyledLabel>
  );
}

export default Tag;

const StyledLabel = styled.div<{
  $tagColor: ColorType;
  $isValid: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 64px;
  padding: 2px 6px 2px 8px;
  background: ${({ $tagColor }) => colorSet[$tagColor]};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  box-shadow: ${({ $isValid }) =>
    $isValid
      ? `0px 1px 2px 0px rgba(5, 29, 57, 0.1),
    0px 0px 0px 0.5px rgba(23, 98, 195, 0.2);`
      : `0px 1px 2px 0px rgba(5, 29, 57, 0.10), 0px 0px 0px 0.5px rgba(214, 76, 76, 0.20);`};
`;

const StyledIcon = styled(CloseIconSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;
