import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import {
  BookingDetailViewDto,
  GenerateBookingDto,
} from "../bookingRegister/interface";

export const bookingEditApi = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      editBooking: build.mutation<
        Row<BookingDetailViewDto>,
        GenerateBookingDto & { id: number }
      >({
        query: (body) => {
          const { id, ...restBody } = body;
          return {
            url: `/exporters/this/bookings/${body.id}`,
            method: "PUT",
            body: restBody,
          };
        },
      }),
    };
  },
});

export const { useEditBookingMutation } = bookingEditApi;
