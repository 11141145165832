import { SignupJoinPathType } from "../store/apis/auth/interface";

export const HRM_CORPORATION_STRING = {
  corporateName: {
    en: "HRM CORPORATION",
    ko: "(주)에이치알엠",
  },
  representativeName: {
    en: "Sungchan Ahn",
    ko: "안성찬",
  },
  address: {
    en: "604, 217-59, Kintex-ro, Ilsanseo-gu, Goyang-si, Gyeonggi-do, Republic of Korea",
    ko: "경기도 고양시 일산서구 킨텍스로 217-59, 오피스동 604호 (대화동, 제2 킨텍스)",
  },
  tel: "+82)31-907-2030",
  registrationNumber: "489-88-00517",
};

const STRINGS = {
  login: {
    footer: {
      en: [
        `${HRM_CORPORATION_STRING.corporateName.en}`,
        `Name of representative: ${HRM_CORPORATION_STRING.representativeName.en}`,
        `Business registration number : ${HRM_CORPORATION_STRING.registrationNumber}`,
        `${HRM_CORPORATION_STRING.tel}`,
        `Address : ${HRM_CORPORATION_STRING.address.en}`,
      ],
      ko: [
        `사업자명 : ${HRM_CORPORATION_STRING.corporateName.ko}`,
        `대표자 : ${HRM_CORPORATION_STRING.representativeName.ko}`,
        `사업자등록번호 : ${HRM_CORPORATION_STRING.registrationNumber}`,
        `전화 : ${HRM_CORPORATION_STRING.tel}`,
        `주소 : ${HRM_CORPORATION_STRING.address.ko}`,
      ],
    },
  },
  signup: {
    common: {
      callout: {
        en: [
          "Employees must have a ",
          "corporate code ",
          "to join ECOYA Export.",
          "If you don't have a ",
          "corporate code ",
          "please sign up for a corporate account first!",
        ],
        ko: [
          "직원 회원은 ECOYA Export에 가입한 ",
          "기업코드",
          "가 있어야 가입할 수 있습니다. 기업 가입 이력이 없다면 ",
          "기업 계정",
          "을 가입해주세요!",
        ],
      },
    },
  },
  sideNav: {
    serviceOperation: {
      en: "Service operation",
      ko: "서비스 운영",
    },
    management: {
      en: "Management",
      ko: "관리",
    },
    customerServiceCenter: {
      en: "Customer service center",
      ko: "고객센터",
    },
    home: {
      en: "Home",
      ko: "홈",
    },
    contract: {
      en: "Contract",
      ko: "계약",
    },
    contractDetail: {
      en: "Contract Detail",
      ko: "계약 상세",
    },
    exportContractCreate: {
      en: "Create Contract",
      ko: "계약 생성하기",
    },
    importContract: {
      en: "Order",
      ko: "발주",
    },
    importContractDetail: {
      en: "Order Detail",
      ko: "발주 상세",
    },
    importContractRegistration: {
      en: "Create PO",
      ko: "PO 생성하기",
    },
    importContractEdit: {
      en: "Edit PO",
      ko: "PO 수정",
    },
    createPO: {
      en: "Create PO",
      ko: "PO 생성하기",
    },
    editPO: {
      en: "Edit PO",
      ko: "PO 수정하기",
    },
    contractRegistration: {
      en: "Create Contract",
      ko: "신규 계약 등록",
    },
    contractEdit: {
      en: "Edit Contract",
      ko: "계약 수정",
    },
    contractManagement: {
      en: "Contract",
      ko: "계약 관리",
    },
    booking: {
      en: "Booking",
      ko: "부킹",
    },
    bookingRegistration: {
      en: "Create Booking",
      ko: "부킹 등록",
    },
    bookingEdit: {
      en: "Edit Booking",
      ko: "부킹 수정",
    },
    bookingManagement: {
      en: "Booking",
      ko: "부킹 관리",
    },
    bookingDetail: {
      en: "Booking Detail",
      ko: "부킹 상세",
    },
    loading: {
      en: "Loading",
      ko: "로딩",
    },
    loadingRegistration: {
      en: "Create Loading",
      ko: "로딩 등록",
    },
    loadingDetail: {
      en: "Loading Detail",
      ko: "로딩 상세",
    },
    loadingManagement: {
      en: "Loading",
      ko: "로딩 관리",
    },
    loadingEdit: {
      en: "Edit Loading",
      ko: "로딩 수정",
    },
    shipment: {
      en: "Shipment",
      ko: "선적",
    },
    shipmentManagement: {
      en: "Shipment",
      ko: "선적 관리",
    },
    shipmentRegistration: {
      en: "Create Shipment",
      ko: "선적 생성하기",
    },
    shipmentDetail: {
      en: "Shipment Detail",
      ko: "선적 상세",
    },
    clientManagement: {
      en: "Client Management",
      ko: "거래처 관리",
    },
    clientManagementRegistration: {
      en: "Client Registration",
      ko: "거래처 등록",
    },
    clientManagementEdit: {
      en: "Edit Client Information",
      ko: "거래처 정보 수정",
    },
    clientManagementDetail: {
      en: "Client Detail",
      ko: "거래처 상세",
    },
    factoryManagement: {
      en: "Factory Management",
      ko: "작업지 관리",
    },
    factoryManagementRegistration: {
      en: "Factory Registration",
      ko: "작업지 등록",
    },
    factoryManagementDetail: {
      en: "Factory Detail",
      ko: "작업지 관리 상세",
    },
    factoryManagementEdit: {
      en: "Edit Factory Information",
      ko: "작업지 관리 편집",
    },
    corporateManagement: {
      en: "Corporate\nManagement",
      ko: "회사관리",
    },
    companyInformationManagement: {
      en: "Company Information\nManagement",
      ko: "회사정보관리",
    },
    itemManagement: {
      en: "Item Management",
      ko: "아이템 관리",
    },
    staffManagement: {
      en: "Staff Management",
      ko: "직원 관리",
    },
    notice: {
      en: "Notice",
      ko: "공지사항",
    },
    inquiry: {
      en: "1:1 Inquiry",
      ko: "1:1 문의",
    },
    inquiryRegistration: {
      en: "Create 1:1 Inquiry",
      ko: "1:1 문의 등록",
    },
    faq: {
      en: "FAQ",
      ko: "자주 묻는 질문",
    },
    termsOfUse: {
      en: "Terms of Use",
      ko: "이용약관",
    },
    privacyPolicy: {
      en: "Privacy Policy",
      ko: "개인정보 처리방침",
    },
    myPage: {
      en: "My Page",
      ko: "마이페이지",
    },
    fileBox: {
      en: "File Box",
      ko: "파일함",
    },
    sales: {
      en: "Contract",
      ko: "계약",
    },
    createSales: {
      en: "Create Contract",
      ko: "계약 생성하기",
    },
    editSales: {
      en: "Edit Contract",
      ko: "계약 수정",
    },
    detailSales: {
      en: "Contract Detail",
      ko: "계약 상세",
    },
    importManagement: {
      en: "Management",
      ko: "관리",
    },
    warehouseManagement: {
      en: "Warehouse Management",
      ko: "입고지 관리",
    },
    warehouseRegistration: {
      en: "Warehouse Registration",
      ko: "입고지 등록",
    },
    warehouseDetail: {
      en: "Warehouse Detail",
      ko: "입고지 상세",
    },
    warehouseEdit: {
      en: "Edit Warehouse Information",
      ko: "입고지 수정",
    },
    subscriptionManagement: {
      en: "Subscription Management",
      ko: "구독 관리",
    },
    importStatus: {
      en: "Import Status",
      ko: "수입 현황",
    },
    importStatusDetail: {
      en: "Import Status Detail",
      ko: "수입 현황 상세",
    },
  },
};

const SUBSCRIPTION_PATH_OPTION_LIST: {
  langKey: string;
  value: SignupJoinPathType;
}[] = [
  {
    langKey: "common:search",
    value: "SEARCHING",
  },
  {
    langKey: "common:introduction",
    value: "REFERRAL",
  },
  {
    langKey: "common:news",
    value: "NEWS_ARTICLE",
  },
  {
    langKey: "common:fair",
    value: "EXHIBITION",
  },
  {
    langKey: "common:etc",
    value: "ETC",
  },
];

export { STRINGS, SUBSCRIPTION_PATH_OPTION_LIST };
