import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import VerificationInputDiv from "@/src/components/molecule/VerificationInputDiv";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import { Button } from "@/src/components/atom/Button";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useLazyGetCompanyInfoViaLinkCodeQuery } from "@/src/store/apis/link";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAlert from "@/src/hooks/useAlert";
import LinkCompanyDetailDialog from "../LinkCompanyDetailDialog";
import { useAppSelector } from "@/src/store";
import { useTranslation } from "react-i18next";

interface LinkCodeDialogProps {
  onClose: () => void;
}

enum AlertDialogState {
  NULL,
  DUPLICATE,
}

const LinkCodeDialog = ({ onClose }: LinkCodeDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const lang = useAppSelector((state) => state.lang.value);
  const linkCode = useAppSelector(
    (state) => state.auth.user?.exporter.companyLinkCode
  );
  const [step, setStep] = useState<1 | 2>(1);
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );

  const [verification, setVerification] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const ref = useRef<HTMLDivElement[]>([]);
  const [getCompanyInfoViaLinkCode, { isFetching, currentData }] =
    useLazyGetCompanyInfoViaLinkCodeQuery();

  const handleCodeSubmit = async () => {
    try {
      const connectionLinkCode = verification.join("");
      if (linkCode === connectionLinkCode) {
        // eslint-disable-next-line no-throw-literal
        throw {
          data: {
            message:
              lang === "ko"
                ? "본사 기업 코드는 등록할 수 없습니다. 다시 입력해주세요."
                : "You cannot register the headquarters company code. Please enter the code again.",
          },
        };
      }
      await getCompanyInfoViaLinkCode({
        companyLinkCode: connectionLinkCode,
      }).unwrap();

      setStep(2);
    } catch (e: any) {
      const isAlreadyRegistered = e.status === 409;
      if (isAlreadyRegistered) {
        setAlertDialogState(AlertDialogState.DUPLICATE);
      } else {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  useEffect(() => {
    ref.current[0].focus();
  }, []);

  const renderDialogByStep = () => {
    if (step === 1) {
      return (
        <Dialog
          title={t("clientManagement:exporter.linkCodeDialog.dialogTitle")}
          open
          onOpenChange={onClose}
          width={640}
          footer={
            <FooterButtonsContainer>
              <Button
                buttonGrade={"tertiary"}
                buttonColor="black"
                onClick={() =>
                  navigate(EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD)
                }
              >
                {t(
                  "clientManagement:exporter.linkCodeDialog.button.generalRegistration"
                )}
              </Button>
              <Button
                disabled={verification.includes("") || isFetching}
                onClick={handleCodeSubmit}
                isLoading={isFetching}
              >
                {t(
                  "clientManagement:exporter.linkCodeDialog.button.codeRegistration"
                )}
              </Button>
            </FooterButtonsContainer>
          }
        >
          <Flex className="gap-40">
            <Flex className="gap-8">
              <Typo typoType="h2" as="h1">
                {t("clientManagement:exporter.linkCodeDialog.title")}
              </Typo>
              <Typo typoType="b7m">
                {t("clientManagement:exporter.linkCodeDialog.description")}
              </Typo>
            </Flex>

            <Flex className="gap-8">
              <VerificationCodeTitle>
                {t("clientManagement:exporter.linkCodeDialog.linkCode")}{" "}
                <strong>*</strong>
              </VerificationCodeTitle>

              <FlexRow className="gap-4">
                {verification.map((_, idx) => {
                  return (
                    <VerificationInputDiv
                      key={idx.toString()}
                      ref={(node) => node && ref.current.push(node)}
                      char={verification[idx]}
                      style={idx === 3 ? { marginRight: "20px" } : undefined}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (key === "ArrowLeft") {
                          if (idx - 1 !== -1) {
                            ref.current[idx - 1].focus();
                          }
                        }
                        if (key === "ArrowRight") {
                          if (idx + 1 !== verification.length) {
                            ref.current[idx + 1].focus();
                          }
                        }
                        if ((e.ctrlKey || e.metaKey) && e.key === "v") {
                          navigator.clipboard.readText().then((res) => {
                            const overridden = Array.from(
                              { length: 8 },
                              () => ""
                            );
                            const pasteText = res
                              .split("")
                              .filter((letter) => letter !== " ")
                              .slice(0, 8)
                              .concat(overridden)
                              .slice(0, 8);
                            setVerification(pasteText);
                          });
                        }
                      }}
                      onCharChange={(char) => {
                        if (!!verification[idx]) {
                          if (idx + 1 !== verification.length) {
                            ref.current[idx + 1].focus();
                          }
                          return;
                        }
                        const copied = verification.slice();
                        copied.splice(idx, 1, char ?? "");
                        setVerification(copied);
                        if (idx + 1 !== verification.length) {
                          ref.current[idx + 1].focus();
                        }
                      }}
                      onBackSpaceKeyDown={() => {
                        const copied = verification.slice();
                        copied.splice(idx, 1, "");
                        setVerification(copied);
                        if (idx - 1 !== -1) {
                          ref.current[idx - 1].focus();
                        }
                      }}
                    />
                  );
                })}
              </FlexRow>
            </Flex>
          </Flex>
        </Dialog>
      );
    }
    if (step === 2) {
      return (
        <LinkCompanyDetailDialog
          onClose={onClose}
          linkCode={verification.join("")}
          defaultClientType={currentData?.row.companyType || "BOTH"}
          defaultClientCode={currentData?.row.companyName || ""}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;
    if (alertDialogState === AlertDialogState.DUPLICATE) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.linkCodeDialog.alertDialog.successIntegration.title"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          cancelText={""}
          okText={t("common:ok")}
        >
          {t(
            "clientManagement:exporter.linkCodeDialog.alertDialog.successIntegration.description"
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <>
      {renderDialogByStep()}
      {renderAlertDialog()}
    </>
  );
};

export default LinkCodeDialog;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.gap-8 {
    gap: 8px;
  }

  &.gap-40 {
    gap: 40px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;

  &.gap-4 {
    gap: 4px;
  }
`;

const FooterButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const VerificationCodeTitle = styled.p`
  ${typo.b7m};
  color: ${colorSet.gray2};
  width: 100%;

  strong {
    color: ${colorSet.red2};
  }
`;
