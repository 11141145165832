import styled from "styled-components";
import { ReactComponent as NotFoundSvg } from "@/src/assets/icons/icon-not-found.svg";
import Typo from "../../atom/Typo";
import { useTranslation } from "react-i18next";

function UnauthorizedDescription() {
  const { t } = useTranslation();

  return (
    <FlexColumnCenter>
      <NotFoundIcon />

      <Typo color="gray2" typoType="b4m">
        {t("alert:authorizationPageError")}
      </Typo>
    </FlexColumnCenter>
  );
}

export default UnauthorizedDescription;

const FlexColumnCenter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
  gap: 24px;
`;

const NotFoundIcon = styled(NotFoundSvg)`
  width: 283px;
  height: 172px;
`;
