import React, { useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import TabItem from "@/src/components/molecule/TabItem";
import Typo from "@/src/components/atom/Typo";
import SignatureStep from "@/src/components/organism/SignatureStep";
import Select from "@/src/components/atom/Select";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as DocumentSvg } from "@/src/assets/icons/icon-document-gary4.svg";
import { ReactComponent as PenSvg } from "@/src/assets/icons/icon-pen.svg";
import {
  useGetExporterContractSignatureHistoriesQuery,
  useLazyGetExporterPoHistoriesQuery,
  useLazyGetExporterScHistoriesQuery,
} from "@/src/store/apis/contracts/contractDetail";
import { ReactComponent as ChevronLeft } from "@/src/assets/icons/icon-chevron-left-black.svg";
import {
  ContractStatusType,
  SignatureStatusType,
} from "@/src/store/apis/contracts/interface";
import renderContractSignatureBadge from "../../utils/renderContractSignatureBadge";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import Loader from "@/src/components/atom/Loader";
import Dialog from "@/src/components/atom/Dialog";
import Flex from "@/src/components/molecule/Flex";
import { IconButton } from "@/src/components/atom/Button";
import { downloadFile } from "@/src/utils/downloadFile";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface ContractDocumentTabProps {
  tab: "po" | "sc";
  onTabChange: (tab: ContractDocumentTabProps["tab"]) => void;
  poNo?: string;
  scNo?: string;
  poVersion?: number;
  scVersion?: number;
  contractStatus: ContractStatusType;
  signatureStatus: SignatureStatusType;
  onVersionChange: ({
    type,
    version,
  }: {
    type: ContractDocumentTabProps["tab"];
    version: number;
  }) => void;
}

const EMPTY_ARRAY: [] = [];

const ContractDocumentTab = ({
  tab,
  poNo,
  scNo,
  onTabChange,
  poVersion,
  scVersion,
  contractStatus,
  signatureStatus,
  onVersionChange,
}: ContractDocumentTabProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const [rejectReason, setRejectReason] = useState<string | undefined>(
    undefined
  );
  const [pageNum, setPageNum] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { histories } = useGetExporterContractSignatureHistoriesQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;

        return {
          histories: isStable ? currentData.rows : EMPTY_ARRAY,
          isPoReady: currentData?.rows[0].isImporterSignedPO,
        };
      },
    }
  );
  const [
    getExporterPoHistories,
    { isPoHistoryError, poHistory, isPoHitoryFetching },
  ] = useLazyGetExporterPoHistoriesQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      return {
        isPoHistoryError: isError,
        isPoHitoryFetching: isFetching,
        poHistory: currentData,
      };
    },
  });
  const [
    getExporterScHistories,
    { isScHistoryError, isScHitoryFetching, scHistory },
  ] = useLazyGetExporterScHistoriesQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      return {
        isScHistoryError: isError,
        isScHitoryFetching: isFetching,
        scHistory: currentData,
      };
    },
  });

  useEffect(() => {
    (async () => {
      await getExporterPoHistories({
        contractId: Number(params.id),
      });
      await getExporterScHistories({
        contractId: Number(params.id),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDialog = () => {
    if (!rejectReason) return null;

    return (
      <Dialog
        open
        width={496}
        title="반려 사유 확인"
        onOpenChange={() => setRejectReason(undefined)}
      >
        <PreWrap>
          <Typo typoType="b7r" color="gray2">
            {rejectReason}
          </Typo>
        </PreWrap>
      </Dialog>
    );
  };

  return (
    <>
      <FlexSpaceBetween>
        <FlexRow role="tablist">
          <StyeldBlueTabItem
            tabValue={"po"}
            disabled={contractStatus === "NOT_LINKED" || isPoHistoryError}
            tabIndex={tab === "po" ? 0 : -1}
            data-selected={tab === "po"}
            onClick={() => onTabChange("po")}
          >
            PO
          </StyeldBlueTabItem>
          <StyeldBlueTabItem
            tabValue={"sc"}
            tabIndex={tab === "sc" ? 0 : -1}
            data-selected={tab === "sc"}
            onClick={() => onTabChange("sc")}
            disabled={isScHistoryError}
          >
            SC
          </StyeldBlueTabItem>
        </FlexRow>

        <FlexRow flexGap={8}>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            disabled={
              tab === "po"
                ? isPoHistoryError || isPoHitoryFetching
                : isScHistoryError || isScHitoryFetching
            }
            onClick={() => {
              downloadFile(
                (tab === "po" ? poHistory : scHistory)?.rows.find(
                  ({ id }) => (tab === "po" ? poVersion : scVersion) === id
                )?.mediaFileUrl ?? "",
                tab === "po" ? `po_${poVersion}.pdf` : `sc_${scVersion}.pdf`
              );
            }}
          >
            <PdfDownloadIcon
              disabled={
                tab === "po"
                  ? isPoHistoryError || isPoHitoryFetching
                  : isScHistoryError || isScHitoryFetching
              }
            />
          </IconButton>

          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            disabled={
              tab === "po"
                ? isPoHistoryError || isPoHitoryFetching
                : isScHistoryError || isScHitoryFetching
            }
            onClick={() => {
              window.open(
                (tab === "po" ? poHistory : scHistory)?.rows.find(
                  ({ id }) => (tab === "po" ? poVersion : scVersion) === id
                )?.mediaFileUrl ?? "",
                "_blank"
              );
            }}
          >
            <PdfPreviewIcon
              disabled={
                tab === "po"
                  ? isPoHistoryError || isPoHitoryFetching
                  : isScHistoryError || isScHitoryFetching
              }
            />
          </IconButton>
        </FlexRow>
      </FlexSpaceBetween>

      <FlexSpaceBetween>
        <FlexRow flexGap={8}>
          <Typo typoType="h4">
            {tab === "po" ? `PO No. ${poNo}` : `SC No. ${scNo}`}
          </Typo>
          {renderContractSignatureBadge({
            t,
            type: signatureStatus,
          })}
        </FlexRow>
        <FlexRow flexGap={8}>
          <Typo typoType="b10r">
            {t("contract:exporter.detail.label.versionInformation")}
          </Typo>

          {tab === "po" ? (
            <StyledSelect
              size="middle"
              options={(poHistory?.rows || []).map(
                ({ id, version, createdAt }) => {
                  return {
                    value: id,
                    label: `Ver. ${version} (${dayjs(createdAt).format(
                      DATE_FORMAT_STRINGS.YY_MM_DD
                    )})`,
                  };
                }
              )}
              value={poVersion}
              suffixIcon={isPoHitoryFetching ? <Loader /> : undefined}
              disabled={isPoHistoryError}
              onChange={(version) => onVersionChange({ type: "po", version })}
            />
          ) : (
            <StyledSelect
              size="middle"
              options={(scHistory?.rows || []).map(
                ({ id, version, createdAt }) => {
                  return {
                    value: id,
                    label: `Ver. ${version} (${dayjs(createdAt).format(
                      DATE_FORMAT_STRINGS.YY_MM_DD
                    )})`,
                  };
                }
              )}
              value={scVersion}
              suffixIcon={isScHitoryFetching ? <Loader /> : undefined}
              disabled={isScHistoryError}
              onChange={(version) => onVersionChange({ type: "sc", version })}
            />
          )}
        </FlexRow>
      </FlexSpaceBetween>

      <FlexRowStart>
        <DocumentSection>
          {tab === "sc" ? (
            <Flex justifyContent="space-between" alignItems="center" gap={40}>
              {scHistory?.rows.find(({ id }) => scVersion === id)
                ?.mediaFileUrl ? (
                <>
                  {maxPage > 1 && (
                    <IconBackground
                      onClick={() => {
                        if (pageNum !== 1) {
                          setPageNum((prev) => prev - 1);
                        }
                      }}
                      isActive={pageNum !== 1}
                    >
                      <ChevronLeftIcon isActive={pageNum !== 1} />
                    </IconBackground>
                  )}
                  <Document
                    file={
                      scHistory?.rows.find(({ id }) => scVersion === id)
                        ?.mediaFileUrl
                    }
                    onLoadSuccess={({ numPages }) => setMaxPage(numPages)}
                  >
                    <Page pageNumber={pageNum} renderTextLayer={false} />
                  </Document>
                  {maxPage > 1 && (
                    <IconBackground
                      onClick={() => {
                        if (pageNum !== maxPage) {
                          setPageNum((prev) => prev + 1);
                        }
                      }}
                      isActive={pageNum !== maxPage}
                    >
                      <ChevronLeftIcon
                        isActive={pageNum !== maxPage}
                        $rotateDeg={180}
                      />
                    </IconBackground>
                  )}
                </>
              ) : (
                <FlexColumnCenter>
                  <DocumentIcon />
                  <Typo typoType="b7m" color="gray8">
                    No Contract
                  </Typo>
                </FlexColumnCenter>
              )}
            </Flex>
          ) : (
            <Flex justifyContent="space-between" alignItems="center" gap={40}>
              {poHistory?.rows.find(({ id }) => poVersion === id)
                ?.mediaFileUrl ? (
                <>
                  {maxPage > 1 && (
                    <IconBackground
                      onClick={() => {
                        if (pageNum !== 1) {
                          setPageNum((prev) => prev - 1);
                        }
                      }}
                      isActive={pageNum !== 1}
                    >
                      <ChevronLeftIcon isActive={pageNum !== 1} />
                    </IconBackground>
                  )}
                  <Document
                    file={
                      poHistory?.rows.find(({ id }) => poVersion === id)
                        ?.mediaFileUrl
                    }
                    onLoadSuccess={({ numPages }) => setMaxPage(numPages)}
                  >
                    <Page pageNumber={pageNum} renderTextLayer={false} />
                  </Document>
                  {maxPage > 1 && (
                    <IconBackground
                      onClick={() => {
                        if (pageNum !== maxPage) {
                          setPageNum((prev) => prev + 1);
                        }
                      }}
                      isActive={pageNum !== maxPage}
                    >
                      <ChevronLeftIcon
                        isActive={pageNum !== maxPage}
                        $rotateDeg={180}
                      />
                    </IconBackground>
                  )}
                </>
              ) : (
                <FlexColumnCenter>
                  <DocumentIcon />
                  <Typo typoType="b7m" color="gray8">
                    No Contract
                  </Typo>
                </FlexColumnCenter>
              )}
            </Flex>
          )}
        </DocumentSection>

        <SignatureStepSection>
          <FlexRow flexGap={4}>
            <PenSvg />
            <Typo typoType="h6">
              {t("contract:exporter.detail.label.signature")}
            </Typo>
          </FlexRow>

          <Divider />

          <StyledSignatureStep
            signStep={histories
              .slice()
              .reverse()
              .map(
                ({ createdAt, editorName, signatureStatus, rejectReason }) => ({
                  type: signatureStatus === "PENDING" ? "error" : "done",
                  at: dayjs(createdAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD_HH_mm
                  ),
                  who: editorName,
                  stepName: signatureStatus,
                  onErrorClick:
                    signatureStatus === "PENDING"
                      ? () => setRejectReason(rejectReason)
                      : undefined,
                })
              )}
          />
        </SignatureStepSection>
      </FlexRowStart>
      {renderDialog()}
    </>
  );
};

export default ContractDocumentTab;

const PdfPreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 20px;
  height: 20px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PdfDownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 20px;
  height: 20px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const StyeldBlueTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  background: ${colorSet.gray11};
  color: ${colorSet.gray7};
  border: 1px solid ${colorSet.gray9};
  ${typo.b9m};

  &:disabled {
    cursor: not-allowed;
  }

  &[data-selected="true"] {
    background: ${colorSet.white};
    color: ${colorSet.blue4};
    border: 1px solid ${colorSet.blue4};
  }

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;

const FlexRow = styled.div<{ flexGap?: number }>`
  display: flex;
  align-items: center;
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
`;

const FlexRowStart = styled(FlexRow)`
  align-items: flex-start;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DocumentSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 964px;
  background: ${colorSet.gray5};
`;

const SignatureStepSection = styled.section`
  width: 300px;
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const StyledSignatureStep = styled(SignatureStep)`
  padding: 0 16px;
  height: 477px;
  overflow: auto;
`;

const Divider = styled.div`
  width: 100%;
  background: ${colorSet.gray9};
  height: 1px;
  margin: 8px 0 24px;
`;

const DocumentIcon = styled(DocumentSvg)`
  width: 56px;
  height: 56px;

  path {
    fill: ${colorSet.gray8};
  }
`;

const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledSelect = styled(Select)`
  width: 240px;
`;

const PreWrap = styled.div`
  white-space: pre-wrap;
`;

const IconBackground = styled.div<{ isActive: boolean }>`
  background: #00000033;
  padding: 8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isActive }) =>
    isActive
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: not-allowed;
        `};
`;

const ChevronLeftIcon = styled(ChevronLeft)<{
  isActive: boolean;
  $rotateDeg?: number;
}>`
  ${({ $rotateDeg }) =>
    $rotateDeg &&
    css`
      transform: rotate(${$rotateDeg}deg);
    `};

  path {
    ${({ isActive }) =>
      isActive
        ? css`
            fill: white;
          `
        : css`
            fill: #abc4d7;
          `};
  }
`;
