import { apiSlice } from "../baseQuery";
import { ContractDto } from "../contracts/contractDetail/interface";
import { Rows } from "../type";

const sharingApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSharingContractViaBooking: build.query<
      Rows<ContractDto>,
      { bookingId?: number }
    >({
      query: ({ bookingId }) => ({
        url: "/sharing/booking/contracts",
        params: {
          bookingId,
        },
      }),
    }),
    getSharingContractViaTask: build.query<
      Rows<ContractDto>,
      { taskId?: number }
    >({
      query: ({ taskId }) => ({
        url: "/sharing/task/contracts",
        params: {
          taskId,
        },
      }),
    }),
    getSharingContractViaShipment: build.query<
      Rows<ContractDto>,
      { shipmentId?: number }
    >({
      query: ({ shipmentId }) => ({
        url: "/sharing/shipment/contracts",
        params: {
          shipmentId,
        },
      }),
    }),

    shareBooking: build.mutation<void, { bookingId: number }>({
      query: ({ bookingId }) => ({
        url: "/sharing/booking",
        body: { bookingId },
        method: "POST",
      }),
    }),
    shareLoading: build.mutation<void, { taskId: number }>({
      query: ({ taskId }) => ({
        url: "/sharing/task",
        body: { taskId },
        method: "POST",
      }),
    }),
    shareShipment: build.mutation<void, { shipmentId: number }>({
      query: ({ shipmentId }) => ({
        url: "/sharing/shipment",
        body: { shipmentId },
        method: "POST",
      }),
    }),
    shareCiPl: build.mutation<void, { shipmentId: number }>({
      query: ({ shipmentId }) => ({
        url: "sharing/ci-pl",
        body: { shipmentId },
        method: "POST",
      }),
    }),
    shareBl: build.mutation<void, { shipmentId: number }>({
      query: ({ shipmentId }) => ({
        url: "sharing/bl",
        body: { shipmentId },
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSharingContractViaBookingQuery,
  useGetSharingContractViaShipmentQuery,
  useGetSharingContractViaTaskQuery,

  useShareBookingMutation,
  useShareLoadingMutation,
  useShareShipmentMutation,
  useShareCiPlMutation,
  useShareBlMutation,
} = sharingApi;

export { sharingApi };
