const ERROR_CODE: string[] = [
  "EXPORTER_USER_EXPORTER_INACTIVATED",
  "ACCOUNT_HISTORY_NOT_FOUND_TOKEN",
  "ACCOUNT_HISTORY_EXPIRED_TOKEN",
  "ACCOUNT_NOT_FOUND_TOKEN",
  "ACCOUNT_INACTIVATED_ACCOUNT",
  "ACCOUNT_LOCKED_ACCOUNT",
  "EXPORTER_USER_EXPORTER_INACTIVATED",
];

export default ERROR_CODE;
