import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import colorSet from "@/src/styles/color";
import { css, styled } from "styled-components";
import DropDown from "@/src/components/atom/DropDown";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import Notification from "@/src/components/organism/Notification";
import { Button } from "@/src/components/atom/Button";
import { useAppDispatch, useAppSelector } from "@/src/store";
import typo from "@/src/styles/typography";
import UserSvg from "@/src/assets/icons/icon-my-page.svg";
import ChevronDownSvg from "@/src/assets/icons/icon-chevron-down-indigo.svg";
import LockSvg from "@/src/assets/icons/icon-lock.svg";
import LogoutSvg from "@/src/assets/icons/icon-logout-black.svg";
import { ReactComponent as BellSvg } from "@/src/assets/icons/icon-bell.svg";
import { useSignOutMutation } from "@/src/store/apis/auth";
import useAlert from "@/src/hooks/useAlert";
import { persistor } from "@/src/index";
import { useNavigate } from "react-router-dom";
import { StyledScroll } from "@/src/styles/scroll";
import { pushAlarmsApi } from "@/src/store/apis/pushAlarms";
import { isUndefined } from "@/src/utils/is";
import { closeNotification, openNotification } from "@/src/store/slice/webUtil";
import { currencyApi } from "@/src/store/apis/currency";
import { apiSlice } from "@/src/store/apis/baseQuery";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import {
  DropdownMenuContentProps,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import AlertDialog from "@/src/components/atom/AlertDialog";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useTranslation } from "react-i18next";

export const MAIN_CONTENT_MAIN_ID = "layout-main";

interface MainContentLayoutProps {
  breadcrumb?: string[];
  pageTitle?: ReactNode;
  /**
   * @description Page Title을 커스텀 하고 싶을 때 사용
   */
  customPageTitle?: ReactNode;
  sideAccessory?: ReactNode;
  useInnerScroll?: boolean;
}

const notificationDropdownContentProps: DropdownMenuContentProps = {
  collisionPadding: { right: 40 },
  sideOffset: 10,
};

const AccountDropdownContentProps: DropdownMenuContentProps = {
  align: "end",
  sideOffset: 10,
};

enum DialogState {
  NULL,
  PLAN_SIGNUP,
  NO_PERMISSION,
}

function ImporterMainContentLayout({
  children,
  breadcrumb,
  pageTitle,
  customPageTitle,
  sideAccessory,
  useInnerScroll,
}: PropsWithChildren<MainContentLayoutProps>) {
  const { t } = useTranslation();
  const mainRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const isNotificationOpen = useAppSelector(
    (state) => state.webUtil.isNotificationOpen
  );
  const contentLoading = useAppSelector(
    (state) => state.webUtil.contentLoading
  );
  const { isEnterpriseInquiries } = useAppSelector(
    (state) => state.subscription
  );

  const alert = useAlert();
  const navigate = useNavigate();

  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isScroll, setIsScroll] = useState(false);
  const { count } = pushAlarmsApi.endpoints.getAllPushAlarms.useQueryState(
    { services: ["IMPORT_WEB", "COMMON_WEB"] },
    {
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;

        return {
          count: !isUnstable ? currentData?.count : 0,
        };
      },
    }
  );

  const [signOut] = useSignOutMutation();

  const handleClientControlClick = () => {
    const isCorporateManager = user?.exporterUserType === "CORPORATE_MANAGER";
    const isManager = !isCorporateManager;

    // 기업 타입 BOTH
    if (user?.exporter.companyType === "BOTH") {
      // 기업관리자, 개인 타입 BOTH
      if (isCorporateManager || user?.exporterUserMainFieldType === "BOTH") {
        return navigate(EXPORTER_PRIVATE_PATH.HOME);
      }

      // 개인 타입 BUYER, 중간관리자, 일반매니저
      if (isManager && user?.exporterUserMainFieldType === "BUYER") {
        return setDialogState(DialogState.NO_PERMISSION);
      }
    }

    // Importer 기업 타입
    if (user?.exporter.companyType === "BUYER") {
      // 중간 관리자, 일반 매니저
      if (isManager) {
        return setDialogState(DialogState.NO_PERMISSION);
      }

      // 기업관리자
      if (isCorporateManager) {
        // 문의를 했는지?
        if (isEnterpriseInquiries) {
          navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP);
        }

        return setDialogState(DialogState.PLAN_SIGNUP);
      }
    }
  };

  const renderPageTitle = () => {
    if (customPageTitle) {
      return customPageTitle;
    }

    if (pageTitle) {
      return (
        <PageTitle as="h1" color="gray1">
          {pageTitle}
        </PageTitle>
      );
    }
  };

  const logout = async () => {
    try {
      await signOut().unwrap;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      persistor.purge();
      dispatch(currencyApi.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());
      navigate(PUBLIC_PATH.LOGIN);
    }
  };

  useEffect(() => {
    if (mainRef.current) {
      let lastScroll = document.documentElement.scrollTop || 0;

      mainRef.current.addEventListener("scroll", function () {
        let scrollTop = mainRef.current?.scrollTop as number;
        if (scrollTop === 0) {
          setIsScroll(false);
        }

        if (scrollTop > lastScroll) {
          setIsScroll(true);
        }
        lastScroll = scrollTop;
      });
    }
  }, []);

  const renderClientTypeController = () => {
    const isManager = user?.exporterUserType === "MANAGER";
    const corporate = user?.exporter;
    const getIsLock = () => {
      switch (isManager) {
        case true:
          return user?.exporterUserMainFieldType !== "BOTH";
        case false:
          return corporate?.companyType !== "BOTH";
      }
    };
    const isLock = getIsLock();

    return (
      <>
        <ClientSwitchPanel>
          <ClientSwitch
            aria-selected={false}
            data-selected={false}
            onClick={handleClientControlClick}
          >
            {isLock && <Icon iconSrc={LockSvg} iconSize={14} />}
            Exporter
          </ClientSwitch>
          <ClientSwitch aria-selected data-selected>
            Importer
          </ClientSwitch>
        </ClientSwitchPanel>
      </>
    );
  };

  const renderAccountDropdown = () => {
    const menu = [
      {
        icon: UserSvg,
        label: "My Page",
        onSelect: () => navigate(IMPORTER_PRIVATE_PATH.MY_PAGE),
      },
      {
        icon: LogoutSvg,
        label: "Logout",
        onSelect: logout,
      },
    ];

    return (
      <>
        {menu.map(({ icon, label, onSelect }) => (
          <AccountDropDownItem onSelect={onSelect}>
            <Icon iconSrc={icon} iconSize={18} />
            <Typo typoType="b9r">{label}</Typo>
          </AccountDropDownItem>
        ))}
      </>
    );
  };

  const renderAlertDialog = () => {
    // Export 수출자 기능 안내
    if (dialogState === DialogState.PLAN_SIGNUP) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.exportFeaturesGuide")}
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
          }}
          cancelText={t("subscriptionManagement:subscription.button.cancel")}
          okText={t("subscriptionManagement:subscription.button.ok")}
          onOk={() => {
            navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP);
          }}
        >
          {t(
            "subscriptionManagement:subscription.exportFeaturesGuideDescription"
          )}
        </AlertDialog>
      );
    }

    // 권한 없음
    if (dialogState === DialogState.NO_PERMISSION) {
      return (
        <AlertDialog
          title={t("subscriptionManagement:subscription.noAuthority")}
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
          }}
          okText={t("subscriptionManagement:subscription.button.ok")}
          cancelText={null}
          onOk={() => {
            setDialogState(DialogState.NULL);
          }}
        >
          {t("subscriptionManagement:subscription.noAuthorityDescription")}
        </AlertDialog>
      );
    }
  };

  return (
    <MainContentLayoutContainer useInnerScroll={useInnerScroll}>
      <BreadcrumbContainer $isScroll={isScroll}>
        <BreadCrumb>
          {breadcrumb?.map((text, idx, origin) => {
            if (idx === origin.length - 1) {
              return (
                <li key={idx.toString()}>
                  <Typo typoType="b10r" color="gray6">
                    {text}
                  </Typo>
                </li>
              );
            }

            return (
              <li key={idx.toString()} className="breadCrumb-text">
                <Typo typoType="b10r" color="gray7">
                  {text}
                </Typo>
              </li>
            );
          })}
        </BreadCrumb>

        <UserControllerContainer>
          {/* 수입자 / 수출자 컨트롤러 */}
          {renderClientTypeController()}

          {/* Notification */}
          <DropDown
            open={isNotificationOpen}
            onOpenChange={() => {
              if (isNotificationOpen) {
                return dispatch(closeNotification());
              } else {
                dispatch(openNotification());
              }
            }}
            dropdownContentProps={notificationDropdownContentProps}
            dropdownTrigger={
              <NotificationContainer>
                <BellIcon />

                {count !== 0 && (
                  <NotificationRedCircle>
                    <Typo typoType="btn5m" color="white">
                      {count}
                    </Typo>
                  </NotificationRedCircle>
                )}
              </NotificationContainer>
            }
          >
            <Notification
              type="import"
              services={["IMPORT_WEB", "COMMON_WEB"]}
              onClose={() => {
                dispatch(closeNotification());
              }}
            />
          </DropDown>
          {/* Account Dropdown */}
          <DropDown
            dropdownContentProps={AccountDropdownContentProps}
            dropdownTrigger={
              <StyledButton
                buttonSize={24}
                buttonColor="black"
                buttonGrade="tertiary"
              >
                <Typo typoType="btn5m">{user?.name}</Typo>
                <Icon iconSrc={ChevronDownSvg} iconSize={18} />
              </StyledButton>
            }
          >
            <StyledSectionCardWithoutHeader>
              <AccountDropDownList>
                {renderAccountDropdown()}
              </AccountDropDownList>
            </StyledSectionCardWithoutHeader>
          </DropDown>
        </UserControllerContainer>
      </BreadcrumbContainer>

      <Flex useInnerScroll={useInnerScroll}>
        {sideAccessory}

        {/* Main Content */}
        <MainContent
          ref={mainRef}
          $overflowHidden={contentLoading}
          id={MAIN_CONTENT_MAIN_ID}
          $hasSidePadding={!!sideAccessory}
        >
          {renderPageTitle()}
          {children}
        </MainContent>
      </Flex>

      {renderAlertDialog()}
    </MainContentLayoutContainer>
  );
}

export default ImporterMainContentLayout;

const Flex = styled.div<{ useInnerScroll?: boolean }>`
  display: flex;
  flex: 1;
  overflow: auto;

  ${({ useInnerScroll }) =>
    useInnerScroll &&
    css`
      overflow: auto;
    `};
`;

const MainContentLayoutContainer = styled.main<{ useInnerScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  background: ${colorSet.white};
  width: calc(100% - 256px);
  ${({ useInnerScroll }) =>
    useInnerScroll &&
    css`
      overflow: auto;
    `};
`;

const BreadcrumbContainer = styled.section<{ $isScroll: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ $isScroll }) => ($isScroll ? "10px 40px" : "32px 40px 10px")};
  border-bottom: 1px solid ${colorSet.gray10};
  background: ${colorSet.white};
  transition: 0.3s;
`;

const BreadCrumb = styled.ul`
  display: flex;
  align-items: center;
  gap: 2px;
  min-height: 20px;

  .breadCrumb-text {
    display: flex;
    align-items: center;
    gap: 2px;

    &::after {
      color: ${colorSet.gray7};
      padding: 0 2px;
      content: ">";
    }

    &:last-child::after {
      content: none;
    }
  }
`;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const UserControllerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px 4px 12px;
`;

const MainContent = styled.article<{
  $overflowHidden: boolean;
  $hasSidePadding: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ $hasSidePadding }) =>
    $hasSidePadding
      ? css`
          padding: 0 40px 24px 16px;
        `
      : css`
          padding: 0px 40px 24px 40px;
        `};
  width: 100%;
  margin: 0 auto;
  overflow: ${({ $overflowHidden }) => ($overflowHidden ? "hidden" : "auto")};
  flex: 1;
  position: relative;
  background: ${colorSet.white};

  ${StyledScroll}
`;

const NotificationContainer = styled.div`
  display: flex;
  position: relative;
`;

const BellIcon = styled(BellSvg)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const NotificationRedCircle = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: ${colorSet.red2};
  outline: 1px solid ${colorSet.white};
  cursor: pointer;
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
  width: 180px;
`;

const AccountDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;

const AccountDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }
`;

const ClientSwitchPanel = styled.div`
  background: ${colorSet.gray11};
  border: 1px solid ${colorSet.gray9};
  border-radius: 6px;
  width: 136px;
  display: flex;
`;

const ClientSwitch = styled.button`
  width: 50%;
  border: none;
  border-radius: 6px;
  padding: 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  &[data-selected="true"] {
    ${typo.b11m};
    background: ${colorSet.indigo};
    color: ${colorSet.white};
  }

  &[data-selected="false"] {
    ${typo.b11r};
    background: transparent;
    color: ${colorSet.gray7};
  }
`;
