import Badge from "@/src/components/atom/Badge";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import {
  CELL_TYPE_EDIT_ACTION,
  CELL_TYPE_DELETE_ACTION,
} from "@/src/utils/row-data-util";
import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";

export const warehouseListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "warehouseName",
    flex: 1,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "tel",
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.tel && params.data.telPrefix ? (
        <Typo typoType="b9r">
          {params.data.telPrefix} {params.data.tel}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "countryCodeItemName",
    cellStyle: { textAlign: "center" },
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      const countryName =
        params.data.lang === "en"
          ? params.data.country.codeItemNameEn
          : params.data.country.codeItemNameKo;

      return params.data.country ? (
        <Typo typoType="b9r">
          {params.data.country.codeItemName}){countryName}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    field: "mainCategory",
    headerName: "Category in Charge",
    cellStyle: { textAlign: "center" },
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const mainCategories = params.data.mainCategories.map((item: any) => {
        return item.codeItemNameEn;
      });

      return params.data.country ? (
        <Typo typoType="b9r">{mainCategories.join(", ")}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    field: "isActivated",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    width: 130,
    resizable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
];

export const warehouseContactPersonListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isFullContact =
        params.data.personalContactPrefix && params.data.personalContact;

      return isFullContact ? (
        <>
          {params.data.personalContactPrefix} {params.data.personalContact}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "officeContact",
    cellStyle: { textAlign: "center" },
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isFullContact =
        params.data.officeContact && params.data.officeContactPrefix;

      return isFullContact ? (
        <>
          {params.data.officeContactPrefix} {params.data.officeContact}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    lockPosition: "right",
    field: "isActivated",
    width: 130,
    resizable: false,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
];

export const editWarehouseContactPersonListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    resizable: false,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isFullContact =
        params.data.personalContactPrefix && params.data.personalContact;

      return isFullContact ? (
        <>
          {params.data.personalContactPrefix} {params.data.personalContact}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    field: "officeContact",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isFullContact =
        params.data.officeContact && params.data.officeContactPrefix;

      return isFullContact ? (
        <>
          {params.data.officeContactPrefix} {params.data.officeContact}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    lockPosition: "right",
    field: "isActivated",
    width: 130,
    resizable: false,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    lockPosition: "right",
    field: "edit",
    width: 130,
    resizable: false,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_ACTION,
  },
];

export const addFactoryContactPersonListColumn: ColDef[] = [
  ...editWarehouseContactPersonListColumn.map((item) => ({
    ...item,
    sortable: false,
  })),
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    lockPosition: "right",
    field: "delete",
    width: 130,
    resizable: false,
    sortable: false,
    cellRenderer: CELL_TYPE_DELETE_ACTION,
  },
];
