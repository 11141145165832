import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  BookingProgressDto,
  BookingProgressContractDto,
  BookingProgressContainerInfoDto,
  GetImporterShipmentProgressRequest,
  ShipmentDetailDocumentAndMemoViewDto,
} from "./interface";

const importerShipmentListApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShipmentByBl: build.query<
      Rows<BookingProgressDto>,
      GetImporterShipmentProgressRequest
    >({
      query: (params) => ({
        url: "/importers/this/shipment-progress",
        params,
      }),
    }),
    getShipmentByBooking: build.query<
      Rows<BookingProgressDto>,
      GetImporterShipmentProgressRequest
    >({
      query: (params) => ({
        url: "/importers/this/booking-progress",
        params,
      }),
    }),
    getImminentShipment: build.query<Rows<BookingProgressDto>, void>({
      query: () => ({
        url: "/importers/this/imminent-shipments",
      }),
    }),
    getBookingShipmentContainer: build.query<
      Rows<{ id: number; blNo?: string }>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/bookings/${id}/shipments`,
      }),
    }),
    getBookingShipmentContainerInformation: build.query<
      Rows<BookingProgressContainerInfoDto>,
      { id: number; shipmentIdList?: string; page?: number; pageSize?: number }
    >({
      query: ({ id, shipmentIdList, page, pageSize }) => ({
        url: `/importers/this/bookings/${id}/container-information`,
        params: {
          shipmentIdList,
          page,
          pageSize,
        },
      }),
    }),
    getBookingShipmentContainerIntegrationInfo: build.query<
      Row<{
        totalGrossWeight: string;
        totalNumberOfContainer: number;
        totalUnitQuantity: string;
      }>,
      { id: number; shipmentIdList?: string }
    >({
      query: ({ id, shipmentIdList }) => ({
        url: `/importers/this/bookings/${id}/container-integration-info`,
        params: {
          shipmentIdList,
        },
      }),
    }),
    getBookingShipmentDocument: build.query<
      Rows<ShipmentDetailDocumentAndMemoViewDto>,
      { id: number; shipmentIdList?: string }
    >({
      query: ({ id, shipmentIdList }) => ({
        url: `/importers/this/bookings/${id}/shipments/document`,
        params: {
          shipmentIdList,
        },
      }),
    }),
    getBookingContracts: build.query<
      Rows<BookingProgressContractDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/bookings/${id}/contracts`,
      }),
    }),

    getImporterBookingProgressExcelList: build.query<
      void,
      GetImporterShipmentProgressRequest
    >({
      query: (params) => ({
        url: "/importers/this/booking-progress-excel",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "booking-progress.xlsx",
          });
        },
      }),
    }),

    getImporterShipmentProgressExcelList: build.query<
      void,
      GetImporterShipmentProgressRequest
    >({
      query: (params) => ({
        url: "/importers/this/shipment-progress-excel",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "shipment-progress.xlsx",
          });
        },
      }),
    }),
  }),
});

export const {
  useLazyGetShipmentByBlQuery,
  useLazyGetShipmentByBookingQuery,
  useGetImminentShipmentQuery,
  useGetBookingShipmentContainerQuery,
  useLazyGetBookingShipmentContainerInformationQuery,
  useLazyGetBookingShipmentContainerIntegrationInfoQuery,
  useLazyGetBookingShipmentDocumentQuery,
  useGetBookingContractsQuery,
  useLazyGetImporterBookingProgressExcelListQuery,
  useLazyGetImporterShipmentProgressExcelListQuery,
} = importerShipmentListApi;

export { importerShipmentListApi };
