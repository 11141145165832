import { RouteObject } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "./path";
import ProtectedExportPrivateRoute from "@/src/components/template/ProtectedExportPrivateRoute";
import ExporterBookingPage from "@/src/pages/exporter/Booking";
import ExporterBookingDetailPage from "@/src/pages/exporter/Booking/detail";
import ExporterBookingAddPage from "@/src/pages/exporter/Booking/add";
import ExporterBookingEditPage from "@/src/pages/exporter/Booking/edit";
import ExporterClientManagementAddPage from "@/src/pages/exporter/ClientManagement/add";
import ExporterClientManagementPage from "@/src/pages/exporter/ClientManagement";
import ExporterClientManagementDetailPage from "@/src/pages/exporter/ClientManagement/detail";
import ExporterClientManagementEditPage from "@/src/pages/exporter/ClientManagement/edit";
import ExporterCompanyInformationManagement from "@/src/pages/exporter/CompanyInformationManagement";
import ExporterContractAddPage from "@/src/pages/exporter/Contract/add";
import ExporterContractPage from "@/src/pages/exporter/Contract";
import ExporterContractDetailPage from "@/src/pages/exporter/Contract/detail";
import ExporterContractEditPage from "@/src/pages/exporter/Contract/edit";
import ExporterDashboardPage from "@/src/pages/exporter/Dashboard";
import ExporterFactoryManagementPage from "@/src/pages/exporter/FactoryManagement";
import ExporterFactoryManagementAddPage from "@/src/pages/exporter/FactoryManagement/add";
import ExporterFactoryManagementDetailPage from "@/src/pages/exporter/FactoryManagement/detail";
import ExporterFactoryManagementEditPage from "@/src/pages/exporter/FactoryManagement/edit";
import ExporterFaqPage from "@/src/pages/exporter/Faq";
import ExporterInquiryPage from "@/src/pages/exporter/Inquiry";
import ExporterInquiryAddPage from "@/src/pages/exporter/Inquiry/add";
import ExporterInquiryDetailPage from "@/src/pages/exporter/Inquiry/detail";
import ExporterItemManagementPage from "@/src/pages/exporter/ItemManagement";
import ExporterMypage from "@/src/pages/exporter/Mypage";
import ExporterMypageEditPage from "@/src/pages/exporter/Mypage/edit";
import ExporterNotFoundPage from "@/src/pages/exporter/NotFound";
import ExporterNoticePage from "@/src/pages/exporter/Notice";
import ExporterNoticeDetailPage from "@/src/pages/exporter/Notice/detail";
import ExporterShipmentPage from "@/src/pages/exporter/Shipment";
import ExporterShipmentAddPage from "@/src/pages/exporter/Shipment/add";
import ExporterAddCiPage from "@/src/pages/exporter/Shipment/addCi";
import ExporterAddPlPage from "@/src/pages/exporter/Shipment/addPl";
import ExporterShipmentDetailPage from "@/src/pages/exporter/Shipment/detail";
import ExporterEditCiPage from "@/src/pages/exporter/Shipment/editCi";
import ExporterEditPlPage from "@/src/pages/exporter/Shipment/editPl";
import ExporterStaffManagementPage from "@/src/pages/exporter/StaffManagement";
import ExporterTaskPage from "@/src/pages/exporter/Task";
import ExporterTaskAddPage from "@/src/pages/exporter/Task/add";
import ExporterTaskDetailPage from "@/src/pages/exporter/Task/detail";
import ExporterTaskEditPage from "@/src/pages/exporter/Task/edit";
import SubscriptionLandingPage from "@/src/pages/exporter/SubscriptionLanding";
import NormalPlanSignupPage from "@/src/pages/exporter/NormalPlanSignup";
import EnterpriseSignupPage from "@/src/pages/exporter/EnterpriseSignup";
import SubscriptionManagementPage from "@/src/pages/exporter/SubscriptionManagement";
import SubscriptionAnnuallyTerminationPage from "@/src/pages/exporter/SubscriptionAnnuallyTermination";
import ImporterFileBoxPage from "@/src/pages/exporter/FileBox";

const exporterPrivateRoutes: RouteObject[] = [
  {
    path: EXPORTER_PRIVATE_PATH.ROOT,
    element: <ProtectedExportPrivateRoute />,
    errorElement: <ExporterNotFoundPage />,
    children: [
      // Service operation
      {
        path: EXPORTER_PRIVATE_PATH.HOME,
        element: <ExporterDashboardPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.CONTRACT,
        element: <ExporterContractPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/:id`,
        element: <ExporterContractDetailPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.CONTRACT_ADD,
        element: <ExporterContractAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.CONTRACT_EDIT}/:id`,
        element: <ExporterContractEditPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.BOOKING,
        element: <ExporterBookingPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.BOOKING_DETAIL}/:id`,
        element: <ExporterBookingDetailPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.BOOKING_ADD,
        element: <ExporterBookingAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.BOOKING_EDIT}/:id`,
        element: <ExporterBookingEditPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.LOADING,
        element: <ExporterTaskPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.LOADING_ADD,
        element: <ExporterTaskAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.LOADING_EDIT}/:id`,
        element: <ExporterTaskEditPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/:id`,
        element: <ExporterTaskDetailPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.SHIPMENT,
        element: <ExporterShipmentPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.SHIPMENT_ADD,
        element: <ExporterShipmentAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/:id`,
        element: <ExporterShipmentDetailPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.SHIPMENT_ADD_CI}/:id`,
        element: <ExporterAddCiPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.SHIPMENT_ADD_PL}/:id`,
        element: <ExporterAddPlPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.SHIPMENT_EDIT_CI}/:id`,
        element: <ExporterEditCiPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.SHIPMENT_EDIT_PL}/:id`,
        element: <ExporterEditPlPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.FILE_BOX}`,
        element: <ImporterFileBoxPage />,
      },
      // Management
      {
        path: EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT,
        element: <ExporterClientManagementPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD,
        element: <ExporterClientManagementAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/:id`,
        element: <ExporterClientManagementDetailPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_EDIT}/:id`,
        element: <ExporterClientManagementEditPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT,
        element: <ExporterFactoryManagementPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_ADD,
        element: <ExporterFactoryManagementAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_DETAIL}/:id`,
        element: <ExporterFactoryManagementDetailPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.FACTORY_MANAGEMENT_EDIT}/:id`,
        element: <ExporterFactoryManagementEditPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
        element: <ExporterCompanyInformationManagement />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.ITEM_MANAGEMENT,
        element: <ExporterItemManagementPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.STAFF_MANAGEMENT,
        element: <ExporterStaffManagementPage />,
      },

      // Customer service center
      {
        path: EXPORTER_PRIVATE_PATH.NOTICE,
        element: <ExporterNoticePage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.NOTICE}/:id`,
        element: <ExporterNoticeDetailPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.INQUIRY,
        element: <ExporterInquiryPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.INQUIRY}/:id`,
        element: <ExporterInquiryDetailPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.INQUIRY_ADD}`,
        element: <ExporterInquiryAddPage />,
      },
      {
        path: `${EXPORTER_PRIVATE_PATH.INQUIRY_EDIT}/:id`,
        element: <ExporterInquiryDetailPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.FAQ,
        element: <ExporterFaqPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.MY_PAGE,
        element: <ExporterMypage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.MY_PAGE_EDIT,
        element: <ExporterMypageEditPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.NOT_FOUND,
        element: <ExporterNotFoundPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT,
        element: <SubscriptionManagementPage />,
      },
      {
        path: EXPORTER_PRIVATE_PATH.SUBSCRIPTION_ANNUALLY_TERMINATION,
        element: <SubscriptionAnnuallyTerminationPage />,
      },
    ],
  },
  {
    path: EXPORTER_PRIVATE_PATH.SUBSCRIPTION_LANDING,
    element: <SubscriptionLandingPage />,
  },
  {
    path: EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP,
    element: <NormalPlanSignupPage />,
  },
  {
    path: EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP,
    element: <EnterpriseSignupPage />,
  },
];

export default exporterPrivateRoutes;
