import CryptoJS from "crypto-js";

/**
 * @description Email Share URL query CIPHER KEY
 */
export const EMAIL_SHARE_URL_QUERY_AES_CIPHER_KEY =
  "1d6RWOfWjlZHbzkzvCjVwTzCb6HwhlQZ";

/**
 * @param encryptedString: 복호화 하고자 하는 값
 * @description 이메일 공유받은 URL내 암호화된 query를 AES256형식으로 복호화하는 함수
 * @returns AES256 복호화된 값
 */
export const aesDecrypt = (encryptedString: string): string => {
  const cipher = CryptoJS.AES.decrypt(
    encryptedString,
    CryptoJS.enc.Utf8.parse(EMAIL_SHARE_URL_QUERY_AES_CIPHER_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(""),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  return cipher.toString(CryptoJS.enc.Utf8);
};
