import { CSSProperties } from "react";
import colorSet from "@/src/styles/color";
import styled from "styled-components";
import Typo from "../../atom/Typo";
import { Button } from "../../atom/Button";
import Icon from "../../atom/Icon";
import ResetSvg from "@/src/assets/icons/icon-filter-reset.svg";
import { FilterItem } from "./types/filterItem";
import renderFilterItem from "./utils/renderFilterItem";
import typo from "@/src/styles/typography";
import { GridSize, getLabelSize } from "./utils/filterSize";
import { useAppSelector } from "@/src/store";

interface FilterProps<T> {
  filterTitle?: string;
  filterData: T;
  filterKeyList: FilterItem[];
  optionList?: { key: keyof T; option: { value: any; label: string }[] }[];

  onChange: (key: keyof T, value: any) => void;
  onReset?: () => void;
  onSubmit: () => void;
  gridTemplateColumns?: CSSProperties["gridTemplateColumns"];
}

function Filter<T extends {}>({
  filterTitle,
  filterData,
  filterKeyList,
  optionList,
  onChange,
  onReset,
  onSubmit,
  gridTemplateColumns,
}: FilterProps<T>) {
  const lang = useAppSelector((state) => state.lang.value);
  const isFilterListTypeRadioAndCheckbox = filterKeyList.some(
    (item) => item.type.includes("radio") || item.type.includes("checkbox")
  );

  if (isFilterListTypeRadioAndCheckbox) {
    const isOptionList = optionList !== undefined;

    if (!isOptionList || optionList.length === 0) {
      throw new Error(
        "type이 checkbox 또는 radio일 경우 optionList를 추가해주세요"
      );
    }
  }

  return (
    <FilterContainer
      gridTemplateColumns={gridTemplateColumns}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onSubmit();
        }
      }}
    >
      <Typo typoType="b8m" color="gray3">
        {filterTitle ?? lang === "en" ? "Search Filter" : "검색필터"}
      </Typo>

      {/* Filter List */}
      <section>
        <ul>
          {filterKeyList.map((filterItem) => {
            return (
              <Li>
                <Label size={filterItem.size ?? "small"}>
                  {filterItem.label}
                </Label>
                {renderFilterItem(filterItem, filterData, onChange, optionList)}
              </Li>
            );
          })}
        </ul>
      </section>

      {/* Button (Submit, Reset) */}
      <ButtonContainer>
        <Button
          buttonGrade="tertiary"
          buttonColor="black"
          onClick={onReset}
          buttonSize={32}
          style={{ padding: "6px" }}
        >
          <FlexCenter>
            <Icon iconSrc={ResetSvg} iconSize={20} />
          </FlexCenter>
        </Button>
        <Button
          type="submit"
          buttonColor="blue"
          buttonGrade="secondary"
          onClick={onSubmit}
          buttonSize={32}
        >
          {lang === "en" ? "Search" : "검색하기"}
        </Button>
      </ButtonContainer>
    </FilterContainer>
  );
}

export default Filter;

const FilterContainer = styled.article<{
  gridTemplateColumns: CSSProperties["gridTemplateColumns"];
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 16px;
  border: none;
  border-radius: 16px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: auto;
  flex-shrink: 0;

  & > section {
    height: auto;

    & > ul {
      height: 100%;
      display: grid;
      grid-template-columns: ${({ gridTemplateColumns }) => {
        if (gridTemplateColumns) {
          return gridTemplateColumns;
        } else {
          return "repeat(3, 1fr)";
        }
      }};
      grid-auto-rows: minmax(34px, auto);
      gap: 8px 16px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  &:nth-child(3n + 2) {
    border-left: 1px solid ${colorSet.gray9};
  }
  &:nth-child(3n + 3) {
    border-left: 1px solid ${colorSet.gray9};
  }
`;

const Label = styled.label<{ size: GridSize }>`
  color: ${colorSet.gray4};
  ${typo.b10r}
  width: ${({ size }) => getLabelSize(size)};
  flex-shrink: 0;
  padding-left: 8px;
  text-align: left;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
