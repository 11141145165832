import React from "react";
import { keyframes, styled } from "styled-components";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import { useAppSelector } from "@/src/store";
import { useLazyGetFaqsQuery } from "@/src/store/apis/faq";
import * as Accordion from "@radix-ui/react-accordion";
import colorSet from "@/src/styles/color";
import Icon from "@/src/components/atom/Icon";
import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import Pagination from "@/src/components/atom/Pagination";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { isUndefined } from "@/src/utils/is";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import TabItem from "@/src/components/molecule/TabItem";
import Typo from "@/src/components/atom/Typo";
import { useTranslation } from "react-i18next";

const emptyArray: PartialCommonCodeItemDto[] = [];

const ImporterFaqPage = () => {
  const { t } = useTranslation();

  const { value } = useAppSelector((state) => state.lang);
  const [getFaqs, faqResult] = useLazyGetFaqsQuery();

  const {
    state: {
      pagination,
      filterData: { category },
    },
    func: { onPaginationChange, onForceSearch },
  } = useFilterSearch<{ category?: string }, { category?: string }>({
    isReady: false,
    gridRef: null,
    fetch: getFaqs,
    defaultFilterData: {},
  });

  const { faqCategory } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "FAQ_CATEGORY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          faqCategory: !isUnstable ? currentData ?? emptyArray : emptyArray,
        };
      },
    }
  );

  const faqList =
    faqResult.currentData?.rows.map((row) => {
      const category = faqCategory.find(
        (item) => item.codeItemName === row.category
      );

      return {
        ...row,
        categoryEn: category?.codeItemNameEn,
        categoryKr: category?.codeItemNameKo,
      };
    }) ?? [];

  return (
    <ImporterMainLayout
      breadcrumb={[t("sideNav:faq")]}
      pageTitle={t("sideNav:faq")}
    >
      <TabContainer role="tablist">
        {faqCategory?.length > 0 && (
          <StyledTabItem
            tabValue="all"
            tabIndex={isUndefined(category) ? 0 : -1}
            onFocusItem={() => {
              onForceSearch("category", undefined);
            }}
            data-selected={isUndefined(category)}
          >
            <Typo typoType="b5m">{t("faq:tabs.all")}</Typo>
          </StyledTabItem>
        )}
        {faqCategory?.map((categoryItem) => {
          return (
            <StyledTabItem
              key={categoryItem.codeItemName}
              tabValue={categoryItem.codeItemName}
              tabIndex={category === categoryItem.codeItemName ? 0 : -1}
              onFocusItem={() => {
                onForceSearch("category", categoryItem.codeItemName);
              }}
              data-selected={category === categoryItem.codeItemName}
            >
              <Typo typoType="b5m">
                {value === "en"
                  ? categoryItem.codeItemNameEn
                  : categoryItem.codeItemNameKo}
              </Typo>
            </StyledTabItem>
          );
        })}
      </TabContainer>
      <Card>
        {faqCategory?.length > 0 && (
          <Accordion.Root type="multiple">
            {faqList.map((faq) => {
              return (
                <Item
                  key={faq.id}
                  className="AccordionItem"
                  value={faq.id.toString()}
                >
                  <Trigger>
                    <p>
                      {value === "en"
                        ? `[${faq.categoryEn || ""}] ${faq.questionEn}`
                        : `[${faq.categoryKr || ""}] ${faq.questionKr}`}
                    </p>

                    <Icon
                      iconSrc={ArrowDownSvg}
                      iconSize={24}
                      className="arrow"
                    />
                  </Trigger>
                  <Content>
                    <div
                      className="ck ck-content"
                      dangerouslySetInnerHTML={{
                        __html:
                          value === "en"
                            ? faq?.answerEn || "-"
                            : faq?.answerKr || "-",
                      }}
                    />
                  </Content>
                </Item>
              );
            })}
          </Accordion.Root>
        )}

        <PaginationContainer>
          <Pagination
            total={faqResult.currentData?.count ?? 0}
            current={pagination.page}
            pageSize={pagination.pageSize}
            onChange={(page, pageSize) => {
              onPaginationChange({ page, pageSize });
            }}
            pageSizeOptions={[10, 30, 50, 100]}
          />
        </PaginationContainer>
      </Card>
    </ImporterMainLayout>
  );
};

export default ImporterFaqPage;

const slideDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: var(--radix-accordion-content-height);
    }
`;

const slideUp = keyframes`
    0% {
        height: var(--radix-accordion-content-height);
    }
    100% {
        height: 0;
    }
`;

const Trigger = styled(Accordion.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray11};
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    transition: transform 0.3s linear;
  }

  &:focus {
    outline-offset: 1px;
    border: 2px solid ${colorSet.blue5};
    padding: 14px 14px 15px 14px;
  }

  &[data-state="open"] {
    .arrow {
      transform: rotate(180deg);
    }
  }
`;

const Content = styled(Accordion.Content)`
  background: ${colorSet.gray11};
  overflow: hidden;

  div.ck-content {
    padding: 24px 40px;
  }

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }

  * {
    all: revert;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: normal;
  }
`;

const Item = styled(Accordion.Item)`
  overflow: hidden;
`;

const Card = styled.div`
  border-radius: 16px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
`;

const PaginationContainer = styled.div`
  padding: 16px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
  border-radius: 8px;
  min-height: 42px;
  background-color: ${colorSet.gray11};
`;

const StyledTabItem = styled(TabItem)`
  flex: 1 1 0%;
  cursor: pointer;
  padding: 7px 15px 7px 16px;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  border-color: ${colorSet.gray9};
  background-color: ${colorSet.gray11};

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left-width: 1px;
    padding-left: 15px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & {
    span {
      color: ${colorSet.gray6};
    }
  }

  &[data-selected="true"] {
    span {
      color: ${colorSet.white};
    }

    border-color: ${colorSet.indigo};
    background-color: ${colorSet.indigo};
  }
`;
