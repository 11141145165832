import { ScData } from "@/src/components/template/pdfs/Sc/types";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { contractDetailApi } from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { companyApi } from "@/src/store/apis/corporate/company";
import dayjs from "dayjs";

type ScDataType = "DETAIL" | "EDIT" | "ADD" | "SHARE";

interface GetScPdfDataProps {
  type: ScDataType;
  id?: number;
  /**
   * @description 계약 추가, 수정시 SC 데이터
   */
  contractAddAndEditScData?: {
    sellerCoporation?: string;
    sellerContactPrefix?: string;
    sellerContact?: string;
    sellerFaxPrefix?: string;
    sellerFax?: string;
    sellerCountryName?: string;
    sellerRegion?: string;
    sellerPostalCode?: string;
    sellerLocality?: string;
    sellerStreetAddress?: string;

    scNumber: string;
    buyerCoporation: string;
    buyerContactPrefix: string;
    buyerContact: string;
    buyerFaxPrefix: string;
    buyerFax: string;
    buyerCountryName: string;
    buyerRegion: string;
    buyerPostalCode: string;
    buyerLocality: string;
    buyerStreetAddress: string;

    orderDate: string;
    paymentTerm: string;
    paymentTermsRemark: string;
    originLocation: string;
    shippingTerm: string;
    shippingTermRemark: string;
    lastShipmentDate: string;
    quantityPrefix: string;
    quantity: string;
    description: string;
    hsCode: string;
    unitPricePrefix: string;
    unitPrice: string;
    amount: string;
    remark: string;

    isAddBankDetail: boolean;
    isAddContractTerms: boolean;

    componyLogoUrl?: string;
  };
  /**
   * @description 문서 공유 시 SC 데이터
   */
  sharedScData?: ContractDetailShareInfoDto | ContractDetailViewDto;
}

const getScPdfData = ({
  id,
  type,
  contractAddAndEditScData,
  sharedScData,
}: GetScPdfDataProps) => {
  const contractDetail =
    id && contractDetailApi.endpoints.getContractDetail.useQueryState({ id });
  const exportersData =
    companyApi.endpoints.getExporters.useQueryState(undefined);

  const transformScPdfData = (type: ScDataType) => {
    switch (type) {
      case "SHARE":
        return {
          scNumber: sharedScData?.scNo || "",
          componyLogoUrl: sharedScData?.businessLogoSimpleMedia?.mediaUrl || "",
          sellerCoporation: sharedScData?.companyName || "",
          sellerContact: sharedScData?.tel || "",
          sellerContactPrefix: sharedScData?.telPrefix || "",
          sellerFax: sharedScData?.fax || "",
          sellerFaxPrefix: sharedScData?.faxPrefix || "",

          sellerCountryName: sharedScData?.countryName || "",
          sellerRegion: sharedScData?.region || "",
          sellerPostalCode: sharedScData?.postalCode || "",
          sellerLocality: sharedScData?.locality || "",
          sellerStreetAddress: sharedScData?.streetAddress || "",

          buyerCoporation: sharedScData?.buyerName || "",
          buyerContactPrefix: sharedScData?.buyerTelPrefix || "",
          buyerContact: sharedScData?.buyerTel || "",
          buyerFaxPrefix: sharedScData?.buyerFaxPrefix || "",
          buyerFax: sharedScData?.buyerFax || "",

          buyerCountryName: sharedScData?.buyerCountryName || "",
          buyerRegion: sharedScData?.buyerRegion || "",
          buyerPostalCode: sharedScData?.buyerPostalCode || "",
          buyerLocality: sharedScData?.buyerLocality || "",
          buyerStreetAddress: sharedScData?.buyerStreetAddress || "",

          orderDate: dayjs
            .utc(sharedScData?.orderDateAt)
            .format(DATE_FORMAT_STRINGS.YYYY_MM_DD),
          paymentTerm: sharedScData?.paymentTerms || "",
          paymentTermsRemark: sharedScData?.paymentTermsRemark || "",
          originLocation: sharedScData?.origin || "",
          shippingTermRemark: sharedScData?.shippingTermsRemark || "",
          shippingTerm: sharedScData?.shippingTerms || "",
          lastShipmentDate: dayjs
            .utc(sharedScData?.lastShipmentDateAt)
            .format(DATE_FORMAT_STRINGS.YYYY_MM_DD),

          quantityPrefix: sharedScData?.quantityUnit || "",
          quantity:
            Number(
              String(sharedScData?.quantity).replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          description: sharedScData?.exporterItem || "",
          hsCode: sharedScData?.hsCode || "",
          unitPricePrefix: sharedScData?.unitPriceUnit || "",
          unitPrice:
            Number(
              String(sharedScData?.unitPrice).replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          amount:
            (
              sharedScData?.quantity &&
              sharedScData?.unitPrice &&
              Number(
                parseFloat(
                  String(sharedScData?.quantity * sharedScData?.unitPrice)
                ).toFixed(2)
              )
            )?.toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          remark: sharedScData?.contractRemark || "",
          isAddBankDetail: sharedScData?.isAddBankDetail || false,
          bankName: sharedScData?.bankName || "",
          bankTelPrefix: sharedScData?.bankTelPrefix || "",
          bankTel: sharedScData?.bankTel || "",
          bankFaxPrefix: sharedScData?.bankFaxPrefix || "",
          bankFax: sharedScData?.bankFax || "",
          bankStreetAddress: sharedScData?.bankStreetAddress || "",
          bankLocality: sharedScData?.bankLocality || "",
          bankRegion: sharedScData?.bankRegion || "",
          bankPostalCode: sharedScData?.bankPostalCode || "",
          bankCountryName: sharedScData?.bankCountryName || "",
          swiftCode: sharedScData?.swiftCode || "",
          accountNumber: sharedScData?.accountNumber || "",
          accountName: sharedScData?.accountName || "",
          sellerSignatureUrl:
            sharedScData?.signatureSimpleMedia?.mediaUrl || "",
          buyerSignatureUrl: "",
          isAddContractTerms: sharedScData?.isAddContractTerms,
          contractTermsTitle: sharedScData?.salesContractTerms?.title || "",
          contractTerms:
            sharedScData?.salesContractTerms?.latestSalesContractTermsHistory
              .body || "",
        } as ScData;

      case "DETAIL":
        if (!contractDetail) {
          return {} as ScData;
        }

        return {
          scNumber: contractDetail?.currentData?.scNo || "",
          componyLogoUrl:
            contractDetail?.currentData?.businessLogoSimpleMedia?.mediaUrl ||
            "",
          sellerCoporation: contractDetail?.currentData?.companyName || "",
          sellerContact: contractDetail?.currentData?.tel || "",
          sellerContactPrefix: contractDetail?.currentData?.telPrefix || "",
          sellerFax: contractDetail?.currentData?.fax || "",
          sellerFaxPrefix: contractDetail?.currentData?.faxPrefix || "",

          sellerCountryName: contractDetail?.currentData?.countryName || "",
          sellerRegion: contractDetail?.currentData?.region || "",
          sellerPostalCode: contractDetail?.currentData?.postalCode || "",
          sellerLocality: contractDetail?.currentData?.locality || "",
          sellerStreetAddress: contractDetail?.currentData?.streetAddress || "",

          buyerCoporation: contractDetail?.currentData?.buyerName || "",
          buyerContactPrefix: contractDetail?.currentData?.buyerTelPrefix || "",
          buyerContact: contractDetail?.currentData?.buyerTel || "",
          buyerFaxPrefix: contractDetail?.currentData?.buyerFaxPrefix || "",
          buyerFax: contractDetail?.currentData?.buyerFax || "",

          buyerCountryName: contractDetail?.currentData?.buyerCountryName || "",
          buyerRegion: contractDetail?.currentData?.buyerRegion || "",
          buyerPostalCode: contractDetail?.currentData?.buyerPostalCode || "",
          buyerLocality: contractDetail?.currentData?.buyerLocality || "",
          buyerStreetAddress:
            contractDetail?.currentData?.buyerStreetAddress || "",

          orderDate: contractDetail?.currentData?.orderDateAt
            ? dayjs
                .utc(contractDetail?.currentData?.orderDateAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "",
          paymentTerm: contractDetail?.currentData?.paymentTerms || "",
          paymentTermsRemark:
            contractDetail?.currentData?.paymentTermsRemark || "",
          originLocation: contractDetail?.currentData?.origin || "",
          shippingTermRemark:
            contractDetail?.currentData?.shippingTermsRemark || "",
          shippingTerm: contractDetail?.currentData?.shippingTerms || "",
          lastShipmentDate: contractDetail?.currentData?.lastShipmentDateAt
            ? dayjs
                .utc(contractDetail?.currentData?.lastShipmentDateAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "",

          quantityPrefix: contractDetail?.currentData?.quantityUnit || "",
          quantity:
            Number(
              String(contractDetail?.currentData?.quantity).replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          description: contractDetail?.currentData?.exporterItem || "",
          hsCode: contractDetail?.currentData?.hsCode || "",
          unitPricePrefix: contractDetail?.currentData?.unitPriceUnit || "",
          unitPrice:
            Number(
              String(contractDetail?.currentData?.unitPrice).replace(/[,]/g, "")
            ).toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          amount:
            (
              contractDetail?.currentData?.quantity &&
              contractDetail?.currentData?.unitPrice &&
              Number(
                parseFloat(
                  String(
                    contractDetail?.currentData?.quantity *
                      contractDetail?.currentData?.unitPrice
                  )
                ).toFixed(2)
              )
            )?.toLocaleString("ko-KR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || "",
          remark: contractDetail?.currentData?.contractRemark || "",
          isAddBankDetail:
            contractDetail?.currentData?.isAddBankDetail || false,
          bankName: contractDetail?.currentData?.bankName || "",
          bankTelPrefix: contractDetail?.currentData?.bankTelPrefix || "",
          bankTel: contractDetail?.currentData?.bankTel || "",
          bankFaxPrefix: contractDetail?.currentData?.bankFaxPrefix || "",
          bankFax: contractDetail?.currentData?.bankFax || "",
          bankStreetAddress:
            contractDetail?.currentData?.bankStreetAddress || "",
          bankLocality: contractDetail?.currentData?.bankLocality || "",
          bankRegion: contractDetail?.currentData?.bankRegion || "",
          bankPostalCode: contractDetail?.currentData?.bankPostalCode || "",
          bankCountryName: contractDetail?.currentData?.bankCountryName || "",
          swiftCode: contractDetail?.currentData?.swiftCode || "",
          accountNumber: contractDetail?.currentData?.accountNumber || "",
          accountName: contractDetail?.currentData?.accountName || "",
          sellerSignatureUrl:
            contractDetail?.currentData?.signatureSimpleMedia?.mediaUrl || "",
          buyerSignatureUrl: "",

          isAddContractTerms: contractDetail?.currentData?.isAddContractTerms,
          contractTermsTitle:
            exportersData?.currentData?.termsOfSalesContract
              .salesContractTermsWithLatestHistory?.title || "",
          contractTerms:
            exportersData?.currentData?.termsOfSalesContract
              ?.salesContractTermsWithLatestHistory
              ?.latestSalesContractTermsHistory.body || "",
        } as ScData;

      case "ADD":
      case "EDIT":
        return {
          scNumber: contractAddAndEditScData?.scNumber,
          componyLogoUrl: contractAddAndEditScData?.componyLogoUrl || "",
          sellerCoporation:
            contractAddAndEditScData?.sellerCoporation ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.companyName ||
            "",
          sellerContact:
            contractAddAndEditScData?.sellerContact ||
            exportersData?.currentData?.exporterDetailQueryResultDto?.tel ||
            "",
          sellerContactPrefix:
            contractAddAndEditScData?.sellerContactPrefix ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.telPrefix ||
            "",
          sellerFax:
            contractAddAndEditScData?.sellerFax ||
            exportersData?.currentData?.exporterDetailQueryResultDto?.fax ||
            "",
          sellerFaxPrefix:
            contractAddAndEditScData?.sellerFaxPrefix ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.faxPrefix ||
            "",
          sellerCountryName:
            contractAddAndEditScData?.sellerCountryName ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.countryName ||
            "",
          sellerRegion:
            contractAddAndEditScData?.sellerRegion ||
            exportersData?.currentData?.exporterDetailQueryResultDto?.region ||
            "",
          sellerPostalCode:
            contractAddAndEditScData?.sellerPostalCode ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.postalCode ||
            "",
          sellerLocality:
            contractAddAndEditScData?.sellerLocality ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.locality ||
            "",
          sellerStreetAddress:
            contractAddAndEditScData?.sellerStreetAddress ||
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.streetAddress ||
            "",

          buyerCoporation: contractAddAndEditScData?.buyerCoporation,
          buyerContactPrefix: contractAddAndEditScData?.buyerContactPrefix,
          buyerContact: contractAddAndEditScData?.buyerContact,
          buyerFaxPrefix: contractAddAndEditScData?.buyerFaxPrefix,
          buyerFax: contractAddAndEditScData?.buyerFax,
          buyerCountryName: contractAddAndEditScData?.buyerCountryName,
          buyerRegion: contractAddAndEditScData?.buyerRegion,
          buyerPostalCode: contractAddAndEditScData?.buyerPostalCode,
          buyerLocality: contractAddAndEditScData?.buyerLocality,
          buyerStreetAddress: contractAddAndEditScData?.buyerStreetAddress,

          orderDate: contractAddAndEditScData?.orderDate,
          paymentTerm: contractAddAndEditScData?.paymentTerm,
          paymentTermsRemark: contractAddAndEditScData?.paymentTermsRemark,
          originLocation: contractAddAndEditScData?.originLocation,
          shippingTerm: contractAddAndEditScData?.shippingTerm,
          shippingTermRemark: contractAddAndEditScData?.shippingTermRemark,
          lastShipmentDate: contractAddAndEditScData?.lastShipmentDate,
          quantityPrefix: contractAddAndEditScData?.quantityPrefix,
          quantity: contractAddAndEditScData?.quantity,
          description: contractAddAndEditScData?.description,
          hsCode: contractAddAndEditScData?.hsCode,
          unitPricePrefix: contractAddAndEditScData?.unitPricePrefix,
          unitPrice: contractAddAndEditScData?.unitPrice,
          amount: contractAddAndEditScData?.amount,
          remark: contractAddAndEditScData?.remark,

          isAddBankDetail: contractAddAndEditScData?.isAddBankDetail,
          bankName:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankName || "",
          bankTelPrefix:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankTelPrefix || "",
          bankTel:
            exportersData?.currentData?.exporterDetailQueryResultDto?.bankTel ||
            "",
          bankFaxPrefix:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.faxPrefix || "",
          bankFax:
            exportersData?.currentData?.exporterDetailQueryResultDto?.bankFax ||
            "",
          bankStreetAddress:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankStreetAddress || "",
          bankLocality:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankLocality || "",
          bankRegion:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankRegion || "",
          bankPostalCode:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankPostalCode || "",
          bankCountryName:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.bankCountryName || "",
          swiftCode:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.swiftCode || "",
          accountNumber:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.accountNumber || "",
          accountName:
            exportersData?.currentData?.exporterDetailQueryResultDto
              ?.accountName || "",
          sellerSignatureUrl:
            exportersData?.currentData?.signatureManagement.simpleExporterSignatureMedias?.filter(
              (item) => item.isDefault === true
            )[0].signatureSimpleMedia?.mediaUrl || "",
          buyerSignatureUrl: "",

          isAddContractTerms: contractAddAndEditScData?.isAddContractTerms,
          contractTermsTitle:
            exportersData?.currentData?.termsOfSalesContract
              .salesContractTermsWithLatestHistory?.title || "",
          contractTerms:
            exportersData?.currentData?.termsOfSalesContract
              ?.salesContractTermsWithLatestHistory
              ?.latestSalesContractTermsHistory.body || "",
        } as ScData;
    }
  };

  return transformScPdfData(type);
};

export default getScPdfData;
