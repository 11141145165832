import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import Table from "@/src/components/atom/Table";
import { shipmentRateColumns } from "../columns/shipmentRateColumns";
import { useGetShipmentRateInfoQuery } from "@/src/store/apis/dashboard";
import { ShipmentRateInfoDto } from "@/src/store/apis/dashboard/interface";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

const ShipmentRate = () => {
  const ref = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { currentData, isFetching } = useGetShipmentRateInfoQuery(
    { page, pageSize },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (ref.current && ref && isReady) {
      if (isFetching) {
        ref.current.api.showLoadingOverlay();
      }
    }
  }, [isFetching, isReady]);

  return (
    <>
      <Table
        ref={ref}
        onGridReady={() => setIsReady(true)}
        rowData={currentData?.rows ?? []}
        columnDefs={shipmentRateColumns}
        totalPage={currentData?.count ?? 0}
        height={252}
        page={page}
        pageSize={pageSize}
        onRowClicked={({ data }: { data: ShipmentRateInfoDto }) =>
          navigate(
            `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${data.contractId}`
          )
        }
        handlePaginationClick={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
      />
    </>
  );
};

export default ShipmentRate;
