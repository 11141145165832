import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import Typo from "@/src/components/atom/Typo";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import Icon from "@/src/components/atom/Icon";
import renderBookingStatusBadge from "../utils/renderBookingStatusBadge";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import SplitBadge from "../components/list/SplitBadge";

export const columnBookingList: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isBookmarked === true) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }

      return null;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "bookingStatus",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return renderBookingStatusBadge({
          type: params.data.bookingStatus,
          t: params.data.t,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.bookingNo ? (
        <Typo typoType="b9r">
          {params.data.isSplitted && <SplitBadge size="xs" />}

          {params.data.bookingNo}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "scNo",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.scNo ? (
        <Typo typoType="b9r">{params.data.scNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "mainCategoryCodeItemName",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.mainCategory ? (
        <Typo typoType="b9r">{params.data.mainCategory}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "numberOfContainer",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.numberOfContainer && params.data.containerType ? (
        <Typo typoType="b9r">
          {params.data.numberOfContainer}x{params.data.containerType}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "docCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.docCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.docCutOffAt).format(
              dayjs(params.data.docCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "cargoCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.cargoCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.cargoCutOffAt).format(
              dayjs(params.data.cargoCutOffAt).get("minute") === 30
                ? DATE_FORMAT_STRINGS.YYYY_MM_DD
                : DATE_FORMAT_STRINGS.YYYY_MM_DD_hA
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.data.portOfLoading}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
];
