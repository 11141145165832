import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from ".";
import AutoLogout from "./AutoLogout";
import FullLoading from "./components/molecule/FullLoading";
import ContentLoading from "./components/molecule/ContentLoading";
import { AlertQuantumRoot } from "./components/quantum/AlertQuantum";
import store from "./store";
import GlobalStyle from "./styles/GlobalStyle";
import privateRoutes from "./routes/private/private";
import importerPrivateRoutes from "./routes/importer/private";
import exporterPrivateRoutes from "./routes/exporter/private";
import CountryCode from "./CountryCode";
import PushAlarms from "./PushAlarms";
import publicRoutes from "./routes/public/public";
import CurrentSubscription from "./CurrentSubscription";

const router = createBrowserRouter([
  ...importerPrivateRoutes,
  ...exporterPrivateRoutes,
  ...privateRoutes,
  ...publicRoutes,
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AlertQuantumRoot>
          <GlobalStyle />
          <AutoLogout />
          <CountryCode />
          <PushAlarms />
          <CurrentSubscription />
          <RouterProvider router={router} />
          <div id="alert" />
          {store.getState().webUtil.loading && <FullLoading />}
          <ContentLoading />
        </AlertQuantumRoot>
      </PersistGate>
    </Provider>
  );
}

export default App;
