import styled, { css } from "styled-components";

interface IconProps {
  iconSrc: string;
  iconSize?: number;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  className?: string;
  onPointerDown?: (e: React.PointerEvent<HTMLSpanElement>) => void;
}

const Icon = ({
  iconSrc,
  iconSize,
  onClick,
  onPointerDown,
  className,
}: IconProps) => {
  return (
    <StyledIcon
      $iconSrc={iconSrc}
      $iconSize={iconSize ?? 16}
      onClick={onClick}
      className={className}
      onPointerDown={onPointerDown}
      style={
        onClick
          ? {
              cursor: "pointer",
            }
          : undefined
      }
    />
  );
};

export default Icon;

const StyledIcon = styled.span<{ $iconSrc: string; $iconSize: number }>`
  ${({ $iconSize }) => css`
    width: ${$iconSize}px;
    height: ${$iconSize}px;
  `};
  display: inline-block;
  background: url(${({ $iconSrc }) => $iconSrc}) center center/contain no-repeat;
`;
