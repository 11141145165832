// URL을 Blob으로 변환하는 함수
export const toDataURL = (url: string) => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

// URL로부터 파일 다운로드하는 기존 함수
export const downloadFile = async (url: string, fileName?: string) => {
  const a = document.createElement("a");
  a.href = await toDataURL(url);
  a.download = fileName ?? "download";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Blob으로부터 파일 다운로드하는 기존 함수
export const downloadFileFromBlob = (blob: Blob, fileName?: string) => {
  const a = document.createElement("a");
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName ?? "download";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url); // 메모리 누수 방지
};
