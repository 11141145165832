import { ValueGetterParams, ColDef } from "ag-grid-community";
import { ExporterUserDto } from "@/src/store/apis/auth/interface";

export const staffManagerColumn = [
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Name",
    cellStyle: { textAlign: "center" },
    field: "name",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Email",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Personal)",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.personalContact
            ? `${data?.personalContactPrefix ?? ""} ${data?.personalContact}`
            : "-"}
        </>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contact (Office)",
    cellStyle: { textAlign: "center" },
    field: "officeContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<ExporterUserDto>) => {
      return (
        <>
          {!!data?.officeContact
            ? `${data?.officeContactPrefix ?? ""} ${data?.officeContact}`
            : "-"}
        </>
      );
    },
  },
] as ColDef[];
