import React from "react";
import { useAppSelector } from "@/src/store";
import { ReactComponent as NotFoundSvg } from "@/src/assets/icons/icon-not-found.svg";
import { styled } from "styled-components";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import PUBLIC_PATH from "@/src/routes/public/path";

const SLASH = "/";
const EXPORT = "export";
const IMPORT = "import";

function isSame<T>(x: T, y: T): boolean {
  return x === y;
}

const NotFoundPage = () => {
  const user = useAppSelector((state) => state.auth.user);
  const lang = useAppSelector((state) => state.lang.value);
  const path = useLocation();

  const isExport =
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() === EXPORT ||
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() !== IMPORT;

  const isImport =
    path.pathname.split(SLASH)[1]?.toLocaleLowerCase() === IMPORT;

  if (!user) {
    return <AuthInvalidNotFoundContent lang={lang} />;
  }

  const getClientType = () => {
    if (isSame(user.exporterUserType, "CORPORATE_MANAGER")) {
      return user.exporter.companyType;
    }
    return user.exporterUserMainFieldType;
  };

  /* 수입자 일 때, BOTH 일 때 */
  if (
    (isSame(getClientType(), "BUYER") || isSame(getClientType(), "BOTH")) &&
    isImport
  ) {
    return (
      <ImporterMainLayout>
        <FlexColumnCenter>
          <AuthValidNotFoundContent
            lang={lang}
            homePath={IMPORTER_PRIVATE_PATH.HOME}
          />
        </FlexColumnCenter>
      </ImporterMainLayout>
    );
  }

  /* 수출자 일 때, BOTH 일 때 */
  if (
    (isSame(getClientType(), "BOTH") || isSame(getClientType(), "SELLER")) &&
    isExport
  ) {
    const hasExportPlan = true;
    const hasPlanHistory = true;
    if (hasExportPlan || hasPlanHistory) {
      return (
        <ExporterMainLayout>
          <FlexColumnCenter>
            <AuthValidNotFoundContent lang={lang} homePath={PUBLIC_PATH.ROOT} />
          </FlexColumnCenter>
        </ExporterMainLayout>
      );
    }
  }

  return <AuthInvalidNotFoundContent lang={lang} />;
};

export default NotFoundPage;

const FlexColumnCenter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const NotFoundIcon = styled(NotFoundSvg)`
  width: 283px;
  height: 172px;
`;

const NotFoundTitle = styled.h1`
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard Bold";
  line-height: normal;
  color: ${colorSet.gray2};
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 40px;
`;

const AuthValidNotFoundContent = ({
  homePath,
  lang,
}: {
  homePath: string;
  lang: "ko" | "en";
}) => {
  const navigate = useNavigate();

  return (
    <>
      <NotFoundIcon />
      <NotFoundTitle>Not Found</NotFoundTitle>
      <Typo color="gray2" typoType="b4m">
        {lang === "ko"
          ? `페이지를 찾을 수 없습니다.\n페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.`
          : `Page not found.\nThe page does not exist or is not available.`}
      </Typo>

      <Flex>
        <Button
          buttonGrade="tertiary"
          buttonColor="black"
          buttonSize={40}
          style={{ width: "158px" }}
          onClick={() => navigate(homePath)}
        >
          Back to Home
        </Button>
        <Button
          style={{ width: "158px" }}
          onClick={() => window.history.back()}
        >
          Back to Previous
        </Button>
      </Flex>
    </>
  );
};

const AuthInvalidNotFoundContent = ({ lang }: { lang: "ko" | "en" }) => {
  return (
    <FlexColumnCenter style={{ height: "100vh" }}>
      <NotFoundIcon />
      <NotFoundTitle>Not Found</NotFoundTitle>
      <Typo color="gray2" typoType="b4m">
        <Typo color="gray2" typoType="b4m">
          {lang === "ko"
            ? `페이지를 찾을 수 없습니다.\n페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.`
            : `Page not found.\nThe page does not exist or is not available.`}
        </Typo>
      </Typo>

      <Flex>
        <Button
          style={{ width: "158px" }}
          onClick={() => window.history.back()}
        >
          Back to Previous
        </Button>
      </Flex>
    </FlexColumnCenter>
  );
};
