import Table, { renderNoRowsComponent } from "@/src/components/atom/Table";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import { isUndefined } from "@/src/utils/is";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { columnSalesWarehouseList } from "../../columns/columnsSales";
import {
  useLazyGetSalesWithWarehousingsQuery,
  useUpdateWarehousingsMutation,
} from "@/src/store/apis/sales";
import { WarehousingDto } from "@/src/store/apis/sales/interface";
import { createViewAction, editAction } from "@/src/utils/row-data-util";
import EditSalesDialog from "../dialog/EditSalesDialog";
import Loader from "@/src/components/atom/Loader";
import styled from "styled-components";
import SalesContainerListDialog from "../dialog/SalesContainerListDialog";
import { useTranslation } from "react-i18next";

enum DialogState {
  NULL,
  EDIT,
  VIEW,
}

const emptyArray: WarehousingDto[] = [];

function SalesDetailContainerList() {
  const { t } = useTranslation();
  const params = useParams();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();

  const [columnDefs] = useState<ColDef[]>(columnSalesWarehouseList);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [selectWarehouse, setSelectWarehouse] = useState<WarehousingDto | null>(
    null
  );

  // API
  const [updateWarehouse, { isLoading: isUpdateWarehouseLoading }] =
    useUpdateWarehousingsMutation();
  const [getSalesWithWarehousings, { isFetching, count, list, isError }] =
    useLazyGetSalesWithWarehousingsQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;
        const isStable = !isUnstable;

        return {
          isFetching,
          isError,
          count: isStable ? currentData.count : 0,
          list: isStable ? currentData?.rows : emptyArray,
        };
      },
    });

  useAgGridHeaderRefresh({
    gridRef: gridRef?.current,
    isReady,
    headerSet: [
      {
        columnKey: "warehousedAt",
        langKey: "sales:detail.table.warehousedAt",
      },
      {
        columnKey: "warehousedAmount",
        langKey: "sales:detail.table.warehousedAmount",
      },
      {
        columnKey: "warehousingMedia",
        langKey: "sales:detail.table.warehousingMedia",
      },
      {
        columnKey: "warehousingContainers",
        langKey: "sales:detail.table.warehousingContainers",
      },
      {
        columnKey: "view",
        langKey: "sales:detail.table.view",
      },
      {
        columnKey: "edit",
        langKey: "sales:detail.table.edit",
      },
    ],
  });

  // Submit
  const handleSubmit = useCallback(
    async (listParams?: { page?: number; pageSize?: number }) => {
      const page = listParams?.page || pagination.page;
      const pageSize = listParams?.pageSize || pagination.pageSize;
      const salesWithWarehousingsParams = {
        page,
        pageSize,
        id: Number(params.id),
      };

      try {
        await getSalesWithWarehousings(salesWithWarehousingsParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getSalesWithWarehousings,
      pagination.page,
      pagination.pageSize,
      params.id,
    ]
  );

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  const handleViewClick = (data: any) => {
    setSelectWarehouse(data);
    setDialogState(DialogState.VIEW);
  };

  const handleEditClick = (data: any) => {
    setSelectWarehouse(data);
    setDialogState(DialogState.EDIT);
  };

  const handleUpdateWarehouse = async ({
    warehousedAt,
    warehousingMediaId,
  }: {
    warehousedAt?: string;
    warehousingMediaId?: number;
  }) => {
    if (!selectWarehouse) {
      return;
    }

    try {
      await updateWarehouse({
        salesId: Number(params.id),
        id: selectWarehouse.id,
        warehousedAt,
        warehousingMediaId,
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      setSelectWarehouse(null);
      setIsReady(false);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderTable = () => {
    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    if (list.length === 0 || isError) {
      return <>{renderNoRowsComponent()}</>;
    }

    return (
      <Table
        ref={gridRef}
        rowData={list}
        columnDefs={columnDefs}
        totalPage={count}
        isPaginationDataMaping
        pageSize={pagination.pageSize}
        page={pagination.page}
        handlePaginationClick={handlePaginationClick}
        onGridReady={() => {
          setIsReady(true);
        }}
        components={{
          ...createViewAction((row) => {
            handleViewClick(row);
          }),
          ...editAction((row) => {
            handleEditClick(row);
          }),
        }}
      />
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.EDIT) {
      return (
        <EditSalesDialog
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectWarehouse(null);
          }}
          onSave={handleUpdateWarehouse}
          isLoading={isUpdateWarehouseLoading}
          selectWarehouse={selectWarehouse || null}
        />
      );
    }

    if (dialogState === DialogState.VIEW && selectWarehouse) {
      return (
        <SalesContainerListDialog
          open
          onOpenChange={() => {
            setDialogState(DialogState.NULL);
            setSelectWarehouse(null);
          }}
          warehousingId={selectWarehouse.id}
        />
      );
    }
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionCard cardTitle={t("sales:detail.receiptList")}>
      {renderTable()}
      {renderDialog()}
    </SectionCard>
  );
}

export default SalesDetailContainerList;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
`;
