import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "@/src/store";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

const ProtectedImportPrivateRoute = () => {
  const user = useAppSelector((state) => state.auth.user);
  const token = useAppSelector((state) => state.auth.token);
  const search = useLocation();

  if (!user) {
    return <Navigate to={PUBLIC_PATH.LOGIN} replace />;
  }

  const getClientType = () => {
    if (isSame(user.exporterUserType, "CORPORATE_MANAGER")) {
      return user.exporter.companyType;
    }
    return user.exporterUserMainFieldType;
  };

  /* 수출자 일 때 */
  if (isSame(getClientType(), "SELLER")) {
    return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />;
  }

  if (
    token &&
    (search.pathname === IMPORTER_PRIVATE_PATH.ROOT ||
      search.pathname === PUBLIC_PATH.ROOT)
  ) {
    return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} replace />;
  }

  return <Outlet />;
};

export default ProtectedImportPrivateRoute;

function isSame<T>(x: T, y: T): boolean {
  return x === y;
}
