import React from "react";
import Typo from "@/src/components/atom/Typo";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { isUndefined } from "@/src/utils/is";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";

function BookingAndContractCard() {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isError,
    contractId,
    scNo,
    poNo,
    etdAt,
    initialEtdAt,
    estimatedWeightAndUnit,
    allocation,
    etdDateFormat,
    exporterItemCode,
  } = taskDetailApi.endpoints.getTaskDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          isError,
          contractId: currentData?.contractId,
          scNo: !isUnstable ? currentData.scNo : "-",
          poNo: !isUnstable ? currentData.poNo : "-",
          exporterItemCode: !isUnstable ? currentData.exporterItemCode : "-",
          etdAt: !isUnstable ? currentData.etdAt : null,
          initialEtdAt: !isUnstable ? currentData.initialEtdAt : null,
          etdDateFormat: !isUnstable
            ? dayjs(currentData.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
          estimatedWeight: !isUnstable ? currentData.estimatedWeight : "",
          estimatedWeightAndUnit:
            !isUnstable &&
            currentData.estimatedWeight &&
            currentData.estimatedWeightUnit
              ? `${Number(currentData.estimatedWeight).toLocaleString(
                  "ko-KR"
                )} ${currentData.estimatedWeightUnit}`
              : "-",
          allocation: isUnstable ? "" : currentData.allocation,
        };
      },
    }
  );

  const renderEtd = () => {
    if (!etdAt || !initialEtdAt) {
      return "-";
    }

    return (
      <Etd>
        {etdDateFormat}
        <EtdCompareCount
          initDate={initialEtdAt}
          currentDate={etdAt}
          typoType="b7m"
        />
      </Etd>
    );
  };

  return (
    <SectionCard cardTitle={t("task:detail.cards.bookingContractInformation")}>
      <RowContainer>
        <RowItem>
          <SectionCardRow
            label={"Item Code"}
            value={
              <BreakWordTypo typoType="b7r" color="gray5">
                {exporterItemCode}
              </BreakWordTypo>
            }
          />
          <SectionCardRow
            label={"SC No."}
            value={
              !isError ? (
                <BlueText
                  typoType="h7"
                  color="systemBlue2"
                  onClick={() =>
                    navigate(
                      `${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${contractId}`
                    )
                  }
                >
                  {scNo}
                </BlueText>
              ) : (
                "-"
              )
            }
          />
          <SectionCardRow
            label={"PO No."}
            value={
              <BreakWordTypo typoType="b7r" color="gray5">
                {poNo}
              </BreakWordTypo>
            }
          />
        </RowItem>
        <GrayLine />

        <RowItem>
          <SectionCardRow label={"ETD"} value={renderEtd()} />
          <SectionCardRow
            label={"Estimated Weight"}
            value={
              <BreakWordTypo typoType="b7r" color="gray5">
                {estimatedWeightAndUnit}
              </BreakWordTypo>
            }
          />

          {allocation && (
            <SectionCardRow label={"Allocation"} value={allocation} />
          )}
        </RowItem>
      </RowContainer>
    </SectionCard>
  );
}

export default BookingAndContractCard;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  border-right: 1px solid ${colorSet.gray9};
`;

const Etd = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
  word-break: break-word;
`;
