import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import {
  GetPushAlarmsRequest,
  ModifyPushAlarmDto,
  PushAlarmDto,
  ReadPushAlarmBulkDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["allPushAlarms", "pushAlarmsMerge"],
});

export const pushAlarmsApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 자신의 푸시 알림 읽지않은 목록 조회
    getAllPushAlarms: build.query<Rows<PushAlarmDto>, GetPushAlarmsRequest>({
      query: (params) => ({
        url: "/exporter-users/this/pushAlarms",
        params: { isRead: false, ...params },
      }),

      providesTags: ["allPushAlarms"],
    }),

    // 자신의 푸시 알림 목록 조회
    getPushAlarms: build.query<Rows<PushAlarmDto>, GetPushAlarmsRequest>({
      query: (params) => ({
        url: "/exporter-users/this/pushAlarms",
        params,
      }),
    }),

    // 자신의 푸시 알림 목록 조회 (무한스크롤 용 작업중)
    getPushAlarmsMerge: build.query<
      Rows<PushAlarmDto>,
      GetPushAlarmsRequest & { isReset?: boolean }
    >({
      query: (params) => ({
        url: "/exporter-users/this/pushAlarms",
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.isReset) {
          currentCache.rows = newItems.rows;
        } else {
          currentCache.rows.push(...newItems.rows);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["pushAlarmsMerge"],
    }),

    // 자신의 푸시 알림 다중 읽음 처리 API
    multiplePushAlarmsRead: build.mutation<
      Rows<PushAlarmDto>,
      ReadPushAlarmBulkDto
    >({
      query: (body) => ({
        method: "PUT",
        url: `/exporter-users/this/pushAlarms`,
        body,
      }),
      invalidatesTags: ["allPushAlarms"],
    }),

    // 자신의 푸시 알림 단일 수정 API
    pushAlarmRead: build.mutation<
      Rows<PushAlarmDto>,
      ModifyPushAlarmDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/exporter-users/this/pushAlarms/${id}`,
        body,
      }),
      invalidatesTags: ["allPushAlarms"],
    }),
  }),
});

export const {
  useGetAllPushAlarmsQuery,
  useGetPushAlarmsQuery,
  useLazyGetPushAlarmsQuery,
  useLazyGetPushAlarmsMergeQuery,

  useMultiplePushAlarmsReadMutation,
  usePushAlarmReadMutation,
} = pushAlarmsApi;
