import React from "react";

import { Button, ConfigProvider, type ButtonProps } from "antd";
import styled from "styled-components";
import colorSet from "@/src/styles/color";

interface Props extends ButtonProps {}

const SelectOptionButton: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Pretendard Regular",
          borderRadiusLG: 0,
          fontSizeLG: 16,
          paddingContentHorizontal: 16,
          paddingContentVertical: 8,
        },
      }}
    >
      <StyledButton type="text" size="large" {...rest}>
        {children}
      </StyledButton>
    </ConfigProvider>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  text-align: left;

  &:hover {
    &&& {
      background-color: ${colorSet.gray10};
    }
  }
`;

export default SelectOptionButton;
