import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { ModifyAnnuallySubscription, SubscriptionCreditType } from "./type";
import { subscriptionApi } from "../../apis/subscription";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";

interface SubscriptionInitialState {
  // 연결제 플랜 변경
  modifyAnnuallySubscription: ModifyAnnuallySubscription | null;
  // 구독
  subscriptionCredit: SubscriptionCreditType | null;
  // 엔터프라이즈 문의 여부
  isEnterpriseInquiries: boolean;
  // 엔터프라이즈 플랜 여부
  isEnterprisePlan: boolean;
  // 자동결제 실패 여부
  isPaymentFail: boolean;
  // 기업 구독 여부
  isCompanySubscription: boolean;
}

const initialState: SubscriptionInitialState = {
  modifyAnnuallySubscription: null,
  subscriptionCredit: null,
  isEnterpriseInquiries: false,
  isEnterprisePlan: false,
  isPaymentFail: false,
  isCompanySubscription: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    changeAnnuallySubscription: (
      state,
      action: PayloadAction<ModifyAnnuallySubscription | null>
    ) => {
      state.modifyAnnuallySubscription = action.payload;
    },
    changeSubscriptionCredit: (
      state,
      action: PayloadAction<SubscriptionCreditType | null>
    ) => {
      state.subscriptionCredit = action.payload;
    },
    closeEnterprisePlanDialog: (state) => {
      state.isEnterpriseInquiries = false;
      state.isEnterprisePlan = false;
    },
    closePaymentFailDialog: (state) => {
      state.isPaymentFail = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addMatcher(
      subscriptionApi.endpoints.getCurrentSubscription.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          const endDate = dayjs(
            dayjs(payload.subscriptionEndAtTo).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )
          );
          const duration = endDate.diff(dayjs(), "days");

          if (payload.subscriptionStatus === "CANCELED") {
            // 해지 예정 상태
            if (duration > 0) {
              state.isCompanySubscription = true;
              state.subscriptionCredit = {
                numberOfLoadingCode: payload.planVersion.numberOfLoadingCode,
                numberOfMember: payload.planVersion.numberOfMember,
                loadingCodeCredit: payload.loadingCodeCredit,
                memberNumberCredit: payload.memberNumberCredit,
              };
            } else {
              // 즉시 해지 상태
              state.subscriptionCredit = null;
              state.isCompanySubscription = false;
            }
          } else {
            // 그 외
            state.isCompanySubscription = duration >= 0;
            state.subscriptionCredit = {
              numberOfLoadingCode: payload.planVersion.numberOfLoadingCode,
              numberOfMember: payload.planVersion.numberOfMember,
              loadingCodeCredit: payload.loadingCodeCredit,
              memberNumberCredit: payload.memberNumberCredit,
            };
          }
        }
      }
    );

    builder.addMatcher(
      subscriptionApi.endpoints.getEnterpriseInquiries.matchFulfilled,
      (state, { payload }) => {
        const { rows } = payload;
        const currentInquiries = rows[0];

        if (payload.statusCode === 200 && currentInquiries) {
          state.isEnterpriseInquiries = true;
        }
      }
    );

    builder.addMatcher(
      subscriptionApi.endpoints.getPlans.matchFulfilled,
      (state, { payload }) => {
        const { rows } = payload;
        const enterprisePlans = rows.filter(
          (item) => item.planType === "ENTERPRISE" && item.isActivated
        );

        if (payload.statusCode === 200 && enterprisePlans.length >= 1) {
          state.isEnterprisePlan = true;
        }
      }
    );

    builder.addMatcher(
      subscriptionApi.endpoints.getCurrentSubscription.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          if (payload.subscriptionStatus === "PAYMENT_FAIL") {
            state.isPaymentFail = true;
            state.isCompanySubscription = false;
          } else {
            state.isPaymentFail = false;
          }
        }
      }
    );
  },
});

export default subscriptionSlice.reducer;
export const {
  changeAnnuallySubscription,
  changeSubscriptionCredit,
  closeEnterprisePlanDialog,
  closePaymentFailDialog,
} = subscriptionSlice.actions;
