import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import { styled } from "styled-components";
import useAlert from "@/src/hooks/useAlert";
import MultipleInput from "@/src/components/atom/Input/MultipleInput";
import { useLazyGetContractDetailBuyerEmployeesQuery } from "@/src/store/apis/contracts/contractDetail";
import { Controller, useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import Loader from "@/src/components/atom/Loader";
import { isUndefined } from "@/src/utils/is";
import { BuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import { TEMPLATE_OPTION_VALUE } from "@/src/constant/emailDialog";
import colorSet from "@/src/styles/color";
import BasicTextArea from "@/src/components/atom/TextArea/BasicTextArea";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { emailRegex } from "@/src/utils/regex";
import { FileDto } from "@/src/store/apis/file/interface";
import Flex from "@/src/components/molecule/Flex";
import Table from "@/src/components/atom/Table";
import { fileBoxEmailColumns } from "../../columns/fileBoxColumns";
import { deleteAction } from "@/src/utils/row-data-util";
import { ICellRendererParams } from "ag-grid-community";
import { useSendImporterFileMutation } from "@/src/store/apis/file";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";

interface EmailDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  buyerId: number;
  buyerName: string;
  fileList: FileDto[];
  contractId: number;
}

enum AlertDialogState {
  NULL,
  CANCEL,
}

const buyerEmptyArray: BuyerEmployeeDto[] = [];

function EmailDialog({
  open,
  onOpenChange,
  buyerId,
  buyerName,
  fileList,
  contractId,
}: EmailDialogProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const titleContainerRef = useRef<HTMLDivElement>(null);
  const recommendRef = useRef<HTMLDivElement>(null);
  const alert = useAlert();
  const [columnDefs] = useState(fileBoxEmailColumns);
  const [files, setFiles] = useState(fileList);

  // API
  const [getBuyerEmployees, { buyerList, isBuyerFetching }] =
    useLazyGetContractDetailBuyerEmployeesQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          buyerList: !isUnstable
            ? currentData.rows ?? buyerEmptyArray
            : buyerEmptyArray,
          isBuyerFetching: isFetching,
        };
      },
    });

  const [sendEmail, { isLoading }] = useSendImporterFileMutation({
    selectFromResult: ({ isLoading }) => {
      return { isLoading };
    },
  });

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady: true,
    headerSet: [
      {
        columnKey: "type",
        langKey: "emailDialog:table.type",
      },
      {
        columnKey: "originalFileName",
        langKey: "emailDialog:table.originalFileName",
      },
    ],
  });

  // State
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  const { control, watch, setValue, setFocus, getValues, handleSubmit } =
    useForm<{
      buyerId: string;
      contractId?: string;
      email: string[];
      emailKeyword: string;
      cc: string;
      emailCcList: string[];
      emailTitle: string;
      emailContent: string;
      template: string[];
    }>({
      mode: "all",
      defaultValues: {
        buyerId: "",
        contractId: undefined,
        emailKeyword: "",
        email: [],
        cc: "",
        emailCcList: [],
        emailTitle: "",
        emailContent: "",
        template: [TEMPLATE_OPTION_VALUE.booking],
      },
    });

  const recipientsOptionList = buyerList.map((item) => {
    return {
      label: `${item.name} (${item.email})`,
      value: String(item.id),
    };
  });

  useEffect(() => {
    setFocus("buyerId");
  }, [setFocus]);

  const validationEmail = (value: string) => {
    return emailRegex.test(value);
  };

  // Submit
  const handleSubmitClick = async () => {
    const emailCcList = getValues("emailCcList").filter((email) =>
      validationEmail(email)
    );

    const buyerEmployeeIdList =
      buyerList
        .filter((buyer) =>
          getValues("email").some((id) => id === String(buyer.id))
        )
        .map((item) => item.id) ?? [];

    const emailParams = {
      emailCcList,
      emailTitle: getValues("emailTitle"),
      emailContent: getValues("emailContent")
        ? getValues("emailContent")
        : undefined,
      contractId,
      buyerId,
      buyerEmployeeIdList,
      mediaIdList: files.map(({ id }) => id),
    };

    try {
      await sendEmail(emailParams).unwrap();
      onOpenChange(false);

      alert.showAlert({
        type: "success",
        message: "Send was successful",
      });
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleDeleteClick = (rowData: ICellRendererParams["data"]) => {
    setFiles((prev) => prev.filter(({ id }) => rowData.id !== id));
  };

  useEffect(() => {
    (async () => {
      try {
        await getBuyerEmployees({ id: buyerId }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e?.data?.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId, getBuyerEmployees]);

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.CANCEL) {
      return (
        <CancelAlertDialog
          open={alertDialogState === AlertDialogState.CANCEL}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => onOpenChange(false)}
        />
      );
    }
  };

  return (
    <Dialog
      title={t("emailDialog:dialogTitle")}
      width={850}
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setAlertDialogState(AlertDialogState.CANCEL)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setAlertDialogState(AlertDialogState.CANCEL)}
          >
            <Typo typoType="btn3m">{t("emailDialog:button.cancelButton")}</Typo>
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitClick)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Typo typoType="btn3m" color="white">
                {t("emailDialog:button.sendButton")}
              </Typo>
            )}
          </Button>
        </DialogFooterContainer>
      }
    >
      {renderAlertDialog()}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        {/* Importer */}
        <FormItem
          label={"Importer"}
          name="buyerId"
          type="text"
          direction="horizontal"
          control={control}
          inputProps={{
            disabled: true,
            value: buyerName,
          }}
          errorsMessage={{
            required: t("error:required"),
          }}
        />

        {/* Recipients */}
        <RecipientsContainer>
          <RecipientsForm
            label={t("emailDialog:label.recipients")}
            type="multipleSelect"
            name="email"
            rules={{ required: true }}
            control={control as any}
            options={recipientsOptionList}
            direction="horizontal"
            inputProps={{
              suffixIcon: isBuyerFetching ? <Loader /> : undefined,
              placeholder: t("emailDialog:placeholder.email"),
              getPopupContainer: (triggerNode: any) => {
                return triggerNode.parentElement;
              },
              onRemoveItem: (value) => {
                const watchCategory = watch("email");
                const watchCategoryFilter = watchCategory.filter(
                  (item) => item !== value
                );
                setValue("email", watchCategoryFilter);
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
        </RecipientsContainer>

        {/* CC */}
        <StyledSectionCardRow
          label="CC"
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="cc"
              render={({ field }) => (
                <MultipleInput
                  placeholder={t("emailDialog:placeholder.cc")}
                  value={field.value}
                  valueList={watch("emailCcList")}
                  onChangeValue={(value) => setValue("cc", value)}
                  onChangeValueList={(valueList) => {
                    const uniqueEmailList = valueList.filter(
                      (value, index, self) => {
                        return self.indexOf(value) === index;
                      }
                    );

                    setValue("emailCcList", uniqueEmailList);
                  }}
                  validation={validationEmail}
                />
              )}
            />
          }
        />

        <GrayLine />

        {/* Title */}
        <TitleContainer ref={titleContainerRef}>
          <FormItem
            label={t("emailDialog:label.title")}
            name="emailTitle"
            control={control}
            type="text"
            rules={{ required: true }}
            inputProps={{
              placeholder: t("emailDialog:placeholder.title"),
              onKeyDown: (e) => {
                if (e.code === "ArrowDown") {
                  recommendRef.current && recommendRef.current.focus();
                }
              },
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
        </TitleContainer>

        <Flex gap={8}>
          <FileTitle as="p" typoType="b7m">
            {t("emailDialog:label.file")}
          </FileTitle>

          <Table
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={files}
            height={176}
            isPagination={false}
            totalPage={files?.length || 0}
            components={{
              ...deleteAction(handleDeleteClick),
            }}
          />
        </Flex>

        {/* Contents */}
        <StyledSectionCardRow
          label={t("emailDialog:label.emailContent")}
          direction="horizontal"
          value={
            <Controller
              control={control}
              name="emailContent"
              render={({ field }) => (
                <StyledTextArea
                  placeholder={t("emailDialog:placeholder.emailContent")}
                  {...field}
                />
              )}
            />
          }
        />
      </Form>
    </Dialog>
  );
}

export default EmailDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  p {
    width: 100%;
  }
`;

const GrayLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const StyledTextArea = styled(BasicTextArea)`
  min-height: 235px;
`;

const RecipientsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const RecipientsForm = styled(FormItem)`
  width: 100%;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const FileTitle = styled(Typo)`
  width: 164px;
  flex-shrink: 0;
`;
