import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";

const renderData = (data: string, isSelectable: boolean) => {
  return data ? (
    <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
      {data}
    </Typo>
  ) : (
    <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
      -
    </Typo>
  );
};

export const columnContainerList: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    lockPosition: "left",
    pinned: "left",
    headerCheckboxSelection: false,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    resizable: false,
    sortable: false,
    width: 42,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Contract No.",
    cellStyle: (params) => {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvent: "none",
      };
    },
    field: "salesNo",
    flex: 1,
    sortable: false,
    minWidth: 110,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(
        params.data.warehousingContainer?.warehousing?.sales.salesNumber,
        isSelectable
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "BL No.",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "shipmentContainer.id",
    flex: 1,
    sortable: false,
    minWidth: 110,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(
        params.data.shipmentContainer.shipment.blNo,
        isSelectable
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Container No.",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "containerNo",
    flex: 1,
    minWidth: 110,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(params.data.containerNo, isSelectable);
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Seal No.",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "sealNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(params.data.sealNo, isSelectable);
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "warehouse",
    flex: 1,
    minWidth: 110,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(
        params.data.warehousingContainer?.warehousing?.sales?.warehouse
          ?.warehouseName,
        isSelectable
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    minWidth: 100,
    sortable: false,
    field: "itemCode",
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(
        params.data.contract.importerItemCode.itemCode,
        isSelectable
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Unit Q’ty",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "unitQuantity",
    minWidth: 80,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.unitQuantity ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {params.data.unitQuantity} {params.data.unitQuantityUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Gross Weight",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "grossWeight",
    minWidth: 110,
    sortable: false,
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.firstWeight || params.data.secondWeight ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {(params.data.firstWeight ? Number(params.data.firstWeight) : 0) -
            (params.data.secondWeight ? Number(params.data.secondWeight) : 0)}

          {params.data.weightUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Net Weight",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "netWeight",
    minWidth: 110,
    sortable: false,
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.netWeight ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {params.data.netWeight}
          {params.data.weightUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
];

export const columnContainerListWithoutSelection: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: { textAlign: "center" },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      const index = params.node?.rowIndex ?? 1;
      return <Typo typoType="b9r">{index + 1}</Typo>;
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Container No.",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "containerNo",
    flex: 1,
    minWidth: 110,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(params.data.containerNo, isSelectable);
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Seal No.",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "sealNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(params.data.sealNo, isSelectable);
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Item Code",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    minWidth: 100,
    sortable: false,
    field: "itemCode",
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return renderData(
        params.data.contract.importerItemCode.itemCode,
        isSelectable
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Unit Q’ty",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "unitQuantity",
    minWidth: 80,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.unitQuantity ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {params.data.unitQuantity} {params.data.unitQuantityUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Gross Weight",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "grossWeight",
    minWidth: 110,
    sortable: false,
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.firstWeight || params.data.secondWeight ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {(params.data.firstWeight ? Number(params.data.firstWeight) : 0) -
            (params.data.secondWeight ? Number(params.data.secondWeight) : 0)}

          {params.data.weightUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Net Weight",
    cellStyle: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    field: "netWeight",
    minWidth: 110,
    sortable: false,
    flex: 1,
    cellRenderer: (params: ValueGetterParams) => {
      const isSelectable = !!params.node?.selectable;
      return params.data.netWeight ? (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          {params.data.netWeight}
          {params.data.weightUnit}
        </Typo>
      ) : (
        <Typo typoType="b9r" color={isSelectable ? "gray2" : "gray7"}>
          -
        </Typo>
      );
    },
  },
];
