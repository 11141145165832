import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import {
  EmailShareHistoryListViewDto,
  GetEmailShareHistoriesRequest,
} from "./interface";

export const emailShareHistoriesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 이메일 공유 이력 조회 API
    getEmailShareHistories: build.query<
      Rows<EmailShareHistoryListViewDto>,
      GetEmailShareHistoriesRequest
    >({
      query: (params) => ({
        url: "/exporters/this/email-share-histories",
        params,
      }),
    }),
  }),
});

export const { useLazyGetEmailShareHistoriesQuery } = emailShareHistoriesApi;
