import { Ref, forwardRef } from "react";
import { Checkbox } from "antd";
import {
  CheckboxGroupProps as AntdCheckboxGroupProps,
  AbstractCheckboxGroupProps,
} from "antd/es/checkbox/Group";
import { styled } from "styled-components";
import typo from "@/src/styles/typography";
import CheckedSvg from "../../../assets/icons/icon-checkbox-check.svg";
import UnCheckedSvg from "../../../assets/icons/icon-checkbox-uncheck.svg";
import DisabledSvg from "../../../assets/icons/icon-checkbox-disabled.svg";
import CheckedDisabledSvg from "../../../assets/icons/icon-checkbox-check-disabled.svg";
import colorSet from "@/src/styles/color";

export interface CheckboxGroupProps extends AntdCheckboxGroupProps {
  options: AbstractCheckboxGroupProps["options"];
}

const CheckboxGroup = forwardRef(
  ({ options, ...props }: CheckboxGroupProps, ref: Ref<HTMLDivElement>) => {
    return (
      <StyledCheckboxGroup options={options} ref={ref} {...props}>
        {options?.map((item, idx) => {
          const renderLabel = () => {
            if (typeof item === "string" || typeof item === "number") {
              return item;
            }

            return item.label;
          };

          return (
            <StyledCheckbox key={idx.toString()}>
              {renderLabel()}
            </StyledCheckbox>
          );
        })}
      </StyledCheckboxGroup>
    );
  }
);

export default CheckboxGroup;

const StyledCheckbox = styled(Checkbox)`
  background: none;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  //label
  & > label {
    gap: 10px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span:not(.ant-checkbox) {
      padding: 0;
      ${typo.b9m};
    }
  }

  // checkbox
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background: url(${UnCheckedSvg}) no-repeat;
    background-position: center;
    border: transparent;
    border-radius: inherit;

    // checkbox click
    &::after {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      transform: translate(0%, 0%);
      background: url(${CheckedSvg}) no-repeat;
      background-position: center;
    }
  }

  // checkbox background
  .ant-checkbox-checked .ant-checkbox-inner {
    border: none;
    background: none;
  }

  // checkbox disabled
  .ant-checkbox-disabled {
    background: url(${DisabledSvg}) no-repeat;
  }

  // checkbox checked hover
  .ant-checkbox-checked:after {
    border: none;
    background: url(${CheckedSvg}) no-repeat;
    background-color: ${colorSet.white};
    border-radius: inherit;
  }

  // checked disabled
  .ant-checkbox-checked.ant-checkbox-disabled {
    background: url(${CheckedDisabledSvg}) no-repeat;
    background-position: center;

    .ant-checkbox-inner::after {
      background: none;
    }
  }
`;
