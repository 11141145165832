/**
 *
 * @param filterData : 검색할 필터의 {key: value...} 형태의 객체
 * @param page : 현재 페이지
 * @param pageSize : 페이지 사이즈
 * @returns
 */

const getQueryString = <T>(data: {
  data?: T;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: "ASC" | "DESC" | null | undefined;
}) => {
  const undefinedDataFilter = Object.entries({
    ...data.data,
    page: data.page,
    pageSize: data.pageSize,
    sort: data.sort,
    order: data.order,
  }).reduce((acc: any, [key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      acc[key] = value;
    }

    return acc;
  }, {});

  const queryString = Object.keys(undefinedDataFilter)
    .map((key) => {
      const value = undefinedDataFilter[key];
      const encodedKey = encodeURIComponent(key);
      const encodedValue = decodeURIComponent(value);

      return `${encodedKey}=${encodedValue}`;
    })
    .join("&");

  return `?${queryString}`;
};

export default getQueryString;
