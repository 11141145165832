import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import React, { CSSProperties, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AirplaneIcon from "@/src/assets/icons/icon-paper-airplane.svg";
import { isUndefined } from "@/src/utils/is";
import { authApi, useSignOutMutation } from "@/src/store/apis/auth";
import { useAppDispatch } from "@/src/store";
import useAlert from "@/src/hooks/useAlert";
import { persistor } from "@/src/index";
import { currencyApi } from "@/src/store/apis/currency";
import { apiSlice } from "@/src/store/apis/baseQuery";
import Flex from "@/src/components/molecule/Flex";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useTranslation } from "react-i18next";

function EnterpriseInquiryRequesting() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [signOut] = useSignOutMutation();
  const { companyType } = authApi.endpoints.getSession.useQueryState(
    undefined,
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isStable,
          companyType: isStable && currentData.row.exporter.companyType,
        };
      },
    }
  );

  const logout = useCallback(async () => {
    try {
      await signOut().unwrap;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.date.message[0]
        : e.date.message;
      alert.showAlert({ message, type: "error" });
    } finally {
      persistor.purge();
      dispatch(currencyApi.util.resetApiState());
      dispatch(apiSlice.util.resetApiState());
      navigate(PUBLIC_PATH.LOGIN, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    // Seller일 경우, 로그아웃
    if (companyType === "SELLER") {
      return logout();
    }

    //  Both일 경우, 수입자 홈으로 이동
    if (companyType === "BOTH" || companyType === "BUYER") {
      return navigate(IMPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
  };

  const renderButton = () => {
    if (companyType === "SELLER") {
      return (
        <Flex gap={8}>
          <StyledButton
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP)}
          >
            {t("enterpriseSignup:button.selectPlan")}
          </StyledButton>
          <StyledButton
            buttonColor="blue"
            buttonGrade="secondary"
            onClick={handleButtonClick}
          >
            {t("enterpriseSignup:button.completed")}
          </StyledButton>
        </Flex>
      );
    }

    if (companyType === "BOTH" || companyType === "BUYER") {
      return (
        <StyledButton
          buttonColor="blue"
          buttonGrade="secondary"
          onClick={handleButtonClick}
        >
          {t("enterpriseSignup:button.home")}
        </StyledButton>
      );
    }
  };
  return (
    <StyledSectionCard>
      <FlexColumn gap={80}>
        {/* Information */}
        <PlanInfo>
          <FlexColumn alignItems="center">
            <Icon iconSrc={AirplaneIcon} iconSize={80} />
          </FlexColumn>

          <FlexColumn gap={12} alignItems="center">
            <Typo typoType="d6">{t("enterpriseSignup:inquiryRequesting")}</Typo>
            <WhiteSpace>
              {t("enterpriseSignup:inquiryRequestDescription")}
            </WhiteSpace>
          </FlexColumn>
        </PlanInfo>

        {/* Button */}
        {renderButton()}
      </FlexColumn>
    </StyledSectionCard>
  );
}

export default EnterpriseInquiryRequesting;

const StyledSectionCard = styled(SectionCardWithoutHeader)`
  width: 640px;
  padding: 40px 48px;
`;

const FlexColumn = styled.div<{
  gap?: number;
  alignItems?: CSSProperties["alignItems"];
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
  align-items: ${({ alignItems }) => alignItems};
  width: 100%;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
  flex: 1;
`;

const WhiteSpace = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
`;
