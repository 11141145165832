import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import HomeSvg from "@/src/assets/icons/icon-home.svg";
import HomeIndigoSvg from "@/src/assets/icons/icon-home-indigo.svg";
import ContractSvg from "@/src/assets/icons/icon-document-copy-white.svg";
import ContractIndigoSvg from "@/src/assets/icons/icon-document-copy-indigo.svg";
import FileBoxIndigoSvg from "@/src/assets/icons/icon-file-box-indigo.svg";
import FileBoxSvg from "@/src/assets/icons/icon-file-box-white.svg";
import NoticeSvg from "@/src/assets/icons/icon-notice.svg";
import NoticeIndigoSvg from "@/src/assets/icons/icon-notice-indigo.svg";
import InquirySvg from "@/src/assets/icons/icon-inquiry.svg";
import InquiryIndigoSvg from "@/src/assets/icons/icon-inquiry-indigo.svg";
import FaqSvg from "@/src/assets/icons/icon-faq.svg";
import FaqIndigoSvg from "@/src/assets/icons/icon-faq-indigo.svg";
import BuildingMultipleIndigoSvg from "@/src/assets/icons/icon-building-multiple-indigo.svg";
import ImportStatusIndigoSvg from "@/src/assets/icons/icon-calendar-today-indigo.svg";
import ImportStatusSvg from "@/src/assets/icons/icon-calendar-today.svg";
import SettingSvg from "@/src/assets/icons/icon-setting.svg";
import PUBLIC_PATH from "@/src/routes/public/path";
import { TFunction } from "i18next";

const IMPORTER_OPERATION_NAV_MENU = [
  {
    iconSrc: HomeIndigoSvg,
    selectedIconSrc: HomeSvg,
    label: "home",
    destination: IMPORTER_PRIVATE_PATH.HOME,
  },
  {
    iconSrc: ContractIndigoSvg,
    selectedIconSrc: ContractSvg,
    label: "importContract",
    destination: IMPORTER_PRIVATE_PATH.CONTRACT,
  },
  {
    iconSrc: ImportStatusIndigoSvg,
    selectedIconSrc: ImportStatusSvg,
    label: "importStatus",
    destination: IMPORTER_PRIVATE_PATH.SHIPMENT,
  },
  {
    iconSrc: ContractIndigoSvg,
    selectedIconSrc: ContractSvg,
    label: "sales",
    destination: IMPORTER_PRIVATE_PATH.SALES_MANAGEMENT,
  },
  {
    iconSrc: FileBoxIndigoSvg,
    selectedIconSrc: FileBoxSvg,
    label: "fileBox",
    destination: IMPORTER_PRIVATE_PATH.FILE_BOX,
  },
];

const IMPORTER_CUSTOMER_SERVICE_MENU = [
  {
    iconSrc: NoticeIndigoSvg,
    selectedIconSrc: NoticeSvg,
    label: "notice",
    destination: IMPORTER_PRIVATE_PATH.NOTICE,
  },
  {
    iconSrc: InquiryIndigoSvg,
    selectedIconSrc: InquirySvg,
    label: "inquiry",
    destination: IMPORTER_PRIVATE_PATH.INQUIRY,
  },
  {
    iconSrc: FaqIndigoSvg,
    selectedIconSrc: FaqSvg,
    label: "faq",
    destination: IMPORTER_PRIVATE_PATH.FAQ,
  },
];

const IMPORTER_MANAGEMENT_NAV_MENU = [
  {
    iconSrc: BuildingMultipleIndigoSvg,
    isOpen: false,
    label: "corporateManagement",
    closeHeight: 72,
    fullHeight: 240,
    includePath: [
      "company-information-management",
      "staff-management",
      "client-management",
    ],
    children: [
      {
        label: `companyInformationManagement`,
        destination: IMPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
      },
      {
        label: "staffManagement",
        destination: IMPORTER_PRIVATE_PATH.STAFF_MANAGEMENT,
      },
      {
        label: "clientManagement",
        destination: IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT,
      },
    ],
  },
  {
    iconSrc: SettingSvg,
    isOpen: false,
    label: "importManagement",
    closeHeight: 72,
    fullHeight: 240,
    includePath: ["item-management", "store-management"],
    children: [
      {
        label: "itemManagement",
        destination: IMPORTER_PRIVATE_PATH.ITEM_MANAGEMENT,
      },
      {
        label: "warehouseManagement",
        destination: IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT,
      },
    ],
  },
];

const IMPORTER_TERMS_POLICY_NAV_MENU = (t: TFunction) => [
  {
    destination: `${PUBLIC_PATH.TERMS}?selectTab=termsOfUse`,
    isBlank: true,
    label: t("sideNav:termsOfUse"),
  },
  {
    destination: `${PUBLIC_PATH.TERMS}?selectTab=privacyPolicy`,
    isBlank: true,
    label: t("sideNav:privacyPolicy"),
  },
];

export {
  IMPORTER_OPERATION_NAV_MENU,
  IMPORTER_CUSTOMER_SERVICE_MENU,
  IMPORTER_MANAGEMENT_NAV_MENU,
  IMPORTER_TERMS_POLICY_NAV_MENU,
};
