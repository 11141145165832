const PRIVATE_PATH = {
  // Private
  ROOT: "/",
  LINK_TO_ACCOUNT: "/link-to-account",
  CHANGE_PASSWORD: "/change-password",
  TWO_FACTOR_AUTHENTICATION: "/two-factor-authentication",
  TEMPORARY_SIGNUP: "/temporary-signup",

  // Email
  SHARED_EMAIL_CONTRACT: "/shared-email/contract",
  SHARED_EMAIL_BOOKING: "/shared-email/booking",
  SHARED_EMAIL_TASK: "/shared-email/task",
  SHARED_EMAIL_SHIPMENT: "/shared-email/shipment",
  SHARED_EMAIL_IMPORT_CONTRACT: "/import/shared-email/contract",
};

export default PRIVATE_PATH;
