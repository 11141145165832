import { useSearchParams } from "react-router-dom";
import ContractAddBasedOnScPage from "./components/add/ContractAddBasedOnSc";
import ContractAddPage from "./components/add/ContractAddPage";

const ImporterContractAddPage = () => {
  const [params] = useSearchParams();
  const isBasedOnPo = !!params.get("id");

  return (
    <>
      {isBasedOnPo ? (
        <ContractAddBasedOnScPage id={Number(params.get("id"))} />
      ) : (
        <ContractAddPage />
      )}
    </>
  );
};

export default ImporterContractAddPage;
