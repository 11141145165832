import Typo from "@/src/components/atom/Typo";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import FooterPng from "@/src/assets/images/img-email-footer.png";
import WorldMapPng from "@/src/assets/images/img-world-map.png";
import Icon from "@/src/components/atom/Icon";
import CheckCircleSvg from "@/src/assets/icons/icon-checkmark-circle-green-fill.svg";
import { Button } from "@/src/components/atom/Button";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useGetEnterpriseInquiriesQuery,
  useGetSubscriptionsQuery,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import { SubscriptionDto } from "@/src/store/apis/subscription/interface";
import useCompanyHomeRedirect from "@/src/hooks/useCompanyHomeRedirect";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useTranslation } from "react-i18next";

const subscriptionEmptyArray: SubscriptionDto[] = [];

function SubscriptionLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyType, exporterUserType, isCompanyTypeFetching } =
    useGetSessionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching }) => {
        return {
          isCompanyTypeFetching: isFetching,
          companyType:
            currentData?.row.exporterUserType === "CORPORATE_MANAGER"
              ? currentData?.row.exporter.companyType
              : currentData?.row.exporterUserMainFieldType,
          exporterUserType: currentData?.row.exporterUserType,
        };
      },
    });

  const { subscriptions, isSubscriptionsFetching } = useGetSubscriptionsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isSubscriptionsFetching: isFetching,
          subscriptions: isStable ? currentData?.rows : subscriptionEmptyArray,
        };
      },
    }
  );

  const { isInquiriesFetching, inquiries } = useGetEnterpriseInquiriesQuery(
    {},
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          // 문의여부
          isInquiriesFetching: isFetching,
          inquiries: isStable ? currentData.rows : [],
        };
      },
    }
  );

  const {
    func: { redirectNonCorporate },
  } = useCompanyHomeRedirect({
    companyType,
    userType: exporterUserType,
    isSubscription:
      subscriptions?.[0]?.subscriptionStatus === "PROGRESS" || false,
  });

  const descriptionList = [
    t("shareEmail:sharedFooter.digitalizedContainerInspection"),
    t("shareEmail:sharedFooter.automaticDocumentation"),
    t("shareEmail:sharedFooter.RealTimeCargoTracking"),
    t("shareEmail:sharedFooter.highUtilizationDashboard"),
  ];

  const handleLogoClick = () => {
    // 구독을 안했거나 수입자 경우
    if (
      companyType === "BUYER" ||
      subscriptions?.[0]?.subscriptionStatus !== "PROGRESS"
    ) {
      return navigate(IMPORTER_PRIVATE_PATH.HOME);
    }

    // BOTH, 수출자
    if (companyType === "BOTH" || companyType === "SELLER") {
      return navigate(EXPORTER_PRIVATE_PATH.HOME);
    }
  };

  const handleNormalPlanSignupClick = () => {
    navigate(EXPORTER_PRIVATE_PATH.NORMAL_PLAN_SIGNUP);
  };

  const redirectRoute = useCallback(() => {
    redirectNonCorporate();
  }, [redirectNonCorporate]);

  const redirectEnterpriseSignup = useCallback(() => {
    if (inquiries.length === 0) {
      return null;
    }

    if (
      inquiries[0]?.status !== "CANCELED" &&
      inquiries[0]?.status !== "SUBSCRIPTION_COMPLETED"
    ) {
      // 취소, 구독이 완료된 상태 외 모두 엔터프라이즈 이동
      return navigate(EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP);
    }
  }, [inquiries, navigate]);

  const renderContent = () => {
    if (
      isCompanyTypeFetching ||
      isSubscriptionsFetching ||
      isInquiriesFetching
    ) {
      return <></>;
    }

    return (
      <SignUpLayout onLogoClick={handleLogoClick}>
        <StyledMain>
          <CopyrightText typoType="h1">
            {t("subscriptionLanding:copyrightText")}
          </CopyrightText>

          <Content>
            <Image src={FooterPng} />

            <ListContainer>
              <WorldMapImage src={WorldMapPng} />
              <List>
                {descriptionList.map((string, idx) => {
                  return (
                    <ListItem key={idx.toString()}>
                      <StyledIcon iconSrc={CheckCircleSvg} iconSize={24} />

                      <Typo typoType="b7m">{string}</Typo>
                    </ListItem>
                  );
                })}
              </List>
            </ListContainer>

            <StyledButton onClick={handleNormalPlanSignupClick}>
              {t("subscriptionLanding:button.subscriptionSignUp")}
            </StyledButton>
          </Content>
        </StyledMain>
      </SignUpLayout>
    );
  };

  useEffect(() => {
    redirectRoute();
  }, [redirectRoute]);

  useEffect(() => {
    redirectEnterpriseSignup();
  }, [redirectEnterpriseSignup]);

  return renderContent();
}

export default SubscriptionLandingPage;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 560px;
  padding: 24px 16px;
  margin: 0 auto;
`;

const CopyrightText = styled(Typo)`
  white-space: pre-wrap;
  text-align: center;
  padding-bottom: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Image = styled.img`
  width: 528px;
  height: 396px;
  object-fit: cover;
  border-radius: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

const WorldMapImage = styled.img`
  width: 225px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;
