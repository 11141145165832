import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { keyframes, styled } from "styled-components";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import RightArrowIcon from "../SectionCard/RightArrowIcon";

interface SectionFoldableCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  cardTitle?: ReactNode;
  rightAccessary?: ReactNode;
  cardContentContainerStyle?: CSSProperties;
  containerClassName?: string;
}

const SectionFoldableCard = ({
  open,
  onOpenChange,
  cardTitle,
  rightAccessary,
  cardContentContainerStyle,
  children,
  containerClassName,
  ...props
}: SectionFoldableCardProps) => {
  const isCardTitleString = typeof cardTitle === "string";
  const defaultOpen = useRef<boolean>(open);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const originalStyleRef = useRef<Record<string, string>>();

  useEffect(() => {
    defaultOpen.current = false;
  }, []);

  useLayoutEffect(() => {
    const container = containerRef.current;

    if (container) {
      originalStyleRef.current = originalStyleRef.current || {
        transitionDuration: container.style.transitionDuration,
        animationName: container.style.animationName,
      };

      container.style.transitionDuration = "0s";
      container.style.animationName = "none";

      const { height } = container.getBoundingClientRect();
      container.style.setProperty(
        "--card-content-container-height",
        `${height}px`
      );

      if (!defaultOpen.current) {
        container.style.transitionDuration =
          originalStyleRef.current.transitionDuration;
        container.style.animationName = originalStyleRef.current.animationName;
      }
    }
  }, [open]);

  const renderContent = () => {
    return (
      <>
        {children && (
          <CardContentContainer
            style={cardContentContainerStyle}
            ref={containerRef}
            className={`${containerClassName} ${open ? "open" : "close"}`}
          >
            <div className="animation-wrapper">{children}</div>
          </CardContentContainer>
        )}
      </>
    );
  };

  return (
    <SectionCardArticle {...props}>
      <CardHeader>
        {isCardTitleString ? <CardTitle>{cardTitle}</CardTitle> : cardTitle}

        {rightAccessary ? (
          <RightAccessaryContainer>
            <RightArrowIcon
              isCardOpen={open}
              onClick={() => onOpenChange(!open)}
            />
          </RightAccessaryContainer>
        ) : null}
      </CardHeader>

      {renderContent()}
    </SectionCardArticle>
  );
};

export default SectionFoldableCard;

const SectionCardArticle = styled.article`
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #0000000d;
`;

const CardTitle = styled.h2`
  ${typo.h6}
`;

const RightAccessaryContainer = styled.div`
  margin-left: auto;
`;

const CardHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 48px;
  background: ${colorSet.gray11};
  border-radius: 8px;

  ${CardTitle} {
    flex: 1;
  }
`;

const close = keyframes` 
  0% {
    height: var(--card-content-container-height);
  }

  100% {
    height: 0px;
  }
`;

const open = keyframes`
  0% {
    height: 0px;
  }

  100% {
    height: var(--card-content-container-height);
  }
`;

const CardContentContainer = styled.div`
  .animation-wrapper {
    padding: 24px 16px;
  }

  &.open {
    animation: ${open} 500ms forwards;
  }

  &.close {
    animation: ${close} 500ms forwards;
    overflow: hidden;
  }
`;
