import { ForwardedRef, forwardRef } from "react";
import {
  RadialLinearScale,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Legend,
  Title,
  BarElement,
  Tooltip,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Doughnut, ChartProps as ReactChartJsProps } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title,
  BarElement
);

interface DoughnutChartProps
  extends Omit<ReactChartJsProps<"doughnut">, "type"> {
  options?: ChartOptions<"doughnut">;
  data: ChartData<"doughnut">;
}

const DoughnutChart = forwardRef(
  (
    { options, data, ...restProps }: DoughnutChartProps,
    ref: ForwardedRef<ChartJSOrUndefined<"doughnut">>
  ) => {
    return <Doughnut ref={ref} data={data} options={options} {...restProps} />;
  }
);

export default DoughnutChart;
