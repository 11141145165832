import { RouteObject } from "react-router-dom";
import PRIVATE_PATH from "./path";
import ProtectedPrivateRoute from "@/components/template/ProtectedPrivateRoute";
import NotFoundPage from "@/src/pages/private/NotFound";
import SharedShipmentPage from "@/src/pages/private/SharedEmail/SharedShipment";
import SharedContractPage from "@/src/pages/private/SharedEmail/SharedContract";
import SharedBookingPage from "@/src/pages/private/SharedEmail/SharedBooking";
import SharedTaskPage from "@/src/pages/private/SharedEmail/SharedTask";
import LinkToAccountPage from "@/src/pages/private/LinkToAccount";
import TemporarySignupPage from "@/pages/public/TemporarySignup";
import TwoFactorAuthenticationPage from "@/src/pages/private/TwoFactorAuthentication";
import ChangePasswordPage from "@/src/pages/private/ChangePassword";
import SharedImportContractPage from "@/src/pages/private/SharedEmail/SharedImportContract";

const privateRoutes: RouteObject[] = [
  {
    path: PRIVATE_PATH.ROOT,
    element: <ProtectedPrivateRoute />,
    errorElement: <NotFoundPage />,
  },
  {
    path: PRIVATE_PATH.CHANGE_PASSWORD,
    element: <ChangePasswordPage />,
  },
  {
    path: PRIVATE_PATH.TWO_FACTOR_AUTHENTICATION,
    element: <TwoFactorAuthenticationPage />,
  },
  { path: PRIVATE_PATH.SHARED_EMAIL_CONTRACT, element: <SharedContractPage /> },
  { path: PRIVATE_PATH.SHARED_EMAIL_BOOKING, element: <SharedBookingPage /> },
  { path: PRIVATE_PATH.SHARED_EMAIL_TASK, element: <SharedTaskPage /> },
  {
    path: PRIVATE_PATH.SHARED_EMAIL_SHIPMENT,
    element: <SharedShipmentPage />,
  },
  {
    path: PRIVATE_PATH.SHARED_EMAIL_IMPORT_CONTRACT,
    element: <SharedImportContractPage />,
  },
  {
    path: PRIVATE_PATH.LINK_TO_ACCOUNT,
    element: <LinkToAccountPage />,
  },
  {
    path: PRIVATE_PATH.TEMPORARY_SIGNUP,
    element: <TemporarySignupPage />,
  },
];
export default privateRoutes;
