export const isUndefined = (value: any): value is undefined =>
  typeof value === "undefined";

export const isBoolean = (value: any): value is boolean =>
  typeof value === "boolean";

export const isNull = (value: any) => value === null;

export const isNullish = (value: any | null | undefined) =>
  value === null || value === undefined;
