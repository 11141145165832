import { apiSlice } from "../baseQuery";
import { Row } from "../type";
import {
  DispatcherKeyType,
  DispatcherOptionDto,
  GenerateDispatcherOptionDto,
  ModifyDispatcherOptionDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["dispatcherOption"],
});

const dispatcherOptionApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 자신의 디스패처 옵션 단일 조회 API
    getDispatcherOption: build.query<DispatcherOptionDto, DispatcherKeyType>({
      query: (dispatcherKey) => ({
        url: `/exporter-users/this/dispatcherOption/${dispatcherKey}`,
      }),
      transformResponse: (res: Row<DispatcherOptionDto>) => res.row,
    }),

    /**
     *@description
     * 구독 관리 페이지에서 알림 방식
     * 알림 생성 및 수정시 2번 api 요청 (dispatcherKey)
     * SUBSCRIPTION_PAYMENT_NOTICE: 결제 예정 알림
     * PAYMENT_SUCCESS: 결제 성공
     *
     * 대신 조회할때는 하나만 **PAYMENT_SUCCESS**
     */
    // 디스패처 옵션 생성
    addDispatcherOption: build.mutation<
      DispatcherOptionDto,
      GenerateDispatcherOptionDto
    >({
      query: (body) => ({
        url: "/exporter-users/this/dispatcherOption",
        method: "POST",
        body,
      }),
      transformResponse: (res: Row<DispatcherOptionDto>) => res.row,
    }),

    // 디스패처 옵션 단일 수정 API
    updateDispatcherOption: build.mutation<
      DispatcherOptionDto,
      ModifyDispatcherOptionDto & { dispatcherKey: DispatcherKeyType }
    >({
      query: ({ dispatcherKey, ...body }) => ({
        url: `/exporter-users/this/dispatcherOption/${dispatcherKey}`,
        method: "PUT",
        body,
      }),
      transformResponse: (res: Row<DispatcherOptionDto>) => res.row,
    }),
  }),
});

export const {
  useGetDispatcherOptionQuery,
  useLazyGetDispatcherOptionQuery,
  useAddDispatcherOptionMutation,
  useUpdateDispatcherOptionMutation,
} = dispatcherOptionApi;
