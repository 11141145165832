import Typo from "@/src/components/atom/Typo";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";
import BookmarkSvg from "@/src/assets/icons/icon-bookmark.svg";
import Icon from "@/src/components/atom/Icon";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import { renderShipmentStatusBadge } from "../utils/renderShipmentStatusBadge";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import getTableIndex from "@/src/utils/getTableIndex";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import styled from "styled-components";
import ErrorCircleSvg from "@/src/assets/icons/icon-error-circle-fill.svg";

export const columnShipmentList: ColDef[] = [
  {
    headerName: "No.",
    headerClass: "ag-right-aligned-cell",
    field: "no",
    lockPosition: "left",
    pinned: "left",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    width: 70,
    minWidth: 70,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <TypoContainer>
          <ErrorCircle>
            {params.data.isCiOrPlRecheckNeeded && (
              <Icon iconSrc={ErrorCircleSvg} />
            )}
          </ErrorCircle>

          <Typo typoType="b9r">
            {params.node?.rowIndex
              ? getTableIndex(
                  params.node?.rowIndex + 1,
                  params.data.page,
                  params.data.pageSize
                )
              : getTableIndex(1, params.data.page, params.data.pageSize)}
          </Typo>
        </TypoContainer>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "isBookmarked",
    lockPosition: "left",
    pinned: "left",
    width: 70,
    minWidth: 70,
    resizable: true,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isBookmarked === true) {
        return <Icon iconSrc={BookmarkSvg} iconSize={18} />;
      }

      return null;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "shipmentStatus",
    flex: 1,
    minWidth: 143,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data) {
        return renderShipmentStatusBadge({
          type: params.data.shipmentStatus,
          lang: params.data.lang,
          t: params.data.t,
        });
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "blNo",
    flex: 1,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.blNo ? (
        <TableBlueText typoType="b9r" color="systemBlue2">
          {params.data.blNo}
        </TableBlueText>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "bookingNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.bookingNo ? (
        <Typo typoType="b9r">{params.data.bookingNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "mainCategoryCodeItemName",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.mainCategory ? (
        <Typo typoType="b9r">{params.data.mainCategory}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 180,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "scNo",
    flex: 1,
    minWidth: 140,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.scNo ? (
        <Typo typoType="b9r">{params.data.scNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "etdAt",
    flex: 1,
    minWidth: 180,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etdAt) {
        return (
          <>
            <Typo typoType="b9r">
              {dayjs(params.data.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
            </Typo>{" "}
            <EtdCompareCount
              initDate={params.data.initialEtdAt || ""}
              currentDate={params.data.etdAt || ""}
              typoType="b9m"
            />
          </>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "etaAt",
    flex: 1,
    minWidth: 140,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.etaAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "docCutOffAt",
    flex: 1,
    minWidth: 220,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.docCutOffAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.docCutOffAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "numberOfShipmentContainer",
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return (
        <Typo typoType="b9r">
          {params.data.numberOfShipmentContainer
            ? params.data.numberOfShipmentContainer
            : "-"}
        </Typo>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "totalNetWeight",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.totalNetWeight ? (
        <Typo typoType="b9r">{params.data.totalNetWeight}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "portOfLoading",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.portOfLoading ? (
        <Typo typoType="b9r">{params.data.portOfLoading}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "workplaceDescription",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.workplaceDescription ? (
        <Typo typoType="b9r">{params.data.workplaceDescription}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "earliestTaskDate",
    flex: 1,
    minWidth: 158,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.earliestTaskDate ? (
        <Typo typoType="b9r">
          {dayjs(params.data.earliestTaskDate).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )}
        </Typo>
      ) : (
        "-"
      );
    },
  },
];

const TypoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 6px;
`;

const ErrorCircle = styled.div`
  display: flex;
  align-items: center;
  width: 14px;
  height: 14px;
`;
