/**
 * @description 대문자 영어문자열 첫글자 대문자 및 소문자 변환
 */
export const convertToManagerType = (type: string) => {
  const convertedType = type.replace(/_/g, " ").toLowerCase();
  return convertedType.charAt(0).toUpperCase() + convertedType.slice(1);
};

/**
 * @description 이메일 앞 @ *** 마스킹
 * @param email
 * @param removeLength : 제거될 문자열 개수 기본값 3
 */
export const changeEmailCharacters = (
  email: string,
  removeLength: number = 3
) => {
  const atIndex = email.indexOf("@");
  const emailPrefix = email.substring(0, atIndex);
  const modifiedEmail =
    emailPrefix.substring(0, emailPrefix.length - removeLength) +
    "***" +
    email.substring(atIndex);

  return modifiedEmail;
};

/**
 * @description 퍼센트 구하기
 */
export const calculatePercentage = (part: number, total: number) => {
  return Math.floor((part / total) * 100);
};

/**
 * @description 메인 키테고리 이름형식 Metal, Glass ...
 */
export const formatCategoryName = (item: string[]) => {
  const categoryString = item
    .map((item) => item[0] + item.slice(1).toLowerCase().replace(/_/g, "-"))
    .join(", ");

  return categoryString;
};
