import dayjs from "dayjs";
import { currencyApiSlice } from "../baseQuery";

const currencyApi = currencyApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCurrency: build.query<
      {
        base: string;
        rates: {
          AUD: number;
          CAD: number;
          CNY: number;
          EUR: number;
          GBP: number;
          JPY: number;
          KRW: number;
          USD: number;
        };
      },
      { base: string }
    >({
      query: ({ base }) => ({
        url: "/api/latest.json",
        params: {
          app_id: process.env.REACT_APP_EXCHANGE_RATES_API_APP_ID,
          symbols: "USD,EUR,CNY,GBP,AUD,JPY,CAD,KRW",
          base,
        },
      }),
    }),
    getOneDayBeforeCurrency: build.query<
      {
        base: string;
        rates: {
          AUD: number;
          CAD: number;
          CNY: number;
          EUR: number;
          GBP: number;
          JPY: number;
          KRW: number;
          USD: number;
        };
      },
      { base: string }
    >({
      query: ({ base }) => ({
        url: `/api/historical/${dayjs
          .utc()
          .subtract(1, "day")
          .format("YYYY-MM-DD")}.json`,
        params: {
          app_id: process.env.REACT_APP_EXCHANGE_RATES_API_APP_ID,
          symbols: "USD,EUR,CNY,GBP,AUD,JPY,CAD,KRW",
          base,
        },
      }),
    }),
  }),
});

export const { useGetCurrencyQuery, useGetOneDayBeforeCurrencyQuery } =
  currencyApi;

export { currencyApi };
