import React from "react";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface TaskCompletedAlertDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onOk: () => void;
}

function TaskCompletedAlertDialog({
  open,
  onOpenChange,
  onOk,
}: TaskCompletedAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t("task:detail.alert.confirmTitle")}
      onOpenChange={onOpenChange}
      onOk={onOk}
      okText={t("task:detail.button.okButton")}
      cancelText={t("task:detail.button.cancelButton")}
    >
      {t("task:detail.alert.confirmDescription")}
    </AlertDialog>
  );
}

export default TaskCompletedAlertDialog;
