import { PropsWithChildren, ReactNode } from "react";
import {
  QAlertDialogRoot,
  QAlertDialogPortal,
  QAlertDialogTitle,
  QAlertDialogCloseButton,
  QAlertDialogDescription,
  QAlertDialogOverlay,
  QAlertDialogContent,
} from "../../quantum/AlertDialogQuantum";
import styled, { keyframes } from "styled-components";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { Button } from "../Button";
import zIndex from "@/src/styles/zIndex";

interface AlertDialogProps {
  title: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  cancelText?: ReactNode;
  onCancel?: () => void;
  okText?: ReactNode;
  onOk?: () => void;
  width?: number;
  description?: ReactNode;
  isOkLoading?: boolean;
  isOkDisabled?: boolean;
}

function AlertDialog({
  open,
  onOpenChange,
  title,
  cancelText = "Later",
  onCancel,
  okText,
  onOk,
  width = 305,
  description,
  children,
  isOkLoading,
  isOkDisabled,
  ...rest
}: PropsWithChildren<AlertDialogProps>) {
  return (
    <QAlertDialogRoot open={open} onOpenChange={onOpenChange}>
      <QAlertDialogPortal>
        <StyledOverlay />

        <StyledDialogContent width={width} {...rest}>
          <div className="alert-dialog-container">
            <StyledTitle>{title}</StyledTitle>
            <StyledDescription>{description || children}</StyledDescription>
          </div>

          <ButtonContainer>
            {cancelText && (
              <StyledCancelButton onClick={() => onCancel && onCancel()}>
                {cancelText}
              </StyledCancelButton>
            )}
            {okText && (
              <StyledOkButton
                isLoading={isOkLoading}
                disabled={isOkDisabled}
                onClick={onOk}
              >
                {okText}
              </StyledOkButton>
            )}
          </ButtonContainer>
        </StyledDialogContent>
      </QAlertDialogPortal>
    </QAlertDialogRoot>
  );
}

export default AlertDialog;

const overlayKeyframe = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const StyledOverlay = styled(QAlertDialogOverlay)`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  inset: 0;
  animation: ${overlayKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: ${zIndex.alertDialog};
`;

const StyledDialogContent = styled(QAlertDialogContent)<{ width: number }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${({ width }) => width}px;
  padding: 20px;
  background: ${colorSet.white};
  border-radius: 12px;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: ${zIndex.alertDialog};

  .alert-dialog-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const StyledTitle = styled(QAlertDialogTitle)`
  margin: 0;
  color: ${colorSet.gray2};
  ${typo.h4}
`;

const StyledCancelButton = styled(QAlertDialogCloseButton)`
  border-radius: 8px;
  border: none;
  color: ${colorSet.gray2};
  box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
    0px 1px 2px rgba(5, 29, 57, 0.1);
  background: ${colorSet.white};
  cursor: pointer;
  ${typo.btn3m}
  &:hover {
    box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
      0px 2px 4px rgba(5, 29, 57, 0.2);
  }
  &:active {
    box-shadow: 0px 0px 0px 1px rgba(5, 29, 57, 0.6),
      0px 0px 0px 2px rgba(5, 29, 57, 0.2), 0px 2px 4px rgba(5, 29, 57, 0.2);
  }
  &:disabled {
    background: ${colorSet.white};
    color: ${colorSet.gray8};
    box-shadow: 0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
      0px 1px 2px rgba(5, 29, 57, 0.1);
    pointer-events: none;
  }
`;

const StyledOkButton = styled(Button)``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  ${StyledCancelButton}, ${StyledOkButton} {
    padding: 10px 0;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
  }
`;

const StyledDescription = styled(QAlertDialogDescription)`
  color: ${colorSet.gray5};
  ${typo.b8r}

  white-space: pre-line;
`;
