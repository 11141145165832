export const SHARED_SEARCH_PARAMS_STRINGS = {
  contractShareKey: "contractShareKey",
  bookingShareKey: "bookingShareKey",
  taskShareKey: "taskShareKey",
  shipmentShareKey: "shipmentShareKey",
  importContractShareKey: "importContractShareKey",

  preview: "preview",
  template: "template",

  contractId: "contractId",
  bookingId: "bookingId",
  taskId: "taskId",
  shipmentId: "shipmentId",
  importContractId: "importContractId",
};
