import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import Flex from "@/src/components/molecule/Flex";
import { useAppDispatch, useAppSelector } from "@/src/store";
import {
  PaymentCycleType,
  PlanDto,
  PlanVersionDto,
  SubscriptionStatusType,
  SubscriptionType,
} from "@/src/store/apis/subscription/interface";
import { closeEnterprisePlanDialog } from "@/src/store/slice/subscription";
import styled from "styled-components";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-Info-blue4-fill.svg";
import {
  subscriptionApi,
  useCreateSubscriptionsMutation,
  useGetPaymentMethodsQuery,
  useGetSubscriptionsQuery,
  useLazyGetCurrentSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import colorSet from "@/src/styles/color";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IconCheck from "@/src/assets/icons/icon-check-shape.svg";
import Icon from "@/src/components/atom/Icon";
import useAlert from "@/src/hooks/useAlert";
import { useTranslation } from "react-i18next";

const emptyPlanArray: PlanDto[] = [];

function EnterprisePlanRegisterConfirm() {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const open = useAppSelector(
    (state) => state.subscription.isEnterpriseInquiries
  );

  // API
  const [getCurrentSubscription] = useLazyGetCurrentSubscriptionQuery();
  const [createSubscriptions, { isLoading: isCreateSubscriptionLoading }] =
    useCreateSubscriptionsMutation();
  const [updateSubscription, { isLoading: isUpdateSubscriptionLoading }] =
    useUpdateSubscriptionMutation();
  const { payments } = useGetPaymentMethodsQuery(
    {
      page: 1,
      pageSize: 2,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnStable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnStable;

        return {
          payments: isStable ? currentData.rows : [],
        };
      },
    }
  );
  const { plans } = subscriptionApi.endpoints.getPlans.useQueryState(
    {},
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          plans: isStable ? currentData.rows : emptyPlanArray,
        };
      },
    }
  );

  // 구독 목록 조회
  const { subscriptionId } = useGetSubscriptionsQuery(
    {
      page: 1,
      pageSize: 10,
    },
    {
      selectFromResult: ({ currentData }) => {
        return {
          subscriptionId: currentData?.rows?.[0].id,
        };
      },
    }
  );

  const paymentMethod = payments.filter((item) => item.isRepresentative)[0];

  const enterprisePlan = plans.filter(({ planType, planVersionList }) => {
    const releaseAt = dayjs(
      dayjs(planVersionList?.[0].releasedAt).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD
      )
    );
    const duration = releaseAt.diff(dayjs(), "days");
    // 엔터프라이즈, 오늘날짜 기준 반영일자가 포함되는 날
    return planType === "ENTERPRISE" && duration >= 0;
  })[0];

  const planVersion = enterprisePlan.planVersionList?.[0] as PlanVersionDto;
  const {
    currencyUnit,
    monthlyFee,
    numberOfLoadingCode,
    numberOfMember,
    annualFee,
    paymentCycle,
  } = planVersion;

  const goToEnterprisePlanInquiryPage = () => {
    return navigate(
      `${EXPORTER_PRIVATE_PATH.ENTERPRISE_SIGNUP}?isInquiry=true`
    );
  };

  const handleSubscribeChangeConfirmClick = async () => {
    if (!subscriptionId) {
      return;
    }

    try {
      // 즉시 해지
      const res = await updateSubscription({
        id: subscriptionId,
        subscriptionEndAtTo: dayjs().toISOString(),
        isImmediateCancel: true,
        subscriptionStatus: "CANCELED",
      }).unwrap();

      // 구독 등록
      if (res && enterprisePlan && user) {
        const { planVersionList, id: enterprisePlanId } = enterprisePlan;

        await createSubscriptions({
          subscriptionStatus: "PROGRESS" as SubscriptionStatusType,
          subscriptionType: "STANDARD" as SubscriptionType,
          paymentCycle: planVersionList?.[0].paymentCycle as PaymentCycleType,
          exporterId: user.exporter.id,
          planId: enterprisePlanId,
          planVersionId: planVersionList?.[0].id as number,
          paymentMethodId: paymentMethod.id,
        }).unwrap();

        await getCurrentSubscription();
        dispatch(closeEnterprisePlanDialog());

        alert.showAlert({
          type: "success",
          message: t("alert:saveSuccess"),
        });
      }
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const renderCard = () => {
    if (!enterprisePlan) {
      return null;
    }

    const releaseAt = dayjs(planVersion.releasedAt).format(
      DATE_FORMAT_STRINGS.YYYY_MM_DD
    );

    const paymentPrice = paymentCycle === "MONTHLY" ? monthlyFee : annualFee;
    const paymentCycleText = paymentCycle === "MONTHLY" ? "월" : "연";

    return (
      <Card>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column" gap={8}>
            <Flex alignItems="center" gap={4}>
              <Typo typoType="b7m">{enterprisePlan.name}</Typo>
            </Flex>

            <Typo typoType="d6">
              {paymentCycleText} {Number(paymentPrice)?.toLocaleString("ko-KR")}{" "}
              {currencyUnit}
            </Typo>
          </Flex>

          <StyledButton
            buttonColor="blue"
            buttonGrade="secondary"
            onClick={goToEnterprisePlanInquiryPage}
          >
            문의하기
          </StyledButton>
        </Flex>
        <Divider />
        <ul className="gap-8">
          <li>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">활성화 계정 {numberOfMember}명</Typo>
            </div>
          </li>
          <li>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">
                로딩 코드 {numberOfLoadingCode}회 전송 가능
              </Typo>
            </div>
            <div>
              <Icon iconSrc={IconCheck} iconSize={16} />
              <Typo typoType="b9r">플랜 등록 유효기간: {releaseAt}</Typo>
            </div>
          </li>
        </ul>
      </Card>
    );
  };

  return (
    <Dialog
      title="플랜 구독"
      open={open}
      onOpenChange={() => dispatch(closeEnterprisePlanDialog())}
      width={800}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => {
              dispatch(closeEnterprisePlanDialog());
            }}
          >
            취소
          </Button>
          <Button
            disabled={
              isUpdateSubscriptionLoading || isCreateSubscriptionLoading
            }
            isLoading={
              isUpdateSubscriptionLoading || isCreateSubscriptionLoading
            }
            onClick={handleSubscribeChangeConfirmClick}
          >
            구독하기
          </Button>
        </DialogFooterContainer>
      }
    >
      <Title typoType="d6">영업팀 승인 완료</Title>
      <Flex flexDirection="column" gap={16}>
        <CallOut borderColor="blue9" backgroundColor="blue10">
          <Flex alignItems="center" gap={8}>
            <InfoIcon />
            <Typo typoType="b9r" color="indigo">
              Enterprise 플랜 구독 후 Export 서비스 이용이 가능합니다.
            </Typo>
          </Flex>
        </CallOut>
        {renderCard()}
      </Flex>
    </Dialog>
  );
}

export default EnterprisePlanRegisterConfirm;

const Title = styled(Typo)`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const InfoIcon = styled(InfoSvg)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  flex: 1;

  & > ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  & > .gap-16 {
    gap: 16px;
  }

  & > .gap-8 {
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  text-align: center;
  height: 100%;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;
