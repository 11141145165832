import { apiSlice } from "../baseQuery";
import { Rows } from "../type";
import { FileDto, SendEmailWithFileDto } from "./interface";

type FileParamsType = {
  contractId: number;
  fileClass:
    | "ALL"
    | "CONTRACT"
    | "BOOKING"
    | "TASK"
    | "SHIPMENT"
    | "WAREHOUSING";
};

const fileApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getExporterFile: build.query<Rows<FileDto>, FileParamsType>({
      query: (params) => ({
        url: "/exporter/file-box",
        params,
      }),
    }),
    sendExporterFile: build.mutation<void, SendEmailWithFileDto>({
      query: (body) => ({
        url: "/exporter/file-box/emails",
        body,
        method: "POST",
      }),
    }),
    getImporterFile: build.query<Rows<FileDto>, FileParamsType>({
      query: (params) => ({
        url: "/importer/file-box",
        params,
      }),
    }),
    sendImporterFile: build.mutation<void, SendEmailWithFileDto>({
      query: (body) => ({
        url: "/importer/file-box/emails",
        body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetExporterFileQuery,
  useGetImporterFileQuery,
  useSendExporterFileMutation,
  useSendImporterFileMutation,
} = fileApi;
