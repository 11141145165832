import { useEffect, useState } from "react";
import { css, styled } from "styled-components";
import dayjs from "dayjs";
import { Button, IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import SectionCard from "@/src/components/molecule/SectionCard";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import OpenSvg from "@/src/assets/icons/icon-open.svg";
import Typo from "@/src/components/atom/Typo";
import colorSet, { ColorType } from "@/src/styles/color";
import { downloadFile } from "@/src/utils/downloadFile";
import { useSearchParams } from "react-router-dom";
import useAlert from "@/src/hooks/useAlert";
import CiDownLoad from "@/src/components/template/pdfs/Ci/DownLoad";
import PlDownLoad from "@/src/components/template/pdfs/Pl/DownLoad";
import CiPreview from "@/src/components/template/pdfs/Ci/Preview";
import PlPreview from "@/src/components/template/pdfs/Pl/Preview";
import { renderDocumentStatusBadge } from "@/src/pages/exporter/Shipment/utils/renderShipmentStatusBadge";
import AllFileCard from "./AllFileCard";
import DocumentsExternalCard from "./DocumentsExternalCard";
import {
  useLazyGetShipmentShareCiInfoQuery,
  useLazyGetShipmentShareDocumentInfoQuery,
  useLazyGetShipmentSharePlInfoQuery,
} from "@/src/store/apis/shipments/shipmentShare";
import { useBlnoToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import { isNull, isUndefined } from "@/src/utils/is";
import {
  BlStatusType,
  CiStatusType,
  InternalScFileInfo,
  PlStatusType,
  SimpleDocumentMediaDto,
  SimpleFileMediaDto,
} from "@/src/store/apis/shipments/shipmentDetail/interface";
import { useLazyGetShipmentDetailDocumentAndMemoQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { CiData } from "@/src/components/template/pdfs/Ci/types";
import { PlData } from "@/src/components/template/pdfs/Pl/types";
import { useLazyGetCiInformationQuery } from "@/src/store/apis/shipments/ci";
import { useLazyGetPlInformationQuery } from "@/src/store/apis/shipments/pl";
import { useTranslation } from "react-i18next";

type DocumentType = "CI" | "PL" | "BL";

// 해당 요청은 ciStatus가 CI_FINAL인 경우에만 가능합니다.
// 해당 요청은 plStatus가 PL_FINAL인 경우에만 가능합니다.
const CI_ERROR_CODE =
  "SHIPMENT_INQUIRY_CI_INFO_BY_SHIPMENT_SHARE_KEY_INVALID_CI_STATUS";
const PL_ERROR_CODE =
  "SHIPMENT_INQUIRY_PL_INFO_BY_SHIPMENT_SHARE_KEY_INVALID_PL_STATUS";

interface ShipmentWithDocumentProps {
  sharedShipmentId: number;
  shipmentShareKey: string;
}

const scEmptyArray: InternalScFileInfo[] = [];
const documentEmptyArray: SimpleDocumentMediaDto[] = [];
const simpleDocumentEmptyArray: SimpleDocumentMediaDto[] = [];
const fileEmptyArray: SimpleFileMediaDto[] = [];

function ShipmentWithDocument({
  sharedShipmentId,
  shipmentShareKey,
}: ShipmentWithDocumentProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const [allButtonRef, setAllButtonRef] = useState<HTMLButtonElement[]>([]);

  const isPreview = searchParams.get("preview") === "true";
  const previewShipmentId = Number(searchParams.get("shipmentId"));

  // Preview API
  const [
    getShipmentDocument,
    {
      previewIsSuccess,
      previewBlNo,
      previewBlStatus,
      previewShippingLine,
      previewShippingLineUrl,
      previewBlMediaUrl,
      previewBlMediaOriginalFileName,
      previewCiStatus,
      previewPlStatus,
      previewExternalSimpleDocumentMedias,
      previewInternalScFileInfos,
      previewScAttactedFileSimpleFileMedias,
      previewPoFileSimpleFileMedias,
      previewLcFileSimpleFileMedias,
      previewBookingFileSimpleFileMedias,
    },
  ] = useLazyGetShipmentDetailDocumentAndMemoQuery({
    selectFromResult: ({ isError, currentData, isSuccess, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        previewIsSuccess: isSuccess,
        previewBlNo: !isUnstable ? currentData.row.blNo : "",
        previewBlStatus: currentData?.row.blStatus as BlStatusType,
        previewShippingLine: !isUnstable
          ? currentData.row.shippingLine ?? ""
          : "",
        previewShippingLineUrl: !isUnstable
          ? currentData.row.shippingLineUrl
          : "",
        previewBlMediaUrl: currentData?.row.blMediaUrl,
        previewBlMediaOriginalFileName:
          currentData?.row.blMediaOriginalFileName,
        previewBlMediaId: currentData?.row.blMediaId,
        previewCiStatus: currentData?.row.ciStatus as CiStatusType,
        previewPlStatus: currentData?.row.plStatus as PlStatusType,
        previewInternalSimpleDocumentMedias: !isUnstable
          ? currentData.row.internalSimpleDocumentMedias ??
            simpleDocumentEmptyArray
          : simpleDocumentEmptyArray,
        previewExternalSimpleDocumentMedias: !isUnstable
          ? currentData.row.externalSimpleDocumentMedias ??
            simpleDocumentEmptyArray
          : simpleDocumentEmptyArray,
        previewInternalScFileInfos: !isUnstable
          ? currentData.row.internalScFileInfos ?? scEmptyArray
          : scEmptyArray,
        previewScAttactedFileSimpleFileMedias: !isUnstable
          ? currentData.row.scAttactedFileSimpleFileMedias ?? fileEmptyArray
          : fileEmptyArray,
        previewPoFileSimpleFileMedias: !isUnstable
          ? currentData.row.poFileSimpleFileMedias ?? fileEmptyArray
          : fileEmptyArray,
        previewLcFileSimpleFileMedias: !isUnstable
          ? currentData.row.lcFileSimpleFileMedias ?? fileEmptyArray
          : fileEmptyArray,
        previewBookingFileSimpleFileMedias: !isUnstable
          ? currentData.row.bookingFileSimpleFileMedias ?? fileEmptyArray
          : fileEmptyArray,
      };
    },
  });
  const [
    getCiPreview,
    {
      data: previewCiData,
      isFetching: isPreviewCiFetching,
      isError: isPreviewCiError,
      isSuccess: isPreviewCiSuccess,
    },
  ] = useLazyGetCiInformationQuery();
  const [
    getPlPreview,
    {
      data: previewPlData,
      isFetching: isPreviewPlFetching,
      isError: isPreviewPlError,
      isSuccess: isPreviewPlSuccess,
    },
  ] = useLazyGetPlInformationQuery();

  // API
  const [
    getShipmentShareDocumentInfo,
    {
      blNo,
      isSuccess,
      shippingLine,
      shippingLineUrl,
      blMediaUrl,
      blMediaOriginalFileName,
      ciStatus,
      plStatus,
      blStatus,
      internalScFileInfos,
      scAttactedFileSimpleFileMedias,
      poFileSimpleFileMedias,
      lcFileSimpleFileMedias,
      bookingFileSimpleFileMedias,
      externalSimpleDocumentMedias,
    },
  ] = useLazyGetShipmentShareDocumentInfoQuery({
    selectFromResult: ({ currentData, isError, isSuccess, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        isSuccess,
        blNo: !isUnstable ? currentData?.row.blNo : "-",
        shippingLine: !isUnstable ? currentData?.row.shippingLine ?? "-" : "-",
        shippingLineUrl: !isUnstable
          ? currentData.row.shippingLineUrl ?? ""
          : "-",
        blMediaUrl: !isUnstable ? currentData.row.blMediaUrl ?? "" : "",
        blMediaOriginalFileName: !isUnstable
          ? currentData.row.blMediaOriginalFileName ?? ""
          : "",
        ciStatus: currentData?.row.ciStatus as CiStatusType,
        plStatus: currentData?.row.plStatus as PlStatusType,
        blStatus: currentData?.row.blStatus as BlStatusType,
        internalScFileInfos: !isUnstable
          ? currentData?.row.internalScFileInfos ?? scEmptyArray
          : scEmptyArray,
        scAttactedFileSimpleFileMedias: !isUnstable
          ? currentData.row.scAttactedFileSimpleFileMedias ?? documentEmptyArray
          : documentEmptyArray,
        poFileSimpleFileMedias: !isUnstable
          ? currentData.row.poFileSimpleFileMedias ?? documentEmptyArray
          : documentEmptyArray,
        lcFileSimpleFileMedias: !isUnstable
          ? currentData.row.lcFileSimpleFileMedias ?? documentEmptyArray
          : documentEmptyArray,
        bookingFileSimpleFileMedias: !isUnstable
          ? currentData.row.bookingFileSimpleFileMedias ?? documentEmptyArray
          : documentEmptyArray,
        externalSimpleDocumentMedias: !isUnstable
          ? currentData.row.externalSimpleDocumentMedias ?? documentEmptyArray
          : documentEmptyArray,
      };
    },
  });
  const [
    getCi,
    {
      data: ciData,
      isFetching: isCiFetching,
      isError: isCiError,
      isSuccess: isCiSuccess,
    },
  ] = useLazyGetShipmentShareCiInfoQuery();
  const [
    getPl,
    {
      data: plData,
      isFetching: isPlFetching,
      isError: isPlError,
      isSuccess: isPlSuccess,
    },
  ] = useLazyGetShipmentSharePlInfoQuery();
  const [blnoToSeaVantageUrl] = useBlnoToSeaVantageUrlMutation();

  const documentData = {
    blNo: isPreview ? previewBlNo : blNo,
    shippingLine: isPreview ? previewShippingLine : shippingLine,
    shippingLineUrl: isPreview ? previewShippingLineUrl : shippingLineUrl,
    blMediaUrl: isPreview ? previewBlMediaUrl : blMediaUrl,
    blMediaOriginalFileName: isPreview
      ? previewBlMediaOriginalFileName
      : blMediaOriginalFileName,

    ciStatus: isPreview ? previewCiStatus : ciStatus,
    plStatus: isPreview ? previewPlStatus : plStatus,
    blStatus: isPreview ? previewBlStatus : blStatus,
  };

  const mediaFileData = {
    internalScFile: isPreview
      ? previewInternalScFileInfos
      : internalScFileInfos,

    scAttachmentFile: isPreview
      ? previewScAttactedFileSimpleFileMedias
      : scAttactedFileSimpleFileMedias,

    poFile: isPreview ? previewPoFileSimpleFileMedias : poFileSimpleFileMedias,
    lcFile: isPreview ? previewLcFileSimpleFileMedias : lcFileSimpleFileMedias,

    bookingFile: isPreview
      ? previewBookingFileSimpleFileMedias
      : bookingFileSimpleFileMedias,

    externalSimpleDocumentMedias: isPreview
      ? previewExternalSimpleDocumentMedias
      : externalSimpleDocumentMedias,
  };

  const totalOfContainer = plData?.plItems.reduce<{
    quantityUnit: string[];
    quantity: number;
    grossWeight: number;
    grossWeightUnit: string;
    netWeight: number;
    netWeightUnit: string;
    cbm: number;
    isGrossWeightHidden: boolean;
    isNetWeightHidden: boolean;
    isCbmHidden: boolean;
  }>(
    (acc, val) => {
      const totalOfsingleContainer = val.plItemContainers.reduce<{
        quantityUnit: string[];
        quantity: number;
        grossWeight: number;
        grossWeightUnit: string;
        netWeight: number;
        netWeightUnit: string;
        cbm: number;
        isGrossWeightHidden: boolean;
        isNetWeightHidden: boolean;
        isCbmHidden: boolean;
      }>(
        (a, v) => {
          return {
            quantityUnit: v.quantityUnit
              ? a.quantityUnit.includes(v.quantityUnit)
                ? a.quantityUnit
                : a.quantityUnit.concat(v.quantityUnit)
              : a.quantityUnit,
            quantity: a.quantity + (v?.quantity || 0),
            grossWeight:
              a.grossWeight +
              (Number.isNaN(Number(v?.grossWeight))
                ? 0
                : Number(v?.grossWeight)),
            grossWeightUnit: v.grossWeightUnit,
            netWeight:
              a.netWeight +
              (Number.isNaN(Number(v?.netWeight)) ? 0 : Number(v?.netWeight)),
            netWeightUnit: v.netWeightUnit,
            cbm: a.cbm + (isNull(v?.cbm) ? 0 : Number(v?.cbm) || 0),
            isGrossWeightHidden: a.isGrossWeightHidden
              ? true
              : v.isGrossWeightHidden,
            isNetWeightHidden: a.isNetWeightHidden ? true : v.isNetWeightHidden,
            isCbmHidden: a.isCbmHidden ? true : v.isCbmHidden,
          };
        },
        {
          quantityUnit: [],
          quantity: 0,
          grossWeight: 0,
          grossWeightUnit: "",
          netWeight: 0,
          netWeightUnit: "",
          cbm: 0,
          isGrossWeightHidden: false,
          isNetWeightHidden: false,
          isCbmHidden: false,
        }
      );

      return {
        quantityUnit: [
          ...acc.quantityUnit,
          ...totalOfsingleContainer.quantityUnit,
        ],
        quantity: acc.quantity + totalOfsingleContainer.quantity,
        grossWeight: acc.grossWeight + totalOfsingleContainer.grossWeight,
        grossWeightUnit: totalOfsingleContainer.grossWeightUnit,
        netWeight: acc.netWeight + totalOfsingleContainer.netWeight,
        netWeightUnit: totalOfsingleContainer.netWeightUnit,
        cbm: acc.cbm + totalOfsingleContainer.cbm,
        isGrossWeightHidden: acc.isGrossWeightHidden
          ? true
          : totalOfsingleContainer.isGrossWeightHidden,
        isNetWeightHidden: acc.isNetWeightHidden
          ? true
          : totalOfsingleContainer.isNetWeightHidden,
        isCbmHidden: acc.isCbmHidden
          ? true
          : totalOfsingleContainer.isCbmHidden,
      };
    },
    {
      quantityUnit: [],
      quantity: 0,
      grossWeight: 0,
      grossWeightUnit: "",
      netWeight: 0,
      netWeightUnit: "",
      cbm: 0,
      isGrossWeightHidden: false,
      isNetWeightHidden: false,
      isCbmHidden: false,
    }
  );
  const previewTotalOfContainer = previewPlData?.plItems.reduce<{
    quantityUnit: string[];
    quantity: number;
    grossWeight: number;
    grossWeightUnit: string;
    netWeight: number;
    netWeightUnit: string;
    cbm: number;
    isGrossWeightHidden: boolean;
    isNetWeightHidden: boolean;
    isCbmHidden: boolean;
  }>(
    (acc, val) => {
      const totalOfsingleContainer = val.plItemContainers.reduce<{
        quantityUnit: string[];
        quantity: number;
        grossWeight: number;
        grossWeightUnit: string;
        netWeight: number;
        netWeightUnit: string;
        cbm: number;
        isGrossWeightHidden: boolean;
        isNetWeightHidden: boolean;
        isCbmHidden: boolean;
      }>(
        (a, v) => {
          return {
            quantityUnit: v.quantityUnit
              ? a.quantityUnit.includes(v.quantityUnit)
                ? a.quantityUnit
                : a.quantityUnit.concat(v.quantityUnit)
              : a.quantityUnit,
            quantity: a.quantity + (v?.quantity || 0),
            grossWeight:
              a.grossWeight +
              (Number.isNaN(Number(v?.grossWeight))
                ? 0
                : Number(v?.grossWeight)),
            grossWeightUnit: v.grossWeightUnit,
            netWeight:
              a.netWeight +
              (Number.isNaN(Number(v?.netWeight)) ? 0 : Number(v?.netWeight)),
            netWeightUnit: v.netWeightUnit,
            cbm: a.cbm + (isNull(v?.cbm) ? 0 : Number(v?.cbm) || 0),
            isGrossWeightHidden: a.isGrossWeightHidden
              ? true
              : v.isGrossWeightHidden,
            isNetWeightHidden: a.isNetWeightHidden ? true : v.isNetWeightHidden,
            isCbmHidden: a.isCbmHidden ? true : v.isCbmHidden,
          };
        },
        {
          quantityUnit: [],
          quantity: 0,
          grossWeight: 0,
          grossWeightUnit: "",
          netWeight: 0,
          netWeightUnit: "",
          cbm: 0,
          isGrossWeightHidden: false,
          isNetWeightHidden: false,
          isCbmHidden: false,
        }
      );

      return {
        quantityUnit: [
          ...acc.quantityUnit,
          ...totalOfsingleContainer.quantityUnit,
        ],
        quantity: acc.quantity + totalOfsingleContainer.quantity,
        grossWeight: acc.grossWeight + totalOfsingleContainer.grossWeight,
        grossWeightUnit: totalOfsingleContainer.grossWeightUnit,
        netWeight: acc.netWeight + totalOfsingleContainer.netWeight,
        netWeightUnit: totalOfsingleContainer.netWeightUnit,
        cbm: acc.cbm + totalOfsingleContainer.cbm,
        isGrossWeightHidden: acc.isGrossWeightHidden
          ? true
          : totalOfsingleContainer.isGrossWeightHidden,
        isNetWeightHidden: acc.isNetWeightHidden
          ? true
          : totalOfsingleContainer.isNetWeightHidden,
        isCbmHidden: acc.isCbmHidden
          ? true
          : totalOfsingleContainer.isCbmHidden,
      };
    },
    {
      quantityUnit: [],
      quantity: 0,
      grossWeight: 0,
      grossWeightUnit: "",
      netWeight: 0,
      netWeightUnit: "",
      cbm: 0,
      isGrossWeightHidden: false,
      isNetWeightHidden: false,
      isCbmHidden: false,
    }
  );

  // Shared Data Fetching
  const mountWhenFetch = async () => {
    try {
      await getShipmentShareDocumentInfo({
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getPlData = async () => {
    try {
      await getPl({
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.errorCode === PL_ERROR_CODE) {
        return;
      }
      alert.showAlert({ type: "error", message });
    }
  };
  const getCiData = async () => {
    try {
      await getCi({
        shipmentShareKey,
        shipmentId: sharedShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.errorCode === CI_ERROR_CODE) {
        return;
      }

      alert.showAlert({ type: "error", message });
    }
  };

  // Preview Data Fetching
  const getPreviewData = async () => {
    try {
      await getShipmentDocument({ id: previewShipmentId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getPreviewPlData = async () => {
    try {
      await getPlPreview({ id: previewShipmentId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };
  const getPreviewCiData = async () => {
    try {
      await getCiPreview({ id: previewShipmentId }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  useEffect(() => {
    if (isPreview) {
      getPreviewData();
      getPreviewPlData();
      getPreviewCiData();
    } else {
      mountWhenFetch();
      getPlData();
      getCiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlnoClick = async () => {
    if (!documentData.blNo) {
      return;
    }

    try {
      const { cargoTrackUrl } = await blnoToSeaVantageUrl({
        blNo: documentData.blNo,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const renderCiPdfButtons = () => {
    if (
      (isPreview &&
        (isPreviewCiFetching || isPreviewCiError || !isPreviewCiSuccess)) ||
      previewCiStatus === "CI_TEMPORARY"
    ) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    if (!isPreview && (isCiFetching || isCiError || !isCiSuccess)) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    const ciInfoToCiData = {
      ...ciData,
      etdAt: ciData?.etdAt
        ? dayjs.utc(ciData.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: ciData?.lcInfoList
        ? ciData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs.utc(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: ciData?.invoiceDateAt
        ? dayjs.utc(ciData.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: ciData?.ciItems?.[0]?.netWeightUnit,
      componyLogoUrl: ciData?.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: ciData?.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault
      )?.mediaUrl,
      totalIsGrossWeightHidden: ciData?.ciItems.some(
        ({ isGrossWeightHidden }) => isGrossWeightHidden
      ),
      totalIsNetWeightHidden: ciData?.ciItems.some(
        ({ isNetWeightHidden }) => isNetWeightHidden
      ),
    } as CiData;

    const ciInfoToCiPreviewData = {
      ...previewCiData,
      etdAt: previewCiData?.etdAt
        ? dayjs.utc(previewCiData.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: previewCiData?.lcInfoList
        ? previewCiData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs.utc(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: previewCiData?.invoiceDateAt
        ? dayjs.utc(previewCiData.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: previewCiData?.ciItems?.[0]?.netWeightUnit,
      componyLogoUrl: previewCiData?.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: previewCiData?.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault
      )?.mediaUrl,
      totalIsGrossWeightHidden: previewCiData?.ciItems.some(
        ({ isGrossWeightHidden }) => isGrossWeightHidden
      ),
      totalIsNetWeightHidden: previewCiData?.ciItems.some(
        ({ isNetWeightHidden }) => isNetWeightHidden
      ),
    } as CiData;

    return (
      <>
        <CiPreview
          data={isPreview ? ciInfoToCiPreviewData : ciInfoToCiData}
          trigger={
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <PreviewIcon />
            </IconButton>
          }
        />

        <CiDownLoad
          data={isPreview ? ciInfoToCiPreviewData : ciInfoToCiData}
          fileName={`invoice no. ${ciData?.invoiceNo}.pdf`}
          trigger={
            <IconButton
              ref={(node) => {
                if (node) {
                  const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                    buttonNode.isSameNode(node)
                  );

                  if (!isAlreadyInRef) {
                    setAllButtonRef(allButtonRef.concat(node));
                  }
                }
              }}
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      </>
    );
  };

  const renderDocumentDownloadButtons = (
    type: DocumentType,
    status: "TEMPORARY" | "FINAL" | null
  ) => {
    switch (type) {
      case "CI":
        if (!status) {
          return <></>;
        }

        return (
          <DocumentButtonContainer>
            {renderCiPdfButtons()}
          </DocumentButtonContainer>
        );

      case "PL":
        if (!status) {
          return <></>;
        }

        return (
          <DocumentButtonContainer>
            {renderPlPdfButtons()}
          </DocumentButtonContainer>
        );

      case "BL":
        if (!status) {
          return <></>;
        }

        return (
          <>
            <BlDocumentInfo>
              <Typo typoType="b7r">Shipping Line</Typo>
              <BlDocumentName>
                <BlName typoType="b7r" color="gray5">
                  {documentData.shippingLine}
                </BlName>
                {documentData.shippingLineUrl && (
                  <IconButton
                    buttonSize={24}
                    buttonColor="black"
                    buttonGrade="tertiary"
                    onClick={() => {
                      window.open(documentData.shippingLineUrl, "_blank");
                    }}
                  >
                    <Icon iconSrc={OpenSvg} iconSize={20} />
                  </IconButton>
                )}
              </BlDocumentName>
            </BlDocumentInfo>

            <DocumentButtonContainer>
              <IconButton
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                disabled={!documentData.blMediaUrl}
                onClick={() => {
                  window.open(documentData.blMediaUrl, "_blank");
                }}
              >
                <PreviewIcon disabled={!documentData.blMediaUrl} />
              </IconButton>
              <IconButton
                ref={(node) => {
                  if (node) {
                    const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                      buttonNode.isSameNode(node)
                    );

                    if (!isAlreadyInRef) {
                      setAllButtonRef(allButtonRef.concat(node));
                    }
                  }
                }}
                buttonSize={32}
                buttonColor="black"
                buttonGrade="tertiary"
                disabled={!documentData.blMediaUrl}
                onClick={() => {
                  if (documentData.blMediaUrl) {
                    downloadFile(
                      documentData.blMediaUrl ?? "",
                      documentData.blMediaOriginalFileName
                    );
                  }
                }}
              >
                <DownloadIcon disabled={!documentData.blMediaUrl} />
              </IconButton>
            </DocumentButtonContainer>
          </>
        );
    }
  };

  const renderPlPdfButtons = () => {
    if (
      (isPreview &&
        (isPreviewPlFetching ||
          isPreviewPlError ||
          !isPreviewPlSuccess ||
          !previewTotalOfContainer)) ||
      previewPlStatus === "PL_TEMPORARY"
    ) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    if (
      !isPreview &&
      (isPlFetching || isPlError || !isPlSuccess || !totalOfContainer)
    ) {
      return (
        <>
          <IconButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <PreviewIcon disabled />
          </IconButton>
          <IconButton
            disabled
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
          >
            <DownloadIcon disabled />
          </IconButton>
        </>
      );
    }

    const plInfoToPlData = {
      ...plData,
      etdAt: plData?.etdAt
        ? dayjs.utc(plData?.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: plData?.lcInfoList
        ? plData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs.utc(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: plData?.invoiceDateAt
        ? dayjs.utc(plData?.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: totalOfContainer?.grossWeightUnit,
      componyLogoUrl: plData?.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: plData?.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault
      )?.mediaUrl,
      totalQuantityUnit: totalOfContainer?.quantityUnit,
      totalQuantity: totalOfContainer?.quantity,
      totalGrossWeight: totalOfContainer?.grossWeight,
      totalGrossWeightUnit: totalOfContainer?.grossWeightUnit,
      totalNetWeight: totalOfContainer?.netWeight,
      totalNetWeightUnit: totalOfContainer?.netWeightUnit,
      totalCbm: totalOfContainer?.cbm,
      totalIsGrossWeightHidden: totalOfContainer?.isGrossWeightHidden,
      totalIsNetWeightHidden: totalOfContainer?.isNetWeightHidden,
      totalIsCbmHidden: totalOfContainer?.isCbmHidden,
    } as PlData;

    const plInfoToPreviewPlData = {
      ...previewPlData,
      etdAt: previewPlData?.etdAt
        ? dayjs.utc(previewPlData?.etdAt).format("YYYY-MM-DD")
        : undefined,
      lcInfoList: previewPlData?.lcInfoList
        ? previewPlData.lcInfoList.map(({ lcNo, expireDateAt }) => {
            return {
              lcNo,
              expireDateAt: dayjs.utc(expireDateAt).format("YYYY-MM-DD"),
            };
          })
        : [],
      invoiceDateAt: previewPlData?.invoiceDateAt
        ? dayjs.utc(previewPlData?.invoiceDateAt).format("YYYY-MM-DD")
        : undefined,
      commonWeightUnit: previewTotalOfContainer?.grossWeightUnit,
      componyLogoUrl: previewPlData?.businessLogoSimpleMedia?.mediaUrl,
      signatureUrl: previewPlData?.simpleSignatureMedias?.find(
        ({ isDefault }) => isDefault
      )?.mediaUrl,
      totalQuantityUnit: previewTotalOfContainer?.quantityUnit,
      totalQuantity: previewTotalOfContainer?.quantity,
      totalGrossWeight: previewTotalOfContainer?.grossWeight,
      totalGrossWeightUnit: previewTotalOfContainer?.grossWeightUnit,
      totalNetWeight: previewTotalOfContainer?.netWeight,
      totalNetWeightUnit: previewTotalOfContainer?.netWeightUnit,
      totalCbm: previewTotalOfContainer?.cbm,
      totalIsGrossWeightHidden: previewTotalOfContainer?.isGrossWeightHidden,
      totalIsNetWeightHidden: previewTotalOfContainer?.isNetWeightHidden,
      totalIsCbmHidden: previewTotalOfContainer?.isCbmHidden,
    } as PlData;

    return (
      <>
        <PlPreview
          data={isPreview ? plInfoToPreviewPlData : plInfoToPlData}
          trigger={
            <IconButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <PreviewIcon />
            </IconButton>
          }
        />
        <PlDownLoad
          data={isPreview ? plInfoToPreviewPlData : plInfoToPlData}
          trigger={
            <IconButton
              ref={(node) => {
                if (node) {
                  const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                    buttonNode.isSameNode(node)
                  );

                  if (!isAlreadyInRef) {
                    setAllButtonRef(allButtonRef.concat(node));
                  }
                }
              }}
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
            >
              <DownloadIcon />
            </IconButton>
          }
        />
      </>
    );
  };

  return (
    <DocumentMemoContainer>
      <SectionCard
        cardTitle={t("shipment:exporter.detail.label.shippingDocuments")}
        rightAccessory={
          <StyledButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => {
              allButtonRef.forEach((buttonNode) => buttonNode.click());
            }}
          >
            <DownloadIcon size={16} />
            {t("shipment:exporter.detail.button.downloadButton")}
          </StyledButton>
        }
      >
        <ShippingDocument>
          <ShippingDocumentItem>
            <DocumentTitle>
              <Typo typoType="h3">C/I</Typo>
              {renderDocumentStatusBadge({
                t,
                type:
                  documentData.ciStatus === "CI_TEMPORARY"
                    ? "TEMPORARY_REGISTER"
                    : documentData.ciStatus === "CI_FINAL"
                    ? "COMPLETED"
                    : "BEFORE_REGISTRATION",
              })}
            </DocumentTitle>{" "}
            {renderDocumentDownloadButtons(
              "CI",
              documentData.ciStatus === "CI_TEMPORARY"
                ? "TEMPORARY"
                : documentData.ciStatus === "CI_FINAL"
                ? "FINAL"
                : null
            )}
          </ShippingDocumentItem>

          <ShippingDocumentItem>
            <DocumentTitle>
              <Typo typoType="h3">P/L</Typo>
              {renderDocumentStatusBadge({
                t,
                type:
                  documentData.plStatus === "PL_TEMPORARY"
                    ? "TEMPORARY_REGISTER"
                    : documentData.plStatus === "PL_FINAL"
                    ? "COMPLETED"
                    : "BEFORE_REGISTRATION",
              })}
            </DocumentTitle>{" "}
            {renderDocumentDownloadButtons(
              "PL",
              documentData.plStatus === "PL_TEMPORARY"
                ? "TEMPORARY"
                : documentData.plStatus === "PL_FINAL"
                ? "FINAL"
                : null
            )}
          </ShippingDocumentItem>

          <ShippingDocumentItem>
            <DocumentTitle>
              <BlTextContainer>
                <Typo typoType="h3">B/L No.</Typo>
                {documentData.blNo && (
                  <BlueText
                    typoType="h4"
                    color="systemBlue2"
                    onClick={handleBlnoClick}
                  >
                    {documentData.blNo}
                  </BlueText>
                )}
              </BlTextContainer>
              {renderDocumentStatusBadge({
                t,
                type:
                  documentData.blStatus === "BL_DRAFT"
                    ? "DRAFT"
                    : documentData.blStatus === "BL_FINAL"
                    ? "FINAL"
                    : "BEFORE_REGISTRATION",
              })}
            </DocumentTitle>
            {renderDocumentDownloadButtons(
              "BL",
              documentData.blStatus === "BL_DRAFT"
                ? "TEMPORARY"
                : documentData.blStatus === "BL_FINAL"
                ? "FINAL"
                : null
            )}
          </ShippingDocumentItem>
        </ShippingDocument>
      </SectionCard>

      {/* Cards */}
      <CardContainer>
        <AllFileCard
          shipmentShareKey={shipmentShareKey}
          sharedShipmentId={sharedShipmentId}
          isSuccess={isPreview ? previewIsSuccess : isSuccess}
          internalScFile={mediaFileData.internalScFile}
          scAttachmentFile={mediaFileData.scAttachmentFile}
          poFile={mediaFileData.poFile}
          lcFile={mediaFileData.lcFile}
          bookingFile={mediaFileData.bookingFile}
        />

        <DocumentsExternalCard
          data={mediaFileData.externalSimpleDocumentMedias}
        />
      </CardContainer>
    </DocumentMemoContainer>
  );
}

export default ShipmentWithDocument;

const DocumentMemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ShippingDocument = styled.section`
  display: flex;
  gap: 24px;
`;

const DocumentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ShippingDocumentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 24px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  gap: 16px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
  size?: number;
}>`
  width: ${({ size }) => (size ? `${size}px` : "20px")};
  height: ${({ size }) => (size ? `${size}px` : "20px")};

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 20px;
  height: 20px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const DocumentButtonContainer = styled.div`
  display: flex;
  gap: 8px;

  a {
    line-height: 0px;
  }
`;

const BlDocumentInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BlDocumentName = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BlName = styled(Typo)`
  word-break: break-all;
`;

const BlTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;
