import zIndex from "@/src/styles/zIndex";
import { styled } from "styled-components";
import PulseLoader from "react-spinners/PulseLoader";
import colorSet from "@/src/styles/color";

function FullLoading() {
  return (
    <FullLoadingOverlay>
      <PulseLoader color={colorSet.blue4} />
    </FullLoadingOverlay>
  );
}

export default FullLoading;

const FullLoadingOverlay = styled.div`
  z-index: ${zIndex.fullLoading};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
`;
