import SectionCard from "@/src/components/molecule/SectionCard";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import { downloadFile } from "@/src/utils/downloadFile";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { StyledScroll } from "@/src/styles/scroll";
import Typo from "@/src/components/atom/Typo";
import typo from "@/src/styles/typography";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import PoDownLoad from "@/src/components/template/pdfs/Po/DownLoad";
import PoPreview from "@/src/components/template/pdfs/Po/Preview";
import Icon from "@/src/components/atom/Icon";
import PreviewBlackSvg from "@/src/assets/icons/icon-preview-black.svg";
import DownloadBlackSvg from "@/src/assets/icons/icon-download-black.svg";
import PreviewBlueSvg from "@/src/assets/icons/icon-preview-blue.svg";
import DownloadBlueSvg from "@/src/assets/icons/icon-download-blue.svg";
import dayjs from "dayjs";
import { Button, IconButton } from "@/src/components/atom/Button";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import renderContractStatusBadge from "@/src/pages/exporter/Contract/utils/renderContractStatusBadge";
import styled from "styled-components";
import colorSet from "@/src/styles/color";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { SHARED_SEARCH_PARAMS_STRINGS } from "../../constants";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import { isNull } from "@/src/utils/is";
import { ContractDetailViewForImporterDto } from "@/src/store/apis/contracts/contractDetail/interface";
import getPoPdfData from "@/src/components/template/pdfs/Po/utils/getPoPdfData";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type ImportContractItemDataType = ContractDetailViewForImporterDto &
  ContractDetailShareInfoDto;

interface ImportContractItemProps {
  contractData: ImportContractItemDataType;
  isError?: boolean;
}

function ImportContractItem({
  contractData,
  isError,
}: ImportContractItemProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isPreview =
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.preview) === "true";

  const commonData = isPreview ? contractData : contractData?.importerInfo;
  const sharedData = {
    ...commonData,
    buyerName: isPreview
      ? contractData?.buyerName
      : contractData?.importerInfo?.companyName,
    orderDateAt: isPreview
      ? contractData?.importerOrderDateAt
      : contractData?.importerInfo?.importerOrderDateAt,
  };

  const scPdfData = getPoPdfData({
    type: "ADD",
    contractAddAndEditPoData: {
      sellerCorporation: sharedData.buyerName,
      sellerContactPrefix: sharedData.telPrefix,
      sellerContact: sharedData.tel,
      sellerFaxPrefix: sharedData.faxPrefix,
      sellerFax: sharedData.fax,
      sellerCountryName: sharedData.buyerCountryName,
      sellerRegion: sharedData.buyerRegion,
      sellerPostalCode: sharedData.buyerPostalCode,
      sellerLocality: sharedData.buyerLocality,
      sellerStreetAddress: sharedData.buyerStreetAddress,
      sellerContactPersonName: sharedData.buyerEmployeeName,

      poNumber: sharedData.poNo || "",
      orderDate: sharedData.orderDateAt
        ? dayjs(sharedData.orderDateAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
        : "",
      paymentTerm: sharedData.paymentTerms || "",
      paymentTermsRemark: sharedData.paymentTermsRemark || "",
      originLocation: sharedData.origin || "",
      shippingTerm: sharedData.shippingTerms || "",
      shippingTermRemark: sharedData.shippingTermsRemark || "",
      lastShipmentDate: sharedData.lastShipmentDateAt
        ? dayjs(sharedData.lastShipmentDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )
        : "",
      deliveryDate: sharedData.deliveryDateAt
        ? dayjs(sharedData.deliveryDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )
        : "",
      quantityPrefix: sharedData.quantityUnit || "",
      quantity: sharedData.quantity
        ? Number(
            sharedData.quantity.toString()?.replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      description: sharedData.importerItem || "",
      hsCode: sharedData.importerHsCode || "",
      unitPricePrefix: sharedData.unitPriceUnit || "",
      unitPrice: sharedData.unitPrice
        ? Number(
            sharedData.unitPrice.toString().replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      amount:
        (sharedData.quantity &&
          sharedData.unitPrice &&
          Number(
            (
              (parseFloat(sharedData.quantity.toString().replace(/,/g, "")) ||
                0) *
              (parseFloat(sharedData.unitPrice.toString().replace(/,/g, "")) ||
                0)
            ).toFixed(2)
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        "",
      remark: sharedData.contractRemark || "",
      freeTime: sharedData.freetime ?? "",
      placeOfDelivery: sharedData.placeOfDelivery || "",
      isAddContractTerms: !!sharedData.isAddContractTerms,
    },
  });

  const renderBadgeContainer = () => {
    return (
      <HeaderContainer>
        {sharedData.contractStatus &&
          renderContractStatusBadge({
            t,
            type: sharedData.contractStatus as ContractStatusType,
            size: "L",
          })}

        {!sharedData.scSimpleMedia?.id && (
          <PdfButtonContainer>
            <PoDownLoad
              fileName={`SC NO.${sharedData.scNo}.pdf`}
              data={scPdfData}
              trigger={
                <StyledButton
                  buttonSize={32}
                  buttonColor="black"
                  buttonGrade="tertiary"
                >
                  <Icon iconSrc={DownloadBlackSvg} iconSize={20} />
                  {t("shareEmail:sharedContract.buttons.download")}
                </StyledButton>
              }
            />
            <PoPreview
              data={scPdfData}
              trigger={
                <StyledButton
                  buttonSize={32}
                  buttonColor="black"
                  buttonGrade="tertiary"
                >
                  <Icon iconSrc={PreviewBlackSvg} iconSize={20} />
                  {t("shareEmail:sharedContract.buttons.preview")}
                </StyledButton>
              }
            />
          </PdfButtonContainer>
        )}
      </HeaderContainer>
    );
  };

  const renderContractNoneTitleCard = () => {
    return (
      <SectionCardGrid>
        <StyledSectionCardColumnSix
          label={
            <Typo color="gray6" typoType="b9m">
              Exporter
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {sharedData.buyerName ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Shipping Terms
            </Typo>
          }
          direction="vertical"
          value={
            <ShippingTermsContainer>
              <Typo typoType="h4">{`${sharedData.shippingTerms ?? "-"}`}</Typo>
              {sharedData.shippingTermsRemark && (
                <BreakWordTypo typoType="h4">{`${sharedData.shippingTermsRemark}`}</BreakWordTypo>
              )}
            </ShippingTermsContainer>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Exporter Contact
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {sharedData.buyerEmployeeName ?? "-"}
            </BreakWordTypo>
          }
        />

        <HorizenGrayLine />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Item
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {sharedData.importerItem ?? "-"}
            </BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Qty
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              sharedData.quantity && sharedData.quantityUnit
                ? `${Number(sharedData.quantity).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${sharedData.quantityUnit}`
                : "-"
            } `}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Unit Price
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{`${
              sharedData.unitPrice && sharedData.unitPriceUnit
                ? `${Number(sharedData.unitPrice).toLocaleString("ko-KR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} ${sharedData.unitPriceUnit}`
                : "-"
            }`}</BreakWordTypo>
          }
        />
        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Amount
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {`${
                sharedData.quantity && sharedData.unitPrice
                  ? `${parseFloat(
                      (
                        Number(sharedData.quantity) *
                        Number(sharedData.unitPrice)
                      ).toFixed(2)
                    ).toLocaleString("ko-KR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${sharedData.unitPriceUnit}`
                  : "-"
              }`}
            </BreakWordTypo>
          }
        />
      </SectionCardGrid>
    );
  };

  const renderContractInformationCard = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.detail.contractInformationCard.title")}
      >
        <ContractInformationFlexColumn>
          {/* Card */}
          <ContractDateContainer>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Payment Terms
              </Typo>
              <PaymentTermsContainer>
                <BreakWordTypo color="blue2" typoType="h4">
                  {sharedData.paymentTerms
                    ? `${sharedData.paymentTerms} ${
                        sharedData.paymentTermsRemark
                          ? sharedData.paymentTermsRemark
                          : ""
                      }`
                    : "-"}
                </BreakWordTypo>
              </PaymentTermsContainer>
            </ContractDateCard>
            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Order Date
              </Typo>
              <BreakWordTypo color="blue2" typoType="h4">
                {sharedData.orderDateAt
                  ? dayjs.utc(sharedData.orderDateAt).format("YYYY-MM-DD")
                  : "-"}
              </BreakWordTypo>
            </ContractDateCard>

            {sharedData.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  Expire Date
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {sharedData.expireDateAt
                    ? dayjs.utc(sharedData.expireDateAt).format("YYYY-MM-DD")
                    : "-"}
                </Typo>
              </ContractDateCard>
            )}

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Last Shipment Date
              </Typo>
              <Typo color="blue2" typoType="h4">
                {sharedData.lastShipmentDateAt
                  ? dayjs
                      .utc(sharedData.lastShipmentDateAt)
                      .format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>

            <ContractDateCard>
              <Typo color="gray6" typoType="b9m">
                Delivery Date
              </Typo>
              <Typo color="blue2" typoType="h4">
                {sharedData.deliveryDateAt
                  ? dayjs.utc(sharedData.deliveryDateAt).format("YYYY-MM-DD")
                  : "-"}
              </Typo>
            </ContractDateCard>
          </ContractDateContainer>

          <ContractInformationGridContainer>
            <ContractInformationGridItem>
              {/* Location */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Location
                </Typo>
                <section>
                  <SectionCardRow
                    label={"Place of Delivery"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.placeOfDelivery ??
                          sharedData.anyPortIn ??
                          "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={"Origin / Location"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.origin ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  {sharedData.allocation && (
                    <SectionCardRow
                      label={"Allocation"}
                      value={
                        <BreakWordTypo typoType="b7r" color="gray5" as="p">
                          {sharedData.allocation ?? "-"}
                        </BreakWordTypo>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>

              {/* Attachment File */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Attachment File
                </Typo>
                <section>
                  <SectionCardRow
                    label={"PO No."}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {sharedData.poNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.poSimpleMedia)}
                          >
                            {isNull(sharedData.poSimpleMedia) ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    sharedData.poSimpleMedia?.mediaUrl ?? "",
                                    sharedData.poSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.poSimpleMedia)}
                          >
                            {isNull(sharedData.poSimpleMedia) ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={sharedData.poSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  <SectionCardRow
                    label={"SC No."}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {sharedData.scNo ?? "-"}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.scSimpleMedia)}
                          >
                            {!sharedData.scSimpleMedia ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadBlueSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    sharedData.scSimpleMedia?.mediaUrl ?? "",
                                    sharedData.scSimpleMedia
                                      ?.originalFileName ?? ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(sharedData.scSimpleMedia)}
                          >
                            {!sharedData.scSimpleMedia ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={sharedData.scSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewBlueSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />

                  {sharedData.paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                    <SectionCardRow
                      label={"L/C No."}
                      value={
                        <FileInfoContainer>
                          <BreakWordTypo typoType="b7r" color="gray5">
                            {sharedData.lcNo ?? "-"}
                          </BreakWordTypo>
                          <div>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                sharedData.paymentTermsSimpleMedia
                              )}
                            >
                              {sharedData.paymentTermsSimpleMedia === null ? (
                                <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                              ) : (
                                <Icon
                                  iconSrc={DownloadBlueSvg}
                                  iconSize={16}
                                  onClick={() =>
                                    downloadFile(
                                      sharedData.paymentTermsSimpleMedia
                                        ?.mediaUrl ?? "",
                                      sharedData.paymentTermsSimpleMedia
                                        ?.originalFileName ?? ""
                                    )
                                  }
                                />
                              )}
                            </IconButton>
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={isNull(
                                sharedData.paymentTermsSimpleMedia
                              )}
                            >
                              {sharedData.paymentTermsSimpleMedia === null ? (
                                <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                              ) : (
                                <a
                                  href={
                                    sharedData.paymentTermsSimpleMedia?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon
                                    iconSrc={PreviewBlueSvg}
                                    iconSize={16}
                                  />
                                </a>
                              )}
                            </IconButton>
                          </div>
                        </FileInfoContainer>
                      }
                    />
                  )}
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>

            <GrayLine />

            <ContractInformationGridItem>
              {/* ETC */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  ETC
                </Typo>
                <section>
                  <SectionCardRow
                    label={"HS Code"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.importerHsCode ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                  <SectionCardRow
                    label={"Freetime"}
                    value={
                      <BreakWordTypo typoType="b7r" color="gray5" as="p">
                        {sharedData.freetime ?? "-"}
                      </BreakWordTypo>
                    }
                  />
                </section>
              </ContractInformationRow>

              {/* Optional */}
              <ContractInformationRow>
                <Typo as="p" typoType="b9m" color="gray7">
                  Optional
                </Typo>
                <section>
                  <SectionCardRow
                    label={"Contract Term"}
                    value={
                      sharedData.isAddContractTerms
                        ? "Included"
                        : "Not Included"
                    }
                  />
                  <SectionCardRow
                    label={"Bank Detail"}
                    value={
                      sharedData.isAddBankDetail ? "Included" : "Not Included"
                    }
                  />
                </section>
              </ContractInformationRow>
            </ContractInformationGridItem>
          </ContractInformationGridContainer>
        </ContractInformationFlexColumn>
      </SectionCard>
    );
  };

  const renderRemarkCard = () => {
    return (
      <SectionCard cardTitle="Remark">
        <RemarkContainer>
          <SectionCardRow
            label={
              <Typo color="gray7" typoType="b9m">
                Contract Remark
              </Typo>
            }
            direction="vertical"
            value={
              <ReadOnlyText>
                {sharedData.importerContractRemark ?? "-"}
              </ReadOnlyText>
            }
          />
        </RemarkContainer>
      </SectionCard>
    );
  };

  const renderContractContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          Purchase Order No. {sharedData.poNo ?? "-"}
        </PageTitle>

        <FlexColumn>
          {/* header */}
          {renderBadgeContainer()}
          {/* ContractNoneTitleCard */}
          {renderContractNoneTitleCard()}
          {/* Contract Information */}
          {renderContractInformationCard()}
          {/* Remark */}
          {renderRemarkCard()}
        </FlexColumn>
      </>
    );
  };

  return renderContractContent();
}

export default ImportContractItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PdfButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSectionCardColumnSix = styled(SectionCardRow)`
  grid-column: 6 span;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTermsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ContractDateCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContractDateContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const ContractInformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const ContractInformationGridContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const ContractInformationGridItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 182px;
  ${StyledScroll}
`;

const HorizenGrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
