const colorSet = {
  indigo: "#1C3D61",
  // blue
  blue1: "#0B3E91",
  blue2: "#1150AA",
  blue3: "#1761C2",
  blue4: "#166DD7",
  blue5: "#1479EB",
  blue6: "#61A3EF",
  blue7: "#ADCCF3",
  blue8: "#C9DEF7",
  blue9: "#D7E7F9",
  blue10: "#F0F8FF",

  // gray
  gray1: "#000000",
  gray2: "#222729",
  gray3: "#353C3F",
  gray4: "#4A5256",
  gray5: "#5E696E",
  gray6: "#6E777C",
  gray7: "#AEB7BC",
  gray8: "#CFD6D9",
  gray9: "#E7EDF0",
  gray10: "#F1F4F6",
  gray11: "#F7F9FA",
  gray12: "#FFFFFF",

  // red
  red1: "#BF4040",
  red2: "#D64C4C",
  red3: "#E68686",
  red4: "#EEA3A3",
  red5: "#F2B2B2",
  red6: "#F5C0C0",
  red7: "#FAD8D8",
  red8: "#FFEFEF",

  // yellow
  yellow1: "#E1AE00",
  yellow2: "#FFCC00",
  yellow3: "#FFD633",
  yellow4: "#FFE066",
  yellow5: "#FFF0B3",
  yellow6: "#FFF7D9",

  // green
  green1: "#0A9A32",
  green2: "#15BD66",
  green3: "#35DB80",
  green4: "#64E8A4",
  green5: "#93EDBF",
  green6: "#EBFAEF",

  // systemBlue1
  systemBlue1: "#005CE1",
  systemBlue2: "#007AFF",
  systemBlue3: "#3395FF",
  systemBlue4: "#66AFFF",
  systemBlue5: "#B3D7FF",
  systemBlue6: "#E5F2FF",

  systemRed2: "#D64C4C",

  systemPink1: "#c2255c",
  systemPink2: "#e64980",
  systemPink3: "#f783ac",
  systemPink4: "#fcc2d7",
  systemPink5: "#ffdeeb",
  systemPink6: "#fff0f6",

  systemLime1: "#5c940d",
  systemLime2: "#82c91e",
  systemLime3: "#c0eb75",
  systemLime4: "#d8f5a2",
  systemLime5: "#e9fac8",
  systemLime6: "#f4fce3",

  systemGrape1: "#862e9c",
  systemGrape2: "#be4bdb",
  systemGrape3: "#da77f2",
  systemGrape4: "#eebefa",
  systemGrape5: "#f3d9fa",
  systemGrape6: "#f8f0fc",

  systemOrange1: "#e8590c",
  systemOrange2: "#fd7e14",
  systemOrange3: "#ffa94d",
  systemOrange4: "#ffd8a8",
  systemOrange5: "#ffe8cc",
  systemOrange6: "#fff4e6",

  white: "#FFFFFF",

  // gradient
  gradient: `linear-gradient(89.55deg, #1866C9 -5.11%, #0C3F92 119.02%);`,

  chartTeal1: "#3C9196",
  chartTeal2: "#4D9FA4",
  chartTeal3: "#5EADB2",
  chartTeal4: "#6FBBC0",
  chartTeal5: "#7FC9CE",
  chartTeal6: "#96CFD7",
  chartTeal7: "#83BFCA",
  chartTeal8: "#71AFBE",
  chartTeal9: "#5E9FB2",
  chartTeal10: "#4C90A6",
  chartTeal11: "#417B93",
  chartTeal12: "#4C89A6",
  chartTeal13: "#4D91B0",
  chartTeal14: "#549BBC",
  chartTeal15: "#54A1C4",
  chartTeal16: "#54A8CD",
  chartTeal17: "#56ADD4",
  chartTeal18: "#54B5DF",
  chartTeal19: "#6CBBE1",
  chartTeal20: "#86C4E3",
  chartTeal21: "#9BCBE6",
  chartTeal22: "#AED2E8",
  chartTeal23: "#C0DAE9",
  chartTeal24: "#CCE1EF",
  chartTeal25: "#D8E7F0",
  chartEtc: "#E9EDF0",

  chartBlue1: "#103A6D",
  chartBlue2: "#135099",
  chartBlue3: "#1A63BC",
  chartBlue4: "#0F77CD",
  chartBlue5: "#249ADD",
  chartBlue6: "#3CAFD1",
  chartBlue7: "#6CD1C5",
  chartBlue8: "#ABE4B8",
  chartBlue9: "#E6F2B4",
  chartBlue10: "#FFFDBD",

  chartMint1: "#1D3C60",
  chartMint2: "#105074",
  chartMint3: "#0E6E96",
  chartMint4: "#0D8899",
  chartMint5: "#22A5AD",
  chartMint6: "#77F5A9",
  chartMint7: "#31D3AD",
  chartMint8: "#2CE8AF",
  chartMint9: "#77F5A9",
  chartMint10: "#AFFFCF",

  chartAqua1: "#61B3B6",
  chartAqua2: "#6CC6C9",
  chartAqua3: "#84D4D6",
  chartAqua4: "#9BE3E3",
  chartAqua5: "#AAEBEC",

  chartViolet1: "#827BBD",
  chartViolet2: "#988CD6",
  chartViolet3: "#AA9EDE",
  chartViolet4: "#C2B6EF",
  chartViolet5: "#D4CAF6",

  chartBlueViolet1: "#72BFF1",
  chartBlueViolet2: "#65B2F1",
  chartBlueViolet3: "#63A4EE",
  chartBlueViolet4: "#6098DE",
  chartBlueViolet5: "#5E8DCD",
  chartBlueViolet6: "#5A82BE",
  chartBlueViolet7: "#5677AD",
  chartBlueViolet8: "#71AFBE",
  chartBlueViolet9: "#5E9FB2",
  chartBlueViolet10: "#4C90A6",
  chartBlueViolet11: "#52638E",
  chartBlueViolet12: "#5E6F9C",
  chartBlueViolet13: "#6A7AAA",
  chartBlueViolet14: "#7686B8",
  chartBlueViolet15: "#8292C7",
  chartBlueViolet16: "#8E9FD5",
  chartBlueViolet17: "#9BACE5",
  chartBlueViolet18: "#B2B0DA",
  chartBlueViolet19: "#A6A4D2",
  chartBlueViolet20: "#9A98C9",
  chartBlueViolet21: "#8D8CBF",
  chartBlueViolet22: "#8081B7",
  chartBlueViolet23: "#7475AE",
  chartBlueViolet24: "#676BA5",
  chartBlueViolet25: "#59609C",

  // alert
  alertError: "#FF6168",
  alertSuccess: "#55A2EA",
} as const;

export default colorSet;

type ColorType = keyof typeof colorSet;
export { type ColorType };
