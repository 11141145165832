import mapScopeCity from "../../../../assets/images/image-map-scope-city.png";
import mapScopeCountry from "../../../../assets/images/image-map-scope-country.png";
import mapScopeAll from "../../../../assets/images/image-map-scope-all.png";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";

export const getMaxContainerInfoViaLang = (
  lang: "en" | "ko",
  maxContainer: number
) => {
  return lang === "en"
    ? `You can select no more than ${maxContainer} containers`
    : `최대 ${maxContainer}대까지 작업 요청 가능합니다.
  `;
};

export const MEDIA_COORDINATE_RANGE_OPTION_LIST: {
  label: string;
  value: MediaCoordinateRangeType;
  mapSrc: string;
}[] = [
  {
    label: "label.mediaCoordinateRangeCity",
    value: "CITY",
    mapSrc: mapScopeCity,
  },
  {
    label: "label.mediaCoordinateRangeCountry",
    value: "COUNTRY",
    mapSrc: mapScopeCountry,
  },
  {
    label: "label.mediaCoordinateRangeAll",
    value: "ALL",
    mapSrc: mapScopeAll,
  },
];
