import { useCallback, useEffect, useState } from "react";
import {
  AlertQuantumPortal,
  AlertQuantumList,
  AlertQuantumItem,
  AlertListItem,
  AlertPositionType,
  AlertType,
  AlertQuantumClose,
} from "../../quantum/AlertQuantum";
import { css, keyframes, styled } from "styled-components";
import colorSet from "../../../styles/color";
import zIndex from "../../../styles/zIndex";
import AlertPrefixRedSvg from "../../../assets/icons/icon-alert-prefix-red.svg";
import AlertPrefixBlueSvg from "../../../assets/icons/icon-alert-prefix-blue.svg";
import AlertCloseRedSvg from "../../../assets/icons/icon-alert-close-red.svg";
import AlertCloseBlueSvg from "../../../assets/icons/icon-alert-close-blue.svg";
import typo from "../../../styles/typography";

interface AlertProps {
  list: AlertListItem[];
}

function Alert({ list }: AlertProps) {
  const [topLeftList, setTopLeftList] = useState<AlertListItem[]>([]);
  const [topRightList, setTopRightList] = useState<AlertListItem[]>([]);
  const [topCenterList, setTopCenterList] = useState<AlertListItem[]>([]);
  const [bottomLeftList, setBottomLeftList] = useState<AlertListItem[]>([]);
  const [bottomRightList, setBottomRightList] = useState<AlertListItem[]>([]);
  const [bottomCenterList, setBottomCenterList] = useState<AlertListItem[]>([]);

  const positionListMaping = useCallback(() => {
    const topLeft = list.filter((item) => item.position === "topLeft");
    const topRight = list.filter((item) => item.position === "topRight");
    const topCenter = list.filter((item) => item.position === "topCenter");
    const bottomLeft = list.filter((item) => item.position === "bottomLeft");
    const bottomRight = list.filter((item) => item.position === "bottomRight");
    const bottomCenter = list.filter(
      (item) => item.position === "bottomCenter"
    );

    setTopLeftList(topLeft);
    setTopRightList(topRight);
    setTopCenterList(topCenter);
    setBottomLeftList(bottomLeft);
    setBottomRightList(bottomRight);
    setBottomCenterList(bottomCenter);
  }, [list]);

  useEffect(() => {
    positionListMaping();
  }, [positionListMaping]);

  const alertTypePrefixSvg = (type: AlertType) => {
    if (type === "success") {
      return AlertPrefixBlueSvg;
    }
    if (type === "error") {
      return AlertPrefixRedSvg;
    }

    return "";
  };

  const alertTypeCloseSvg = (type: AlertType) => {
    if (type === "success") {
      return AlertCloseBlueSvg;
    }
    if (type === "error") {
      return AlertCloseRedSvg;
    }

    return "";
  };

  const renderAlert = (item: AlertListItem) => {
    return (
      <AlertContainer
        type={item.type ?? "success"}
        position={item.position ?? "topCenter"}
        isHide={item.isHide ?? false}
      >
        <AlertTextContainer>
          <AlertPrefixIcon url={alertTypePrefixSvg(item.type ?? "success")} />
          <AlertContent>
            <AlertBody>{item.message}</AlertBody>
            {/* X 아이콘으로 변경 */}
            <AlertQuantumClose>
              <AlertCloseIcon url={alertTypeCloseSvg(item.type ?? "success")} />
            </AlertQuantumClose>
          </AlertContent>
        </AlertTextContainer>
      </AlertContainer>
    );
  };

  return (
    <AlertQuantumPortal>
      <AlertQuantumList>
        <AlertListContainer>
          {topLeftList.length >= 1 && (
            <AlertContainerTopLeft>
              {topLeftList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerTopLeft>
          )}

          {topRightList.length >= 1 && (
            <AlertContainerTopRight>
              {topRightList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerTopRight>
          )}

          {topCenterList.length >= 1 && (
            <AlertContainerTopCenter>
              {topCenterList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerTopCenter>
          )}

          {bottomLeftList.length >= 1 && (
            <AlertContainerBottomLeft>
              {bottomLeftList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerBottomLeft>
          )}

          {bottomRightList.length >= 1 && (
            <AlertContainerBottomRight>
              {bottomRightList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerBottomRight>
          )}

          {bottomCenterList.length >= 1 && (
            <AlertContainerBottomCenter>
              {bottomCenterList.map((item, idx) => {
                return (
                  <AlertQuantumItem
                    key={idx.toString()}
                    alertId={item.id as number}
                  >
                    {renderAlert(item)}
                  </AlertQuantumItem>
                );
              })}
            </AlertContainerBottomCenter>
          )}
        </AlertListContainer>
      </AlertQuantumList>
    </AlertQuantumPortal>
  );
}

export default Alert;

const AlertContainerBasic = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: ${zIndex.alert};
  padding: 0;
  margin: 0;
`;

const AlertContainerTopLeft = styled(AlertContainerBasic)`
  top: 20px;
  left: 20px;
`;
const AlertContainerTopRight = styled(AlertContainerBasic)`
  top: 20px;
  right: 20px;
  align-items: flex-end;
`;
const AlertContainerTopCenter = styled(AlertContainerBasic)`
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
`;
const AlertContainerBottomLeft = styled(AlertContainerBasic)`
  bottom: 20px;
  left: 20px;
`;
const AlertContainerBottomRight = styled(AlertContainerBasic)`
  bottom: 20px;
  right: 20px;
  align-items: flex-end;
`;
const AlertContainerBottomCenter = styled(AlertContainerBasic)`
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
`;

const getAlertContainerType = (type: AlertType) => {
  switch (type) {
    case "error":
      return css`
        color: ${colorSet.red2};
        background: ${colorSet.red8};
      `;

    case "success":
      return css`
        color: ${colorSet.systemBlue2};
        background: ${colorSet.systemBlue6};
      `;
  }
};

const rightKeyFrame = keyframes`
  from{
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
`;

const leftKeyFrame = keyframes`
  from{
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`;

const bottomCenterKeyFrame = keyframes`
  from{
    transform: translateY(100%);
  }
  to{
    transform: translateY(0);
  }
`;

const topCenterKeyFrame = keyframes`
  from{
    transform: translateY(-100%);
  }
  to{
    transform: translateY(0);
  }
`;

const getAlertContainerPosition = (position: AlertPositionType) => {
  switch (position) {
    case "bottomCenter":
      return css`
        animation: ${bottomCenterKeyFrame} 0.7s;
      `;

    case "bottomLeft":
      return css`
        animation: ${leftKeyFrame} 0.7s;
      `;

    case "bottomRight":
      return css`
        animation: ${rightKeyFrame} 0.7s;
      `;

    case "topLeft":
      return css`
        animation: ${leftKeyFrame} 0.7s;
      `;

    case "topRight":
      return css`
        animation: ${rightKeyFrame} 0.7s;
      `;

    case "topCenter":
      return css`
        animation: ${topCenterKeyFrame} 0.7s;
      `;
  }
};

const AlertListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AlertContainer = styled.div<{
  type: AlertType;
  position: AlertPositionType;
  isHide: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 264px;
  width: auto;
  height: 100%;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  transition: none;
  ${({ type }) => getAlertContainerType(type)};
  ${({ position }) => getAlertContainerPosition(position)};
  animation-fill-mode: none;

  ${({ isHide }) =>
    isHide &&
    css`
      opacity: 0;
      transform: translateY(-10px);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    `};
`;

const AlertTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const AlertBody = styled.div`
  ${typo.b7m};
  word-break: break-all;
`;

const AlertPrefixIcon = styled.div<{ url: string }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: ${({ url }) => url && `url(${url}) no-repeat`};
`;

const AlertContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const AlertCloseIcon = styled(AlertPrefixIcon)``;
