import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import { GeneratePlDto, PlInfoDto } from "./interface";
// import { CiInfoDto, GenerateCiDto } from "./interface";

const plApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 선적의 P/L 정보를 조회하는 API 입니다. P/L을 등록하지 않은 경우에도 해당 API를 호출하여 생성 화면으로 진입합니다.
    getPlInformation: build.query<PlInfoDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/pl-info`,
      }),
      transformResponse: (res: Row<PlInfoDto>) => res.row,
    }),

    // 선적의 초기 P/L 정보를 조회하는 API 입니다.
    getInitialPlInformation: build.query<PlInfoDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/initial-pl-info`,
      }),
      transformResponse: (res: Row<PlInfoDto>) => res.row,
    }),

    // Temporary 상태의 P/L을 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다. 또한, shipmentStatus가 COMPLETED인 경우 해당 요청은 불가합니다.
    createTemporaryPl: build.mutation<
      PlInfoDto,
      GeneratePlDto & { id: number }
    >({
      query: ({ id, ...restBody }) => ({
        url: `/exporters/this/shipments/${id}/temporary-pl-info-creation`,
        method: "POST",
        body: restBody,
      }),
    }),

    // Final 상태의 P/L을 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다. 또한, shipmentStatus가 COMPLETED인 경우 해당 요청은 불가합니다.
    createFinalPl: build.mutation<PlInfoDto, GeneratePlDto & { id: number }>({
      query: ({ id, ...restBody }) => ({
        url: `/exporters/this/shipments/${id}/final-pl-info-creation`,
        method: "POST",
        body: restBody,
      }),
    }),
  }),
});

export const {
  useGetPlInformationQuery,
  useLazyGetPlInformationQuery,
  useLazyGetInitialPlInformationQuery,

  useCreateTemporaryPlMutation,
  useCreateFinalPlMutation,
} = plApi;

export { plApi };
