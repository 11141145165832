export const DASHBOARD_DATE_STRINGS = {
  sunday: { en: "SUN", ko: "일" },
  monday: { en: "MON", ko: "월" },
  tuesday: { en: "TUE", ko: "화" },
  wednesday: { en: "WED", ko: "수" },
  thursday: { en: "THU", ko: "목" },
  friday: { en: "FRI", ko: "금" },
  saturday: { en: "SAT", ko: "토" },
};

export const RANK = {
  1: { en: "1st", ko: "1위" },
  2: { en: "2nd", ko: "2위" },
  3: { en: "3rd", ko: "3위" },
  4: { en: "4th", ko: "4위" },
  5: { en: "5th", ko: "5위" },
};
