import colorSet from "@/src/styles/color";
import { PropsWithChildren } from "react";
import styled from "styled-components";
import ExportLogo from "@/src/assets/logos/logo-export.svg";
import Icon from "../../atom/Icon";
import GlobalSvg from "@/src/assets/icons/icon-global-language.svg";
import Typo from "../../atom/Typo";
import typo from "@/src/styles/typography";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { changeLanguage } from "@/src/store/slice/lang";
import { useNavigate } from "react-router-dom";
import PUBLIC_PATH from "@/src/routes/public/path";
import EmailFooter from "@/src/pages/private/SharedEmail/components/EmailFooter";
import i18n from "@/src/i18n";

interface SharedEmailLayoutProps {
  logoType: "seller" | "import";
}

function SharedEmailLayout({
  children,
  logoType,
}: PropsWithChildren<SharedEmailLayoutProps>) {
  const lang = useAppSelector((state) => state.lang.value);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLanguageChangeClick = (select: "en" | "ko") => {
    i18n.changeLanguage(select);
    dispatch(changeLanguage(select));
  };

  return (
    <SharedEmailLayoutContainer>
      {/* Language */}
      <LanguageContainer>
        <Icon iconSrc={GlobalSvg} iconSize={18} />
        <LanguageTypo
          isSelect={lang === "en"}
          onClick={() => handleLanguageChangeClick("en")}
        >
          EN
        </LanguageTypo>
        <LanguageIsolation>|</LanguageIsolation>
        <LanguageTypo
          isSelect={lang === "ko"}
          onClick={() => handleLanguageChangeClick("ko")}
        >
          KOR
        </LanguageTypo>
      </LanguageContainer>

      <Main>
        <EcoyaLogo
          src={ExportLogo}
          alt="logo"
          onClick={() => navigate(PUBLIC_PATH.LOGIN)}
        />
        {children}
        <EmailFooter />
      </Main>
    </SharedEmailLayoutContainer>
  );
}

export default SharedEmailLayout;

const SharedEmailLayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const LanguageContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  padding: 10px 40px;
  background: ${colorSet.white};
  border-bottom: 1px solid ${colorSet.gray10};
`;

const LanguageTypo = styled(Typo)<{ isSelect: boolean }>`
  cursor: pointer;
  color: ${({ isSelect }) => (isSelect ? colorSet.gray2 : colorSet.gray7)};
  ${typo.b9m}
`;

const LanguageIsolation = styled(Typo)`
  user-select: none;
  color: ${colorSet.gray7};
  ${typo.b9r}
`;

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${colorSet.gray9};
  padding: 40px;
  width: 1250px;
  min-height: 1000px;
  margin: 80px auto 40px;
`;

const EcoyaLogo = styled.img`
  width: 208px;
  height: 32px;
  cursor: pointer;
`;
