import Badge from "@/src/components/atom/Badge";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import {
  CELL_TYPE_DELETE_ACTION,
  CELL_TYPE_EDIT_ACTION,
  CELL_TYPE_EDIT_DELETE_ACTION,
} from "@/src/utils/row-data-util";
import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";
import {
  BuyerEmployeeDto,
  BuyerListViewDto,
} from "@/src/store/apis/client/buyer/interface";
import dayjs from "dayjs";
import LinkStatusBadge from "../components/LinkStatusBadge";
import { formatCategoryName } from "@/src/utils/format";

export const clientListColumn = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "createdAt",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<BuyerListViewDto>) => {
      return (
        <>{`${dayjs(data?.buyerListQueryResultDto.createdAt).format(
          "YYYY-MM-DD"
        )}`}</>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerName: "코드 발송",
    field: "codeSend",
    flex: 1,
    minWidth: 150,
    cellRenderer: "SEND_CODE",
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    field: "linkedStatus",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({
      data,
    }: ValueGetterParams<BuyerListViewDto & { lang: "en" | "ko" }>) => {
      return (
        <LinkStatusBadge
          status={data?.buyerListQueryResultDto.linkedStatus || "PENDING"}
          lang={data?.lang || "en"}
        />
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerName",
    flex: 1,
    minWidth: 150,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 150,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    headerName: "Client Type",
    cellStyle: { textAlign: "center" },
    field: "clientType",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<BuyerListViewDto>) => {
      const isBoth = data?.companyType === "BOTH";
      const isExport = data?.companyType === "SELLER";
      return (
        <>{`${isBoth ? "Export & Import" : isExport ? "Export" : "Import"}`}</>
      );
    },
    sortable: false,
  },
  {
    field: "countryCodeItemName",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<BuyerListViewDto>) => {
      return <>{`${data?.country}`}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    field: "mainCategoryCodeItemNames",
    headerName: "Category in Charge",
    cellStyle: { textAlign: "center" },
    flex: 1,
    minWidth: 150,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerListViewDto>) => {
      const mainCategoryCodeItemNames = formatCategoryName(
        data?.mainCategoryCodeItemNames as string[]
      );

      return (
        <>{data?.mainCategoryCodeItemNames ? mainCategoryCodeItemNames : "-"}</>
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "linkedAt",
    headerName: "연동 완료일",
    flex: 1,
    minWidth: 150,
    cellRenderer: ({ data }: ValueGetterParams<BuyerListViewDto>) => {
      return (
        <>{`${
          data?.buyerListQueryResultDto.linkedAt
            ? dayjs(data?.buyerListQueryResultDto.linkedAt).format("YYYY-MM-DD")
            : "-"
        }`}</>
      );
    },
    sortable: false,
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    lockPosition: "right",
    field: "isActivated",
    width: 130,
    resizable: false,

    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
] as ColDef[];

export const buyerContactPersonListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "name",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "email",
    flex: 1,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: { textAlign: "center" },
    field: "personalContact",
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerEmployeeDto>) => {
      const isFullContact =
        data?.personalContactPrefix && data?.personalContact;
      if (!isFullContact) return "-";
      return <>{`${data.personalContactPrefix} ${data.personalContact}`}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "officeContact",
    cellStyle: { textAlign: "center" },
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerEmployeeDto>) => {
      const isFullContact = data?.officeContactPrefix && data?.officeContact;
      if (!isFullContact) return "-";
      return <>{`${data.officeContactPrefix} ${data.officeContact}`}</>;
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "buyerEmployeeRemark",
    cellStyle: { textAlign: "center" },
    minWidth: 253,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerEmployeeRemark || "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    field: "isActivated",
    width: 130,
    resizable: false,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerEmployeeDto>) => {
      if (data?.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
];

export const editBuyerContactPersonListColumn: ColDef<BuyerEmployeeDto>[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "name",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "email",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "personalContact",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerEmployeeDto>) => {
      const isFullContact =
        data?.personalContactPrefix && data?.personalContact;
      if (!isFullContact) return "-";
      return <>{`${data.personalContactPrefix} ${data.personalContact}`}</>;
    },
  },
  {
    field: "officeContact",
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    minWidth: 200,
    flex: 1,
    sortable: false,
    cellRenderer: ({ data }: ValueGetterParams<BuyerEmployeeDto>) => {
      const isFullContact = data?.officeContactPrefix && data?.officeContact;
      if (!isFullContact) return "-";
      return <>{`${data.officeContactPrefix} ${data.officeContact}`}</>;
    },
  },
  {
    field: "buyerEmployeeRemark",
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    minWidth: 253,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerEmployeeRemark || "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    width: 130,
    field: "isActivated",
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "edit",
    width: 130,
    pinned: "right",
    resizable: false,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_DELETE_ACTION,
  },
];

export const addBuyerContactPersonListColumn: ColDef[] = [
  {
    headerName: "No.",
    field: "no",
    lockPosition: "left",
    width: 100,
    resizable: false,
    cellRenderer: function (params: ValueGetterParams) {
      return (
        <Typo typoType="b9r">
          {params.node?.rowIndex
            ? getTableIndex(
                params.node?.rowIndex + 1,
                params.data.page,
                params.data.pageSize
              )
            : getTableIndex(1, params.data.page, params.data.pageSize)}
        </Typo>
      );
    },
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "name",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "email",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "personalContact",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: "officeContact",
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: "buyerEmployeeRemark",
    cellStyle: { textAlign: "center" },
    headerClass: "ag-right-aligned-cell ag-header-hide",
    minWidth: 253,
    flex: 1,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyerEmployeeRemark || "-";
    },
  },
  {
    headerClass: "ag-right-aligned-cell ag-header-hide",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    width: 130,
    field: "isActivated",
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.isActivated) {
        return (
          <Badge
            text="YES"
            badgeSize="S"
            badgeColor="systemLime5"
            color="systemLime1"
          />
        );
      }
      return (
        <Badge text="No" badgeSize="S" badgeColor="gray10" color="gray7" />
      );
    },
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "edit",
    width: 130,
    pinned: "right",
    resizable: false,
    sortable: false,
    cellRenderer: CELL_TYPE_EDIT_ACTION,
  },
  {
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    lockPosition: "right",
    headerClass: "ag-right-aligned-cell ag-header-hide",
    field: "delete",
    width: 130,
    pinned: "right",
    resizable: false,
    sortable: false,
    cellRenderer: CELL_TYPE_DELETE_ACTION,
  },
];
