import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import { ModifyTaskDto } from "./interface";

export const taskEditApi = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      editTask: build.mutation<
        Row<{ id: number }>,
        ModifyTaskDto & { id: number }
      >({
        query: ({ id, ...restBody }) => ({
          url: `/exporters/this/tasks/${id}`,
          method: "PUT",
          body: restBody,
        }),
      }),
    };
  },
});

export const { useEditTaskMutation } = taskEditApi;
