import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@/src/components/atom/Button";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import useAlert from "@/src/hooks/useAlert";
import colorSet from "@/src/styles/color";
import { columnContainerTab } from "../columnContainerTab";
import { createViewAction } from "@/src/utils/row-data-util";
import SelectContainerDialog from "../dialog/SelectContainerDialog";
import {
  useGetShipmentDetailContainersIntegrationQuery,
  useLazyGetShipmentDetailContainersQuery,
} from "@/src/store/apis/shipments/shipmentDetail";
import ImageMultipleSvg from "@/src/assets/icons/icon-image-multiple.svg";
import Icon from "@/src/components/atom/Icon";
import SingleContainerPhotoDialog from "../../dialog/SingleContainerPhotoDialog";
import ContainerPhotosDialog from "../../dialog/ContainerPhotosDialog";
import { useContainerIdToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { ShipmentDetailContainerListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import { isUndefined } from "@/src/utils/is";
import Loader from "@/src/components/atom/Loader";
import { useTranslation } from "react-i18next";

enum DialogState {
  NULL,
  CONTAINER_PHOTO_DIALOG,
  ALL_CONTAINER_PHOTO_DIALOG,
  EDIT_CONTAINER_DIALOG,
}

interface ContainerCardProps {
  isEditAuth: boolean;
  isShipmentCompleted: boolean;
}

const containerEmptyArray: ShipmentDetailContainerListViewDto[] = [];

function ContainerCard({
  isEditAuth,
  isShipmentCompleted,
}: ContainerCardProps) {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const alert = useAlert();
  const params = useParams();
  const [columnDefs] = useState<ColDef[]>(columnContainerTab);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [containerIdForDialog, setContainerIdFofDialog] = useState<number>();

  // API
  const [containerIdToSeaVantageUrl] = useContainerIdToSeaVantageUrlMutation();
  const [
    getShipmentDetailContainers,
    { isFetching, shipmentWithContainerList, count },
  ] = useLazyGetShipmentDetailContainersQuery({
    selectFromResult: ({ isError, isFetching, currentData }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);

      return {
        isFetching,
        count: !isUnstable ? currentData.count : 0,
        shipmentWithContainerList: !isUnstable
          ? currentData.rows ?? containerEmptyArray
          : containerEmptyArray,
      };
    },
  });
  const {
    numberOfContainerTypeB,
    totalGrossWeight,
    totalNetWeight,
    totalQuantity,
    isContainersIntegrationFetching,
  } = useGetShipmentDetailContainersIntegrationQuery(
    {
      id: Number(params.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          isContainersIntegrationFetching: isFetching,
          numberOfContainerTypeB: !isUnstable
            ? currentData.numberOfContainerTypeB ?? "-"
            : "-",
          totalGrossWeight: !isUnstable
            ? currentData.totalGrossWeight ?? "-"
            : "-",
          totalNetWeight: !isUnstable ? currentData.totalNetWeight ?? "-" : "-",
          totalQuantity: !isUnstable ? currentData.totalQuantity ?? "-" : "-",
        };
      },
    }
  );

  // Submit
  const handleSubmit = useCallback(
    async (param?: { page?: number; pageSize?: number }) => {
      const page = param?.page || pagination.page;
      const pageSize = param?.pageSize || pagination.pageSize;
      const containerParams = { page, pageSize, id: Number(params.id) };

      try {
        await getShipmentDetailContainers(containerParams).unwrap();

        setPagination({
          page,
          pageSize,
        });
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    },
    [
      alert,
      getShipmentDetailContainers,
      pagination.page,
      pagination.pageSize,
      params.id,
    ]
  );

  const handleViewButtonClick = (data: any) => {
    setContainerIdFofDialog(data.id);
    setDialogState(DialogState.CONTAINER_PHOTO_DIALOG);
  };

  const handleViewAllButtonClick = () => {
    setDialogState(DialogState.ALL_CONTAINER_PHOTO_DIALOG);
  };

  // Pagination;
  const handlePaginationClick = (page: number, pageSize: number) => {
    handleSubmit({
      page,
      pageSize,
    });
  };

  // Loading
  useEffect(() => {
    if (isFetching && gridRef.current) {
      gridRef?.current?.api?.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContainerNoClick = async (containerId: number) => {
    try {
      const { cargoTrackUrl } = await containerIdToSeaVantageUrl({
        containerId,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;

    if (
      dialogState === DialogState.CONTAINER_PHOTO_DIALOG &&
      containerIdForDialog
    ) {
      return (
        <SingleContainerPhotoDialog
          containerId={containerIdForDialog}
          onClose={() => {
            setContainerIdFofDialog(undefined);
            setDialogState(DialogState.NULL);
          }}
        />
      );
    }

    if (dialogState === DialogState.ALL_CONTAINER_PHOTO_DIALOG) {
      return (
        <ContainerPhotosDialog
          shipmentId={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.EDIT_CONTAINER_DIALOG) {
      return (
        <SelectContainerDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  const renderContainerTotalInfo = () => {
    if (isContainersIntegrationFetching) {
      return (
        <ContainerInfo>
          <Loader size={36} />
        </ContainerInfo>
      );
    } else {
      return (
        <ContainerInfo>
          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              Total Number of Container
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {numberOfContainerTypeB}
            </BreakWordTypo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              Total Gross Weight
            </Typo>
            <Typo color="blue2" typoType="h4">
              {totalGrossWeight}
            </Typo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              Total Net Weight
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {totalNetWeight}
            </BreakWordTypo>
          </InfoItem>

          <InfoItem>
            <Typo color="gray6" typoType="b9m">
              Total Unit Q’ty
            </Typo>
            <BreakWordTypo color="blue2" typoType="h4">
              {totalQuantity}
            </BreakWordTypo>
          </InfoItem>
        </ContainerInfo>
      );
    }
  };

  return (
    <SectionCard
      cardTitle={t("shipment:exporter.detail.label.containerList")}
      rightAccessory={
        <RightAccessoryContainer>
          <StyledButton
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={handleViewAllButtonClick}
          >
            <Icon iconSrc={ImageMultipleSvg} />
            <Typo typoType="btn3m">
              {t("shipment:exporter.detail.button.viewAllButton")}
            </Typo>
          </StyledButton>

          <Button
            buttonSize={32}
            buttonColor="black"
            buttonGrade="tertiary"
            onClick={() => setDialogState(DialogState.EDIT_CONTAINER_DIALOG)}
            disabled={!isEditAuth || isShipmentCompleted}
          >
            {t("shipment:exporter.detail.button.chooseAgainButton")}
          </Button>
        </RightAccessoryContainer>
      }
    >
      <TableContainer>
        {renderContainerTotalInfo()}

        <Table
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={shipmentWithContainerList}
          totalPage={count}
          onCellClicked={(e) => {
            const selectShipment = e.node.data;
            const containerNo = e.column.getColDef().field === "containerNo";

            if (containerNo && selectShipment.containerNo) {
              handleContainerNoClick(selectShipment.id);
            }
          }}
          isPaginationDataMaping
          handlePaginationClick={handlePaginationClick}
          pageSize={pagination.pageSize}
          page={pagination.page}
          rowClass={"ag-row-none-cursor"}
          components={{
            ...createViewAction(handleViewButtonClick),
          }}
        />
      </TableContainer>

      {/* Dialog */}
      {renderDialog()}
    </SectionCard>
  );
}

export default ContainerCard;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContainerInfo = styled.ul`
  display: flex;
  justify-content: center;
`;

const InfoItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  padding: 16px 8px;
  border-right: 1px solid ${colorSet.gray10};
  text-align: center;

  &:last-child {
    border-right: none;
  }
`;

const RightAccessoryContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
