import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LanguageSliceState {
  value: "en" | "ko";
}

const initialState: LanguageSliceState = {
  value: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<"en" | "ko">) => {
      state.value = action.payload;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
