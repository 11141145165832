import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const namespaces = [
  "addMemoDialog",
  "alert",
  "booking",
  "changePassword",
  "clientManagement",
  "common",
  "company",
  "companyManagement",
  "contract",
  "cancelAlertDialog",
  "dashboard",
  "emailDialog",
  "emailHistoryDialog",
  "enterpriseSignup",
  "error",
  "factoryManagement",
  "faq",
  "fileBox",
  "findPassword",
  "itemManagement",
  "linkToAccount",
  "login",
  "myPage",
  "normalPlanSignup",
  "notification",
  "registeredLinkToAccount",
  "sendLinkCodeDialog",
  "shareableContractDialog",
  "shareEmail",
  "shipment",
  "sideNav",
  "signup",
  "signupCorp",
  "signupStaff",
  "staffManagement",
  "subscriptionAnnuallyTermination",
  "subscriptionLanding",
  "subscriptionManagement",
  "success",
  "sales",
  "task",
  "temporarySignupCorp",
  "twoFactorAuthentication",
  "warehouseManagement",
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    debug: false,
    ns: namespaces,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    partialBundledLanguages: true,
    load: "languageOnly",
    react: {
      useSuspense: false, // Suspense 비활성화
    },
  });

export default i18n;
