import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";

export const editTaskPageDefaultValue = {
  bookingId: undefined,
  contractId: undefined,
  workingDayAt: "",
  workEstimatedTime: undefined,
  transportRemark: "",
  sampleBale: {
    count: 1,
    isUse: false,
  },
  empty: {
    count: 1,
    isUse: false,
  },
  quarterLoaded: {
    count: 1,
    isUse: false,
  },
  halfLoaded: {
    count: 1,
    isUse: false,
  },
  fullLoaded: {
    count: 1,
    isUse: false,
  },
  oneDoorClosed: {
    count: 1,
    isUse: false,
  },
  bothDoorsClosed: {
    count: 1,
    isUse: false,
  },
  closeUpSeal: {
    count: 1,
    isUse: true,
  },
  extra: {
    count: 1,
    isUse: false,
    remark: "",
  },
  taskCode: "",
  workplaceId: undefined,
  numberOfTaskContainer: 0,
  memo: "",
  taskWorkplaceEmployeeInfos: [
    {
      transmissionMethod: "ALL",
      workplaceEmployeeId: undefined,
    },
    {
      transmissionMethod: "ALL",
      workplaceEmployeeId: undefined,
    },
  ],
};

export const addTaskPageDefaultValue = {
  bookingId: undefined,
  contractId: undefined,
  workEstimatedTime: undefined,
  workingDayAt: "",
  transportRemark: "",
  simpleBale: {
    count: 1,
    isUse: true,
  },
  empty: {
    count: 1,
    isUse: true,
  },
  quarterLoaded: {
    count: 1,
    isUse: true,
  },
  halfLoaded: {
    count: 1,
    isUse: true,
  },
  fullLoaded: {
    count: 1,
    isUse: true,
  },
  oneDoorClosed: {
    count: 1,
    isUse: true,
  },
  bothDoorsClosed: {
    count: 1,
    isUse: true,
  },
  closeUpSeal: {
    count: 1,
    isUse: true,
  },
  extra: {
    count: 1,
    isUse: false,
    remark: "",
  },
  workplaceId: undefined,
  numberOfTaskContainer: 0,
  memo: "",
  taskWorkplaceEmployeeInfos: [
    {
      transmissionMethod: "ALL",
      workplaceEmployeeId: undefined,
    },
    {
      transmissionMethod: "ALL",
      workplaceEmployeeId: undefined,
    },
  ],
  mediaCoordinateRange: "CITY" as MediaCoordinateRangeType,
};
