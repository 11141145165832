import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { CiData } from "./types";
import colorSet from "@/src/styles/color"; // @ts-ignore
import RobotoBoldItalic from "../../../../assets/fonts/Roboto-BoldItalic.ttf";
import stringToPdfPriceText from "../utils/stringToPdfPrieceText";

interface CiContentProps {
  data: CiData;
}

const CiContent = ({ data }: CiContentProps) => {
  const isGrossWeightHidden = data.ciItems.some(
    ({ isGrossWeightHidden }) => isGrossWeightHidden === true
  );

  const isNetWeightHidden = data.ciItems.some(
    ({ isNetWeightHidden }) => isNetWeightHidden === true
  );

  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* Title */}
        <View style={styles.titleContainer}>
          <View style={styles.companyLogo}>
            {!!data.componyLogoUrl ? (
              <Image
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                src={{
                  uri: data.componyLogoUrl as string,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
            ) : (
              <View style={styles.companyLogoDefault} />
            )}
          </View>

          <Text style={styles.title}>COMMERCIAL INVOICE</Text>
          <Text style={styles.draft}>{data.shipmentDocumentType}</Text>
        </View>
        {/* Shipper ~ Remark */}
        <View
          style={{
            ...styles.flexRow,
            border: 1,
          }}
        >
          {/* Left */}
          <View style={{ ...styles.flexColumn, flex: 1 }}>
            {/* Shipper / Exporter*/}
            <View
              style={{
                ...styles.flexItemColumn,
                borderBottom: 1,
              }}
            >
              <Text style={styles.itemTitle}>{data.shipperTitle}</Text>
              <Text style={styles.itemText}>{data.shipper}</Text>
            </View>

            {/* Consignee / Importer */}
            <View style={styles.flexItemColumn}>
              <Text style={styles.itemTitle}>{data.consigneeTitle}</Text>
              <Text style={styles.itemText}>{data.consignee}</Text>
            </View>

            {/* Notify Party / Applicant */}
            {!data.isNotifyPartyHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderTop: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.notifyPartyTitle}</Text>
                <Text style={styles.itemText}>{data.notifyParty}</Text>
              </View>
            ) : null}
          </View>
          {/* Right  */}
          <View
            style={{
              ...styles.flexColumn,
              flex: 1,
              borderLeft: 1,
            }}
          >
            {/* Invoice No. and Date */}
            <View
              style={{
                ...styles.flexItemColumn,
                borderBottom:
                  data.isPoNoHidden &&
                  data.isScNoHidden &&
                  data.isLcInfoHidden &&
                  data.isRemarkHidden
                    ? 0
                    : 1,
                flex: data.isRemarkHidden ? 1 : undefined,
              }}
            >
              <Text style={styles.itemTitle}>{data.invoicoNoAndDateTitle}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.itemText}>{data.invoiceNo}</Text>
                <Text style={styles.itemText}>{data.invoiceDateAt}</Text>
              </View>
            </View>

            {/* PO No. SC No.*/}
            {data.isPoNoHidden && data.isScNoHidden ? null : (
              <View
                style={{
                  ...styles.flexRow,
                  borderBottom:
                    data.isLcInfoHidden && data.isRemarkHidden ? 0 : 1,
                  flex: data.isRemarkHidden ? 1 : undefined,
                }}
              >
                {!data.isPoNoHidden ? (
                  <View
                    style={{
                      ...styles.flexItemColumn,
                      borderRight: data.isScNoHidden ? 0 : 1,
                      flex: 1,
                    }}
                  >
                    <Text style={styles.itemTitle}>{data.poNoTitle}</Text>
                    {data.poNoList?.map((item, idx, origin) => {
                      const poNoText =
                        origin.length - 1 === idx ? item : `${item},`;

                      return (
                        <Text key={idx.toString()} style={styles.itemText}>
                          {poNoText}
                        </Text>
                      );
                    })}
                  </View>
                ) : null}

                {!data.isScNoHidden ? (
                  <View
                    style={{
                      ...styles.flexItemColumn,
                      flex: 1,
                    }}
                  >
                    <Text style={styles.itemTitle}>{data.scNoTitle}</Text>
                    {data.scNoList?.map((item, idx, origin) => {
                      const scNoText =
                        origin.length - 1 === idx ? item : `${item},`;

                      return (
                        <Text key={idx.toString()} style={styles.itemText}>
                          {scNoText}
                        </Text>
                      );
                    })}
                  </View>
                ) : null}
              </View>
            )}

            {/* L/C No. and Date*/}
            {!data.isLcInfoHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderBottom: data.isRemarkHidden ? 0 : 1,
                  flex: data.isRemarkHidden ? 1 : undefined,
                }}
              >
                <View>
                  <Text style={styles.itemTitle}>{data.lcNoAndDateTitle}</Text>

                  {data?.lcInfoList?.map((item, idx) => {
                    return (
                      <View
                        key={idx.toString()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text style={styles.itemText}>{item.lcNo}</Text>
                        <Text style={styles.itemText}>{item.expireDateAt}</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            ) : null}

            {/* Remark */}
            {!data.isRemarkHidden ? (
              <View style={styles.flexItemColumn}>
                <Text style={styles.itemTitle}>{data.remarkTitle}</Text>
                <Text style={styles.itemText}>{data.remark}</Text>
              </View>
            ) : null}
          </View>
        </View>

        {/* Port of Loading ~ Place of Delivery */}
        {data.isPortOfLoadingHidden &&
        data.isPortOfDischargeHidden &&
        data.isPlaceOfDeliveryHidden ? null : (
          <View style={{ ...styles.flexRow, border: 1, borderTop: 0 }}>
            {/* Port of Loading */}
            {!data.isPortOfLoadingHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isPortOfDischargeHidden && data.isPlaceOfDeliveryHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.portOfLoadingTitle}</Text>
                <Text style={styles.itemText}>{data.portOfLoading}</Text>
              </View>
            ) : null}

            {/* // Port of Discharge */}
            {!data.isPortOfDischargeHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  flex: 1,
                  borderRight: data.isPlaceOfDeliveryHidden ? 0 : 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.portOfDischargeTitle}
                </Text>
                <Text style={styles.itemText}>{data.portOfDischarge}</Text>
              </View>
            ) : null}

            {/* // Place of Delivery */}
            {!data.isPlaceOfDeliveryHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.placeOfDeliveryTitle}
                </Text>
                <Text style={styles.itemText}>{data.placeOfDelivery}</Text>
              </View>
            ) : null}
          </View>
        )}

        {/* Vessel ~ SHIPPING TERMS */}
        {data.isVesselAndVoyageNoHidden &&
        data.isEtdHidden &&
        data.isPaymentTermsHidden &&
        data.isShippingTermsHidden ? null : (
          <View style={{ ...styles.flexRow, border: 1, borderTop: 0 }}>
            {/* // Vessel and Voyage No. */}
            {!data.isVesselAndVoyageNoHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isShippingTermsHidden &&
                    data.isPaymentTermsHidden &&
                    data.isEtdHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>
                  {data.vesselAndVoyageNoTitle}
                </Text>
                <Text style={styles.itemText}>
                  {data.vessel} {data.voyageNo}
                </Text>
              </View>
            ) : null}

            {/* ETD */}
            {!data.isEtdHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight:
                    data.isShippingTermsHidden && data.isPaymentTermsHidden
                      ? 0
                      : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.etdTitle}</Text>
                <Text style={styles.itemText}>{data.etdAt}</Text>
              </View>
            ) : null}

            {/* Payment TERMS */}
            {!data.isPaymentTermsHidden ? (
              <View
                style={{
                  ...styles.flexItemColumn,
                  borderRight: data.isShippingTermsHidden ? 0 : 1,
                  flex: 1,
                }}
              >
                <Text style={styles.itemTitle}>{data.paymentTermsTitle}</Text>
                <Text style={styles.itemText}>{data.paymentTerms}</Text>
              </View>
            ) : null}

            {/* Shipping Terms */}
            {!data.isShippingTermsHidden ? (
              <View style={{ ...styles.flexItemColumn, flex: 1 }}>
                <Text style={styles.itemTitle}>{data.shippingTermsTitle}</Text>
                <Text style={styles.itemText}>{data.shippingTerms}</Text>
              </View>
            ) : null}
          </View>
        )}

        {/* Item Table Header */}
        <View
          style={{
            ...styles.flexRow,
            alignItems: "center",
            height: "32px",
            border: 1,
            borderTop: 0,
          }}
        >
          <Text
            style={{
              ...styles.itemTableTitle,
              height: "32px",
              borderRight: 1,
              paddingTop: "4px",
              width: "60px",
            }}
          >
            Quantity
          </Text>
          <Text
            style={{
              ...styles.itemTableTitle,
              flex: 1,
              borderRight: 1,
              height: "32px",
              paddingTop: "4px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            Description of Goods
          </Text>
          {!isNetWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "32px",
                borderRight: 1,
                width: "58px",
              }}
            >
              <Text style={styles.itemTableTitle}>Net Weight</Text>
              <Text style={styles.itemTableTitle}>
                ({data.commonWeightUnit})
              </Text>
            </View>
          )}
          {!isGrossWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRight: 1,
                height: "32px",
                width: "64px",
              }}
            >
              <Text style={styles.itemTableTitle}>Gross Weight</Text>
              <Text style={styles.itemTableTitle}>
                ({data.commonWeightUnit})
              </Text>
            </View>
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRight: 1,
              height: "32px",
              width: "50px",
            }}
          >
            <Text style={styles.itemTableTitle}>Unit</Text>
            <Text style={styles.itemTableTitle}>Price</Text>
          </View>
          <Text
            style={{
              ...styles.itemTableTitle,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
              paddingTop: "4px",
              width: "50px",
            }}
          >
            Amount
          </Text>
        </View>
        {/* itemList */}
        {data.ciItems.map((item, idx) => {
          return (
            <View
              key={idx.toString()}
              style={{
                ...styles.flexRow,
                alignItems: "flex-start",
                minHeight: "79px",
                border: 1,
                borderBottom: 1,
                borderTop: 1,
                marginTop: "-1px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  height: "100%",
                  minHeight: "79px",
                  width: "60px",
                  borderRight: 1,
                }}
              >
                <Text
                  style={{
                    ...styles.itemText,
                    flex: 1,
                    paddingTop: "4px",
                    textAlign: "center",
                  }}
                >
                  {stringToPdfPriceText({
                    value: item.quantity || "",
                  })}{" "}
                  {item.quantityUnit}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flex: 1,
                  borderRight: 1,
                  padding: "2px",
                  minHeight: "79px",
                }}
              >
                {!item.isItemHidden && (
                  <Text style={styles.itemText}>Item : {item.item}</Text>
                )}
                {!item.isHsCodeHidden && (
                  <Text style={{ ...styles.itemText, paddingBottom: "6px" }}>
                    HS Code : {item.hsCode}
                  </Text>
                )}
                {!item.isDescriptionOfGoodsHidden && (
                  <Text style={{ ...styles.itemText, paddingBottom: "6px" }}>
                    {item.descriptionOfGoods}
                  </Text>
                )}
              </View>

              {!isNetWeightHidden && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                    borderRight: 1,
                    minHeight: "79px",
                    width: "58px",
                  }}
                >
                  <Text
                    style={{
                      ...styles.itemText,
                      textAlign: "center",
                      paddingTop: "4px",
                    }}
                  >
                    {stringToPdfPriceText({
                      value: item.netWeight || "",
                    })}
                  </Text>
                </View>
              )}
              {!isGrossWeightHidden && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                    borderRight: 1,
                    minHeight: "79px",
                    width: "64px",
                  }}
                >
                  <Text
                    style={{
                      ...styles.itemText,
                      paddingTop: "4px",
                      textAlign: "center",
                    }}
                  >
                    {stringToPdfPriceText({ value: item.grossWeight || "" })}
                  </Text>
                </View>
              )}

              {/* unitPrice */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  borderRight: 1,
                  minHeight: "79px",
                  width: "50px",
                }}
              >
                <Text
                  style={{
                    ...styles.itemText,
                    paddingTop: "4px",
                  }}
                >
                  {item.unitPriceUnit}
                </Text>
                <Text
                  style={{
                    ...styles.itemText,
                  }}
                >
                  {stringToPdfPriceText({
                    value: item.unitPrice || "",
                  })}
                </Text>
              </View>

              {/* amount */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "79px",
                  width: "50px",
                }}
              >
                <Text
                  style={{
                    ...styles.itemText,
                    paddingTop: "4px",
                    textAlign: "center",
                  }}
                >
                  {item.amountUnit}
                </Text>
                <Text style={{ ...styles.itemText, textAlign: "center" }}>
                  {stringToPdfPriceText({ value: item.amount || "" })}
                </Text>
              </View>
            </View>
          );
        })}

        {/* Total */}
        <View
          wrap={false}
          style={{
            ...styles.flexRow,
            alignItems: "flex-start",
            minHeight: "22px",
            backgroundColor: "#000000",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minHeight: "22px",
              width: "61px",
              paddingLeft: "6px",
            }}
          >
            <Text style={styles.itemTableTotalTitle}>TOTAL</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minHeight: "22px",
              flex: 1,
            }}
          >
            <Text style={{ ...styles.itemTableTotalTitle, textAlign: "left" }}>
              {stringToPdfPriceText({
                value: data.totalQuantity,
                referenceIndex: 25,
              })}
            </Text>
          </View>

          {!data.totalIsNetWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "57px",
                minHeight: "22px",
              }}
            >
              <Text style={{ ...styles.itemTableTotalTitle, display: "flex" }}>
                {stringToPdfPriceText({ value: data.totalNetWeight })}
              </Text>
            </View>
          )}

          {!data.totalIsGrossWeightHidden && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "22px",
                paddingRight: "4px",
                paddingLeft: "4px",
                width: "64px",
                marginRight: "1px",
              }}
            >
              <Text style={styles.itemTableTotalTitle}>
                {stringToPdfPriceText({ value: data.totalGrossWeight })}
              </Text>
            </View>
          )}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "22px",
              width: "100px",
              marginRight: "1px",
            }}
          >
            <Text style={styles.itemTableTotalTitle}>
              {stringToPdfPriceText({
                value: data.totalAmount,
                referenceIndex: 5,
              })}
            </Text>
          </View>
        </View>

        {/* Bank */}
        <View style={styles.bankAndsignature}>
          <View
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
              height: "100%",
              marginTop: "auto",
              minHeight: "20px",
            }}
          />

          <View style={styles.bankContainer} wrap={false}>
            <View style={styles.bank}>
              {!data.isBankDetailHidden ? (
                <>
                  <Text style={styles.itemTitle}>{`<Bank Details>`}</Text>
                  <Text style={styles.itemText}>
                    BANK NAME : {data.bankName}
                  </Text>
                  <Text style={styles.itemText}>
                    BANK ADDRESS : {data.bankAddress}
                  </Text>
                  <Text style={styles.itemText}>BANK TEL : {data.bankTel}</Text>
                  <Text style={styles.itemText}>FAX NO. : {data.bankFax}</Text>
                  <Text style={styles.itemText}>
                    SWIFT CODE : {data.swiftCode}
                  </Text>
                  <Text style={styles.itemText}>
                    ACCOUNT NO. : {data.accountNumber}
                  </Text>
                  <Text style={styles.itemText}>
                    ACOUNT NAME : {data.accountName}
                  </Text>
                </>
              ) : null}
            </View>

            <View style={{ alignSelf: "flex-end" }}>
              <View style={styles.signature}>
                <View style={styles.signatureImageContainer}>
                  {!!data.signatureUrl ? (
                    <Image
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      src={{
                        uri: data.signatureUrl,
                        method: "GET",
                        headers: { "Cache-Control": "no-cache" },
                        body: "",
                      }}
                    />
                  ) : (
                    <View style={styles.signatureImageDefault} />
                  )}
                </View>
              </View>

              <View style={styles.signatureTextContainer}>
                <Text style={styles.signatureText}>Authorized Signature</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CiContent;

Font.register({
  family: "SpoqaHanSans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansRegular.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansBold.ttf",
      fontWeight: 700,
      fontStyle: "normal",
    },
  ],
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoBoldItalic,
      fontWeight: 700,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },

  // Title
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "81px",
    marginBottom: 2,
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    width: "100%",
    textAlign: "center",
    alignSelf: "center",
    position: "absolute",
  },
  draft: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: "italic",
    marginLeft: "auto",
    marginTop: "auto",
  },
  companyLogo: {
    position: "absolute",
    width: "81px",
    height: "81px",
  },
  companyLogoDefault: {
    position: "absolute",
    width: "76px",
    height: "76px",
    backgroundColor: colorSet.white,
  },

  // Common
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    minHeight: "auto",
    flex: 1,
  },
  flexItemColumn: {
    display: "flex",
    flexDirection: "column",
    minHeight: "auto",
    padding: "6px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },

  // Bank
  bankAndsignature: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
  },
  bankContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "8px",
    minHeight: "88px",
  },
  bank: { display: "flex", flexDirection: "column", marginTop: "auto" },

  // Signature
  signature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    width: "128px",
  },
  signatureImageDefault: {
    marginTop: "auto",
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: colorSet.white,
  },
  signatureImageContainer: {
    marginTop: "auto",
    width: "100%",
    maxHeight: 64,
  },
  signatureTextContainer: {
    display: "flex",
    width: "100%",
    minHeight: "32px",
  },

  // Text
  itemTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    marginBottom: "4px",
  },
  itemTableTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
  },
  itemTableTotalTitle: {
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    color: "#ffffff",
  },
  itemText: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "SpoqaHanSans",
  },
  signatureTitle: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
  },
  signatureText: {
    marginLeft: "auto",
    marginRight: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: 8,
    fontWeight: 700,
    fontFamily: "SpoqaHanSans",
    textAlign: "center",
    width: "128px",
    borderTop: 1,
  },
});
