import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { ColDef, IRowNode } from "ag-grid-community";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import { Button } from "@/src/components/atom/Button";
import { useAppSelector } from "@/src/store";
import Typo from "@/src/components/atom/Typo";
import {
  useLazyGetContractQuery,
  useLazyGetContractsQuery,
} from "@/src/store/apis/contracts";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import colorSet from "@/src/styles/color";
import AlertDialog from "@/src/components/atom/AlertDialog";
import useAlert from "@/src/hooks/useAlert";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useTranslation } from "react-i18next";

interface LoadContractsDialogProps {
  open: boolean;
  onClose: () => void;
  onFetchContractViaId: (detail: ContractDetailViewDto) => void;
}

enum AlertDialogState {
  NULL,
  SELECT,
}

const LoadContractDialog = ({
  open,
  onClose,
  onFetchContractViaId,
}: LoadContractsDialogProps) => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const ref = useRef<AgGridReact>(null);
  const alert = useAlert();
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [selectedIdState, setSelectedId] = useState<number | null>(null);
  const [buyerCode, setBuyerCode] = useState<string>();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => <>{radioRenderer(params)}</>,
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...column,
  ]);

  useAgGridHeaderRefresh({
    gridRef: ref.current,
    isReady,
    headerSet: [
      {
        columnKey: "createdAt",
        langKey: "contract:exporter.add.table.createdAt",
      },
      {
        columnKey: "scNo",
        langKey: "contract:exporter.add.table.scNo",
      },
      {
        columnKey: "item",
        langKey: "contract:exporter.add.table.itemCode",
      },
      {
        columnKey: "qty",
        langKey: "contract:exporter.add.table.qty",
      },
      {
        columnKey: "price",
        langKey: "contract:exporter.add.table.price",
      },
      {
        columnKey: "shippingTerms",
        langKey: "contract:exporter.add.table.shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "contract:exporter.add.table.placeOfDelivery",
      },
      {
        columnKey: "location",
        langKey: "contract:exporter.add.table.location",
      },
    ],
  });

  const { isBuyerListFetching, buyers } = useGetBuyersQuery(
    {},
    {
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        buyers: !isError && currentData?.data ? currentData.data : [],
        isBuyerListError: isError,
        isBuyerListFetching: isFetching,
      }),
      refetchOnMountOrArgChange: true,
    }
  );
  const [getContracts, { isFetching, count, list }] = useLazyGetContractsQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => ({
      isFetching,
      list: !isError ? currentData?.rows || [] : [],
      count: currentData?.count || 0,
    }),
  });
  const [getContractDetail] = useLazyGetContractQuery();

  const contractsList = list.reduce<any>((acc, val) => {
    const resource = {
      id: val.id,
      buyerNameCode: val.buyerNameCode,
      createdAt: val.orderDateAt,
      scNo: val.scNo,
      item: val.itemCode,
      qty: `${parseFloat(val.quantity).toLocaleString("ko-KR")} ${
        val.quantityUnit
      }`,
      price: `${parseFloat(val.unitPrice).toLocaleString("ko-KR")} ${
        val.unitPriceUnit
      }`,
      shippingTerms: val.shippingTerms,
      placeOfDelivery: val.placeOfDelivery ?? "",
      location: val.origin ?? "",
      isChecked: selectedIdState === val.id,
    };
    return [...acc, resource];
  }, []);

  const buyerList = buyers.reduce<{ label: string; value: string }[]>(
    (acc, val) => {
      const resource = {
        label: val?.buyerNameCode || "",
        value: `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
      };
      return [...acc, resource];
    },
    [{ label: lang === "en" ? "All" : "전체", value: "all" }]
  );

  const radioRenderer = (params: IRowNode) => {
    const handleRadioChange = () => {
      setSelectedId(params.data.id);
    };

    const checked = params.data.isChecked;

    return (
      <StyledRadio>
        <input
          type="radio"
          className="custom-ag-radio"
          checked={checked}
          onChange={handleRadioChange}
        />
        <span className="custom-radio-mark" />
      </StyledRadio>
    );
  };

  useEffect(() => {
    if (isFetching && ref.current) {
      ref.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getContracts({
        isExporterMainCategoryFilterOn: true,
        page: 1,
        pageSize: 10,
      });
    })();
  }, [getContracts]);

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.SELECT) {
      return (
        <AlertDialog
          open
          title={t(
            "contract:exporter.add.alertDialog.loadPreviousContract.title"
          )}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={async () => {
            if (!selectedIdState) {
              return setAlertDialogState(AlertDialogState.NULL);
            }
            try {
              const { row } = await getContractDetail({
                id: selectedIdState,
              }).unwrap();
              onFetchContractViaId(row);
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              alert.showAlert({ type: "error", message });
            } finally {
              setAlertDialogState(AlertDialogState.NULL);
              onClose();
            }
          }}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
        >
          <WhiteSpace>
            {t(
              "contract:exporter.add.alertDialog.loadPreviousContract.description"
            )}
          </WhiteSpace>
        </AlertDialog>
      );
    }
  };

  return (
    <>
      <Dialog
        title={t("contract:exporter.add.alert.loadContracts")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <ButtonContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("contract:exporter.add.common.exit")}
            </Button>
            <Button
              disabled={!selectedIdState}
              onClick={() => setAlertDialogState(AlertDialogState.SELECT)}
            >
              {t("contract:exporter.add.common.select")}
            </Button>
          </ButtonContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">
              {t("contract:exporter.add.alert.buyerCode")}
            </Typo>
            <Select
              options={buyerList}
              showSearch
              defaultValue={"all"}
              suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
              disabled={isBuyerListFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              onChange={async (value, option: any) => {
                setBuyerCode(value === "all" ? undefined : option.label);
                setPagination({ page: 1, pageSize: pagination.pageSize });
                await getContracts({
                  buyerNameCode: value === "all" ? undefined : option.label,
                  isExporterMainCategoryFilterOn: true,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </InputContainer>

          <Table
            ref={ref}
            onGridReady={() => setIsReady(true)}
            columnDefs={columnDefs}
            rowData={contractsList}
            totalPage={count}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getContracts({
                page,
                pageSize,
                buyerNameCode: buyerCode,
                isExporterMainCategoryFilterOn: true,
              });
            }}
            onRowClicked={(params) => setSelectedId(params.data.id)}
          />
        </Content>
      </Dialog>
      {renderAlertDialog()}
    </>
  );
};

export default LoadContractDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    border: 1px solid ${colorSet.gray9} !important;
    border-radius: 8px;
  }
`;

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

const column: ColDef[] = [
  {
    headerName: "Importer Code",
    field: "buyerNameCode",
    width: 180,
    ...commonColumn,
  },
  {
    headerName: "Contract Registration",
    field: "createdAt",
    width: 180,
    minWidth: 180,
    cellRenderer: (params: any) => {
      return dayjs(params.data.createdAt).format("YYYY-MM-DD");
    },
    ...commonColumn,
  },
  {
    headerName: "SC No.",
    field: "scNo",
    width: 140,
    ...commonColumn,
  },
  {
    headerName: "Item",
    field: "item",
    width: 160,
    ...commonColumn,
  },
  {
    headerName: "Qty",
    field: "qty",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Unit Price",
    field: "price",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Shipping Terms",
    field: "shippingTerms",
    width: 160,
    ...commonColumn,
  },
  {
    headerName: "Place of Delivery",
    field: "placeOfDelivery",
    width: 160,
    cellRenderer: (params: any) => {
      if (!params.data.placeOfDelivery) {
        return "-";
      }
      return params.data.placeOfDelivery;
    },
    ...commonColumn,
  },
  {
    headerName: "Location",
    field: "location",
    width: 150,
    cellRenderer: (params: any) => {
      if (!params.data.location) {
        return "-";
      }
      return params.data.location;
    },
    ...commonColumn,
  },
];

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const WhiteSpace = styled.p`
  white-space: pre-wrap;
`;
