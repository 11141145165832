import dayjs from "dayjs";
import { styled } from "styled-components";
import { useParams } from "react-router-dom";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { IconButton } from "@/src/components/atom/Button";
import { downloadFile } from "@/src/utils/downloadFile";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { contractDetailApi } from "@/src/store/apis/contracts/contractDetail";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { isNull, isUndefined } from "@/src/utils/is";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { useTranslation } from "react-i18next";

export const PAYMENT_TERMS_LC_TYPE = "L/C";
const CONTRACT_INFO_DATE_FORMAT = "YYYY-MM-DD";

const memoEmptyArray: ContractDetailViewDto["simpleContractMemos"] = [];

function ContractInformationCard() {
  const { t } = useTranslation();
  const params = useParams();

  const {
    paymentTerms,
    paymentTermsRemark,
    orderDateAt,
    expireDateAt,
    lastShipmentDateAt,
    placeOfDelivery,
    anyPortIn,
    origin,
    allocation,
    poNo,
    poSimpleMedia,
    scNo,
    scSimpleMedia,
    paymentTermsSimpleMedia,
    hsCode,
    freetime,
    isAddContractTerms,
    isAddBankDetail,
    contractRemark,
    importerContractRemark,
    simpleContractMemos,
    lcNo,
  } = contractDetailApi.endpoints.getImporterContractDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          paymentTerms: !isUnstable ? currentData.paymentTerms : "",
          paymentTermsRemark: !isUnstable
            ? currentData.paymentTermsRemark ?? ""
            : "",
          orderDateAt:
            !isUnstable && currentData.importerOrderDateAt
              ? dayjs(currentData.importerOrderDateAt).format(
                  CONTRACT_INFO_DATE_FORMAT
                )
              : "-",
          expireDateAt:
            !isUnstable && currentData.expireDateAt
              ? dayjs(currentData.expireDateAt).format(
                  CONTRACT_INFO_DATE_FORMAT
                )
              : "-",
          lastShipmentDateAt:
            !isUnstable && currentData.lastShipmentDateAt
              ? dayjs(currentData?.lastShipmentDateAt).format(
                  CONTRACT_INFO_DATE_FORMAT
                )
              : "-",
          placeOfDelivery: !isUnstable
            ? currentData.placeOfDelivery ?? "-"
            : "-",
          anyPortIn: !isUnstable ? currentData.anyPortIn ?? "" : "",
          origin: !isUnstable ? currentData.origin ?? "-" : "-",
          allocation: currentData?.allocation,
          poNo: !isUnstable ? currentData.poNo ?? "-" : "-",
          poSimpleMedia: currentData?.poSimpleMedia,
          scNo: !isUnstable ? currentData.scNo ?? "-" : "-",
          scSimpleMedia: currentData?.scSimpleMedia,
          paymentTermsSimpleMedia: currentData?.paymentTermsSimpleMedia,
          hsCode: !isUnstable ? currentData.importerHsCode ?? "-" : "-",
          freetime: !isUnstable ? currentData.freetime ?? "-" : "-",
          isAddContractTerms:
            !isUnstable && currentData?.isAddContractTerms
              ? "Included"
              : "Not Included",
          isAddBankDetail:
            !isUnstable && currentData?.isAddBankDetail
              ? "Included"
              : "Not Included",
          contractRemark: !isUnstable ? currentData.contractRemark ?? "-" : "-",
          importerContractRemark: !isUnstable
            ? currentData.importerContractRemark ?? "-"
            : "-",
          simpleContractMemos:
            !isUnstable && currentData.simpleContractMemos
              ? currentData.simpleContractMemos ?? memoEmptyArray
              : memoEmptyArray,
          lcNo: !isUnstable ? currentData.lcNo ?? "-" : "-",
        };
      },
    }
  );

  return (
    <ContractInformationCardContainer>
      {/* Left */}
      <FlexColumn>
        {/* Contract Information */}
        <StyledSectionCardFlexFive
          cardTitle={t(
            "contract:importer.detail.contractInformationCard.title"
          )}
        >
          <ContractInformationFlexColumn>
            {/* Card */}
            <ContractDateContainer>
              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  Payment Terms
                </Typo>
                <PaymentTermsContainer>
                  <BreakWordTypo color="blue2" typoType="h4">
                    {paymentTerms
                      ? `${paymentTerms} ${paymentTermsRemark}`
                      : "-"}
                  </BreakWordTypo>
                </PaymentTermsContainer>
              </ContractDateCard>

              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  Order Date
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {orderDateAt}
                </Typo>
              </ContractDateCard>

              <GrayLine />

              {paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                <ContractDateCard>
                  <Typo color="gray6" typoType="b9m">
                    Expire Date
                  </Typo>
                  <Typo color="blue2" typoType="h4">
                    {expireDateAt}
                  </Typo>
                </ContractDateCard>
              )}

              <ContractDateCard>
                <Typo color="gray6" typoType="b9m">
                  Last Shipment Date
                </Typo>
                <Typo color="blue2" typoType="h4">
                  {lastShipmentDateAt}
                </Typo>
              </ContractDateCard>
            </ContractDateContainer>

            {/* Rows */}
            <ContractInformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Location
              </Typo>
              <section>
                <SectionCardRow
                  label={"Place of Delivery"}
                  value={placeOfDelivery ?? anyPortIn ?? "-"}
                />
                <SectionCardRow label={"Origin / Location"} value={origin} />
                {allocation && (
                  <SectionCardRow
                    label={"Allocation"}
                    value={allocation ?? "-"}
                  />
                )}
              </section>
            </ContractInformationRow>

            <ContractInformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Attachment File
              </Typo>
              <section>
                <SectionCardRow
                  label={"PO No."}
                  value={
                    <FileInfoContainer>
                      <BreakWordTypo typoType="b7r" color="gray5">
                        {poNo}
                      </BreakWordTypo>
                      <div>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          disabled={isNull(poSimpleMedia)}
                        >
                          {isNull(poSimpleMedia) ? (
                            <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                          ) : (
                            <Icon
                              iconSrc={DownloadSvg}
                              iconSize={16}
                              onClick={() =>
                                downloadFile(
                                  poSimpleMedia?.mediaUrl ?? "",
                                  poSimpleMedia?.originalFileName ?? ""
                                )
                              }
                            />
                          )}
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          disabled={isNull(poSimpleMedia)}
                        >
                          {isNull(poSimpleMedia) ? (
                            <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                          ) : (
                            <a
                              href={poSimpleMedia?.mediaUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon iconSrc={PreviewSvg} iconSize={16} />
                            </a>
                          )}
                        </IconButton>
                      </div>
                    </FileInfoContainer>
                  }
                />

                <SectionCardRow
                  label={"SC No."}
                  value={
                    <FileInfoContainer>
                      <BreakWordTypo typoType="b7r" color="gray5">
                        {scNo}
                      </BreakWordTypo>
                      <div>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          disabled={isNull(scSimpleMedia)}
                        >
                          {isNull(scSimpleMedia) ? (
                            <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                          ) : (
                            <Icon
                              iconSrc={DownloadSvg}
                              iconSize={16}
                              onClick={() =>
                                downloadFile(
                                  scSimpleMedia?.mediaUrl ?? "",
                                  scSimpleMedia?.originalFileName ?? ""
                                )
                              }
                            />
                          )}
                        </IconButton>
                        <IconButton
                          buttonSize={24}
                          buttonColor="blue"
                          buttonGrade="secondary"
                          disabled={isNull(scSimpleMedia)}
                        >
                          {isNull(scSimpleMedia) ? (
                            <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                          ) : (
                            <a
                              href={scSimpleMedia?.mediaUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon iconSrc={PreviewSvg} iconSize={16} />
                            </a>
                          )}
                        </IconButton>
                      </div>
                    </FileInfoContainer>
                  }
                />

                {paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                  <SectionCardRow
                    label={"L/C No."}
                    value={
                      <FileInfoContainer>
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {lcNo}
                        </BreakWordTypo>
                        <div>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(paymentTermsSimpleMedia)}
                          >
                            {paymentTermsSimpleMedia === null ? (
                              <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                            ) : (
                              <Icon
                                iconSrc={DownloadSvg}
                                iconSize={16}
                                onClick={() =>
                                  downloadFile(
                                    paymentTermsSimpleMedia?.mediaUrl ?? "",
                                    paymentTermsSimpleMedia?.originalFileName ??
                                      ""
                                  )
                                }
                              />
                            )}
                          </IconButton>
                          <IconButton
                            buttonSize={24}
                            buttonColor="blue"
                            buttonGrade="secondary"
                            disabled={isNull(paymentTermsSimpleMedia)}
                          >
                            {paymentTermsSimpleMedia === null ? (
                              <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                            ) : (
                              <a
                                href={paymentTermsSimpleMedia?.mediaUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewSvg} iconSize={16} />
                              </a>
                            )}
                          </IconButton>
                        </div>
                      </FileInfoContainer>
                    }
                  />
                )}
              </section>
            </ContractInformationRow>

            <ContractInformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                ETC
              </Typo>
              <section>
                <SectionCardRow label={"HS Code"} value={hsCode} />
                <SectionCardRow label={"Freetime"} value={freetime} />
              </section>
            </ContractInformationRow>

            <ContractInformationRow>
              <Typo as="p" typoType="b9m" color="gray7">
                Optional
              </Typo>
              <section>
                <SectionCardRow
                  label={"Contract Term"}
                  value={isAddContractTerms}
                />
                <SectionCardRow label={"Bank Detail"} value={isAddBankDetail} />
              </section>
            </ContractInformationRow>
          </ContractInformationFlexColumn>
        </StyledSectionCardFlexFive>
      </FlexColumn>

      {/* Right */}
      <FlexColumn>
        {/* Remark */}
        <StyledSectionCardFlexOne cardTitle="Remark">
          <RemarkContainer>
            <SectionCardRow
              label={
                <Typo color="gray7" typoType="b9m">
                  PO Remark
                </Typo>
              }
              direction="vertical"
              value={
                <ReadOnlyText typoType="b7r">
                  {importerContractRemark}
                </ReadOnlyText>
              }
            />

            <SectionCardRow
              label={
                <Typo color="gray7" typoType="b9m">
                  SC Remark
                </Typo>
              }
              direction="vertical"
              value={
                <ReadOnlyText typoType="b7r">{contractRemark}</ReadOnlyText>
              }
            />
          </RemarkContainer>
        </StyledSectionCardFlexOne>

        {/* Memo */}
        <StyledSectionCardFlexOne
          cardTitle={t("contract:importer.detail.contractInformationCard.memo")}
        >
          <MemoContainer>
            <MemoDescription>
              <StyledIcon iconSrc={InfoSvg} />
              <Typo typoType="b9r" color="gray6">
                {t("contract:importer.detail.description.memo")}
              </Typo>
            </MemoDescription>

            <MemoContent>
              {simpleContractMemos.length >= 1 ? (
                simpleContractMemos.map((item, idx) => {
                  return (
                    <MemoItem key={idx.toString()}>
                      <MemoLabelContainer>
                        <Typo color="gray3" typoType="h9">
                          {item.writerName}
                        </Typo>
                        <Typo color="gray7" typoType="b9r">
                          {`(${dayjs(item.wroteAt).format("YY-MM-DD")}, ${dayjs(
                            item.wroteAt
                          ).format("HH:mm")})`}
                        </Typo>
                      </MemoLabelContainer>

                      <ReadOnlyText typoType="b7r" color="gray6">
                        {item.memo}
                      </ReadOnlyText>
                    </MemoItem>
                  );
                })
              ) : (
                <MemoNoDataContainer>
                  {renderNoRowsComponent()}
                </MemoNoDataContainer>
              )}
            </MemoContent>
          </MemoContainer>
        </StyledSectionCardFlexOne>
      </FlexColumn>
    </ContractInformationCardContainer>
  );
}

export default ContractInformationCard;

const ContractInformationCardContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const StyledSectionCardFlexOne = styled(SectionCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledSectionCardFlexFive = styled(StyledSectionCardFlexOne)`
  flex: 5;
`;

const ContractInformationFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContractInformationRow = styled.div`
  & > p {
    padding-bottom: 8px;
  }

  & > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ContractDateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  background: ${colorSet.gray11};
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

const ContractDateCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const GrayLine = styled.div`
  grid-column: 2 span;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const RemarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 400px;
  ${StyledScroll}
`;

const MemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MemoDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 162px;
  ${StyledScroll}
`;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const FileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  & > div {
    display: flex;
    gap: 4px;
  }

  a {
    line-height: 0px;
  }
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;
