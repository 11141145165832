import SectionCard from "@/src/components/molecule/SectionCard";
import React, { Dispatch, SetStateAction } from "react";
import Flex from "@/src/components/molecule/Flex";
import Typo from "@/src/components/atom/Typo";
import styled, { css } from "styled-components";
import CallOut from "@/src/components/molecule/CallOut";
import colorSet from "@/src/styles/color";
import { renderRibbonIcon } from "../utils/renderRibbonIcon";
import DoughnutChart from "@/src/components/atom/chart/DoughnutChart";
import { useAppSelector } from "@/src/store";
import {
  renderItemDoughnutChartLabel,
  renderSellerDoughnutChartLabel,
} from "../utils/renderChartLabel";
import { useGetSellerAndItemRankStatisticQuery } from "@/src/store/apis/dashboard/importer";
import { useImporterStatisticsFilterContext } from "../hooks/hook";
import dayjs from "dayjs";
import { isUndefined } from "@/src/utils/is";
import { commonApi } from "@/src/store/apis/common";
import Loader from "@/src/components/atom/Loader";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { RANK } from "../constant";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

type RankType = "seller" | "item";
export type DoughnutTooltipType = {
  value: number;
  percentage: number;
  unit?: string;
};

interface TradingVolumeStatisticsProps {
  selectSellerRankIndex: number;
  onSellerRankIndexChange: Dispatch<SetStateAction<number>>;
  selectItemRankIndex: number;
  onItemRankIndexChange: Dispatch<SetStateAction<number>>;
}

function TradingVolumeStatistics({
  selectSellerRankIndex,
  onSellerRankIndexChange,
  selectItemRankIndex,
  onItemRankIndexChange,
}: TradingVolumeStatisticsProps) {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const { ataAt, mainCategoryCodeItemName } =
    useImporterStatisticsFilterContext();

  const { sellerTopRankList, itemTopRankList, isFetching } =
    useGetSellerAndItemRankStatisticQuery(
      {
        ataAtFrom: dayjs(ataAt?.[0]).startOf("day").toISOString(),
        ataAtTo: dayjs(ataAt?.[1]).endOf("day").toISOString(),
        importerMainCategories:
          mainCategoryCodeItemName !== "all"
            ? mainCategoryCodeItemName
            : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isFetching || isError || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isFetching,
            sellerTopRankList: isStable ? currentData.sellerTopRankList : [],
            itemTopRankList: isStable ? currentData.itemTopRankList : [],
          };
        },
      }
    );

  const { currentData: measurementList } =
    commonApi.endpoints.getCommonCodeViaCodeName.useQueryState({
      codeName: "MEASUREMENT",
    });

  const sellerTopRankData = sellerTopRankList.reduce<{
    labels: string[];
    data: DoughnutTooltipType[];
  }>(
    (acc, val) => ({
      labels: [...acc.labels, val.sellerName],
      data: [
        ...acc.data,
        {
          value: Number(val.sellerTotalNetWeight),
          percentage: val.sellerTotalNetWeightPercent,
          unit: val.sellerTotalNetWeightUnit,
        },
      ],
    }),
    {
      labels: [],
      data: [],
    }
  );
  const sellerDoughnut = {
    labels: sellerTopRankData.labels,
    datasets: [
      {
        data: sellerTopRankData.data,
        backgroundColor: [
          colorSet.chartBlueViolet6,
          colorSet.chartBlueViolet4,
          colorSet.chartBlueViolet3,
          colorSet.chartBlueViolet2,
          colorSet.chartBlueViolet1,
        ],
      },
    ],
  };

  const itemDoughnutData = itemTopRankList.reduce<{
    labels: string[];
    data: DoughnutTooltipType[];
  }>(
    (acc, val) => ({
      labels: [...acc.labels, val.item],
      data: [
        ...acc.data,
        {
          value: Number(val.itemTotalNetWeight),
          percentage: val.itemTotalNetWeightPercent,
          unit: val.itemTotalNetWeightUnit,
        },
      ],
    }),
    {
      labels: [],
      data: [],
    }
  );
  const itemDoughnut = {
    labels: itemDoughnutData.labels,
    datasets: [
      {
        data: itemDoughnutData.data,
        backgroundColor: [
          colorSet.chartAqua1,
          colorSet.chartAqua2,
          colorSet.chartAqua3,
          colorSet.chartAqua4,
          colorSet.chartAqua5,
        ],
      },
    ],
  };

  const renderRankingRow = ({
    index,
    label,
    value,
    unit,
    percentage,
  }: {
    index: number;
    label: string;
    value: string;
    unit?: string;
    percentage: number;
  }) => {
    return (
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap={4}>
          {renderRibbonIcon(index)}
          <Typo typoType="b10r" color="gray6">
            {label}
          </Typo>
        </Flex>
        <Typo typoType="b10m">
          {Number(value).toLocaleString("ko-KR")} {unit} ({percentage}%)
        </Typo>
      </Flex>
    );
  };

  const renderRankingTabs = (rankType: RankType) => {
    if (rankType === "seller") {
      let sellerTabList = sellerTopRankList;
      sellerTabList = sellerTabList.concat(
        new Array(5 - sellerTabList.length).fill("")
      );

      return (
        <RankingTabs role="tablist">
          {sellerTabList.map(({ sellerId, rank }, idx) => {
            const isDisabled = typeof sellerId !== "number";

            return (
              <StyledRankingItem
                key={idx.toString()}
                tabIndex={idx === selectSellerRankIndex ? 0 : -1}
                isSelect={idx === selectSellerRankIndex}
                tabValue={idx}
                onClick={() => onSellerRankIndexChange(idx)}
                onFocusItem={() => onSellerRankIndexChange(idx)}
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <Typo
                    typoType="b9m"
                    color={idx === selectSellerRankIndex ? "blue4" : "gray7"}
                  >
                    -
                  </Typo>
                ) : (
                  <Typo
                    typoType="b9m"
                    color={idx === selectSellerRankIndex ? "blue4" : "gray7"}
                  >
                    {RANK[rank as keyof typeof RANK][lang]}
                  </Typo>
                )}
              </StyledRankingItem>
            );
          })}
        </RankingTabs>
      );
    }

    if (rankType === "item") {
      let itemTabList = itemTopRankList;
      itemTabList = itemTabList.concat(
        new Array(5 - itemTabList.length).fill("")
      );

      return (
        <RankingTabs role="tablist">
          {itemTabList.map(({ itemId, rank }, idx) => {
            const isDisabled = typeof itemId !== "number";

            return (
              <StyledRankingItem
                key={idx.toString()}
                tabIndex={idx === selectItemRankIndex ? 0 : -1}
                isSelect={idx === selectItemRankIndex}
                tabValue={idx}
                onClick={() => onItemRankIndexChange(idx)}
                onFocusItem={() => onItemRankIndexChange(idx)}
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <Typo
                    typoType="b9m"
                    color={idx === selectItemRankIndex ? "blue4" : "gray7"}
                  >
                    -
                  </Typo>
                ) : (
                  <Typo
                    typoType="b9m"
                    color={idx === selectItemRankIndex ? "blue4" : "gray7"}
                  >
                    {RANK[rank as keyof typeof RANK][lang]}
                  </Typo>
                )}
              </StyledRankingItem>
            );
          })}
        </RankingTabs>
      );
    }
  };

  const renderSellerContent = () => {
    const selectRank = sellerTopRankList[selectSellerRankIndex];
    const sellerList =
      selectRank?.itemTopRankList?.map(
        ({
          item,
          itemTotalNetWeight,
          itemTotalNetWeightUnit,
          itemTotalNetWeightPercent,
        }) => {
          return {
            label: item,
            value: itemTotalNetWeight,
            percentage: itemTotalNetWeightPercent,
            unit:
              measurementList?.find(
                (item) => item.codeItemName === itemTotalNetWeightUnit
              )?.codeItemNameEn ?? "",
          };
        }
      ) ?? [];
    const isNoData = isUndefined(selectRank);

    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    const renderCallOutContent = () => {
      if (isNoData) {
        return (
          <Flex alignItems="center" justifyContent="center" flex={1}>
            {renderNoRowsComponent()}
          </Flex>
        );
      }

      return (
        <>
          <Flex flexDirection="column" gap={4}>
            <Typo typoType="h7">{selectRank?.sellerName ?? "-"}</Typo>
            <Typo typoType="b8m">
              {t("dashboard:importer.statistics.top3ItemsByTradingVolume")}
            </Typo>
          </Flex>

          <Flex flexDirection="column" gap={8}>
            {sellerList.map((row, index) => {
              return renderRankingRow({ ...row, index });
            })}
          </Flex>
        </>
      );
    };

    return (
      <Flex flexDirection="column" gap={16} flex={1}>
        <ChartContainer>
          <DoughnutChart
            data={sellerDoughnut as any}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  bodyColor: colorSet.gray8,
                  titleColor: colorSet.gray10,
                  bodyFont: {
                    size: 14,
                  },
                  xAlign: "left",
                  yAlign: "center",
                  callbacks: {
                    title: (chartItem) => {
                      return chartItem[0].dataset.label;
                    },
                    label: (a) => {
                      const index = a.dataIndex;
                      const dataset = a.dataset.data[index] as any;
                      const { value, percentage, unit } = dataset;

                      const unitName =
                        measurementList?.find(
                          (item) => item.codeItemName === unit
                        )?.codeItemNameEn || "";

                      return `${Number(value).toLocaleString(
                        "ko-KR"
                      )} ${unitName} (${percentage}%)`;
                    },
                  },
                },
                title: {
                  display: true,
                  color: colorSet.gray5,
                  padding: {
                    bottom: 16,
                  },
                  font: {
                    size: 15,
                  },
                  text: t(
                    "dashboard:importer.statistics.sellersByTradingVolume"
                  ),
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </ChartContainer>

        <Flex justifyContent="center" gap={16}>
          {sellerTopRankData.labels.map((item, idx) => {
            return renderSellerDoughnutChartLabel(idx, item.toString());
          })}
        </Flex>
        <StyledCallOut borderColor="gray9" backgroundColor="white">
          {renderRankingTabs("seller")}
          <Divider />
          {renderCallOutContent()}
        </StyledCallOut>
      </Flex>
    );
  };

  const renderItems = () => {
    const selectRank = itemTopRankList[selectItemRankIndex];
    const itemList =
      selectRank?.sellerTopRankList?.map(
        ({
          sellerName,
          sellerTotalNetWeight,
          sellerTotalNetWeightUnit,
          sellerTotalNetWeightPercent,
        }) => {
          return {
            label: sellerName,
            value: sellerTotalNetWeight,
            percentage: sellerTotalNetWeightPercent,
            unit:
              measurementList?.find(
                (item) => item.codeItemName === sellerTotalNetWeightUnit
              )?.codeItemNameEn ?? "",
          };
        }
      ) ?? [];
    const isNoData = isUndefined(selectRank);

    if (isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    const renderCallOutContent = () => {
      if (isNoData) {
        return (
          <Flex alignItems="center" justifyContent="center" flex={1}>
            {renderNoRowsComponent()}
          </Flex>
        );
      }

      return (
        <>
          <Flex flexDirection="column" gap={4}>
            <Typo typoType="h7">{selectRank?.item ?? "-"}</Typo>
            <Typo typoType="b8m">
              {t("dashboard:importer.statistics.top3SellersByTradingVolume")}
            </Typo>
          </Flex>
          <Flex flexDirection="column" gap={8}>
            <Flex flexDirection="column" gap={8}>
              {itemList.map((row, index) => {
                return renderRankingRow({ ...row, index });
              })}
            </Flex>
          </Flex>
        </>
      );
    };

    return (
      <Flex flexDirection="column" gap={16} flex={1}>
        <ChartContainer>
          <DoughnutChart
            data={itemDoughnut as any}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
                tooltip: {
                  bodyColor: colorSet.gray8,
                  titleColor: colorSet.gray10,
                  bodyFont: {
                    size: 14,
                  },
                  xAlign: "left",
                  yAlign: "center",
                  callbacks: {
                    title: (chartItem) => {
                      return chartItem[0].dataset.label;
                    },
                    label: (a) => {
                      const index = a.dataIndex;
                      const dataset = a.dataset.data[index] as any;
                      const { value, percentage, unit } = dataset;

                      const unitName =
                        measurementList?.find(
                          (item) => item.codeItemName === unit
                        )?.codeItemNameEn || "";

                      return `${Number(value).toLocaleString(
                        "ko-KR"
                      )} ${unitName} (${percentage}%)`;
                    },
                  },
                },
                title: {
                  display: true,
                  color: colorSet.gray5,
                  padding: {
                    bottom: 16,
                  },
                  font: {
                    size: 15,
                  },
                  text: t("dashboard:importer.statistics.itemsByTradingVolume"),
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </ChartContainer>
        <Flex justifyContent="center" gap={16}>
          {itemDoughnutData.labels.map((item, idx) => {
            return renderItemDoughnutChartLabel(idx, item.toString());
          })}
        </Flex>

        <StyledCallOut borderColor="gray9" backgroundColor="white">
          {renderRankingTabs("item")}
          <Divider />
          {renderCallOutContent()}
        </StyledCallOut>
      </Flex>
    );
  };

  return (
    <SectionCard
      cardTitle={t("dashboard:importer.statistics.tradingVolumeStatistics")}
    >
      <Flex gap={16}>
        {renderSellerContent()}
        {renderItems()}
      </Flex>
    </SectionCard>
  );
}

export default TradingVolumeStatistics;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const StyledCallOut = styled(CallOut)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 233px;
`;

const RankingTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledRankingItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 999px;
  background: ${colorSet.gray10};
  cursor: pointer;

  ${({ isSelect }) =>
    isSelect &&
    css`
      background: ${colorSet.white};
      border-color: ${colorSet.blue4};
    `}

  &:disabled {
    cursor: not-allowed;
  }
`;

const ChartContainer = styled.div`
  height: 212px;
  margin: 0 auto;
  width: calc(100% - 213px);
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 495px;
`;
