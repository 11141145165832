import React from "react";
import styled from "styled-components";
import Typo from "@/src/components/atom/Typo";
import { useAppSelector } from "@/src/store";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import Icon from "@/src/components/atom/Icon";
import PaperAirplaneSvg from "@/src/assets/icons/icon-paper-airplane.svg";
import { Button } from "@/src/components/atom/Button";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useTranslation } from "react-i18next";

const LinkSuccess = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  return (
    <StyledMain>
      <Typo as="h1" typoType="d3" className="enter-account-code-heading">
        {t("linkToAccount:linkToAccount")}
      </Typo>

      <StyledBasicCard>
        <Icon iconSrc={PaperAirplaneSvg} iconSize={80} />

        <Flex>
          <Typo typoType="d6" color="gray1">
            {t("linkToAccount:integrationApprovalRequestCompleted")}
          </Typo>
          <Typo typoType="b7m">
            {t("linkToAccount:integrationApprovalRequestCompletedDescription")}
          </Typo>
        </Flex>

        <StyledButton
          buttonColor="blue"
          buttonGrade="secondary"
          onClick={() =>
            navigate(
              user?.exporter.companyType === "BOTH" ||
                user?.exporter.companyType === "SELLER"
                ? EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT
                : IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT
            )
          }
        >
          {t("linkToAccount:goToClientManagement")}
        </StyledButton>
      </StyledBasicCard>
    </StyledMain>
  );
};

export default LinkSuccess;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h1.enter-account-code-heading {
    padding: 24px 0;
  }
`;

const StyledBasicCard = styled(SectionCardWithoutHeader)`
  width: 640px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  white-space: pre-wrap;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
