import { forwardRef, useMemo, useState } from "react";
import Select from ".";
import { styled } from "styled-components";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import { SelectProps as AntdSelectProps, ConfigProvider, Divider } from "antd";
import Icon from "@/src/components/atom/Icon";
import IconSearchInput from "@/src/assets/icons/icon-search.svg";
import Typo from "@/src/components/atom/Typo";
import { useAppSelector } from "@/src/store";

type OptionType = {
  value: string;
  label: string;
};

export interface ComboBoxSelectProps extends AntdSelectProps {
  options?: OptionType[];
  value?: string[];
  disableValues?: string[];
  searchInputPlaceholder?: string;
}

const ComboBoxSelect = forwardRef<HTMLSelectElement, ComboBoxSelectProps>(
  (props, ref) => {
    const lang = useAppSelector((state) => state.lang.value);

    const {
      options,
      value: propsValue,
      disableValues,
      searchInputPlaceholder,
      ...restProps
    } = props;

    const [searchValue, setSearchValue] = useState("");
    const [open, setOpen] = useState(false);

    const selectOptions = useMemo(() => {
      return (
        options
          ?.filter(({ label, value }) => {
            if (searchValue && value === "all") return false;

            return (
              label.toLowerCase().includes(searchValue.toLowerCase()) ||
              value.toLowerCase().includes(searchValue.toLowerCase())
            );
          })
          ?.map(({ label, value }) => {
            return {
              label,
              value,
              disabled: disableValues?.includes(value),
            };
          }) ?? []
      );
    }, [options, searchValue, disableValues]);

    return (
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Pretendard Regular",
            controlItemBgHover: colorSet.gray10,
            borderRadius: 8,
            colorTextPlaceholder: colorSet.gray7,
            fontSizeIcon: 16,
          },
        }}
      >
        <StyledSelect
          ref={ref}
          style={{ width: "100%" }}
          mode="multiple"
          showAction={[]}
          options={selectOptions}
          value={propsValue}
          onFocus={() => setOpen(true)}
          onKeyDown={(e) => e.stopPropagation()}
          tagRender={(props) => {
            const tagIndex =
              propsValue?.findIndex((val) => val === props.value) ?? -1;

            if (tagIndex === 0) {
              return (
                <StyledTypo $marginLeft={12} typoType="b9r">
                  {props.label}
                </StyledTypo>
              );
            } else if (tagIndex === 1) {
              if (!propsValue || propsValue?.length < 2) {
                return <></>;
              }

              const text =
                lang === "ko"
                  ? `외 ${propsValue.length - 1}개`
                  : `and ${propsValue.length - 1} others`;

              return (
                <StyledTypo $marginLeft={4} typoType="b9r">
                  {text}
                </StyledTypo>
              );
            } else {
              return <></>;
            }
          }}
          dropdownRender={(menu) => (
            <>
              <SearchInputContainer>
                <BorderlessInput
                  autoFocus
                  ref={(input) => {
                    setTimeout(() => {
                      input?.focus();
                    }, 100);
                  }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      e.stopPropagation();
                    }
                  }}
                  placeholder={searchInputPlaceholder}
                />
                <Icon iconSrc={IconSearchInput} iconSize={18} />
              </SearchInputContainer>
              <Divider style={{ margin: "0 0 4px" }} />
              {menu}
            </>
          )}
          filterOption={false}
          showSearch={false}
          onDropdownVisibleChange={(open) => {
            setOpen(open);
            setSearchValue("");
          }}
          suffixIcon={<Icon iconSrc={IconSearchInput} iconSize={18} />}
          onSelect={(value, option) => {
            if (
              value === "all" || // 선택한 옵션이 전체
              propsValue?.length === (options?.length ?? 0) - 2 // 하나만 선택하면 모두 선택하게 되는 경우
            ) {
              restProps.onChange?.(["all"], [option]);
            } else {
              if (propsValue?.includes("all")) {
                // 선택된 옵션들 중, 전체가 있을 경우
                restProps.onChange?.(
                  [...propsValue.filter((val) => val !== "all"), value],
                  []
                );
              } else {
                // 기본 동작
                restProps.onChange?.([...(propsValue ?? []), value], []);
              }
            }
          }}
          onDeselect={(value) => {
            if (value === "all") {
              // 전체 선택해제
              restProps.onChange?.(["all"], []);
            } else if (propsValue?.length === 1) {
              // 선택된 옵션이 1개였을 경우,
              restProps.onChange?.(["all"], []);
            } else {
              // 기본 동작
              restProps.onChange?.(
                propsValue?.filter((val) => val !== value) ?? [],
                []
              );
            }
          }}
          open={open}
          {...restProps}
          onChange={undefined}
        />
      </ConfigProvider>
    );
  }
);

export default ComboBoxSelect;

const StyledTypo = styled(Typo)<{ $marginLeft: number }>`
  margin-left: ${({ $marginLeft }) => `${$marginLeft}px`};
`;

const SearchInputContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 8px 8px 16px;
`;

const BorderlessInput = styled.input`
  width: 100%;
  border: none;
  flex: 1;
  ${typo.b9r};
  padding: 0;

  &::placeholder {
    color: ${colorSet.gray7};
    ${typo.b9r};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${colorSet.gray7};
    background: ${colorSet.gray10};
  }
`;

const StyledSelect = styled(Select)`
  .ant-select .ant-select-arrow {
    background: blue;
  }

  // max count
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    & > span {
      background: ${colorSet.blue10};
      box-shadow: 0px 0px 0px 0.5px rgba(23, 98, 195, 0.2),
        0px 1px 2px rgba(5, 29, 57, 0.1);
      border-radius: 64px;
      padding: 2px 8px;
      color: ${colorSet.blue4};
      ${typo.btn3m}
      margin-right: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
`;
