import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import dayjs from "dayjs";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import ShipmentWithContract from "./components/shipments/ShipmentWithContract";
import ShipmentWithTask from "./components/shipments/ShipmentWithTask";
import ShipmentWithContainer from "./components/shipments/ShipmentWithContainer";
import ShipmentWithDocument from "./components/shipments/ShipmentWithDocument";
import SharedEmailLayout from "@/src/components/template/Layout/SharedEmailLayout";
import { useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sessionStorageKeySet } from "@/src/constant/sessionstorage";
import useAlert from "@/src/hooks/useAlert";
import CertificationCodeLayout from "./components/CertificationCode";
import { Button } from "@/src/components/atom/Button";
import FormItem from "@/src/components/molecule/FormItem";
import { useLazyGetShipmentShareQuery } from "@/src/store/apis/shipments/shipmentShare";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { SHARED_SEARCH_PARAMS_STRINGS } from "./constants";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import { isUndefined } from "@/src/utils/is";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { useLazyGetShipmentDetailQuery } from "@/src/store/apis/shipments/shipmentDetail";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import TabItem from "@/src/components/molecule/TabItem";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

type TabType = "BOOKING_CONTRACT" | "LOADING" | "CONTAINER" | "DOCUMENT_MEMO";

export type ShipmentDecrypt = {
  buyerId: string;
  shipmentId: string;
  shipmentShareKey: string;
};

const TAB_LIST: TabType[] = [
  "BOOKING_CONTRACT",
  "LOADING",
  "CONTAINER",
  "DOCUMENT_MEMO",
];

const SharedShipmentPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const sharedShipmentEncryptKey = location.search.slice(1);

  const [
    getShipmentSharedData,
    {
      buyerName,
      etdAt,
      etaAt,
      shippingLine,
      vesselAndVoyageNo,
      portOfLoading,
      placeOfDelivery,
      invoiceNo,
      isShipmentError,
    },
  ] = useLazyGetShipmentShareQuery({
    selectFromResult: ({ isError, currentData, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        buyerName: !isUnstable ? currentData.buyerName : "-",
        etdAt:
          !isUnstable && currentData.etdAt
            ? dayjs
                .utc(currentData.etdAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
        etaAt:
          !isUnstable && currentData.etaAt
            ? dayjs
                .utc(currentData.etaAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
        shippingLine: !isUnstable ? currentData.shippingLine : "-",
        vesselAndVoyageNo:
          !isUnstable && currentData.vessel && currentData.voyageNo
            ? `${currentData.vessel} ${currentData.voyageNo}`
            : "-",
        portOfLoading: !isUnstable ? currentData.portOfLoading ?? "-" : "-",
        placeOfDelivery: !isUnstable ? currentData.placeOfDelivery ?? "-" : "-",
        invoiceNo: !isUnstable ? currentData.invoiceNo ?? "-" : "-",
        isShipmentError: isError,
      };
    },
  });

  const [
    getPreviewShipment,
    {
      previewBuyerName,
      previewEtdAt,
      previewEtaAt,
      previewShippingLine,
      previewVesselAndVoyageNo,
      previewPortOfLoading,
      previewPlaceOfDelivery,
      previewInvoiceNo,
      isPreviewError,
    },
  ] = useLazyGetShipmentDetailQuery({
    selectFromResult: ({ isError, currentData, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        previewBuyerName: !isUnstable ? currentData.buyerName : "-",
        previewEtdAt:
          !isUnstable && currentData.etdAt
            ? dayjs
                .utc(currentData.etdAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
        previewEtaAt:
          !isUnstable && currentData.etaAt
            ? dayjs
                .utc(currentData.etaAt)
                .format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : "-",
        previewShippingLine: !isUnstable ? currentData.shippingLine : "-",
        previewVesselAndVoyageNo:
          !isUnstable && currentData.vessel && currentData.voyageNo
            ? `${currentData.vessel} ${currentData.voyageNo}`
            : "-",
        previewPortOfLoading: !isUnstable
          ? currentData.portOfLoading ?? "-"
          : "-",
        previewPlaceOfDelivery: !isUnstable
          ? currentData.placeOfDelivery ?? "-"
          : "-",
        previewInvoiceNo: !isUnstable ? currentData.invoiceNo ?? "-" : "-",
        isPreviewError: isError,
      };
    },
  });

  // Query
  // sharedShipmentEncryptKey;
  const shipmentDecryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedShipmentEncryptKey)
  ) as ShipmentDecrypt;

  const shipmentSessionKeysArray = window.sessionStorage.getItem(
    sessionStorageKeySet.SHARED_SHIPMENT
  )
    ? (JSON.parse(
        window.sessionStorage.getItem(
          sessionStorageKeySet.SHARED_SHIPMENT
        ) as string
      ) as string[])
    : [];
  const isIncludesShipmentSessionKey = shipmentSessionKeysArray?.includes(
    sharedShipmentEncryptKey
  );
  const isPreview =
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.preview) === "true";
  const previewShipmentId = Number(
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.shipmentId)
  );

  // State
  const [isCodeConfirm, setIsCodeConfirm] = useState(false);
  const [selectTab, setSelectTab] = useState<TabType>(TAB_LIST[0]);

  const { control, getValues, handleSubmit, setFocus } = useForm<{
    shipmentShareKey: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      shipmentShareKey: "",
    },
  });

  useEffect(() => {
    setFocus("shipmentShareKey");
  }, [setFocus]);

  // 공유코드 입력후 공유용 API Fetching
  const handleSubmitClick = async () => {
    const params = {
      shipmentShareKey: getValues("shipmentShareKey"),
      shipmentId: Number(shipmentDecryptToObject.shipmentId),
    };

    try {
      await getShipmentSharedData(params).unwrap();

      const jsonArray = JSON.stringify([
        ...shipmentSessionKeysArray,
        sharedShipmentEncryptKey,
      ]);

      window.sessionStorage.setItem(
        sessionStorageKeySet.SHARED_SHIPMENT,
        jsonArray
      );

      setIsCodeConfirm(true);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  };

  // 마운트 시 query내 복호화된 shareKey가 입력되었을 때 공유용 API Fetching
  const fetchWhenMount = useCallback(async () => {
    const params = {
      shipmentShareKey: shipmentDecryptToObject.shipmentShareKey,
      shipmentId: Number(shipmentDecryptToObject.shipmentId),
    };

    try {
      await getShipmentSharedData(params).unwrap();
      buttonRef.current?.focus();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  }, [
    shipmentDecryptToObject.shipmentShareKey,
    shipmentDecryptToObject.shipmentId,
    getShipmentSharedData,
    alert,
  ]);

  // 미리보기 형식 상세정보 Fetching
  const getPreviewShipmentData = async () => {
    try {
      await getPreviewShipment({
        id: previewShipmentId,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useLayoutEffect(() => {
    if (isPreview) {
      setIsCodeConfirm(true);
      getPreviewShipmentData();
    }
    if (isIncludesShipmentSessionKey) {
      setIsCodeConfirm(true);
      fetchWhenMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabItemTitle = (type: TabType, t: TFunction) => {
    switch (type) {
      case "BOOKING_CONTRACT":
        return t("shareEmail:sharedShipment.tabs.booking");

      case "LOADING":
        return t("shareEmail:sharedShipment.tabs.loading");

      case "CONTAINER":
        return t("shareEmail:sharedShipment.tabs.container");

      case "DOCUMENT_MEMO":
        return t("shareEmail:sharedShipment.tabs.document");
    }
  };

  const renderShipmentCommonInfoCard = () => {
    const shipmentData = {
      buyerName: isPreview ? previewBuyerName : buyerName,
      etdAt: isPreview ? previewEtdAt : etdAt,
      etaAt: isPreview ? previewEtaAt : etaAt,
      shippingLine: isPreview ? previewShippingLine : shippingLine,
      vesselAndVoyageNo: isPreview
        ? previewVesselAndVoyageNo
        : vesselAndVoyageNo,
      portOfLoading: isPreview ? previewPortOfLoading : portOfLoading,
      placeOfDelivery: isPreview ? previewPlaceOfDelivery : placeOfDelivery,
    };

    return (
      <SectionCardGrid>
        <StyledSectionCardBuyerRow
          label={
            <Typo color="gray6" typoType="b9m">
              Importer
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {shipmentData.buyerName}
            </BreakWordTypo>
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              ETD
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{shipmentData.etdAt}</BreakWordTypo>
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              ETA
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">{shipmentData.etaAt}</BreakWordTypo>
          }
        />

        <GrayLine />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Shipping Line
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {shipmentData.shippingLine}
            </BreakWordTypo>
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Vessel / Voyage No.
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {shipmentData.vesselAndVoyageNo}
            </BreakWordTypo>
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Port of Loading
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {shipmentData.portOfLoading}
            </BreakWordTypo>
          }
        />

        <StyledSectionCardRow
          label={
            <Typo color="gray6" typoType="b9m">
              Place of Delivery
            </Typo>
          }
          direction="vertical"
          value={
            <BreakWordTypo typoType="h4">
              {shipmentData.placeOfDelivery}
            </BreakWordTypo>
          }
        />
      </SectionCardGrid>
    );
  };

  const renderTabItemContent = (type: TabType) => {
    const shipmentShareKey = isIncludesShipmentSessionKey
      ? shipmentDecryptToObject.shipmentShareKey
      : getValues("shipmentShareKey");
    const sharedShipmentId = Number(shipmentDecryptToObject.shipmentId);

    switch (type) {
      case "BOOKING_CONTRACT":
        return (
          <ShipmentWithContract
            sharedShipmentId={sharedShipmentId}
            shipmentShareKey={shipmentShareKey}
          />
        );

      case "LOADING":
        return (
          <ShipmentWithTask
            sharedShipmentId={sharedShipmentId}
            shipmentShareKey={shipmentShareKey}
          />
        );

      case "CONTAINER":
        return (
          <ShipmentWithContainer
            sharedShipmentId={sharedShipmentId}
            shipmentShareKey={shipmentShareKey}
          />
        );

      case "DOCUMENT_MEMO":
        return (
          <ShipmentWithDocument
            sharedShipmentId={sharedShipmentId}
            shipmentShareKey={shipmentShareKey}
          />
        );
    }
  };

  const renderSharedShipment = () => {
    const isError = isPreview ? isPreviewError : isShipmentError;
    const shipmentData = {
      invoiceNo: isPreview ? previewInvoiceNo : invoiceNo,
    };

    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          Invoice No. {shipmentData.invoiceNo}
        </PageTitle>
        {/* Card */}
        {renderShipmentCommonInfoCard()}

        <Tabs role="tablist">
          {TAB_LIST.map((item, idx) => {
            return (
              <StyledTabItem
                ref={idx === 0 ? buttonRef : null}
                key={idx.toString()}
                tabIndex={item === selectTab ? 0 : -1}
                data-selected={item === selectTab}
                tabValue={item}
                onClick={() => setSelectTab(item)}
                onFocusItem={(value) => {
                  setSelectTab(value as TabType);
                }}
              >
                <Typo
                  typoType="b5m"
                  color={item === selectTab ? "white" : "gray6"}
                >
                  {renderTabItemTitle(item, t)}
                </Typo>
              </StyledTabItem>
            );
          })}
        </Tabs>
        {renderTabItemContent(selectTab)}
      </>
    );
  };

  return (
    <>
      {!isCodeConfirm ? (
        <CertificationCodeLayout title="Check shipment contract information">
          <Form onSubmit={handleSubmit(handleSubmitClick)}>
            <FormItem
              label={"Certification Code"}
              type="text"
              name="shipmentShareKey"
              rules={{ required: true }}
              errorsMessage={{
                required: t("error:required"),
              }}
              control={control}
              direction="vertical"
              inputProps={{
                placeholder: "Enter code",
              }}
            />

            <StyledButton type="submit">Confirm</StyledButton>
          </Form>
        </CertificationCodeLayout>
      ) : (
        <SharedEmailLayout logoType="seller">
          {renderSharedShipment()}
        </SharedEmailLayout>
      )}
    </>
  );
};

export default SharedShipmentPage;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 3 span;

  p {
    max-width: 100%;
  }
`;

const StyledSectionCardBuyerRow = styled(StyledSectionCardRow)`
  grid-column: 6 span;
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid ${colorSet.gray9};
  background: ${colorSet.gray11};

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &[data-selected="true"] {
    background: ${colorSet.indigo};
    border: 1px solid ${colorSet.indigo};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
