import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/src/store";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import { Controller, useForm } from "react-hook-form";
import { ContractAddFormType } from "../../types/contract.type";
import { contractAddBasedOnPoForm } from "../../utils/defaultValue";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateContractBasedPoMutation,
  useLazyCheckScNoQuery,
} from "@/src/store/apis/contracts";
import {
  useCreateBuyerEmployeesMutation,
  useGetBuyersQuery,
  useLazyGetBuyerEmployeesQuery,
} from "@/src/store/apis/client/buyer";
import { isUndefined } from "@/src/utils/is";
import {
  useCreateSingleItemCodeMutation,
  useGetNonFilteredItemCodesQuery,
} from "@/src/store/apis/corporate/companyItemManegement";
import { useGetCommonCodeListQuery } from "@/src/store/apis/common";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import { commonCodeMapper } from "@/src/utils/commonCodeHelper";
import getScPdfData from "@/src/components/template/pdfs/Sc/utils/getScPdfData";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import AddItemDialog, {
  AddItemType,
} from "../../../ItemManagement/components/dialog/AddItemDialog";
import SectionCard from "@/src/components/molecule/SectionCard";
import styled, { css } from "styled-components";
import { mediaQuery } from "@/src/styles/mediaQuery";
import colorSet from "@/src/styles/color";
import Select from "@/src/components/atom/Select";
import typo from "@/src/styles/typography";
import { Button } from "@/src/components/atom/Button";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Input from "@/src/components/atom/Input";
import Loader from "@/src/components/atom/Loader";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import Icon from "@/src/components/atom/Icon";
import ReferemceImageDialog from "../detail/dialog/ReferemceImageDialog";
import AlertDialog from "@/src/components/atom/AlertDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ScPreview from "@/src/components/template/pdfs/Sc/Preview";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import NoDataBagSvg from "@/src/assets/icons/icon-no-data-bag.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import { useLazyGetContractDetailQuery } from "@/src/store/apis/contracts/contractDetail";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import SelectOptionButton from "@/src/components/atom/Button/SelectOptionButton";
import BuyerEmployeeAddDialog from "../../../ClientManagement/dialog/BuyerEmployeeAddDialog";
import { useTranslation } from "react-i18next";

enum DialogState {
  NULL,
  IMAGE,
  LOAD_CONTRACT,
  ADD_ITEM,
  ADD_CONTACT_PERSON,
}

enum AlertDialogState {
  NULL,
  SAVED_DATA,
  BACK_TO_PREVIOUS,
}

type OptionType = {
  value: string;
  label: string;
};

interface ContractAddBasedOnPoPageProps {
  id: number;
}

const ContractAddBasedOnPoPage = ({ id }: ContractAddBasedOnPoPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    unregister,
  } = useForm<ContractAddFormType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: contractAddBasedOnPoForm,
  });

  const user = useAppSelector((state) => state.auth.user);
  const subscription = useAppSelector((state) => state.subscription);

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    user?.exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const [
    getContractDetail,
    {
      quantityAndUnit,
      unitPriceAndUnit,
      amount,
      currentData,
      mainCategoryForImporter,
      importerItem,
      hasLastShipmentDateAtOnPo,
      hasFreetimeOnPo,
      hasOriginOnPo,
    },
  ] = useLazyGetContractDetailQuery({
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isUndefined(currentData) || isError || isFetching;

      return {
        quantityAndUnit:
          !isUnstable && currentData.quantity && currentData.quantityUnit
            ? `${Number(currentData.quantity).toLocaleString("ko-KR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${currentData.quantityUnit}`
            : "-",
        unitPriceAndUnit:
          !isUnstable && currentData.unitPrice && currentData.unitPriceUnit
            ? `${Number(currentData.unitPrice).toLocaleString("ko-KR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${currentData.unitPriceUnit}`
            : "-",
        amount:
          !isUnstable &&
          currentData.quantity &&
          currentData.unitPrice &&
          currentData.unitPriceUnit
            ? `${parseFloat(
                (
                  Number(currentData.quantity) * Number(currentData.unitPrice)
                ).toFixed(2)
              ).toLocaleString("ko-KR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${currentData.unitPriceUnit}`
            : "-",
        currentData,
        importerItem: !isUnstable ? currentData.importerItem : null,
        hasLastShipmentDateAtOnPo: !isUnstable
          ? !!currentData.lastShipmentDateAt
          : true,
        hasFreetimeOnPo: !isUnstable ? !!currentData.freetime : true,
        hasOriginOnPo: !isUnstable ? !!currentData.origin : true,
        mainCategoryForImporter: !isUnstable
          ? currentData.mainCategoryForImporter
          : null,
      };
    },
  });

  const [selectImage, setSelectImage] = useState("");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );

  // SC PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const previewScPdfResourceRef = useRef<ContractAddFormType | null>(null);

  const { mainCategoryList } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isErrorAndUndefined =
        isError || isFetching || currentData === undefined;

      const isManager = currentData?.row.exporterUserType === "MANAGER";

      return {
        mainCategoryList: !isErrorAndUndefined
          ? isManager
            ? currentData.row.mainCategoryCodeItemNames
            : currentData.row.exporter.mainCategoryCodeItemNames
          : [],
      };
    },
  });
  const [createContract] = useCreateContractBasedPoMutation();
  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [checkScNo] = useLazyCheckScNoQuery();

  const { buyerList, isBuyersFetching } = useGetBuyersQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          buyerList: !isUnstable ? currentData.data : [],
          isBuyersFetching: isFetching,
        };
      },
    }
  );
  const [createSingleItemCode] = useCreateSingleItemCodeMutation();
  const [getBuyerEmployees, buyerEmployeesResult] =
    useLazyGetBuyerEmployeesQuery();
  const {
    itemsList,
    isItemsFetching,
    refetch: itemListRefetch,
  } = useGetNonFilteredItemCodesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;

        return {
          itemsList: isStable ? currentData.rows : [],
          isItemsFetching: isFetching,
        };
      },
    }
  );

  const { isCommonCodeFetching, commonCodeCurrentData } =
    useGetCommonCodeListQuery(
      {
        codeNameList: [
          { codeName: "FREETIME" },
          { codeName: "COUNTRY" },
          { codeName: "MAIN_CATEGORY" },
        ],
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, currentData }) => {
          return {
            isCommonCodeFetching: isFetching,
            commonCodeCurrentData: currentData ?? [],
          };
        },
      }
    );

  const [freeTimeList = [], countryList = [], commonMainCategoryList = []] =
    commonCodeCurrentData;

  const exportersData = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const buyerToOptionList = buyerList
    .filter(
      ({ isActivated, mainCategoryCodeItemNames }) =>
        isActivated &&
        mainCategoryCodeItemNames.some((mainCategory) =>
          mainCategoryList.includes(mainCategory)
        )
    )
    .reduce<OptionType[]>((acc, val) => {
      const resource = {
        label: val.buyerName,
        value: val.buyerListQueryResultDto.id.toString(),
      };
      return [...acc, resource];
    }, []);

  const buyerListToOptionList = buyerEmployeesResult.isError
    ? []
    : buyerEmployeesResult.currentData?.rows
        .filter(({ isActivated }) => isActivated)
        .reduce<OptionType[]>((acc, val) => {
          const resource = {
            label: val.name,
            value: val.id.toString(),
          };
          return [...acc, resource];
        }, []);

  const itemListToOptionList = itemsList
    .filter(({ isActivated, mainCategoryCodeItemName, mainCategory }) => {
      const buyerMainCategory =
        buyerList.find(({ id }) => currentData?.buyerId === id)
          ?.mainCategoryCodeItemNames ?? [];

      return (
        isActivated &&
        mainCategoryList.includes(mainCategoryCodeItemName) &&
        buyerMainCategory.includes(mainCategoryCodeItemName) &&
        mainCategory === mainCategoryForImporter
      );
    })
    .reduce<OptionType[]>((acc, val) => {
      const resource = {
        label: `${val.itemCode ? `${val.itemCode} > ` : ""}${val.item} > ${
          val.subCategory
        } > ${val.mainCategory}`,
        value: `${val.id}_${val.exporterItemId}`,
      };
      return [...acc, resource];
    }, []);

  const freetimeOptions = freeTimeList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );

  const referenceImageList =
    getValues("referenceMediaIdList")?.map((item) => item.mediaUrl) || [];

  const contractAddScData = getScPdfData({
    type: "ADD",
    contractAddAndEditScData: {
      componyLogoUrl:
        exportersData.currentData?.basicInformation?.businessLogoSimpleMedia
          ?.mediaUrl ?? "",
      buyerCoporation: currentData?.buyerName || "",
      buyerContactPrefix: currentData?.telPrefix || "",
      buyerContact: currentData?.tel || "",
      buyerFaxPrefix: currentData?.faxPrefix || "",
      buyerFax: currentData?.fax || "",
      buyerCountryName: currentData?.buyerCountryName || "",
      buyerRegion: currentData?.buyerRegion || "",
      buyerPostalCode: currentData?.buyerPostalCode || "",
      buyerLocality: currentData?.buyerLocality || "",
      buyerStreetAddress: currentData?.buyerStreetAddress || "",
      scNumber: previewScPdfResourceRef.current?.scNo || "",
      orderDate: currentData?.orderDateAt
        ? dayjs(currentData?.orderDateAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
        : "",
      paymentTerm: currentData?.paymentTerms || "",
      paymentTermsRemark: currentData?.paymentTermsRemark || "",
      originLocation: previewScPdfResourceRef.current?.origin || "",
      shippingTerm: currentData?.shippingTerms || "",
      shippingTermRemark: currentData?.shippingTermsRemark || "",
      lastShipmentDate: previewScPdfResourceRef.current?.lastShipmentDateAt
        ? dayjs(previewScPdfResourceRef.current?.lastShipmentDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )
        : "",
      quantityPrefix: currentData?.quantityUnit || "",
      quantity: currentData?.quantity
        ? Number(
            currentData?.quantity.toString().replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      description: previewScPdfResourceRef.current?.item || "",
      hsCode: previewScPdfResourceRef.current?.hsCode || "",
      unitPricePrefix: currentData?.unitPriceUnit || "",
      unitPrice: currentData?.unitPrice
        ? Number(
            currentData?.unitPrice.toString().replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      amount:
        (currentData?.quantity &&
          currentData?.unitPrice &&
          Number(
            (
              (parseFloat(
                currentData?.quantity?.toString().replace(/,/g, "")
              ) || 0) *
              (parseFloat(
                currentData?.unitPrice?.toString().replace(/,/g, "")
              ) || 0)
            ).toFixed(2)
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        "",
      remark: previewScPdfResourceRef.current?.contractRemark || "",
      isAddBankDetail: previewScPdfResourceRef.current?.isAddBankDetail.length
        ? true
        : false,
      isAddContractTerms: previewScPdfResourceRef.current?.isAddContractTerms
        .length
        ? true
        : false,
    },
  });

  const handleImageClick = (image: string) => {
    setDialogState(DialogState.IMAGE);
    setSelectImage(image);
  };

  const handleCreateContract = async (formValue: ContractAddFormType) => {
    try {
      handleContentLoadingOn();

      const { row } = await createContract({
        /**
         * Basic Information
         */
        id,
        scNo: formValue.scNo,
        buyerId: Number(formValue.buyerId),
        buyerEmployeeId: Number(formValue.buyerEmployeeId),
        allocation: formValue.allocation || undefined,
        orderDateAt: dayjs(formValue.orderDateAt).startOf("day").toISOString(),
        isAddContractTerms: !!formValue.isAddContractTerms.length,
        hsCode: formValue.hsCode || undefined,
        exporterItemId: Number(formValue.exporterItemId.split("_")[1]),
        exporterItemCodeId: Number(formValue.exporterItemCodeId),
        /**
         * Transaction Information
         */
        freetime:
          formValue.freetimeCodeItemName === "D/I"
            ? formValue.freetimeDirect || undefined
            : formValue.freetime || undefined,
        freetimeCodeItemName:
          formValue.freetimeCodeItemName === "D/I"
            ? undefined
            : formValue.freetimeCodeItemName || undefined,
        isAddBankDetail: !!formValue.isAddBankDetail.length,
        lastShipmentDateAt: formValue.lastShipmentDateAt
          ? dayjs(formValue.lastShipmentDateAt).startOf("day").toISOString()
          : undefined,
        /**
         * Attachment file
         */
        referenceMediaIdList: formValue.referenceMediaIdList.length
          ? formValue.referenceMediaIdList.map(({ id }) => id)
          : undefined,
        scMediaId: formValue?.scMediaId?.[0]?.id,
        contractRemark: formValue.contractRemark || undefined,
        memo: formValue.memo || undefined,
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("contract:exporter.add.alert.saveSuccess"),
      });
      if (row.id) {
        navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${row.id}`);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  const handleAddItems = async ({
    item,
    exporterItemCodeList,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
  }: AddItemType) => {
    const buyerMaincategoryCodeItemName = commonMainCategoryList.find(
      ({ codeItemNameEn }) => codeItemNameEn === mainCategoryForImporter
    )?.codeItemName;

    if (buyerMaincategoryCodeItemName !== mainCategoryCodeItemName) {
      return alert.showAlert({
        type: "error",
        message: "수입자 계약의 메인 카테고리와 일치 하지 않습니다.",
      });
    }

    try {
      const { row } = await createSingleItemCode({
        item: item,
        itemCode: exporterItemCodeList[0].itemCode,
        subCategoryCodeItemName: subCategoryCodeItemName,
        mainCategoryCodeItemName: mainCategoryCodeItemName,
      }).unwrap();

      itemListRefetch();

      const categoryList =
        buyerList.find(({ id }) => watch("buyerId") === id.toString())
          ?.mainCategoryCodeItemNames ?? [];

      if (categoryList.includes(mainCategoryCodeItemName)) {
        setValue("exporterItemId", `${row.id}_${row.exporterItemId}`);
        setValue("mainCategory", row.mainCategory ?? "");
        setValue("subCategory", row.subCategory ?? "");
        setValue("item", row.item ?? "");
        setValue("exporterItemCodeId", String(row.id));
        setValue("exporterItemCode", row.itemCode);
        clearErrors("exporterItemId");
      }

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddBuyerEmployee = async (
    values: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    }
  ) => {
    const buyerId = getValues("buyerId");

    try {
      setDialogState(DialogState.NULL);
      const { row: createdEmployee } = await createEmployee({
        id: Number(buyerId),
        name: values.name,
        email: values.email,
        isActivated: values.isActivated === "Y",
        personalContactPrefix: values.personalContactPrefix || undefined,
        personalContact: values.personalContact || undefined,
        officeContactPrefix: values.officeContactPrefix || undefined,
        officeContact: values.officeContact || undefined,
        buyerEmployeeRemark: values.buyerEmployeeRemark || undefined,
      }).unwrap();

      await getBuyerEmployees({
        id: Number(buyerId),
      });

      setValue("buyerEmployeeId", createdEmployee.id.toString());
      clearErrors("buyerEmployeeId");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
    }
  };

  const renderItemSubInputs = () => {
    return (
      <>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.mainCategory")}
            <strong>*</strong>
          </SubFormLabel>
          <Controller
            name="mainCategory"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder="Main category"
              />
            )}
          />
        </SubFormRow>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.subCategory")}
            <strong>*</strong>
          </SubFormLabel>
          <Controller
            name="subCategory"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder="Sub category"
              />
            )}
          />
        </SubFormRow>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.item")}
            <strong>*</strong>
          </SubFormLabel>
          <Controller
            name="item"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder="Item"
              />
            )}
          />
        </SubFormRow>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.itemCode")}
            <strong>*</strong>
          </SubFormLabel>
          <Controller
            name="exporterItemCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder={getValues("mainCategory") ? "-" : "Item code"}
              />
            )}
          />
        </SubFormRow>
      </>
    );
  };

  const renderContractInfoBasedPo = () => {
    return (
      <>
        <SectionCardGrid>
          {[
            {
              gridColumn: 6,
              title: "Importer",
              value: (
                <BuyerText
                  color="systemBlue2"
                  typoType="h4"
                  onClick={() =>
                    navigate(
                      `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT}/${currentData?.buyerId}`
                    )
                  }
                >
                  {currentData?.buyerName || "-"}
                </BuyerText>
              ),
            },
            {
              gridColumn: 3,
              title: "Shipping Terms",
              value: (
                <BreakWordTypo typoType="h4">
                  {currentData?.shippingTerms || "-"}{" "}
                  {currentData?.shippingTermsRemark || ""}
                </BreakWordTypo>
              ),
            },
            {
              gridColumn: 3,
              title: "Importer Contract",
              value: <BreakWordTypo typoType="h4">-</BreakWordTypo>,
            },
            {
              isDivider: true,
            },
            {
              gridColumn: 3,
              title: "Item",
              value: (
                <BreakWordTypo typoType="h4">{importerItem}</BreakWordTypo>
              ),
            },
            {
              gridColumn: 3,
              title: "Qty",
              value: (
                <BreakWordTypo typoType="h4">{quantityAndUnit}</BreakWordTypo>
              ),
            },
            {
              gridColumn: 3,
              title: "Unit Price",
              value: (
                <BreakWordTypo typoType="h4">{unitPriceAndUnit}</BreakWordTypo>
              ),
            },
            {
              gridColumn: 3,
              title: "Amount",
              value: <BreakWordTypo typoType="h4">{amount}</BreakWordTypo>,
            },
          ].map(({ gridColumn, isDivider = false, title, value }) => {
            if (isDivider) {
              return <StyledSectionCardRow data-columns={12} data-divider />;
            }
            return (
              <StyledSectionCardRow
                data-columns={gridColumn}
                label={
                  <Typo color="gray6" typoType="b9m">
                    {title}
                  </Typo>
                }
                direction="vertical"
                value={value}
              />
            );
          })}
        </SectionCardGrid>

        <BottomSectionCard>
          {[
            {
              gridColumn: 3,
              title: "Place of Delivery",
              value: currentData?.placeOfDelivery || "-",
            },
            {
              gridColumn: 3,
              title: "Port of Discharge",
              value: currentData?.portOfDischarge || "-",
            },
            {
              gridColumn: 3,
              title: "Payment Terms",
              value: `${currentData?.paymentTerms} ${
                currentData?.paymentTermsRemark || ""
              }`,
            },
            {
              gridColumn: 3,
              title: "Origin / Location",
              value: currentData?.origin || "-",
            },
            {
              isDivider: true,
            },
            {
              gridColumn: 4,
              title: "Last Shipment Date",
              value: currentData?.lastShipmentDateAt
                ? dayjs(currentData?.lastShipmentDateAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )
                : "-",
            },
            {
              gridColumn: 4,
              title: "Freetime",
              value: currentData?.freetime || "-",
            },
            {
              gridColumn: 4,
              title: "Delivery Date",
              value: currentData?.deliveryDateAt
                ? dayjs(currentData.deliveryDateAt).format(
                    DATE_FORMAT_STRINGS.YYYY_MM_DD
                  )
                : "-",
            },
          ].map(({ gridColumn, isDivider = false, title, value }) => {
            if (isDivider) {
              return <StyledSectionCardRow data-columns={12} data-divider />;
            }
            return (
              <StyledSectionCardCenterRow
                data-columns={gridColumn}
                label={
                  <Typo color="gray6" typoType="b9m">
                    {title}
                  </Typo>
                }
                direction="vertical"
                value={
                  <Typo typoType="h4" color="blue2">
                    {value}
                  </Typo>
                }
              />
            );
          })}
        </BottomSectionCard>
      </>
    );
  };

  const renderAdditionalInfoBasedPo = () => {
    return (
      <>
        <SectionCard
          cardTitle={t(
            "contract:exporter.add.common.additionalInputInformation"
          )}
          cardContentContainerStyle={{ display: "flex", gap: "24px" }}
        >
          <FlexColumn flexGap={16}>
            <Typo typoType="b9m" color="gray7">
              Basic Information
            </Typo>

            <FlexRow>
              <HalfFormItemContainer>
                <StyledFormItem
                  label={t("contract:exporter.add.common.scNo")}
                  type={"text"}
                  name={"scNo"}
                  control={control as any}
                  inputProps={{
                    placeholder: t("contract:exporter.add.placeholder.scNo"),
                  }}
                  rules={{
                    required: true,
                    validate: async (scNo) => {
                      const checkResult = await checkScNo({ scNo });
                      return !checkResult.isError;
                    },
                  }}
                  errorsMessage={{
                    required: t("common:required"),
                    validate: t("error:alreadyExisted"),
                  }}
                />

                <StyledFormItem
                  label="Importer"
                  rules={{
                    required: true,
                  }}
                  type="singleSelect"
                  control={control as any}
                  name="buyerId"
                  inputContainerClassName="selector-size-m"
                  inputProps={{
                    suffixIcon: isBuyersFetching ? <Loader /> : undefined,
                    disabled: true,
                    placeholder: t("contract:exporter.add.placeholder.buyer"),
                    filterOption: (input, option) =>
                      ((option?.label as string) ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase()),
                    onChange: async (a: any) => {
                      try {
                        const res = await getBuyerEmployees({
                          id: Number(a),
                        }).unwrap();

                        const buyerCountryCodeItemName =
                          buyerList.find(
                            ({ buyerListQueryResultDto }) =>
                              buyerListQueryResultDto.id.toString() === a
                          )?.buyerListQueryResultDto.countryCodeItemName ??
                          undefined;
                        const buyerCountryInfo = countryList?.find(
                          ({ codeItemName }) =>
                            buyerCountryCodeItemName === codeItemName
                        );
                        const countryLabel = buyerCountryInfo
                          ? `${buyerCountryInfo.codeItemName}) ${buyerCountryInfo.codeItemNameEn}`
                          : undefined;

                        setValue("anyPortIn", countryLabel);
                        setValue(
                          "anyPortInCodeItemName",
                          buyerCountryCodeItemName
                        );

                        const candidate = contractAddBasedOnPoForm;

                        setValue("exporterItemId", candidate["exporterItemId"]);
                        setValue("mainCategory", candidate["mainCategory"]);
                        setValue("subCategory", candidate["subCategory"]);
                        setValue("item", candidate["item"]);
                        setValue(
                          "exporterItemCodeId",
                          candidate["exporterItemCodeId"]
                        );
                        setValue(
                          "exporterItemCode",
                          candidate["exporterItemCode"]
                        );

                        const validBuyerList = res.rows.filter(
                          ({ isActivated, id }) => isActivated
                        );

                        if (validBuyerList.length === 1) {
                          setValue(
                            "buyerEmployeeId",
                            validBuyerList[0].id.toString()
                          );
                          clearErrors("buyerEmployeeId");
                        } else {
                          setValue(
                            "buyerEmployeeId",
                            candidate["buyerEmployeeId"]
                          );
                        }
                      } catch (e: any) {
                        const message = Array.isArray(e.data.message)
                          ? e.data.message[0]
                          : e.data.message;
                        alert.showAlert({ type: "error", message });
                      }
                    },
                  }}
                  options={buyerToOptionList}
                  bottomAccessory={
                    <>
                      <SubFormRow>
                        <SubFormLabel>
                          Importer contact<strong>*</strong>
                        </SubFormLabel>
                        <Controller
                          name="buyerEmployeeId"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Flex className="selector-size-s">
                              <StyledSelect
                                {...field}
                                value={field.value || undefined}
                                data-invalid={!!errors.buyerEmployeeId}
                                showSearch
                                filterOption={(input, option) =>
                                  ((option?.label as string) ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={buyerListToOptionList}
                                placeholder={t(
                                  "contract:exporter.add.placeholder.buyerContact"
                                )}
                                suffixIcon={
                                  buyerEmployeesResult.isFetching ? (
                                    <Loader />
                                  ) : undefined
                                }
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      <DirectInputSelectOptionButton
                                        onClick={() => {
                                          setValue("buyerEmployeeId", "");
                                          setDialogState(
                                            DialogState.ADD_CONTACT_PERSON
                                          );
                                        }}
                                      >
                                        {t(
                                          "contract:exporter.add.common.directInput"
                                        )}
                                      </DirectInputSelectOptionButton>
                                      {(buyerListToOptionList?.length ?? 0) >
                                        0 && <div>{menu}</div>}
                                    </>
                                  );
                                }}
                              />
                              {errors.buyerEmployeeId && (
                                <StyledInputError
                                  message={t("error:required")}
                                />
                              )}
                            </Flex>
                          )}
                        />
                      </SubFormRow>
                    </>
                  }
                  errorsMessage={{
                    required: t("error:required"),
                    validate: t("error:alreadyExisted"),
                  }}
                />
                <StyledFormItem
                  label={t("contract:exporter.add.common.thirdPartyTrade")}
                  type="checkbox"
                  control={control as any}
                  name="isThirdPartyTrade"
                  inputProps={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!e.target.checked) {
                        unregister("allocation");
                      }
                    },
                  }}
                  options={[
                    {
                      label: t(
                        "contract:exporter.add.common.thirdPartyTradeLabel"
                      ),
                      value: "true",
                    },
                  ]}
                  bottomAccessory={
                    <>
                      {!!watch("isThirdPartyTrade").length && (
                        <SubFormRow>
                          <SubFormLabel>
                            Allocation<strong>*</strong>
                          </SubFormLabel>
                          <Flex>
                            <StyledInput
                              {...register("allocation", {
                                required: true,
                              })}
                              placeholder="Enter allocation"
                              data-invalid={!!errors.allocation}
                              onClear={() => setValue("allocation", "")}
                            />
                            {!!errors.allocation && (
                              <StyledInputError message={t("error:required")} />
                            )}
                          </Flex>
                        </SubFormRow>
                      )}
                    </>
                  }
                />
                <StyledFormItem
                  label="Order Date"
                  type="date"
                  control={control as any}
                  name="orderDateAt"
                  rules={{ required: true }}
                  errorsMessage={{
                    required: t("error:required"),
                  }}
                />
                <StyledFormItem
                  label={t("contract:exporter.add.common.optional")}
                  type="checkbox"
                  control={control as any}
                  name="isAddContractTerms"
                  options={[
                    {
                      label: t("contract:exporter.add.common.addContractTerm"),
                      value: "true",
                    },
                  ]}
                />
              </HalfFormItemContainer>
              <HalfFormItemContainer>
                <StyledFormItem
                  label={t("contract:exporter.add.common.hsCode")}
                  control={control as any}
                  name="hsCode"
                  type="text"
                  inputProps={{
                    placeholder: t("contract:exporter.add.placeholder.hsCode"),
                  }}
                />

                <CustomFormItemRow>
                  <StyledCustomLabel>
                    {t("contract:exporter.add.common.item")}
                  </StyledCustomLabel>
                  <DoubleInputContainerWithError style={{ flex: 1 }}>
                    <DoubleInputContainer style={{ width: "100%" }}>
                      <Controller
                        name="exporterItemId"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => {
                          const isItemFieldDisabled =
                            isItemsFetching || !watch("buyerId");
                          return (
                            <>
                              <StyledSelect
                                {...field}
                                value={field.value || undefined}
                                style={{ flex: 1, width: 1 }}
                                placeholder={t(
                                  "contract:exporter.add.placeholder.item"
                                )}
                                filterOption={(input, option) =>
                                  ((option?.label as string) ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={itemListToOptionList}
                                data-invalid={!!errors.exporterItemId}
                                showSearch
                                suffixIcon={
                                  isItemsFetching ? <Loader /> : undefined
                                }
                                disabled={isItemFieldDisabled}
                                onChange={(value, option: any) => {
                                  const splitedLabel =
                                    option.label.split(" > ");
                                  const hasItemCode = splitedLabel.length === 4;

                                  if (hasItemCode) {
                                    const [
                                      itemCode,
                                      item,
                                      subCategory,
                                      mainCategory,
                                    ] = splitedLabel;
                                    const itemCodeId = value.split("_")[0];
                                    setValue("exporterItemId", value);
                                    setValue(
                                      "mainCategory",
                                      mainCategory ?? ""
                                    );
                                    setValue("subCategory", subCategory ?? "");
                                    setValue("item", item ?? "");
                                    setValue("exporterItemCodeId", itemCodeId);
                                    setValue("exporterItemCode", itemCode);
                                  }
                                }}
                              />
                              <StyledButton
                                buttonColor="black"
                                buttonGrade="tertiary"
                                buttonSize={40}
                                onClick={() =>
                                  setDialogState(DialogState.ADD_ITEM)
                                }
                                disabled={isItemFieldDisabled}
                              >
                                <AddIcon disabled={isItemFieldDisabled} /> Add
                                Item
                              </StyledButton>
                            </>
                          );
                        }}
                      />
                    </DoubleInputContainer>
                    {errors.exporterItemId && (
                      <InputError message={t("error:required")} />
                    )}
                    {renderItemSubInputs()}
                  </DoubleInputContainerWithError>
                </CustomFormItemRow>
              </HalfFormItemContainer>
            </FlexRow>

            <Divider />

            <Typo typoType="b9m" color="gray7">
              Transaction Information
            </Typo>

            <FlexRow>
              <HalfFormItemContainer>
                {!hasLastShipmentDateAtOnPo && (
                  <StyledFormItem
                    label="Last Shipment Date"
                    type="date"
                    control={control as any}
                    name="lastShipmentDateAt"
                    inputProps={{
                      allowClear: true,
                    }}
                  />
                )}
                {!hasFreetimeOnPo && (
                  <StyledFormItem
                    label={"Freetime"}
                    type="singleSelect"
                    name={"freetimeCodeItemName"}
                    control={control as any}
                    options={[
                      {
                        label: t("contract:exporter.add.common.directInput"),
                        value: "D/I",
                      },
                      ...freetimeOptions,
                    ]}
                    inputContainerClassName="selector-size-m"
                    inputProps={{
                      placeholder: t(
                        "contract:exporter.add.placeholder.freetime"
                      ),
                      virtual: false,
                      allowClear: true,
                      suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
                      filterOption: (input, option) =>
                        ((option?.label as string) ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        ((option?.value as string) ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option?.value === "D/I",
                      dropdownRender: (menu) => {
                        return (
                          <CustomDropdownWrapper>{menu}</CustomDropdownWrapper>
                        );
                      },
                      onChange: (label: any, option: any) => {
                        if (option) {
                          setValue("freetime", option.label);
                        }
                      },
                      onClear: () => {
                        setValue("freetime", undefined);
                      },
                    }}
                    bottomAccessory={
                      watch("freetimeCodeItemName") === "D/I" ? (
                        <Input
                          {...register("freetimeDirect")}
                          placeholder="Enter freetime"
                          onClear={() => setValue("freetimeDirect", "")}
                        />
                      ) : null
                    }
                  />
                )}

                <StyledFormItem
                  label={t("contract:exporter.add.common.optional")}
                  type="checkbox"
                  control={control as any}
                  name="isAddBankDetail"
                  options={[
                    {
                      label: "Add Bank Detail",
                      value: "true",
                    },
                  ]}
                />
              </HalfFormItemContainer>
              <HalfFormItemContainer>
                {!hasOriginOnPo && (
                  <StyledFormItem
                    label="Origin / Location"
                    type="text"
                    control={control as any}
                    name="origin"
                    inputProps={{
                      placeholder: t(
                        "contract:exporter.add.placeholder.origin"
                      ),
                    }}
                  />
                )}
              </HalfFormItemContainer>
            </FlexRow>
          </FlexColumn>
        </SectionCard>
      </>
    );
  };

  const renderAttachmentFile = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.attachmentFile")}
      >
        <FlexGapDiv>
          <StyledFormItem
            label={t("contract:exporter.add.common.scFile")}
            type="file"
            name="scMediaId"
            control={control as any}
            inputProps={{
              defaultFileList: watch("scMediaId")
                ? [
                    new File(
                      [""],
                      watch("scMediaId")?.[0]?.originalFileName ?? ""
                    ),
                  ]
                : undefined,
              onRemove: () => setValue("scMediaId", undefined),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.referenceImage")}
            type="imageUpload"
            name="referenceMediaIdList"
            control={control as any}
            errorsMessage={{
              required: t("error:required"),
            }}
            bottomAccessory={
              <WarningDescription>
                <StyledIcon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("contract:exporter.add.placeholder.referenceImage")}
                </Typo>
              </WarningDescription>
            }
            inputProps={{
              imageSrcList: watch("referenceMediaIdList")?.map(
                ({ mediaUrl }) => {
                  return {
                    imageSrc: mediaUrl,
                    onClick: () => handleImageClick(mediaUrl),
                  };
                }
              ),
              onRemove: (removeItemUrl) => {
                setValue(
                  "referenceMediaIdList",
                  watch("referenceMediaIdList").filter(
                    ({ mediaUrl }) => mediaUrl !== removeItemUrl
                  )
                );
              },
            }}
          />
        </FlexGapDiv>
      </StyledSectionCard>
    );
  };

  const renderContractRemark = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.contractRemark")}
      >
        <FormItem
          type="textarea"
          name="contractRemark"
          control={control}
          inputProps={{
            style: { width: "100%" },
            placeholder: t("contract:exporter.add.placeholder.contractRemark"),
            maxLength: 1000,
          }}
          noLabel
        />
      </StyledSectionCard>
    );
  };

  const renderMemo = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.memo")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <MemoHalfContainer>
          <MemoPlaceholder>
            <Icon iconSrc={NoDataBagSvg} iconSize={56} />
            <Typo as="p" typoType="b7m" color="gray8">
              {t("contract:exporter.add.common.noData")}
            </Typo>
          </MemoPlaceholder>
        </MemoHalfContainer>
        <MemoHalfContainer>
          <MemoInputContainer>
            <WarningDescription style={{ color: colorSet.gray6 }}>
              <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
              {t("contract:exporter.add.memoInfo")}
            </WarningDescription>
            <FormItem
              type="textarea"
              name="memo"
              control={control}
              inputProps={{
                style: { width: "100%" },
                placeholder: t("contract:exporter.add.placeholder.memo"),
                maxLength: 500,
              }}
              noLabel
            />
          </MemoInputContainer>
        </MemoHalfContainer>
      </StyledSectionCard>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;

    if (dialogState === DialogState.IMAGE && selectImage) {
      return (
        <ReferemceImageDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          imageList={referenceImageList ?? [""]}
          selectImage={selectImage}
          onChangeSelect={setSelectImage}
        />
      );
    }

    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isSingleItemCode
          onSave={handleAddItems}
        />
      );
    }

    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      const selectedBuyer = buyerList.find(
        ({ id }) => watch("buyerId") === id.toString()
      );

      return (
        <BuyerEmployeeAddDialog
          onSave={handleAddBuyerEmployee}
          onClose={() => setDialogState(DialogState.NULL)}
          defaultCountryCode={selectedBuyer?.telPrefix}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) return null;

    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("contract:exporter.add.alertDialog.backToPrevious.title")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => navigate(-1)}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
        >
          {t("contract:exporter.add.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        handleContentLoadingOn();
        const result = await getContractDetail({ id });

        if (result.isSuccess) {
          reset({
            scNo: result.data.scNo,
            buyerId: result.data.buyerId.toString(),
            hsCode: result.data.hsCode || undefined,
            allocation: result.data.allocation || undefined,
            freetime: result.data.freetime || undefined,
            freetimeCodeItemName:
              !result.data.freetimeCodeItemName && !!result.data.freetime
                ? "D/I"
                : result.data.freetimeCodeItemName,
            freetimeDirect:
              !result.data.freetimeCodeItemName && !!result.data.freetime
                ? result.data.freetime
                : undefined,
            lastShipmentDateAt: result.data.lastShipmentDateAt
              ? dayjs(result.data.lastShipmentDateAt)
              : undefined,
            paymentTerms: result.data.paymentTerms || undefined,
            paymentTermsCodeItemName:
              result.data.paymentTermsCodeItemName || undefined,
            paymentTermsRemark: result.data.paymentTermsRemark || undefined,
            isThirdPartyTrade: result.data.allocation ? ["true"] : [],
            isAddBankDetail: result.data.isAddBankDetail ? ["true"] : [],
            isAddContractTerms: result.data.isAddContractTerms ? ["true"] : [],
            referenceMediaIdList: result.data.referenceSimpleMedias ?? [],
            origin: result.data.origin || undefined,
          });

          await getBuyerEmployees({ id: result.data.buyerId });
        }
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.date.message[0]
          : e.date.message;
        alert.showAlert({ message, type: "error" });
      } finally {
        handleContentLoadingOff();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:contract"), t("sideNav:exportContractCreate")]}
      customPageTitle={
        <HeaderInnerDiv>
          <h1>{t("sideNav:exportContractCreate")}</h1>
        </HeaderInnerDiv>
      }
      pageTitle={t("sideNav:exportContractCreate")}
    >
      <ContentSection>
        <StyledForm onSubmit={handleSubmit(handleCreateContract)}>
          <WarningDescription>
            <Icon iconSrc={InfoSvg} iconSize={16} />
            {t("contract:exporter.add.contractAddInfo")}
          </WarningDescription>

          {renderContractInfoBasedPo()}
          {renderAdditionalInfoBasedPo()}

          <Section>
            {renderAttachmentFile()}
            {renderContractRemark()}
          </Section>
          {renderMemo()}

          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() =>
                  setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
                }
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
                {t("contract:exporter.add.buttonLabel.backToPrevious")}
              </Button>

              <SaveButtonContainer>
                {(!watch("scMediaId") || !watch("scMediaId")?.length) && (
                  <>
                    <ScPreview
                      data={contractAddScData}
                      trigger={
                        <HiddenPreviewButton
                          type="button"
                          ref={previewButtonRef}
                        />
                      }
                    />
                    <Button
                      buttonGrade="tertiary"
                      buttonColor="black"
                      isLoading={
                        exportersData.isFetching || // 수출자정보 fetching
                        isPreviewLoading // PDF 미리보기 클릭시 loading
                      }
                      disabled={
                        exportersData.isFetching || // 수출자정보 fetching
                        isPreviewLoading // PDF 미리보기 클릭시 loading
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                      onClick={() => {
                        previewScPdfResourceRef.current = getValues();
                        setIsPreviewLoading(true);

                        setTimeout(() => {
                          if (previewButtonRef.current) {
                            previewButtonRef.current.click();
                            setIsPreviewLoading(false);
                          }
                        }, 1000);
                      }}
                    >
                      <Icon iconSrc={PreviewSvg} iconSize={16} />
                      {t("contract:exporter.add.buttonLabel.preview")}
                    </Button>
                  </>
                )}

                <Button
                  buttonGrade="primary"
                  style={{ width: 158 }}
                  type="submit"
                  disabled={isCreateDisabled}
                >
                  {t("contract:exporter.add.buttonLabel.save")}
                </Button>
              </SaveButtonContainer>
            </FooterButtonSection>
          </BottomFixedContainer>
        </StyledForm>
      </ContentSection>

      {renderDialog()}
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ContractAddBasedOnPoPage;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const StyleButton = styled(Button)`
  ${typo.btn3m}
`;

const HeaderInnerDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  h1 {
    ${typo.h1}
  }

  ${StyleButton} {
    flex-shrink: 0;
    margin-left: auto;
  }
`;

const WarningDescription = styled.p`
  ${typo.b9r};
  color: ${colorSet.gray4};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const HalfFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;
`;

const SubFormLabel = styled.p`
  color: ${colorSet.gray6};
  ${typo.b9r};

  strong {
    color: ${colorSet.red2};
    font-size: 12px;
    padding-left: 2px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.selector-size-s {
    width: calc(100% - 164px - 16px - 100px);
  }
`;

const SubFormRow = styled.div<{ isRequired?: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;

  ${Flex} {
    flex: 1;
  }

  ${SubFormLabel} {
    width: 100px;
    flex-shrink: 0;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;

  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }

  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const CustomLabel = styled.p`
  width: 164px;
  color: ${colorSet.gray6};
  ${typo.b9r};
  flex-shrink: 0;

  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const StyledCustomLabel = styled(CustomLabel)`
  color: ${colorSet.gray1};
  ${typo.b7r};
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DoubleInputContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  &.fixed-width-container {
    width: calc(100% - 172px);

    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      width: 100%;
    }
  }
`;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const Section = styled.section`
  display: flex;
  gap: 16px;
`;

const MemoHalfContainer = styled.div`
  flex: 1;
`;

const MemoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const MemoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlexGapDiv = styled(Flex)`
  gap: 24px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const DoubleInputContainerWithError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledFormItem = styled(FormItem)`
  .ant-select-selection-placeholder {
    width: 1px;
  }

  .selector-size-m {
    width: calc(100% - 164px - 8px);
  }

  .ant-picker .ant-picker-clear {
    width: 18px;
    height: 18px;
    outline: 1px solid white;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;

    .selector-size-m {
      width: 100%;
    }
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const AddIcon = styled(AddSvg)<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;

  path {
    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `};
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
`;

const CustomDropdownWrapper = styled.div`
  div.ant-select-item {
    &:first-of-type {
      color: ${colorSet.blue4};

      &::before {
        content: url(${AddBlueSvg});
        width: 18px;
        height: 18px;
        position: relative;
        margin-right: 8px;
        top: 4px;
      }
    }
  }
`;

const HiddenPreviewButton = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &[data-columns="3"] {
    grid-column: 3 span;
  }

  &[data-columns="4"] {
    grid-column: 4 span;
  }

  &[data-columns="6"] {
    grid-column: 6 span;
  }

  &[data-columns="12"] {
    grid-column: 12 span;
  }

  &[data-divider="true"] {
    border-top: 1px solid ${colorSet.gray9};
  }
`;

const BuyerText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

const BottomSectionCard = styled(SectionCardGrid)`
  padding: 16px 24px;
  background: ${colorSet.gray11};
  border: ${colorSet.gray10};
  text-align: center;
`;

const StyledSectionCardCenterRow = styled(StyledSectionCardRow)`
  align-items: center;
`;

const FlexColumn = styled.div<{ flexGap?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const Divider = styled.div`
  border-top: 1px solid ${colorSet.gray9};
  margin: 24px 0;
`;

const DirectInputSelectOptionButton = styled(SelectOptionButton)`
  color: ${colorSet.blue4};

  &:hover {
    &&& {
      color: ${colorSet.blue4};
    }
  }

  &::before {
    content: url(${AddBlueSvg});
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    top: 4px;
  }
`;
