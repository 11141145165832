import store from "../..";
import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import { InquiryDto, GenerateInquiryDto, ModifyInquiryDto } from "./interface";

const inquiryApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getInquiries: build.query<
      Rows<InquiryDto>,
      { page?: number; pageSize?: number }
    >({
      query: ({ page = 1, pageSize = 10 }) => ({
        url: "/inquiries",
        params: {
          service: "EXPORT_WEB",
          isViewable: true,
          exporterUserId: store.getState().auth.user?.id,
          page,
          pageSize,
        },
      }),
    }),
    getInquiry: build.query<InquiryDto, { id: number }>({
      query: ({ id }) => ({
        url: `/inquiries/${id}`,
      }),
      transformResponse: (res: Row<InquiryDto>) => res.row,
    }),
    createInquiry: build.mutation<
      InquiryDto,
      Omit<GenerateInquiryDto, "service">
    >({
      query: (body) => ({
        url: `/inquiries`,
        method: "POST",
        body: {
          service: "EXPORT_WEB",
          ...body,
        },
      }),
      transformResponse: (res: Row<InquiryDto>) => res.row,
    }),

    editInquiry: build.mutation<
      InquiryDto,
      Omit<ModifyInquiryDto, "service"> & { id: number }
    >({
      query: ({ id, ...restBody }) => ({
        url: `/inquiries/${id}`,
        method: "POST",
        body: {
          service: "EXPORT_WEB",
          ...restBody,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetInquiriesQuery,
  useLazyGetInquiryQuery,
  useEditInquiryMutation,
  useCreateInquiryMutation,
} = inquiryApi;
