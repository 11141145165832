import Badge from "@/src/components/atom/Badge";
import { SalesStatusType } from "@/src/store/apis/sales/interface";

const renderSalesStatusBadge = (param: {
  type: SalesStatusType | null;
  lang: "en" | "ko";
  size?: "S" | "L";
}) => {
  const { type, size = "S" } = param;

  switch (type) {
    case "PROCESSING":
      return (
        <Badge
          text={"Processing"}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "COMPLETE":
      return (
        <Badge
          text={"Complete"}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );
  }
};

export default renderSalesStatusBadge;
