import colorSet from "@/src/styles/color";
import { Chart, Plugin } from "chart.js";

export const BAR_COLOR_SET = [
  colorSet.chartBlue1,
  colorSet.chartBlue2,
  colorSet.chartBlue3,
  colorSet.chartBlue4,
  colorSet.chartBlue5,
  colorSet.chartBlue6,
  colorSet.chartBlue7,
  colorSet.chartBlue8,
  colorSet.chartBlue9,
  colorSet.chartBlue10,
];

export const BAR_MINT_COLOR_SET = [
  colorSet.chartMint1,
  colorSet.chartMint2,
  colorSet.chartMint3,
  colorSet.chartMint4,
  colorSet.chartMint5,
  colorSet.chartMint6,
  colorSet.chartMint7,
  colorSet.chartMint8,
  colorSet.chartMint9,
  colorSet.chartMint10,
];
export const BAR_TEAL_COLOR_SET = [
  colorSet.chartTeal1,
  colorSet.chartTeal2,
  colorSet.chartTeal3,
  colorSet.chartTeal4,
  colorSet.chartTeal5,
  colorSet.chartTeal6,
  colorSet.chartTeal7,
  colorSet.chartTeal8,
  colorSet.chartTeal9,
  colorSet.chartTeal10,
  colorSet.chartTeal11,
  colorSet.chartTeal12,
  colorSet.chartTeal13,
  colorSet.chartTeal14,
  colorSet.chartTeal15,
  colorSet.chartTeal16,
  colorSet.chartTeal17,
  colorSet.chartTeal18,
  colorSet.chartTeal19,
  colorSet.chartTeal20,
  colorSet.chartTeal21,
  colorSet.chartTeal22,
  colorSet.chartTeal23,
  colorSet.chartTeal24,
  colorSet.chartTeal25,
];

export const BAR_BLUE_VIOLET_COLOR_SET = [
  colorSet.chartBlueViolet1,
  colorSet.chartBlueViolet2,
  colorSet.chartBlueViolet3,
  colorSet.chartBlueViolet4,
  colorSet.chartBlueViolet5,
  colorSet.chartBlueViolet6,
  colorSet.chartBlueViolet7,
  colorSet.chartBlueViolet8,
  colorSet.chartBlueViolet9,
  colorSet.chartBlueViolet10,
  colorSet.chartBlueViolet11,
  colorSet.chartBlueViolet12,
  colorSet.chartBlueViolet13,
  colorSet.chartBlueViolet14,
  colorSet.chartBlueViolet15,
  colorSet.chartBlueViolet16,
  colorSet.chartBlueViolet17,
  colorSet.chartBlueViolet18,
  colorSet.chartBlueViolet19,
  colorSet.chartBlueViolet20,
  colorSet.chartBlueViolet21,
  colorSet.chartBlueViolet22,
  colorSet.chartBlueViolet23,
  colorSet.chartBlueViolet24,
  colorSet.chartBlueViolet25,
];

export const removeDuplicate = (items: any[]) => {
  return Array.from(new Set(items));
};

export const sortStackedBarChartPlugin: Plugin<"bar"> = {
  id: "p2",
  beforeDraw: (chart: Chart & { sortedData: Record<any, any> }) => {
    chart.sortedData = {};

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      dataset.data.forEach((data, index) => {
        if (!chart.sortedData[index]) {
          chart.sortedData[index] = {
            data: [],
          };
        }

        chart.sortedData[index].data[datasetIndex] = {
          datasetIndex: datasetIndex,
          hidden: false,
          color: dataset.backgroundColor,
          value: dataset.data[index],
          y: chart.getDatasetMeta(datasetIndex).data[index].y,
          base: (chart.getDatasetMeta(datasetIndex).data[index] as any).base,
        };
      });
    });

    const chartTop = chart.scales.y.top;
    const chartMax = chart.scales.y.max;
    const chartHeight = chart.scales.y.height / chartMax;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      dataset.data.forEach((data, index) => {
        chart.sortedData[index].data = Object.keys(chart.sortedData[index].data)
          .map((k) => chart.sortedData[index].data[k])
          .sort((a, b) => a.value - b.value);
        chart.sortedData[index].data.forEach((d: any, i: number) => {
          d.base =
            chartTop +
            (chartMax -
              Object.keys(chart.sortedData[index].data)
                .map((k) => chart.sortedData[index].data[k].value)
                .reduce((a, b) => a + b, 0)) *
              chartHeight +
            Object.keys(chart.sortedData[index].data)
              .map((k) => chart.sortedData[index].data[k])
              .filter((d) => d.hidden)
              .reduce((a, b) => a + b.value, 0) *
              chartHeight;

          for (let j = 0; j < i; j++) {
            d.base += chartHeight * chart.sortedData[index].data[j].value;
          }

          d.y = d.base + chartHeight * d.value;
        });
      });
    });
  },
  beforeDatasetDraw: (
    chart: Chart & { sortedData: Record<any, any> },
    args
  ) => {
    chart.getDatasetMeta(args.index).data.forEach((data, index) => {
      const el = chart.sortedData[index]?.data.filter(
        (e: any) => e.datasetIndex === args.index
      )[0];
      data.y = el?.y;
      (data as any).base = el?.base;
    });
  },
};
