export const transformValueOrNull = <T extends {}, K extends keyof T>({
  targetObject,
  additionalNullValue = [],
  removeFieldKey,
}: {
  targetObject: T;
  additionalNullValue?: any[];
  removeFieldKey?: K[];
}) => {
  const paramsRemoveFieldKey = removeFieldKey || [];

  return Object.fromEntries(
    Object.entries(targetObject)
      .filter(([key]) => !(paramsRemoveFieldKey as string[]).includes(key))
      .reduce<any>((acc, [key, val]) => {
        if ([...additionalNullValue, undefined, null, ""]?.includes(val)) {
          return [...acc, [key, null]];
        }

        return [...acc, [key, val]];
      }, [])
  );
};

export const transformValueOrUndefined = <T extends {}, K extends keyof T>({
  targetObject,
  additionalNullValue = [],
  removeFieldKey,
}: {
  targetObject: T;
  additionalNullValue?: any[];
  removeFieldKey?: K[];
}) => {
  const paramsRemoveFieldKey = removeFieldKey || [];

  return Object.fromEntries(
    Object.entries(targetObject)
      .filter(([key]) => !(paramsRemoveFieldKey as string[]).includes(key))
      .reduce<any>((acc, [key, val]) => {
        if ([...additionalNullValue, undefined, null, ""]?.includes(val)) {
          return [...acc, [key, undefined]];
        }

        return [...acc, [key, val]];
      }, [])
  );
};

export const dataConvertUndefined = (
  data: Record<string, any>,
  allKeyList?: string[]
) => {
  const dataConvertToUndefined = Object.entries(data).reduce(
    (acc: any, [key]) => {
      const isAllValue = allKeyList?.includes(key);
      if (isAllValue) {
        acc[key] = "all";
      } else {
        acc[key] = undefined;
      }

      return acc;
    },
    {}
  );

  return dataConvertToUndefined;
};

/**
 *
 * @param urlString : URL형태의 문자열
 * @description URL형태의 문자열을 객체로 바꿔주는 함수
 */
export const transformURLSearchParamsToObject = (urlString: string) => {
  return Object.fromEntries(new URLSearchParams(urlString));
};

// 타입을 PascalCase로 변환하는 유틸리티 함수
export const toPascalCase = (str: string): string => {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
