import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { renderLoadingStatusBadge } from "@/src/pages/exporter/Task/utils/renderTaskStatusBadge";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import styled from "styled-components";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { Accordion as AccordionRoot } from "@radix-ui/react-accordion";
import SharedTaskAccordionItem from "../SharedTaskAccordionItem";
import useAlert from "@/src/hooks/useAlert";
import {
  ContainerShareInfoListDto,
  TaskBookingContractShareInfoV2Dto,
} from "@/src/store/apis/tasks/taskShare/interface";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import {
  useTaskContainerFullBatchDownloadMutation,
  useTaskShareKeyWithContainerFullBatchDownloadMutation,
} from "@/src/store/apis/containerImage";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "@/src/components/atom/Loader";
import {
  ContainerListViewDto,
  TaskDetailViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import { TaskDecrypt } from "../../SharedTask";
import { useTranslation } from "react-i18next";

interface TaskItemProps {
  taskData?:
    | TaskBookingContractShareInfoV2Dto["taskDetailShareInfo"]
    | TaskDetailViewDto;
  containerData?: ContainerShareInfoListDto[] | ContainerListViewDto[];
  isError?: boolean;
  isContainerDataError?: boolean;
  taskShareKey: string;
  extraRemark?: string;
}

function TaskItem({
  taskData,
  containerData,
  isError,
  isContainerDataError,
  taskShareKey,
  extraRemark,
}: TaskItemProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const location = useLocation();

  const isPreview = searchParams.get("preview") === "true";
  const previewLoadingId = Number(searchParams.get("taskId"));

  const sharedLoadingEncryptKey = location.search.slice(1);
  const loadingDecryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedLoadingEncryptKey)
  ) as TaskDecrypt;

  const [loadingContainerImagesDownload, { isDownloadLoading }] =
    useTaskShareKeyWithContainerFullBatchDownloadMutation({
      selectFromResult: ({ isLoading }) => {
        return { isDownloadLoading: isLoading };
      },
    });
  const [
    previewTypeLoadingContainerImagesDownload,
    { isPreviewTypeDownloadLoading },
  ] = useTaskContainerFullBatchDownloadMutation({
    selectFromResult: ({ isLoading }) => {
      return { isPreviewTypeDownloadLoading: isLoading };
    },
  });

  const containerDownloadData = {
    isLoading: isPreview ? isPreviewTypeDownloadLoading : isDownloadLoading,
  };

  const handleImagesDownload = async () => {
    try {
      if (isPreview) {
        await previewTypeLoadingContainerImagesDownload({
          id: previewLoadingId,
        }).unwrap();

        return;
      }

      await loadingContainerImagesDownload({
        taskId: Number(loadingDecryptToObject.taskId),
        taskShareKey,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderLoadingContent = () => {
    if (isError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <>
        <PageTitle as="h1" color="gray1">
          Booking No. {taskData?.bookingNo ?? "-"}
        </PageTitle>
        <HeaderBadgeContainer>
          {taskData?.taskStatus &&
            renderLoadingStatusBadge({
              type: taskData?.taskStatus,
              size: "L",
              t,
            })}
        </HeaderBadgeContainer>

        <StyledSectionCardGrid>
          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                Importer
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {taskData?.buyerName ?? "-"}
              </BreakWordTypo>
            }
          />
          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                Item
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {taskData?.exporterItem ?? "-"}
              </BreakWordTypo>
            }
          />
          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                No. of Container
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {taskData?.numberOfTaskContainer && taskData?.containerType
                  ? `${taskData?.numberOfTaskContainer}X${taskData?.containerType}`
                  : "-"}
              </BreakWordTypo>
            }
          />

          <GrayLine />

          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                Total Net Weight
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {taskData?.totalNetWeight ?? "-"}
              </BreakWordTypo>
            }
          />

          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                Total Unit Q’ty
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">
                {taskData?.totalUnitQuantity ?? "-"}
              </BreakWordTypo>
            }
          />

          <StyledSectionCardRow
            label={
              <Typo color="gray6" typoType="b9m">
                Estimated Weight
              </Typo>
            }
            direction="vertical"
            value={
              <BreakWordTypo typoType="h4">{`${
                taskData?.taskEstimatedWeight && taskData?.estimatedWeightUnit
                  ? `${Number(taskData?.taskEstimatedWeight).toLocaleString(
                      "ko-KR"
                    )} ${taskData?.estimatedWeightUnit}`
                  : "-"
              } `}</BreakWordTypo>
            }
          />
        </StyledSectionCardGrid>
      </>
    );
  };

  const renderLoadingContainer = () => {
    if (isContainerDataError) {
      return (
        <LoaderWithErrorContainer>
          {renderNoRowsComponent()}
        </LoaderWithErrorContainer>
      );
    }

    return (
      <SectionCard cardTitle={t("task:detail.cards.containerLoadingResult")}>
        <TitleContainer>
          <Typo typoType="h6" color="gray5">
            {t("task:detail.cards.containerList")}
          </Typo>
          <TitleButtonContainer>
            <StyledButton
              buttonSize={32}
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={handleImagesDownload}
              disabled={containerDownloadData.isLoading}
            >
              {containerDownloadData.isLoading ? (
                <Figure>
                  <Loader />
                </Figure>
              ) : (
                <>
                  <DownloadIcon />
                  {t("task:detail.cards.download")}
                </>
              )}
            </StyledButton>
          </TitleButtonContainer>
        </TitleContainer>

        <SeaVantageUrlDescription>
          <Icon iconSrc={InfoGray6Svg} iconSize={16} />
          <Typo typoType="b9r" color="gray6">
            {t(
              "shareEmail:sharedTask.containerTaskCard.seaVantageUrlDescription"
            )}
          </Typo>
        </SeaVantageUrlDescription>

        <AccordionRoot type="multiple">
          {containerData?.map((item, idx) => {
            return (
              <SharedTaskAccordionItem
                containerNo={item.containerNo}
                sealNo={item.sealNo}
                containerStatus={item.containerStatus}
                extraRemark={extraRemark}
                containerId={item.id}
                key={item.id}
                index={idx}
              />
            );
          })}
        </AccordionRoot>
      </SectionCard>
    );
  };

  return (
    <>
      {renderLoadingContent()}
      {renderLoadingContainer()}
    </>
  );
}

export default TaskItem;

const PageTitle = styled(Typo)`
  display: block;
  padding: 16px 0;
  ${typo.h1}
`;

const HeaderBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  grid-column: 4 span;

  p {
    max-width: 100%;
  }
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SeaVantageUrlDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const LoaderWithErrorContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledSectionCardGrid = styled(SectionCardGrid)`
  margin-bottom: 16px;
`;

const DownloadIcon = styled(DownloadSvg)``;

const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  text-align: center;
`;
