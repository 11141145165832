import Badge from "@/src/components/atom/Badge";
import { ContractStatusType } from "@/src/store/apis/contracts/interface";
import { TFunction } from "i18next";

const renderContractStatusBadge = (param: {
  t: TFunction;
  type: ContractStatusType | null;
  size?: "S" | "L";
}) => {
  const { t, type, size = "S" } = param;

  switch (type) {
    case "PROCESSING":
      return (
        <Badge
          text={t("contract:importer.list.badge.processing")}
          color="green1"
          badgeColor="green6"
          badgeSize={size}
        />
      );

    case "COMPLETE":
      return (
        <Badge
          text={t("contract:importer.list.badge.complete")}
          color="gray4"
          badgeColor="gray10"
          badgeSize={size}
        />
      );

    case "NOT_LINKED":
      return (
        <Badge
          text={t("contract:importer.list.badge.notLinked")}
          color="red2"
          badgeColor="red8"
          badgeSize={size}
        />
      );
  }
};

export default renderContractStatusBadge;
