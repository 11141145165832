import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { ColDef, IRowNode } from "ag-grid-community";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import { Button } from "@/src/components/atom/Button";
import { useAppSelector } from "@/src/store";
import Typo from "@/src/components/atom/Typo";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useLazyGetFilteredBookingListAssociatedWithTaskQuery } from "@/src/store/apis/tasks/taskRegister";
import { TaskBookingListViewDto } from "@/src/store/apis/tasks/taskRegister/interface";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import Icon from "@/src/components/atom/Icon";
import InfoSvg from "@/src/assets/icons/icon-info-gray6.svg";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

interface LoadBookingDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (detail?: TaskBookingListViewDto) => void;
  defaultSelectedItemId?: number;
  maxContainer?: number;
}

interface BookingListGridRowDataType {
  id: number;
  bookingNo: string;
  etdAt: string;
  initialEtdAt: string;
  buyerNameCode: string;
  item: string;
  numberOfContainer: string;
  remainingNumberOfContainer: string;
  pureRemainingCount: number;
  isDisable: boolean;
  isChecked: boolean;
}

const LoadBookingDialog = ({
  open,
  onClose,
  onSelect,
  defaultSelectedItemId,
  maxContainer,
}: LoadBookingDialogProps) => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const [selectedId, setSelectedId] = useState<number | null>(
    defaultSelectedItemId || null
  );
  const alert = useAlert();
  const [buyerCode, setBuyerCode] = useState<string>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const ref = useRef<AgGridReact>(null);
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => (
        <>
          {radioRenderer(
            params,
            setSelectedId,
            maxContainer
              ? maxContainer > params?.data?.pureRemainingCount
              : false
          )}
        </>
      ),
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...column,
  ]);

  useAgGridHeaderRefresh({
    gridRef: ref.current,
    isReady,
    headerSet: [
      {
        columnKey: "remainingNumberOfContainer",
        langKey: "task:exporter.add.table.remainingContainer",
      },
    ],
  });

  const { buyerList, isBuyerListError, isBuyerListFetching } =
    useGetBuyersQuery(
      {},
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          return {
            buyerList: currentData?.data ?? [],
            isBuyerListError: isError,
            isBuyerListFetching: isFetching,
          };
        },
        refetchOnMountOrArgChange: true,
      }
    );
  const [getBookingList, { bookingList, count, isFetching }] =
    useLazyGetFilteredBookingListAssociatedWithTaskQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => ({
        bookingList:
          !isError && !isUndefined(currentData) ? currentData.rows : [],
        isFetching,
        count: currentData?.count || 0,
      }),
    });
  const buyersListToOptions = isBuyerListError
    ? []
    : buyerList.reduce<{ label: string; value: string }[]>(
        (acc, val) => {
          const resource = {
            label: val?.buyerNameCode || "",
            value:
              `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
          };
          return [...acc, resource];
        },
        [{ label: lang === "en" ? "All" : "전체", value: "all" }]
      );

  const handleSelect = () => {
    onSelect?.(bookingList.find(({ id }) => id === selectedId));
    onClose();
    alert.showAlert({
      type: "success",
      message: t("task:add.alert.selectBookingSuccess"),
    });
  };

  const bookingListRowData = bookingList.reduce<BookingListGridRowDataType[]>(
    (acc, val) => {
      const resource = {
        id: val.id,
        bookingNo: val.bookingNo,
        etdAt: val.etdAt,
        initialEtdAt: val.initialEtdAt,
        buyerNameCode: val.buyerNameCode || "-",
        item: val.itemCode || "-",
        numberOfContainer: `${val.numberOfContainer.toLocaleString("ko-KR")}ea`,
        remainingNumberOfContainer: `${val.remainingNumberOfContainer.toLocaleString(
          "ko-KR"
        )}ea`,
        pureRemainingCount: val.remainingNumberOfContainer,
        isDisable: maxContainer
          ? val.remainingNumberOfContainer < maxContainer
          : false,
        isChecked: val.id === selectedId,
      };
      return [...acc, resource];
    },
    []
  );

  useEffect(() => {
    if (isFetching && ref.current) {
      ref.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getBookingList({
        page: 1,
        pageSize: 10,
      });
    })();
  }, [getBookingList]);

  return (
    <>
      <Dialog
        title={t("task:add.dialog.selectBooking")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <ButtonContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("task:add.common.exit")}
            </Button>
            <Button disabled={!selectedId} onClick={handleSelect}>
              {t("task:add.buttonLabel.select")}
            </Button>
          </ButtonContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">{"Importer Code"}</Typo>
            <Select
              options={buyersListToOptions}
              showSearch
              defaultValue={"all"}
              suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
              disabled={isBuyerListFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentElement;
              }}
              onChange={async (value, option: any) => {
                setBuyerCode(value === "all" ? undefined : option.label);
                setPagination({ page: 1, pageSize: pagination.pageSize });
                await getBookingList({
                  buyerNameCode: value === "all" ? undefined : option.label,
                  page: 1,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </InputContainer>

          <FlexAlignCenter>
            <Icon className="info-svg" iconSrc={InfoSvg} iconSize={16} />
            <Typo typoType="b9r" color="gray6">
              {t("task:add.dialog.bookingInfo")}
            </Typo>
          </FlexAlignCenter>

          <Table
            ref={ref}
            onGridReady={() => setIsReady(true)}
            columnDefs={columnDefs}
            rowData={bookingListRowData}
            totalPage={count}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getBookingList({
                page,
                pageSize,
                buyerNameCode: buyerCode,
              });
            }}
            onRowClicked={(params) => {
              if (
                maxContainer &&
                maxContainer > params?.data?.pureRemainingCount
              ) {
                return;
              }
              setSelectedId(params.data.id);
            }}
          />
        </Content>
      </Dialog>
    </>
  );
};

export default LoadBookingDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    outline: 1px solid ${colorSet.gray9};
    border-radius: 8px;
  }
`;

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
  cellClass: (params: any) => {
    const isDisabled = params.data.isDisable;
    return isDisabled ? "diabled-cell" : undefined;
  },
};

const column: ColDef[] = [
  {
    headerName: "Booking No.",
    field: "bookingNo",
    width: 140,
    ...commonColumn,
  },
  {
    headerName: "ETD",
    field: "etdAt",
    width: 140,
    cellRenderer: (params: any) => {
      return (
        <>
          {dayjs(params.data.etdAt).format("YYYY-MM-DD")}{" "}
          <EtdCompareCount
            typoType="b9m"
            initDate={params.data.initialEtdAt}
            currentDate={params.data.etdAt}
            color={params.data.isDisable ? "gray7" : undefined}
          />
        </>
      );
    },
    flex: 1,
    ...commonColumn,
  },
  {
    headerName: "Importer Code",
    field: "buyerNameCode",
    width: 160,
    ...commonColumn,
  },
  {
    headerName: "Item Code",
    field: "item",
    width: 110,
    ...commonColumn,
  },
  {
    headerName: "No. of Container",
    field: "numberOfContainer",
    width: 110,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
  {
    field: "remainingNumberOfContainer",
    width: 160,
    ...commonColumn,
    cellStyle: { textAlign: "right" },
  },
];

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  .info-svg {
    flex-shrink: 0;
  }
`;

const radioRenderer = (
  params: IRowNode,
  onChange: (id: number) => void,
  disabled: boolean
) => {
  const handleRadioChange = () => {
    onChange(params.data.id);
  };

  const checked = params.data.isChecked;

  return (
    <StyledRadio>
      <input
        type="radio"
        className="custom-ag-radio"
        checked={checked}
        onChange={handleRadioChange}
        disabled={disabled}
      />
      <span className="custom-radio-mark" />
    </StyledRadio>
  );
};
