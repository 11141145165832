import { ReactNode, useState } from "react";
import { styled } from "styled-components";
import { ReactImageGalleryItem } from "react-image-gallery";
import Dialog from "@/src/components/atom/Dialog";
import Typo from "@/src/components/atom/Typo";
import PhotoCollection, {
  PhotoCollectionProps,
} from "@/src/components/molecule/PhotoCollection";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Icon from "@/src/components/atom/Icon";
import { IconButton } from "@/src/components/atom/Button";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import {
  ContainerDetailViewDto,
  ContainerListViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import dayjs from "dayjs";
import { ImageProps } from "antd";
import { useTranslation } from "react-i18next";
import LoadingPhotoPreviewDialog from "../LoadingPhotoPreviewDialog";
import { MediaCoordinateRangeType } from "@/src/store/apis/tasks/taskRegister/interface";

export interface ContainerMediaData {
  latitude?: string;
  longitude?: string;
  mediaUrl?: string;
  createAt?: string;
  originalFileName?: string;
  zoomLevel?: number;
}

interface ViewPhotoCollectionDialogProps
  extends Pick<PhotoCollectionProps, "imgProps" | "thumbnailProps"> {
  items: ReactImageGalleryItem[];
  open: boolean;
  onClose: () => void;
  startIndex?: number;
  selectLoading?: ContainerListViewDto | ContainerDetailViewDto | null;
  mediaDataList: ContainerMediaData[];
  antdImageProps?: ImageProps;
  photoDetailRightAccessory?: ReactNode;
  onSelectImageChange?: (media: ContainerMediaData | null) => void;
  mediaCoordinateRange?: MediaCoordinateRangeType;
  zoomLevel?: number;
}

export const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const ViewPhotoCollectionDialog = ({
  items,
  open,
  onClose,
  startIndex,
  selectLoading,
  mediaDataList,
  imgProps,
  thumbnailProps,
  antdImageProps,
  photoDetailRightAccessory,
  onSelectImageChange,
  mediaCoordinateRange,
  zoomLevel,
}: ViewPhotoCollectionDialogProps) => {
  const { t } = useTranslation();

  const [isPhotoDetailInfoDialogOpen, setIsPhotoDetailInfoDialogOpen] =
    useState(false);
  const [selectImage, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  const handlePhotoClick = (imageUrl: string) => {
    const findImageInfo = mediaDataList.find(
      (item) => item.mediaUrl === imageUrl
    );

    if (!findImageInfo) {
      return;
    }

    setSelectImage(findImageInfo);
    onSelectImageChange?.(findImageInfo);
    setIsPhotoDetailInfoDialogOpen(true);
  };

  const renderPhotoDialog = () => {
    if (isPhotoDetailInfoDialogOpen) {
      return (
        <LoadingPhotoPreviewDialog
          open
          onOpenChange={() => {
            setIsPhotoDetailInfoDialogOpen(false);
            setSelectImage(null);
            onSelectImageChange?.(null);
          }}
          selectImage={selectImage}
          antdImageProps={antdImageProps}
          photoDetailRightAccessory={photoDetailRightAccessory}
          mediaCoordinateRange={mediaCoordinateRange}
          zoomLevel={zoomLevel || selectImage?.zoomLevel}
        />
      );
    }
  };

  return (
    <>
      <Dialog
        title={t("task:detail.dialog.viewPhotoCollection")}
        open={open}
        onOpenChange={() => onClose?.()}
        width={1150}
      >
        <DialogContentSection>
          <ImageGalleryContainer>
            <PhotoCollection
              items={items}
              startIndex={startIndex}
              onImageClick={(imageUrl) => handlePhotoClick(imageUrl)}
              imgProps={imgProps}
              thumbnailProps={thumbnailProps}
            />
          </ImageGalleryContainer>

          <TaskDetailSection>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.loadingDetails")}
              </Typo>
              <DetailContainer>
                <SectionCardRow
                  label="Container No."
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.containerNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
                <SectionCardRow
                  label="Seal No."
                  value={
                    <StyledRowTypo typoType="b7m" color="blue2">
                      {selectLoading?.sealNo ?? "-"}
                    </StyledRowTypo>
                  }
                />
              </DetailContainer>
            </div>
            <div>
              <Typo
                typoType="b9m"
                color="gray7"
                as="p"
                className="detail-title"
              >
                {t("task:detail.dialog.additionalInformation")}
              </Typo>

              <DetailContainer>
                <SectionCardRow
                  label={t("task:detail.cards.weighingTicket")}
                  value={
                    <FileInfoContainer>
                      <div>
                        <figure>
                          <Typo typoType="b7r" color="gray5">
                            {selectLoading?.weighingTicketMedias &&
                            selectLoading?.weighingTicketMedias.length >= 1
                              ? selectLoading?.weighingTicketMedias[0]
                                  ?.originalFileName
                              : "-"}
                          </Typo>
                        </figure>
                        {selectLoading?.weighingTicketMedias &&
                          selectLoading?.weighingTicketMedias.length >= 1 &&
                          selectLoading?.weighingTicketMedias[0]?.mediaUrl && (
                            <IconButton
                              buttonSize={24}
                              buttonColor="blue"
                              buttonGrade="secondary"
                              disabled={false}
                            >
                              <a
                                href={
                                  selectLoading?.weighingTicketMedias[0]
                                    ?.mediaUrl
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon iconSrc={PreviewSvg} iconSize={16} />
                              </a>
                            </IconButton>
                          )}
                      </div>

                      {selectLoading?.weighingTicketMedias &&
                        selectLoading?.weighingTicketMedias.length >= 2 && (
                          <div>
                            <figure>
                              <Typo typoType="b7r" color="gray5">
                                {
                                  selectLoading?.weighingTicketMedias[1]
                                    ?.originalFileName
                                }
                              </Typo>
                            </figure>

                            {selectLoading?.weighingTicketMedias[1]
                              ?.mediaUrl && (
                              <IconButton
                                buttonSize={24}
                                buttonColor="blue"
                                buttonGrade="secondary"
                                disabled={false}
                              >
                                <a
                                  href={
                                    selectLoading?.weighingTicketMedias[1]
                                      ?.mediaUrl
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Icon iconSrc={PreviewSvg} iconSize={16} />
                                </a>
                              </IconButton>
                            )}
                          </div>
                        )}
                    </FileInfoContainer>
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.firstWeight")}
                  value={
                    selectLoading?.firstWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.firstWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.secondWeight")}
                  value={
                    selectLoading?.secondWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.secondWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label="Gross Weight"
                  value={
                    selectLoading?.grossWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.grossWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label="Net Weight"
                  value={
                    selectLoading?.netWeight && selectLoading.weightUnit
                      ? `${Number(selectLoading?.netWeight).toLocaleString(
                          "ko-KR"
                        )} ${selectLoading?.weightUnit}`
                      : "-"
                  }
                />
                <SectionCardRow
                  label="Unit Q’ty"
                  value={
                    !selectLoading?.unitQuantity &&
                    !selectLoading?.unitQuantityUnit ? (
                      "-"
                    ) : (
                      <>
                        {selectLoading?.unitQuantity
                          ? selectLoading.unitQuantity.toLocaleString("ko-KR")
                          : ""}
                        {selectLoading?.unitQuantityUnit ?? ""}
                      </>
                    )
                  }
                />
                <SectionCardRow
                  label={t("task:detail.cards.photoSubmittedDate")}
                  value={
                    selectLoading?.photoSubmittedDateAt
                      ? dayjs(selectLoading?.photoSubmittedDateAt).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : "-"
                  }
                />
              </DetailContainer>
            </div>
          </TaskDetailSection>
        </DialogContentSection>
      </Dialog>
      {renderPhotoDialog()}
    </>
  );
};

export default ViewPhotoCollectionDialog;

const DialogContentSection = styled.section`
  display: flex;
  gap: 24px;
`;

const ImageGalleryContainer = styled.div`
  width: 718px;
  flex-shrink: 0;
`;

const TaskDetailSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .detail-title {
    padding-bottom: 8px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    gap: 8px;

    figure {
      max-width: 155px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  a {
    line-height: 0px;
  }
`;

const StyledRowTypo = styled(Typo)`
  word-break: break-word;
`;
