import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SectionCard from "@/src/components/molecule/SectionCard";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { isUndefined } from "@/src/utils/is";
import styled from "styled-components";
import Loader from "@/src/components/atom/Loader";
import UpSvg from "@/src/assets/icons/icon-arrow-up.svg";
import DownSvg from "@/src/assets/icons/icon-arrow-down.svg";
import TagUpSvg from "@/src/assets/icons/icon-arrow-up-blue2.svg";
import TagDownSvg from "@/src/assets/icons/icon-arrow-down-blue2.svg";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import dayjs from "dayjs";
import colorSet from "@/src/styles/color";
import { Button } from "@/src/components/atom/Button";
import ContainerHistoryDialog from "../dialog/ContainerHistoryDialog";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import { MAIN_CONTENT_MAIN_ID } from "@/src/components/template/Layout/exporter/ExporterMainContentLayout";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";

const CombineSplitHistoryCard = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [historyContainerId, setHistoryContainerId] = useState<number | null>(
    null
  );
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] =
    useState<boolean>(false);

  const { hisotryList, isHistoryFetching } =
    taskDetailApi.endpoints.getContainerTaskChangeHistoryList.useQueryState(
      {
        taskId: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;

          return {
            hisotryList: !isUnstable ? currentData.rows : [],
            isHistoryFetching: isFetching,
          };
        },
      }
    );

  const handleHistoryButtonClick = (id: number) => {
    setIsHistoryDialogOpen(true);
    setHistoryContainerId(id);
  };

  const renderHistoryDialog = () => {
    if (!isHistoryDialogOpen || !historyContainerId) return null;

    return (
      <ContainerHistoryDialog
        onClose={() => {
          setIsHistoryDialogOpen(false);
          setHistoryContainerId(null);
        }}
        containerId={historyContainerId}
      />
    );
  };

  return (
    <>
      <SectionCard cardTitle={t("task:detail.cards.combineSplitHistory")}>
        {isHistoryFetching ? (
          <LoaderSection>
            <Loader />
          </LoaderSection>
        ) : (
          <ContentSection>
            {hisotryList.length === 0 ? (
              <NoRowsWrapper>{renderNoRowsComponent()}</NoRowsWrapper>
            ) : (
              <>
                {hisotryList.map(
                  ({
                    containerNo,
                    createdAt,
                    inOutType,
                    containerId,
                    inOutDetailTaskNo,
                    inOutDetailTaskId,
                  }) => {
                    return (
                      <HistoryRow>
                        <Left>
                          <InOutDiv>
                            <Icon
                              iconSrc={inOutType === "IN" ? DownSvg : UpSvg}
                              iconSize={16}
                            />
                            <Typo
                              typoType="b9r"
                              color={inOutType === "IN" ? "green1" : "red2"}
                            >
                              {inOutType}
                            </Typo>
                          </InOutDiv>
                          <Typo typoType="b7r" color="gray2">
                            Container No.
                            <Typo typoType="b7m" color="gray2">
                              {containerNo}
                            </Typo>
                          </Typo>
                        </Left>

                        <Right>
                          <CurrentTag
                            onClick={() => {
                              navigate(
                                `${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${inOutDetailTaskId}`
                              );
                              document
                                .getElementById(MAIN_CONTENT_MAIN_ID)
                                ?.scrollTo(0, 0); // Scroll 유지 풀기
                            }}
                          >
                            <Icon
                              iconSrc={
                                inOutType === "IN" ? TagDownSvg : TagUpSvg
                              }
                              iconSize={12}
                            />

                            <Typo typoType="b11r" color="systemBlue2">
                              {inOutType === "IN" ? (
                                <>From Loading No.{inOutDetailTaskNo}</>
                              ) : (
                                <>To Loading No.{inOutDetailTaskNo}</>
                              )}
                            </Typo>
                          </CurrentTag>

                          <Typo typoType="b9r" color="gray7">
                            {dayjs(createdAt).format("YY-MM-DD, HH:mm")}
                          </Typo>

                          <Button
                            buttonGrade="tertiary"
                            buttonColor="black"
                            buttonSize={24}
                            onClick={() =>
                              handleHistoryButtonClick(containerId)
                            }
                          >
                            History
                          </Button>
                        </Right>
                      </HistoryRow>
                    );
                  }
                )}
              </>
            )}
          </ContentSection>
        )}
      </SectionCard>
      {renderHistoryDialog()}
    </>
  );
};

export default CombineSplitHistoryCard;

const HistoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LoaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const ContentSection = styled.section`
  max-height: 172px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 4px 4px 4px 0; // 위 아래 오른쪽이 살짝 잘려보여 처리
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;
const InOutDiv = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  flex-shrink: 0;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CurrentTag = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 8px;
  border: 1px solid ${colorSet.systemBlue4};
  border-radius: 2px;
  cursor: pointer;
`;

const NoRowsWrapper = styled.div`
  padding: 20px 0;
`;
