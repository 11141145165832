import React from "react";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { styled } from "styled-components";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import { useNavigate, useParams } from "react-router-dom";
import IMPORTER_PATH from "@/src/routes/importer/path";
import { contractDetailApi } from "@/src/store/apis/contracts/contractDetail";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { isUndefined } from "@/src/utils/is";

function BuyerCard() {
  const params = useParams();
  const naviate = useNavigate();

  const {
    buyerId,
    buyerName,
    shippingTerms,
    shippingTermsRemark,
    buyerEmployeeName,
    exporterItem,
    quantityAndUnit,
    amount,
    unitPriceAndUnit,
  } = contractDetailApi.endpoints.getImporterContractDetail.useQueryState(
    {
      id: Number(params.id),
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isUndefined(currentData) || isError || isFetching;

        return {
          buyerId: !isUnstable ? currentData.importerBuyerId : "",
          buyerName: !isUnstable ? currentData.buyerName : "-",
          shippingTerms: !isUnstable ? currentData.shippingTerms ?? "-" : "-",
          shippingTermsRemark: !isUnstable
            ? currentData.shippingTermsRemark ?? ""
            : "-",
          buyerEmployeeName: !isUnstable
            ? currentData?.buyerEmployeeName ?? "-"
            : "-",
          exporterItem: !isUnstable ? currentData?.importerItem ?? "-" : "-",
          exporterItemCode: !isUnstable
            ? currentData?.importerItemCode ?? "-"
            : "-",
          unitPrice: currentData?.unitPrice,
          unitPriceUnit: currentData?.unitPriceUnit,
          quantityAndUnit:
            !isUnstable && currentData.quantity && currentData.quantityUnit
              ? `${Number(currentData.quantity).toLocaleString("ko-KR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${currentData.quantityUnit}`
              : "-",
          unitPriceAndUnit:
            !isUnstable && currentData.unitPrice && currentData.unitPriceUnit
              ? `${Number(currentData.unitPrice).toLocaleString("ko-KR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${currentData.unitPriceUnit}`
              : "-",
          amount:
            !isUnstable &&
            currentData.quantity &&
            currentData.unitPrice &&
            currentData.unitPriceUnit
              ? `${parseFloat(
                  (
                    Number(currentData.quantity) * Number(currentData.unitPrice)
                  ).toFixed(2)
                ).toLocaleString("ko-KR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${currentData.unitPriceUnit}`
              : "-",
        };
      },
    }
  );

  return (
    <SectionCardGrid>
      <StyledSectionCardColumnSix
        label={
          <Typo color="gray6" typoType="b9m">
            Exporter
          </Typo>
        }
        direction="vertical"
        value={
          buyerId ? (
            <BuyerText
              color="systemBlue2"
              typoType="h4"
              onClick={() =>
                naviate(`${IMPORTER_PATH.CLIENT_MANAGEMENT_DETAIL}/${buyerId}`)
              }
            >
              {buyerName}
            </BuyerText>
          ) : (
            <BreakWordTypo typoType="h4">{buyerName}</BreakWordTypo>
          )
        }
      />
      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Shipping Terms
          </Typo>
        }
        direction="vertical"
        value={
          <ShippingTermsContainer>
            <Typo typoType="h4">{shippingTerms}</Typo>
            {shippingTermsRemark && (
              <BreakWordTypo typoType="h4">{shippingTermsRemark}</BreakWordTypo>
            )}
          </ShippingTermsContainer>
        }
      />
      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Exporter Contact
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{buyerEmployeeName}</BreakWordTypo>}
      />

      <GrayLine />

      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Item
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{exporterItem}</BreakWordTypo>}
      />

      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Qty
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{quantityAndUnit}</BreakWordTypo>}
      />
      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Unit Price
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{unitPriceAndUnit}</BreakWordTypo>}
      />
      <StyledSectionCardThree
        label={
          <Typo color="gray6" typoType="b9m">
            Amount
          </Typo>
        }
        direction="vertical"
        value={<BreakWordTypo typoType="h4">{amount}</BreakWordTypo>}
      />
    </SectionCardGrid>
  );
}

export default BuyerCard;

const StyledSectionCardColumnSix = styled(SectionCardRow)`
  grid-column: 6 span;
`;

const StyledSectionCardThree = styled(SectionCardRow)`
  grid-column: 3 span;
`;

const GrayLine = styled.div`
  grid-column: 12 span;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const BuyerText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

const ShippingTermsContainer = styled.div`
  display: flex;
  gap: 4px;
`;
