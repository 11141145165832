import { ExporterUserDto } from "../../auth/interface";
import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  ExporterUserListViewDto,
  ModifyExporterUserDto,
  ModifyExporterUserMainFieldByIdListDto,
  StaffManagementListRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["staffs"],
});

const staffApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getStaffs: build.query<
      Rows<ExporterUserListViewDto>,
      StaffManagementListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/exporter-users",
        params,
      }),
      providesTags: ["staffs"],
    }),

    editStaff: build.mutation<
      Row<ExporterUserDto>,
      ModifyExporterUserDto & { id: number }
    >({
      query: ({ id, ...body }) => {
        return {
          method: "PUT",
          url: `/exporters/this/exporter-users/${id}`,
          body: { ...body },
        };
      },
      invalidatesTags: ["staffs"],
    }),

    /**
     * 수출회사 직원의 담당 분야를 수정하는 API 입니다.
     * 수정은 CORPORATE_MANAGER, MIDDLE_MANAGER만 가능합니다.
     */
    editBulkStaffType: build.mutation<
      Row<ExporterUserDto>,
      ModifyExporterUserMainFieldByIdListDto
    >({
      query: (body) => {
        return {
          method: "PUT",
          url: `/exporters/this/exporter-users`,
          body,
        };
      },
      invalidatesTags: ["staffs"],
    }),
  }),
});

export const {
  useLazyGetStaffsQuery,
  useGetStaffsQuery,
  useEditStaffMutation,
  useEditBulkStaffTypeMutation,
} = staffApi;
export { staffApi };
