import React, { CSSProperties, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Accordion from "@radix-ui/react-accordion";
import styled, { css, keyframes } from "styled-components";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { useLazyGetImporterBookingListQuery } from "@/src/store/apis/bookings/bookingList";
import { useLazyGetImporterTaskListQuery } from "@/src/store/apis/tasks/taskList";
import { useLazyGetImporterShipmentListQuery } from "@/src/store/apis/shipments/shipmentList";
import { SignatureStatusType } from "@/src/store/apis/contracts/interface";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import NotShareSvg from "@/src/assets/icons/illust-not-share.svg";
import { ReactComponent as CornerArrowSvg } from "@/src/assets/icons/icon-corner-arrow.svg";
import BookingInProcessingTab from "./BookingInProcessingTab";
import LoadingInProcessingTab from "./LoadingInProcessingTab";
import ShipmentInProcessingTab from "./ShipmentInProcessingTab";
import { useTranslation } from "react-i18next";

type ListType = "booking" | "loading" | "shipment";

const EMPTY_ARRAY: [] = [];

interface ProcessingTabProps {
  tab: ListType[];
  onTabChange: (tab: ListType) => void;
  hasLinkedBefore: boolean;
  signatureStatus: SignatureStatusType;
  selectedProcessingTab?: ListType;
  selectedProcessingItem?: number;
  onSelectedItemChange: (tab: ListType, item: number) => void;
}

const ProcessingTab = ({
  tab,
  onTabChange,
  hasLinkedBefore,
  signatureStatus,
  selectedProcessingTab,
  selectedProcessingItem,
  onSelectedItemChange,
}: ProcessingTabProps) => {
  const { t } = useTranslation();
  const params = useParams();

  const [getBookingList, { bookingCount, bookingList }] =
    useLazyGetImporterBookingListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          bookingList: isError ? EMPTY_ARRAY : currentData?.rows,
          bookingCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });
  const [getLoadingList, { loadingList, loadingCount }] =
    useLazyGetImporterTaskListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          loadingList: isError ? EMPTY_ARRAY : currentData?.rows,
          loadingCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });
  const [getShipmentList, { shipmentCount, shipmentList }] =
    useLazyGetImporterShipmentListQuery({
      selectFromResult: ({ currentData, isError }) => {
        return {
          shipmentList: isError ? EMPTY_ARRAY : currentData?.rows,
          shipmentCount: isError ? 0 : currentData?.count || 0,
        };
      },
    });

  const refetchList = (listType: ListType) => {
    switch (listType) {
      case "booking":
        return async () => {
          await getBookingList({
            contractId: Number(params.id),
          });
        };
      case "loading":
        return async () => {
          await getLoadingList({
            contractId: Number(params.id),
            isCancelTaskRequestExist: false,
          });
        };
      case "shipment":
        return async () => {
          await getShipmentList({
            contractId: Number(params.id),
          });
        };
    }
  };

  const renderContent = () => {
    switch (selectedProcessingTab) {
      case "booking":
        return (
          <BookingInProcessingTab
            id={selectedProcessingItem || 0}
            refetchList={refetchList(selectedProcessingTab)}
          />
        );
      case "loading":
        return (
          <LoadingInProcessingTab
            id={selectedProcessingItem || 0}
            refetchList={refetchList(selectedProcessingTab)}
          />
        );
      case "shipment":
        return (
          <ShipmentInProcessingTab
            id={selectedProcessingItem || 0}
            blNo={
              shipmentList?.find(({ id }) => id === selectedProcessingItem)
                ?.blNo
            }
            refetchList={refetchList(selectedProcessingTab)}
          />
        );
    }
  };

  useEffect(() => {
    (async () => {
      await getBookingList({
        contractId: Number(params.id),
      });
      await getLoadingList({
        contractId: Number(params.id),
        isCancelTaskRequestExist: false,
      });
      await getShipmentList({
        contractId: Number(params.id),
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexRow flexGap={16} alignItems={"flex-start"}>
      <SideTab>
        <Accordion.Root type="multiple" value={tab}>
          <Item value="booking" className="AccordionItem">
            <Trigger onClick={() => onTabChange("booking")}>
              <p>Booking ({bookingCount})</p>

              <Icon iconSrc={ArrowDownSvg} iconSize={24} className="arrow" />
            </Trigger>
            <Content>
              {bookingList?.map(({ bookingNo, id, isRead }) => {
                const isNew = !(isRead ?? true);

                return (
                  <StyledLocalButton
                    key={`processing-booking-${id}`}
                    data-selected={
                      selectedProcessingTab === "booking" &&
                      id === selectedProcessingItem
                    }
                    onClick={() => onSelectedItemChange("booking", id)}
                  >
                    <CornerArrowIcon />
                    <DomainNumber>No. {bookingNo}</DomainNumber>
                    {isNew && <RedDot />}
                  </StyledLocalButton>
                );
              })}
            </Content>
          </Item>
          <Item value="loading" className="AccordionItem">
            <Trigger onClick={() => onTabChange("loading")}>
              <p>Loading ({loadingCount})</p>

              <Icon iconSrc={ArrowDownSvg} iconSize={24} className="arrow" />
            </Trigger>
            <Content>
              {loadingList?.map(({ taskNo, id, isRead }) => {
                const isNew = !(isRead ?? true);

                return (
                  <StyledLocalButton
                    key={`processing-loading-${id}`}
                    data-selected={
                      selectedProcessingTab === "loading" &&
                      id === selectedProcessingItem
                    }
                    onClick={() => onSelectedItemChange("loading", id)}
                  >
                    <CornerArrowIcon />
                    <DomainNumber>No. {taskNo}</DomainNumber>
                    {isNew && <RedDot />}
                  </StyledLocalButton>
                );
              })}
            </Content>
          </Item>
          <Item value="shipment" className="AccordionItem">
            <Trigger onClick={() => onTabChange("shipment")}>
              <p>Shipment ({shipmentCount})</p>

              <Icon iconSrc={ArrowDownSvg} iconSize={24} className="arrow" />
            </Trigger>
            <Content>
              {shipmentList?.map(({ id, invoiceNo, isRead }) => {
                const isNew = !(isRead ?? true);

                return (
                  <StyledLocalButton
                    key={`processing-shipment-${id}`}
                    data-selected={
                      selectedProcessingTab === "shipment" &&
                      id === selectedProcessingItem
                    }
                    onClick={() => onSelectedItemChange("shipment", id)}
                  >
                    <CornerArrowIcon />
                    <DomainNumber>No. {invoiceNo}</DomainNumber>
                    {isNew && <RedDot />}
                  </StyledLocalButton>
                );
              })}
            </Content>
          </Item>
        </Accordion.Root>
      </SideTab>

      {signatureStatus === "LINKED" || hasLinkedBefore ? (
        <>{renderContent()}</>
      ) : (
        <NotSharedContainer>
          <Icon iconSrc={NotShareSvg} iconSize={160} />

          <Typo typoType="b4m">
            {t("contract:importer.detail.label.thisIsBeforeTheUpdate")}
          </Typo>
        </NotSharedContainer>
      )}
    </FlexRow>
  );
};

export default ProcessingTab;

const FlexRow = styled.div<{
  flexGap?: number;
  alignItems?: CSSProperties["alignItems"];
}>`
  display: flex;
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `};
`;

const SideTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 8px;
  border-right: 1px solid ${colorSet.gray9};
  flex-shrink: 0;
  position: sticky;
  top: 0;
  overflow: auto;
`;

const StyledLocalButton = styled.button`
  cursor: pointer;
  ${typo.b8r};
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &[data-selected="true"] {
    background: ${colorSet.blue10};
    color: ${colorSet.blue4};
    ${typo.b9m};
    border: none;
  }

  &[data-selected="false"] {
    background: ${colorSet.white};
    color: ${colorSet.gray2};
    ${typo.b9m};
    border: none;
  }
`;

const RedDot = styled.span`
  display: block;
  background-color: ${colorSet.red2};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`;

const slideDown = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: var(--radix-accordion-content-height);
    }
`;

const slideUp = keyframes`
    0% {
        height: var(--radix-accordion-content-height);
    }
    100% {
        height: 0;
    }
`;

const Trigger = styled(Accordion.Trigger)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    transition: transform 0.15s linear;
  }

  &[data-state="open"] {
    .arrow {
      transform: rotate(180deg);
    }
  }
`;

const Content = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }
`;

const Item = styled(Accordion.Item)`
  overflow: hidden;
`;

const CornerArrowIcon = styled(CornerArrowSvg)<{ isSelected?: boolean }>`
  flex-shrink: 0;

  path {
    ${({ isSelected }) =>
      isSelected &&
      css`
        stroke: ${colorSet.blue4};
      `};
  }
`;

const DomainNumber = styled.span`
  flex: 1;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotSharedContainer = styled.div`
  height: 670px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
