export const bookingDefaultValue = {
  bookingNo: "",
  shippingLine: "",
  shippingLineCodeItemName: "",
  vessel: "",
  voyageNo: "",
  placeOfReceipt: "",
  placeOfReceiptCodeItemName: "",
  portOfLoading: "",
  portOfLoadingCodeItemName: "",
  portOfDischarge: "",
  portOfDischargeCodeItemName: "",
  placeOfDelivery: "",
  placeOfDeliveryCodeItemName: "",
  etdAt: "",
  etaAt: "",
  docCutOffAt: undefined,
  cargoCutOffAt: undefined,
  docCutOffAtHour: undefined,
  cargoCutOffAtHour: undefined,
  cfs: "",
  numberOfContainer: "",
  containerType: "",
  containerTypeCodeItemName: undefined,
  containerTypeDirectInput: undefined,
  estimatedWeight: undefined,
  estimatedWeightUnit: "MT",
  estimatedWeightUnitCodeItemName: "MEASUREMENT_MT",
  bookingRemark: undefined,
  memo: undefined,
  bookingFileMediaId: [],
  contractIdList: [],
};
