import { apiSlice } from "../../baseQuery";
import { PartialCommonCodeItemDto } from "../../common/interface";
import { Row, Rows } from "../../type";
import {
  ExporterItemCodeDto,
  ExporterItemCodeListViewV2Dto,
  ExporterItemListViewV2Dto,
  GenerateBulkExporterItemCodesV2Dto,
  GenerateSingleExporterItemCodeV2Dto,
  GenerateSingleExporterItemCodeV2Response,
  GetItemCodeCheckRequest,
  GetItemListRequest,
  GetItemManagementListRequest,
  GetRegistrableItemsRequest,
  MessageDto,
  ModifyExporterItemCodeV2Dto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["items", "itemOptionList"],
});

const itemManagementApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // (v2)아이템 코드 목록 조회 API
    getItemCodeList: build.query<
      Rows<ExporterItemCodeListViewV2Dto>,
      GetItemManagementListRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/exporter-item-codes",
        params,
      }),
      providesTags: ["items"],
    }),

    // (v2)아이템 목록 조회 API
    getItemList: build.query<
      Rows<ExporterItemListViewV2Dto>,
      GetItemListRequest
    >({
      query: () => ({
        url: "/v2/exporters/this/exporter-items",
      }),
      providesTags: ["itemOptionList"],
    }),

    // (v2)subCategoryCodeItemName으로 "공통 코드의 아이템"과 "직접 입력한 아이템"을 병합한 목록 조회 API
    // 아이템 코드 생성시 사용
    getRegistrableItems: build.query<
      Rows<PartialCommonCodeItemDto>,
      GetRegistrableItemsRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/registrable-items",
        params,
      }),
    }),

    // (v2)벌크로 아이템 코드 생성 API
    createBulkItemCode: build.mutation<
      void,
      GenerateBulkExporterItemCodesV2Dto
    >({
      query: (body) => {
        return {
          method: "POST",
          url: `/v2/exporters/this/bulk-exporter-item-codes`,
          body,
        };
      },
      invalidatesTags: ["items", "itemOptionList"],
    }),

    createSingleItemCode: build.mutation<
      Row<GenerateSingleExporterItemCodeV2Response>,
      GenerateSingleExporterItemCodeV2Dto
    >({
      query: (body) => {
        return {
          method: "POST",
          url: `/v2/exporters/this/exporter-item-codes`,
          body,
        };
      },
      invalidatesTags: ["items", "itemOptionList"],
    }),

    // (v2)아이템 코드 단일 수정 API
    editExporterItemCode: build.mutation<
      Row<ExporterItemCodeDto>,
      ModifyExporterItemCodeV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => {
        return {
          method: "PUT",
          url: `/v2/exporters/this/exporter-item-codes/${id}`,
          body: { ...body },
        };
      },
      invalidatesTags: ["items"],
    }),

    // (v2)아이템 코드 존재 유무 확인 API
    // 아이템 코드의 존재 유무를 확인하는 API 입니다.
    // 존재하지 않아 사용이 가능할 경우 statusCode 200
    // 이미 존재하여 사용이 불가능할 경우 statusCode 409를 반환합니다.
    getItemCodeCheck: build.query<Row<MessageDto>, GetItemCodeCheckRequest>({
      query: (params) => ({
        url: "/v2/exporters/this/exporter-item-code/item-code-check",
        params,
      }),
    }),

    getNonFilteredItemCodes: build.query<
      Rows<ExporterItemCodeListViewV2Dto>,
      GetItemManagementListRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/non-filtered-exporter-item-codes",
        params,
        providesTags: ["items"],
      }),
    }),
    getUniqueItemCodes: build.query<
      Row<{
        itemCodes: string[];
      }>,
      {}
    >({
      query: (params) => ({
        url: "/exporters/this/exporter-item-codes/unique",
        params,
        providesTags: ["uniqueItemCodes"],
      }),
    }),
  }),
});

export const {
  useGetItemCodeListQuery,
  useLazyGetItemCodeListQuery,
  useGetItemListQuery,
  useLazyGetItemListQuery,
  useGetNonFilteredItemCodesQuery,
  useLazyGetNonFilteredItemCodesQuery,

  useCreateSingleItemCodeMutation,
  useLazyGetRegistrableItemsQuery,
  useCreateBulkItemCodeMutation,
  useEditExporterItemCodeMutation,
  useLazyGetItemCodeCheckQuery,

  useGetUniqueItemCodesQuery,
} = itemManagementApi;
export { itemManagementApi as itemManegementApi };
