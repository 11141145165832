import colorSet from "@/src/styles/color";
import { CSSProperties, ReactNode } from "react";
import { styled } from "styled-components";
import Typo from "../Typo";

interface BasicCardProps {
  title: ReactNode;
  children?: ReactNode;
  className?: string;
  containerStyle?: CSSProperties;
}

const BasicCard = ({
  title,
  children,
  className,
  containerStyle,
}: BasicCardProps) => {
  return (
    <Container className={className} style={containerStyle}>
      <Typo as="h2" typoType="h4" color="gray1">
        {title}
      </Typo>
      {children}
    </Container>
  );
};

export default BasicCard;

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${colorSet.white};

  h2 {
    background: ${colorSet.gray11};
    height: 48px;
    display: flex;
    align-items: center;
    margin: 8px;
    border-radius: 8px;
  }
`;

export const BasicCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
