import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { keyframes, styled } from "styled-components";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { setImporterTwoDepsNav } from "@/src/store/slice/sideNav";
import { setItemType } from "@/src/store/slice/companyItem";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import ImporterMainContentLayout from "./ImporterMainContentLayout";
import { STRINGS } from "@/src/constant";
import {
  IMPORTER_CUSTOMER_SERVICE_MENU,
  IMPORTER_OPERATION_NAV_MENU,
  IMPORTER_TERMS_POLICY_NAV_MENU,
} from "./menu";
import {
  Accordion as AccordionRoot,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BackgroundImagePng from "@/src/assets/icons/hrm_bg.png";
import LogoSvg from "@/src/assets/logos/logo-export.svg";
import TopSubtractSvg from "@/src/assets/icons/icon-top-subtract-indigo.svg";
import BottomSubtractSvg from "@/src/assets/icons/icon-bottom-subtract-indigo.svg";
import ChevronDownSvg from "@/src/assets/icons/icon-chevron-down-indigo.svg";
import { changeLanguage } from "@/src/store/slice/lang";
import I18n from "@/src/i18n";
import { useTranslation } from "react-i18next";

interface MainLayoutProps {
  children?: ReactNode;
  breadcrumb?: string[];
  pageTitle?: ReactNode;
  customPageTitle?: ReactNode;
  sideAccessory?: ReactNode;
  useInnerScroll?: boolean;
}

interface NavMenu {
  iconSrc: string;
  selectedIconSrc?: string;
  label: { en: string; ko: string };
  destination: string;
  isBlank?: boolean;
}

const ImporterMainLayout = ({
  children,
  breadcrumb,
  pageTitle,
  customPageTitle,
  sideAccessory,
  useInnerScroll,
}: MainLayoutProps) => {
  return (
    <Container>
      <BackgroundImage>
        <img src={BackgroundImagePng} alt="background" />
      </BackgroundImage>

      <Nav />

      <ImporterMainContentLayout
        breadcrumb={breadcrumb}
        pageTitle={pageTitle}
        customPageTitle={customPageTitle}
        sideAccessory={sideAccessory}
        useInnerScroll={useInnerScroll}
      >
        {children}
      </ImporterMainContentLayout>
    </Container>
  );
};

export default ImporterMainLayout;

const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  min-width: 1250px;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const BackgroundImage = styled.figure`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 20%;
  z-index: ${zIndex.backgroundImage};

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

// ------------------------------------ //

const Nav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { value } = useAppSelector((state) => state.lang);
  const { importerSelectTwoDepsNav } = useAppSelector((state) => state.sideNav);

  const navMenuList = IMPORTER_OPERATION_NAV_MENU.map((item) => {
    return {
      ...item,
      label: STRINGS.sideNav[item.label as keyof typeof STRINGS.sideNav],
    };
  }) as NavMenu[];

  const customerServiceCenterMenuList = IMPORTER_CUSTOMER_SERVICE_MENU.map(
    (item) => {
      return {
        ...item,
        label: STRINGS.sideNav[item.label as keyof typeof STRINGS.sideNav],
      };
    }
  ) as NavMenu[];

  const handleTwoDepsClick = (idx: number) => {
    const target = importerSelectTwoDepsNav[idx];

    if (target) {
      const copy = importerSelectTwoDepsNav.slice();
      copy[idx] = { ...target, isOpen: !target.isOpen };

      dispatch(setImporterTwoDepsNav(copy));
    }
  };

  const handleNavItemClick = (path: string, isBlank?: boolean) => {
    if (location.pathname === path) {
      return;
    }

    if (isBlank) {
      return window.open(path, "_blank");
    }

    return navigate(path);
  };

  const handleLanguageChange = (lang: "en" | "ko") => {
    I18n.changeLanguage(lang);
    dispatch(changeLanguage(lang));
  };

  return (
    <StyledNav>
      <div>
        <Logo
          className="logo"
          onClick={() => navigate(IMPORTER_PRIVATE_PATH.HOME)}
        />

        <NavigationSection>
          {/* Service operation */}
          <NavGroupTitle>{t("sideNav:serviceOperation")}</NavGroupTitle>
          <NavGroupContainer>
            {navMenuList.map(
              ({ iconSrc, label, destination, selectedIconSrc }, idx) => {
                const currentPath = window.location.pathname.split("/")[2];
                const isSelected =
                  `${IMPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                  destination;

                return (
                  <NavItem
                    key={idx.toString()}
                    data-status={isSelected ? "selected" : "unselected"}
                    onClick={() => handleNavItemClick(destination)}
                  >
                    <Icon
                      iconSrc={isSelected ? selectedIconSrc ?? "" : iconSrc}
                      iconSize={20}
                    />
                    <Typo
                      color={isSelected ? "white" : "indigo"}
                      typoType="b8m"
                    >
                      {label[value]}
                    </Typo>
                  </NavItem>
                );
              }
            )}
          </NavGroupContainer>

          {/* Management */}
          {importerSelectTwoDepsNav?.map((item, idx) => {
            const title =
              idx === 0 ? "Corporate operations" : "Import management";
            return (
              <>
                <NavGroupTitle>{title}</NavGroupTitle>
                <AccordionRoot
                  type="single"
                  value={item.isOpen ? item.label[value] : ""}
                >
                  <AccordionItem
                    key={idx.toString()}
                    value={item.label[value]}
                    onClick={() => {
                      handleTwoDepsClick(idx);
                    }}
                  >
                    <StyledAccordionTrigger>
                      <div>
                        <Icon iconSrc={item.iconSrc} iconSize={20} />
                        <Typo typoType="b8m" color="indigo">
                          {item.label[value]}
                        </Typo>
                      </div>

                      <StyledChevronIcon
                        iconSrc={ChevronDownSvg}
                        iconSize={20}
                      />
                    </StyledAccordionTrigger>

                    <StyledAccordionContent>
                      {item.children.map(({ label, destination }) => {
                        const currentPath =
                          window.location.pathname.split("/")[2];
                        const isSelected =
                          `${IMPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                          destination;

                        return (
                          <AccordionNavItem
                            key={label[value]}
                            data-status={isSelected ? "selected" : "unselected"}
                            onClick={(e) => {
                              if (
                                destination ===
                                IMPORTER_PRIVATE_PATH.ITEM_MANAGEMENT
                              ) {
                                dispatch(setItemType("item"));
                              }

                              e.preventDefault();
                              e.stopPropagation();
                              handleNavItemClick(destination);
                            }}
                          >
                            <Typo
                              color={isSelected ? "white" : "indigo"}
                              typoType="b9m"
                            >
                              {label[value]}
                            </Typo>
                          </AccordionNavItem>
                        );
                      })}
                    </StyledAccordionContent>
                  </AccordionItem>
                </AccordionRoot>
              </>
            );
          })}

          {/* Customer ServiceCenter Menu */}
          <NavGroupTitle>{t("sideNav:customerServiceCenter")}</NavGroupTitle>

          <NavGroupContainer>
            {customerServiceCenterMenuList.map(
              ({ iconSrc, label, destination, selectedIconSrc, isBlank }) => {
                const currentPath = window.location.pathname.split("/")[2];
                const isSelected =
                  `${IMPORTER_PRIVATE_PATH.ROOT}/${currentPath}` ===
                  destination;

                return (
                  <NavItem
                    key={label[value]}
                    data-status={isSelected ? "selected" : "unselected"}
                    onClick={() => handleNavItemClick(destination, isBlank)}
                  >
                    <Icon
                      iconSrc={isSelected ? selectedIconSrc ?? "" : iconSrc}
                      iconSize={20}
                    />
                    <Typo
                      color={isSelected ? "white" : "indigo"}
                      typoType="b8m"
                    >
                      {label[value]}
                    </Typo>
                  </NavItem>
                );
              }
            )}
          </NavGroupContainer>
        </NavigationSection>
      </div>
      <NavBottomDiv>
        <NavGroupContainer>
          {IMPORTER_TERMS_POLICY_NAV_MENU(t).map(({ label, destination }) => {
            return (
              <NavItem
                key={label}
                onClick={() => handleNavItemClick(destination)}
              >
                <Typo color="indigo" typoType="b9m">
                  {label}
                </Typo>
              </NavItem>
            );
          })}

          <LanguageContainer>
            <Typo color="indigo" typoType="b9m">
              {t("error:lang")}
            </Typo>

            <Language>
              <Typo
                as="button"
                className="en"
                onClick={() => handleLanguageChange("en")}
                data-selected={value === "en"}
              >
                EN
              </Typo>
              <Typo color="indigo" typoType="b9m" className="divider">
                |
              </Typo>
              <Typo
                as="button"
                className="ko"
                onClick={() => handleLanguageChange("ko")}
                data-selected={value === "ko"}
              >
                KOR
              </Typo>
            </Language>
          </LanguageContainer>
        </NavGroupContainer>
      </NavBottomDiv>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  width: 256px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  z-index: 1;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Logo = styled.span`
  display: inline-block;
  background: url(${LogoSvg}) no-repeat;
  background-size: contain;
  width: 160px;
  height: 24px;
  margin: 32px 32px 20px 32px;
  flex-shrink: 0;
  cursor: pointer;
`;

const NavigationSection = styled.section`
  padding: 0 0 0 16px;
`;

const NavGroupTitle = styled.p`
  ${typo.b10m};
  color: ${colorSet.gray6};
  padding: 20px 16px 12px;
`;

const NavGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0 12px 16px;
  cursor: pointer;

  &[data-status="selected"] {
    position: relative;
    background: ${colorSet.indigo};
    border-radius: 80px 0px 0px 80px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    &::before {
      content: "";
      position: absolute;
      top: -23px;
      right: 0px;
      width: 23px;
      height: 24px;
      background: url(${TopSubtractSvg}) no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -23px;
      right: 0px;
      width: 23px;
      height: 24px;
      background: url(${BottomSubtractSvg}) no-repeat;
    }
  }

  &[data-status="unselected"] {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 36px 0px 0px 36px;
    }
  }
`;

const StyledChevronIcon = styled(Icon)`
  transition: 0.3s;
`;

const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
  }

  &[data-state="open"] {
    border-top-left-radius: 32px;
    background: #0000001a;

    ${StyledChevronIcon} {
      transform: rotateZ(-180deg);
    }
  }

  &[data-state="closed"] {
    ${StyledChevronIcon} {
      transform: rotateZ(0deg);
    }
  }
`;

const slideDown = keyframes`
    0% {
        opacity: 0;
        height: 0;
        padding-bottom: 0;
        background: none;
    }
    100% {
        opacity: 1;
        height: var(--radix-accordion-content-height);
        padding-bottom: 12px;
        background: #0000001A;
    }
`;

const slideUp = keyframes`
    0% {
        opacity: 1;
        height: var(--radix-accordion-content-height);
        padding-bottom: 12px;
        background: #0000001A;
    }
    100% {
        height: 0;
        opacity: 0;
        padding-bottom: 0;
        background: none;
    }
`;

const StyledAccordionContent = styled(AccordionContent)`
  border-bottom-left-radius: 32px;
  background: #0000001a;
  padding-bottom: 12px;

  &[data-state="open"] {
    animation: ${slideDown} 0.3s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.3s ease-in-out;
  }
`;

const AccordionNavItem = styled(NavItem)`
  margin-left: 24px;
`;

const NavBottomDiv = styled.div`
  padding: 0 0 0 16px;
`;

const LanguageContainer = styled.div`
  padding: 12px 16px 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Language = styled.div`
  display: flex;
  align-items: center;

  .divider {
    padding: 0 4px;
  }

  button.en,
  button.ko {
    all: unset;
    cursor: pointer;

    ${typo.b9m}
    &[data-selected="true"] {
      color: ${colorSet.indigo};
    }

    &[data-selected="false"] {
      color: ${colorSet.gray6};
    }
  }
`;
