import styled from "styled-components";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import SharedEmailLayout from "@/src/components/template/Layout/SharedEmailLayout";
import CertificationCodeLayout from "./components/CertificationCode";
import { useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import { Button } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import { useLocation, useSearchParams } from "react-router-dom";
import { sessionStorageKeySet } from "@/src/constant/sessionstorage";
import { ContractDetailShareInfoDto } from "@/src/store/apis/contracts/contractShare/interface";
import { useLazyGetImporterContractDetailQuery } from "@/src/store/apis/contracts/contractDetail";
import { ContractDetailViewForImporterDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { SHARED_SEARCH_PARAMS_STRINGS } from "./constants";
import Loader from "@/src/components/atom/Loader";
import ImportContractItem from "./components/tabs/ImportContractItem";
import { useLazyGetContractSharedQuery } from "@/src/store/apis/contracts/contractShare";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import { useTranslation } from "react-i18next";

export type ImportContractDecrypt = {
  buyerId: string;
  contractId: string;
  contractShareKey: string;
};

const SharedImportContractPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const location = useLocation();
  const sharedImportContractEncryptKey = location.search.slice(1);

  // API
  const [
    getContractPreviewData,
    { isPreviewError, isPreviewContractFetching },
  ] = useLazyGetImporterContractDetailQuery({
    selectFromResult: ({ isError, isFetching }) => {
      return {
        isPreviewError: isError,
        isPreviewContractFetching: isFetching,
      };
    },
  });
  const [getContractSharedData, { isContractError, isFetching }] =
    useLazyGetContractSharedQuery({
      selectFromResult: ({ isError, isFetching }) => {
        return {
          isFetching,
          isContractError: isError,
        };
      },
    });

  // Query
  // 복호화된 Query String 객체
  const contractDecryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedImportContractEncryptKey)
  ) as ImportContractDecrypt;

  const contractSessionKeysArray = window.sessionStorage.getItem(
    sessionStorageKeySet.SHARED_CONTRACT
  )
    ? (JSON.parse(
        window.sessionStorage.getItem(
          sessionStorageKeySet.SHARED_CONTRACT
        ) as string
      ) as string[])
    : [];
  const isIncludesContractSessionKey = contractSessionKeysArray?.includes(
    sharedImportContractEncryptKey
  );
  const isPreview =
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.preview) === "true";
  const previewContractId = Number(
    searchParams.get(SHARED_SEARCH_PARAMS_STRINGS.importContractId)
  );

  // State
  const [contractData, setContractData] =
    useState<ContractDetailShareInfoDto>();
  const [previewData, setPreviewData] =
    useState<ContractDetailViewForImporterDto>();
  const [isCodeConfirm, setIsCodeConfirm] = useState(false);

  const { control, handleSubmit, setFocus, getValues } = useForm<{
    contractShareKey: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      contractShareKey: "",
    },
  });

  useEffect(() => {
    setFocus("contractShareKey");
  }, [setFocus]);

  // 공유코드 입력후 공유용 API Fetching
  const handleSubmitClick = async () => {
    const params = {
      contractShareKey: getValues("contractShareKey"),
      contractId: Number(contractDecryptToObject.contractId),
    };

    try {
      const res = await getContractSharedData(params).unwrap();
      setContractData(res.row);
      const jsonArray = JSON.stringify([
        ...contractSessionKeysArray,
        sharedImportContractEncryptKey,
      ]);
      window.sessionStorage.setItem(
        sessionStorageKeySet.SHARED_CONTRACT,
        jsonArray
      );
      setIsCodeConfirm(true);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  };

  // 마운트 시 query내 복호화된 shareKey가 입력되었을 때 공유용 API Fetching
  const fetchWhenMount = useCallback(async () => {
    const params = {
      contractShareKey: contractDecryptToObject.contractShareKey,
      contractId: Number(contractDecryptToObject.contractId),
    };

    try {
      const res = await getContractSharedData(params).unwrap();
      setContractData(res.row);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      setIsCodeConfirm(false);
      alert.showAlert({ type: "error", message });
    }
  }, [
    alert,
    contractDecryptToObject.contractId,
    contractDecryptToObject.contractShareKey,
    getContractSharedData,
  ]);

  // 미리보기 형식 상세정보 Fetching
  const getPreviewContractData = async () => {
    try {
      const res = await getContractPreviewData({
        id: previewContractId,
      }).unwrap();

      setPreviewData(res);
    } catch (e: any) {
      const message = Array.isArray(e?.data?.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  useLayoutEffect(() => {
    if (isPreview) {
      setIsCodeConfirm(true);
      getPreviewContractData();
    }
    if (isIncludesContractSessionKey) {
      setIsCodeConfirm(true);
      fetchWhenMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSharedEmailContent = () => {
    if (isPreviewContractFetching || isFetching) {
      return (
        <LoaderContainer>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <ImportContractItem
        contractData={isPreview ? previewData : (contractData as any)}
        isError={isPreview ? isPreviewError : isContractError}
      />
    );
  };

  return (
    <>
      {!isCodeConfirm ? (
        <CertificationCodeLayout title="Check share contract information">
          <Form onSubmit={handleSubmit(handleSubmitClick)}>
            <FormItem
              label={"Certification Code"}
              type="text"
              name="contractShareKey"
              rules={{ required: true }}
              errorsMessage={{
                required: t("error:required"),
              }}
              control={control}
              direction="vertical"
              inputProps={{
                placeholder: "Enter code",
              }}
            />

            <StyledButton type="submit">Confirm</StyledButton>
          </Form>
        </CertificationCodeLayout>
      ) : (
        <SharedEmailLayout logoType="import">
          {renderSharedEmailContent()}
        </SharedEmailLayout>
      )}
    </>
  );
};

export default SharedImportContractPage;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const StyledButton = styled(Button)`
  text-align: center;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
