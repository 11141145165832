import { Dayjs } from "dayjs";

// 주차를 계산하는 함수
export const getWeekOfMonth = (date: Dayjs) => {
  const startOfMonth = date.startOf("month");
  const startDayOfWeek = startOfMonth.day(); // 월의 첫날의 요일

  // 월의 첫 주가 시작되는 요일 기준으로 첫 주의 일수 계산
  const adjustedStartOfMonth = startOfMonth.subtract(startDayOfWeek, "day");

  let weekNumber = Math.ceil((date.diff(adjustedStartOfMonth, "day") + 1) / 7);

  // 주의 시작일이 이전 달에 걸칠 경우, 주차를 재조정한다.
  if (startOfMonth.month() !== date.month()) {
    weekNumber = 5;
  }

  return weekNumber;
};

// 현재 요일부터 시작하는 주의 요일 배열 가져오기
export const getWeeklyDays = (startDay: Dayjs) => {
  const daysOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const day = startDay.add(i, "day");
    daysOfWeek.push({
      value: day,
      day: day.format("dddd").toLocaleLowerCase(), // 요일 문자열
    });
  }

  return daysOfWeek;
};

// 선택된 날짜 기준의 -12개월을 하여 속한 개월의 배열 가져오기
export const getLast12Months = (endDate: Dayjs) => {
  const monthsArray = [];
  const startDate = endDate.subtract(11, "month");

  for (let i = 0; i < 12; i++) {
    const currentMonth = startDate.add(i, "month");

    monthsArray.push({
      value: currentMonth,
      fullName: currentMonth.format("MMMM").toLocaleLowerCase(),
      headerName: currentMonth.format("YY.MM"),
    });
  }

  return monthsArray;
};

// 날짜를 비교해서 두 날짜의 차이만큼 date정로의 배열을 담아주는 함수
export const getDayNames = (startDate: Dayjs, endDate: Dayjs) => {
  const dateArray = [];
  const duration = endDate.diff(startDate, "days");

  for (let i = 0; i <= duration; i++) {
    const currentDay = startDate.add(i, "day");

    dateArray.push({
      value: currentDay,
      fullName: currentDay.format("MM-DD"),
      headerName: currentDay.format("YY.MM.DD"),
    });
  }

  return dateArray;
};
