import { PropsWithChildren, ReactNode } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenuContentProps,
  DropdownMenuPortalProps,
  DropdownMenuProps,
  DropdownMenuTriggerProps,
} from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";
import zIndex from "@/src/styles/zIndex";

interface DropDownProps extends DropdownMenuProps {
  dropdownTrigger: ReactNode;
  dropdownTriggerProps?: DropdownMenuTriggerProps;
  dropdownContentProps?: DropdownMenuContentProps;
  dropdownPortalProps?: DropdownMenuPortalProps;
}

function DropDown({
  dropdownTrigger,
  dropdownTriggerProps,
  dropdownContentProps,
  dropdownPortalProps,
  children,
  ...rest
}: PropsWithChildren<DropDownProps>) {
  return (
    <DropdownMenu.Root {...rest}>
      <DropdownMenu.Trigger asChild {...dropdownTriggerProps}>
        {dropdownTrigger}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal {...dropdownPortalProps}>
        <StyledDropdownMenuContent sideOffset={5} {...dropdownContentProps}>
          {children}
        </StyledDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default DropDown;

const StyledDropdownMenuContent = styled(DropdownMenu.Content)`
  z-index: ${zIndex.dropdown};
`;
