import dayjs from "dayjs";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import Typo from "@/src/components/atom/Typo";
import Badge from "@/src/components/atom/Badge";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";

export const columnShareableTable: ColDef[] = [
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: "status",
    flex: 1,
    minWidth: 120,
    sortable: false,

    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.signatureStatus === "LINKED") {
        return (
          <Badge
            badgeColor="systemLime5"
            color="systemLime1"
            text="YES"
            badgeSize="S"
          />
        );
      }
      return (
        <Badge badgeColor="gray10" color="gray7" text="NO" badgeSize="S" />
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "PO. No",
    cellStyle: { textAlign: "center" },
    field: "poNo",
    flex: 1,
    minWidth: 120,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.poNo ? (
        <Typo typoType="b9r">{params.data.poNo}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "SC. No",
    cellStyle: { textAlign: "center" },
    field: "scNo",
    flex: 1,
    minWidth: 120,
    sortable: false,
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Importer Code",
    cellStyle: { textAlign: "center" },
    field: "buyerNameCode",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.buyer.buyerNameCode ? (
        <Typo typoType="b9r">{params.data.buyer.buyerNameCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    cellStyle: { textAlign: "center" },
    field: "itemCode",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.itemCode ? (
        <Typo typoType="b9r">{params.data.itemCode}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Qty",
    cellStyle: { textAlign: "right" },
    field: "qty",
    flex: 1,
    minWidth: 120,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.quantity ? (
        <Typo typoType="b9r">
          {params.data.quantity} {params.data.quantityUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Unit Price",
    cellStyle: { textAlign: "right" },
    field: "unitPrice",
    flex: 1,
    minWidth: 120,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.unitPrice ? (
        <Typo typoType="b9r">
          {params.data.unitPrice} {params.data.unitPriceUnit}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Shipping terms",
    cellStyle: { textAlign: "center" },
    field: "shippingTerms",
    flex: 1,
    minWidth: 240,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.shippingTerms ? (
        <Typo typoType="b9r">
          {params.data.shippingTerms}
          {params.data.shippingTermsRemark && (
            <> {params.data.shippingTermsRemark}</>
          )}
        </Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Place of Delivery",
    cellStyle: { textAlign: "center" },
    field: "placeOfDelivery",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.placeOfDelivery ? (
        <Typo typoType="b9r">{params.data.placeOfDelivery}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Origin / Location",
    cellStyle: { textAlign: "center" },
    field: "orgin",
    flex: 1,
    minWidth: 160,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      return params.data.origin ? (
        <Typo typoType="b9r">{params.data.origin}</Typo>
      ) : (
        "-"
      );
    },
  },
  {
    headerClass: "ag-right-aligned-cell",
    headerName: "Last Shipment Date",
    cellStyle: { textAlign: "center" },
    field: "lastShipmentDateAt",
    flex: 1,
    minWidth: 220,
    sortable: false,
    cellRenderer: (params: ValueGetterParams) => {
      if (params.data.lastShipmentDateAt) {
        return (
          <Typo typoType="b9r">
            {dayjs(params.data.lastShipmentDateAt).format(
              DATE_FORMAT_STRINGS.YYYY_MM_DD
            )}
          </Typo>
        );
      } else {
        return "-";
      }
    },
  },
];
