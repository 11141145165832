import { apiSlice } from "../baseQuery";
import { ExporterItemCodeListViewDto } from "../corporate/companyItem/interface";
import { Row, Rows } from "../type";
import { ContractDetailViewDto, ContractDto } from "./contractDetail/interface";
import { ContractListViewDto } from "./contractList/interface";
import {
  ExporterItemWithExporterItemCodeDto,
  GenerateContractByImporterDto,
  GenerateContractDto,
  GenerateContractV2Dto,
  GeneratePoDto,
  GenerateScDto,
  GetContractListRequest,
} from "./interface";

const contractsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /**
     * 계약 생성
     */
    createContract: build.mutation<
      Row<ContractDetailViewDto>,
      GenerateContractDto
    >({
      query: (body) => ({
        url: "/exporters/this/contracts",
        method: "POST",
        body,
      }),
    }),
    createContractV2: build.mutation<
      Row<ContractDetailViewDto>,
      GenerateContractV2Dto
    >({
      query: (body) => ({
        url: "/v2/exporters/this/contracts",
        method: "POST",
        body,
      }),
    }),
    createContractBasedPo: build.mutation<
      Row<ContractDetailViewDto>,
      { id: number } & GenerateScDto
    >({
      query: (body) => ({
        url: `/exporters/this/contracts/${body.id}/sc`,
        method: "POST",
        body,
      }),
    }),
    createImporterContract: build.mutation<
      Row<ContractDto>,
      GenerateContractByImporterDto
    >({
      query: (body) => ({
        url: "/importers/this/contracts",
        method: "POST",
        body,
      }),
    }),
    createContractBasedSc: build.mutation<
      Row<ContractDetailViewDto>,
      { id: number } & GeneratePoDto
    >({
      query: (body) => ({
        url: `/importers/this/contracts/${body.id}/po`,
        method: "POST",
        body: {
          ...body,
          id: undefined,
        },
      }),
    }),

    /**
     * po No 존재 유무 확인
     */
    checkPoNo: build.query<Row<void>, { poNo: string }>({
      query: ({ poNo }) => ({
        url: "/exporters/this/contract/po-no-check",
        params: {
          poNo,
        },
      }),
    }),
    /**
     * sc No 존재 유무 확인
     */
    checkScNo: build.query<Row<void>, { scNo: string }>({
      query: ({ scNo }) => ({
        url: "/exporters/this/contract/sc-no-check",
        params: {
          scNo,
        },
      }),
    }),
    /**
     * 계약 리스트 불러오기
     */
    getContracts: build.query<
      Rows<ContractListViewDto>,
      GetContractListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/contracts",
        params,
      }),
    }),
    /**
     * 부킹 생성 > Load Contract 시 사용하는 API
     */
    getOngoingContracts: build.query<
      Rows<ContractListViewDto>,
      GetContractListRequest
    >({
      query: (params) => ({
        url: "/exporters/this/ongoing-contracts",
        params,
      }),
    }),
    /**
     * 단일 계약 상세 불러오기
     */
    getContract: build.query<
      Row<ContractDetailViewDto>,
      { id: number; bookingId?: number }
    >({
      query: ({ id, bookingId }) => ({
        url: `/exporters/this/contracts/${id}`,
        params: {
          bookingId,
        },
      }),
    }),
    /**
     * 단일 계약 수정 하기
     */
    editContract: build.mutation<
      Row<ContractDetailViewDto>,
      GenerateContractDto & { id: number }
    >({
      query: (body) => ({
        url: `/exporters/this/contracts/${body.id}`,
        method: "PUT",
        body: {
          ...body,
          id: undefined,
        },
      }),
    }),

    /**
     * 단일 계약 수정 하기
     */
    editExporterContract: build.mutation<
      Row<ContractDetailViewDto>,
      GenerateContractDto & { id: number }
    >({
      query: (body) => ({
        url: `/v2/exporters/this/contracts/${body.id}`,
        method: "PUT",
        body: {
          ...body,
          id: undefined,
        },
      }),
    }),
    editImporterContract: build.mutation<
      Row<ContractDetailViewDto>,
      GenerateContractDto & { id: number }
    >({
      query: (body) => ({
        url: `/v2/importers/this/contracts/${body.id}`,
        method: "PUT",
        body: {
          ...body,
          id: undefined,
        },
      }),
    }),
    /**
     * 아이템 ID로 아이템 코드 목록 조회
     */
    getItemCodeListViaItemId: build.query<
      Rows<ExporterItemCodeListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/exporter-items/${id}/exporter-item-codes`,
      }),
    }),
    getExporterItemsWithExporterItemCodes: build.query<
      Rows<ExporterItemWithExporterItemCodeDto>,
      void
    >({
      query: () => ({
        url: "/exporters/this/exporter-items-with-exporter-item-codes",
      }),
    }),
  }),
});

export const {
  useCreateContractMutation,
  useCreateContractV2Mutation,
  useCreateContractBasedPoMutation,
  useCreateContractBasedScMutation,
  useCreateImporterContractMutation,
  useGetContractQuery,
  useGetExporterItemsWithExporterItemCodesQuery,
  useEditContractMutation,
  useEditImporterContractMutation,
  useLazyGetContractsQuery,
  useLazyCheckPoNoQuery,
  useLazyCheckScNoQuery,
  useLazyGetContractQuery,
  useLazyGetItemCodeListViaItemIdQuery,
  useLazyGetExporterItemsWithExporterItemCodesQuery,
  useEditExporterContractMutation,
  useLazyGetOngoingContractsQuery,
} = contractsApi;
