import { useContext } from "react";
import { AlertRootContext } from "../components/quantum/AlertQuantum";

const useAlert = () => {
  const context = useContext(AlertRootContext);

  if (!context) {
    throw Error("alert context 안에서 사용하세요");
  }

  return context;
};

export default useAlert;
