import React, { useState } from "react";
import BookingContractCardItem from "./BookingContractCardItem";
import { useGetBookingContractsQuery } from "@/src/store/apis/shipments/importerShipment";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

interface ContractInformationCardProps {
  id: number;
}

const EMPTY_ARRAY: [] = [];

const ContractInformationCard = ({ id }: ContractInformationCardProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { contractList } = useGetBookingContractsQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        const isStable = !isUnstable;

        return {
          contractList: isStable ? currentData.rows : EMPTY_ARRAY,
        };
      },
    }
  );
  return (
    <>
      <SectionFoldableCard
        open={open}
        onOpenChange={setOpen}
        cardTitle={t("shipment:importer.detail.label.contractInformation")}
        rightAccessary={
          <RightArrowIcon
            isCardOpen={open}
            onClick={() => setOpen((open) => !open)}
          />
        }
      >
        {contractList.map((data, index) => {
          return <BookingContractCardItem data={data} index={index} />;
        })}
      </SectionFoldableCard>
    </>
  );
};

export default ContractInformationCard;
