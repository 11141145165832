import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AlertListItem, AlertRootContext, FadeOutTime } from ".";

const useAlertRoot = (
  list: AlertListItem[],
  onChangeList: Dispatch<SetStateAction<AlertListItem[]>>
) => {
  const context = useContext(AlertRootContext);
  const [alertTime, setAlertTime] = useState(0);

  if (context === undefined) {
    throw Error("Alert Dialog Context 안에서 사용하세요");
  }

  const showAlert = ({
    message,
    type = "success",
    position = "topCenter",
    time = 3000,
    limit = 1,
  }: AlertListItem) => {
    const newAlert: AlertListItem = {
      id: Date.now() + list.length,
      message: message,
      position,
      type,
      isHide: false,
      time,
      limit,
    };
    onChangeList((prev) => {
      const currentList = [...prev, newAlert];
      const sliceList = currentList.slice(0, limit);

      return sliceList;
    });
    setAlertTime(time);
  };

  const clearAll = useCallback(() => {
    return onChangeList([]);
  }, [onChangeList]);

  const removeAlert = useCallback(
    (target: AlertListItem) => {
      onChangeList((prev) =>
        prev.map((item) => {
          return target.id === item.id ? { ...item, isHide: true } : item;
        })
      );

      setTimeout(() => {
        onChangeList((prev) => prev.filter((item) => item.id !== target.id));
      }, FadeOutTime);
    },
    [onChangeList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length >= 1) {
        removeAlert(list[0]);
      }
    }, alertTime);

    return () => {
      clearInterval(interval);
    };
  }, [list, alertTime, removeAlert]);

  return {
    fnc: { showAlert, onChangeList, removeAlert, clearAll },
    state: { list },
  };
};

export default useAlertRoot;
