import React, { CSSProperties, useEffect, useRef } from "react";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import Typo from "@/src/components/atom/Typo";
import SectionCardGrid from "@/src/components/molecule/SectionCardGrid";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import SectionCard from "@/src/components/molecule/SectionCard";
import { useGetImporterBookingDetailQuery } from "@/src/store/apis/bookings/bookingDetail";
import { isNull, isUndefined } from "@/src/utils/is";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import EtdCompareCount from "@/src/components/molecule/EtdCompareCount";
import { IconButton } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import { downloadFile } from "@/src/utils/downloadFile";
import PreviewSvg from "@/src/assets/icons/icon-preview-indigo.svg";
import PreviewGraySvg from "@/src/assets/icons/icon-preview-gray.svg";
import DownloadSvg from "@/src/assets/icons/icon-download-indigo.svg";
import DownloadGraySvg from "@/src/assets/icons/icon-download-gray.svg";
import { useTranslation } from "react-i18next";

interface BookingInProcessingTabProps {
  id: number;
  refetchList?: () => void;
}

const BookingInProcessingTab = ({
  id,
  refetchList,
}: BookingInProcessingTabProps) => {
  const { t } = useTranslation();
  const isRefetchOnce = useRef<boolean>(false);
  const {
    bookingNo,
    portOfLoading,
    etdAt,
    etaAt,
    ataAt,
    atdAt,
    placeOfDelivery,
    shippingLine,
    shippingLineUrl,
    placeOfReceipt,
    portOfDischarge,
    vessel,
    voyageNo,
    bookingRemark,
    bookingFileSimpleMedia,
  } = useGetImporterBookingDetailQuery(
    { id },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;

        const isNew = !(currentData?.isRead ?? true);

        if (isNew && !isRefetchOnce.current) {
          refetchList?.();
          isRefetchOnce.current = true;
        }

        return {
          bookingNo: isStable ? currentData.bookingNo : "-",
          currentData,
          etdAt: isStable
            ? dayjs(currentData.etdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : undefined,
          etaAt: isStable
            ? dayjs(currentData.etaAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
            : undefined,
          ataAt:
            isStable && currentData.ataAt
              ? dayjs(currentData.ataAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : undefined,
          atdAt:
            isStable && currentData.atdAt
              ? dayjs(currentData.atdAt).format(DATE_FORMAT_STRINGS.YYYY_MM_DD)
              : undefined,
          portOfLoading: isStable ? currentData.portOfLoading : "-",
          placeOfDelivery: isStable ? currentData.placeOfDelivery : "-",
          shippingLine: isStable ? currentData.shippingLine : "-",
          placeOfReceipt: isStable ? currentData.placeOfReceipt : "-",
          portOfDischarge: isStable ? currentData.portOfDischarge : "-",
          shippingLineUrl: isStable ? currentData.shippingLineUrl : undefined,
          vessel: isStable ? currentData.vessel : undefined,
          voyageNo: isStable ? currentData.voyageNo : undefined,
          bookingRemark: isStable ? currentData.bookingRemark : undefined,
          bookingFileSimpleMedia: isStable
            ? currentData.bookingFileSimpleMedia
            : null,
        };
      },
    }
  );

  useEffect(() => {
    isRefetchOnce.current = false;
  }, [id]);

  return (
    <Article>
      <FlexDiv fullWidth flexDirection="column">
        <Typo as="h3" typoType="h4" className="header">
          Booking No. {bookingNo}
        </Typo>
        <SectionCardGrid>
          {[
            {
              gridColumn: 6,
              title: "ETD / ATD",
              value: (
                <BreakWord typoType="h4">
                  {etdAt || "-"} / {atdAt || "-"}{" "}
                  {etdAt && atdAt ? (
                    <EtdCompareCount
                      initDate={dayjs(atdAt).toISOString()}
                      currentDate={dayjs(etdAt).toISOString()}
                    />
                  ) : null}
                </BreakWord>
              ),
            },
            {
              gridColumn: 6,
              title: "ETA / ATA",
              value: (
                <BreakWord typoType="h4">
                  {etaAt || "-"} / {ataAt || "-"}{" "}
                  {etaAt && ataAt ? (
                    <EtdCompareCount
                      initDate={dayjs(ataAt).toISOString()}
                      currentDate={dayjs(etaAt).toISOString()}
                    />
                  ) : null}
                </BreakWord>
              ),
            },
            {
              isDivider: true,
            },
            {
              gridColumn: 3,
              title: "Port of Loading",
              value: <BreakWord typoType="h4">{portOfLoading}</BreakWord>,
            },

            {
              gridColumn: 3,
              title: "Place of Delivery",
              value: <BreakWord typoType="h4">{placeOfDelivery}</BreakWord>,
            },
            {
              gridColumn: 6,
              title: "Shipping Line",
              value: (
                <BreakWord
                  typoType="h4"
                  onClick={() => {
                    if (shippingLineUrl) {
                      window.open(shippingLineUrl, "_blank");
                    }
                  }}
                  data-action={!!shippingLineUrl}
                >
                  {shippingLine}
                </BreakWord>
              ),
            },
          ].map(({ gridColumn, isDivider = false, title, value }, idx) => {
            if (isDivider) {
              return (
                <StyledSectionCardRow
                  data-columns={12}
                  data-divider
                  key={idx.toString()}
                />
              );
            }
            return (
              <StyledSectionCardRow
                key={idx.toString()}
                data-columns={gridColumn}
                label={
                  <Typo color="gray6" typoType="b9m">
                    {title}
                  </Typo>
                }
                direction="vertical"
                value={value}
              />
            );
          })}
        </SectionCardGrid>
      </FlexDiv>

      <FlexDiv gap={16}>
        <StyledSectionCard
          cardTitle={t("contract:importer.detail.label.bookingInformation")}
        >
          <FlexDiv gap={24} flexDirection="column">
            <FlexDiv gap={8} flexDirection="column">
              <Typo typoType="b9m" color="gray7">
                Location
              </Typo>

              <FlexDiv gap={16} flexDirection="column">
                {[
                  { label: "Place of Receipt", value: placeOfReceipt },
                  { label: "Port of Discharge", value: portOfDischarge },
                  {
                    label: "Vessel / Voyage No.",
                    value: `${vessel} / ${voyageNo}`,
                  },
                ].map(({ label, value }) => {
                  return (
                    <FlexDiv gap={8}>
                      <Typo typoType="b7m" color="gray2" className="row-label">
                        {label}
                      </Typo>
                      <Typo typoType="b7r" color="gray5">
                        {value}
                      </Typo>
                    </FlexDiv>
                  );
                })}
              </FlexDiv>
            </FlexDiv>

            <FlexDiv gap={8} flexDirection="column">
              <Typo typoType="b9m" color="gray7">
                Attachment File
              </Typo>

              <FlexDiv gap={8}>
                <Typo typoType="b7m" color="gray2" className="row-label">
                  Booking
                </Typo>

                <FlexDiv gap={4}>
                  <IconButton
                    buttonSize={24}
                    buttonColor="blue"
                    buttonGrade="secondary"
                    disabled={isNull(bookingFileSimpleMedia)}
                  >
                    {isNull(bookingFileSimpleMedia) ? (
                      <Icon iconSrc={DownloadGraySvg} iconSize={16} />
                    ) : (
                      <Icon
                        iconSrc={DownloadSvg}
                        iconSize={16}
                        onClick={() =>
                          downloadFile(
                            bookingFileSimpleMedia?.mediaUrl ?? "",
                            bookingFileSimpleMedia?.originalFileName ?? ""
                          )
                        }
                      />
                    )}
                  </IconButton>
                  <IconButton
                    buttonSize={24}
                    buttonColor="blue"
                    buttonGrade="secondary"
                    disabled={isNull(bookingFileSimpleMedia)}
                  >
                    {isNull(bookingFileSimpleMedia) ? (
                      <Icon iconSrc={PreviewGraySvg} iconSize={16} />
                    ) : (
                      <a
                        href={bookingFileSimpleMedia?.mediaUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon iconSrc={PreviewSvg} iconSize={16} />
                      </a>
                    )}
                  </IconButton>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
        </StyledSectionCard>
        <StyledSectionCard cardTitle="Booking Remark">
          <Typo typoType="b7r" className="white-space-pre-wrap">
            {bookingRemark}
          </Typo>
        </StyledSectionCard>
      </FlexDiv>
    </Article>
  );
};

export default BookingInProcessingTab;

const Article = styled.article`
  width: calc(100% - 236px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    padding: 16px;
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &[data-columns="3"] {
    grid-column: 3 span;
  }

  &[data-columns="4"] {
    grid-column: 4 span;
  }

  &[data-columns="6"] {
    grid-column: 6 span;
  }

  &[data-columns="12"] {
    grid-column: 12 span;
  }

  &[data-divider="true"] {
    border-top: 1px solid ${colorSet.gray9};
  }
`;

const BreakWord = styled(Typo)`
  word-break: break-word;

  &[data-action="true"] {
    color: ${colorSet.systemBlue2};
    text-decoration: underline;
    text-decoration-color: ${colorSet.systemBlue2};
    cursor: pointer;
  }
`;

const FlexDiv = styled.div<{
  gap?: number;
  fullWidth?: boolean;
  flexDirection?: CSSProperties["flexDirection"];
}>`
  display: flex;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};

  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};

  .row-label {
    width: 164px;
    flex-shrink: 0;
  }
`;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
  flex-shrink: 0;

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }
`;
