import { InputHTMLAttributes, forwardRef } from "react";
import styled from "styled-components";
import CheckboxCheckSvg from "@/src/assets/icons/icon-checkbox-check.svg";
import UnCheckboxCheckSvg from "@/src/assets/icons/icon-checkbox-uncheck.svg";
import CheckboxCheckDisabledSvg from "@/src/assets/icons/icon-checkbox-check-disable.svg";
import CheckboxDisabledSvg from "@/src/assets/icons/icon-checkbox-disabled.svg";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  return <StyledCheckbox type="checkbox" ref={ref} {...props} />;
});

const StyledCheckbox = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  background: url(${UnCheckboxCheckSvg}) center/contain no-repeat;

  &[type="checkbox" i] {
    margin: 0;
  }

  &:checked {
    background: url(${CheckboxCheckSvg}) center/contain no-repeat;

    &:disabled {
      background: url(${CheckboxCheckDisabledSvg}) center/contain no-repeat;
    }
  }

  &:disabled {
    background: url(${CheckboxDisabledSvg}) center/contain no-repeat;
    cursor: not-allowed;
  }
`;

export default Checkbox;
