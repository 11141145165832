import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useRef,
  useState,
} from "react";
import { css, styled } from "styled-components";
import Icon from "../Icon";
import ClearSvg from "@/src/assets/icons/icon-input-clear.svg";

type InputSizeType = "medium" | "small";

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  allowClear?: boolean;
  ["data-invalid"]?: boolean;
  onClear?: () => void;
  inputSize?: InputSizeType;
  suffix?: ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    allowClear = true,
    inputSize = "medium",
    onFocus,
    onBlur,
    suffix,
    ...restProps
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <InputContainer
      data-invalid={restProps["data-invalid"]}
      $isDisabled={!!restProps.disabled}
      $isFocus={isFocus}
      $inputSize={inputSize}
    >
      <BorderlessInput
        ref={(node) => {
          if (node && ref) {
            if (typeof ref === "function") {
              ref(node);
            } else {
              ref.current = node;
            }
          }
          inputRef.current = node;
          setValue(node?.value);
        }}
        onFocus={(e) => {
          onFocus?.(e);
          setIsFocus(true);
        }}
        onBlur={(e) => {
          onBlur?.(e);
          setIsFocus(false);
        }}
        {...restProps}
        onChange={(e) => {
          restProps.onChange?.(e);
          setValue(e.target.value);
        }}
      />
      {allowClear && value && !restProps.disabled && (
        <StyledIcon
          iconSrc={ClearSvg}
          iconSize={20}
          onPointerDown={() => {
            props.onClear?.();
            setValue("");
            setTimeout(() => inputRef.current?.focus(), 0);
          }}
        />
      )}
      {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
    </InputContainer>
  );
});

export default Input;

const InputContainer = styled.div<{
  $isDisabled: boolean;
  $isFocus: boolean;
  $inputSize: InputSizeType;
}>`
  width: 100%;
  height: ${({ $inputSize }) => ($inputSize === "medium" ? 40 : 34)}px;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 8px 16px;
  border: 1px solid ${colorSet.gray9};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: all 0.15s;
  background: ${colorSet.white};

  &:hover {
    box-shadow: 0px 0px 0px 1px #1479eb, 0px 0px 0px 3px rgba(24, 123, 235, 0.2),
      0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  ${({ $isFocus }) =>
    $isFocus &&
    css`
      border: 1px solid ${colorSet.blue1};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

      &:hover {
        box-shadow: none;
      }
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      background: ${colorSet.gray10};
      border: 1px solid ${colorSet.gray8};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      pointer-events: none;
    `};

  &[data-invalid="true"] {
    border: 1px solid ${colorSet.red2};
  }
`;

const BorderlessInput = styled.input`
  width: 100%;
  border: none;
  flex: 1;
  ${typo.b7r};
  padding: 0;

  &::placeholder {
    color: ${colorSet.gray7};
    ${typo.b7r};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${colorSet.gray7};
    background: ${colorSet.gray10};
  }

  /* 자동완성시 disabled 컬러 */
  &:-webkit-autofill:disabled {
    -webkit-text-fill-color: ${colorSet.gray7};
    -webkit-box-shadow: 0 0 0px 1000px ${colorSet.gray10} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const SuffixContainer = styled.div`
  padding-left: 8px;
`;
