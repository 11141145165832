import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { setRedirectUrl } from "@/src/store/slice/auth";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";

const ProtectedPublicRoute = () => {
  const { user, redirectUrl, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const search = useLocation();

  if (user) {
    if (redirectUrl) {
      setTimeout(() => {
        dispatch(setRedirectUrl(null));
      }, 0);
      return <Navigate to={redirectUrl} replace />;
    }

    if (
      token &&
      (user.exporter.companyType === "BUYER" ||
        search.pathname === IMPORTER_PRIVATE_PATH.ROOT ||
        search.pathname === PUBLIC_PATH.ROOT)
    ) {
      return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} replace />;
    }

    if (
      token &&
      (user.exporter.companyType === "BOTH" ||
        user.exporter.companyType === "SELLER" ||
        search.pathname === EXPORTER_PRIVATE_PATH.ROOT ||
        search.pathname === PUBLIC_PATH.ROOT)
    ) {
      return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedPublicRoute;
