import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  ContainerShareInfoDto,
  ContainerShareInfoListDto,
  GetTaskSharedContainerListRequest,
  GetTaskSharedRequest,
  TaskBookingContractShareInfoV2Dto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const taskShareApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // (v2)이메일로 전송받은 taskShareKey와 taskId로 작업 단일 조회 API
    getTaskShared: build.query<
      Row<TaskBookingContractShareInfoV2Dto>,
      GetTaskSharedRequest
    >({
      query: (params) => ({
        url: "/v2/tasks/this",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 taskShareKey와 taskId로 작업에 귀속된 컨테이너 목록 조회 API
    getTaskContainerListShared: build.query<
      Rows<ContainerShareInfoListDto>,
      GetTaskSharedContainerListRequest
    >({
      query: (params) => ({
        url: "/tasks/this/containers",
        params,
      }),
      providesTags: [""],
    }),

    // 이메일로 전송받은 taskShareKey와 taskId로 작업에 귀속된 컨테이너 단일 조회 API
    getTaskContainerShared: build.query<
      ContainerShareInfoDto,
      GetTaskSharedRequest & { id: number }
    >({
      query: ({ id, ...params }) => ({
        url: `/tasks/this/containers/${id}`,
        params,
      }),
      transformResponse: (res: Row<ContainerShareInfoDto>) => res.row,
      providesTags: [""],
    }),
  }),
});

export const {
  useLazyGetTaskSharedQuery,
  useLazyGetTaskContainerListSharedQuery,
  usePrefetch: useTaskSharedPrefetch,
} = taskShareApi;
export { taskShareApi };
