import React, { useMemo, useRef, useState } from "react";
import { Button, IconButton } from "@/src/components/atom/Button";
import SectionCard from "@/src/components/molecule/SectionCard";
import { css, styled } from "styled-components";
import { ReactComponent as ClipSvg } from "@/src/assets/icons/icon-link-clip.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { ReactComponent as PreviewSvg } from "@/src/assets/icons/icon-preview-black.svg";
import { ReactComponent as CancelCircleSvg } from "@/src/assets/icons/icon-cancle-circle.svg";
import colorSet, { ColorType } from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import Typo from "@/src/components/atom/Typo";
import { downloadFile } from "@/src/utils/downloadFile";
import {
  ExternalDocumentType,
  SimpleDocumentMediaDto,
} from "@/src/store/apis/shipments/shipmentDetail/interface";
import useAlert from "@/src/hooks/useAlert";
import {
  useCreateExternalDocumentMutation,
  useDeleteShipmentExternalDocumentMutation,
} from "@/src/store/apis/shipments/shipmentDetail";
import { useUploadBigFileMutation } from "@/src/store/apis/media";
import { useParams } from "react-router-dom";
import Loader from "@/src/components/atom/Loader";
import Badge from "@/src/components/atom/Badge";
import { useTranslation } from "react-i18next";

interface DocumentsExternalCardProps {
  data: SimpleDocumentMediaDto[];
  isEditAuth: boolean;
  isShipmentCompleted: boolean;
}

function DocumentsExternalCard({
  data,
  isEditAuth,
  isShipmentCompleted,
}: DocumentsExternalCardProps) {
  const { t } = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [deleteDocument, { isLoading: isDeleting }] =
    useDeleteShipmentExternalDocumentMutation();
  const [upload, { isLoading: isUploading }] = useUploadBigFileMutation();
  const [createExternalDocument, { isLoading }] =
    useCreateExternalDocumentMutation();

  const [allButtonRef, setAllButtonRef] = useState<HTMLButtonElement[]>([]);

  const selectedExternalDocumentType = useRef<ExternalDocumentType>("ETC");
  const selectedExternalDocumentTypeMaxFileLength = useRef<number>(5);

  const externalDocumentMap = useMemo(() => {
    const map = new Map<ExternalDocumentType, SimpleDocumentMediaDto[]>();
    map.set("C/O", []);
    map.set("Insurance", []);
    map.set("Certificate", []);
    map.set("ETC", []);

    data?.forEach((item) => {
      map.set(item.type, [...(map.get(item.type) ?? []), item]);
    });

    return map;
  }, [data]);

  const handleDelete = async (id: number) => {
    try {
      await deleteDocument({ id }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    if (
      e.target.files.length > selectedExternalDocumentTypeMaxFileLength.current
    ) {
      alert.showAlert({
        type: "error",
        message: t("shipment:exporter.detail.alert.exceedMaximumFileLength"),
      });
      return;
    }

    try {
      const data = await upload({
        files: Array.from(e.target.files),
        folder: "shipment-external-files/",
      }).unwrap();

      await createExternalDocument({
        id: Number(params.id),
        mediaIds: data.map((media) => media.id),
        type: selectedExternalDocumentType.current,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      alert.showAlert({ type: "error", message });
    }
  };

  const renderFileInfo = (item: SimpleDocumentMediaDto) => {
    return (
      <FileInfo>
        <FileName typoType="b7r" color="gray5">
          {item.originalFileName}
        </FileName>

        <ButtonContainer>
          {isDeleting ? (
            <Loader />
          ) : (
            <CancelCircleIcon
              onClick={() => handleDelete(item.id)}
              isEditAuth={!isEditAuth || isShipmentCompleted}
            />
          )}

          <FileButtons>
            <IconButton
              ref={(node) => {
                if (node) {
                  const isAlreadyInRef = allButtonRef.some((buttonNode) =>
                    buttonNode.isSameNode(node)
                  );

                  if (!isAlreadyInRef) {
                    setAllButtonRef(allButtonRef.concat(node));
                  }
                }
              }}
              buttonSize={24}
              buttonColor="blue"
              buttonGrade="secondary"
              onClick={() => {
                downloadFile(item.mediaUrl, item.originalFileName);
              }}
            >
              <DownloadIcon color="indigo" />
            </IconButton>
            <IconButton
              buttonSize={24}
              buttonColor="blue"
              buttonGrade="secondary"
            >
              <a href={item.mediaUrl} target="_blank" rel="noreferrer">
                <PreviewIcon color="indigo" />
              </a>
            </IconButton>
          </FileButtons>
        </ButtonContainer>
      </FileInfo>
    );
  };

  const renderExternalDocumentLabel = (type: ExternalDocumentType) => {
    const documents = externalDocumentMap.get(type) ?? [];
    const DOCUMENTS_MAX_LENGTH = type === "ETC" ? 5 : 2;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <p
          style={{
            width: "80px",
            flexShrink: 0,
          }}
        >
          <Typo typoType="b7m">{type}</Typo>
        </p>
        <p
          style={{
            width: "74px",
          }}
        >
          <Typo typoType="h6" color="blue4">
            {documents.length}
          </Typo>
          <Typo typoType="b6m">/{DOCUMENTS_MAX_LENGTH}</Typo>
        </p>
      </div>
    );
  };

  const renderExternalDocument = (type: ExternalDocumentType) => {
    const documents = externalDocumentMap.get(type) ?? [];
    const DOCUMENTS_MAX_LENGTH = type === "ETC" ? 5 : 2;

    return (
      <div
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "flex-start",
        }}
      >
        {documents.length !== DOCUMENTS_MAX_LENGTH && (
          <ExternalDocumentButtonContainer>
            <StyledButton
              buttonGrade="secondary"
              buttonColor="blue"
              buttonSize={24}
              disabled={
                isUploading || isLoading || !isEditAuth || isShipmentCompleted
              }
              onClick={() => {
                if (fileInputRef.current) {
                  selectedExternalDocumentType.current = type;
                  selectedExternalDocumentTypeMaxFileLength.current =
                    (type === "ETC" ? 5 : 2) - documents.length;
                  fileInputRef.current.click();
                }
              }}
            >
              {isUploading || isLoading ? (
                <Loader />
              ) : (
                <ClipIcon disabled={!isEditAuth || isShipmentCompleted} />
              )}
              {t("shipment:exporter.detail.button.addFileButton")}
            </StyledButton>
          </ExternalDocumentButtonContainer>
        )}
        {documents.length > 0 && (
          <FileInfoList>
            {documents.map((item) => {
              return renderFileInfo(item);
            })}
          </FileInfoList>
        )}
      </div>
    );
  };

  return (
    <SectionCard
      cardContentContainerStyle={{
        padding: 0,
      }}
      cardTitle={
        <TitleWrapper>
          <Typo typoType="h6">
            {t("shipment:exporter.detail.label.additionalDocuments")}
          </Typo>
          <Badge
            badgeColor="white"
            borderColor="green2"
            color="green2"
            badgeType="line"
            badgeSize="S"
            text={t("shipment:exporter.detail.label.share")}
          />
        </TitleWrapper>
      }
      rightAccessory={
        <RightAccessaryContainer>
          <StyledButton
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => allButtonRef.forEach((button) => button.click())}
          >
            <DownloadIcon />
            {t("shipment:exporter.detail.button.downloadButton")}
          </StyledButton>

          <HiddenInput
            ref={fileInputRef}
            type="file"
            multiple={true}
            onChange={handleAddFile}
            value={""}
          />
        </RightAccessaryContainer>
      }
    >
      <Files>
        <SectionCardRow
          label={renderExternalDocumentLabel("C/O")}
          value={renderExternalDocument("C/O")}
        />
        <SectionCardRow
          label={renderExternalDocumentLabel("Insurance")}
          value={renderExternalDocument("Insurance")}
        />
        <SectionCardRow
          label={renderExternalDocumentLabel("Certificate")}
          value={renderExternalDocument("Certificate")}
        />
        <SectionCardRow
          label={renderExternalDocumentLabel("ETC")}
          value={renderExternalDocument("ETC")}
        />
      </Files>
    </SectionCard>
  );
}

export default DocumentsExternalCard;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RightAccessaryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 268px;
  padding: 24px;

  ${StyledScroll}
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FileInfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FileInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

const FileButtons = styled.div`
  display: flex;
  gap: 4px;

  a {
    line-height: 0px;
  }
`;

const FileName = styled(Typo)`
  word-break: break-all;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const PreviewIcon = styled(PreviewSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const ClipIcon = styled(ClipSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const CancelCircleIcon = styled(CancelCircleSvg)<{ isEditAuth: boolean }>`
  cursor: pointer;
  flex-shrink: 0;

  ${({ isEditAuth }) =>
    isEditAuth &&
    css`
      display: none;
    `}
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const ExternalDocumentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
