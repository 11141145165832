import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../../baseQuery";
import { Rows } from "../../type";
import { GetShipmentListRequest, ShipmentListViewDto } from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["list"],
});

const shipmentListApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 선적 목록 조회
    getShipmentList: build.query<
      Rows<ShipmentListViewDto>,
      GetShipmentListRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/shipments",
        params,
      }),
      providesTags: ["list"],
    }),

    // 선적 카테고리 필터된 목록 조회
    getShipmentCategoryFilterList: build.query<
      Rows<ShipmentListViewDto>,
      GetShipmentListRequest
    >({
      query: (params) => ({
        url: "/v2/exporters/this/shipments",
        params: { isExporterUserMainCategoryFilterOn: true, ...params },
      }),
      providesTags: ["list"],
    }),

    getShipmentsExcelList: build.query<void, GetShipmentListRequest>({
      query: (params) => ({
        url: "/v2/exporters/this/shipment-excel",
        params: {
          isExporterUserMainCategoryFilterOn: true,
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            manualExcelFileName: "shipment_list.xlsx",
          });
        },
      }),
    }),

    getImporterShipmentList: build.query<
      Rows<ShipmentListViewDto>,
      GetShipmentListRequest
    >({
      query: (params) => ({
        url: "/importers/this/shipments",
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetShipmentListQuery,
  useGetShipmentListQuery,
  useLazyGetShipmentsExcelListQuery,

  useLazyGetShipmentCategoryFilterListQuery,
  useGetShipmentCategoryFilterListQuery,

  useLazyGetImporterShipmentListQuery,
  useGetImporterShipmentListQuery,
} = shipmentListApi;
export { shipmentListApi };
