import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import { SimpleContractDto } from "@/src/store/apis/bookings/bookingDetail/interface";
import colorSet from "@/src/styles/color";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import BreakWordTypo from "@/src/components/molecule/BreakWordTypo";
import { PAYMENT_TERMS_LC_TYPE } from "@/src/pages/exporter/Contract/components/detail/card/ContractInformationCard";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useTranslation } from "react-i18next";

interface SimpleContractProps {
  data: SimpleContractDto;
  index: number;
}

function SimpleContract({ data, index }: SimpleContractProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCardOpen, setIsCardOpen] = useState(true);

  const {
    scNo,
    id,
    buyerId,
    buyerName,
    exporterItem,
    paymentTerms,
    paymentTermsRemark,
    buyerEmployeeName,
    poNo,
    lcNo,
    quantity,
    quantityUnit,
    unitPrice,
    unitPriceUnit,
    allocation,
    placeOfDelivery,
    shippingTerms,
    shippingTermsRemark,
    lastShipmentDateAt,
    freetime,
    origin,
    exporterItemCode,
  } = data;

  return (
    <SectionCard
      cardTitle={`${t(
        "booking:detail.card.contractInformation"
      )} ${addZeroPrefix(index + 1)}`}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={isCardOpen}
          onClick={() => setIsCardOpen((prev) => !prev)}
        />
      }
    >
      {isCardOpen && (
        <SimpleContractContainer>
          {/* Simple Contract Info */}
          <InformationContainer>
            <InformationItem>
              <Typo typoType="b9m" color="gray6">
                SC No.
              </Typo>
              <BlueText
                typoType="h4"
                color="systemBlue2"
                onClick={() =>
                  navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${id}`)
                }
              >
                {scNo}
              </BlueText>
            </InformationItem>

            <InformationItem>
              <Typo typoType="b9m" color="gray6">
                Importer
              </Typo>
              <BlueText
                typoType="h4"
                color="systemBlue2"
                onClick={() =>
                  navigate(
                    `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/${buyerId}`
                  )
                }
              >
                {buyerName}
              </BlueText>
            </InformationItem>

            <InformationItem>
              <Typo typoType="b9m" color="gray6">
                Item
              </Typo>
              <Typo typoType="h4" color="blue2">
                {exporterItem}
              </Typo>
            </InformationItem>

            <InformationItem>
              <Typo typoType="b9m" color="gray6">
                Contact
              </Typo>
              <Typo typoType="h4" color="blue2">
                {buyerEmployeeName}
              </Typo>
            </InformationItem>

            <InformationItem>
              <Typo typoType="b9m" color="gray6">
                Payment Terms
              </Typo>
              <PaymentTermsContainer>
                <BreakWordTypo color="blue2" typoType="h4">
                  {paymentTerms
                    ? `${paymentTerms} ${paymentTermsRemark ?? ""}`
                    : "-"}
                </BreakWordTypo>
              </PaymentTermsContainer>
            </InformationItem>
          </InformationContainer>

          {/* Rows */}
          <InformationDetail>
            <InformationDetailItem>
              <SectionCardRow
                label={"Item Code"}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {exporterItemCode ?? "-"}
                  </BreakWordTypo>
                }
              />
              <SectionCardRow
                label={"PO No."}
                value={
                  <BreakWordTypo typoType="b7r" color="gray5">
                    {poNo ?? "-"}
                  </BreakWordTypo>
                }
              />
              {paymentTerms === PAYMENT_TERMS_LC_TYPE && (
                <SectionCardRow
                  label={"LC No."}
                  value={
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {lcNo ?? "-"}
                    </BreakWordTypo>
                  }
                />
              )}
              <SectionCardRow
                label={"Quantity"}
                value={
                  quantity && quantityUnit ? (
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {`${Number(quantity).toLocaleString("ko-KR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} ${quantityUnit}`}
                    </BreakWordTypo>
                  ) : (
                    "-"
                  )
                }
              />
              <SectionCardRow
                label={"Unit Price"}
                value={
                  unitPrice && unitPriceUnit ? (
                    <BreakWordTypo typoType="b7r" color="gray5">
                      {`${Number(unitPrice).toLocaleString("ko-KR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })} ${unitPriceUnit}`}
                    </BreakWordTypo>
                  ) : (
                    "-"
                  )
                }
              />

              {allocation && (
                <SectionCardRow
                  label={"Allocation"}
                  value={allocation ?? "-"}
                />
              )}
            </InformationDetailItem>
            <GrayLine />
            <InformationDetailItem>
              <SectionCardRow
                label={"Place of Delivery"}
                value={placeOfDelivery ? placeOfDelivery : "-"}
              />
              <SectionCardRow
                label={"Shipping terms"}
                value={
                  shippingTerms ? (
                    <ShippingTerms>
                      <Typo typoType="b7r" color="gray5">
                        {shippingTerms}
                      </Typo>
                      {shippingTermsRemark && (
                        <BreakWordTypo typoType="b7r" color="gray5">
                          {shippingTermsRemark}
                        </BreakWordTypo>
                      )}
                    </ShippingTerms>
                  ) : (
                    "-"
                  )
                }
              />
              <SectionCardRow
                label={"Last Shipment Date"}
                value={
                  lastShipmentDateAt
                    ? dayjs(lastShipmentDateAt).format("YYYY-MM-DD")
                    : "-"
                }
              />
              <SectionCardRow
                label={"Freetime"}
                value={freetime ? freetime : "-"}
              />
              <SectionCardRow
                label={"Origin / Location"}
                value={origin ? origin : "-"}
              />
            </InformationDetailItem>
          </InformationDetail>
        </SimpleContractContainer>
      )}
    </SectionCard>
  );
}

export default SimpleContract;

const SimpleContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InformationContainer = styled.ul`
  display: flex;
  border-radius: 8px;
  background: ${colorSet.gray11};
`;

const InformationItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 16px 8px;
  flex: 1;
`;

const BlueText = styled(Typo)`
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

const PaymentTermsContainer = styled.div`
  text-align: center;
`;

const InformationDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 16px;
  padding: 0 8px;
`;

const InformationDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GrayLine = styled.div`
  width: 1px;
  border-right: 1px solid ${colorSet.gray9};
`;

const ShippingTerms = styled.div`
  display: flex;
  gap: 4px;
`;
