import colorSet, { ColorType } from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

interface SplitBadgeProps extends HTMLAttributes<HTMLSpanElement> {
  size: "xs" | "s";
  color?: ColorType;
  backgroundColor?: ColorType;
}

function RectangularBadge({
  size,
  color = "white",
  backgroundColor = "systemLime1",
  children,
}: SplitBadgeProps) {
  return (
    <StyledBadge size={size} color={color} backgroundColor={backgroundColor}>
      {children}
    </StyledBadge>
  );
}

export default RectangularBadge;

const StyledBadge = styled.span<{
  size: "xs" | "s";
  color: ColorType;
  backgroundColor: ColorType;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ size }) =>
    size === "xs"
      ? css`
          padding: 2px 4px;
          ${typo.b11m}
        `
      : css`
          padding: 2px 6px;
          ${typo.btn5m}
        `};

  color: ${({ color }) => colorSet[color]};
  background: ${({ backgroundColor }) => colorSet[backgroundColor]};
  border-radius: 2px;
  flex-shrink: 0;
  margin-right: 4px;
`;
