import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { useNavigate } from "react-router-dom";
import useAlert from "@/src/hooks/useAlert";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import Popup from "@/src/Popup";
import Dot from "@/src/components/atom/Dot";
import { useLazyGetExporterUserAdditionalInfosQuery } from "@/src/store/apis/auth";
import { useGetMainDomainSummaryInfoQuery } from "@/src/store/apis/dashboard";
import colorSet from "@/src/styles/color";
import CurrencySelect from "./components/CurrencySelect";
import GuideContent from "./components/GuideContent";
import RecentActivitiesCard from "./components/RecentActivitiesCard";
import ShipmentRate from "./components/ShipmentRate";
import Statistics from "./components/Statistics";
import StatisticsCard from "./components/StatisticsCard";
import TaskCalendar from "./components/TaskCalendar";
import VolumeStatistics from "./components/VolumeStatistics";
import DocumentSvg from "@/src/assets/icons/icon-document-blue2.svg";
import CalendarSvg from "@/src/assets/icons/icon-calendar-blue2.svg";
import BoxSvg from "@/src/assets/icons/icon-box-blue2.svg";
import ShipSvg from "@/src/assets/icons/icon-ship-blue2.svg";
import { StyledHorizontalScroll } from "@/src/styles/scroll";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { closeLinkageRequestAlertDialog } from "@/src/store/slice/webUtil";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { closePaymentFailDialog } from "@/src/store/slice/subscription";
import {
  useGetCurrentSubscriptionQuery,
  useGetPlansQuery,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import EnterprisePlanChangeConfirm from "./components/dialog/EnterprisePlanChangeConfirm";
import EnterprisePlanRegisterConfirm from "./components/dialog/EnterprisePlanRegisterConfirm";
import TabItem from "@/src/components/molecule/TabItem";
import { useTranslation } from "react-i18next";

type TabType = "loadingBoard" | "statistics";

const TAB_LIST: {
  id: TabType;
  label: string;
}[] = [
  {
    id: "loadingBoard",
    label: "dashboard:exporter.tabs.loadingStatus",
  },
  {
    id: "statistics",
    label: "dashboard:exporter.tabs.statistics",
  },
];

const today = dayjs();

const ExporterDashboardPage = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLinkageRequestAlertDialogOpen = useAppSelector(
    (state) => state.webUtil.isLinkageRequestAlertDialogOpen
  );
  const {
    isEnterpriseInquiries: isEnterpriseInquiriesDialogOpen,
    isPaymentFail: isPaymentFailDialogOpen,
  } = useAppSelector((state) => state.subscription);

  const [isGuideOpen, setIsGuideOpen] = useState(true);
  const [tab, setTab] = useState<TabType>("loadingBoard");
  const [year, setYear] = useState(today.year());
  const [month, setMonth] = useState(today.month() + 1);
  const [factory, setFactory] = useState<string>();
  const [item, setItem] = useState<string>();
  const [isReady, setIsReady] = useState(false);

  const { currentData, isFetching } = useGetMainDomainSummaryInfoQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { currentData: currentSubscription } = useGetCurrentSubscriptionQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { plans } = useGetPlansQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          plans: isStable ? currentData.rows : [],
        };
      },
    }
  );

  const [getIsGuideNeeded, { isGuideNeeded }] =
    useLazyGetExporterUserAdditionalInfosQuery({
      selectFromResult: ({ data, isFetching }) => ({
        isGuideNeeded: data?.row.isInitialGuideNeeded || false,
        isFetching,
      }),
    });

  const enterprisePlan = plans.filter(({ planType, planVersionList }) => {
    const releaseAt = dayjs(
      dayjs(planVersionList?.[0].releasedAt).format(
        DATE_FORMAT_STRINGS.YYYY_MM_DD
      )
    );
    const duration = releaseAt.diff(dayjs(), "days");
    // 엔터프라이즈, 오늘날짜 기준 반영일자가 포함되는 날
    return planType === "ENTERPRISE" && duration >= 0;
  })[0];

  const renderStatisticsSection = () => {
    return (
      <>
        {[
          {
            cardTitle: t(
              "dashboard:exporter.commonStatistics.processingContract"
            ),
            icon: DocumentSvg,
            numberOfStatistic: isFetching
              ? 0
              : currentData?.processingContract.totalCount,
            rankOfStatistic: isFetching
              ? undefined
              : currentData?.processingContract.buyerRankList.map(
                  ({ buyerNameCode, count }) => ({
                    label: buyerNameCode,
                    value: count,
                  })
                ),
            link: EXPORTER_PRIVATE_PATH.CONTRACT,
            rightTopAccessry: undefined,
          },
          {
            cardTitle: t(
              "dashboard:exporter.commonStatistics.confirmedBooking"
            ),
            icon: CalendarSvg,
            numberOfStatistic: isFetching
              ? 0
              : currentData?.confirmedBooking.totalCount,
            rankOfStatistic: isFetching
              ? undefined
              : currentData?.confirmedBooking.buyerRankList.map(
                  ({ buyerNameCode, count }) => ({
                    label: buyerNameCode,
                    value: count,
                  })
                ),
            link: EXPORTER_PRIVATE_PATH.BOOKING,
            rightTopAccessry: (
              <Typo typoType="b10m" color="gray6" className="ws-prewrap ta-end">
                {t("dashboard:exporter.commonStatistics.excludingPastBookings")}
              </Typo>
            ),
          },
          {
            cardTitle: t("dashboard:exporter.commonStatistics.working"),
            icon: BoxSvg,
            numberOfStatistic: isFetching
              ? 0
              : currentData?.workingTask.totalCount,
            rankOfStatistic: isFetching
              ? undefined
              : currentData?.workingTask.buyerRankList.map(
                  ({ buyerNameCode, count }) => ({
                    label: buyerNameCode,
                    value: count,
                  })
                ),
            link: EXPORTER_PRIVATE_PATH.LOADING,
            rightTopAccessry: undefined,
          },
          {
            cardTitle: t(
              "dashboard:exporter.commonStatistics.workingShippingDocs"
            ),
            icon: ShipSvg,
            numberOfStatistic: isFetching
              ? 0
              : currentData?.ciPlWritingShipment.totalCount,
            rankOfStatistic: isFetching
              ? undefined
              : currentData?.ciPlWritingShipment.buyerRankList.map(
                  ({ buyerNameCode, count }) => ({
                    label: buyerNameCode,
                    value: count,
                  })
                ),
            link: EXPORTER_PRIVATE_PATH.SHIPMENT,
            rightTopAccessry: undefined,
          },
        ].map(
          ({
            cardTitle,
            icon,
            numberOfStatistic,
            rankOfStatistic,
            rightTopAccessry,
            link,
          }) => {
            return (
              <StatisticsCard
                cardTitle={cardTitle}
                icon={icon}
                numberOfStatistic={numberOfStatistic}
                rankOfStatistic={rankOfStatistic}
                rightTopAccessry={rightTopAccessry}
                onClick={() => navigate(link)}
              />
            );
          }
        )}
      </>
    );
  };

  const renderTab = () => {
    return (
      <Tabs role="tablist">
        {TAB_LIST.map(({ id, label }) => {
          return (
            <StyledTabItem
              key={id}
              tabIndex={id === tab ? 0 : -1}
              isSelect={id === tab}
              tabValue={id}
              onClick={() => setTab(id)}
              onFocusItem={(value) => setTab(value as TabType)}
            >
              <Typo typoType="b5m" color={id === tab ? "white" : "gray6"}>
                {t(label)}
              </Typo>
            </StyledTabItem>
          );
        })}
      </Tabs>
    );
  };

  const renderTabContent = () => {
    if (tab === "loadingBoard") {
      return (
        <>
          <SectionCard
            cardTitle={t("dashboard:exporter.recentLoadingActivities")}
            cardContentContainerStyle={{
              overflow: "auto",
              padding: "24px 0",
              margin: "0 16px",
            }}
          >
            <RecentActivitiesCard />
          </SectionCard>

          <SectionCard
            cardTitle={t("dashboard:exporter.loadingCalendar")}
            rightAccessory={
              <>
                <DotList>
                  <p>
                    <Dot dotColor="blue2" />
                    <Typo typoType="b10r" color="gray6">
                      Doc Cut-off
                    </Typo>
                  </p>
                  <p>
                    <Dot dotColor="systemGrape2" />
                    <Typo typoType="b10r" color="gray6">
                      Cargo Cut-off
                    </Typo>
                  </p>
                  <p>
                    <Dot dotColor="green2" />
                    <Typo typoType="b10r" color="gray6">
                      Working Day
                    </Typo>
                  </p>
                  <p>
                    <Dot dotColor="systemOrange2" />
                    <Typo typoType="b10r" color="gray6">
                      ETD
                    </Typo>
                  </p>
                </DotList>
              </>
            }
          >
            <TaskCalendar
              year={year}
              month={month}
              factory={factory}
              item={item}
              onFactoryChange={(factory) => setFactory(factory)}
              onItemChange={(item) => setItem(item)}
              onYearChange={(year) => setYear(year)}
              onMonthChange={(month) => setMonth(month)}
            />
          </SectionCard>
        </>
      );
    }

    if (tab === "statistics") {
      return (
        <>
          <SectionCard cardTitle={t("dashboard:exporter.shipmentRate")}>
            <ShipmentRate />
          </SectionCard>
          <SectionCard
            cardTitle={t("dashboard:exporter.transactionStatistics")}
          >
            <Statistics />
          </SectionCard>
          <SectionCard
            cardTitle={t("dashboard:exporter.transactionStatisticsByPeriod")}
          >
            <VolumeStatistics />
          </SectionCard>
        </>
      );
    }
  };

  const renderLinkageAlertDialog = () => {
    if (isLinkageRequestAlertDialogOpen) {
      return (
        <AlertDialog
          open
          title={t("dashboard:exporter.linkageAlertDialog.title")}
          description={t("dashboard:exporter.linkageAlertDialog.description")}
          onOpenChange={() => dispatch(closeLinkageRequestAlertDialog())}
          onOk={() => {
            dispatch(closeLinkageRequestAlertDialog());
            navigate(EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT);
          }}
          onCancel={() => dispatch(closeLinkageRequestAlertDialog())}
          okText={t("common:ok")}
          cancelText={t("common:cancel")}
        />
      );
    }

    if (isPaymentFailDialogOpen) {
      return (
        <AlertDialog
          open
          title="결제 실패"
          description="결제에 실패했습니다. 결제 정보를 확인해주세요."
          onOpenChange={() => dispatch(closePaymentFailDialog())}
          onOk={() => {
            dispatch(closePaymentFailDialog());
            navigate(EXPORTER_PRIVATE_PATH.SUBSCRIPTION_MANAGEMENT);
          }}
          onCancel={() => dispatch(closePaymentFailDialog())}
          okText="Ok"
          cancelText="Cancel"
        />
      );
    }
  };

  const renderEnterprisePlanDialog = () => {
    // 현재 구독 플랜버전 id, 문의한 플랜의 버전id가 같을시 dialog x
    // -> 문의한 엔터프라이즈를 사용중
    if (
      enterprisePlan?.planVersionList?.[0].id ===
      currentSubscription?.planVersion.id
    ) {
      return null;
    }

    if (
      isEnterpriseInquiriesDialogOpen &&
      currentSubscription?.subscriptionStatus === "PROGRESS" &&
      !!enterprisePlan
    ) {
      // 구독중이면서 엔터프라이즈 문의를 했을경우
      // 유효기간이 지나지 않은 엔터프라이즈 플랜이 생성되었을 떄
      return <EnterprisePlanChangeConfirm />;
    }

    // 현재 미구독이지만 구독 이력이 있는경우 엔터프라이즈 문의를 했을경우
    // 유효기간이 지나지 않은 엔터프라이즈 플랜이 생성되었을 떄
    if (
      isEnterpriseInquiriesDialogOpen &&
      currentSubscription?.subscriptionStatus !== "PROGRESS" &&
      !!enterprisePlan
    ) {
      return <EnterprisePlanRegisterConfirm />;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getIsGuideNeeded().unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        alert.showAlert({ type: "error", message });
      } finally {
        setIsReady(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:home")]}
      customPageTitle={
        <>
          {!isReady || (isGuideNeeded && isGuideOpen) ? null : (
            <StyledHeader>
              <FlexRowCenter>
                <Typo as="h1" typoType="h1" color="gray1">
                  Today
                </Typo>

                <Typo typoType="b7m" color="gray6">
                  {dayjs().format("YYYY.MM.DD ddd")}
                </Typo>
              </FlexRowCenter>

              <CurrencySelect />
            </StyledHeader>
          )}
        </>
      }
    >
      {!isReady ? null : isGuideNeeded && isGuideOpen ? (
        <GuideContent onGuideClose={() => setIsGuideOpen(false)} />
      ) : (
        <FlexColumn className="gap-24">
          <StatisticsSection>{renderStatisticsSection()}</StatisticsSection>

          {renderTab()}

          {renderTabContent()}
        </FlexColumn>
      )}
      <Popup />
      {renderLinkageAlertDialog()}
      {renderEnterprisePlanDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterDashboardPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  &.gap-24 {
    gap: 24px;
  }
`;

const StyledHeader = styled.header`
  padding: 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StatisticsSection = styled.section`
  display: flex;
  gap: 16px;
  overflow: auto;
  padding: 16px;
  margin: 0 -16px;

  .ws-prewrap {
    white-space: pre-wrap;
  }

  .ta-end {
    text-align: end;
  }

  ${StyledHorizontalScroll}
  &::-webkit-scrollbar-track {
    margin: 16px;
  }
`;

const Tabs = styled.div`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-right: 1px solid ${colorSet.gray9};
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};
  border: 1px solid ${colorSet.gray9};

  &:last-child {
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const DotList = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  > p {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
