import { utcNow } from "@/src/utils/utcDayjs";
import dayjs from "dayjs";

interface DateDifference {
  updateAt: string;
  maxCountDay?: number;
  disableUtcNow?: boolean;
  disableUtcUpdateAt?: boolean;
}

const getDateDifference = (param: DateDifference) => {
  const {
    updateAt: updateAtParam,
    maxCountDay = 8,
    disableUtcNow = false,
    disableUtcUpdateAt = false,
  } = param;

  if (!updateAtParam) {
    return "-";
  }

  const cur = disableUtcNow ? dayjs() : utcNow();
  const updateAt = disableUtcUpdateAt
    ? dayjs(updateAtParam)
    : dayjs.utc(updateAtParam);

  const diffMinute = cur.diff(updateAt, "minute");
  const diffHour = cur.diff(updateAt, "hour");
  const diffDay = cur.diff(updateAt, "day");

  // 8일 이상
  if (diffDay >= maxCountDay) {
    return updateAt.format("YYYY-MM-DD");
  }

  // 하루 이상
  if (diffDay >= 1) {
    return `${diffDay} days ago`;
  }

  //  1 시간 이상
  if (diffHour >= 1) {
    return diffHour === 1
      ? `${Math.floor(diffHour)} hour ago`
      : `${Math.floor(diffHour)} hours ago`;
  }

  // 1 시간 이하
  if (diffMinute > 1) {
    return `${diffMinute} minutes ago`;
  }

  return "1 minute ago";
};

export default getDateDifference;
