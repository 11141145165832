import React from "react";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { useTranslation } from "react-i18next";

interface RedoAlertDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onOk: () => void;
}

function RedoAlertDialog({ open, onOpenChange, onOk }: RedoAlertDialogProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog
      open={open}
      title={t("contract:importer.detail.alert.redoTitle")}
      onOpenChange={onOpenChange}
      onOk={onOk}
      okText={t("contract:importer.detail.button.okButton")}
      cancelText={t("contract:importer.detail.button.cancelButton")}
    >
      {t("contract:importer.detail.alert.redoDescription")}
    </AlertDialog>
  );
}

export default RedoAlertDialog;
