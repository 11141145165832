import React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/src/store";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import { ReactComponent as NotFoundSvg } from "@/src/assets/icons/icon-not-found.svg";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import { Button } from "@/src/components/atom/Button";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

const ImporterNotFoundPage = () => {
  const token = useAppSelector((state) => state.auth.token);
  const lang = useAppSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const isValid = !!token;

  if (isValid) {
    return (
      <ImporterMainLayout>
        <FlexColumnCenter>
          <NotFoundIcon />
          <NotFoundTitle>Not Found</NotFoundTitle>
          <Typo color="gray2" typoType="b4m">
            {lang === "ko"
              ? `페이지를 찾을 수 없습니다.\n페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.`
              : `Page not found.\nThe page does not exist or is not available.`}
          </Typo>

          <Flex>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              buttonSize={40}
              style={{ width: "158px" }}
              onClick={() => navigate(EXPORTER_PRIVATE_PATH.HOME)}
            >
              Back to Home
            </Button>
            <Button
              style={{ width: "158px" }}
              onClick={() => window.history.back()}
            >
              Back to Previous
            </Button>
          </Flex>
        </FlexColumnCenter>
      </ImporterMainLayout>
    );
  }

  return (
    <FlexColumnCenter style={{ height: "100vh" }}>
      <NotFoundIcon />
      <NotFoundTitle>Not Found</NotFoundTitle>
      <Typo color="gray2" typoType="b4m">
        <Typo color="gray2" typoType="b4m">
          {lang === "ko"
            ? `페이지를 찾을 수 없습니다.\n페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.`
            : `Page not found.\nThe page does not exist or is not available.`}
        </Typo>
      </Typo>

      <Flex>
        <Button
          style={{ width: "158px" }}
          onClick={() => window.history.back()}
        >
          Back to Previous
        </Button>
      </Flex>
    </FlexColumnCenter>
  );
};

export default ImporterNotFoundPage;

const FlexColumnCenter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const NotFoundIcon = styled(NotFoundSvg)`
  width: 283px;
  height: 172px;
`;

const NotFoundTitle = styled.h1`
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  font-family: "Pretendard Bold";
  line-height: normal;
  color: ${colorSet.gray2};
`;

const Flex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 40px;
`;
