import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import Table from "@/src/components/atom/Table";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { useAppSelector } from "@/src/store";
import { useLazyGetNoticesQuery } from "@/src/store/apis/notice";
import colorSet from "@/src/styles/color";
import { columnNoticeList } from "./columns/columnNoticeList";
import useFilterSearch from "@/src/hooks/useFilterSearch";
import { useTranslation } from "react-i18next";

const ExporterNoticePage = () => {
  const { t } = useTranslation();
  const { value: lang } = useAppSelector((state) => state.lang);
  const ref = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [getNotices, noticesResult] = useLazyGetNoticesQuery();

  const {
    state: { pagination },
    func: { onPaginationChange },
  } = useFilterSearch({
    isReady,
    gridRef: ref.current,
    fetch: getNotices,
    defaultFilterData: {},
  });
  useEffect(() => {
    if (ref.current) {
      if (noticesResult.isFetching) {
        ref.current.api.showLoadingOverlay();
      }
    }
  }, [noticesResult.isFetching]);

  useEffect(() => {
    if (ref.current && isReady) {
      ref.current.api.setColumnDefs(columnNoticeList(lang));
    }
  }, [lang, isReady]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:notice")]}
      pageTitle={t("sideNav:notice")}
    >
      <Card>
        <Table
          ref={ref}
          onGridReady={() => setIsReady(true)}
          columnDefs={columnNoticeList(lang)}
          rowData={
            noticesResult.isSuccess ? noticesResult.currentData?.rows : []
          }
          totalPage={noticesResult?.currentData?.count}
          onRowClicked={({ data }) => {
            navigate(`${EXPORTER_PRIVATE_PATH.NOTICE}/${data.id}`);
          }}
          isPaginationDataMaping
          pageSize={pagination.pageSize}
          page={pagination.page}
          handlePaginationClick={(page, pageSize) =>
            onPaginationChange({ page, pageSize })
          }
        />
      </Card>
    </ExporterMainLayout>
  );
};

export default ExporterNoticePage;

const Card = styled.div`
  border-radius: 16px;
  background: ${colorSet.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding: 8px;
`;
