import { Button } from "@/src/components/atom/Button";
import Checkbox from "@/src/components/atom/Checkbox";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Label from "@/src/components/atom/Label";
import Radio from "@/src/components/atom/Radio";
import Typo from "@/src/components/atom/Typo";
import CallOut from "@/src/components/molecule/CallOut";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet from "@/src/styles/color";
import React, { useState } from "react";
import styled from "styled-components";
import { Radio as AntdRadio } from "antd";
import {
  subscriptionApi,
  useGetCancelFeeQuery,
  useUpdateSubscriptionMutation,
} from "@/src/store/apis/subscription";
import { isUndefined } from "@/src/utils/is";
import dayjs from "dayjs";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import {
  PaymentCurrencyUnitType,
  SubscriptionStatusType,
} from "@/src/store/apis/subscription/interface";
import { SubscriptionDialogState } from "../..";
import {
  getAmountOfPayment,
  getCurrencyCancelFee,
} from "../../utils/getPayments";
import Flex from "@/src/components/molecule/Flex";
import { useForm } from "react-hook-form";
import FormItem from "@/src/components/molecule/FormItem";
import { useTranslation } from "react-i18next";

export type UnsubscribeType = "EXPIRATION" | "IMMEDIATE";

interface UnsubscribeDialogProps {
  open: boolean;
  onOpenChange: (value: SubscriptionDialogState) => void;
  onSave: (type: UnsubscribeType) => void;
}

function UnsubscribeDialog({
  open,
  onOpenChange,
  onSave,
}: UnsubscribeDialogProps) {
  const { t } = useTranslation();
  const alert = useAlert();

  const [isConfirm, setIsConfirm] = useState(false);
  const [unsubscribeType, setUnsubscribeType] =
    useState<UnsubscribeType>("EXPIRATION");

  // API
  const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();
  const { subscriptionId } =
    subscriptionApi.endpoints.getSubscriptions.useQueryState(
      {
        page: 1,
        pageSize: 10,
      },
      {
        selectFromResult: ({ currentData }) => {
          return {
            subscriptionId: currentData?.rows?.[0].id,
          };
        },
      }
    );

  const { planName, subscriptionType, subscriptionEndAtTo, paymentList } =
    subscriptionApi.endpoints.getSubscription.useQueryState(
      {
        id: subscriptionId as number,
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError || isFetching;
          const isStable = !isUnstable;

          return {
            subscriptionType: currentData?.subscriptionType,
            planType: currentData?.plan.planType,
            planName: isStable ? currentData?.plan.name : "-",
            paymentCycle: currentData?.paymentCycle,
            annualFee: currentData?.planVersion.annualFee ?? "",
            monthlyFee: currentData?.planVersion.monthlyFee ?? "",
            numberOfMember: currentData?.planVersion.numberOfMember,
            subscriptionEndAtTo: currentData?.subscriptionEndAtTo,
            paymentList: isStable ? currentData.paymentList : [],
          };
        },
      }
    );

  const { cancellationFee, usedDays, penalty } = useGetCancelFeeQuery(
    {
      id: subscriptionId as number,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isFetching,
          cancellationFee: isStable ? currentData?.row.cancellationFee : "-",
          usedDays: isStable ? currentData?.row.usedDays : 0,
          penalty: isStable ? currentData?.row.penalty : 0,
        };
      },
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{
    refundAccountName: string;
    refundBankName: string;
    refundAccountNo: string;
    estimatedRefundDateAt?: string;
    refundBankAddress?: string;
    swiftCode?: string;
    memo?: string;
  }>({
    mode: "onBlur",
    defaultValues: {
      refundAccountName: "",
      refundBankName: "",
      refundAccountNo: "",
      estimatedRefundDateAt: undefined,
      refundBankAddress: undefined,
      swiftCode: undefined,
      memo: undefined,
    },
  });

  const isCardCancelType = usedDays < 180;
  const isFreePlan = subscriptionType === "FREE";
  const payment = paymentList?.find(
    (item) => item.paymentStatus === "PAYMENT_SUCCESS"
  );

  const handleUnsubscribeClick = async () => {
    if (!subscriptionId) {
      return;
    }

    /**
     * @description 구독 즉시 해지
     * - 카드취소 (180일 이전)
     * - 국내계좌 (180일 이후)
     * - 해외계좌 (180일 이후)
     */
    const immediateData = isCardCancelType
      ? {
          // 카드취소
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED" as SubscriptionStatusType,
        }
      : {
          // 계좌정보 (국내, 해외)
          subscriptionEndAtTo: dayjs().toISOString(),
          isImmediateCancel: true,
          subscriptionStatus: "CANCELED" as SubscriptionStatusType,
          refundAccountType:
            payment?.currencyUnit === "KRW" ? "KOREA" : "OTHER",
          refundAccountName: getValues("refundAccountName") || undefined,
          refundBankName: getValues("refundBankName") || undefined,
          refundAccountNo: getValues("refundAccountNo") || undefined,
          estimatedRefundDateAt:
            getValues("estimatedRefundDateAt") || undefined,
          refundBankAddress: getValues("refundBankAddress") || undefined,
          swiftCode: getValues("swiftCode") || undefined,
          memo: getValues("memo") || undefined,
        };

    // 기간 만기 해지
    const expirationData = {
      isImmediateCancel: false,
      subscriptionStatus: "CANCELED" as SubscriptionStatusType,
    };

    const params =
      unsubscribeType === "EXPIRATION" ? expirationData : immediateData;

    try {
      await updateSubscription({
        id: subscriptionId,
        ...params,
      }).unwrap();

      if (unsubscribeType === "EXPIRATION") {
        onSave("EXPIRATION");
        onOpenChange(SubscriptionDialogState.EXPIRATION);
      }
      if (unsubscribeType === "IMMEDIATE") {
        onSave("IMMEDIATE");
        onOpenChange(SubscriptionDialogState.IMMEDIATE);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data?.message)
        ? e.data?.message[0]
        : e.data?.message;
      alert.showAlert({
        type: "error",
        message: message,
      });
    }
  };

  const price = Math.floor(
    getAmountOfPayment({
      exchangeRate: Number(payment?.exchangeRate),
      price: Number(payment?.price),
      currencyUnit: payment?.currencyUnit as PaymentCurrencyUnitType,
      isFree: isFreePlan,
    })
  );

  const cancelFee = Math.floor(
    getCurrencyCancelFee({
      cancellationFee,
      currencyUnit: payment?.currencyUnit as PaymentCurrencyUnitType,
      exchangeRate: payment?.exchangeRate as number,
    })
  );

  const currencyPenalty = () => {
    if (payment?.currencyUnit && payment.exchangeRate) {
      return payment?.currencyUnit === "USD"
        ? Math.floor(penalty)
        : Math.floor(penalty * payment.exchangeRate);
    }
    return 0;
  };

  const getConfirmButtonDisabled = () => {
    // 남은기간 만료 해지
    if (unsubscribeType === "EXPIRATION") {
      return !isConfirm;
    }

    // 즉시 해지
    if (unsubscribeType === "IMMEDIATE") {
      return !isConfirm && !!errors;
    }
  };

  const renderRefundAccountType = () => {
    if (isCardCancelType) {
      return null;
    }

    const isKoreaType = payment?.currencyUnit === "KRW";
    const isOtherType = payment?.currencyUnit === "USD";

    // 국내계좌 환불
    if (isKoreaType) {
      return (
        <Flex flexDirection="column" gap={16}>
          <FormItem
            label={t("subscriptionManagement:subscription.accountName")}
            name="refundAccountName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.accountNamePlaceholder"
              ),
            }}
            rules={{
              required: isKoreaType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.bankName")}
              name="refundBankName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.bankNamePlaceholder"
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder"
                ),
              }}
              rules={{
                required: isKoreaType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
        </Flex>
      );
    }

    // 해외계좌 환불
    if (isOtherType) {
      return (
        <Flex flexDirection="column" gap={16}>
          <Flex gap={16}>
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountName")}
              name="refundAccountName"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNamePlaceholder"
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
            <StyledFormItem
              label={t("subscriptionManagement:subscription.accountNo")}
              name="refundAccountNo"
              type="text"
              control={control as any}
              direction="vertical"
              inputProps={{
                placeholder: t(
                  "subscriptionManagement:subscription.accountNoPlaceholder"
                ),
              }}
              rules={{
                required: isOtherType,
              }}
              errorsMessage={{
                required: t("error:required"),
              }}
            />
          </Flex>
          <FormItem
            label={t("subscriptionManagement:subscription.bankName")}
            name="refundBankName"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: t(
                "subscriptionManagement:subscription.bankNamePlaceholder"
              ),
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label="Bank Address"
            name="refundBankAddress"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: "Enter Bank Address",
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label="SWIFT Code"
            name="swiftCode"
            type="text"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: "Enter SWIFT / BIC Code",
            }}
            rules={{
              required: isOtherType,
            }}
            errorsMessage={{
              required: t("error:required"),
            }}
          />
          <FormItem
            label="Memo"
            name="memo"
            type="textarea"
            control={control}
            direction="vertical"
            inputProps={{
              placeholder: "Enter Memo",
              className: "resize-none",
              maxLength: 140,
            }}
          />
        </Flex>
      );
    }
  };

  return (
    <Dialog
      title={t("subscriptionManagement:subscription.unsubscribe")}
      open={open}
      onOpenChange={() => onOpenChange(SubscriptionDialogState.NULL)}
      width={640}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => onOpenChange(SubscriptionDialogState.NULL)}
          >
            {t("subscriptionManagement:subscription.button.cancel")}
          </Button>
          <Button
            onClick={
              unsubscribeType === "EXPIRATION"
                ? handleUnsubscribeClick
                : handleSubmit(handleUnsubscribeClick)
            }
            disabled={getConfirmButtonDisabled() || isLoading}
            isLoading={isLoading}
          >
            <Typo typoType="btn3m" color="white">
              {t(
                "subscriptionManagement:subscription.button.unsubscribeConfirm"
              )}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      <FlexColumn gap={40}>
        <FlexColumn gap={12}>
          <CenterTypo typoType="h2">
            {t(
              "subscriptionManagement:subscription.pleaseCheckTheCancellationInformation"
            )}
          </CenterTypo>
          <CenterTypo typoType="b7m">
            {t(
              "subscriptionManagement:subscription.pleaseCheckTheCancellationInformationDescription"
            )}
          </CenterTypo>
        </FlexColumn>

        <FlexColumn gap={24}>
          <CallOut>
            <FlexColumn gap={16}>
              <SectionCardRow
                label={t(
                  "subscriptionManagement:subscription.subscriptionPlan"
                )}
                value={planName}
              />
              <SectionCardRow
                label={t("subscriptionManagement:subscription.price")}
                value={`${payment?.currencyUnit || ""} ${price.toLocaleString(
                  "ko-KR",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
              />
              <SectionCardRow
                label={t("subscriptionManagement:subscription.applicationDate")}
                value={dayjs().format(DATE_FORMAT_STRINGS.YYYY_MM_DD)}
              />
            </FlexColumn>
          </CallOut>

          <RadioGroupContainer>
            <Label required>
              {t("subscriptionManagement:subscription.selectWhenToTerminate")}
            </Label>
            <StyledRadioGroup
              value={unsubscribeType}
              onChange={(e) => setUnsubscribeType(e.target.value)}
            >
              <Radio value="EXPIRATION" id="unsubscribeType">
                <RadioItem>
                  <Typo typoType="b7r" color="gray4">
                    {t(
                      "subscriptionManagement:subscription.afterTheRestOfPeriod"
                    )}
                  </Typo>
                  <Typo typoType="b7r">
                    ({t("subscriptionManagement:subscription.expirationDate")}:{" "}
                    {dayjs(subscriptionEndAtTo).format(
                      DATE_FORMAT_STRINGS.YYYY_MM_DD
                    )}
                    )
                  </Typo>
                </RadioItem>
              </Radio>

              <Radio value="IMMEDIATE" id="unsubscribeType">
                <RadioItem>
                  <Typo typoType="b7r" color="gray4">
                    {t(
                      "subscriptionManagement:subscription.immediateTermination"
                    )}
                  </Typo>
                  <Typo typoType="b7r">
                    ({t("subscriptionManagement:subscription.expirationDate")}:{" "}
                    {dayjs().format(DATE_FORMAT_STRINGS.YYYY_MM_DD)})
                  </Typo>
                </RadioItem>
              </Radio>
            </StyledRadioGroup>
          </RadioGroupContainer>

          <Divider />

          {/* 즉시 해지 */}
          {unsubscribeType === "IMMEDIATE" && (
            <Flex flexDirection="column" gap={16}>
              <CallOut backgroundColor="white" borderColor="gray9">
                <SectionCardRow
                  label={t(
                    "subscriptionManagement:subscription.expectedRefundAmount"
                  )}
                  value={
                    <Flex flexDirection="column" gap={8}>
                      <Typo typoType="b7r" color="gray5">
                        {`${payment?.currencyUnit || ""} ${(
                          cancelFee || 0
                        ).toLocaleString("ko-KR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} ${t(
                          "subscriptionManagement:subscription.cancellationCharge"
                        )}`}
                      </Typo>
                      <Typo typoType="b9r" color="gray5">
                        {t("subscriptionManagement:subscription.price")} (
                        {payment?.currencyUnit}
                        {payment?.currencyUnit && " "}
                        {`${price.toLocaleString("ko-KR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        ) -{" "}
                        {t(
                          "subscriptionManagement:subscription.cancellationChargeInfo"
                        )}{" "}
                        ({payment?.currencyUnit}
                        {payment?.currencyUnit && " "}
                        {currencyPenalty().toLocaleString("ko-KR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        )
                      </Typo>
                    </Flex>
                  }
                />
              </CallOut>
              {renderRefundAccountType()}
            </Flex>
          )}

          <CheckboxContainer>
            <Checkbox
              checked={isConfirm}
              onChange={() => setIsConfirm((prev) => !prev)}
            />
            <Typo typoType="b7r">
              {t("subscriptionManagement:subscription.unsubscribeConfirm")}
            </Typo>
          </CheckboxContainer>
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  );
}

export default UnsubscribeDialog;

const FlexColumn = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap}px;
`;

const CenterTypo = styled(Typo)`
  text-align: center;
  white-space: pre-wrap;
`;

const CheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    margin-top: 3px;
    flex-shrink: 0;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledRadioGroup = styled(AntdRadio.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
`;
