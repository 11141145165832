import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useForm } from "react-hook-form";
import { styled } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import AlertDialog from "@/src/components/atom/AlertDialog";
import Table from "@/src/components/atom/Table";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem, { FormItemProps } from "@/src/components/molecule/FormItem";
import FormInputPropsWithInputType from "@/src/components/molecule/FormItem/type";
import SectionCard from "@/src/components/molecule/SectionCard";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import useAlert from "@/src/hooks/useAlert";
import { addFactoryContactPersonListColumn } from "./columns/column";
import { GenerateWorkplaceEmployeeDto } from "@/src/store/apis/client/factory/interface";
import { createDeleteAction, editAction } from "@/src/utils/row-data-util";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ContactPersonAddDialog from "./dialog/ContactPersonAddDialog";
import { isUndefined } from "@/src/utils/is";
import ContactPersonEditDialog, {
  ContactPersonEditFormType,
} from "./dialog/ContactPersonEditDialog";
import CountryWithTimezoneFormItem from "@/src/components/molecule/CountryWithTimezoneFormItem";
import ImporterMainLayout from "@/src/components/template/Layout/importer/ImporterMainLayout";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useCreateWarehousesMutation } from "@/src/store/apis/warehouse";
import { useTranslation } from "react-i18next";

type BasicInformationType = {
  countryCodeItemName: string;
  warehouseName: string;
  isActivated: string;
  apartment: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  mainCategoryCodeItemNames: string[];
  businessNumber: string;
  businessRegistration: any;
  telPrefix: string;
  tel: string;
  faxPrefix: string;
  fax: string;
  timezoneCity: string;
  timezoneGmtOffset: number;
};

type ContactPersonAddModalFormType = {
  name: string;
  email: string;
  personalContactPrefix: string;
  personalContact: string;
  officeContactPrefix: string;
  officeContact: string;
  isActivated: string;
};

enum AlertDialogState {
  NULL,
  REGISTER_CONTACT_PERSON,
  BACK_TO_PREVIOUS,
}

enum DialogState {
  NULL,
  ADD_CONTACT_PERSON,
  EDIT_CONTACT_PERSON,
}

const ImporterWarehouseManagementAddPage = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const gridRef = useRef<AgGridReact>(null);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(addFactoryContactPersonListColumn);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [editedPersonUuid, setEditedPersonUuid] = useState<string | null>(null);
  const [contactPersonList, setContactPersonList] = useState<
    (GenerateWorkplaceEmployeeDto & { uniqueId: string })[]
  >([]);

  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors: basicInformationErrors },
    clearErrors,
    setFocus,
  } = useForm<BasicInformationType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      countryCodeItemName: "",
      warehouseName: "",
      isActivated: "Y",
      apartment: "",
      city: "",
      state: "",
      postal: "",
      country: "",
      mainCategoryCodeItemNames: [],
      businessNumber: "",
      businessRegistration: [],
      telPrefix: "",
      tel: "",
      faxPrefix: "",
      fax: "",
      timezoneCity: "",
      timezoneGmtOffset: 0,
    },
  });

  const { countryList } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "COUNTRY",
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isFetching || isError || isUndefined(currentData);

        return {
          isFetching,
          countryList: !isUnstable ? currentData : [],
        };
      },
    }
  );

  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: !isUnstable ? currentData ?? [] : [],
          };
        },
      }
    );

  const [registerWarehouse] = useCreateWarehousesMutation();

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "warehouseManagement:common.name",
      },
      {
        columnKey: "email",
        langKey: "warehouseManagement:common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "warehouseManagement:common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "warehouseManagement:common.officeContact",
      },
      {
        columnKey: "isActivated",
        langKey: "warehouseManagement:common.use",
      },
      { columnKey: "edit", langKey: "warehouseManagement:common.edit" },
      { columnKey: "delete", langKey: "warehouseManagement:common.delete" },
    ],
  });

  const mainCategoryOptionList = mainCategory.map((item) => {
    return {
      label: item.codeItemNameEn,
      value: item.codeItemName,
    };
  });

  const basicInformationFormItemList: (Partial<
    FormItemProps<BasicInformationType, keyof BasicInformationType> &
      FormInputPropsWithInputType
  > & {
    customRender?: () => ReactNode;
  })[] = [
    {
      label: t("warehouseManagement:common.warehouseName"),
      type: "text",
      name: "warehouseName",
      control: control,
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.warehouseName"),
      },
    },
    {
      customRender: () => (
        <StyledCountryWithTimezoneFormItem
          label={t("signupCorp:content.countryAndCity")}
          register={register as any}
          setValue={setValue as any}
          isRequired={true}
          countryName="countryCodeItemName"
          countryValue={watch("countryCodeItemName") || undefined}
          cityName="timezoneCity"
          cityValue={watch("timezoneCity") || undefined}
          gmtOffsetName="timezoneGmtOffset"
          errorMessage={t("error:required")}
          onCountryChange={(value) => {
            const prefix = countryList.find(
              ({ codeItemName }) => codeItemName === value
            )?.value1;
            setValue("telPrefix", prefix || "");
            setValue("faxPrefix", prefix || "");
          }}
        />
      ),
    },
    {
      label: t("warehouseManagement:common.categoryInCharge"),
      type: "multipleSelect",
      name: "mainCategoryCodeItemNames",
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      control: control,
      options: mainCategoryOptionList,
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.categoryInCharge"),
        suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
        disabled: isMainCategoryFetching,
        onRemoveItem: (item) => {
          setValue(
            "mainCategoryCodeItemNames",
            getValues("mainCategoryCodeItemNames").filter(
              (mainCategoryItem) => mainCategoryItem !== item
            )
          );
        },
      },
    },
    {
      label: t("warehouseManagement:common.businessNumber"),
      type: "text",
      name: "businessNumber",
      control: control,
      inputProps: {
        placeholder: t("warehouseManagement:placeholder.businessNumber"),
      },
    },
    {
      label: t("warehouseManagement:common.businessRegistration"),
      type: "file",
      name: "businessRegistration",
      control: control,
      inputProps: {
        defaultFileList: watch("businessRegistration").length
          ? [
              new File(
                [""],
                watch("businessRegistration")?.[0]?.originalFileName ?? ""
              ),
            ]
          : undefined,
        onRemove: () => setValue("businessRegistration", []),
      },
    },
    {
      label: t("warehouseManagement:common.use"),
      type: "radioGroup",
      name: "isActivated",
      control: control,
      rules: { required: true },
      options: [
        { label: "Y", value: "Y" },
        { label: "N", value: "N" },
      ],
    },
  ];

  const contactPersonListToRowData = contactPersonList
    .map((person, idx) => {
      return {
        no: idx + 1,
        ...person,
      };
    })
    .filter((item, idx) => {
      const endIndex = pagination.page * pagination.pageSize - 1;
      const startIndex =
        pagination.page * pagination.pageSize - pagination.pageSize;
      return idx >= startIndex && idx <= endIndex;
    });

  const handleDetailClick = (data: any) => {
    setEditedPersonUuid(data.uniqueId);
    setDialogState(DialogState.EDIT_CONTACT_PERSON);
  };

  const handleDeleteClick = (data: any) => {
    setContactPersonList((prev) =>
      prev.filter(({ uniqueId }) => uniqueId !== data.uniqueId)
    );
  };

  const handlerRegister = async () => {
    if (contactPersonList.length === 0) {
      return setIsAlertDialogOpen(AlertDialogState.REGISTER_CONTACT_PERSON);
    }

    const registerInfoValues = getValues();
    const countryCodeItemName =
      countryList.find(
        ({ codeItemName }) =>
          codeItemName === registerInfoValues.countryCodeItemName
      )?.codeItemName ?? "";
    try {
      const { row } = await registerWarehouse({
        countryCodeItemName,
        mainCategoryCodeItemNames: registerInfoValues.mainCategoryCodeItemNames,
        warehouseName: registerInfoValues.warehouseName,
        isActivated: registerInfoValues.isActivated === "Y",
        warehouseCountryName: registerInfoValues.country,
        warehouseRegion: registerInfoValues.state || undefined,
        warehouseLocality: registerInfoValues.city || undefined,
        warehouseStreetAddress: registerInfoValues.apartment || undefined,
        warehousePostalCode: registerInfoValues.postal || undefined,
        telPrefix: registerInfoValues.telPrefix,
        faxPrefix: registerInfoValues.faxPrefix,
        warehouseEmployees: contactPersonList.map((person) => ({
          ...person,
          officeContactPrefix: person.officeContactPrefix || undefined,
          officeContact: person.officeContact || undefined,
        })),
        tel: registerInfoValues.tel ? registerInfoValues.tel : undefined,
        fax: registerInfoValues.fax ? registerInfoValues.fax : undefined,
        businessNumber: registerInfoValues.businessNumber || undefined,
        businessRegistrationMediaId: registerInfoValues.businessRegistration
          .length
          ? registerInfoValues.businessRegistration[0].id
          : undefined,
        city: registerInfoValues.timezoneCity,
        gmtOffset: registerInfoValues.timezoneGmtOffset,
      }).unwrap();
      navigate(
        `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_DETAIL}/${row.id}`
      );
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddContactPerson = (
    modalValue: Omit<ContactPersonAddModalFormType, "isActivated"> & {
      isActivated: boolean;
    }
  ) => {
    setContactPersonList((prev) => [
      ...prev,
      {
        ...modalValue,
        uniqueId: `${uuidv4()}`,
      },
    ]);
    setDialogState(DialogState.NULL);
  };

  const handleEditContactPerson = (values: ContactPersonEditFormType) => {
    setContactPersonList((prev) =>
      prev.map((person) =>
        person.uniqueId === editedPersonUuid
          ? {
              uniqueId: person.uniqueId,
              ...values,
            }
          : person
      )
    );
    setDialogState(DialogState.NULL);
    setEditedPersonUuid(null);
  };

  useEffect(() => {
    setFocus("warehouseName");
  }, [setFocus]);

  const renderAlertDialog = () => {
    if (isAlertDialogOpen === AlertDialogState.NULL) return null;
    if (isAlertDialogOpen === AlertDialogState.REGISTER_CONTACT_PERSON) {
      return (
        <AlertDialog
          open
          title={t(
            "warehouseManagement:factoryListAddPage.alertDialog.registerOneContactPerson.title"
          )}
          onOpenChange={() => setIsAlertDialogOpen(AlertDialogState.NULL)}
          onOk={() => {
            setDialogState(DialogState.ADD_CONTACT_PERSON);
            setIsAlertDialogOpen(AlertDialogState.NULL);
          }}
          okText={t("warehouseManagement:common.ok")}
          cancelText={t("warehouseManagement:common.exit")}
        >
          {t(
            "warehouseManagement:factoryListAddPage.alertDialog.registerOneContactPerson.description"
          )}
        </AlertDialog>
      );
    }
    if (isAlertDialogOpen === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t(
            "warehouseManagement:factoryListAddPage.alertDialog.backToPrevious.title"
          )}
          onOpenChange={() => setIsAlertDialogOpen(AlertDialogState.NULL)}
          onOk={() => navigate(-1)}
          okText={t("warehouseManagement:common.ok")}
          cancelText={t("warehouseManagement:common.exit")}
        >
          {t(
            "warehouseManagement:factoryListAddPage.alertDialog.backToPrevious.description"
          )}
        </AlertDialog>
      );
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      /**
       * countryCodeItemName => codeNameKo 의미
       */
      const defaultCountry = countryList.find(
        ({ codeItemName }) => getValues("countryCodeItemName") === codeItemName
      );

      return (
        <ContactPersonAddDialog
          open
          defaultCountryCode={defaultCountry?.value1}
          onSave={handleAddContactPerson}
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
    if (dialogState === DialogState.EDIT_CONTACT_PERSON) {
      const editPerson = contactPersonList.find(
        ({ uniqueId }) => uniqueId === editedPersonUuid
      );
      if (editPerson) {
        return (
          <ContactPersonEditDialog
            open
            onSave={handleEditContactPerson}
            onOpenChange={() => setDialogState(DialogState.NULL)}
            defaultContactPersonInfo={{
              ...editPerson,
              isActivated: editPerson.isActivated ? "Y" : "N",
            }}
          />
        );
      }
    }
  };

  return (
    <ImporterMainLayout
      breadcrumb={[
        "...",
        t("sideNav:warehouseManagement"),
        t("sideNav:warehouseRegistration"),
      ]}
      pageTitle={t("sideNav:warehouseRegistration")}
    >
      <SectionContainer>
        <SectionCard
          cardTitle={t("warehouseManagement:common.basicInformation")}
        >
          <StyledForm onSubmit={handleSubmit(handlerRegister)}>
            <HalfDiv>
              {basicInformationFormItemList.map(
                ({
                  label,
                  type,
                  name,
                  control,
                  options,
                  rules,
                  inputProps,
                  disabled,
                  errorsMessage,
                  customRender,
                }) => {
                  if (customRender) {
                    return customRender();
                  }

                  return (
                    <StyledFormItem
                      label={label}
                      type={type as any}
                      name={name as keyof BasicInformationType}
                      control={control as any}
                      options={options ?? []}
                      inputProps={inputProps as any}
                      rules={rules}
                      disabled={disabled}
                      errorsMessage={errorsMessage}
                    />
                  );
                }
              )}
            </HalfDiv>
            <HalfDiv>
              <StyledAddressFormItem
                label={t("signupCorp:content.address")}
                register={register as any}
                isRequired
                setValue={setValue as any}
                error={basicInformationErrors}
                clearErrors={clearErrors}
              />
              <StyledContactFormItem
                label={t("signupCorp:content.contact")}
                prefixName="telPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="contact"
                prefixValue={watch("telPrefix") || undefined}
                isRequired={false}
              />
              <StyledContactFormItem
                label={t("warehouseManagement:common.fax")}
                prefixName="faxPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="fax"
                prefixValue={watch("faxPrefix") || undefined}
                isRequired={false}
              />
            </HalfDiv>
            <Hidden type="submit" ref={submitButtonRef} />
          </StyledForm>
        </SectionCard>
        <SectionCard
          cardTitle={t("warehouseManagement:common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD_CONTACT_PERSON)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t("warehouseManagement:factoryListAddPage.addContactPerson")}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={gridRef}
            rowData={contactPersonListToRowData}
            columnDefs={columnDefs}
            components={{
              ...editAction(handleDetailClick),
              ...createDeleteAction(handleDeleteClick),
            }}
            isPaginationDataMaping
            totalPage={contactPersonList.length}
            page={pagination.page}
            pageSize={pagination.pageSize}
            onGridReady={() => setIsReady(true)}
            handlePaginationClick={(page, pageSize) =>
              setPagination({ page, pageSize })
            }
          />
        </SectionCard>
        <BottomFixedContainer>
          <FooterButtonContainer>
            <FooterButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() =>
                setIsAlertDialogOpen(AlertDialogState.BACK_TO_PREVIOUS)
              }
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />
              {t("warehouseManagement:common.backToPrevious")}
            </FooterButton>
            <FooterButton onClick={() => submitButtonRef.current?.click()}>
              {t("warehouseManagement:common.save")}
            </FooterButton>
          </FooterButtonContainer>
        </BottomFixedContainer>
      </SectionContainer>
      {renderAlertDialog()}
      {renderDialog()}
    </ImporterMainLayout>
  );
};

export default ImporterWarehouseManagementAddPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const StyledForm = styled.form`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormItem = styled(FormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledCountryWithTimezoneFormItem = styled(CountryWithTimezoneFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledAddressFormItem = styled(AddressFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
    > div {
      width: 100% !important;
    }
  }
`;
const StyledContactFormItem = styled(ContactFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterButton = styled(Button)`
  min-width: 158px;
  text-align: center;
  ${typo.btn3m}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
