import { PropsWithChildren } from "react";
import styled from "styled-components";

function DialogFooterContainer({ children }: PropsWithChildren) {
  return <StyledFooterContainer>{children}</StyledFooterContainer>;
}

export default DialogFooterContainer;

const StyledFooterContainer = styled.div`
  display: flex;
  gap: 8px;
`;
