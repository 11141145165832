import { css, styled } from "styled-components";
import { ConfigProvider } from "antd";
import ImageGallery, { ReactImageGalleryProps } from "react-image-gallery";
import ActiveCheckFillSvg from "@/src/assets/icons/icon-check-circle-fill.svg";
import Icon from "../../atom/Icon";
import ChevronAbledSvg from "@/src/assets/icons/icon-chevron-right-abled.svg";
import ChevronDisabledSvg from "@/src/assets/icons/icon-chevron-left-disabled.svg";
import zIndex from "@/src/styles/zIndex";
import colorSet from "@/src/styles/color";
import { ImgHTMLAttributes } from "react";

export interface PhotoCollectionProps extends ReactImageGalleryProps {
  onImageClick: (imageUrl: string) => void;
  imageHeight?: number;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
  thumbnailProps?: ImgHTMLAttributes<HTMLImageElement>;
}

const PhotoCollection = (props: PhotoCollectionProps) => {
  const { onImageClick, imageHeight = 470, imgProps, thumbnailProps } = props;

  return (
    <ImageGallery
      showFullscreenButton={false}
      renderItem={(item) => {
        return (
          <ConfigProvider
            theme={{
              components: {
                Image: {
                  zIndexPopup: zIndex.antdImage,
                  zIndexPopupBase: zIndex.antdImageBase,
                },
              },
            }}
          >
            <StyledImage
              src={item.original}
              width={718}
              height={imageHeight}
              onClick={() => onImageClick(item.original)}
              {...imgProps}
            />
          </ConfigProvider>
        );
      }}
      showPlayButton={false}
      renderThumbInner={(item) => {
        return (
          <>
            <StyledThumbnail {...thumbnailProps}>
              <img src={item.thumbnail} alt={"thumbnail"} />
            </StyledThumbnail>
            <div className="active-overlay">
              <Icon iconSrc={ActiveCheckFillSvg} iconSize={32} />
            </div>
          </>
        );
      }}
      infinite={false}
      renderLeftNav={(onClick, disabled) => {
        return <LeftNav onClick={onClick} disabled={disabled} />;
      }}
      renderRightNav={(onClick, disabled) => {
        return <RightNav onClick={onClick} disabled={disabled} />;
      }}
      {...props}
    />
  );
};

export default PhotoCollection;

const StyledImage = styled.img`
  object-fit: contain;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${colorSet.gray10};
`;

const StyledThumbnail = styled.div`
  width: 82px;
  height: 82px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

const RightNav = ({
  onClick,
  disabled,
}: {
  onClick: (e: any) => void;
  disabled: boolean;
}) => {
  return (
    <StyledRightIcon
      disabled={disabled}
      iconSrc={disabled ? ChevronDisabledSvg : ChevronAbledSvg}
      onClick={!disabled ? onClick : () => {}}
      iconSize={40}
    />
  );
};

const LeftNav = ({
  onClick,
  disabled,
}: {
  onClick: (e: any) => void;
  disabled: boolean;
}) => {
  return (
    <StyledLeftIcon
      disabled={disabled}
      iconSrc={disabled ? ChevronDisabledSvg : ChevronAbledSvg}
      onClick={!disabled ? onClick : () => {}}
      iconSize={40}
    />
  );
};

const StyledRightIcon = styled(Icon)<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `};
`;

const StyledLeftIcon = styled(Icon)<{ disabled: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;

  ${({ disabled }) =>
    !disabled &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `};
`;
