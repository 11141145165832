import typo, { FontSizeType } from "@/src/styles/typography";
import colorSet, { ColorType } from "@/src/styles/color";
import { ReactNode } from "react";
import styled from "styled-components";

export interface TypoProps {
  typoType?: FontSizeType;
  color?: ColorType;
  as?: keyof HTMLElementTagNameMap;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const Typo = ({
  typoType = "b7m",
  as = "span",
  color = "gray2",
  className,
  children,
  onClick,
  ...rest
}: TypoProps) => {
  return (
    <Component
      as={as}
      $typoType={typoType}
      $typoColor={color}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typo;

const Component = styled.span<{
  $typoType: FontSizeType;
  $typoColor: ColorType;
}>`
  ${({ $typoType }) => typo[$typoType]};
  color: ${({ $typoColor }) => colorSet[$typoColor]};
`;
