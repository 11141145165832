import styled from "styled-components";
import Typo, { TypoProps } from "../../atom/Typo";

interface BreakWordTypoProps extends TypoProps {}

function BreakWordTypo(props: BreakWordTypoProps) {
  return <StyledBreakWordTypo {...props} />;
}

export default BreakWordTypo;

const StyledBreakWordTypo = styled(Typo)`
  word-break: break-all;
`;
