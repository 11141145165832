import React, { Dispatch, SetStateAction } from "react";
import { ShipmentBoardFilterType } from "..";
import SectionCard from "@/src/components/molecule/SectionCard";
import Flex from "@/src/components/molecule/Flex";
import Typo from "@/src/components/atom/Typo";
import styled, { css } from "styled-components";
import colorSet from "@/src/styles/color";
import CallOut from "@/src/components/molecule/CallOut";
import LoadingSvg from "@/src/assets/icons/icon-total-task.svg";
import LoadingContainerSvg from "@/src/assets/icons/icon-total-container.svg";
import CurrencyDownSvg from "@/src/assets/icons/icon-currency-down.svg";
import CurrencyUpSvg from "@/src/assets/icons/icon-currency-up.svg";
import { ReactComponent as ChevronLeftSvg } from "@/src/assets/icons/icon-chevron-left.svg";
import { ReactComponent as ChevronRightSvg } from "@/src/assets/icons/icon-chevron-right.svg";
import DocumentSvg from "@/src/assets/icons/item-document-blue.svg";
import Icon from "@/src/components/atom/Icon";
import { useNavigate } from "react-router-dom";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import dayjs, { Dayjs } from "dayjs";
import { addZeroPrefix } from "@/src/utils/addZeroPrefix";
import { getWeeklyDays } from "../utils/getDateFormat";
import { Button, IconButton } from "@/src/components/atom/Button";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import { StyledHorizontalScroll, StyledScroll } from "@/src/styles/scroll";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import NoShipmentData from "./NoShipmentData";
import { DASHBOARD_DATE_STRINGS } from "../constant";
import { useAppSelector } from "@/src/store";
import { ChartOptions } from "chart.js";
import {
  useGetImporterShipmentTotalStatisticQuery,
  useGetTodayImporterShipmentTotalStatisticQuery,
  useGetYesterdayImporterShipmentTotalStatisticQuery,
} from "@/src/store/apis/dashboard/importer";
import { isUndefined } from "@/src/utils/is";
import { ImporterShipmentTotalStatisticInfoDto } from "@/src/store/apis/dashboard/importer/interface";
import Loader from "@/src/components/atom/Loader";
import { getUniqueArray } from "../utils/getUniqueArray";
import { getEtaDailyChardDataSums } from "../utils/getChartDataFormat";
import ShipmentItemInfoTooltip from "./ShipmentItemInfoTooltip";
import MixedChart from "@/src/components/atom/chart/MixedChart";
import { useTranslation } from "react-i18next";
import TabItem from "@/src/components/molecule/TabItem";

interface ShipmentStatusProps {
  filterData: ShipmentBoardFilterType;
  weeklyShipmentDate: Dayjs[];
  onWeeklyShipmentDateChange: Dispatch<SetStateAction<Dayjs[]>>;
  weekIndex: number;
  onWeekIndexChange: Dispatch<SetStateAction<number>>;
  selectWeeklyDay: { value: Dayjs; day: string };
  onSelectWeeklyDayChange: Dispatch<
    SetStateAction<{ value: Dayjs; day: string }>
  >;
}

type ShipmentInfoType = {
  icon: string;
  label: string;
  currentValue: string;
  compareValue: string;
  statusOfDiff: "up" | "down" | "same";
};

const CHART_OPTION: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
  },
  scales: {
    x: {
      border: {
        color: colorSet.white,
      },
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        color: colorSet.white,
      },
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      position: "bottom",
      labels: {
        color: colorSet.gray6,
        boxWidth: 6,
        boxHeight: 6,
        pointStyle: "circle",
        usePointStyle: true,
        font: {
          size: 13,
        },
      },
    },
  },
};

const shipmentEmptyArray: ImporterShipmentTotalStatisticInfoDto[] = [];

function ShipmentStatus({
  filterData,
  weeklyShipmentDate: week,
  onWeeklyShipmentDateChange: setWeek,
  weekIndex,
  onWeekIndexChange: setWeekIndex,
  selectWeeklyDay,
  onSelectWeeklyDayChange: setSelectWeeklyDay,
}: ShipmentStatusProps) {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const weeklyDays = getWeeklyDays(week[0]);

  const { todayShipmentList, isTodayShipmentFetching } =
    useGetTodayImporterShipmentTotalStatisticQuery(
      {
        seller: filterData.seller !== "all" ? filterData.seller : undefined,
        importerMainCategories:
          filterData.mainCategoryCodeItemName !== "all"
            ? filterData.mainCategoryCodeItemName
            : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isTodayShipmentFetching: isFetching,
            todayShipmentList: isStable ? currentData.rows : shipmentEmptyArray,
          };
        },
      }
    );
  const { yesterdayShipmentList, isYesterdayShipmentFetching } =
    useGetYesterdayImporterShipmentTotalStatisticQuery(
      {
        seller: filterData.seller !== "all" ? filterData.seller : undefined,
        importerMainCategories:
          filterData.mainCategoryCodeItemName !== "all"
            ? filterData.mainCategoryCodeItemName
            : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isYesterdayShipmentFetching: isFetching,
            yesterdayShipmentList: isStable
              ? currentData.rows
              : shipmentEmptyArray,
          };
        },
      }
    );
  const { weeklyShipmentList, isWeeklyShipmentFetching } =
    useGetImporterShipmentTotalStatisticQuery(
      {
        etaAtFrom: dayjs(week[0]).startOf("day").toISOString(),
        etaAtTo: dayjs(week[1]).endOf("day").toISOString(),
        seller: filterData.seller !== "all" ? filterData.seller : undefined,
        importerMainCategories:
          filterData.mainCategoryCodeItemName !== "all"
            ? filterData.mainCategoryCodeItemName
            : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          const isStable = !isUnstable;

          return {
            isWeeklyShipmentFetching: isFetching,
            weeklyShipmentList: isStable
              ? currentData.rows
              : shipmentEmptyArray,
          };
        },
      }
    );

  const todayItems = todayShipmentList
    .map((item) => item.exporterItemIdList)
    .reduce((acc, subArray) => [...acc, ...subArray], []);
  const yesterdayItems = yesterdayShipmentList
    .map((item) => item.exporterItemIdList)
    .reduce((acc, subArray) => [...acc, ...subArray], []);

  const todayShipmentData = {
    itemsCount: getUniqueArray(todayItems).length,
    container:
      todayShipmentList
        .map((item) => item.numberOfContainer)
        .reduce((acc, val) => acc + val, 0) || 0,
    sumOfWeight:
      todayShipmentList
        .map((item) => item.sumOfWeight)
        .reduce((acc, val) => acc + val, 0) || 0,
  };

  const yesterdayShipmentData = {
    itemsCount: getUniqueArray(yesterdayItems).length,
    container:
      yesterdayShipmentList
        .map((item) => item.numberOfContainer)
        .reduce((acc, val) => acc + val, 0) || 0,
    sumOfWeight:
      yesterdayShipmentList
        .map((item) => item.sumOfWeight)
        .reduce((acc, val) => acc + val, 0) || 0,
  };

  const weeklyShipmentData = {
    count: weeklyShipmentList.length,
    container:
      weeklyShipmentList
        .map((item) => item.numberOfContainer)
        .reduce((acc, val) => acc + val, 0) || 0,
    sumOfWeight:
      weeklyShipmentList
        .map((item) => item.sumOfWeight)
        .reduce((acc, val) => acc + val, 0) || 0,
  };

  const weeklyChartData = {
    labels: weeklyDays.map(({ value, day }) => {
      const dateFormatString = value.format(DATE_FORMAT_STRINGS["MM.DD"]);
      const dateFormat = `${dateFormatString} (${
        DASHBOARD_DATE_STRINGS[day as keyof typeof DASHBOARD_DATE_STRINGS][lang]
      })`;

      return dateFormat;
    }),

    datasets: [
      {
        type: "line" as const,
        label: t("dashboard:importer.shipmentSummary.importedVolume"),
        backgroundColor: colorSet.chartBlue1,
        borderColor: colorSet.chartBlue1,
        borderWidth: 2,
        data: getEtaDailyChardDataSums({
          list: weeklyShipmentList,
          startDate: week[0],
          endDate: week[1],
          fieldName: "sumOfWeight",
        }),
      },
      {
        type: "bar" as const,
        label: t("dashboard:importer.shipmentSummary.numberOfContainer"),
        backgroundColor: colorSet.chartViolet5,
        borderColor: colorSet.chartViolet5,
        borderWidth: 2,
        maxBarThickness: 12,
        data: getEtaDailyChardDataSums({
          list: weeklyShipmentList,
          startDate: week[0],
          endDate: week[1],
          fieldName: "numberOfContainer",
        }),
      },
    ],
  };

  const getShipmentInfoStatus = (
    type: "item" | "container" | "weight"
  ): ShipmentInfoType["statusOfDiff"] => {
    const {
      sumOfWeight: todaySumOfWeight,
      container: todayContainer,
      itemsCount: todayItems,
    } = todayShipmentData;
    const {
      sumOfWeight: yesterdaySumOfWeight,
      container: yesterdayContainer,
      itemsCount: yesterdayItems,
    } = yesterdayShipmentData;

    switch (type) {
      case "item":
        if (todayItems - yesterdayItems === 0) {
          return "same";
        }
        if (todayItems > yesterdayItems) {
          return "up";
        }
        return "down";

      case "container":
        if (todayContainer - yesterdayContainer === 0) {
          return "same";
        }
        if (todayContainer > yesterdayContainer) {
          return "up";
        }
        return "down";

      case "weight":
        if (todaySumOfWeight - yesterdaySumOfWeight === 0) {
          return "same";
        }
        if (todaySumOfWeight > yesterdaySumOfWeight) {
          return "up";
        }
        return "down";
    }
  };

  const groupByShipmentItemOfWeek = () => {
    const groupedData = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    } as {
      [key: string]: ImporterShipmentTotalStatisticInfoDto[];
    };

    weeklyShipmentList?.forEach((item) => {
      const dayOfWeek = dayjs(item.etaDateAt)
        .format("dddd")
        .toLocaleLowerCase();
      groupedData[dayOfWeek].push(item);
    });

    return groupedData;
  };

  const goToBookingDetail = (bookingId: number) => {
    navigate(`${IMPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/${bookingId}`);
  };

  const handleLastWeekClick = () => {
    if (weekIndex > -2) {
      const startOfThisWeek = getWeeklyDays(week[0].subtract(7, "day"));

      setWeek((prev) => prev.map((day) => day.subtract(7, "day")));
      setWeekIndex((prev) => prev - 1);
      setSelectWeeklyDay((prev) => {
        const changeWeeklyDays = startOfThisWeek.find(
          (item) => item.day === prev.day
        );

        return {
          value: changeWeeklyDays?.value as Dayjs,
          day: changeWeeklyDays?.day as string,
        };
      });
    }
  };

  const handleNextWeekClick = () => {
    if (weekIndex < 2) {
      const startOfThisWeek = getWeeklyDays(week[0].add(7, "day"));

      setWeek((prev) => prev.map((day) => day.add(7, "day")));
      setWeekIndex((prev) => prev + 1);
      setSelectWeeklyDay((prev) => {
        const changeWeeklyDays = startOfThisWeek.find(
          (item) => item.day === prev.day
        );

        return {
          value: changeWeeklyDays?.value as Dayjs,
          day: changeWeeklyDays?.day as string,
        };
      });
    }
  };

  const renderStatusOfDiff = (
    status: ShipmentInfoType["statusOfDiff"],
    value: string
  ) => {
    switch (status) {
      case "up":
        return (
          <Flex gap={4} alignItems="center">
            <Icon iconSrc={CurrencyUpSvg} iconSize={16} />
            <Typo typoType="b6m" color="red2">
              {value}
            </Typo>
          </Flex>
        );

      case "down":
        return (
          <Flex gap={4} alignItems="center">
            <Icon iconSrc={CurrencyDownSvg} iconSize={16} />
            <Typo typoType="b6m" color="blue2">
              {value}
            </Typo>
          </Flex>
        );

      case "same":
      default:
        return (
          <Typo typoType="b6m" color="gray7">
            -
          </Typo>
        );
    }
  };

  const renderTodayShipmentsCard = () => {
    if (todayShipmentList.length === 0) {
      return (
        <NoDataContainer height={110}>
          <NoShipmentData />
        </NoDataContainer>
      );
    }

    // 5개 이하
    if (todayShipmentList.length <= 5) {
      return (
        <TodayShipmentListContainer>
          {todayShipmentList.map(
            ({ bookingId, blNo, sellerCompanyName, exporterItemList }, idx) => {
              const itemList = exporterItemList.map(
                ({ item, itemNumberOfContainer }) => {
                  return `${item} / ${itemNumberOfContainer} EA`;
                }
              );
              const itemsText = exporterItemList
                .map(({ item }) => item)
                .join(",");
              const itemTotalCount = exporterItemList
                .map(({ itemNumberOfContainer }) => itemNumberOfContainer)
                .reduce((acc, val) => acc + val, 0);

              return (
                <ShipmentCard
                  key={idx.toString()}
                  borderColor="blue9"
                  backgroundColor="blue10"
                  onClick={() => {
                    goToBookingDetail(bookingId);
                  }}
                  className={"w-320"}
                >
                  <Flex flexDirection="column" gap={8} isFullWidth>
                    <Flex flexDirection="column" gap={4}>
                      <CardContainerRow>
                        <Typo typoType="h4">B/L No. {blNo ?? "-"}</Typo>
                      </CardContainerRow>
                      <CardContainerRow>
                        <Typo typoType="b9r">{sellerCompanyName ?? "-"}</Typo>
                      </CardContainerRow>
                    </Flex>

                    <CardContainerRow>
                      <Flex gap={4}>
                        <CardContainerRow maxWidth={250}>
                          <Typo typoType="h9" color="blue4">
                            {itemsText}
                          </Typo>
                        </CardContainerRow>
                        <Typo typoType="b9m" color="blue4">
                          / {itemTotalCount} EA
                        </Typo>
                      </Flex>
                    </CardContainerRow>

                    <ShipmentItemInfoTooltip itemList={itemList}>
                      <ShipmentCardItemsButton
                        buttonSize={24}
                        buttonColor="black"
                        buttonGrade="tertiary"
                        onClick={(e) => e.preventDefault()}
                      >
                        {t("dashboard:importer.statistics.total")}{" "}
                        {exporterItemList.length}{" "}
                        {t("dashboard:importer.statistics.cases")}
                      </ShipmentCardItemsButton>
                    </ShipmentItemInfoTooltip>
                  </Flex>
                </ShipmentCard>
              );
            }
          )}
        </TodayShipmentListContainer>
      );
    } else {
      // 6개 이상
      return (
        <TodayShipmentListContainer>
          {todayShipmentList.map(
            (
              { bookingId, blNo, sellerCompanyName, exporterItemList },
              idx,
              origin
            ) => {
              const itemList = exporterItemList.map(
                ({ item, itemNumberOfContainer }) => {
                  return `${item} / ${itemNumberOfContainer} EA`;
                }
              );
              const itemsText = exporterItemList
                .map(({ item }) => item)
                .join(",");
              const itemTotalCount = exporterItemList
                .map(({ itemNumberOfContainer }) => itemNumberOfContainer)
                .reduce((acc, val) => acc + val, 0);

              const index = idx + 1;
              const restCount = origin.length - 5;

              if (index <= 5) {
                return (
                  <ShipmentCard
                    key={idx.toString()}
                    borderColor="blue9"
                    backgroundColor="blue10"
                    onClick={() => {
                      goToBookingDetail(bookingId);
                    }}
                    className={"w-320"}
                  >
                    <Flex flexDirection="column" gap={8} isFullWidth>
                      <Flex flexDirection="column" gap={4}>
                        <CardContainerRow>
                          <Typo typoType="h4">B/L No. {blNo ?? "-"}</Typo>
                        </CardContainerRow>
                        <CardContainerRow>
                          <Typo typoType="b9r">{sellerCompanyName ?? "-"}</Typo>
                        </CardContainerRow>
                      </Flex>

                      <CardContainerRow>
                        <Flex gap={4}>
                          <CardContainerRow maxWidth={250}>
                            <Typo typoType="h9" color="blue4">
                              {itemsText}
                            </Typo>
                          </CardContainerRow>
                          <Typo typoType="b9m" color="blue4">
                            / {itemTotalCount} EA
                          </Typo>
                        </Flex>
                      </CardContainerRow>

                      <ShipmentItemInfoTooltip itemList={itemList}>
                        <ShipmentCardItemsButton
                          buttonSize={24}
                          buttonColor="black"
                          buttonGrade="tertiary"
                          onClick={(e) => e.preventDefault()}
                        >
                          {t("dashboard:importer.statistics.total")}{" "}
                          {exporterItemList.length}{" "}
                          {t("dashboard:importer.statistics.cases")}
                        </ShipmentCardItemsButton>
                      </ShipmentItemInfoTooltip>
                    </Flex>
                  </ShipmentCard>
                );
              }

              if (index === origin.length) {
                return (
                  <RestCount
                    backgroundColor="white"
                    onClick={() => navigate(IMPORTER_PRIVATE_PATH.SHIPMENT)}
                  >
                    <Flex alignItems="center" justifyContent="center" flex={1}>
                      <Typo typoType="h4">
                        + {restCount}
                        {t("dashboard:importer.statistics.cases")}
                      </Typo>
                    </Flex>
                  </RestCount>
                );
              }

              return null;
            }
          )}
        </TodayShipmentListContainer>
      );
    }
  };

  const renderTodayShipmentInfo = () => {
    const shipmentInfo = [
      {
        icon: DocumentSvg,
        label: "Item",
        currentValue: `${todayShipmentData.itemsCount}`,
        compareValue: `${
          todayShipmentData.itemsCount - yesterdayShipmentData.itemsCount
        }`,
        statusOfDiff: getShipmentInfoStatus("item"),
      },
      {
        icon: LoadingSvg,
        label: "No. of Container",
        currentValue: `${todayShipmentData.container} EA`,
        compareValue: `${
          todayShipmentData.container - yesterdayShipmentData.container
        } EA`,
        statusOfDiff: getShipmentInfoStatus("container"),
      },
      {
        icon: LoadingContainerSvg,
        label: "Total Net Weight",
        currentValue: `${todayShipmentData.sumOfWeight} MT`,
        compareValue: `${
          todayShipmentData.sumOfWeight - yesterdayShipmentData.sumOfWeight
        }`,
        statusOfDiff: getShipmentInfoStatus("weight"),
      },
    ] as ShipmentInfoType[];

    if (isTodayShipmentFetching || isYesterdayShipmentFetching) {
      return (
        <LoaderContainer height={300}>
          <Loader size={60} />
        </LoaderContainer>
      );
    }

    return (
      <>
        <StyledCallOut backgroundColor="white">
          {shipmentInfo.map(
            ({ icon, label, currentValue, compareValue, statusOfDiff }) => {
              return (
                <CallOutContent>
                  <Icon iconSrc={icon} iconSize={52} />

                  <Flex flexDirection="column" gap={4}>
                    <Typo typoType="b9m" color="gray6">
                      {label}
                    </Typo>
                    <Flex alignItems="center" gap={8}>
                      <Typo typoType="h2">{currentValue}</Typo>
                      {renderStatusOfDiff(statusOfDiff, compareValue)}
                    </Flex>
                  </Flex>
                </CallOutContent>
              );
            }
          )}
        </StyledCallOut>
      </>
    );
  };

  const renderWeeklyDateText = () => {
    const currentWeek = week[0].toISOString();
    const nextWeek = week[1].toISOString();

    const currentWeekYear = dayjs(currentWeek)
      .get("y")
      .toString()
      .substring(2, 4);
    const currentWeekMonth = addZeroPrefix(dayjs(currentWeek).get("M") + 1);
    const currentWeekDay = addZeroPrefix(dayjs(currentWeek).get("D"));

    const nextWeekYear = dayjs(nextWeek).get("y").toString().substring(2, 4);
    const nextWeekMonth = addZeroPrefix(dayjs(nextWeek).get("M") + 1);
    const nextWeekDay = addZeroPrefix(dayjs(nextWeek).get("D"));

    return `${currentWeekYear}. ${currentWeekMonth}. ${currentWeekDay} ~ ${nextWeekYear}. ${nextWeekMonth}. ${nextWeekDay}`;
  };

  const renderWeeklyShipmentStatistics = () => {
    const { count, container, sumOfWeight } = weeklyShipmentData;
    const shipmentData = [
      {
        label: t("dashboard:importer.shipmentSummary.totalArrivedShipments"),
        value: `${count} ${t("dashboard:importer.statistics.cases")}`,
      },
      { isDivider: true },
      {
        label: t("dashboard:importer.shipmentSummary.totalContainerQuantity"),
        value: `${container.toLocaleString("ko-KR")} EA`,
      },
      { isDivider: true },
      {
        label: t("dashboard:importer.shipmentSummary.totalImportedVolume"),
        value: `${sumOfWeight.toLocaleString("ko-KR")} MT`,
      },
    ];

    return (
      <WeeklyShipmentStatisticsContainer
        gap={16}
        alignItems="center"
        justifyContent="center"
        flex={1}
      >
        {shipmentData.map(({ label, value, isDivider }, idx) => {
          if (isDivider) {
            return <Divider height={88} />;
          }

          return (
            <WeeklyShipmentStatisticsItem key={idx.toString()}>
              <Typo typoType="b9m" color="gray6" className="text-center">
                {label}
              </Typo>
              <Typo typoType="h4" color="blue2" className="text-center">
                {value}
              </Typo>
            </WeeklyShipmentStatisticsItem>
          );
        })}
      </WeeklyShipmentStatisticsContainer>
    );
  };

  const renderWeeklyDaysTab = () => {
    return (
      <WeeklyTabs role="tablist">
        {weeklyDays.map(({ value, day }) => {
          const dateFormat = value
            .format(DATE_FORMAT_STRINGS.dddd)
            .toLocaleLowerCase();

          return (
            <StyledTabItem
              key={dateFormat}
              tabIndex={dateFormat === selectWeeklyDay.day ? 0 : -1}
              isSelect={dateFormat === selectWeeklyDay.day}
              tabValue={dateFormat}
              onClick={() => setSelectWeeklyDay({ value, day: dateFormat })}
              onFocusItem={() => setSelectWeeklyDay({ value, day: dateFormat })}
            >
              <Typo
                typoType="b9m"
                color={dateFormat === selectWeeklyDay.day ? "blue4" : "gray7"}
              >
                {value.format(DATE_FORMAT_STRINGS["MM.DD"])} /{" "}
                {
                  DASHBOARD_DATE_STRINGS[
                    day as keyof typeof DASHBOARD_DATE_STRINGS
                  ][lang]
                }
              </Typo>
            </StyledTabItem>
          );
        })}
      </WeeklyTabs>
    );
  };

  const renderWeeklyDaysTabContent = () => {
    if (groupByShipmentItemOfWeek()[selectWeeklyDay.day].length === 0) {
      return (
        <NoDataContainer height={236}>
          <NoShipmentData />
        </NoDataContainer>
      );
    }

    return (
      <WeeklyShipmentListContainer>
        {groupByShipmentItemOfWeek()[selectWeeklyDay.day]?.map(
          ({ bookingId, blNo, sellerCompanyName, exporterItemList }, idx) => {
            const itemList = exporterItemList.map(
              ({ item, itemNumberOfContainer }) => {
                return `${item} / ${itemNumberOfContainer} EA`;
              }
            );
            const itemsText = exporterItemList
              .map(({ item }) => item)
              .join(",");
            const itemTotalCount = exporterItemList
              .map(({ itemNumberOfContainer }) => itemNumberOfContainer)
              .reduce((acc, val) => acc + val, 0);

            return (
              <ShipmentCard
                key={idx.toString()}
                borderColor="gray9"
                backgroundColor="white"
                onClick={() => {
                  goToBookingDetail(bookingId);
                }}
                className={"w-320"}
              >
                <Flex flexDirection="column" gap={8} isFullWidth>
                  <Flex flexDirection="column" gap={4}>
                    <CardContainerRow>
                      <Typo typoType="h4">B/L No. {blNo ?? "-"}</Typo>
                    </CardContainerRow>
                    <CardContainerRow>
                      <Typo typoType="b9r">{sellerCompanyName ?? "-"}</Typo>
                    </CardContainerRow>
                  </Flex>

                  <CardContainerRow>
                    <Flex gap={4}>
                      <CardContainerRow maxWidth={250}>
                        <Typo typoType="h9" color="blue4">
                          {itemsText}
                        </Typo>
                      </CardContainerRow>
                      <Typo typoType="b9m" color="blue4">
                        / {itemTotalCount} EA
                      </Typo>
                    </Flex>
                  </CardContainerRow>

                  <ShipmentItemInfoTooltip itemList={itemList}>
                    <ShipmentCardItemsButton
                      buttonSize={24}
                      buttonColor="black"
                      buttonGrade="tertiary"
                      onClick={(e) => e.preventDefault()}
                    >
                      {t("dashboard:importer.statistics.total")}{" "}
                      {exporterItemList.length}{" "}
                      {t("dashboard:importer.statistics.cases")}
                    </ShipmentCardItemsButton>
                  </ShipmentItemInfoTooltip>
                </Flex>
              </ShipmentCard>
            );
          }
        )}
      </WeeklyShipmentListContainer>
    );
  };

  const renderWeeklyShipmentInfo = () => {
    if (isWeeklyShipmentFetching) {
      return (
        <LoaderContainer height={600}>
          <Loader size={60} />
        </LoaderContainer>
      );
    }
    return (
      <>
        <WeeklyShipmentStatistics>
          {renderWeeklyShipmentStatistics()}
          <WeeklyShipmentChart>
            <MixedChart data={weeklyChartData} options={CHART_OPTION} />
          </WeeklyShipmentChart>
        </WeeklyShipmentStatistics>
        {renderWeeklyDaysTab()}
        {renderWeeklyDaysTabContent()}
      </>
    );
  };

  return (
    <SectionCard
      cardTitle={t("dashboard:importer.shipmentSummary.shipmentStatus")}
    >
      <Flex flexDirection="column" gap={16}>
        {/* 오늘 도착 선적 정보 */}
        <SubTitle>
          <Typo typoType="h4">
            {t(
              "dashboard:importer.shipmentSummary.todayArrivalShipmentsInformation"
            )}
          </Typo>
        </SubTitle>
        {renderTodayShipmentInfo()}

        {/* 오늘 도착 선적 목록 */}
        <TodayATAShipment>
          <Typo typoType="b5m" color="gray5">
            {t("dashboard:importer.shipmentSummary.todayArrivalShipmentsList")}
          </Typo>
        </TodayATAShipment>
        {renderTodayShipmentsCard()}

        {/* 주차별 선적 정보 */}
        <SubTitle>
          <Flex gap={8}>
            <Typo typoType="h4">
              {t(
                "dashboard:importer.shipmentSummary.weeklyShipmentInformation"
              )}
            </Typo>
            <Divider height={30} />

            <Flex alignItems="center" gap={8}>
              <Typo typoType="b7m" color="gray5">
                {renderWeeklyDateText()}
              </Typo>
              <IconButton
                buttonSize={24}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleLastWeekClick}
                disabled={weekIndex <= -2}
              >
                <ChevronLeftIcon data-disabled={weekIndex <= -2} />
              </IconButton>
              <IconButton
                buttonSize={24}
                buttonColor="black"
                buttonGrade="tertiary"
                onClick={handleNextWeekClick}
                disabled={weekIndex >= 2}
              >
                <ChevronRightIcon data-disabled={weekIndex >= 2} />
              </IconButton>
            </Flex>

            <Flex alignItems="center" gap={8}>
              <StyledIcon iconSrc={InfoSvg} />
              <Typo typoType="b9r" color="gray5">
                {t(
                  "dashboard:importer.shipmentSummary.weeksBeforeAndAfterTheSpecifiedPeriod"
                )}
              </Typo>
            </Flex>
          </Flex>
        </SubTitle>
        {renderWeeklyShipmentInfo()}
      </Flex>
    </SectionCard>
  );
}

export default ShipmentStatus;

const SubTitle = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const TodayATAShipment = styled.div`
  padding: 8px 0;
`;

const StyledCallOut = styled(CallOut)`
  gap: 0;
`;

const CallOutContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  border-right: 1px solid ${colorSet.gray10};
  padding: 0 16px;

  &:first-of-type {
    padding-left: 0;
    padding-right: 16px;
  }
  &:last-of-type {
    padding-left: 16px;
    border-right: none;
  }
`;

const TodayShipmentListContainer = styled.div`
  display: flex;
  overflow: auto;
  padding-bottom: 16px;
  ${StyledHorizontalScroll}
  gap: 16px;
`;

const WeeklyShipmentListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 320px));
  gap: 16px;
  overflow: auto;
  max-height: 252px;
  ${StyledScroll};
`;

const ShipmentCard = styled(CallOut)`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 230px;
  max-width: 100%;

  &.w-320 {
    max-width: 320px;
  }
`;

const RestCount = styled(CallOut)`
  width: 110px;
  flex-shrink: 0;
  cursor: pointer;
`;

const Divider = styled.div<{ height: number }>`
  width: 1px;
  height: ${({ height }) => height}px;
  border-right: 1px solid ${colorSet.gray10};
`;

const ChevronLeftIcon = styled(ChevronLeftSvg)`
  width: 16px;
  height: 16px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;
const ChevronRightIcon = styled(ChevronRightSvg)`
  width: 16px;
  height: 16px;

  &[data-disabled="true"] {
    path {
      fill: ${colorSet.gray8};
    }
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const WeeklyShipmentStatistics = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const WeeklyShipmentStatisticsContainer = styled(Flex)`
  padding: 56px 0;
`;

const WeeklyShipmentStatisticsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 8px;
  flex: 1;

  .text-center {
    text-align: center;
  }
`;

const WeeklyShipmentChart = styled.div`
  flex: 1;
  width: calc(100% - 600px);
  height: 100%;
  min-height: 200px;
  border: 1px solid ${colorSet.gray10};
  border-radius: 8px;
`;

const WeeklyTabs = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  border: 1px solid ${colorSet.gray9};
  border-radius: 999px;
  background: ${colorSet.gray10};
  cursor: pointer;

  ${({ isSelect }) =>
    isSelect &&
    css`
      background: ${colorSet.white};
      border-color: ${colorSet.blue4};
    `}
`;

const NoDataContainer = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
`;

const LoaderContainer = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
`;

const CardContainerRow = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "290px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ShipmentCardItemsButton = styled(Button)`
  width: max-content;
  padding: 2px 5px;
  border-radius: 100px;
`;
