import Dialog from "@/src/components/atom/Dialog";
import React, { useEffect, useRef, useState } from "react";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import CancelAlertDialog from "@/src/components/molecule/CancelAlertDialog";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import useAlert from "@/src/hooks/useAlert";
import colorSet, { ColorType } from "@/src/styles/color";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import FormItem from "@/src/components/molecule/FormItem";
import TextArea from "@/src/components/atom/TextArea";
import {
  ExporterItemCodeListViewV2Dto,
  ITEM_AVAILABLE_MESSAGE,
} from "@/src/store/apis/corporate/companyItemManegement/interface";
import {
  useEditExporterItemCodeMutation,
  useLazyGetItemCodeCheckQuery,
} from "@/src/store/apis/corporate/companyItemManegement";
import { useTranslation } from "react-i18next";

interface EditItemDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  selectItemCode: ExporterItemCodeListViewV2Dto | null;
}

const ACTIVATED_OPTION_LIST = [
  { label: "Y", value: "Y" },
  { label: "N", value: "N" },
];

function EditItemDialog({
  open,
  onOpenChange,
  selectItemCode,
}: EditItemDialogProps) {
  const { t } = useTranslation();
  const initItemCode = selectItemCode?.itemCode;
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const alert = useAlert();

  // API
  const [getItemCodeCheck] = useLazyGetItemCodeCheckQuery();
  const [editExporterItemCode] = useEditExporterItemCodeMutation();

  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const { control, handleSubmit, setFocus, watch, setValue, getValues } =
    useForm<{
      mainCategory: string;
      subCategory: string;
      item: string;
      itemCode: string;
      description: string;
      isActivated: string;
    }>({
      mode: "onBlur",
      defaultValues: {
        mainCategory: selectItemCode?.mainCategory,
        subCategory: selectItemCode?.subCategory,
        item: selectItemCode?.item,
        itemCode: selectItemCode?.itemCode,
        description: selectItemCode?.description ?? "",
        isActivated: selectItemCode?.isActivated === true ? "Y" : "N",
      },
    });

  useEffect(() => {
    setFocus("itemCode");
  }, [setFocus]);

  const handleSubmitClick = async () => {
    if (selectItemCode === null) {
      return;
    }

    const params = {
      id: selectItemCode.id,
      itemCode: getValues("itemCode"),
      description:
        getValues("description") === "" ? null : getValues("description"),
      isActivated: getValues("isActivated") === "Y" ? true : false,
    };

    try {
      await editExporterItemCode(params).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
      onOpenChange(false);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleItemCodeCheck = async (itemCode: string) => {
    if (!selectItemCode || initItemCode === itemCode) {
      return true;
    }

    const params = {
      mainCategoryCodeItemName: selectItemCode.mainCategoryCodeItemName,
      subCategoryCodeItemName: selectItemCode.subCategoryCodeItemName,
      item: selectItemCode.itemCodeItemName,
      itemCode,
    };

    try {
      const res = await getItemCodeCheck(params).unwrap();

      return res.row.message === ITEM_AVAILABLE_MESSAGE ? false : true;
    } catch (e: any) {
      return true;
    }
  };

  const validateDuplicateFields = async (value: string) => {
    if (initItemCode === value) {
      return true;
    }

    const isDuplicateServer = await handleItemCodeCheck(value);

    return isDuplicateServer ? false : true;
  };

  return (
    <Dialog
      title={t("itemManagement:exporter.dialog.title.editItemCode")}
      open={open}
      width={640}
      onOpenChange={onOpenChange}
      height={536}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsCancelAlertOpen(true)}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            onClick={() => setIsCancelAlertOpen(true)}
          >
            <Typo typoType="btn3m" color="gray2">
              {t("itemManagement:exporter.dialog.buttons.cancelButton")}
            </Typo>
          </Button>
          <Button onClick={() => saveButtonRef.current?.click()}>
            <Typo typoType="btn3m" color="white">
              {t("itemManagement:exporter.dialog.buttons.saveButton")}
            </Typo>
          </Button>
        </DialogFooterContainer>
      }
    >
      {/* 취소 Alert */}
      {isCancelAlertOpen && (
        <CancelAlertDialog
          open={isCancelAlertOpen}
          onOpenChange={setIsCancelAlertOpen}
          onOk={() => onOpenChange(false)}
        />
      )}

      <Form onSubmit={handleSubmit(handleSubmitClick)}>
        <Callout>
          <InfoIcon color="gray6" />
          <Typo typoType="b9r" color="gray6">
            {t("itemManagement:exporter.dialog.callout")}
          </Typo>
        </Callout>

        {/* Form Item */}
        <FormItem
          label={t("itemManagement:exporter.dialog.label.mainCategory")}
          type="text"
          name="mainCategory"
          control={control}
          direction="horizontal"
          inputProps={{ disabled: true }}
        />

        <FormItem
          label={t("itemManagement:exporter.dialog.label.subCategory")}
          type="text"
          name="subCategory"
          control={control}
          direction="horizontal"
          inputProps={{ disabled: true }}
        />

        <FormItem
          label={t("itemManagement:exporter.dialog.label.item")}
          type="text"
          name="item"
          control={control}
          direction="horizontal"
          inputProps={{
            disabled: true,
            style: {
              textOverflow: "ellipsis",
            },
          }}
        />

        <FormItem
          label={t("itemManagement:exporter.dialog.label.itemCode")}
          type="text"
          rules={{
            required: true,
            validate: (value) => validateDuplicateFields(value),
          }}
          name="itemCode"
          control={control}
          direction="horizontal"
          inputProps={{
            placeholder: t(
              "itemManagement:exporter.dialog.placeholder.itemCode"
            ),
          }}
          errorsMessage={{
            required: t("error:required"),
            validate: t("error:itemCodeValidationError"),
          }}
          bottomAccessory={
            <StyledTextArea
              value={watch("description")}
              onChange={(e) => setValue("description", e.target.value)}
              maxLength={100}
              placeholder={t(
                "itemManagement:exporter.dialog.placeholder.itemCodeDescription"
              )}
            />
          }
        />

        <FormItem
          label={t("itemManagement:exporter.dialog.label.use")}
          type="radioGroup"
          name="isActivated"
          control={control}
          direction="horizontal"
          options={ACTIVATED_OPTION_LIST}
        />

        <Hidden ref={saveButtonRef} type="submit" />
      </Form>
    </Dialog>
  );
}

export default EditItemDialog;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Callout = styled.div`
  display: flex;
  padding: 16px;
  gap: 6px;
  border-radius: 8px;
  background: ${colorSet.gray11};
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 96px;
  width: 100%;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;
