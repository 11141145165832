export type WeightUnit = "mt" | "admt" | "kgs" | "lbs" | "lt" | "st";

const conversionFactors: {
  [key in WeightUnit]: { [key in WeightUnit]: number };
} = {
  mt: { mt: 1, admt: 1.02, kgs: 1000, lbs: 2204.62, lt: 0.984206, st: 1.10231 },
  admt: { mt: 0.9804, admt: 1, kgs: 980.4, lbs: 2158.42, lt: 0.9644, st: 1.08 },
  kgs: {
    mt: 0.001,
    admt: 0.00102041,
    kgs: 1,
    lbs: 2.20462,
    lt: 0.000984206,
    st: 0.00110231,
  },
  lbs: {
    mt: 0.000453592,
    admt: 0.000463,
    kgs: 0.453592,
    lbs: 1,
    lt: 0.000446429,
    st: 0.0005,
  },
  lt: { mt: 1.01605, admt: 1.03604, kgs: 1016.05, lbs: 2240, lt: 1, st: 1.12 },
  st: {
    mt: 0.907185,
    admt: 0.926,
    kgs: 907.185,
    lbs: 2000,
    lt: 0.892857,
    st: 1,
  },
};

export default function convertWeight({
  value,
  fromUnit,
  toUnit,
  decimalPlaces,
  includeUnit,
}: {
  value: number;
  fromUnit: WeightUnit;
  toUnit: WeightUnit;
  decimalPlaces: number;
  includeUnit: boolean;
}): string | number {
  const conversionFactor =
    fromUnit === toUnit ? 1 : conversionFactors[fromUnit][toUnit];
  const convertedValue = value * conversionFactor;

  if (includeUnit) {
    const formattedValue = convertedValue.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
    return `${formattedValue} ${toUnit}`;
  } else {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(convertedValue * factor) / factor;
  }
}
