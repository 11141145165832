import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import { GenerateTaskDto, TaskBookingListViewDto } from "./interface";

export const taskRegisterApi = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      createTask: build.mutation<Row<{ id: number }>, GenerateTaskDto>({
        query: (body) => ({
          url: "/exporters/this/tasks",
          method: "POST",
          body,
        }),
      }),
      getBookingListAssociatedWithTask: build.query<
        Rows<TaskBookingListViewDto>,
        {
          buyerNameCode?: string;
          page?: number;
          pageSize?: number;
          bookingId?: number;
        }
      >({
        query: (params) => ({
          url: "exporters/this/task/bookings",
          params: params,
        }),
      }),
      getFilteredBookingListAssociatedWithTask: build.query<
        Rows<TaskBookingListViewDto>,
        { buyerNameCode?: string; page?: number; pageSize?: number }
      >({
        query: (params) => ({
          url: "exporters/this/task/filtered-bookings",
          params: params,
        }),
      }),
    };
  },
});

export const {
  useCreateTaskMutation,
  useLazyGetBookingListAssociatedWithTaskQuery,
  useLazyGetFilteredBookingListAssociatedWithTaskQuery,
} = taskRegisterApi;
