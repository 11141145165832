import dayjs from "dayjs";

export const contractFormDefaultValue = {
  poNo: "",
  poNoRemark: undefined,
  scNo: "",
  scNoRemark: undefined,
  buyerId: "",
  buyerEmployeeId: "",
  isThirdPartyTrade: [],
  allocation: undefined,
  orderDateAt: dayjs(),
  isAddContractTerms: ["true"],
  hsCode: undefined,
  mainCategory: "",
  subCategory: "",
  item: "",
  exporterItemId: "",
  exporterItemCodeId: undefined,
  exporterItemCode: "",
  amount: "",
  quantityUnitCodeItemName: "MEASUREMENT_MT",
  quantityUnit: "MT",
  quantity: "",
  unitPriceUnit: "USD",
  unitPriceUnitCodeItemName: "USD",
  unitPrice: "",
  origin: undefined,
  freetime: undefined,
  freetimeCodeItemName: undefined,
  freetimeDirect: undefined,
  placeOfDelivery: undefined,
  placeOfDeliveryCodeItemName: undefined,
  portOfDischarge: undefined,
  placeOfDischargeCodeItemName: undefined,
  notYetSpecified: false,
  anyPortIn: undefined,
  anyPortInCodeItemName: undefined,
  paymentTerms: undefined,
  paymentTermsCodeItemName: undefined,
  paymentTermsRemark: undefined,
  paymentTermsMediaId: undefined,
  isAddBankDetail: [],
  expireDateAt: "",
  lastShipmentDateAt: undefined,
  shippingTerms: undefined,
  shippingTermsCodeItemName: undefined,
  shippingTermsRemark: undefined,
  referenceMediaIdList: [],
  contractRemark: undefined,
  memo: undefined,
  poMediaId: undefined,
  scMediaId: undefined,
  lcNo: undefined,
};

export const contractAddBasedOnPoForm = {
  scNo: "",
  buyerId: "",
  buyerEmployeeId: "",
  isThirdPartyTrade: [],
  allocation: undefined,
  orderDateAt: "",
  isAddContractTerms: ["true"],
  hsCode: undefined,
  mainCategory: "",
  subCategory: "",
  item: "",
  exporterItemId: "",
  exporterItemCodeId: undefined,
  exporterItemCode: "",
  freetime: undefined,
  freetimeCodeItemName: undefined,
  freetimeDirect: undefined,
  paymentTerms: undefined,
  paymentTermsCodeItemName: undefined,
  paymentTermsRemark: undefined,
  paymentTermsMediaId: undefined,
  isAddBankDetail: [],
  lastShipmentDateAt: undefined,
  referenceMediaIdList: [],
  contractRemark: undefined,
  memo: undefined,
  scMediaId: undefined,
  lcNo: undefined,
  origin: undefined,
};
