import { ReactNode } from "react";
import { styled } from "styled-components";

interface SectionCardWithoutHeaderProps {
  children?: ReactNode;
  className?: string;
}

const SectionCardWithoutHeader = ({
  children,
  className,
}: SectionCardWithoutHeaderProps) => {
  return <Article className={className}>{children}</Article>;
};

export default SectionCardWithoutHeader;

const Article = styled.article`
  box-shadow: 0px 4px 16px 0px #0000001a;
  padding: 8px;
  width: 100%;
  border-radius: 16px;
`;
