import { ReactNode } from "react";
import styled from "styled-components";
import {
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import Select from "../../atom/Select";
import Loader from "../../atom/Loader";
import Input from "../../atom/Input";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import { InputError } from "../FormItem";
import { useTranslation } from "react-i18next";

interface ContactFormItemProps<TFieldValues extends FieldValues> {
  prefixName: string;
  restContactName: string;
  prefixValue?: string;
  register: UseFormRegister<TFieldValues>;
  label?: ReactNode;
  setValue?: UseFormSetValue<TFieldValues>;
  direction?: "horizontal" | "vertical";
  isRequired?: boolean;
  className?: string;
  errors?: any;
  errorMessage?: ReactNode;
  disabled?: boolean;
}

const SEPARATOR = "&";

const ContactFormItem = <TFieldValues extends FieldValues>({
  prefixName,
  restContactName,
  prefixValue,
  register,
  label = "Contact",
  setValue,
  direction = "horizontal",
  isRequired = true,
  className,
  errors,
  errorMessage,
  disabled,
}: ContactFormItemProps<TFieldValues>) => {
  const { t } = useTranslation();
  const hasError = errors?.[prefixName] || errors?.[restContactName];
  const { isFetching, data: countryCodeList } =
    useGetCommonCodeViaCodeNameQuery({
      codeName: "COUNTRY",
    });
  const { onChange: onPrefixChange, ...restPrefixField } = register(
    prefixName as Path<TFieldValues>,
    {
      required: isRequired,
    }
  );
  const { onChange: onContactChange, ...restContactField } = register(
    restContactName as Path<TFieldValues>,
    {
      required: isRequired,
    }
  );
  const contactPrefixValue = countryCodeList?.find(
    ({ value1, codeItemName, codeItemNameKo }) =>
      (value1 ?? "") === prefixValue ||
      codeItemName === prefixValue ||
      codeItemNameKo === prefixValue
  );

  const joinValue = contactPrefixValue
    ? [
        contactPrefixValue?.codeItemName,
        contactPrefixValue?.codeItemNameEn,
        contactPrefixValue?.codeItemNameKo,
        contactPrefixValue?.value1,
      ].join(SEPARATOR)
    : undefined;

  const rawCountryDataToSelectOption = countryCodeList?.reduce<
    { label: string; value: string }[]
  >((acc, val) => {
    return [
      ...acc,
      {
        label: `${val.value1 ?? ""} (${val.codeItemName})`,
        value: [
          val.codeItemName,
          val.codeItemNameEn,
          val.codeItemNameKo,
          val.value1,
        ].join(SEPARATOR),
      },
    ];
  }, []);

  return (
    <FormItemContainer data-direction={direction} className={className}>
      {typeof label === "string" ? (
        <LabelContainer>
          <FormLabel data-direction={direction} data-required={isRequired}>
            {label}
          </FormLabel>
        </LabelContainer>
      ) : (
        label
      )}
      <ContactInputsContainer>
        <InputContainer>
          <StyledSelect
            {...restPrefixField}
            disabled={isFetching || disabled}
            suffixIcon={isFetching ? <Loader /> : undefined}
            value={joinValue}
            showSearch
            data-invalid={!!errors?.[prefixName]}
            placeholder={"+1 (CA)"}
            options={rawCountryDataToSelectOption}
            onChange={(value) => {
              const [codeName] = (value as string).split(SEPARATOR);
              const codeValue =
                countryCodeList?.find(
                  ({ codeItemName }) => codeItemName === codeName
                )?.value1 ?? "";
              setValue?.(
                prefixName as Path<TFieldValues>,
                codeValue as PathValue<TFieldValues, Path<TFieldValues>>,
                { shouldValidate: true }
              );
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement;
            }}
          />
          <StyledInput
            {...restContactField}
            placeholder={t("signupCorp:content.contactPlaceholder")}
            data-invalid={!!errors?.[restContactName]}
            disabled={disabled}
            onBlur={(e) => {
              restContactField.onBlur(e);
              const noZero = e.target.value.replace(/^0+/, "");
              setValue?.(
                restContactName as Path<TFieldValues>,
                noZero as PathValue<TFieldValues, Path<TFieldValues>>,
                { shouldValidate: true }
              );
            }}
            onChange={(e) => {
              const value = e.target.value;
              const onlyNumber = value.replace(/[^0-9]/g, "");
              setValue?.(
                restContactName as Path<TFieldValues>,
                onlyNumber as PathValue<TFieldValues, Path<TFieldValues>>,
                { shouldValidate: true }
              );
            }}
            onClear={() => {
              setValue?.(
                restContactName as Path<TFieldValues>,
                "" as PathValue<TFieldValues, Path<TFieldValues>>
              );
            }}
          />
        </InputContainer>
        {hasError && (
          <InputError message={errorMessage || t("error:required")} />
        )}
      </ContactInputsContainer>
    </FormItemContainer>
  );
};

export default ContactFormItem;

const StyledSelect = styled(Select)`
  width: 140px;
  flex-shrink: 0;

  &[data-invalid="true"] {
    div.ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
`;

const FormLabel = styled.p`
  ${typo.b7r};
  flex-shrink: 0;
  min-width: 163px;
  max-width: 163px;

  &[data-required="true"] {
    &::after {
      content: " *";
      color: ${colorSet.red2};
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormItemContainer = styled.div`
  display: flex;

  &[data-direction="vertical"] {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ContactInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;
