import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import * as HoverCard from "@radix-ui/react-hover-card";
import { Calendar, ConfigProvider } from "antd";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import Select from "@/src/components/atom/Select";
import Dot from "@/src/components/atom/Dot";
import Loader from "@/src/components/atom/Loader";
import typo from "@/src/styles/typography";
import {
  TaskCalendarInfoDto,
  TaskCalendarType,
} from "@/src/store/apis/dashboard/interface";
import useAlert from "@/src/hooks/useAlert";
import {
  MONTH_OPTION_LIST,
  TASK_CALENDER_TYPE_TASK_TYPE_MAP,
  getYearOptionList,
} from "../util/calendar";
import { useLazyGetTaskCalendarInfosQuery } from "@/src/store/apis/dashboard";
import { useLazyGetFactoriesQuery } from "@/src/store/apis/client/factory";
import { isUndefined } from "@/src/utils/is";
import { useLazyGetItemListQuery } from "@/src/store/apis/corporate/companyItemManegement";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";

interface TaskCalendarProps {
  year: number;
  month: number;
  factory?: string;
  item?: string;
  onYearChange?: (year: number) => void;
  onMonthChange?: (month: number) => void;
  onFactoryChange?: (factory?: string) => void;
  onItemChange?: (item?: string) => void;
}

const SELECT_STYLE = {
  width: "200px",
};

const DAY_SELECT_STYLE = {
  width: "94px",
};

const array: any[] = [];

const TaskCalendar = ({
  year,
  month,
  factory,
  item,
  onYearChange,
  onMonthChange,
  onFactoryChange,
  onItemChange,
}: TaskCalendarProps) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [getLoadingCalendarInfo, { loadingList, isFetching }] =
    useLazyGetTaskCalendarInfosQuery({
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          loadingList: isUnstable ? array : currentData.rows,
          isFetching,
        };
      },
    });
  const [getFactories, { factoryList, isFactoryFetching }] =
    useLazyGetFactoriesQuery({
      selectFromResult: ({ isFetching, isError, currentData }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        return {
          factoryList: isUnstable ? array : currentData.rows,
          isFactoryFetching: isFetching,
        };
      },
    });
  const [getItems, { itemList, isItemsFetching }] = useLazyGetItemListQuery({
    selectFromResult: ({ isFetching, isError, currentData }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;
      return {
        itemList: isUnstable ? array : currentData.rows,
        isItemsFetching: isFetching,
      };
    },
  });
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const yearOptions = getYearOptionList({ endYear: 2030 });

  const listDatas =
    loadingList.reduce<Record<number, TaskCalendarInfoDto[]>>((acc, val) => {
      if (!acc[dayjs(val.dateAt).date()]) {
        acc[dayjs(val.dateAt).date()] = [val];
      } else {
        acc[dayjs(val.dateAt).date()].push(val);
      }

      return acc;
    }, {}) ?? {};

  const factoriesToOptions = factoryList.map(({ workplaceName }) => ({
    label: workplaceName,
    value: workplaceName,
  }));

  const itemsToOptions = itemList.map(({ item }) => ({
    label: item,
    value: item,
  }));

  const fetchLoadingInfo = async ({
    paramYear,
    paramMonth,
    paramWorkplaceName,
    paramItemCode,
  }: {
    paramYear: number;
    paramMonth: number;
    paramWorkplaceName?: string;
    paramItemCode?: string;
  }) => {
    try {
      const calendarMonth = dayjs(
        new Date(paramYear || year, (paramMonth || month) - 1)
      );
      const dateAtFrom = calendarMonth.startOf("M").toISOString();
      const dateAtTo = calendarMonth.endOf("M").toISOString();

      await getLoadingCalendarInfo({
        dateAtFrom,
        dateAtTo,
        workplaceName: paramWorkplaceName,
        item: paramItemCode,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;

      if (e.data.statusCode !== 404) {
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const cellRender = (value: Dayjs) => {
    const listData =
      value.month() + 1 === month ? listDatas[value.date()] || [] : [];

    if (isFetching) {
      return null;
    }

    return (
      <>
        <CalendarEventList>
          {listData.map((item) => (
            <HoverCard.Root openDelay={300} closeDelay={0}>
              <HoverCard.Trigger asChild>
                <CalendarEventListItem
                  key={item.taskId}
                  onClick={() =>
                    navigate(
                      `${EXPORTER_PRIVATE_PATH.LOADING_DETAIL}/${item.taskId}`
                    )
                  }
                >
                  <Dot
                    className="flex-shrink-0"
                    dotColor={
                      item.taskCalendarType === "CARGO_CUT_OFF"
                        ? "systemGrape2"
                        : item.taskCalendarType === "DOC_CUT_OFF"
                        ? "blue2"
                        : item.taskCalendarType === "WORKING_DAY"
                        ? "green2"
                        : "systemOrange2"
                    }
                  />
                  <Typo typoType="b9m" className="ellipsis">
                    {item.exporterItemCode}{" "}
                    <Typo typoType="b10r" color="gray6">
                      {
                        TASK_CALENDER_TYPE_TASK_TYPE_MAP[
                          item.taskCalendarType as TaskCalendarType
                        ]
                      }
                    </Typo>
                  </Typo>
                </CalendarEventListItem>
              </HoverCard.Trigger>

              <HoverCard.Portal>
                <StyledHoverCardContent>
                  <StyledHoverCardInnerContent>
                    <p>Importer Code: {item.buyerNameCode}</p>
                    <p>Item Code: {item.exporterItemCode}</p>
                    <p>No of Container: {item.numberOfTaskContainer}</p>
                    <p>Factory: {item.workplaceName}</p>
                  </StyledHoverCardInnerContent>
                  <HoverCard.Arrow />
                </StyledHoverCardContent>
              </HoverCard.Portal>
            </HoverCard.Root>
          ))}
        </CalendarEventList>
      </>
    );
  };

  useLayoutEffect(() => {
    if (calendarContainerRef.current) {
      const a = calendarContainerRef.current.querySelectorAll("td");
      a.forEach((td) => (td.title = ""));
    }
  }, [year, month, factory]);

  useEffect(() => {
    (async () => {
      try {
        const date = new Date(year, month - 1);

        await getLoadingCalendarInfo({
          dateAtFrom: dayjs(date).startOf("M").toISOString(),
          dateAtTo: dayjs(date).endOf("M").toISOString(),
          workplaceName: factory || undefined,
        }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await getFactories({}).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await getItems({ isManagerFilterOn: true }).unwrap();
      } catch (e: any) {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;

        if (e.data.statusCode !== 404) {
          alert.showAlert({ type: "error", message });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCalendarHeader = (renderHelper: {
    value: dayjs.Dayjs;
    onChange: (date: dayjs.Dayjs) => void;
  }) => {
    return (
      <CalendarHeaderSection>
        <Typo typoType="h4" as="h4">
          {dayjs(renderHelper.value).format("YYYY.MM")}
        </Typo>
        <ControlSection>
          <Select
            size="middle"
            style={DAY_SELECT_STYLE}
            options={yearOptions}
            value={year}
            onChange={(value) => {
              fetchLoadingInfo({
                paramYear: Number(value),
                paramMonth: month,
                paramItemCode: item,
                paramWorkplaceName: factory,
              });
              onYearChange?.(Number(value));
            }}
          />
          <Select
            size="middle"
            style={DAY_SELECT_STYLE}
            options={MONTH_OPTION_LIST}
            value={month}
            onChange={(value) => {
              fetchLoadingInfo({
                paramYear: year,
                paramMonth: Number(value),
                paramItemCode: item,
                paramWorkplaceName: factory,
              });
              onMonthChange?.(Number(value));
            }}
          />
          <Label>
            <Typo typoType="b10r" color="gray4">
              Factory
            </Typo>

            <Select
              size="middle"
              options={[{ label: "All", value: "all" }, ...factoriesToOptions]}
              style={SELECT_STYLE}
              value={factory || "all"}
              suffixIcon={isFactoryFetching ? <Loader /> : undefined}
              onChange={(value) => {
                fetchLoadingInfo({
                  paramYear: year,
                  paramMonth: month,
                  paramItemCode: item,
                  paramWorkplaceName: value === "all" ? undefined : value,
                });
                onFactoryChange?.(value === "all" ? undefined : value);
              }}
            />
          </Label>

          <Label>
            <Typo typoType="b10r" color="gray4">
              Item
            </Typo>

            <Select
              size="middle"
              options={[{ label: "All", value: "all" }, ...itemsToOptions]}
              style={SELECT_STYLE}
              value={item || "all"}
              suffixIcon={isItemsFetching ? <Loader /> : undefined}
              onChange={(value) => {
                fetchLoadingInfo({
                  paramYear: year,
                  paramMonth: month,
                  paramItemCode: value === "all" ? undefined : value,
                  paramWorkplaceName: factory,
                });
                onItemChange?.(value === "all" ? undefined : value);
              }}
            />
          </Label>
        </ControlSection>
      </CalendarHeaderSection>
    );
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Calendar: {
              itemActiveBg: colorSet.white,
              colorText: colorSet.gray2,
              colorTextDisabled: colorSet.gray7,
            },
          },
        }}
      >
        <div ref={calendarContainerRef}>
          <StyledCalendar
            value={dayjs(new Date(year, month - 1))}
            cellRender={cellRender}
            headerRender={renderCalendarHeader}
          />
        </div>
      </ConfigProvider>
    </>
  );
};

export default TaskCalendar;

const StyledCalendar = styled(Calendar)`
  .ant-picker-cell-selected .ant-picker-calendar-date-value {
    color: ${colorSet.gray2} !important;
  }

  .ant-picker-cell-today .ant-picker-calendar-date-value {
    color: ${colorSet.blue4} !important;
  }

  .ant-picker-calendar-date-content {
    height: 144px !important;
  }

  .ant-picker-cell {
    pointer-events: none;
  }

  thead {
    th {
      ${typo.b7m};
      color: ${colorSet.gray7} !important;
    }
  }
`;

const CalendarEventList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CalendarEventListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  pointer-events: auto;
  cursor: pointer;

  .flex-shrink-0 {
    flex-shrink: 0;
  }

  .ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CalendarHeaderSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const ControlSection = styled.div`
  display: flex;
  gap: 16px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledHoverCardContent = styled(HoverCard.Content)`
  z-index: 10;
`;

const StyledHoverCardInnerContent = styled.div`
  background: rgba(0, 0, 0, 0.8);
  color: ${colorSet.white};
  padding: 4px 8px;
  border-radius: 4px;
  ${typo.b10m}
`;
