import { excelDownload } from "@/src/utils/excelDownload";
import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import {
  BuyerEntireRankStatisticInfoByItemCodeDto,
  BuyerEntireRankStatisticInfoByItemDto,
  BuyerStatisticInfoDto,
  BuyerTopRankStatisticInfoByItemCodeDto,
  BuyerTopRankStatisticInfoByItemDto,
  BuyerTopRankStatisticInfoDto,
  ContractCountAndCumulativeAmountsDtoByBuyerId,
  DashboardMainCategoryParams,
  DashboardPeriodParams,
  ExporterItemCodeStatisticInfoDto,
  ExporterItemEntireRankStatisticInfoByBuyerDto,
  ExporterItemEntireRankStatisticInfoByItemCodeDto,
  ExporterItemStatisticInfoDto,
  ExporterItemTopRankStatisticInfoByBuyerDto,
  ExporterItemTopRankStatisticInfoByItemCodeDto,
  GetTaskCalendarInfosParams,
  MainCategoryTopRankStatisticInfoDto,
  MainDomainSummaryInfoDto,
  ShipmentRateInfoDto,
  TaskCalendarInfoDto,
} from "./interface";

const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMainDomainSummaryInfo: build.query<MainDomainSummaryInfoDto, void>({
      query: () => ({
        url: "/exporters/this/dashboard/main-domain-summary-info",
      }),
      transformResponse: (res: Row<MainDomainSummaryInfoDto>) => res.row,
    }),
    getTaskCalendarInfos: build.query<
      Rows<TaskCalendarInfoDto>,
      GetTaskCalendarInfosParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/task-calendar-infos",
        params,
      }),
    }),
    getExporterItemStatisticInfo: build.query<
      ExporterItemStatisticInfoDto,
      {
        mainCategoryCodeItemName?: string;
        exporterItemId?: number;
        exporterItemIds?: string;
        etdAtFrom: string;
        etdAtTo: string;
      }
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-statistic-info",
        params,
      }),
      transformResponse: (res: Row<ExporterItemStatisticInfoDto>) => res.row,
    }),

    getExporterItemCodeStatisticInfo: build.query<
      ExporterItemCodeStatisticInfoDto,
      {
        mainCategoryCodeItemName?: string;
        exporterItemIds?: string;
        etdAtFrom: string;
        etdAtTo: string;
      }
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-code-statistic-info",
        params,
      }),
      transformResponse: (res: Row<ExporterItemCodeStatisticInfoDto>) =>
        res.row,
    }),

    getBuyerTopRankStatisticInfo: build.query<
      Row<BuyerTopRankStatisticInfoDto>,
      DashboardPeriodParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-top-rank-statistic-info",
        params,
      }),
    }),
    getBuyerTopRankStatisticInfoByItem: build.query<
      Row<BuyerTopRankStatisticInfoByItemDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-top-rank-statistic-info-by-item",
        params,
      }),
    }),
    getBuyerEntireRankStatisticInfo: build.query<
      Rows<BuyerEntireRankStatisticInfoByItemDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-entire-rank-statistic-info-by-item",
        params,
      }),
    }),
    getExcelBuyerEntireRankStatisticInfoByItem: build.query<
      void,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/excel-download/dashboard/buyer-entire-rank-statistic-info-by-item",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            // manualExcelFileName: "tasks_list.xlsx",
          });
        },
      }),
    }),
    getBuyerTopRankStatisticInfoByItemCode: build.query<
      Row<BuyerTopRankStatisticInfoByItemCodeDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-top-rank-statistic-info-by-item-code",
        params,
      }),
    }),
    getBuyerEntireRankStatisticInfoByItemCode: build.query<
      Rows<BuyerEntireRankStatisticInfoByItemCodeDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-entire-rank-statistic-info-by-item-code",
        params,
      }),
    }),
    getExcelBuyerEntireRankStatisticInfoByItemCode: build.query<
      void,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/excel-download/dashboard/buyer-entire-rank-statistic-info-by-item-code",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            // manualExcelFileName: "tasks_list.xlsx",
          });
        },
      }),
    }),
    getMainCategoryTopRankStatisticInfo: build.query<
      Row<MainCategoryTopRankStatisticInfoDto>,
      DashboardPeriodParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/main-category-top-rank-statistic-info",
        params,
      }),
    }),
    getExporterItemTopRankStatisticInfoByBuyer: build.query<
      Row<ExporterItemTopRankStatisticInfoByBuyerDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-top-rank-statistic-info-by-buyer",
        params,
      }),
    }),
    getExporterItemEntireRankStatisticInfoByBuyer: build.query<
      Rows<ExporterItemEntireRankStatisticInfoByBuyerDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-entire-rank-statistic-info-by-buyer",
        params,
      }),
    }),
    getExcelExporterItemEntireRankStatisticInfoByBuyer: build.query<
      void,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/excel-download/dashboard/exporter-item-entire-rank-statistic-info-by-buyer",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            // manualExcelFileName: "tasks_list.xlsx",
          });
        },
      }),
    }),
    getExporterItemTopRankStatisticInfoByItemCode: build.query<
      Row<ExporterItemTopRankStatisticInfoByItemCodeDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-top-rank-statistic-info-by-item-code",
        params,
      }),
    }),
    getExporterItemEntireRankStatisticInfoByItemCode: build.query<
      Rows<ExporterItemEntireRankStatisticInfoByItemCodeDto>,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/exporter-item-entire-rank-statistic-info-by-item-code",
        params,
      }),
    }),
    getExcelExporterItemEntireRankStatisticInfoByItemCode: build.query<
      void,
      DashboardPeriodParams & DashboardMainCategoryParams
    >({
      query: (params) => ({
        url: "/exporters/this/excel-download/dashboard/exporter-item-entire-rank-statistic-info-by-item-code",
        params: {
          ...params,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        responseHandler: async (response) => {
          excelDownload({
            response,
            // manualExcelFileName: "tasks_list.xlsx",
          });
        },
      }),
    }),

    getBuyerStatisticInfo: build.query<
      BuyerStatisticInfoDto,
      { buyerId?: number; etdAtFrom: string; etdAtTo: string }
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/buyer-statistic-info",
        params,
      }),
      transformResponse: (res: Row<BuyerStatisticInfoDto>) => res.row,
    }),

    getShipmentRateInfo: build.query<
      Rows<ShipmentRateInfoDto>,
      { page?: number; pageSize?: number }
    >({
      query: (params) => ({
        url: "/exporters/this/dashboard/shipment-rate-infos",
        params,
      }),
    }),

    getContractCountAndCumulativeAmountsByBuyerId: build.query<
      ContractCountAndCumulativeAmountsDtoByBuyerId,
      { buyerId: number }
    >({
      query: (params) => ({
        url: `/exporters/this/dashboard/contract-count-and-cumulative-amounts/${params.buyerId}`,
      }),
      transformResponse: (
        res: Row<ContractCountAndCumulativeAmountsDtoByBuyerId>
      ) => res.row,
    }),
  }),
});

export const {
  useLazyGetTaskCalendarInfosQuery,
  useGetMainDomainSummaryInfoQuery,
  useGetExporterItemStatisticInfoQuery,
  useGetExporterItemCodeStatisticInfoQuery,
  useLazyGetExporterItemStatisticInfoQuery,
  useLazyGetBuyerStatisticInfoQuery,
  useGetShipmentRateInfoQuery,

  useGetBuyerTopRankStatisticInfoQuery,
  useGetBuyerTopRankStatisticInfoByItemQuery,
  useGetBuyerTopRankStatisticInfoByItemCodeQuery,
  useGetMainCategoryTopRankStatisticInfoQuery,
  useGetExporterItemTopRankStatisticInfoByBuyerQuery,
  useGetExporterItemTopRankStatisticInfoByItemCodeQuery,

  useGetBuyerEntireRankStatisticInfoQuery,
  useGetBuyerEntireRankStatisticInfoByItemCodeQuery,
  useGetExporterItemEntireRankStatisticInfoByBuyerQuery,
  useGetExporterItemEntireRankStatisticInfoByItemCodeQuery,

  useLazyGetExcelBuyerEntireRankStatisticInfoByItemQuery,
  useLazyGetExcelBuyerEntireRankStatisticInfoByItemCodeQuery,
  useLazyGetExcelExporterItemEntireRankStatisticInfoByBuyerQuery,
  useLazyGetExcelExporterItemEntireRankStatisticInfoByItemCodeQuery,

  useGetContractCountAndCumulativeAmountsByBuyerIdQuery,
} = dashboardApi;
