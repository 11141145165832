export const commonPlApiFieldKey = [
  "shipper",
  "shipperTitle",
  "consignee",
  "consigneeTitle",
  "notifyParty",
  "notifyPartyTitle",
  "isNotifyPartySameAsConsignee",
  "isNotifyPartyHidden",
  "invoiceDateAt",
  "invoicoNoAndDateTitle",
  "poNoTitle",
  "isPoNoHidden",
  "scNoTitle",
  "isScNoHidden",
  "lcInfoList",
  "lcNoAndDateTitle",
  "remark",
  "remarkTitle",
  "isRemarkHidden",
  "portOfLoading",
  "portOfLoadingCodeItemName",
  "portOfLoadingTitle",
  "isPortOfLoadingHidden",
  "portOfDischarge",
  "portOfDischargeCodeItemName",
  "portOfDischargeTitle",
  "isPortOfDischargeHidden",
  "placeOfDelivery",
  "placeOfDeliveryCodeItemName",
  "placeOfDeliveryTitle",
  "isPlaceOfDeliveryHidden",
  "vessel",
  "voyageNo",
  "isVesselAndVoyageNoHidden",
  "etdAt",
  "etdTitle",
  "isEtdHidden",
  "paymentTerms",
  "paymentTermsTitle",
  "isPaymentTermsHidden",
  "shippingTerms",
  "shippingTermsTitle",
  "isShippingTermsHidden",
  "shipmentDocumentType",
  "plItems",
  "simpleSignatureMedias",
];
