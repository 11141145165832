const zIndex = {
  backgroundImage: -1,
  sideNav: 100,
  bottomFixedButtons: 8000,
  paginationDropDown: 8010,
  dropdown: 8900,
  dialog: 9000,
  alertDialog: 9500,
  antdImageBase: 9700,
  antdImage: 9800,
  alert: 9999,
  fullLoading: 10001,
  popup: 10500,
};

export default zIndex;

type ZindexType = keyof typeof zIndex;
export { type ZindexType };
