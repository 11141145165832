import { apiSlice } from "../baseQuery";
import { Row, Rows } from "../type";
import {
  AddressDto,
  AddressListDto,
  CommonCodeNameType,
  FormattedAddressByGeocodeDtos,
  PartialCommonCodeItemDto,
  PopUpDto,
  TermsForAnonymousDto,
  TermsServiceType,
  TimeZoneDto,
} from "./interface";

const commonApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTerms: build.query<
      TermsForAnonymousDto[],
      { service: TermsServiceType; titleEn?: string }
    >({
      query: ({ service, titleEn }) => ({
        url: "/terms",
        params: {
          service,
          titleEn,
        },
      }),
      transformResponse: (res: Rows<TermsForAnonymousDto>) => res.rows,
    }),
    getCommonCodeViaCodeName: build.query<
      PartialCommonCodeItemDto[],
      {
        codeName?: CommonCodeNameType;
        parentCodeItemName?: string;
        sort?: "codeItemName" | "codeItemNameEn" | "codeItemNameKo";
        order?: "ASC" | "DESC";
      }
    >({
      query: ({ codeName, parentCodeItemName, sort, order }) => ({
        url: "/common-code-items",
        params: {
          codeName,
          parentCodeItemName,
          sort,
          order,
        },
      }),
      transformResponse: (res: Rows<PartialCommonCodeItemDto>) => res.rows,
    }),
    getAddress: build.query<
      AddressListDto[],
      { input: string; language: "en" | "ko" }
    >({
      query: ({ input, language }) => ({
        url: "/addresses",
        params: {
          input,
          language,
        },
      }),
      transformResponse: (res: Rows<AddressListDto>) => res.rows,
    }),
    getAddressDetail: build.query<
      AddressDto,
      { placeId: string; language: "en" | "ko" }
    >({
      query: ({ placeId, language }) => ({
        url: "/address/details",
        params: {
          placeId,
          language,
        },
      }),
      transformResponse: (res: Row<AddressDto>) => res.row,
    }),

    getAddressByGeocdoe: build.query<
      Row<FormattedAddressByGeocodeDtos>,
      { latitude: string; longitude: string; language: "en" | "ko" }
    >({
      query: ({ latitude, longitude, language }) => ({
        url: "/address/formatted-address-by-geocode",
        params: {
          latitude,
          longitude,
          language,
        },
      }),
    }),

    getPopup: build.query<Rows<PopUpDto>, void>({
      query: () => ({
        url: "/pop-ups",
        params: {
          service: "EXPORT_WEB",
          isViewable: true,
        },
      }),
    }),

    getCommonCodeList: build.query<
      PartialCommonCodeItemDto[][],
      {
        codeNameList: {
          codeName: CommonCodeNameType;
          parentCodeItemName?: string;
          sort?: "codeItemName" | "codeItemNameEn" | "codeItemNameKo";
          order?: "ASC" | "DESC";
        }[];
      }
    >({
      queryFn: async (arg, _api, _extraOptions, baseQuery) => {
        if (!arg.codeNameList) {
          return {
            data: [],
          };
        }

        const response = await Promise.all(
          arg.codeNameList.map(
            ({ codeName, parentCodeItemName, sort, order }) => {
              return baseQuery({
                url: "/common-code-items",
                params: {
                  codeName,
                  parentCodeItemName,
                  sort,
                  order,
                },
              });
            }
          )
        );

        const data = response.map((res) => {
          if (res.error) {
            return [];
          } else {
            return (res.data as Rows<PartialCommonCodeItemDto>).rows;
          }
        });

        return {
          data,
        };
      },
    }),

    getTimeZones: build.query<
      TimeZoneDto[],
      | {
          countryCode?: string;
          countryName?: string;
          zoneName?: string;
          page?: number;
          pageSize?: number;
        }
      | undefined
    >({
      query: (params) => ({
        url: "/time-zones",
        params,
      }),
      transformResponse: (res: Rows<TimeZoneDto>) => res.rows,
    }),
  }),
});

export const {
  useGetTermsQuery,
  useGetCommonCodeViaCodeNameQuery,
  useLazyGetCommonCodeViaCodeNameQuery,
  useLazyGetAddressQuery,
  useLazyGetAddressDetailQuery,
  useGetPopupQuery,
  useGetCommonCodeListQuery,
  useGetAddressByGeocdoeQuery,
  useGetTimeZonesQuery,
} = commonApi;

export { commonApi };
