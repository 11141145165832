import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import {
  ContractDetailShareInfoDto,
  GetContractSharedRequest,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const contractShareApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    //  이메일로 전송받은 contractShareKey와 contractId로 계약 단일 조회 API
    getContractShared: build.query<
      Row<ContractDetailShareInfoDto>,
      GetContractSharedRequest
    >({
      query: (params) => ({
        url: "/contracts/this",
        params,
      }),
      providesTags: [""],
    }),
  }),
});

export const { useLazyGetContractSharedQuery } = contractShareApi;
export { contractShareApi };
