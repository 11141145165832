import Typo from "@/src/components/atom/Typo";
import React, { HTMLAttributes } from "react";
import NoShipmentSvg from "@/src/assets/icons/icon-no-shipment.svg";
import Icon from "@/src/components/atom/Icon";
import Flex from "@/src/components/molecule/Flex";
import styled from "styled-components";

interface NoShipmentDataProps extends HTMLAttributes<HTMLDivElement> {
  iconSize?: number;
  height?: number;
}

function NoShipmentData({
  height = 110,
  iconSize = 52,
  ...rest
}: NoShipmentDataProps) {
  return (
    <StyledFlex
      flexDirection="column"
      alignItems="center"
      height={height}
      {...rest}
    >
      <Icon iconSrc={NoShipmentSvg} iconSize={iconSize} />
      <Typo typoType="b7m" color="gray8">
        No Shipment
      </Typo>
    </StyledFlex>
  );
}

export default NoShipmentData;

const StyledFlex = styled(Flex)<{ height: number }>``;
