import dayjs from "dayjs";

export const contractFormDefaultValue = {
  poNo: "",
  importerBuyerId: "",
  importerBuyerEmployeeId: "",
  allocation: undefined,
  importerOrderDateAt: dayjs(),
  isAddContractTerms: ["true"],
  hsCode: undefined,
  mainCategory: "",
  subCategory: "",
  item: "",
  importerItemId: "",
  importerItemCodeId: undefined,
  importerItemCode: "",
  amount: "",
  quantityUnitCodeItemName: "MEASUREMENT_MT",
  quantityUnit: "MT",
  quantity: "",
  unitPriceUnit: "USD",
  unitPriceUnitCodeItemName: "USD",
  unitPrice: "",
  lastShipmentDateAt: undefined,
  origin: undefined,
  freetime: undefined,
  freetimeCodeItemName: undefined,
  freetimeDirect: undefined,
  placeOfDelivery: undefined,
  placeOfDeliveryCodeItemName: undefined,
  portOfDischarge: undefined,
  placeOfDischargeCodeItemName: undefined,
  paymentTerms: undefined,
  paymentTermsCodeItemName: undefined,
  paymentTermsRemark: undefined,
  paymentTermsMediaId: undefined,
  shippingTerms: undefined,
  shippingTermsCodeItemName: undefined,
  shippingTermsRemark: undefined,
  referenceMediaIdList: [],
  contractRemark: undefined,
  memo: undefined,
  poMediaId: undefined,
  lcNo: undefined,
  expireDateAt: "",
  deliveryDateAt: undefined,
  importerContractRemark: undefined,
  importerHsCode: "",
};

export const contractAddBasedOnScForm = {
  poNo: "",
  sellerId: "",
  sellerEmployeeId: "",
  orderDateAt: "",
  lastShipmentDateAt: undefined,
  freetime: undefined,
  freetimeCodeItemName: undefined,
  freetimeDirect: undefined,
  deliveryDateAt: undefined,

  hsCode: "",
  mainCategory: "",
  subCategory: "",
  item: "",
  exporterItemId: "",
  exporterItemCodeId: undefined,
  exporterItemCode: "",
  origin: "",
  isAddContractTerms: ["true"],

  poMediaId: undefined,
  referenceMediaIdList: [],
  contractRemark: undefined,
  memo: undefined,
  importerOrderDateAt: undefined,
};
