import { apiSlice } from "../../baseQuery";
import { Row } from "../../type";
import { BookingDetailViewDto, GenerateBookingDto } from "./interface";

export const bookingRegisterApi = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      createBooking: build.mutation<
        Row<BookingDetailViewDto>,
        GenerateBookingDto
      >({
        query: (body) => ({
          url: "/exporters/this/bookings",
          method: "POST",
          body,
        }),
      }),
      checkBookingNo: build.query<void, { bookingNo: string }>({
        query: ({ bookingNo }) => ({
          url: "/exporters/this/booking/booking-no-check",
          params: {
            bookingNo,
          },
        }),
      }),
    };
  },
});

export const { useCreateBookingMutation, useLazyCheckBookingNoQuery } =
  bookingRegisterApi;
