import { isUndefined } from "@/src/utils/is";
import { useContext } from "react";
import { StatisticsFilterRootContext } from "..";

export const useImporterStatisticsFilterContext = () => {
  const context = useContext(StatisticsFilterRootContext);
  if (isUndefined(context)) {
    throw Error("수입자 대시보드 Context가 존재 하지 않습니다.");
  }
  return context;
};
