import colorSet, { ColorType } from "@/src/styles/color";
import React, { PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {
  TooltipArrowProps,
  TooltipContentProps,
  TooltipPortalProps,
  TooltipProps as RootTooltipProps,
  TooltipProviderProps,
} from "@radix-ui/react-tooltip";
import zIndex from "@/src/styles/zIndex";

interface RadixTooltipProps extends RootTooltipProps {
  content?: ReactNode;
  provierProps?: TooltipProviderProps;
  portalProps?: TooltipPortalProps;
  contentProps?: TooltipContentProps;
  isArrow?: boolean;
  arrowProps?: TooltipArrowProps;
  arrowColor?: ColorType;
}

function RadixTooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  portalProps,
  contentProps,
  isArrow = true,
  arrowProps,
  provierProps,
  arrowColor: tooltipColor = "gray2",
  ...props
}: PropsWithChildren<RadixTooltipProps>) {
  return (
    <TooltipPrimitive.Provider {...provierProps}>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        delayDuration={0}
        {...props}
      >
        <TooltipTrigger asChild>{children}</TooltipTrigger>

        <TooltipPrimitive.Portal {...portalProps}>
          <TooltipContent sideOffset={5} {...contentProps}>
            {content}

            <StyledArrow tooltipColor={tooltipColor} {...arrowProps} />
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

export default RadixTooltip;

const TooltipTrigger = styled(TooltipPrimitive.Trigger)`
  outline: none;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

const StyledArrow = styled(TooltipPrimitive.Arrow)<{
  tooltipColor: ColorType;
}>`
  fill: ${({ tooltipColor }) => colorSet[tooltipColor]};
`;

const TooltipContent = styled(TooltipPrimitive.Content)`
  z-index: ${zIndex.alertDialog};
`;
