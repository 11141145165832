import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { styled } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import { useAppSelector } from "@/src/store";
import Dialog from "@/src/components/atom/Dialog";
import Loader from "@/src/components/atom/Loader";
import Select from "@/src/components/atom/Select";
import Table from "@/src/components/atom/Table";
import { useGetBuyersQuery } from "@/src/store/apis/client/buyer";
import { ColDef, IRowNode, RowClickedEvent } from "ag-grid-community";
import UnRadioCheckSvg from "@/src/assets/icons/icon-radio-unchecked.svg";
import RadioCheckSvg from "@/src/assets/icons/icon-radio-checked.svg";
import { Button } from "@/src/components/atom/Button";
import Typo from "@/src/components/atom/Typo";
import colorSet from "@/src/styles/color";
import useAlert from "@/src/hooks/useAlert";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { useLazyGetContractListAssociatedWithBookingQuery } from "@/src/store/apis/contracts/contractList";
import { BookingContractListViewDto } from "@/src/store/apis/contracts/contractList/interface";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

interface LoadContractDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect?: (detail?: BookingContractListViewDto) => void;
  defaultSelectedItemId?: number;
  bookingId: number;
}

const emptyArray: [] = [];

const LoadContractDialog = ({
  open,
  onClose,
  onSelect,
  defaultSelectedItemId,
  bookingId,
}: LoadContractDialogProps) => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.lang.value);
  const [selectedId, setSelectedId] = useState<number | null>(
    defaultSelectedItemId || null
  );
  const alert = useAlert();
  const [buyerCode, setBuyerCode] = useState<string>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const ref = useRef<AgGridReact>(null);
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "",
      field: "radioButton",
      width: 60,
      cellRenderer: (params: IRowNode) => (
        <>{radioRenderer(params, setSelectedId)}</>
      ),
      lockPosition: "left",
      pinned: "left",
      sortable: false,
      resizable: false,
    },
    ...column,
  ]);

  useAgGridHeaderRefresh({
    gridRef: ref.current,
    isReady,
    headerSet: [
      {
        columnKey: "createdAt",
        langKey: "task:exporter.add.table.contractRegistration",
      },
    ],
  });

  const {
    currentData: buyersList,
    isError: isBuyerListError,
    isFetching: isBuyerListFetching,
  } = useGetBuyersQuery({});
  const [getContractList, { isFetching, count, list }] =
    useLazyGetContractListAssociatedWithBookingQuery({
      selectFromResult: ({ currentData, isError, isFetching }) => {
        return {
          isFetching,

          list:
            !isError && !isUndefined(currentData)
              ? currentData.rows
              : emptyArray,
          count: currentData?.count || 0,
        };
      },
    });

  const contractList = list.map((contract) => {
    return {
      ...contract,
      isChecked: selectedId === contract.id,
    };
  });

  const buyersListToOptions = isBuyerListError
    ? []
    : buyersList?.data.reduce<{ label: string; value: string }[]>(
        (acc, val) => {
          const resource = {
            label: val?.buyerNameCode || "",
            value:
              `${val.buyerNameCode}_${val.buyerListQueryResultDto.id}` || "",
          };
          return [...acc, resource];
        },
        [{ label: lang === "en" ? "All" : "전체", value: "all" }]
      );

  const handleSelect = () => {
    onSelect?.(contractList.find(({ id }) => id === selectedId));
    onClose();
    alert.showAlert({
      type: "success",
      message: t("task:add.alert.selectContractSuccess"),
    });
  };

  const handleRowClicked = (params: RowClickedEvent) =>
    setSelectedId(params.data.id);

  useEffect(() => {
    if (isFetching && ref.current) {
      ref.current.api.showLoadingOverlay();
    }
  }, [isFetching]);

  useEffect(() => {
    (async () => {
      await getContractList({
        id: bookingId,
        page: 1,
        pageSize: 10,
        contractStatus: "PROCESSING",
      });
    })();
  }, [bookingId, getContractList]);

  return (
    <>
      <Dialog
        title={t("task:add.dialog.selectContract")}
        open={open}
        onOpenChange={onClose}
        width={1000}
        footer={
          <ButtonContainer>
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              onClick={onClose}
            >
              {t("task:add.common.exit")}
            </Button>
            <Button disabled={!selectedId} onClick={() => handleSelect()}>
              {t("task:add.buttonLabel.select")}
            </Button>
          </ButtonContainer>
        }
      >
        <Content>
          <InputContainer>
            <Typo typoType="b7m">{"Importer Code"}</Typo>
            <Select
              options={buyersListToOptions}
              showSearch
              defaultValue={"all"}
              suffixIcon={isBuyerListFetching ? <Loader /> : undefined}
              disabled={isBuyerListFetching}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentElement;
              }}
              onChange={async (value, option: any) => {
                setBuyerCode(value === "all" ? undefined : option.label);
                setPagination({ page: 1, pageSize: pagination.pageSize });
                await getContractList({
                  id: bookingId,
                  page: 1,
                  buyerNameCode: value === "all" ? undefined : option.label,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </InputContainer>
          <Table
            ref={ref}
            onGridReady={() => setIsReady(true)}
            columnDefs={columnDefs}
            rowData={contractList}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPage={count}
            handlePaginationClick={async (page, pageSize) => {
              setPagination({ page, pageSize });
              await getContractList({
                page,
                pageSize,
                id: bookingId,
                buyerNameCode: buyerCode,
              });
            }}
            onRowClicked={handleRowClicked}
          />
        </Content>
      </Dialog>
    </>
  );
};

export default LoadContractDialog;

const Content = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ag-theme-alpine {
    outline: 1px solid ${colorSet.gray9};
    border-radius: 8px;
  }
`;

const commonColumn = {
  sortable: false,
  headerClass: "ag-right-aligned-cell",
  cellStyle: { textAlign: "center" },
};

const column: ColDef[] = [
  {
    headerName: "Importer Code",
    field: "buyerNameCode",
    width: 180,
    ...commonColumn,
  },
  {
    field: "createdAt",
    width: 180,
    cellRenderer: (params: any) => {
      return <>{dayjs(params.data.createdAt).format("YYYY-MM-DD")}</>;
    },
    ...commonColumn,
  },
  {
    headerName: "SC No.",
    field: "scNo",
    width: 140,
    ...commonColumn,
  },
  {
    headerName: "Item Code",
    field: "itemCode",
    width: 110,
    ...commonColumn,
  },
  {
    headerName: "Qty",
    field: "quantity",
    width: 110,
    ...commonColumn,
    cellRenderer: (params: any) => {
      return (
        <>
          {Number(params.data.quantity).toLocaleString()}{" "}
          {params.data.quantityUnit}
        </>
      );
    },
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Unit Price",
    field: "unitPrice",
    width: 110,
    ...commonColumn,
    cellRenderer: (params: any) => {
      return (
        <>
          {Number(params.data.unitPrice).toLocaleString()}{" "}
          {params.data.unitPriceUnit}
        </>
      );
    },
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Shipping Terms",
    field: "shippingTerms",
    cellRenderer: (params: any) => {
      return (
        <>
          {params.data.shippingTerms} {params.data.shippingTermsRemark ?? ""}
        </>
      );
    },
    width: 160,
    ...commonColumn,
  },
  {
    headerName: "Place of Delivery",
    field: "placeOfDelivery",
    width: 160,
    cellRenderer: (params: any) => {
      return <>{params.data.anyPortIn ?? params.data.placeOfDelivery ?? "-"}</>;
    },
    ...commonColumn,
  },
  {
    headerName: "Origin/Location",
    field: "origin",
    width: 150,
    ...commonColumn,
  },
  {
    headerName: "Last Shipment Date",
    field: "lastShipmentDateAt",
    cellRenderer: (params: any) => {
      return <>{dayjs(params.data.createdAt).format("YYYY-MM-DD")}</>;
    },
    width: 180,
    ...commonColumn,
  },
];

const StyledRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;

  .custom-ag-radio {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-radio-mark {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: url(${UnRadioCheckSvg}) no-repeat;
    background-size: cover;
  }

  .custom-ag-radio:checked + .custom-radio-mark {
    background: url(${RadioCheckSvg}) no-repeat;
    background-size: cover;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const radioRenderer = (params: IRowNode, onChange: (id: number) => void) => {
  const handleRadioChange = () => {
    onChange(params.data.id);
  };

  const checked = params.data.isChecked;

  return (
    <StyledRadio>
      <input
        type="radio"
        className="custom-ag-radio"
        checked={checked}
        onChange={handleRadioChange}
      />
      <span className="custom-radio-mark" />
    </StyledRadio>
  );
};
