import SectionCard from "@/src/components/molecule/SectionCard";
import React from "react";
import styled from "styled-components";
import InfoSvg from "@/src/assets/icons/icon-info-gray.svg";
import Typo from "@/src/components/atom/Typo";
import Icon from "@/src/components/atom/Icon";
import { StyledScroll } from "@/src/styles/scroll";
import { renderNoRowsComponent } from "@/src/components/atom/Table";
import dayjs from "dayjs";
import ReadOnlyText from "@/src/components/atom/TextArea/ReadOnlyText";
import { useParams } from "react-router-dom";
import { taskDetailApi } from "@/src/store/apis/tasks/taskDetail";
import { SimpleTaskMemoDto } from "@/src/store/apis/tasks/taskDetail/interface";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

const memoEmptyArray: SimpleTaskMemoDto[] = [];

function MemoCard() {
  const { t } = useTranslation();
  const params = useParams();

  const { simpleLoadingMemos } =
    taskDetailApi.endpoints.getTaskDetail.useQueryState(
      {
        id: Number(params.id),
      },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isFetching || isError;

          return {
            simpleLoadingMemos: !isUnstable
              ? currentData.simpleTaskMemos ?? memoEmptyArray
              : memoEmptyArray,
          };
        },
      }
    );

  return (
    <StyledSectionCard cardTitle={t("task:detail.cards.memo")}>
      <MemoContainer>
        <MemoDescription>
          <StyeldIcon iconSrc={InfoSvg} />
          <Typo typoType="b9r" color="gray6">
            {t("task:detail.description.memo")}
          </Typo>
        </MemoDescription>

        <MemoContent>
          {simpleLoadingMemos.length >= 1 ? (
            simpleLoadingMemos.map((item, idx) => {
              return (
                <MemoItem key={idx.toString()}>
                  <MemoLabelContainer>
                    <Typo color="gray3" typoType="h9">
                      {item.writerName}
                    </Typo>
                    <Typo color="gray7" typoType="b9r">
                      {`(${dayjs(item.wroteAt).format("YY-MM-DD")}, ${dayjs(
                        item.wroteAt
                      ).format("HH:mm")})`}
                    </Typo>
                  </MemoLabelContainer>

                  <ReadOnlyText typoType="b7r" color="gray6">
                    {item.memo}
                  </ReadOnlyText>
                </MemoItem>
              );
            })
          ) : (
            <MemoNoDataContainer>{renderNoRowsComponent()}</MemoNoDataContainer>
          )}
        </MemoContent>
      </MemoContainer>
    </StyledSectionCard>
  );
}

export default MemoCard;

const StyledSectionCard = styled(SectionCard)`
  grid-column: span 7;
`;

const StyeldIcon = styled(Icon)`
  flex-shrink: 0;
`;

const MemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MemoDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const MemoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  height: 140px;
  ${StyledScroll}
`;

const MemoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MemoLabelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;
`;

const MemoNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
