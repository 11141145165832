import React, { useState } from "react";
import { styled } from "styled-components";
import Dialog from "@/src/components/atom/Dialog";
import FormItem from "@/src/components/molecule/FormItem";
import { useForm } from "react-hook-form";
import typo from "@/src/styles/typography";
import colorSet from "@/src/styles/color";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import useAlert from "@/src/hooks/useAlert";
import { useCreateFactoryEmployeesMutation } from "@/src/store/apis/client/factory";
import { useTranslation } from "react-i18next";

interface AddNewContactPersonDialogProps {
  onClose: () => void;
  factoryId: number;
  factoryName: string;
  defaultCountryCodeItemName: string;
  defaultCountryCodePrefix?: string;
}

type FormValueType = {
  factoryName: string;
  name: string;
  email: string;
  personalContactPrefix: string;
  personalContact: string;
};

const AddNewContactPersonDialog = ({
  factoryId,
  factoryName,
  defaultCountryCodeItemName,
  defaultCountryCodePrefix,
  onClose,
}: AddNewContactPersonDialogProps) => {
  const { t } = useTranslation();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const alert = useAlert();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    factoryName: string;
    name: string;
    email: string;
    personalContactPrefix: string;
    personalContact: string;
  }>({
    defaultValues: {
      factoryName,
      name: "",
      email: "",
      personalContactPrefix: defaultCountryCodePrefix,
      personalContact: "",
    },
  });

  const [createFactoryEmployees] = useCreateFactoryEmployeesMutation();

  const handleSave = async (values: FormValueType) => {
    const { factoryName, ...restValues } = values;
    try {
      await createFactoryEmployees({
        id: factoryId,
        isActivated: true,
        ...restValues,
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      onClose();
    }
  };

  const renderAlertDialog = () => {
    if (!isAlertDialogOpen) return null;
    return (
      <AlertDialog
        title={t("task:add.alertDialog.cancel.title")}
        open
        description={t("task:add.alertDialog.cancel.description")}
        onOpenChange={() => setIsAlertDialogOpen(false)}
        cancelText={t("task:add.common.exit")}
        okText={t("task:add.common.ok")}
        onOk={() => onClose()}
        onCancel={() => setIsAlertDialogOpen(false)}
      />
    );
  };

  return (
    <Dialog
      open
      onOpenChange={onClose}
      title={t("task:add.dialog.addNewContactPerson")}
      destroyDialogWhenEscapePress={false}
      onEscapeKeyDown={() => setIsAlertDialogOpen(true)}
      footer={
        <ButtonContainer>
          <Button buttonGrade="tertiary" buttonColor="black" onClick={onClose}>
            {t("task:add.common.exit")}
          </Button>
          <Button onClick={handleSubmit(handleSave)}>
            {t("task:add.buttonLabel.select")}
          </Button>
        </ButtonContainer>
      }
    >
      <StyledForm>
        <FormItem
          label={
            <CustomLabel>
              {t("task:add.common.factory")} <strong>*</strong>
            </CustomLabel>
          }
          type="text"
          name="factoryName"
          control={control}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="vertical"
          inputProps={{ disabled: true, placeholder: watch("factoryName") }}
        />
        <FormItem
          label={t("task:add.common.name")}
          type="text"
          name="name"
          control={control}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{ placeholder: t("task:add.placeholder.name") }}
        />
        <FormItem
          label={t("task:add.common.email")}
          type="text"
          name="email"
          control={control}
          errorsMessage={{
            required: t("error:required"),
          }}
          direction="vertical"
          rules={{ required: true }}
          inputProps={{ placeholder: t("task:add.placeholder.email") }}
        />
        <ContactFormItem
          prefixName="personalContactPrefix"
          restContactName="personalContact"
          label={t("task:add.common.personalContact")}
          setValue={setValue}
          direction="vertical"
          register={register}
          prefixValue={watch("personalContactPrefix") || undefined}
          errors={errors}
        />
      </StyledForm>
      {renderAlertDialog()}
    </Dialog>
  );
};

export default AddNewContactPersonDialog;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CustomLabel = styled.p`
  ${typo.b7m};
  color: ${colorSet.gray2};

  strong {
    color: ${colorSet.red2};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
