import { apiSlice } from "../../baseQuery";
import {
  BuyerEmployeeDto,
  ModifyContractStatusDto,
} from "../../client/buyer/interface";
import { WarehousingDto } from "../../sales/interface";
import { Row, Rows } from "../../type";
import {
  ContractBookmarkListViewDto,
  ContractDetailViewDto,
  ContractDetailViewForImporterDto,
  ContractHistory,
  ContractRelatedMediaViewDto,
  ContractShipmentSummaryInfoDto,
  ContractSignature,
  ContractTaskSummaryInfoDto,
  ExporterUserContractBookmarkInfoDto,
  IsBookmarkedInfoDto,
  SendContractInfoEmailV2Dto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "bookmark",
    "detail",
    "exporterSignature",
    "importerSignature",
    "importerContractDetail",
  ],
});

const contractDetailApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 계약 단일 항목 조회
    getContractDetail: build.query<ContractDetailViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}`,
      }),
      transformResponse: (res: Row<ContractDetailViewDto>) => res.row,
      providesTags: ["detail"],
    }),

    // 수입자 계약 단일 항목 조회
    getImporterContractDetail: build.query<
      ContractDetailViewForImporterDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}`,
      }),
      transformResponse: (res: Row<ContractDetailViewForImporterDto>) =>
        res.row,
      providesTags: ["importerContractDetail"],
    }),

    // 계약 단일 항목 북마크 여부 조회
    getContractDetailBookmark: build.query<
      ExporterUserContractBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporter-users/this/contracts/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserContractBookmarkInfoDto>) =>
        res.row,
      providesTags: ["bookmark"],
    }),

    // 수출입웹 유저의 단일 계약에 대한 북마크 정보 관장 API
    updateContractDetailBookmark: build.mutation<
      Row<ExporterUserContractBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporter-users/this/contracts/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),

    // 해당 계약의 북마크 유저 목록 조회
    getContractDetailBookmarkUser: build.query<
      Rows<ContractBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/contract-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 수입자 계약 단일 항목 북마크 여부 조회
    getImporterContractDetailBookmark: build.query<
      ExporterUserContractBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importer-users/this/contracts/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserContractBookmarkInfoDto>) =>
        res.row,
      providesTags: ["bookmark"],
    }),

    // 수입자 수출입웹 유저의 단일 계약에 대한 북마크 정보 관장 API
    updateImporterContractDetailBookmark: build.mutation<
      Row<ExporterUserContractBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/importer-users/this/contracts/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),

    // 계약을 북마크한 수출입웹 유저 목록 조회 API
    getImporterContractDetailBookmarkUser: build.query<
      Rows<ContractBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/contract-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 바이어 직원 목록 조회 API
    getContractDetailBuyerEmployees: build.query<
      Rows<BuyerEmployeeDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/buyers/${id}/buyer-employees?isActivated=true`,
      }),
    }),

    // 계약 정보 이메일 발송 API (수출자)
    contractDetailSendEmail: build.mutation<
      void,
      SendContractInfoEmailV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/v2/exporters/this/contracts/${id}/send-contract-info-email`,
        body: body,
      }),
    }),

    // 계약 정보 이메일 발송 API (수입자)
    importerContractDetailSendEmail: build.mutation<
      void,
      SendContractInfoEmailV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/importers/this/contracts/${id}/send-contract-info-email`,
        body: body,
      }),
    }),

    // 계약 상태 변경 API
    updateContractDetailStatus: build.mutation<void, ModifyContractStatusDto>({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/contract-status-change`,
        body: body,
      }),
      invalidatesTags: ["detail"],
    }),

    // 계약 상세에서 계약과 연결된 작업에 대한 요약 정보 조회 API
    getContractTaskSummaryInfo: build.query<
      ContractTaskSummaryInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/task-summary-info`,
      }),
      transformResponse: (res: Row<ContractTaskSummaryInfoDto>) => res.row,
    }),

    // 계약 상세에서 계약과 연결된 선적에 대한 요약 정보 조회 API
    getContractShipmentSummaryInfo: build.query<
      ContractShipmentSummaryInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/shipment-summary-info`,
      }),
      transformResponse: (res: Row<ContractShipmentSummaryInfoDto>) => res.row,
    }),

    // 수입자 계약 상세에서 계약과 연결된 작업에 대한 요약 정보 조회 API
    getImporterContractTaskSummaryInfo: build.query<
      ContractTaskSummaryInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/task-summary-info`,
      }),
      transformResponse: (res: Row<ContractTaskSummaryInfoDto>) => res.row,
    }),

    // 수입자 계약 상세에서 계약과 연결된 선적에 대한 요약 정보 조회 API
    getImporterContractShipmentSummaryInfo: build.query<
      ContractShipmentSummaryInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/shipment-summary-info`,
      }),
      transformResponse: (res: Row<ContractShipmentSummaryInfoDto>) => res.row,
    }),

    confirmSc: build.mutation<{ id: number }, any>({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/sc/confirmation`,
        method: "POST",
      }),
      invalidatesTags: ["exporterSignature", "detail"],
    }),
    confirmPo: build.mutation<{ id: number }, any>({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/po/confirmation`,
        method: "POST",
      }),
      invalidatesTags: ["exporterSignature", "detail"],
    }),
    rejectPo: build.mutation<{ id: number; rejectReason: string }, any>({
      query: ({ id, rejectReason }) => ({
        url: `/exporters/this/contracts/${id}/po/rejection`,
        method: "POST",
        body: {
          rejectReason,
        },
      }),
      invalidatesTags: ["exporterSignature", "detail"],
    }),

    confirmImporterPo: build.mutation<{ id: number }, any>({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/po/confirmation`,
        method: "POST",
      }),
      invalidatesTags: ["importerSignature", "importerContractDetail"],
    }),
    confirmImporterSc: build.mutation<{ id: number }, any>({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/sc/confirmation`,
        method: "POST",
      }),
      invalidatesTags: ["importerSignature", "importerContractDetail"],
    }),
    rejectImporterSc: build.mutation<{ id: number; rejectReason: string }, any>(
      {
        query: ({ id, rejectReason }) => ({
          url: `/importers/this/contracts/${id}/sc/rejection`,
          method: "POST",
          body: {
            rejectReason,
          },
        }),
        invalidatesTags: ["importerSignature", "importerContractDetail"],
      }
    ),

    getContractsMedias: build.query<
      Rows<ContractRelatedMediaViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/medias`,
      }),
    }),

    getImporterContractsMedias: build.query<
      Rows<ContractRelatedMediaViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/medias`,
      }),
    }),

    getExporterContractSignatureHistories: build.query<
      Rows<ContractSignature>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/contracts/${id}/signature-histories`,
      }),
      providesTags: ["exporterSignature"],
    }),

    getImporterContractSignatureHistories: build.query<
      Rows<ContractSignature>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/contracts/${id}/signature-histories`,
      }),
      providesTags: ["importerSignature"],
    }),

    getExporterPoHistories: build.query<
      Rows<ContractHistory>,
      { contractId: number }
    >({
      query: ({ contractId }) => ({
        url: "/exporters/this/contract-histories",
        params: {
          contractId,
          type: "PO",
        },
      }),
    }),
    getExporterScHistories: build.query<
      Rows<ContractHistory>,
      { contractId: number }
    >({
      query: ({ contractId }) => ({
        url: "/exporters/this/contract-histories",
        params: {
          contractId,
          type: "SC",
        },
      }),
    }),

    getImporterPoHistories: build.query<
      Rows<ContractHistory>,
      { contractId: number }
    >({
      query: ({ contractId }) => ({
        url: "/importers/this/contract-histories",
        params: {
          contractId,
          type: "PO",
        },
      }),
    }),
    getImporterScHistories: build.query<
      Rows<ContractHistory>,
      { contractId: number }
    >({
      query: ({ contractId }) => ({
        url: "/importers/this/contract-histories",
        params: {
          contractId,
          type: "SC",
        },
      }),
    }),

    getImporterWarehousing: build.query<
      Rows<WarehousingDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: ({ id, ...rest }) => ({
        url: `/importers/this/contracts/${id}/warehousing`,
        params: { ...rest },
      }),
    }),

    getImporterContractsWarehousing: build.query<
      Rows<WarehousingDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `/importers/this/contracts/${id}/warehousing`,
        params: {
          page,
          pageSize,
        },
      }),
    }),
  }),
});

export const {
  useGetContractDetailQuery,
  useLazyGetContractDetailQuery,

  useGetImporterContractDetailQuery,
  useLazyGetImporterContractDetailQuery,

  // 북마크
  useGetContractDetailBookmarkQuery,
  useGetContractDetailBookmarkUserQuery,
  useUpdateContractDetailBookmarkMutation,
  // 수입자 북마크
  useGetImporterContractDetailBookmarkQuery,
  useGetImporterContractDetailBookmarkUserQuery,
  useUpdateImporterContractDetailBookmarkMutation,

  useGetContractDetailBuyerEmployeesQuery,
  useLazyGetContractDetailBuyerEmployeesQuery,
  useUpdateContractDetailStatusMutation,
  useGetContractTaskSummaryInfoQuery,
  useGetContractShipmentSummaryInfoQuery,
  useContractDetailSendEmailMutation,

  useGetImporterContractTaskSummaryInfoQuery,
  useGetImporterContractShipmentSummaryInfoQuery,

  useConfirmScMutation,
  useConfirmPoMutation,
  useRejectPoMutation,

  useConfirmImporterPoMutation,
  useConfirmImporterScMutation,
  useRejectImporterScMutation,

  useGetContractsMediasQuery,
  useGetImporterContractsMediasQuery,

  useGetExporterContractSignatureHistoriesQuery,
  useLazyGetExporterContractSignatureHistoriesQuery,
  useLazyGetExporterPoHistoriesQuery,
  useLazyGetExporterScHistoriesQuery,

  useGetImporterContractSignatureHistoriesQuery,
  useLazyGetImporterContractSignatureHistoriesQuery,
  useLazyGetImporterPoHistoriesQuery,
  useLazyGetImporterScHistoriesQuery,

  useLazyGetImporterWarehousingQuery,
  useGetImporterContractsWarehousingQuery,
  useImporterContractDetailSendEmailMutation,
} = contractDetailApi;
export { contractDetailApi };
