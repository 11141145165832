import { useGetSeavantageAuthQuery } from "@/src/store/apis/seavantage";
import colorSet from "@/src/styles/color";
import React from "react";
import { PulseLoader } from "react-spinners";

interface CargoTrackingProps {
  blNo: string;
}

const CargoTracking = ({ blNo }: CargoTrackingProps) => {
  const { isFetching, isError, currentData } = useGetSeavantageAuthQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (isFetching) {
    return <PulseLoader color={colorSet.blue4} />;
  }

  if (isError || !currentData) {
    return <></>;
  }

  return (
    <iframe
      title="cargo-tracking"
      src={`https://svmp.seavantage.com/#/cargo/tracking?authToken=${currentData.seaVantageAuthToken}&mblNo=${blNo}`}
      style={{
        height: "700px",
        width: "100%",
      }}
      name="cargoTracking"
      allowFullScreen
    />
  );
};

export default CargoTracking;
