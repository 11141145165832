import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import Dialog from "@/src/components/atom/Dialog";
import TabItem from "@/src/components/molecule/TabItem";
import colorSet from "@/src/styles/color";
import Typo from "@/src/components/atom/Typo";
import {
  useGetCompanyLinkageReceivedHistoryQuery,
  useGetCompanyLinkageSentHistoryQuery,
} from "@/src/store/apis/link";
import {
  CompanyLinkageHistorySummaryDto,
  GetCompanyLinkageHistorySummaryDtoRequest,
} from "@/src/store/apis/link/interface";
import Table from "@/src/components/atom/Table";
import {
  clientRecieveLinkageListColumn,
  clientSendLinkageListColumn,
} from "./columns";
import { AgGridReact } from "ag-grid-react";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import { isUndefined } from "@/src/utils/is";
import { useTranslation } from "react-i18next";

interface LinkHistoryDialogProps {
  onClose: () => void;
}

type TabType = "sentHistory" | "receiveHistory";

const TAB_LIST: {
  id: TabType;
  langKey: string;
}[] = [
  {
    id: "sentHistory",
    langKey: "clientManagement:importer.linkHistoryDialog.tabs.linkSentList",
  },
  {
    id: "receiveHistory",
    langKey: "clientManagement:importer.linkHistoryDialog.tabs.linkReceiveList",
  },
];

const HISTORY_EMPTY_ARRAY: CompanyLinkageHistorySummaryDto[] = [];

const LinkHistoryDialog = ({ onClose }: LinkHistoryDialogProps) => {
  const { t } = useTranslation();
  const ref = useRef<AgGridReact>(null);
  const [tab, setTab] = useState<TabType>("sentHistory");
  const [isReady, setIsReady] = useState<boolean>(false);
  const [sendColumnDefs] = useState(clientSendLinkageListColumn);
  const [receiveColumnDefs] = useState(clientRecieveLinkageListColumn);
  useAgGridHeaderRefresh({
    gridRef: ref.current,
    isReady,
    headerSet: [
      {
        columnKey: "linkedStatus",
        langKey:
          "clientManagement:importer.linkHistoryDialog.table.linkedStatus",
      },
      {
        columnKey: "client",
        langKey: "clientManagement:importer.linkHistoryDialog.table.client",
      },
      {
        columnKey: "clientCode",
        langKey: "clientManagement:importer.linkHistoryDialog.table.clientCode",
      },
      {
        columnKey: "createdAt",
        langKey: "clientManagement:importer.linkHistoryDialog.table.createdAt",
      },
      {
        columnKey: "linkedAt",
        langKey: "clientManagement:importer.linkHistoryDialog.table.linkedAt",
      },
    ],
  });
  const [sentSortState, setSentSortState] =
    useState<GetCompanyLinkageHistorySummaryDtoRequest>({
      sort: undefined,
      order: undefined,
      page: 1,
      pageSize: 10,
    });
  const [receivedSortState, setReceivedSortState] =
    useState<GetCompanyLinkageHistorySummaryDtoRequest>({
      sort: undefined,
      order: undefined,
      page: 1,
      pageSize: 10,
    });

  const { sentHistoryCount, sentHistory } =
    useGetCompanyLinkageSentHistoryQuery(sentSortState, {
      skip: tab !== "sentHistory",
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          sentHistoryCount: isStable ? currentData.count : 0,
          isSentHistoryFetching: isFetching,
          isSentHistoryError: isError,
          sentHistory: isStable ? currentData.rows : HISTORY_EMPTY_ARRAY,
        };
      },
    });
  const { receivedHistoryCount, receivedHistory } =
    useGetCompanyLinkageReceivedHistoryQuery(receivedSortState, {
      skip: tab !== "receiveHistory",
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          receivedHistoryCount: isStable ? currentData.count : 0,
          isReceivedHistoryFetching: isFetching,
          isReceivedHistoryError: isError,
          receivedHistory: isStable ? currentData.rows : HISTORY_EMPTY_ARRAY,
        };
      },
    });

  const renderTable = () => {
    if (tab === "sentHistory") {
      return (
        <Table
          ref={ref}
          columnDefs={sendColumnDefs}
          rowData={sentHistory}
          onGridReady={() => setIsReady(true)}
          isPaginationDataMaping
          totalPage={sentHistoryCount}
          page={sentSortState.page}
          pageSize={sentSortState.pageSize}
          handlePaginationClick={(page, pageSize) =>
            setSentSortState((prev) => ({ ...prev, page, pageSize }))
          }
          onSortChange={(sortSource, isClickedHeader) =>
            !!isClickedHeader &&
            setSentSortState((prev) => ({
              ...prev,
              sort: sortSource?.sort,
              order: sortSource?.order?.toUpperCase(),
            }))
          }
        />
      );
    }

    return (
      <Table
        ref={ref}
        columnDefs={receiveColumnDefs}
        rowData={receivedHistory}
        onGridReady={() => setIsReady(true)}
        isPaginationDataMaping
        totalPage={receivedHistoryCount}
        page={receivedSortState.page}
        pageSize={receivedSortState.pageSize}
        handlePaginationClick={(page, pageSize) =>
          setReceivedSortState((prev) => ({ ...prev, page, pageSize }))
        }
        onSortChange={(sortSource, isClickedHeader) =>
          !!isClickedHeader &&
          setReceivedSortState((prev) => ({
            ...prev,
            sort: sortSource?.sort,
            order: sortSource?.order?.toUpperCase(),
          }))
        }
      />
    );
  };

  return (
    <Dialog
      open
      title={t("clientManagement:importer.linkHistoryDialog.title")}
      onOpenChange={onClose}
      width={1000}
    >
      <Flex>
        <Tabs role="tablist">
          {TAB_LIST.map(({ id, langKey }) => {
            return (
              <StyeldTabItem
                key={id}
                tabIndex={id === tab ? 0 : -1}
                isSelect={id === tab}
                tabValue={id}
                onClick={() => {
                  setTab(id);
                  if (ref.current) {
                    const isSentTabClick = id === "sentHistory";
                    ref.current.columnApi.resetColumnState();
                    ref.current.columnApi.applyColumnState({
                      state: [
                        {
                          colId: isSentTabClick
                            ? sentSortState?.sort || (null as any)
                            : receivedSortState?.sort || (null as any),
                          sort: isSentTabClick
                            ? sentSortState?.order?.toLowerCase() ||
                              (null as any)
                            : receivedSortState?.order?.toLowerCase() ||
                              (null as any),
                        },
                      ],
                    });
                  }
                }}
                onFocusItem={(value) => {
                  setTab(value as TabType);
                  if (ref.current) {
                    const isSentTabClick = value === "sentHistory";
                    ref.current.columnApi.resetColumnState();
                    ref.current.columnApi.applyColumnState({
                      state: [
                        {
                          colId: isSentTabClick
                            ? sentSortState?.sort || (null as any)
                            : receivedSortState?.sort || (null as any),
                          sort: isSentTabClick
                            ? sentSortState?.order || (null as any)
                            : receivedSortState?.order || (null as any),
                        },
                      ],
                    });
                  }
                }}
              >
                <Typo typoType="b5m" color={id === tab ? "white" : "gray6"}>
                  {t(langKey)}
                </Typo>
              </StyeldTabItem>
            );
          })}
        </Tabs>
        {renderTable()}
      </Flex>
    </Dialog>
  );
};

export default LinkHistoryDialog;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Tabs = styled.div`
  display: flex;
`;

const StyeldTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  ${({ isSelect }) => {
    return isSelect
      ? css`
          border: none;
        `
      : css`
          border: 1px solid ${colorSet.gray9};
        `;
  }}
`;
