import Typo from "@/src/components/atom/Typo";
import getTableIndex from "@/src/utils/getTableIndex";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import dayjs from "dayjs";

export const columnNoticeList = (lang: "ko" | "en"): ColDef[] => {
  return [
    {
      headerName: "No.",
      headerClass: "ag-right-aligned-cell",
      field: "no",
      lockPosition: "left",
      pinned: "left",
      cellStyle: { textAlign: "center" },
      width: 70,
      minWidth: 70,
      resizable: false,
      cellRenderer: function (params: ValueGetterParams) {
        return (
          <Typo typoType="b9r">
            {params.node?.rowIndex
              ? getTableIndex(
                  params.node?.rowIndex + 1,
                  params.data.page,
                  params.data.pageSize
                )
              : getTableIndex(1, params.data.page, params.data.pageSize)}
          </Typo>
        );
      },
      sortable: false,
    },
    {
      headerName: "Title",
      headerClass: "ag-right-aligned-cell",
      cellStyle: { textAlign: "center" },
      field: "title",
      flex: 1,
      sortable: false,
      cellRenderer: (params: ValueGetterParams) => {
        return (
          <Typo typoType="b9r">
            {lang === "en"
              ? params.data.titleEn || "-"
              : params.data.titleKr || "-"}
          </Typo>
        );
      },
    },
    {
      headerName: "Register Date",
      headerClass: "ag-right-aligned-cell",
      cellStyle: { textAlign: "center" },
      field: "createdAt",
      width: 180,
      sortable: false,
      cellRenderer: (params: ValueGetterParams) => {
        return params.data.createdAt ? (
          <Typo typoType="b9r">
            {dayjs(params.data.createdAt).format("YYYY-MM-DD")}
          </Typo>
        ) : (
          "-"
        );
      },
    },
  ];
};
