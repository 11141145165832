import React from "react";
import Badge from "@/src/components/atom/Badge";
import { LinkedStatusType } from "@/src/store/apis/client/buyer/interface";

interface LinkStatusBadgeProps {
  status: LinkedStatusType;
  badgeSize?: "L" | "S";
  lang: "en" | "ko";
}

const LinkStatusBadge = ({
  status,
  lang,
  badgeSize = "S",
}: LinkStatusBadgeProps) => {
  switch (status) {
    case "PENDING":
      return (
        <Badge
          text={lang === "en" ? "Unlinked" : "미연동"}
          color="gray7"
          badgeColor="gray10"
          badgeSize={badgeSize}
        />
      );

    case "COMPLETED":
      return (
        <Badge
          text={lang === "en" ? "Linked" : "연동완료"}
          color="systemBlue2"
          badgeColor="systemBlue6"
          badgeSize={badgeSize}
        />
      );

    case "RECEIVED":
      return (
        <Badge
          text={lang === "en" ? "Required" : "승인필요"}
          color="green1"
          badgeColor="green6"
          badgeSize={badgeSize}
        />
      );

    case "SENT":
      return (
        <Badge
          text={lang === "en" ? "Approval" : "승인대기"}
          color="yellow2"
          badgeColor="yellow6"
          badgeSize={badgeSize}
        />
      );
  }
};

export default LinkStatusBadge;
