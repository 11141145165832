import { apiSlice } from "../../baseQuery";
import { Row, Rows } from "../../type";
import {
  ShipmentContainerListViewDto,
  ShipmentDetailViewDto,
  ShipmentTaskListViewDto,
} from "./interface";

const shipmentRegisterApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 계약 단일 항목 조회
    getTasksInShipment: build.query<
      ShipmentTaskListViewDto[],
      { buyerNameCode: string }
    >({
      query: (params) => ({
        url: "/exporters/this/shipment/tasks",
        params,
      }),
      transformResponse: (res: Rows<ShipmentTaskListViewDto>) => res.rows,
    }),
    // 컨테이너 조회
    getContainerListInShipment: build.mutation<
      ShipmentContainerListViewDto[],
      { taskIdList: number[] }
    >({
      query: (body) => ({
        url: "/exporters/this/shipment/container-list-inquiry",
        method: "POST",
        body,
      }),
      transformResponse: (res: Rows<ShipmentContainerListViewDto>) => res.rows,
    }),
    createShipment: build.mutation<
      ShipmentDetailViewDto,
      { containerIdList: number[] }
    >({
      query: (body) => ({
        url: "/exporters/this/shipments",
        method: "POST",
        body,
      }),
      transformResponse: (res: Row<ShipmentDetailViewDto>) => res.row,
    }),
  }),
});

export const {
  useLazyGetTasksInShipmentQuery,
  useGetContainerListInShipmentMutation,
  useCreateShipmentMutation,
} = shipmentRegisterApi;
export { shipmentRegisterApi };
